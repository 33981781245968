import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps, Link } from '../../../components';
import { IconButton } from '@mui/material';
import { IEmailTemplate } from '../../../models';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { hyphenSeparateTwoInputs } from '../../../helpers';

interface EmailTemplatesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IEmailTemplate[];
  refetch: () => Promise<void>;
}

export const EmailTemplatesDataGrid: FC<EmailTemplatesDataGridProps> = ({
  rows,
  refetch,
  ...gridProps
}) => {
  const history = useHistory();
  const columns = useMemo(() => {
    return [
      {
        field: 'emailTemplateEventDescription',
        headerName: 'Event',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'templateName',
        headerName: 'Template Name',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEmailTemplate>) => {
          const { row: emailTemplate } = params;
          return (
            <Link to={`/setup/email-templates/${emailTemplate.emailTemplateId}`}>
              {emailTemplate?.emailTemplateName}
            </Link>
          );
        },
      },
      {
        field: 'subject',
        headerName: 'Subject',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'sortOrder',
        headerName: 'Order',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEmailTemplate>) => {
          const { row: emailTemplate } = params;
          return (
            <IconButton
              color="primary"
              title={`Edit Template: ${emailTemplate.emailTemplateName}`}
              onClick={async () =>
                history.push(`/setup/email-templates/${emailTemplate.emailTemplateId}`)
              }
            >
              <FontAwesomeIcon icon={faEdit} size="sm" />
            </IconButton>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <ServerSideDataGrid
      {...gridProps}
      autoHeight
      getRowId={(row: IEmailTemplate) => row.emailTemplateId}
      rows={rows}
      columns={columns}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (params: IEmailTemplate) => {
          return hyphenSeparateTwoInputs(
            params.emailTemplateEventDescription,
            params.emailTemplateName
          );
        },
        handleEdit: (params: IEmailTemplate) => {
          return history.push(`/setup/email-templates/${params.emailTemplateId}`);
        },
      }}
    />
  );
};
