import { Box, Checkbox, FormControlLabel, MenuItem, Grid, FormHelperText } from '@mui/material';
import {
  Select,
  TextField,
  PhoneTextField,
  Card,
  CardTitle,
  MultipleEmailInput,
  EditAddressInformation,
} from '../../../components';
import { FC, Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { useFormikContext, Field, FieldArray, FormikErrors, FormikTouched } from 'formik';
import {
  IAccountAlert,
  IAccountClass,
  IAccountDetail,
  IBillingGroup,
  IDropdownResponse,
  IReferralType,
  ISalesTax,
  ITerminationType,
} from '../../../models';
import { alphaSort, hasCorrectUserPermissions } from '../../../helpers';
import clsx from 'clsx';
import { DatePicker } from '@mui/x-date-pickers';
import { AccountDetailsCol } from './account-details-col';
import {
  getAccountAlerts,
  getAccountClasses,
  getAccountStatuses,
  getBillingGroups,
  getReferralTypes,
  getSalesTaxes,
  getTerminationTypes,
} from '../../../fetch';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context';
import { generateDefaultName, statementDeliveryOptions } from './CustomersDetailCRUD';
import { Permissions } from '../../../constants';
import { kebabCase } from 'lodash';
import { getDefaultSalesTaxId } from '../utils';

export interface IAccountDetailsFields {
  currentCustomer: IAccountDetail | null;
  handleBlur?: any;
  handleChange?: any;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
  values?: any;
  isEditable?: boolean;
  actualSpendingLimit: number;
  setActualSpendingLimit: (value: number) => void;
  //Dropdown states
  accountClasses?: IAccountClass[];
  setAccountClasses?: (value: IAccountClass[]) => void;
  referralTypes?: IReferralType[];
  setReferralTypes?: (value: IReferralType[]) => void;
  accountAlerts?: IAccountAlert[];
  setAccountAlerts?: (value: IAccountAlert[]) => void;
  billingGroups?: IBillingGroup[];
  setBillingGroups?: (value: IBillingGroup[]) => void;
  salesTaxes?: ISalesTax[];
  setSalesTaxes?: (value: ISalesTax[]) => void;
  accountTypes: IDropdownResponse[];
  setAccountTypes: (value: IDropdownResponse[]) => void;
  isNewCustomer?: boolean;
  terminationTypes?: ITerminationType[];
  setTerminationTypes?: (value: ITerminationType[]) => void;
  pushPinLocation: Microsoft.Maps.Location | null;
  setPushPinLocation: (val: Microsoft.Maps.Location | null) => void;
}

export const AccountDetailsFields: FC<IAccountDetailsFields> = ({
  currentCustomer,
  errors,
  touched,
  handleBlur,
  values,
  isEditable = false,
  //Dropdown states
  accountClasses,
  setAccountClasses,
  referralTypes,
  setReferralTypes,
  accountAlerts,
  setAccountAlerts,
  billingGroups,
  setBillingGroups,
  salesTaxes,
  setSalesTaxes,
  accountTypes,
  isNewCustomer,
  terminationTypes,
  setTerminationTypes,
  pushPinLocation,
  setPushPinLocation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue } = useFormikContext();
  const { user, office } = useContext(UserContext);
  const [billingGroupWarning, setBillingGroupWarning] = useState('');
  const primaryPhoneNumberType = isNewCustomer ? '' : currentCustomer?.primaryPhoneNumberType;
  const [primaryPhoneType, setPrimaryPhoneType] = useState<string>(primaryPhoneNumberType!);
  const primaryPhoneSelected = isNewCustomer ? false : true;
  const [isPrimaryPhoneTypeSelected, setIsPrimaryPhoneTypeSelected] =
    useState(primaryPhoneSelected);
  const [primaryPhoneTypeIndex, setPrimaryPhoneTypeIndex] = useState(-1);

  const handlePhoneFieldBlur = (index: any) => {
    let phoneNumberCount = 0;
    values.phoneNumbers.forEach((phone: any) => {
      if (phone.value !== '' && phone.phoneNumberType !== 'Primary') {
        phoneNumberCount++;
      }
    });
    if (phoneNumberCount === 1) {
      var phoneNumber = values.phoneNumbers[index];
      if (
        phoneNumber.value !== '' &&
        !isPrimaryPhoneTypeSelected &&
        index !== -1 &&
        phoneNumber.phoneNumberType !== 'Primary'
      ) {
        setPrimaryPhoneType(phoneNumber.phoneNumberType);
        setIsPrimaryPhoneTypeSelected(true);
        setPrimaryPhoneTypeIndex(index);
        setFieldValue('primaryPhoneNumberType', phoneNumber.phoneNumberType);
      }
    }
    if (
      Number(index) === Number(primaryPhoneTypeIndex) &&
      values.phoneNumbers[index].value === ''
    ) {
      setPrimaryPhoneType('');
      setIsPrimaryPhoneTypeSelected(false);
      setPrimaryPhoneTypeIndex(-1);
      setFieldValue('primaryPhoneNumberType', '');
    }
  };

  // Dropdown States
  const [isLoadingAccountClasses, setIsLoadingAccountClasses] = useState(false);
  const fetchAccountClasses = async () => {
    setIsLoadingAccountClasses(true);
    try {
      const res = await getAccountClasses({ perPage: -1, officeId: user?.officeId });
      setAccountClasses && setAccountClasses(alphaSort(res.records, 'description'));
    } catch (error) {
      enqueueSnackbar(`Error loading account classes, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingAccountClasses(false);
    }
  };

  const [statuses, setStatuses] = useState<IDropdownResponse[]>([]);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const fetchAccountStatuses = async () => {
    setIsLoadingStatuses(true);
    try {
      const res = await getAccountStatuses();
      setStatuses(alphaSort(res, 'description'));
    } catch (error) {
      enqueueSnackbar(`Error loading account statuses, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingStatuses(false);
    }
  };

  const [isLoadingReferralTypes, setIsLoadingReferralTypes] = useState(false);
  const fetchReferralTypes = async () => {
    setIsLoadingReferralTypes(true);
    try {
      const res = await getReferralTypes({ perPage: -1, officeId: user?.officeId });
      setReferralTypes && setReferralTypes(alphaSort(res.records, 'description'));
    } catch (error) {
      enqueueSnackbar(`Error loading referral types, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingReferralTypes(false);
    }
  };

  const [isLoadingAccountAlerts, setIsLoadingAccountAlerts] = useState(false);
  const fetchAccountAlerts = async () => {
    setIsLoadingAccountAlerts(true);
    try {
      const res = await getAccountAlerts({ perPage: -1, officeId: user?.officeId });
      setAccountAlerts && setAccountAlerts(alphaSort(res.records, 'description'));
    } catch (error) {
      enqueueSnackbar(`Error loading account alerts, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingAccountAlerts(false);
    }
  };

  const [isLoadingBillingGroups, setIsLoadingBillingGroups] = useState(false);
  const fetchBillingGroups = async () => {
    setIsLoadingBillingGroups(true);
    try {
      const res = await getBillingGroups({ perPage: -1, officeId: user?.officeId });
      const sorted = alphaSort(res.records, 'description');
      setBillingGroups && setBillingGroups(sorted);
    } catch (error) {
      enqueueSnackbar(`Error loading billing groups, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingBillingGroups(false);
    }
  };

  const [isLoadingSalesTaxes, setIsLoadingSalesTaxes] = useState(false);
  const fetchSalesTaxes = async () => {
    setIsLoadingSalesTaxes(true);
    try {
      const res = await getSalesTaxes({ perPage: -1, officeId: user?.officeId });
      const sorted: ISalesTax[] = alphaSort(res.records, 'description');
      setSalesTaxes && setSalesTaxes(sorted);
    } catch (error) {
      enqueueSnackbar(`Error loading sales taxes, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSalesTaxes(false);
    }
  };

  const [isLoadingTerminationTypes, setIsLoadingTerminationTypes] = useState(false);
  const fetchTerminationTypes = async () => {
    setIsLoadingTerminationTypes(true);
    try {
      const options: any = {
        perPage: 100,
        officeId: user?.officeId,
      };
      const res = await getTerminationTypes(options);
      setTerminationTypes && setTerminationTypes(alphaSort(res.records, 'description'));
    } catch (error) {
      enqueueSnackbar(`Error loading termination types, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingTerminationTypes(false);
    }
  };

  const [isInfoRequired, setIsInfoRequired] = useState(false);

  const handleStartupSourceDropdownChange = (event: any) => {
    const selectedStartupSourceOption = event.target.value;
    setFieldValue('referralTypeId', selectedStartupSourceOption);

    referralTypes?.forEach(option => {
      if (option.description === selectedStartupSourceOption) {
        let isRequired = option.infoRequired;
        setIsInfoRequired(isRequired);
      }
    });
  };

  const hasPermissionToEditAccountCode = useMemo(
    () =>
      hasCorrectUserPermissions(Permissions.ChangeAccountCode, user!) && office?.canEditAccountCode,
    [office, user]
  );

  useEffect(() => {
    referralTypes?.forEach(option => {
      if (option.description === values.referralTypeId) {
        let isRequired = option.infoRequired;
        setIsInfoRequired(isRequired);
      }
    });
  }, [referralTypes, values.referralTypeId]);

  useEffect(() => {
    // select the billing group if only one in the list
    if (billingGroups && billingGroups?.length === 1 && isNewCustomer) {
      setFieldValue('billingGroupId', billingGroups?.[0]?.billingGroupId);
    }
    setFieldValue('salesTaxId', getDefaultSalesTaxId(salesTaxes));
  }, [salesTaxes, billingGroups, isNewCustomer, setFieldValue]);

  useEffect(() => {
    if (values.billingGroupId && billingGroups && billingGroups?.length > 0) {
      const selected = billingGroups?.find(g => g.billingGroupId === values.billingGroupId);
      // billingGroupId assigned doesn't exist in the list of billing groups then reset the
      // form field to empty
      if (!selected) {
        setBillingGroupWarning('Selected Billing Group no longer active, please select a new one');
        setFieldValue('billingGroupId', '');
      }
    }
  }, [values, billingGroups, setFieldValue]);

  useEffect(() => {
    if (!isNewCustomer) {
      const indexOfPrimaryPhone = values.phoneNumbers.findIndex(
        (item: any) => item.phoneNumberType === values.primaryPhoneNumberType
      );
      if (indexOfPrimaryPhone !== -1) {
        setPrimaryPhoneTypeIndex(indexOfPrimaryPhone);
      }
    }
  }, [isNewCustomer, values.phoneNumbers, values.primaryPhoneNumberType]);

  useEffect(() => {
    if (isEditable) {
      fetchAccountClasses();
      fetchAccountStatuses();
      fetchReferralTypes();
      fetchAccountAlerts();
      fetchTerminationTypes();
      fetchBillingGroups();
      fetchSalesTaxes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);
  return (
    <>
      <Box>
        <Card>
          <CardTitle title="General Information" />
          <Box mt={2.5}>
            <Grid container spacing={2}>
              <AccountDetailsCol>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="nope"
                  label="First Name"
                  name="firstName"
                  required
                  onBlur={e => {
                    handleBlur(e);
                    if (!values.accountName && values.lastName) {
                      setFieldValue(
                        'accountName',
                        generateDefaultName({
                          ...values,
                          firstName: e.target.value,
                        })
                      );
                    }
                    if (!values.addressName && values.lastName) {
                      setFieldValue(
                        'addressName',
                        generateDefaultName({
                          ...values,
                          firstName: e.target.value,
                        })
                      );
                    }
                  }}
                  inputProps={{
                    'data-testid': 'first-name-text'
                  }}
                />
              </AccountDetailsCol>
              <AccountDetailsCol>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="nope"
                  label="Last Name"
                  name="lastName"
                  required
                  onBlur={e => {
                    handleBlur(e);
                    if (!values.accountName && values.firstName) {
                      setFieldValue(
                        'accountName',
                        generateDefaultName({
                          ...values,
                          lastName: e.target.value,
                        })
                      );
                    }
                    if (!values.addressName && values.firstName) {
                      setFieldValue(
                        'addressName',
                        generateDefaultName({
                          ...values,
                          lastName: e.target.value,
                        })
                      );
                    }
                  }}
                  inputProps={{
                    'data-testid': 'last-name-text'
                  }}
                />
              </AccountDetailsCol>
              <AccountDetailsCol>
                <TextField
                  fullWidth
                  required
                  size="small"
                  autoComplete="nope"
                  label="Customer"
                  name="accountName"
                  onBlur={e => {
                    handleBlur(e);
                    if (!values.accountName && (values.firstName || values.lastName)) {
                      setFieldValue('accountName', generateDefaultName(values));
                    }
                  }}
                  inputProps={{
                    'data-testid': 'account-name-text'
                  }}
                />
              </AccountDetailsCol>
              {Array.isArray(accountClasses) && accountClasses.length > 0 && (
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    size="small"
                    autoComplete="nope"
                    label="Class"
                    name="class"
                    disabled={isLoadingAccountClasses}
                    inputProps={{
                      'data-testid': 'class-select'
                    }}
                  >
                    {accountClasses.map(accountClass => (
                      <MenuItem
                        key={`account-class-${accountClass.accountClassDefId}`}
                        value={accountClass.description ?? accountClass.accountClassDefId}
                      >
                        {accountClass.description}
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
              )}
              {isEditable && !!currentCustomer?.name && (
                <AccountDetailsCol>
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="nope"
                    label="Account Code"
                    name="accountCode"
                    disabled={!hasPermissionToEditAccountCode}
                    required
                    inputProps={{
                      'data-testid': 'code-text'
                    }}
                  />
                </AccountDetailsCol>
              )}
              {Array.isArray(accountTypes) && accountTypes.length > 0 && (
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Account Type"
                    name="accountType"
                    inputProps={{
                      'data-testid': 'type-select'
                    }}
                  >
                    {accountTypes.map(type => (
                      <MenuItem key={`account-type-${type.value}`} value={type.value}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
              )}
              {Array.isArray(statuses) && statuses.length > 0 && (
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Status"
                    name="status"
                    disabled={isLoadingStatuses}
                    onChange={e => {
                      setFieldValue('status', e.target.value);
                      if (values.terminationTypeId && e.target.value === 'Active') {
                        setFieldValue('terminationTypeId', '');
                      }
                      if (values.terminationReason && e.target.value === 'Active') {
                        setFieldValue('terminationReason', '');
                      }
                      if (e.target.value !== 'Active') {
                        setFieldValue('dateTerminated', new Date().toISOString());
                      }
                    }}
                    inputProps={{
                      'data-testid': 'status-select'
                    }}
                  >
                    {statuses.map(status => (
                      <MenuItem key={`account-status-${status.value}`} value={status.value}>
                        {status.description}
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
              )}
              <AccountDetailsCol>
                <DatePicker
                  label={'Date Started'}
                  format="MM/dd/yyyy"
                  onChange={(date: any) => setFieldValue('dateStarted', date)}
                  value={new Date(values.dateStarted)}
                  maxDate={new Date()}
                  slotProps={{
                    textField: {
                      inputProps: {
                        'data-testid': 'date-started-field',
                      },
                      error: !!errors?.dateStarted && !!touched?.dateStarted,
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />
              </AccountDetailsCol>
              {values.status !== 'Active' && (
                <AccountDetailsCol>
                  <DatePicker
                    label={'Date Terminated'}
                    format="MM/dd/yyyy"
                    onChange={(date: any) => {
                      setFieldValue('dateTerminated', date);
                    }}
                    value={new Date(values.dateTerminated)}
                    maxDate={new Date()}
                    slotProps={{
                      textField: {
                        inputProps: {
                          'data-testid': 'date-terminated-field',
                        },
                        error: !!errors?.dateTerminated && !!touched?.dateTerminated,
                        size: 'small',
                        fullWidth: true,
                      },
                    }}
                  />
                </AccountDetailsCol>
              )}
              {Array.isArray(referralTypes) &&
                referralTypes.length > 0 &&
                values.status === 'Active' && (
                  <AccountDetailsCol>
                    <Select
                      fullWidth
                      select
                      size="small"
                      autoComplete="nope"
                      label="Startup Source"
                      name="referralTypeId"
                      disabled={isLoadingReferralTypes}
                      hasClear={values.referralTypeId}
                      onChange={handleStartupSourceDropdownChange}
                      inputProps={{
                        'data-testid': 'startup-source-select'
                      }}
                    >
                      {referralTypes.map(type => (
                        <MenuItem
                          key={`referral-type-${type.referralTypeId}`}
                          value={type.description ?? type.referralTypeId}
                        >
                          {type.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </AccountDetailsCol>
                )}
              {values.status === 'Active' && (
                <AccountDetailsCol>
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="nope"
                    label="Startup Info"
                    name="referralInfo"
                    required={isInfoRequired}
                    inputProps={{
                      'data-testid': 'startup-info-text'
                    }}
                  />
                </AccountDetailsCol>
              )}
              <AccountDetailsCol>
                <Select
                  fullWidth
                  select
                  size="small"
                  autoComplete="nope"
                  label="Account Alert"
                  name="accountAlert"
                  disabled={isLoadingAccountAlerts}
                  SelectProps={{
                    displayEmpty: true,
                    defaultValue: values.accountAlert,
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    'data-testid': 'alert-select'
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {!!accountAlerts &&
                    accountAlerts.map(alert => (
                      <MenuItem
                        key={`account-alert-${alert.accountAlertDefId}`}
                        value={alert.description ?? alert.accountAlertDefId}
                      >
                        {alert.description}
                      </MenuItem>
                    ))}
                </Select>
              </AccountDetailsCol>
            </Grid>

            {values.status !== 'Active' && (
              <Grid container spacing={2} sx={{ marginTop: 0 }}>
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Termination Reason"
                    name="terminationTypeId"
                    disabled={isLoadingTerminationTypes}
                    inputProps={{
                      'data-testid': 'termination-reason-select'
                    }}
                  >
                    {terminationTypes?.map(terminationType => (
                      <MenuItem
                        key={`termination-type-${terminationType.terminationTypeId}`}
                        value={terminationType.terminationTypeId}
                      >
                        {terminationType.description}
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
                <AccountDetailsCol sm={12} md={12} xl={12} disableMargin>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                    autoComplete="off"
                    label="Additional Info"
                    name="terminationReason"
                    className={clsx('print--none')}
                    inputProps={{
                      'data-testid': 'termination-reason-text'
                    }}
                  />
                  {/* Used for the print screen only */}
                  {values.terminationReason && (
                    <div className={clsx('print--only')}>{values.terminationReason}</div>
                  )}
                </AccountDetailsCol>
              </Grid>
            )}
          </Box>
        </Card>
      </Box>
      <Box my={3}>
        <Card>
          <CardTitle title="Contact Information" />
          <Box mt={2.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} xl={5}>
                <Grid container spacing={2}>
                  <FieldArray name="phoneNumbers">
                    {() => {
                      return (
                        <>
                          {values.phoneNumbers &&
                            values.phoneNumbers.length > 0 &&
                            values.phoneNumbers.map((phoneNumber: any, index: any) => {
                              if (phoneNumber.phoneNumberType === 'Primary') {
                                return null;
                              }
                              return (
                                <Fragment key={`${index}`}>
                                  <AccountDetailsCol sm={6} md={6} xl={6}>
                                    <Field name={`phoneNumber.${index}.value`}>
                                      {() => {
                                        return (
                                          <PhoneTextField
                                            autoComplete="nope"
                                            label={`${phoneNumber.phoneNumberType} Phone`}
                                            name={`phoneNumbers.${index}.value`}
                                            onBlur={e => handlePhoneFieldBlur(index)}
                                            inputProps={{
                                              'data-testid': `${phoneNumber.phoneNumberType.toLowerCase()}-phone-text`
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </AccountDetailsCol>
                                </Fragment>
                              );
                            })}
                        </>
                      );
                    }}
                  </FieldArray>
                  <AccountDetailsCol sm={12} md={12} xl={6}>
                    <MultipleEmailInput
                      fullWidth
                      size="small"
                      placeholder="test@example.com"
                      label="Email"
                      value={values.emails}
                      onChange={(_e, newEmailsValue) => {
                        setFieldValue('emails', newEmailsValue);
                      }}
                    />
                  </AccountDetailsCol>
                  <AccountDetailsCol sm={12} md={12} xl={6}>
                    <Select
                      fullWidth
                      required
                      size="small"
                      autoComplete="off"
                      label="Primary Phone"
                      name="primaryPhoneNumberType"
                      value={primaryPhoneType}
                      onChange={e => {
                        setPrimaryPhoneType(e.target.value);
                        setIsPrimaryPhoneTypeSelected(true);
                        setFieldValue('primaryPhoneNumberType', e.target.value);
                      }}
                      inputProps={{
                        'data-testid': 'primary-phone-select'
                      }}
                    >
                      {values.phoneNumbers
                        ?.filter((phone: any) => phone.phoneNumberType !== 'Primary')
                        .map((phone: any) => (
                          <MenuItem
                            key={`phone-number-option-${phone.phoneNumberType}`}
                            value={phone.phoneNumberType}
                            disabled={!phone.value}
                          >
                            {`${phone.phoneNumberType} Phone`}
                          </MenuItem>
                        ))}
                    </Select>
                  </AccountDetailsCol>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7} xl={7}>
                <EditAddressInformation
                  pushPinLocation={pushPinLocation}
                  setPushPinLocation={setPushPinLocation}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
      <Box my={3}>
        <Card>
          <CardTitle title="Billing Information" />
          <Box mt={2.5}>
            <Grid container spacing={2}>
              {Array.isArray(billingGroups) && billingGroups.length > 0 && (
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Billing Group"
                    name="billingGroupId"
                    disabled={isLoadingBillingGroups}
                    helperText={
                      !values.billingGroupId && !isLoadingBillingGroups ? billingGroupWarning : ''
                    }
                    sx={
                      billingGroupWarning && !isLoadingBillingGroups
                        ? {
                          '&& .MuiFormHelperText-root': {
                            color: theme => theme.palette.warning.main,
                          },
                        }
                        : undefined
                    }
                    inputProps={{
                      'data-testid': 'billing-group-select'
                    }}
                  >
                    {billingGroups.map(group => (
                      <MenuItem
                        key={`billing-group-${group.billingGroupId}`}
                        value={group.billingGroupId}
                      >
                        {group.description}
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
              )}
              {Array.isArray(salesTaxes) && salesTaxes.length > 0 && (
                <AccountDetailsCol>
                  <Select
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Sales Tax"
                    name="salesTaxId"
                    disabled={isLoadingSalesTaxes}
                    inputProps={{
                      'data-testid': 'sales-tax-select'
                    }}
                  >
                    {salesTaxes.map(tax => (
                      <MenuItem key={`sales-tax-${tax.salesTaxId}`} value={tax.salesTaxId}>
                        {tax.description} - {tax.taxRate}%
                      </MenuItem>
                    ))}
                  </Select>
                </AccountDetailsCol>
              )}
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <AccountDetailsCol disableMargin>
                    <Select
                      fullWidth
                      size="small"
                      autoComplete="nope"
                      label="Statement Delivery"
                      name="statementDelivery"
                      inputProps={{
                        'data-testid': 'statement-delivery-select'
                      }}
                    >
                      {statementDeliveryOptions.map((option, index) => (
                        <MenuItem key={`${index}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </AccountDetailsCol>
                  <AccountDetailsCol sm={12} md={4} xl={6} disableMargin>
                    <FormControlLabel
                      color="primary"
                      control={
                        <Checkbox
                          checked={values.emailWhenServiceClosed}
                          id="emailWhenServiceClosed"
                          color="primary"
                          onClick={() => {
                            setFieldValue('emailWhenServiceClosed', !values.emailWhenServiceClosed);
                          }}
                        />
                      }
                      label="Email when service closed"
                    />
                  </AccountDetailsCol>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
      <Box my={3}>
        <Card>
          <CardTitle title="Additional Information" />
          <Box mt={2.5}>
            <Grid container spacing={2}>
              <FieldArray name="additionalInfo">
                {() => {
                  return (
                    <>
                      {values.additionalInfo.length > 0 &&
                        values.additionalInfo.map((additionalField: any, index: any) => {
                          return (
                            <Fragment key={`${index}`}>
                              <AccountDetailsCol>
                                <Field name={`additionalInfo.${index}.value`}>
                                  {({ field }: any) => {
                                    if (
                                      additionalField.description.includes('Date') ||
                                      additionalField.description.includes('Birthday')
                                    ) {
                                      return (
                                        <>
                                          <DatePicker
                                            {...field}
                                            onChange={(date: any) => {
                                              setFieldValue(field.name, date ?? '');
                                            }}
                                            label={additionalField.description}
                                            format="MM/dd/yyyy"
                                            value={
                                              // the value is a string from the api
                                              // so we need to convert it to a date and remove the appended Z
                                              // otherwise use the date picker object value
                                              values.additionalInfo?.[index]?.value &&
                                                typeof values.additionalInfo?.[index]?.value ===
                                                'string'
                                                ? new Date(
                                                  values.additionalInfo?.[index]?.value?.replace(
                                                    'Z',
                                                    ''
                                                  )
                                                )
                                                : values.additionalInfo?.[index]?.value ?? null
                                            }
                                            maxDate={new Date()}
                                            slotProps={{
                                              textField: {
                                                inputProps: {
                                                  'data-testid': `${kebabCase(additionalField.description)}-text`
                                                },
                                                size: 'small',
                                                fullWidth: true,
                                                error:
                                                  errors &&
                                                    errors.additionalInfo &&
                                                    // @ts-ignore
                                                    errors.additionalInfo[index] &&
                                                    // @ts-ignore
                                                    errors.additionalInfo[index].value
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                          />
                                          {touched &&
                                            errors &&
                                            errors.additionalInfo &&
                                            // @ts-ignore
                                            errors.additionalInfo[index] &&
                                            // @ts-ignore
                                            errors.additionalInfo[index].value && (
                                              <FormHelperText className="text-danger">
                                                {
                                                  // @ts-ignore
                                                  errors.additionalInfo[index].value
                                                }
                                              </FormHelperText>
                                            )}
                                        </>
                                      );
                                    }
                                    return (
                                      <>
                                        <TextField
                                          fullWidth
                                          size="small"
                                          autoComplete="nope"
                                          label={additionalField.description}
                                          name={`additionalInfo.${index}.value`}
                                          inputProps={{
                                            'data-testid': `${kebabCase(additionalField.description)}-text`
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </AccountDetailsCol>
                            </Fragment>
                          );
                        })}
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
          </Box>
        </Card>
      </Box>

      <Box my={3}>
        <Card>
          <CardTitle title="Comments" />
          <Grid container spacing={2}>
            <AccountDetailsCol sm={12} md={12} xl={12} disableMargin>
              <TextField
                fullWidth
                multiline
                rows={4}
                size="small"
                autoComplete="nope"
                label=""
                placeholder="Leave a comment..."
                name="comments"
                className="print--none"
                inputProps={{
                  'data-testid': 'comments-text'
                }}
              />
              {/* Used for the print screen only */}
              {values.comments && <div className="print--only">{values.comments}</div>}
            </AccountDetailsCol>
          </Grid>
        </Card>
      </Box>
    </>
  );
};
