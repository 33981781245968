import { GridSortDirection } from '@mui/x-data-grid';
import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse } from '../models';
import { IAnalysis, IAnalysisDetailResponse } from '../models/analysis';
import { userSignal } from '../signals';

export const getAnalysis = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection | string;
  perPage?: number | string;
  page?: number;
  scheduledServiceId?: string | null;
  repairVisitId?: string | null;
  userId?: string | null;
  siteId?: string;
  officeId?: string | null;
}): Promise<IResponse<IAnalysis[]>> => {
  try {
    const { data } = await axios.get(`/api/analysis`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getAnalysisById = async (
  id: string | number,
  officeId?: string | null
): Promise<IAnalysisDetailResponse> => {
  try {
    const { data } = await axios.get(`/api/analysis/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};
