import { IUser } from '../../models';

export const getUserFullPhoneNumber = (user: IUser | undefined) => {
  if (!user) {
    return '';
  }
  const phoneParts = [];
  if (user.areaCode) {
    phoneParts.push(user.areaCode);
  }
  if (user.phoneNumber) {
    phoneParts.push(user.phoneNumber);
  }
  return phoneParts.join('');
};
