import { Form, Formik } from 'formik';
import {
  Loader,
  Modal,
  ModalSaveSection,
  TextField,
  SelectAsync,
  PrimaryCard,
  PasswordSecurity,
} from '../../../components';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  Box,
  Fade,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  Stack,
  Typography,
} from '@mui/material';
import {
  createUser,
  updateUser,
  getInventoryLocations,
  getUserGroups,
  getUser,
  updateUserPassword,
} from '../../../fetch';
import { IResponse, IInventoryLocation, IUserGroup, IUser } from '../../../models';
import { FC, useContext, useEffect, useState } from 'react';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { faLock, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import pickBy from 'lodash/pickBy';
import { isEqual } from 'lodash';
import { passwordRegex, unCamelCase } from '../../../helpers';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { BrandingContext } from '../../../context/branding-context';

interface IFormData {
  userName: string;
  loginName: string;
  isDisabled: boolean;
  inventoryLocationId: string;
  newPassword: string;
  confirmPassword: string;
  passwordHint: string;
}

const Schema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  loginName: Yup.string().email('Invalid Email').required('Required'),
  isDisabled: Yup.boolean(),
  inventoryLocationId: Yup.string().nullable(),
  newPassword: Yup.string()
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid password',
    }),
  confirmPassword: Yup.string()
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid Password',
    })
    .oneOf([Yup.ref('newPassword'), null], `Passwords don't match`)
    .when('newPassword', {
      is: (val: string) => val && val.trim().length > 0,
      then: Yup.string().required('Required'),
    }),
  passwordHint: Yup.string().when('newPassword', {
    is: (val: string) => val && val.trim().length > 0,
    then: Yup.string().required('Required'),
  }),
});

interface IEditUserModal {
  userId: string | undefined | null;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
}

export const EditUserModal: FC<IEditUserModal> = ({ userId, isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user: currentUser, loadUser: refetchLoggedInUser } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const confirm = useConfirm();
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);
  const [userGroupSelections, setUserGroupSelections] = useState<Record<string, boolean>>({});
  const [initialUserGroupSelections, setInitialUserGroupSelections] = useState<
    Record<string, boolean>
  >({});
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const loadUserGroupOptions = async () => {
    const res = await getUserGroups();
    setUserGroups(res.data);
  };

  useEffect(() => {
    if (userId) {
      loadUserGroupOptions();
    }
  }, [userId]);

  const loadUser = async (userId: string) => {
    try {
      setIsLoadingUser(true);
      const user = await getUser(userId);
      setUser(user);
      const selections: Record<string, boolean> = {};
      user.userGroups.forEach(g => {
        selections[g.userGroupId] = true;
      });
      setInitialUserGroupSelections(selections);
      setUserGroupSelections(selections);
    } finally {
      setIsLoadingUser(false);
    }
  };

  useEffect(() => {
    if (userId) {
      loadUser(userId);
    }
  }, [userId]);

  const handleUserGroupSelection = (group: IUserGroup, checked: boolean) => {
    setUserGroupSelections(
      pickBy(
        {
          ...userGroupSelections,
          [group.userGroupId]: checked,
        },
        value => value === true
      )
    );
  };

  const initialValues: IFormData = {
    userName: user?.userName ?? '',
    loginName: user?.loginName ?? '',
    isDisabled: user?.isDisabled ?? false,
    inventoryLocationId: user?.inventoryLocationId ?? '',
    newPassword: '',
    confirmPassword: '',
    passwordHint: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={async (formData, actions) => {
        try {
          const { newPassword, passwordHint, ...values } = formData;
          const userGroupIds = Object.entries(userGroupSelections)
            .filter(([, selected]) => selected)
            .map(([id]) => id);
          user
            ? await updateUser(user?.userId, {
              ...values,
              userGroupIds,
            })
            : await createUser({
              userName: values.userName,
              loginName: values.loginName,
              isDisabled: values.isDisabled,
              inventoryLocationId: values.inventoryLocationId ?? null,
              officeId: currentUser?.officeId!,
            });
          if (newPassword) {
            try {
              await updateUserPassword(user!.userId, {
                newPassword,
                passwordHint,
              });
            } catch (error: any) {
              enqueueSnackbar(error?.Detail ?? 'Error updating the password, please try again.', {
                variant: 'error',
              });
            }
          }
          // if updates are done to the current user, refetch the user
          if (currentUser?.userId === user?.userId) {
            refetchLoggedInUser(false);
          }
          enqueueSnackbar(`User saved!`, {
            variant: 'success',
          });
          onClose(true);
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? 'Error saving user, please try again.', {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit, dirty, resetForm, isValid, handleChange, values }) => {
        const isDirty = dirty || !isEqual(initialUserGroupSelections, userGroupSelections);
        const handleClose = async () => {
          if (isDirty) {
            const result = await confirm(defaultUnsavedChangesMessage);
            if (result) {
              onClose();
              setUser(null);
              return resetForm();
            }
            return;
          }
          setUser(null);
          onClose();
          resetForm();
        };

        return (
          <Modal
            open={isOpen}
            onClose={handleClose}
            maxWidth="md"
            title={user ? 'Edit User' : 'Add User'}
            hasNoContentPadding
          >
            <Fade in={isOpen}>
              <div style={{ position: 'relative' }}>
                {isLoadingUser && <Loader position="centered" type="overlay" title="Loading..." />}
                {isSubmitting && <Loader type="overlay" position="centered" title="Saving..." />}
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Box mt={1}>
                    <PrimaryCard title="User Info" icon={faUser}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormControlLabel
                              control={<Checkbox name="isDisabled" checked={values.isDisabled} />}
                              label="Disabled"
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="User Name"
                            name="userName"
                            required
                            disabled={isPoolService}
                            inputProps={{
                              'data-lpignore': 'true',
                            }}
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Login Name"
                            name="loginName"
                            required
                            disabled={isPoolService}
                            inputProps={{
                              'data-lpignore': 'true',
                            }}
                            autoComplete='off'
                          />
                        </Grid>
                        {!isPoolService && (
                          <Grid item xs={12} sm={6}>
                            <SelectAsync
                              name="inventoryLocationId"
                              label="Inventory Location"
                              apiRequest={() =>
                                getInventoryLocations({
                                  officeId: currentUser?.officeId,
                                  perPage: -1,
                                })
                              }
                              transformResponse={(response: IResponse<IInventoryLocation[]>) => {
                                return response.records.map(record => ({
                                  label: record.description,
                                  value: record.inventoryLocationId,
                                }));
                              }}
                              hasClear
                            />
                          </Grid>
                        )}
                      </Grid>
                    </PrimaryCard>
                  </Box>
                  {user && (
                    <>
                      {!isPoolService && (
                        <Box mt={2}>
                          <PrimaryCard title="User Password" icon={faLock}>
                            <Typography sx={{ marginBottom: '1.5rem' }}>
                              Please enter and confirm a new password below.
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Stack gap={2}>
                                  <TextField
                                    autoComplete="off"
                                    label="New Password"
                                    name="newPassword"
                                    type="password"
                                  />
                                  <TextField
                                    autoComplete="off"
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                  />
                                  <TextField
                                    autoComplete="off"
                                    label="Password Hint"
                                    name="passwordHint"
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <PasswordSecurity />
                              </Grid>
                            </Grid>
                          </PrimaryCard>
                        </Box>
                      )}
                      <Box mt={2}>
                        <PrimaryCard title="User Groups" icon={faUserGroup}>
                          <Stack direction="row" flexWrap="wrap" gap={1}>
                            {userGroups.map(userGroup => (
                              <FormControlLabel
                                key={userGroup.userGroupId}
                                control={
                                  <Checkbox
                                    checked={!!userGroupSelections[userGroup.userGroupId]}
                                  />
                                }
                                label={unCamelCase(userGroup.userGroupName)}
                                onChange={(_e, checked) =>
                                  handleUserGroupSelection(userGroup, checked)
                                }
                              />
                            ))}
                          </Stack>
                        </PrimaryCard>
                      </Box>
                    </>
                  )}
                  <Box mb={1}>
                    <ModalSaveSection
                      handleCancel={handleClose}
                      isSaveDisabled={!isDirty || !isValid}
                    />
                  </Box>
                </Form>
              </div>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};
