import { Box, Divider, Typography, lighten, useMediaQuery, useTheme } from '@mui/material';

export const OTSSchedulerLegend = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: 900px)`);
  const legend = [
    {
      label: 'Available Days',
      color: lighten(theme.palette.secondary.main, 0.5),
    },
    {
      label: 'Unavailable Days',
      color: '#fff',
    },

    {
      label: 'Current Day',
      color: '#eaf6ff',
    },
    {
      label: 'Weekend',
      color: theme.palette.grey[200],
    },
    {
      label: 'Past Visits',
      color: theme.palette.grey[300],
    },
  ];

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} justifyContent="center">
        {legend.map((leg, index) => {
          return (
            <Box key={`${index}`} display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  backgroundColor: leg.color,
                  width: 20,
                  height: 20,
                  border: `1px solid black`,
                }}
              />
              <Typography variant="caption">{leg.label}</Typography>
            </Box>
          );
        })}
      </Box>
      {!isMobile && (
        <Divider orientation="vertical" sx={{ height: `35px !important`, margin: '0 1rem' }} />
      )}
    </>
  );
};
