import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Box, Alert, Typography, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { ROUTE_HELP_TEXT, getLocalStorage, setLocalStorage } from '../../helpers';

export const ManualAdjustAlert = () => {
  const [isShowingHelpText, setIsShowingHelpText] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetchHelpText = async () => {
      const res = await getLocalStorage(ROUTE_HELP_TEXT);
      // a value stored, then don't show the help text
      if (!!res) {
        setIsShowingHelpText(false);
      } else {
        // a value not stored, then show the help text
        setIsShowingHelpText(true);
      }
    };
    if (mounted) {
      fetchHelpText();
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (!isShowingHelpText) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box mt={1}>
        <Alert severity="info">
          <Box display="flex" alignItems="flex-start">
            <Typography>
              Manually adjust your routes below by dragging and dropping route stops for a date and
              technician. Unsaved changes will display as yellow. Invalid addresses will display as
              red. Unsorted routes will be locked and unmovable until optimized, depending on the
              reason. Clicking the Map button will provide a map view of that date for a visual
              experience. Use the filters below to filter by technician and/or date range.
            </Typography>
            <IconButton
              onClick={async () => {
                setIsShowingHelpText(false);
                await setLocalStorage(ROUTE_HELP_TEXT, 'false');
              }}
              size="small"
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
        </Alert>
      </Box>
    </Grid>
  );
};
