import { useContext, useState } from 'react';
import { NonAuthPage, Card, DisplayGroup, Loader } from '../../components';
import { BrandingContext } from '../../context/branding-context';
import { useParams } from 'react-router-dom';
import { Stack, Grid, Typography, Alert } from '@mui/material';
import { PaymentInfo } from '../online-payments';
import { IExternalInvoicePaymentData } from '../../models';
import { formatAddressBlock, formatDate } from '../../helpers';
import { useQuery } from 'react-query';
import { getExternalInvoicePaymentData } from '../../fetch';
import { AxiosError } from 'axios';

export const ExternalPaymentPage = () => {
  const { isPoolService } = useContext(BrandingContext);
  const { transactionId }: { transactionId?: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [error, setError] = useState<string | null>(null);

  const { isLoading: isLoadingPaymentData, data: paymentData, refetch: reloadExternalInvoice } = useQuery<
    IExternalInvoicePaymentData,
    AxiosError
  >(
    ['getExternalInvoicePaymentData', token, transactionId],
    async () => {
      const res = await getExternalInvoicePaymentData(token as string, transactionId);
      return res;
    },
    {
      onError: (e: any) => {
        setError(e?.Detail ?? 'Error loading invoice payment page, please refresh and try again.');
      },
    }
  );

  const address = paymentData?.address;
  const invoiceNumber = paymentData?.invoiceNumber ?? '';
  const invoiceDate = formatDate(paymentData?.businessDate);
  const reference = paymentData?.reference;

  return (
    <NonAuthPage
      title={`Pay Invoice ${invoiceNumber}`}
      hideHeader={isPoolService}
      innerWrapStyles={{ padding: { xs: 3, md: 4 } }}
    >
      {!!error || !token ? (
        <Alert severity="error">
          The Invoice Payment link no longer appears to be valid. Please contact the store for
          more information. Thank you!
        </Alert>
      ) : (
        <>
          {isLoadingPaymentData && <Loader type="overlay" position="centered" title="Loading" />}
          {paymentData && (
            <Stack spacing={2}>
              {paymentData.amountDue === 0 || paymentData.amountDue === null ? (
                <Alert severity="info">
                  This invoice has either been paid in full or has a zero balance.
                </Alert>
              ) : (
                <>
                  <Alert severity="info">
                    Payment of the remaining balance in full is the only option for this transaction.
                  </Alert>
                  <Card cardTitleProps={{ title: 'Details' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} xl={4}>
                        <DisplayGroup label="Date" labelId="invoiceDate">
                          {invoiceDate}
                        </DisplayGroup>
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <DisplayGroup label="Customer" labelId="accountName">
                          {paymentData.accountName ?? ''}
                        </DisplayGroup>
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <DisplayGroup label="Account Address" labelId="accountAddress" component="div">
                          {!!address && (
                            <>
                              {!!address.addressName && (
                                <Typography component="span">{address?.addressName}</Typography>
                              )}
                              <Typography component="address">
                                {formatAddressBlock(
                                  address?.street,
                                  address?.city,
                                  address?.state,
                                  address?.postalCode
                                )}
                              </Typography>
                            </>
                          )}
                        </DisplayGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <DisplayGroup label="Description" labelId="description">
                          {reference}
                        </DisplayGroup>
                      </Grid>
                    </Grid>
                  </Card>
                  <PaymentInfo
                    currentCustomer={
                      {
                        name: address?.addressName ?? '',
                        firstName: paymentData?.accountFirstName ?? '',
                        lastName: paymentData?.accountLastName ?? '',
                        address: address
                      } as any
                    }
                    invoiceNumber={invoiceNumber}
                    defaultAmount={String(paymentData?.amountDue) ?? 0}
                    isExternalInvoicePayment={true}
                    transactionId={transactionId}
                    reloadExternalInvoice={reloadExternalInvoice}
                    authToken={token}
                    showCancelButton={false}
                    showNotesField={false}
                  />
                </>
              )}
            </Stack>
          )}
        </>
      )}
    </NonAuthPage>
  );
};
