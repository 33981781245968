import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { Card, CardTitle } from '../card';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { WaterAnalysisDataGrid } from './water-analysis-datagrid';
import { WaterAnalysisDetails } from './water-analysis-details';

export interface IWaterAnalysisProps {
  serviceId?: string;
  siteId?: string;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isCardLayout?: boolean;
  isHistory?: boolean;
  cardTitle?: string;
}

export const WaterAnalysis: FC<IWaterAnalysisProps> = ({
  serviceId,
  siteId,
  isExpanded,
  isCollapsible = true,
  initialExpand = false,
  isCardLayout = true,
  isHistory = false,
  cardTitle = 'Analysis',
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const renderChildren = (
    <>
      <WaterAnalysisDetails selectionModel={selectionModel} />
      <WaterAnalysisDataGrid
        siteId={siteId}
        serviceId={serviceId}
        isHistory={isHistory}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
      />
    </>
  );
  if (!isCardLayout && ((isHistory && siteId) || (!isHistory && serviceId))) {
    return <Box>{renderChildren}</Box>;
  }
  if (isCardLayout && ((isHistory && siteId) || (!isHistory && serviceId))) {
    return (
      <Card>
        <CardTitle
          withExpand={isCollapsible}
          overrideExpand={isExpanded}
          initialExpand={initialExpand}
          title={cardTitle}
        >
          {renderChildren}
        </CardTitle>
      </Card>
    );
  }
  return null;
};
