import { FC, useCallback, useContext, useMemo } from 'react';
import {
  GridDataFetcher,
  Loader,
  ServerSideDataGrid,
  useDataGrid,
  Card,
  DaysAvailableSelector,
  CardTitle,
} from '../../../components';
import { useSnackbar } from 'notistack';
import { ITechnicianAvailability } from '../../../models';
import { getRepairTechAvailability, updateRepairTechAvailability } from '../../../fetch';
import { UserContext } from '../../../context';
import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export interface ITechAvailabilityProps {}

export const TechAvailability: FC<ITechAvailabilityProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ITechnicianAvailability> = useCallback(
    async ({ page, perPage, sortDirection, sortColumn }) => {
      try {
        const res = await getRepairTechAvailability({
          sortBy: sortColumn === 'userName' ? 'UserName' : sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId!,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading tech availability, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows: items,
    isLoading: isLoadingTechAvailability,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setRows,
  } = useDataGrid<ITechnicianAvailability>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tech-availability-grid',
      sortColumn: 'userName',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleRowUpdate = useCallback(async (data: ITechnicianAvailability[]) => {
    try {
      await updateRepairTechAvailability({ repairTechAvailability: data });
    } catch (error) {
      enqueueSnackbar('An error occurred while saving the row data', {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (id: string, newValue: string[]) => {
    const updatedData = items.map(x => {
      if (x.repairTechAvailabilityId === id) {
        return {
          ...x,
          daysAvailable: newValue,
        };
      }
      return x;
    });
    setRows(updatedData as ITechnicianAvailability[]); // Live Update for grid, so that changes actually display onClick
    handleRowUpdate(updatedData as ITechnicianAvailability[]); // Save changes from row update
  };

  const columns = useMemo(
    () =>
      [
        {
          field: 'userName',
          headerName: 'Tech',
          minWidth: 250,
        },
        {
          field: 'daysAvailable',
          headerName: 'Days Available',
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams<ITechnicianAvailability>) => {
            return (
              <DaysAvailableSelector
                daysAvailable={params.row.daysAvailable}
                onChange={val => handleOnClick(params.row.repairTechAvailabilityId, val)}
              />
            );
          },
        },
      ].filter(Boolean) as any[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <>
      <Card>
        <CardTitle title="Tech Availability" />
        {isLoadingTechAvailability && (
          <Box height="5rem">
            <Loader position="centered" type="inline" />
          </Box>
        )}
        {!isLoadingTechAvailability && items && items?.length > 0 && (
          <Box>
            <ServerSideDataGrid
              autoHeight
              getRowId={(row: ITechnicianAvailability) => row.repairTechAvailabilityId}
              rows={items}
              columns={columns}
              loading={isLoadingTechAvailability}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              disableColumnFilter
              getRowHeight={() => 'auto'} // Allow padding in Days Available column
              hasMobileLayout
              disableColumnMenu
            />
          </Box>
        )}
        {!isLoadingTechAvailability && (items?.length === 0 || !items) && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="5rem"
          >
            <Typography>No Repair Techs Available.</Typography>
          </Box>
        )}
      </Card>
    </>
  );
};
