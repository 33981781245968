import { createTheme, Theme } from '@mui/material/styles';

export const theme: Theme = createTheme({
  typography: {
    fontFamily: 'HCo Gotham,Helvetica Neue,Helvetica,Arial,sans-serif',
    h2: {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: 'grey',
    },
  },
  palette: {
    primary: {
      main: '#20419a',
      contrastText: '#fff',
      light: '#008BF1',
    },
    secondary: {
      main: '#00bce7',
      contrastText: '#fff',
      light: 'rgba(0, 188, 231, 0.5)',
    },
    tertiary: {
      main: '#ffda02',
      contrastText: '#20419a',
    },
    changed: {
      main: '#FFD166',
    },
    typography: {
      tableHeaders: '#99b2c6',
      labels: '#20419a', // primary
      helpText: '#9e9e9e',
    },
    text: {
      primary: '#212529',
    },
    dividers: {
      grey: '#dee2e6',
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgb(51, 201, 235)',
          color: 'rgb(51, 201, 235)',
          opacity: 1,
          '&&.grey': {
            borderColor: '#dee2e6',
          },
          '&&:not(.MuiDivider-vertical)': {
            height: '2px !important',
          },
        },
        vertical: {
          width: '2px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '& legend': {
            width: 'auto',
          },
        },
        input: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '@media print': {
            display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          padding: 0,
          paddingBottom: '0.5rem',
          fontWeight: 500,
          fontSize: '1.25rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '@media print': {
            border: '1px solid #dee2e6',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0,0,0,.172)',
          '&&.Mui-selected': {
            '&&.MuiToggleButton-primary': {
              backgroundColor: '#20419a',
              color: '#fff',
            },
            '&&.MuiToggleButton-secondary': {
              backgroundColor: '#00bce7',
              color: '#fff',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '@media print': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '@media print': {
            padding: 0,
          },
        },
      },
    },
  },
});
