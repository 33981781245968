import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

interface DataFetcherOptions<T, K> {
  page: number;
  perPage: number;
  sortColumn?: keyof T | string;
  sortDirection?: GridSortDirection;
  beforeItemId?: K;
  afterItemId?: K;
  isFirstPage: boolean;
  isLastPage: boolean;
  isInitialSort?: boolean;
  isLoading?: boolean;
  rows?: T[];
  rowCount?: number;
  search?: string;
  // a very specific prop that is only used in one place, on SitesTab.tsx at the moment so we can handle logic in the call
  isManagingSites?: boolean;
}

interface DataFetcherReturn<T> {
  rows: T[];
  rowCount: number;
}

interface DataFetcherContinueLoadingReturn {
  continueLoading: boolean;
}

export type GridDataFetcher<T, K = unknown> = (
  options: DataFetcherOptions<T, K>
) => Promise<DataFetcherReturn<T> | DataFetcherContinueLoadingReturn | any>;

interface InitialOptions<T, K> {
  page: number;
  pageSize: number;
  rowCount?: number;
  gridKeyName?: string;
  sortColumn?: keyof T;
  sortDirection?: GridSortDirection;
  beforeItemId?: K;
  afterItemId?: K;
  isInitialSort?: boolean;
  isLoading?: boolean;
}

interface UseDataGridOptions<T, K> {
  dataFetcher: GridDataFetcher<T, K>;
  keysetPagingKey?: keyof T;
  initialOptions?: InitialOptions<T, K>;
}

export const useDataGrid = <T, K = unknown>({
  dataFetcher,
  initialOptions,
  keysetPagingKey,
}: UseDataGridOptions<T, K>) => {
  const initialPage = initialOptions?.page || 0;
  const initialPageSize = initialOptions?.pageSize || 10;
  const getLocalPageSize = () => {
    const localPageSize = initialOptions?.gridKeyName
      ? localStorage.getItem(`${initialOptions?.gridKeyName}-page-size`)
      : null;

    return !!localPageSize ? Number(localPageSize) : initialPageSize;
  };

  const [isLoading, setLoading] = useState(true);
  const [rows, setRows] = useState<T[]>([]);
  const [rowCount, setRowCount] = useState<number>(initialOptions?.rowCount ?? 0);
  const [isFirstPage, setIsFirstPage] = useState(initialPage === 0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(getLocalPageSize());
  const [sortColumn, setSortColumn] = useState<keyof T | string | undefined>(
    initialOptions?.sortColumn || ''
  );
  const [sortDirection, setSortDirection] = useState<GridSortDirection>(
    initialOptions?.sortDirection || 'asc'
  );
  const [beforeItemId, setBeforeItemId] = useState<K | undefined>(undefined);
  const [afterItemId, setAfterItemId] = useState<K | undefined>(undefined);
  const [isInitialSort, setIsInitialSort] = useState<boolean | undefined>(
    initialOptions?.isInitialSort
  );

  const fetchData = async (params?: any) => {
    setLoading(true);
    try {
      const rtn = await dataFetcher({
        page,
        perPage: pageSize,
        sortColumn,
        sortDirection,
        beforeItemId,
        afterItemId,
        isFirstPage,
        isLastPage,
        isInitialSort,
        isLoading,
        rows,
        rowCount,
        ...params,
      });

      if ((rtn as DataFetcherReturn<T>).rows) {
        const dataRtn = rtn as DataFetcherReturn<T>;
        setRows(dataRtn.rows);
        setRowCount(dataRtn.rowCount);
        setLoading(false);
      } else if ((rtn as DataFetcherContinueLoadingReturn).continueLoading) {
        return;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataFetcher,
    page,
    pageSize,
    sortColumn,
    sortDirection,
    beforeItemId,
    afterItemId,
    isFirstPage,
    isLastPage,
    isInitialSort,
  ]);

  const sortModel: GridSortModel = [{ field: sortColumn as string, sort: sortDirection }];

  const onPageChange = (newPage: number) => {
    const lastPage = Math.ceil(rowCount / pageSize) - 1;
    const isLastPage = newPage === lastPage;
    const isFirstPage = newPage === 0;
    setIsFirstPage(isFirstPage);
    setIsLastPage(isLastPage);
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    const hasChanged = pageSize !== newPageSize;
    const lastPage = Math.ceil(rowCount / pageSize) - 1;
    const isLastPage = page === lastPage;
    // reset page size and first/last params
    if (isLastPage && hasChanged) {
      setBeforeItemId(undefined);
      setAfterItemId(undefined);
      setPage(initialPage); // reset the initial page since you are on the last page
      setIsFirstPage(true);
      setIsLastPage(false);
    }
    setPageSize(newPageSize);
    if (!!initialOptions?.gridKeyName) {
      localStorage.setItem(`${initialOptions?.gridKeyName}-page-size`, String(newPageSize));
    }
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortColumn(initialOptions?.sortColumn ?? '');
      setSortDirection(initialOptions?.sortDirection || 'asc');
      return;
    }
    setIsInitialSort(false);
    setSortColumn(sortModel?.[0]?.field ?? '');
    setSortDirection(sortModel?.[0].sort);
    setPage(0);
    setBeforeItemId(undefined);
    setAfterItemId(undefined);
  };

  const onKeysetPageChange = (
    newPage: number,
    isResetFirstPage?: boolean,
    isResetLastPage?: boolean
  ) => {
    if (!keysetPagingKey) {
      throw new Error('When using keyset pagination a keysetPagingKey is required');
    }
    setPage(newPage);
    const lastPage = Math.ceil(rowCount / pageSize) - 1;
    const isLastPage =
      isResetLastPage !== undefined
        ? isResetLastPage!
        : newPage === lastPage || (newPage + 1) * pageSize === rowCount;
    const isFirstPage = isResetFirstPage !== undefined ? isResetFirstPage! : newPage === 0;
    const isNextPage = newPage >= page && !isLastPage;
    setIsFirstPage(isFirstPage);
    setIsLastPage(isLastPage);
    if (isFirstPage || isLastPage) {
      setBeforeItemId(undefined);
      setAfterItemId(undefined);
    } else if (isNextPage) {
      const lastItem = rows[rows.length - 1];
      setBeforeItemId(undefined);
      if (lastItem) {
        setAfterItemId(lastItem[keysetPagingKey] as K);
      } else {
        setAfterItemId(undefined);
      }
    } else {
      const firstItem = rows[0];
      setAfterItemId(undefined);
      if (firstItem) {
        setBeforeItemId(firstItem[keysetPagingKey] as K);
      } else {
        setBeforeItemId(undefined);
      }
    }
  };
  return {
    isLoading,
    rows,
    rowCount,
    page,
    beforeItemId,
    afterItemId,
    pageSize,
    sortColumn,
    sortDirection,
    sortModel,
    setRows,
    setPage,
    setPageSize,
    setSortColumn,
    setSortDirection,
    onPageChange,
    onKeysetPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchData,
    setIsInitialSort,
  };
};
