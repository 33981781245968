import {
  Box,
  Divider,
  Checkbox,
  useMediaQuery,
  FormControlLabel,
  Tooltip,
  Button,
  Alert,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CreditLimitAlert,
  Link,
  ExternalLink,
  Card,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
} from '../../../components';
import { IAccountDetail, IAccountTransaction } from '../../../models';
import {
  formatDate,
  formatMoney,
  getLegacyUrl,
  hasCorrectUserPermissions,
  hyphenSeparateTwoInputs,
  truncate,
} from '../../../helpers';
import { getAccountTransactions, updateTransactionSuppress } from '../../../fetch';
import { UserContext } from '../../../context';
import { Permissions } from '../../../constants';
import { TransactionModal } from './transaction-modal';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BrandingContext } from '../../../context/branding-context';

export interface ITransactionsTab {
  accountId: string;
  currentCustomer: IAccountDetail | null;
  isEditable?: boolean;
}

const PCP_LEGACY_URL = getLegacyUrl?.();

export const TransactionsTab: FC<ITransactionsTab> = ({
  accountId,
  currentCustomer,
  isEditable = true,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isPoolService } = useContext(BrandingContext);
  const { user } = useContext(UserContext);
  const { v2Billing } = useFlags();

  const [isShowingTransactionModal, setIsShowingTransactionModal] = useState(false);
  const [shouldRefetchAgingReport, setShouldRefetchAgingReport] = useState(false);

  const [isUpdatingSuppress, setIsUpdatingSupress] = useState<{
    [id: string]: boolean;
  }>({});

  const isMobile = useMediaQuery(`(max-width: 1150px)`);
  const isSmMobile = useMediaQuery(`(max-width: 900px)`);

  const dataFetcher: GridDataFetcher<IAccountTransaction> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {
        const res = await getAccountTransactions(accountId, {
          page: page + 1,
          perPage,
          sortDirection: sortDirection || 'desc',
          sortBy: 'businessDate',
        });
        setShouldRefetchAgingReport(false);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading transactions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading: isLoadingTransactions,
    refetch: refetchTransactions,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IAccountTransaction>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'customer-transactions-grid',
      sortColumn: 'date',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const hasTransactionSameDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditSameDay,
    user!
  );
  const hasTransactionPriorDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditPriorDay,
    user!
  );

  const renderTransactionDate = (transaction: IAccountTransaction) => {
    const isInvoiceLink = transaction.transactionType === 'Invoice';
    // the date for the transaction is greater than or equal to today
    const dateIsSameOrAfter =
      isSameDay(new Date(transaction.date), new Date()) ||
      isAfter(new Date(transaction.date), new Date());

    // the date for the transaction is less than to today
    const dateIsBefore = isBefore(new Date(transaction.date), new Date());

    const hasPermission: boolean =
      (hasTransactionSameDayPermission && dateIsSameOrAfter) ||
      (hasTransactionPriorDayPermission && dateIsBefore);

    if (hasPermission) {
      const redirect = `?redirect=/customers/${accountId}?activeTab=transactions`;

      if (v2Billing) {
        let link = isInvoiceLink
          ? `/billing/invoices/${transaction.transactionId}${redirect}`
          : `/billing/transactions/${transaction.transactionId}${redirect}`;
        return <Link to={link}>{transaction.date ? formatDate(transaction.date) : ''}</Link>;
      }

      if (isInvoiceLink) {
        return (
          <ExternalLink
            to={`${PCP_LEGACY_URL}/Office/Billing/Invoice/${transaction.transactionId}`}
          >
            {transaction.date ? formatDate(transaction.date) : ''}
          </ExternalLink>
        );
      }

      return (
        <ExternalLink to={`${PCP_LEGACY_URL}/Office/Billing/Edit/${transaction.transactionId}`}>
          {transaction.date ? formatDate(transaction.date) : ''}
        </ExternalLink>
      );
    }
    return <>{transaction.date ? formatDate(transaction.date) : ''}</>;
  };
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'date',
        headerName: 'Date',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return renderTransactionDate(transaction);
        },
      },
      {
        field: 'transactionType',
        headerName: 'Type',
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'reference',
        headerName: 'Description',
        disableColumnMenu: true,
        flex: 3,
        minWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return transaction.reference && transaction.reference.length > 60 ? (
            <Tooltip
              key={`${transaction.transactionId}-description`}
              placement="bottom"
              title={transaction.reference}
            >
              <span>{truncate(transaction.reference, 60)}</span>
            </Tooltip>
          ) : (
            <span>{transaction.reference}</span>
          );
        },
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 70,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return transaction.invoiceNumber ? <>{transaction.invoiceNumber}</> : <></>;
        },
      },
      {
        field: 'debitAmount',
        headerName: 'Debit',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return <>{formatMoney(transaction.debitAmount)}</>;
        },
      },
      {
        field: 'creditAmount',
        headerName: 'Credit',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return <>{formatMoney(transaction.creditAmount)}</>;
        },
      },
      {
        field: 'balance',
        headerName: 'Balance',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return <>{formatMoney(transaction.balance)}</>;
        },
      },
      {
        field: 'isPosted',
        headerName: 'Posted',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 50,
        maxWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;

          const formatValue = () => {
            if (transaction.isPosted || transaction.paymentStatus === 'ApprovedPendingReview') {
              return 'Yes';
            }
            if (isPoolService && transaction.paymentStatus === 'Error') {
              return transaction?.authDesc ?? transaction.paymentStatus;
            }
            if (isPoolService && transaction.paymentStatus) {
              return transaction.paymentStatus;
            }
            return 'No';
          };
          return formatValue().length > 12 ? (
            <Tooltip
              key={`${transaction.transactionId}-posted`}
              placement="bottom"
              title={formatValue()}
            >
              <span>{truncate(formatValue(), 12)}</span>
            </Tooltip>
          ) : (
            <span>{formatValue()}</span>
          );
        },
      },
      isPoolService && {
        field: 'accountingIntegrationStatus',
        headerName: 'Accounting',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          return (
            <>{transaction.isExported ? 'Exported' : transaction.accountingIntegrationStatus}</>
          );
        },
      },
      {
        field: 'isSuppressed',
        headerName: 'Suppress',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 50,
        sortable: false,

        renderCell: (params: GridRenderCellParams<IAccountTransaction>) => {
          const { row: transaction } = params;
          if (isEditable) {
            return (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction.isSuppressed}
                      sx={{ padding: `0 8px`, backgroundColor: `transparent !important` }}
                    />
                  }
                  label=""
                  disabled={isUpdatingSuppress[transaction.transactionId]}
                  onChange={async (_, checked) => {
                    try {
                      setIsUpdatingSupress({
                        [transaction.transactionId]: true,
                      });
                      await updateTransactionSuppress(transaction.transactionId, checked);
                      enqueueSnackbar(
                        checked ? 'Transaction is suppressed!' : 'Transaction is un-suppressed!',
                        {
                          variant: 'success',
                        }
                      );
                      refetchTransactions();
                    } catch (error: any) {
                      enqueueSnackbar(
                        error?.Detail ?? 'An error occurred while updating your statement',
                        {
                          variant: 'error',
                        }
                      );
                    } finally {
                      setIsUpdatingSupress({
                        [transaction.transactionId]: false,
                      });
                    }
                  }}
                />
              </Box>
            );
          }
          return null;
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdatingSuppress,
    isEditable,
    hasTransactionSameDayPermission,
    hasTransactionPriorDayPermission,
    v2Billing,
    isPoolService,
  ]);

  const hasTransactions = !isLoadingTransactions && rows && rows?.length > 0;
  const showAuthorizenetAlert = useMemo(
    () => rows?.filter(r => r.paymentStatus === 'ApprovedPendingReview').length > 0,
    [rows]
  );

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          <Box mb={1}>
            <CreditLimitAlert
              accountId={accountId}
              isAlwaysShown
              shouldRefetch={shouldRefetchAgingReport}
            />
          </Box>
          {showAuthorizenetAlert && (
            <Box mb={1}>
              <Alert severity="error">
                A transaction has triggered the Authorize.net IP transaction filter. We recommend
                that you disable the filter in authorize.net. Please contact the helpdesk for
                additional assistance.
              </Alert>
            </Box>
          )}
          <Box>
            <ServerSideDataGrid
              autoHeight
              getRowId={(row: IAccountTransaction) => row.transactionId!}
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              columnHeaderHeight={36}
              page={page}
              pageSize={perPage}
              sortModel={sortModel}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
              rowCount={recordCount}
              hideFooter={!hasTransactions}
              columnVisibilityModel={{
                isSuppressed: isEditable,
              }}
              loading={isLoadingTransactions}
              noResultsMessage="No transactions found."
              hasMobileLayout
              mobileProps={{
                mobileCustomDefaultAccessor: (val: IAccountTransaction) =>
                  hyphenSeparateTwoInputs(formatDate(val?.date) ?? '', val?.transactionType),
                truncateAccordionLabel: true,
              }}
            />
          </Box>
          <Divider sx={{ marginTop: isSmMobile ? '1rem' : 0 }} />
          <Box
            marginTop="1rem"
            display="flex"
            flexWrap="wrap"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            {isEditable && (
              <Box
                display="flex"
                alignItems="center"
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                }}
                gap={1}
              >
                {hasTransactionSameDayPermission && (
                  <>
                    {!isLoadingTransactions && (
                      <>
                        <Button
                          onClick={() => {
                            setIsShowingTransactionModal(true);
                          }}
                          fullWidth={isMobile}
                          color="primary"
                          variant="outlined"
                        >
                          Post
                        </Button>
                        <Button
                          onClick={() => {
                            history.push(
                              `/billing/invoices/new?redirect=${encodeURIComponent(
                                `/customers/${accountId}?activeTab=transactions`
                              )}&accountId=${accountId}`
                            );
                          }}
                          fullWidth={isMobile}
                          color="primary"
                          variant="outlined"
                          sx={{ minWidth: '10rem' }}
                        >
                          Create Invoice
                        </Button>
                      </>
                    )}
                  </>
                )}
                {!isLoadingTransactions && (
                  <Button
                    onClick={() =>
                      history.push(
                        `/online-payments/${accountId}?redirect=/customers/${accountId}?activeTab=transactions`
                      )
                    }
                    fullWidth={isMobile}
                    color="primary"
                    variant="outlined"
                    sx={{ minWidth: '10rem' }}
                  >
                    Online Payments
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Card>
      </Box>

      <TransactionModal
        isOpen={isShowingTransactionModal}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            refetchTransactions();
            setShouldRefetchAgingReport(true);
          }
          setIsShowingTransactionModal(false);
        }}
        accountId={currentCustomer?.accountId as string}
      />
    </>
  );
};
