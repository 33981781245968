import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { IVisitPhoto } from '../../models';

 interface ChildProps {
   openModal: (isOpen: boolean) => void
   setSource: (source: string) => void
   images: IVisitPhoto[]
}

const VisitImageGrid: React.FC<ChildProps> = (props) => {
  const classes = useStyles();

    const imageClicked = (source: string) =>{
       props.openModal(true);
       props.setSource(source)
    }
    
  return (
    <Grid className={classes.gallery}>
      {props.images.map((image) => (
         <div key={image.visitPhotoId}>
            <img
            
            className={classes.galleryItem}
            src={image.urlPath}
            alt={image.photoName}
            onClick={() => imageClicked(image.urlPath)}
            />
            {image.photoName}
            </div>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    gallery: {
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
    },
    galleryItem: {
      width: '100%',
      height: '70%',
      objectFit: 'cover',
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  };
});



export default VisitImageGrid;