import { axios, formatRawDate } from '../helpers';
import qs from 'querystring';
import { GetCalendarRequest, ICalendarItem, IUpdateCalendarRequest } from '../models';
import { userSignal } from '../signals';

export const getCalendarEvents = async (
  request: GetCalendarRequest = {},
  officeId?: string | null
): Promise<ICalendarItem[]> => {
  try {
    const { startDate, endDate, ...filters } = request;
    const { data } = await axios.get(`/api/users/calendar`, {
      params: {
        ...filters,
        startDate: startDate ? formatRawDate(startDate) : undefined,
        endDate: endDate ? formatRawDate(endDate) : undefined,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateCalendar = async (
  request: IUpdateCalendarRequest,
  officeId?: string | null
): Promise<void> => {
  const { data } = await axios.put(`/api/calendar`, {
    ...request,
    officeId: officeId ?? userSignal.value?.officeId ?? '',
  });
  return data;
};
