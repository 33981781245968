import { getEnvironment } from './environment';

export const APP_VERSION = `pcp-${getEnvironment()}-app-version`;
export const WEB_VERSION = `pcp-${getEnvironment()}-web-version`;
export const CALENDAR_VIEW = `pcp-${getEnvironment()}-calendar-view`;
export const ROUTE_HELP_TEXT = `pcp-${getEnvironment()}-route-help-text`;
export const SIDENAV_OPEN = `pcp-${getEnvironment()}-nav-open`;
export const REMEMBER_ME = `pcp-${getEnvironment()}-remember-me`;
export const TREATMENT_GRID_KEY = `pcp-${getEnvironment()}-treatments-page-size`;
export const SERVICE_LOG_GRID_KEY = `pcp-${getEnvironment()}-service-log-page-size`;
export const MAINTENANCE_FIELD_REPORT_USER_GROUP = `pcp-${getEnvironment()}-maintenance-field-report-user-group`;
export const OTS_FIELD_REPORT_USER_GROUP = `pcp-${getEnvironment()}-ots-field-report-user-group`;

/**
 * Sets an item in local storage. NOTE: Only for use within the browser.
 * @param key string
 * @param value any
 * @returns boolean
 */
export const setLocalStorage = async (key: string, value: any): Promise<boolean> => {
  try {
    const item = JSON.stringify(value);
     localStorage.setItem(`pcp-${getEnvironment()}-${key}`, item);
   return true;
  } catch (error) {
    console.error('setLocalStorage', error);
    return false;
  }
};

/**
 * Gets an item from local storage. NOTE: Only for use within the browser.
 * @param key string
 * @returns any
 */
export const getLocalStorage = async (key: string): Promise<any | undefined> => {
  try {
    const item: any = localStorage.getItem(`pcp-${getEnvironment()}-${key}`);
    if (item) {
      const parsed = JSON.parse(item);
      return parsed;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error('getLocalStorage', error);
    return undefined;
  }
};

export const removeLocalStorage = async (key: string): Promise<boolean> => {
  try {
    localStorage.removeItem(`pcp-${getEnvironment()}-${key}`);
    return true;
  } catch (error) {
    console.error('removeLocalStorage', error);
    return false;
  }
};


