import { GridSortDirection } from '@mui/x-data-grid';
import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IRecommendation } from '../models';
import { userSignal } from '../signals';

export const getRecommendations = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection | string;
  perPage?: number | string;
  scheduledServiceId?: string | null;
  officeId?: string | null;
}): Promise<IResponse<IRecommendation[]>> => {
  try {
    const { data } = await axios.get(`/api/recommendations`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};
