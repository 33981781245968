import { axios } from '../helpers';
import qs from 'qs';
import { IPart, IResponse } from '../models';
import { userSignal } from '../signals';

export const getParts = async (filters?: {
  search?: string;
  partId?: string | number;
  partIds?: number[];
  officeId?: string | null;
}): Promise<IResponse<IPart[]>> => {
  try {
    const { data } = await axios.get(`/api/parts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
    return {
      totalRecordCount: data.length,
      records: data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
