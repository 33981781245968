import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useContext } from 'react';
import { BrandingContext } from '../../context/branding-context';
import { Theme } from '@mui/material';

interface ILogo {
  className?: string;
}

export const Logo: FC<ILogo> = ({ className }) => {
  const { appLongName, fullLogo, isPoolService } = useContext(BrandingContext);
  const classes = useStyles({ isPoolService });

  return (
    <img src={fullLogo} alt={`${appLongName} Logo`} className={clsx(classes.appLogo, className)} />
  );
};

const useStyles = makeStyles<Theme, { isPoolService: boolean }>(() => ({
  appLogo: {
    margin: 'auto 0',
    width: ({ isPoolService }) => (isPoolService ? 160 : 170),
    marginTop: ({ isPoolService }) => (isPoolService ? 3 : 0),
  },
}));
