import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC, useEffect, useState } from 'react';
import { getUpdateModes } from '../../fetch';
import { IRouteUpdateMode, IUpdateMode } from '../../models';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface UpdateModeSelectProps {
  value: IRouteUpdateMode;
  onChange?: (value: IRouteUpdateMode) => unknown;
  disabled?: boolean;
}

export const UpdateModeSelect: FC<UpdateModeSelectProps> = ({ value, onChange, disabled }) => {
  const classes = useStyles();
  const [updateModes, setUpdateModes] = useState<IUpdateMode[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (selected: IUpdateMode) => {
    handleClose();
    if (selected.value === value) {
      return;
    }
    onChange?.(selected.value as IRouteUpdateMode);
  };

  const loadOptions = async () => {
    const modes = await getUpdateModes();
    setUpdateModes(modes);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  let selectedLabel = 'Update Mode';

  const selectedOption = updateModes.find(m => m.value === value);

  if (selectedOption) {
    selectedLabel = `${selectedOption.description} Mode`;
  }

  return (
    <>
      <Button
        id="update-mode-select"
        aria-controls={open ? 'update-mode-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
        size="large"
        className={classes.updateModeButton}
        disabled={disabled}
        data-testid="update-mode-select"
      >
        {selectedLabel}
      </Button>
      <Menu
        id="update-mode-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'update-mode-select',
        }}
      >
        {updateModes.map(option => (
          <MenuItem
            key={option.value}
            selected={option === selectedOption}
            onClick={() => handleSelection(option)}
          >
            {option.description} Mode
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  updateModeButton: {
    padding: '5px 10px',
    gap: theme.spacing(1),
  },
}));
