import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { DisplayGroup } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideCardDisplayGroup = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Display Group">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Display Group component is a custom built component that is used for rendering a
            view-only version of a label/field value grouping. This component was specifically
            designed for the view-only display of the Customer Account Details and is used in
            conjunction with cards and card titles to form sections of display-only data.
          </Typography>
          <Typography mb={1}>
            The component has built-in styling, including print specific classes for optimal print
            styling.
          </Typography>
          <Typography mb={1}>
            This component is meant to be used in conjunction with{' '}
            <Link href="https://mui.com/material-ui/react-grid/" target="_blank">
              MUI Grid
            </Link>{' '}
            components to render section data in columns. When implementing columns, be sure to
            include a print class of <em>col-print-6</em> so that things are printed in 2 columns
            (customize print column size as you see fit).
          </Typography>
          <code>
            Props:
            <br />- labelId?: string
            <br />- labelClasses?: string
            <br />- label: string
            <br />- children: any
            <br />- component?: React.ElementType
            <br />- display?: string
            <br />- wrap?: string
            <br />- isDropdownResponse?: boolean
            <br />- dropdownValue?: string
            <br />- apiRequest?: () =&gt; Promise&lt;any&gt;
            <br />- transformResponse?: (response: any) =&gt; ISelectOption[]
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4} xl={3} className="col-print-6">
                <DisplayGroup label="Account Name" labelId="accountName">
                  Adams Family
                </DisplayGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3} className="col-print-6">
                <DisplayGroup label="First Name" labelId="firstName">
                  Adams
                </DisplayGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3} className="col-print-6">
                <DisplayGroup label="Last Name" labelId="lastName">
                  Family
                </DisplayGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3} className="col-print-6">
                <DisplayGroup label="Account Type" labelId="accountType">
                  -
                </DisplayGroup>
              </Grid>
            </Grid>
          </Box>

          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Grid container spacing=&#123;2&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item xs=&#123;12&#125; sm=&#123;6&#125; lg=&#123;4&#125;
            xl=&#123;3&#125; className="col-print-6"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;DisplayGroup label="Account Name"
            labelId="accountName"&gt;Adams Family&lt;/DisplayGroup&gt; <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item xs=&#123;12&#125; sm=&#123;6&#125; lg=&#123;4&#125;
            xl=&#123;3&#125; className="col-print-6"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;DisplayGroup label="First Name"
            labelId="firstName"&gt;Adams &lt;/DisplayGroup&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item xs=&#123;12&#125; sm=&#123;6&#125; lg=&#123;4&#125;
            xl=&#123;3&#125; className="col-print-6"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;DisplayGroup label="Last Name"
            labelId="lastName"&gt;Family &lt;/DisplayGroup&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item xs=&#123;12&#125; sm=&#123;6&#125; lg=&#123;4&#125;
            xl=&#123;3&#125; className="col-print-6"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;DisplayGroup label="Account Type"
            labelId="accountType"&gt;- &lt;/DisplayGroup&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &lt;/Grid&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
