import { Error } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { FC, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/skeleton/PrivateRoute';
// @ts-ignore
import { Helmet } from 'react-helmet';
import {
  UserContextHandler,
  UserContext,
  ServiceRoutesContextHandler,
  SearchContextHandler,
  SideNavHandler,
} from './context';
import {
  ServiceRouteDetailPage,
  ServiceDetailPage,
  Login,
  Home,
  NotFound,
  ServiceRoutes,
  ServicesPage,
  Logout,
  RecurringService,
  ForgotPassword,
  ResetPassword,
  CustomersListPage,
  CustomViewPage,
  CustomersDetail,
  OnlinePayments,
  AddEditEstimate,
  BillingPage,
  InvoiceDetailPage,
  ReportPage,
  SearchPage,
  InventoryListPage,
  LeadsPage,
  EstimateListPage,
  EstimatesDetailsPage,
  LeadDetailPage,
  TasksPage,
  MyAccountPage,
  FieldReportPage,
  CalendarPage,
  NewsManagementPage,
  NewsPage,
  TasksDetailPage,
  AlertsPage,
  FeedbackPage,
  FeedbackManagementPage,
  HelpPage,
  CommissionsListPage,
  CommissionDetail,
  BatchesList,
  PostPaymentPage,
  StoreManagement,
  FeedbackItemDetails,
  TransactionBatchPage,
  TransactionPage,
  WelcomePage,
  ContractPage,
  OTSDetailPage,
  OTSLandingPage,
  VerifyAddresses,
  ExternalPaymentPage,
  SetupPage,
} from './pages';
import { theme } from './styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { defaultConfirmOptions, FeatureFlag, Paths, Permissions, ROLES } from './constants';
import { CommissionsListContextHandler } from './context/commissions-context';
import { getLegacyUrl } from './helpers';
import { VersionWrapper } from './context/version-wrapper';
import locale from 'date-fns/locale/en-US';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from './components';
// @ts-ignore
import favicon from './styles/images/favicon.ico';
import { BrandingContextHandler } from './context/branding-context';
import { styleGuideRoutes } from './constants/style-guide-routes';

const { REACT_APP_GA_TRACKING } = require('./buildSettings.json');

declare global {
  interface Window {
    ga: any;
  }
}

const initializeGoogleAnalytics = () => {
  if (!window.ga && REACT_APP_GA_TRACKING) {
    ReactGA.initialize(REACT_APP_GA_TRACKING, {
      gtagOptions: {
        titleCase: false,
      },
    });
  }
};

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // no caching of api calls,
      retry: false, // no retry on failed api calls
      retryOnMount: false, // the query will not be retried on mount if it contains an error
      staleTime: Infinity, // no caching of api calls
      refetchOnMount: false, // the query will not refetch on mount.
      refetchOnWindowFocus: false, // the query will not refetch on window focus.
    },
  },
});
// used to send the logged in user to LD so targeting works
const LDWrapper = ({ children }: { children: any }) => {
  const ldClient = useLDClient();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (user) {
      ldClient?.identify({
        key: user?.loginName?.toLowerCase(),
        custom: {
          store: user?.officeName ?? '',
          storeCode: user?.officeCode ?? '',
          userName: user?.userName ?? '',
          storeId: user?.officeId ?? '',
          loginType: user?.loginType ?? '',
          userType: user?.userType ?? '',
          isOfficeAdmin: user?.isOfficeAdmin ?? false,
        },
      });
    } else {
      ldClient?.identify({ key: 'anonymous', anonymous: true }); // Reset to anonymous flag detection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return children;
};

// Start Calendar Day of Week on Monday, instead of Sunday
if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

export const App: FC = () => {
  useEffect(() => {
    initializeGoogleAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnackbarProvider
      iconVariant={{
        error: <Error style={{ marginRight: '5px' }} />,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={Slide}
      dense={false}
    >
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <QueryClientProvider client={queryClient}>
              <BrandingContextHandler>
                <UserContextHandler>
                  <VersionWrapper>
                    <SearchContextHandler>
                      <SideNavHandler>
                        <ErrorBoundary>
                          <LDWrapper>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={locale}
                            >
                              <ConfirmProvider defaultOptions={defaultConfirmOptions}>
                                <Switch>
                                  {/* Auth */}
                                  <Route exact path="/login" component={Login} />
                                  <Route exact path="/logout" component={Logout} />
                                  <Route exact path="/forgot-password" component={ForgotPassword} />
                                  <Route path="/reset-password" component={ResetPassword} />
                                  <Route path="/welcome" component={WelcomePage} />
                                  <Route
                                    exact
                                    path="/invoices/:transactionId/pay"
                                    component={ExternalPaymentPage}
                                  />
                                  <Route
                                    exact
                                    path="/estimates/:estimateId/agreement"
                                    component={ContractPage}
                                  />
                                  <Route
                                    exact
                                    path="/services/ots/:repairId/agreement"
                                    component={ContractPage}
                                  />
                                  {/* Home */}
                                  <PrivateRoute
                                    exact
                                    path="/"
                                    featureFlag={FeatureFlag.v2HomePage}
                                    legacyUrl={`${getLegacyUrl()}/Office/Home`}
                                    role={ROLES.Office}
                                  >
                                    <Home />
                                  </PrivateRoute>
                                  {/* Routes */}
                                  <PrivateRoute
                                    exact
                                    path="/routes"
                                    featureFlag={FeatureFlag.v2Routes}
                                    legacyUrl={Paths.routes.legacyUrl}
                                    permission={Permissions.ViewRoutes}
                                  >
                                    <ServiceRoutesContextHandler>
                                      <ServiceRoutes />
                                    </ServiceRoutesContextHandler>
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/routes/:serviceRouteId"
                                    featureFlag={FeatureFlag.v2Routes}
                                    legacyUrl={Paths.routes.legacyUrl}
                                    permission={Permissions.ViewRoutes}
                                  >
                                    <ServiceRoutesContextHandler>
                                      <ServiceRouteDetailPage />
                                    </ServiceRoutesContextHandler>
                                  </PrivateRoute>
                                  {/* Services */}
                                  <PrivateRoute
                                    exact
                                    path="/services/maintenance"
                                    featureFlag={FeatureFlag.v2Services}
                                    legacyUrl={Paths.maintenance.legacyUrl}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <ServicesPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/services/field-reports"
                                    featureFlag={FeatureFlag.v2FieldReport}
                                    legacyUrl={Paths.fieldReport.legacyUrl}
                                    permission={Permissions.ViewPayments}
                                  >
                                    <FieldReportPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/services/maintenance/:serviceId"
                                    featureFlag={FeatureFlag.v2Services}
                                    legacyUrl={Paths.maintenance.legacyUrl}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <ServiceDetailPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/services/maintenance/recurring/:recurringId"
                                    featureFlag={FeatureFlag.v2Services}
                                    legacyUrl={Paths.maintenance.legacyUrl}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <RecurringService />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/services/ots"
                                    featureFlag={FeatureFlag.v2Ots}
                                    legacyUrl={Paths.ots.legacyUrl}
                                  >
                                    <OTSLandingPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/services/ots/:repairId"
                                    featureFlag={FeatureFlag.v2Ots}
                                    legacyUrl={Paths.ots.legacyUrl}
                                  >
                                    <OTSDetailPage />
                                  </PrivateRoute>
                                  {/* Customers */}
                                  <PrivateRoute
                                    exact
                                    path="/customers"
                                    featureFlag={FeatureFlag.v2Customers}
                                    legacyUrl={Paths.customers.legacyUrl}
                                    permission={Permissions.ViewCustomers}
                                  >
                                    <CustomersListPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/customers/:paramsAccountId"
                                    featureFlag={FeatureFlag.v2Customers}
                                    legacyUrl={Paths.customers.legacyUrl}
                                    permission={Permissions.ViewCustomers}
                                  >
                                    <CustomersDetail />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/customers/:paramsAccountId/edit"
                                    featureFlag={FeatureFlag.v2Customers}
                                    legacyUrl={Paths.customers.legacyUrl}
                                    permission={Permissions.EditAccountInfo}
                                  >
                                    <CustomersDetail isEditMode={true} />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/customers/view/:viewId"
                                    featureFlag={FeatureFlag.v2Customers}
                                    legacyUrl={Paths.customers.legacyUrl}
                                    permission={Permissions.ViewCustomers}
                                  >
                                    <CustomViewPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/customers/:accountId/estimates/:estimateId"
                                  >
                                    <AddEditEstimate />
                                  </PrivateRoute>
                                  {/* Payments */}
                                  <PrivateRoute
                                    exact
                                    path={Paths.payments.url}
                                    legacyUrl={Paths.payments.legacyUrl}
                                    featureFlag={FeatureFlag.v2Payments}
                                    permission={Permissions.ViewPayments}
                                  >
                                    <BatchesList />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path={`${Paths.payments.url}/online/:transactionBatchId`}
                                    legacyUrl={Paths.payments.legacyUrl}
                                    featureFlag={FeatureFlag.v2Payments}
                                    permission={Permissions.ViewPayments}
                                  >
                                    <TransactionBatchPage />
                                  </PrivateRoute>

                                  <PrivateRoute
                                    exact
                                    path={`${Paths.payments.url}/payment/:transactionBatchId`}
                                    legacyUrl={Paths.payments.legacyUrl}
                                    featureFlag={FeatureFlag.v2Payments}
                                    permission={Permissions.ViewPayments}
                                  >
                                    <PostPaymentPage />
                                  </PrivateRoute>
                                  <PrivateRoute exact path="/online-payments/:accountId">
                                    <OnlinePayments />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/online-payments/:accountId/recurring/new"
                                  >
                                    <OnlinePayments isNewPayment isRecurring />
                                  </PrivateRoute>
                                  {/* Billing */}
                                  <PrivateRoute
                                    exact
                                    path={Paths.billing.url}
                                    legacyUrl={Paths.billing.legacyUrl}
                                    featureFlag={FeatureFlag.v2Billing}
                                    permission={Permissions.ViewBilling}
                                  >
                                    <BillingPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path={`${Paths.billing.url}/invoices/:invoiceId`}
                                    legacyUrl={Paths.billing.legacyUrl}
                                    featureFlag={FeatureFlag.v2Billing}
                                    permission={Permissions.ViewBilling}
                                  >
                                    <InvoiceDetailPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path={`${Paths.billing.url}/transactions/:transactionId`}
                                    featureFlag={FeatureFlag.v2Billing}
                                    permission={Permissions.ViewBilling}
                                  >
                                    <TransactionPage />
                                  </PrivateRoute>
                                  {/* Leads */}
                                  <PrivateRoute
                                    exact
                                    path="/leads"
                                    featureFlag={FeatureFlag.v2Leads}
                                  >
                                    <LeadsPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/leads/:leadId"
                                    featureFlag={FeatureFlag.v2Leads}
                                  >
                                    <LeadDetailPage />
                                  </PrivateRoute>
                                  {/* Reports */}
                                  <PrivateRoute
                                    exact
                                    path={Paths.reports.url}
                                    permission={Permissions.ViewReports}
                                    featureFlag={FeatureFlag.v2Reports}
                                  >
                                    <ReportPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path={Paths.inventory.url}
                                    permission={Permissions.Inventory}
                                    legacyUrl={Paths.inventory.legacyUrl}
                                    featureFlag={FeatureFlag.v2InventoryReport}
                                  >
                                    <InventoryListPage />
                                  </PrivateRoute>
                                  {/* Search */}
                                  <PrivateRoute
                                    exact
                                    path="/search"
                                    featureFlag={FeatureFlag.v2Customers}
                                  >
                                    <SearchPage />
                                  </PrivateRoute>
                                  {/* Sales - Estimates */}
                                  <PrivateRoute
                                    exact
                                    path="/estimates"
                                    permission={Permissions.ViewEstimates}
                                    featureFlag={FeatureFlag.v2Ots}
                                  >
                                    <EstimateListPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/estimates/:estimateId"
                                    permission={Permissions.ViewEstimates}
                                    featureFlag={FeatureFlag.v2Ots}
                                  >
                                    <EstimatesDetailsPage />
                                  </PrivateRoute>
                                  {/* Tasks */}
                                  <PrivateRoute
                                    exact
                                    path="/scheduling/tasks"
                                    legacyUrl={Paths.tasks.legacyUrl}
                                    featureFlag={FeatureFlag.v2Scheduling}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <TasksPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/scheduling/tasks/:taskId"
                                    legacyUrl={Paths.tasks.legacyUrl}
                                    featureFlag={FeatureFlag.v2Scheduling}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <TasksDetailPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/scheduling/calendar"
                                    legacyUrl={Paths.calendar.legacyUrl}
                                    featureFlag={FeatureFlag.v2Scheduling}
                                    permission={Permissions.ViewScheduledServices}
                                  >
                                    <CalendarPage />
                                  </PrivateRoute>
                                  {/* My Account */}
                                  <PrivateRoute
                                    exact
                                    path="/my-account"
                                    featureFlag={FeatureFlag.v2AncillaryFeatures}
                                  >
                                    <MyAccountPage />
                                  </PrivateRoute>
                                  {/** Setup */}
                                  <PrivateRoute path="/setup">
                                    <SetupPage />
                                  </PrivateRoute>

                                  {/** Admin */}
                                  <PrivateRoute
                                    exact
                                    path="/admin/news-management"
                                    role={ROLES.SuperAdmin}
                                  >
                                    <NewsManagementPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/admin/store-management"
                                    role={ROLES.SuperAdmin}
                                  >
                                    <StoreManagement />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/admin/feedback-management"
                                    role={ROLES.SuperAdmin}
                                  >
                                    <FeedbackManagementPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/admin/feedback-management/feedback/:feedbackId"
                                    role={ROLES.SuperAdmin}
                                  >
                                    <FeedbackItemDetails />
                                  </PrivateRoute>

                                  {/** News */}
                                  <PrivateRoute
                                    exact
                                    path="/news"
                                    featureFlag={FeatureFlag.v2AncillaryFeatures}
                                  >
                                    <NewsPage />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/alerts"
                                    featureFlag={FeatureFlag.v2AncillaryFeatures}
                                  >
                                    <AlertsPage />
                                  </PrivateRoute>
                                  {/** Feedback */}
                                  <PrivateRoute
                                    exact
                                    path="/feedback"
                                    featureFlag={FeatureFlag.v2AncillaryFeatures}
                                  >
                                    <FeedbackPage />
                                  </PrivateRoute>
                                  {/** Commissions */}
                                  <PrivateRoute
                                    exact
                                    path="/commissions"
                                    legacyUrl={Paths.commissions.legacyUrl}
                                    featureFlag={FeatureFlag.v2Commissions}
                                    permission={Permissions.EditViewCommissions}
                                  >
                                    <CommissionsListContextHandler>
                                      <CommissionsListPage />
                                    </CommissionsListContextHandler>
                                  </PrivateRoute>
                                  <PrivateRoute
                                    exact
                                    path="/commissions/:commissionId"
                                    legacyUrl={Paths.commissions.legacyUrl}
                                    featureFlag={FeatureFlag.v2Commissions}
                                    permission={Permissions.EditViewCommissions}
                                  >
                                    <CommissionsListContextHandler>
                                      <CommissionDetail />
                                    </CommissionsListContextHandler>
                                  </PrivateRoute>
                                  {/** Help */}
                                  <PrivateRoute
                                    exact
                                    path="/help"
                                    featureFlag={FeatureFlag.v2AncillaryFeatures}
                                  >
                                    <HelpPage />
                                  </PrivateRoute>
                                  {/* Verify Addresses */}
                                  <PrivateRoute
                                    exact
                                    path={Paths.verifyAddresses.url}
                                    featureFlag={FeatureFlag.v2Customers}
                                    legacyUrl={Paths.verifyAddresses.legacyUrl}
                                  >
                                    <VerifyAddresses />
                                  </PrivateRoute>
                                  {/** Kitchen Sink */}
                                  {styleGuideRoutes?.map((route, index) => (
                                    <PrivateRoute
                                      key={index}
                                      exact
                                      path={route.path}
                                      role={ROLES.SuperAdmin}
                                    >
                                      {route.child}
                                    </PrivateRoute>
                                  ))}
                                  {/* Redirects */}
                                  <Redirect
                                    from="/services/recurring"
                                    to="/services/maintenance/recurring"
                                  />
                                  <Redirect
                                    from="/services/:serviceId"
                                    to="/services/maintenance/:serviceId"
                                  />
                                  <Redirect from="/services" to="/services/maintenance" />
                                  <Redirect
                                    from="/recurring-services/:recurringId"
                                    to="/services/maintenance/recurring/:recurringId"
                                  />
                                  <Redirect from="/recurring-services" to="/services/maintenance" />
                                  <Redirect from="/payments/:path" to="/payments" />
                                  <Route exact component={NotFound} />
                                </Switch>
                              </ConfirmProvider>
                            </LocalizationProvider>
                          </LDWrapper>
                        </ErrorBoundary>
                      </SideNavHandler>
                    </SearchContextHandler>
                  </VersionWrapper>
                </UserContextHandler>
              </BrandingContextHandler>
            </QueryClientProvider>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
};
