import { Page } from '../../components';
import { ReportDetails } from './report-details';

export const ReportPage = () => {
  return (
    <Page title="Reports">
      <ReportDetails />
    </Page>
  );
};
