import {
  faBroom,
  faFlask,
  faWrench,
  faExclamationCircle,
  faExternalLink,
  faLocationPin,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragIndicator, Lock } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Typography,
  Checkbox,
  useTheme,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Stack,
} from '@mui/material';
import { Theme, darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState, useEffect } from 'react';
import { ICalendarView, IRouteUpdateMode, IService } from '../../../models';
import { isRouteStartOrEnd } from '../utils';
import { InvalidLocationIcon } from '../../../components';
import { formatAddressBlock, formatDate } from '../../../helpers';
import { addDays, subDays } from 'date-fns';
import clsx from 'clsx';

const GHOSTING_ICON_COLOR = '#949494';

interface ISortableItem {
  service: IService;
  condense?: boolean;
  draggableId?: string;
  handleSelection?: (e: any, checked: boolean, val: string, techServices: string[]) => void;
  selectedDraggableIds?: string[];
  isDragging?: boolean;
  activeDraggableId?: string | null;
  saving?: boolean;
  isLocked?: boolean;
  hasChanged?: boolean;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showIndex?: boolean;
  indexStyle?: 'avatar' | 'inline';
  index: number;
  techColor?: string;
  allowOptimization?: boolean;
  onOptimizationClick?: () => unknown;
  updateMode?: IRouteUpdateMode;
  handleSiteChange?: () => void;
  colorizeSiteIndex?: boolean;
  view?: ICalendarView;
  serviceDate: string;
  techServices?: string[];
}

const CONTEXT_MENU_X_OFFSET = 2;
const CONTEXT_MENU_Y_OFFSET = -6;

export const SortableItem: FC<ISortableItem> = ({
  service,
  condense = false,
  draggableId = '',
  handleSelection,
  selectedDraggableIds,
  isDragging = false,
  activeDraggableId,
  saving = false,
  isLocked = false,
  hasChanged,
  readonly,
  allowMultiDrag = true,
  showIndex = false,
  indexStyle = 'avatar',
  index,
  techColor,
  allowOptimization = true,
  onOptimizationClick,
  handleSiteChange,
  colorizeSiteIndex,
  view = ICalendarView.Day,
  serviceDate,
  techServices,
}) => {
  const classes = useStyles({ condense, isDragging, isLocked });
  const theme = useTheme();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [currentTechServices, setCurrentTechServices] = useState<string[]>([]);

  // used for showing the number of items you are moving at the same time
  const shouldShowSelection: boolean =
    // @ts-ignore
    isDragging && selectedDraggableIds?.length > 1 && activeDraggableId === draggableId;
  // used for when you selected multiple services and are dragging two a new tech
  const isGhosting: boolean =
    isSelected && activeDraggableId && activeDraggableId !== draggableId ? true : false;
  const isStartOrEnd = isRouteStartOrEnd(service);

  const isDraggable = !isStartOrEnd && !readonly && !isLocked && !isGhosting && !service.isClosed;

  let isRecurring = !!service.recurringServiceId;
  const recurringStartDate = formatDate(service?.recurringServiceStartDate);
  const recurringEndDate = formatDate(service?.recurringServiceEndDate);

  let backgroundColor = 'rgba(0, 188, 231, .25)';
  if (service.isClosed) {
    backgroundColor = theme.palette.grey[300];
  }
  if (isStartOrEnd) {
    backgroundColor = 'transparent';
  } else if (isGhosting) {
    backgroundColor = '#e1e1e1';
  } else if (isDragging) {
    backgroundColor = '#B7E6F1';
  }

  if (hasChanged) {
    backgroundColor = theme.palette.changed.main;
  }

  if (isSelected) {
    backgroundColor = darken(backgroundColor, 0.25);
  }

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + CONTEXT_MENU_X_OFFSET,
            mouseY: event.clientY + CONTEXT_MENU_Y_OFFSET,
          }
        : null
    );
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    setCurrentTechServices(techServices!);
  }, [techServices]);

  useEffect(() => {
    const isSiteStartOrEnd = isRouteStartOrEnd(service);
    if (!isSiteStartOrEnd) {
      // Check if current serviceId was converted to draggableId.
      const isDraggableId = selectedDraggableIds!.some(ids =>
        ids.includes(service.scheduledServiceId)
      );
      if (isDraggableId) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    } else {
      setIsSelected(false);
    }
  }, [selectedDraggableIds, service]);

  const serviceTitle = (
    <Typography
      className={classes.serviceName}
      display={colorizeSiteIndex ? 'flex' : 'block'}
      alignItems={colorizeSiteIndex ? 'center' : 'unset'}
      component="div"
    >
      {showIndex && indexStyle === 'inline' && !isStartOrEnd && (
        <>
          <Box
            component={colorizeSiteIndex ? 'div' : 'span'}
            sx={{
              ...(colorizeSiteIndex && {
                backgroundColor: `${techColor || '#aaaaaa'}`,
                color: theme.palette.common.white,
                borderRadius: '50%',
                padding: theme.spacing(0.5),
                lineHeight: '2',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
                fontSize: '.75rem',
              }),
              marginRight: theme.spacing(0.5),
            }}
          >
            {`${index}${colorizeSiteIndex ? '' : '.'}`}
          </Box>
        </>
      )}
      {service?.siteName ?? 'No Address'}
    </Typography>
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      key={service.scheduledServiceId}
      onContextMenu={handleContextMenu}
      style={{ cursor: 'context-menu' }}
    >
      <Box
        sx={{
          backgroundColor,
          borderBottom: service.siteName === 'Route Start' ? '1px solid black' : 0,
          borderTop: service.siteName === 'Route End' ? '1px solid black' : 0,
          borderRadius: isStartOrEnd ? 0 : '6px',
          margin: isStartOrEnd ? 0 : '8px 0',
        }}
        className={clsx(
          !service?.latitude || !service?.longitude ? classes.serviceWarning : classes.service,
          `view-${view}`
        )}
      >
        {showIndex && indexStyle === 'avatar' && !isStartOrEnd && (
          <Avatar
            className={classes.serviceIndexBadge}
            sx={{
              border: `2px solid ${techColor || '#aaaaaa'}`,
              backgroundColor: `${techColor || '#aaaaaa'}`,
            }}
          >
            {index}
          </Avatar>
        )}
        {isDraggable && allowMultiDrag && !service.isClosed && (
          <Tooltip title="Select to drag multiple">
            <Checkbox
              checked={isSelected}
              onChange={(e, checked) => {
                handleSelection?.(e, checked, draggableId, currentTechServices);
              }}
              disabled={isGhosting || saving}
              sx={{ padding: '0' }}
            />
          </Tooltip>
        )}
        <Stack sx={{ overflow: 'hidden', flex: 1 }} gap={0.5} className={classes.serviceContent}>
          {!!service?.siteName && !isStartOrEnd ? (
            <Tooltip
              title={
                formatAddressBlock(service?.address, service?.city, service?.state, service?.zip) ??
                service?.siteName
              }
            >
              {serviceTitle}
            </Tooltip>
          ) : (
            serviceTitle
          )}
          {!isStartOrEnd && (
            <>
              <Tooltip title={service?.serviceType}>
                <Typography
                  variant="caption"
                  className={classes.truncateText}
                  sx={{
                    textDecoration: service.isClosed ? 'line-through' : 'none',
                  }}
                >
                  {service?.serviceType}
                </Typography>
              </Tooltip>
              <Box display="flex" alignItems="end">
                <Box flex={1} display="flex" alignItems="center">
                  {isRecurring && (
                    <Tooltip title="This is a recurring service">
                      <span>
                        <FontAwesomeIcon
                          fontSize="small"
                          className={classes.icon}
                          style={{
                            color: isGhosting ? GHOSTING_ICON_COLOR : theme.palette.primary.light,
                          }}
                          icon={faRefresh}
                          size="lg"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {service.showBeakerIcon && (
                    <Tooltip title="Service">
                      <span>
                        <FontAwesomeIcon
                          className={classes.icon}
                          style={{
                            color: isGhosting ? GHOSTING_ICON_COLOR : theme.palette.primary.light,
                          }}
                          icon={faFlask}
                          size="lg"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {service.showBrushIcon && (
                    <Tooltip title="Cleaning">
                      <span>
                        <FontAwesomeIcon
                          className={classes.icon}
                          style={{
                            color: isGhosting ? GHOSTING_ICON_COLOR : theme.palette.primary.light,
                          }}
                          icon={faBroom}
                          size="lg"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {service.showWrenchIcon && (
                    <Tooltip title="Repair">
                      <span>
                        <FontAwesomeIcon
                          className={classes.icon}
                          style={{
                            color: isGhosting ? GHOSTING_ICON_COLOR : theme.palette.primary.light,
                          }}
                          icon={faWrench}
                          size="lg"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {(!service.latitude || !service.longitude) && (
                    <InvalidLocationIcon
                      accountId={service.accountId}
                      siteId={service.siteId}
                      handleSiteChange={handleSiteChange}
                    />
                  )}
                  {!service.isSorted && (
                    <Tooltip title="This service has not been sorted">
                      <span>
                        <IconButton
                          disableRipple={allowOptimization}
                          onClick={() => allowOptimization && onOptimizationClick?.()}
                          component="div"
                          sx={{ padding: 0 }}
                        >
                          <FontAwesomeIcon
                            className={classes.icon}
                            style={{ color: theme.palette.warning.main }}
                            icon={faExclamationCircle}
                            size="lg"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {service?.manualLocation && (
                    <Tooltip title="Manual Location">
                      <span>
                        <FontAwesomeIcon
                          className={classes.icon}
                          style={{ color: theme.palette.info.main }}
                          icon={faLocationPin}
                          size="lg"
                        />
                      </span>
                    </Tooltip>
                  )}
                </Box>
                <Box className={classes.truncateText}>
                  {/* show "NEW" if it's within 30 days of recurring service start date */}
                  {!!recurringStartDate &&
                    !!serviceDate &&
                    new Date(serviceDate) <= addDays(new Date(recurringStartDate), 30) && (
                      <Tooltip title={`New service as of: ${formatDate(recurringStartDate)}`}>
                        <Typography lineHeight={1} variant="caption" fontWeight={'bold'}>
                          NEW
                        </Typography>
                      </Tooltip>
                    )}
                  {/* show "ENDING" if it's within 30 days of recurring service end date */}
                  {!!recurringEndDate &&
                    !!serviceDate &&
                    new Date(serviceDate) >= subDays(new Date(recurringEndDate), 30) && (
                      <Tooltip title={`Service ending: ${formatDate(recurringEndDate)}`}>
                        <Typography
                          lineHeight={1}
                          variant="caption"
                          fontWeight={'bold'}
                          maxWidth={50}
                          marginLeft={1}
                        >
                          ENDING
                        </Typography>
                      </Tooltip>
                    )}
                </Box>
              </Box>
            </>
          )}
        </Stack>
        {isDraggable && (
          <IconButton sx={{ cursor: 'grab', p: '0', justifySelf: 'flex-end' }} disabled={saving}>
            <DragIndicator className={classes.dragIndicator} />
          </IconButton>
        )}
        {isLocked && !readonly && !isStartOrEnd && (
          <Tooltip title={service.lockedReason || 'This service is locked and cannot be reordered'}>
            <span>
              <Lock className={classes.lockIcon} />
            </span>
          </Tooltip>
        )}
        {shouldShowSelection && (
          <Box
            sx={{
              boxShadow: '0px 1px 3px 0',
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              padding: '0 8px',
              borderRadius: '50%',
              background: '#fff',
            }}
          >
            {selectedDraggableIds?.length}
          </Box>
        )}
      </Box>

      <Menu
        open={contextMenu !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
        PaperProps={{ className: classes.contextMenu }}
      >
        <MenuItem
          component="a"
          href={`/services/maintenance/${service.scheduledServiceId}`}
          target="_blank"
          onClick={closeContextMenu}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faExternalLink} size="sm" />
          </ListItemIcon>
          <ListItemText>Open</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { condense: boolean; isDragging: boolean; isLocked: boolean }>(
  theme => {
    return {
      service: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        padding: '8px 5px 8px 5px',
        gap: '3px',
        boxShadow: ({ isDragging }) => (isDragging ? theme.shadows[4] : 'none'),
        cursor: ({ isLocked }) => (isLocked ? 'not-allowed' : 'context-menu'),
        '&&.view-work_week': {
          [theme.breakpoints.between('sm', 'lg')]: {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          },
        },
        '&&.view-week, &&.view-date_range': {
          [theme.breakpoints.between('sm', 'xl')]: {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          },
        },
      },
      serviceWarning: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(211, 47, 47, .25)',
        width: '100%',
        padding: '8px 5px 8px 5px',
      },
      serviceName: {
        fontSize: '0.85rem',
        fontWeight: 'bold',
        lineHeight: 1,
      },
      icon: {
        borderRadius: '50%',
        fontSize: 16,
        marginRight: 5,
      },
      dragIndicator: {
        color: theme.palette.primary.dark,
        '.view-work_week &&': {
          [theme.breakpoints.between('sm', 'lg')]: {
            order: 2,
          },
        },
        '.view-week &&, .view-date_range &&': {
          [theme.breakpoints.between('sm', 'xl')]: {
            order: 2,
          },
        },
      },
      lockIcon: {
        color: theme.palette.grey[700],
      },
      recurringIcon: {
        color: theme.palette.primary.main,
      },
      contextMenu: {
        '& .MuiListItemIcon-root': {
          minWidth: 'auto',
          marginRight: '8px',
        },
      },
      serviceIndexBadge: {
        width: '24px',
        height: '24px',
        fontSize: '16px',
        marginRight: '12px',
      },
      truncateText: {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      serviceContent: {
        '.view-work_week &&': {
          [theme.breakpoints.between('sm', 'lg')]: {
            order: 3,
            flex: '0 0 100%',
          },
        },
        '.view-week &&, .view-date_range &&': {
          [theme.breakpoints.between('sm', 'xl')]: {
            order: 3,
            flex: '0 0 100%',
          },
        },
      },
    };
  }
);
