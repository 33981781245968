import { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
import { Box, Button, Divider, Fade, FormHelperText, Typography } from '@mui/material';
import { Loader } from '../loader';
import { IDocument, IDocumentPUT } from '../../models';
import { DisplayGroup, Modal } from '..';
import { TextField } from '../formikMui';
import { DocumentUpload } from '../file/document-upload';
import { useConfirm } from '../../hooks';
import { SaveButton } from '../../components';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IDocumentModal {
  isOpen: boolean;
  onClose: () => void;
  selectedDocument: IDocument | null;
  isLoading?: boolean;
  reloadList: () => void;
  title: string;
  postApiRequest: (
    id: string | number,
    payload: File | string,
    description?: string
  ) => Promise<any>;
  putApiRequest: (payload: IDocumentPUT, accountDocumentId: string | number) => Promise<any>;
  inputId: string;
  postFilterId: string | number;
}

const DocumentSchema = Yup.object().shape({
  file: Yup.mixed().required('File is required.  Accepted formats include PDF, DOC, DOCX, XLS, XLSX, and TIFF.'),
  description: Yup.string().max(255, 'Max 255 characters').nullable(),
});

export const DocumentModal: FC<IDocumentModal> = ({
  isOpen,
  onClose,
  selectedDocument,
  isLoading,
  reloadList,
  title,
  postApiRequest,
  putApiRequest,
  inputId,
  postFilterId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          file: selectedDocument?.fileLocation ?? '',
          description: selectedDocument?.description ?? '',
        }}
        validationSchema={DocumentSchema}
        onSubmit={async (values, actions) => {
          try {
            selectedDocument
              ? await putApiRequest(
                { description: values.description },
                selectedDocument.accountDocumentId
              )
              : await postApiRequest(postFilterId, values.file, values.description);

            enqueueSnackbar(`Successfully ${!selectedDocument ? 'created' : 'updated'} document!`, {
              variant: 'success',
            });
            onClose();
            reloadList();
            actions.resetForm();
          } catch (err: any) {
            if (err?.data?.Detail) {
              enqueueSnackbar(err.data.Detail, { variant: 'error' });
            } else {
              enqueueSnackbar(JSON.stringify(err), { variant: 'error' });
            }
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          errors,
          touched,
        }) => {
          const reset = async (resetForm: any) => {
            if (!deepEqual(initialValues, values)) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                resetForm();
                onClose();
              } else {
                return;
              }
            } else {
              onClose();
              resetForm();
            }
          };

          return (
            <Modal
              open={isOpen}
              onClose={() => {
                reset(resetForm);
              }}
              maxWidth="sm"
            >
              {isSubmitting && <Loader type="overlay" position="centered" title="Saving..." />}
              <Fade in={isOpen}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mb={2}>
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {selectedDocument ? `Edit ${title}` : `Add ${title}`}
                    </Typography>
                    <Divider />
                  </Box>
                  <div>
                    <Box mb={3}>
                      {selectedDocument ? (
                        <DisplayGroup label="Document File" labelId="document-file">
                          {selectedDocument?.originalFileName}
                        </DisplayGroup>
                      ) : (
                        <>
                          <DocumentUpload
                            id={inputId}
                            label="Document File"
                            handleFileChange={async val => {
                              setFieldValue('file', val.file);
                            }}
                            disabled={isSubmitting}
                            isEditable={!selectedDocument}
                          />
                          {touched && errors?.file && (
                            <FormHelperText error>{errors?.file}</FormHelperText>
                          )}
                        </>
                      )}
                    </Box>
                    <Box>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                        autoComplete="nope"
                        label="Description"
                        name="description"
                        inputProps={{
                          'data-testid': 'description-field'
                        }}
                      />
                    </Box>
                    <Box
                      margin="1rem 0 0"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Box marginRight="1rem">
                        <Button
                          type="button"
                          color="inherit"
                          onClick={() => {
                            reset(resetForm);
                          }}
                          data-testid="cancel-upload-button"
                        >
                          Cancel
                        </Button>
                      </Box>
                      <SaveButton
                        disabled={!dirty || isSubmitting || !isValid}
                        text={selectedDocument ? 'Save' : `Add ${title}`}
                        data-testid="save-upload-button"
                      />
                    </Box>
                  </div>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
