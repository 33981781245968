import { startOfWeek, nextMonday, nextFriday, endOfDay, nextSunday } from 'date-fns';
import { createContext, FC, useState, Dispatch, SetStateAction } from 'react';
import { ITechnician, ICalendarView, ICalendarDateRange } from '../models';
import { useLocalStorage } from 'react-use';

export const ROUTES_DATE_RANGE_VIEW_KEY = 'routes_date_range_view';
export const DEFAULT_ROUTES_DATE_RANGE_VIEW = ICalendarView.WorkWeek;

const getDefaultDateRange = (): ICalendarDateRange => {
  const previouslySelectedView = localStorage.getItem(
    ROUTES_DATE_RANGE_VIEW_KEY
  ) as ICalendarView | null;
  const view = previouslySelectedView || DEFAULT_ROUTES_DATE_RANGE_VIEW;
  const now = new Date();
  switch (view) {
    case ICalendarView.Week:
      const weekStart = endOfDay(nextMonday(startOfWeek(now)));
      const weekEnd = endOfDay(nextSunday(weekStart));
      return { startDate: weekStart, endDate: weekEnd };
    case ICalendarView.WorkWeek:
    default:
      const workWeekStart = endOfDay(nextMonday(startOfWeek(now)));
      const workWeekEnd = endOfDay(nextFriday(workWeekStart));
      return { startDate: workWeekStart, endDate: workWeekEnd };
  }
};

interface IServiceRoutesProps {
  isShowingFilters: boolean;
  setIsShowingFilters: Dispatch<SetStateAction<boolean>>;
  selectedTechs: ITechnician[];
  setSelectedTechs: Dispatch<SetStateAction<ITechnician[]>>;
  selectedDateRange?: ICalendarDateRange;
  setSelectedDateRange: Dispatch<SetStateAction<ICalendarDateRange | undefined>>;
  view?: ICalendarView;
  setView: Dispatch<SetStateAction<ICalendarView | undefined>>;
  isSingleViewMode: boolean;
  setIsSingleViewMode: Dispatch<SetStateAction<boolean>>;
}

export const ServiceRoutesContext = createContext<IServiceRoutesProps>({
  isShowingFilters: false,
  setIsShowingFilters: () => {},
  selectedTechs: [],
  setSelectedTechs: () => {},
  selectedDateRange: getDefaultDateRange(),
  setSelectedDateRange: () => {},
  view: ICalendarView.WorkWeek,
  isSingleViewMode: false,
  setIsSingleViewMode: () => {},
  setView: () => {},
});

interface IServiceRoutesContextHandlerProps {}

export const ServiceRoutesContextHandler: FC<IServiceRoutesContextHandlerProps> = ({
  children,
}): JSX.Element => {
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [selectedTechs, setSelectedTechs] = useState<ITechnician[]>([]);
  const [isSingleViewMode, setIsSingleViewMode] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>(
    getDefaultDateRange()
  );
  const [view, setView] = useLocalStorage<ICalendarView>(
    ROUTES_DATE_RANGE_VIEW_KEY,
    DEFAULT_ROUTES_DATE_RANGE_VIEW,
    { raw: true }
  );

  return (
    <ServiceRoutesContext.Provider
      value={{
        isShowingFilters,
        setIsShowingFilters,
        selectedTechs,
        setSelectedTechs,
        selectedDateRange,
        setSelectedDateRange,
        isSingleViewMode,
        setIsSingleViewMode,
        view: view || ICalendarView.WorkWeek,
        setView,
      }}
    >
      {children}
    </ServiceRoutesContext.Provider>
  );
};
