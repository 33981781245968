import {
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  CardTitle,
  Loader,
  Card,
} from '../../../components';
import { Box, Button, IconButton } from '@mui/material';
import { useMemo, useCallback, useState, useContext } from 'react';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { faTrash, faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getUserGroups, deleteUserGroup } from '../../../fetch';
import { IUserGroup } from '../../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { EditUserGroupModal } from './edit-user-group-modal';
import { EditUsersInGroupModal } from './edit-users-in-group-modal';
import { EditPermissionsInGroupModal } from './edit-permissions-in-group-modal';
import { GroupsKebabMenu } from './groups-kabab-menu';
import { BrandingContext } from '../../../context/branding-context';

export const UserGroupsTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const [currentUserGroup, setCurrentUserGroup] = useState<IUserGroup | null>(null);
  const [isShowingUsersModal, setIsShowingUsersModal] = useState<boolean>(false);
  const [isShowingPermissionsModal, setIsShowingPermissionsModal] = useState<boolean>(false);
  const [isShowingEditModal, setIsShowingEditModal] = useState<boolean>(false);
  const [isDeletingUserGroup, setIsDeletingUserGroup] = useState<boolean>(false);

  const dataFetcher: GridDataFetcher<IUserGroup, number> = useCallback(
    async ({ perPage, sortColumn, sortDirection, beforeItemId, afterItemId }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'desc',
          perPage,
          officeId: user?.officeId as string,
        };

        if (beforeItemId) {
          options.before = beforeItemId;
        }

        if (afterItemId) {
          options.after = afterItemId;
        }

        const res = await getUserGroups(options);
        return {
          rows: res.data,
          rowCount: res.totalCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading user groups, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchUserGroups,
  } = useDataGrid<IUserGroup, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'user-group-grid',
      sortColumn: 'userGroupName',
      sortDirection: 'desc',
    },
    keysetPagingKey: 'userGroupId',
    dataFetcher,
  });
  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'userGroupName',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: '',
        renderCell: (params: GridRenderCellParams<IUserGroup>) => {
          return (
            <Box>
              <IconButton
                color="primary"
                title="Edit user group"
                sx={{ marginRight: theme => theme.spacing(1) }}
                onClick={() => {
                  setCurrentUserGroup(params.row);
                  setIsShowingPermissionsModal(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              {!isPoolService && (
                <IconButton
                  color="error"
                  title="Delete user group"
                  onClick={async () => {
                    try {
                      const result = await confirm(
                        'Are you sure you want to delete this user group?'
                      );
                      if (result) {
                        setIsDeletingUserGroup(true);
                        await deleteUserGroup(params.row.userGroupId);
                        enqueueSnackbar(`User Group Deleted!`, {
                          variant: 'success',
                        });
                        fetchUserGroups();
                      }
                    } catch (error: any) {
                      enqueueSnackbar(
                        error?.Detail ?? `Error deleting user group, please try again.`,
                        {
                          variant: 'error',
                        }
                      );
                    } finally {
                      setIsDeletingUserGroup(false);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
      !isPoolService && {
        field: 'actions2',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: '',
        renderCell: (params: GridRenderCellParams<IUserGroup>) => {
          return (
            <GroupsKebabMenu
              setIsShowingUsersModal={setIsShowingUsersModal}
              setCurrentUserGroup={setCurrentUserGroup}
              row={params.row}
              setIsShowingEditModal={setIsShowingEditModal}
            />
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
  }, [
    fetchUserGroups,
    setCurrentUserGroup,
    setIsShowingUsersModal,
    setIsShowingEditModal,
    confirm,
    enqueueSnackbar,
    isPoolService,
  ]);

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          {isDeletingUserGroup && <Loader type="overlay" position="centered" />}
          {!isPoolService && (
            <CardTitle
              action={
                <>
                  <Button
                    onClick={() => {
                      setIsShowingEditModal(true);
                    }}
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  >
                    Add User Group
                  </Button>
                </>
              }
            />
          )}
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: IUserGroup) => row.userGroupId}
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            sortModel={sortModel}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
          />
        </Card>
      </Box>
      <EditUserGroupModal
        isOpen={isShowingEditModal}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            fetchUserGroups();
          }
          setIsShowingEditModal(false);
          setCurrentUserGroup(null);
        }}
        currentUserGroup={currentUserGroup}
      />
      <EditUsersInGroupModal
        isOpen={isShowingUsersModal}
        onClose={() => {
          setIsShowingUsersModal(false);
          setCurrentUserGroup(null);
        }}
        currentUserGroup={currentUserGroup}
      />
      <EditPermissionsInGroupModal
        isOpen={isShowingPermissionsModal}
        onClose={() => {
          setIsShowingPermissionsModal(false);
          setCurrentUserGroup(null);
        }}
        currentUserGroup={currentUserGroup}
      />
    </>
  );
};
