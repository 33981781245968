import { FC } from 'react';
import { Page } from '../../../components';
import { FeedbackManagementTable } from './feedback-management-table';
import { Box } from '@mui/material';

export const FeedbackManagementPage: FC = () => {
  return (
    <Page title="Feedback Management">
      <Box marginTop="1rem">
        <FeedbackManagementTable />
      </Box>
    </Page>
  );
};
