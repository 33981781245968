import { TextField } from '@mui/material';
import { FC } from 'react';
import { IParameterInputProps } from '../../../../models';

export const TextareaParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const { parameterName, promptText } = parameter;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value, parameter);
  };

  return (
    <TextField
      multiline
      fullWidth
      rows={4}
      size="small"
      label={promptText}
      name={parameterName}
      value={value}
      onChange={handleChange}
    />
  );
};
