import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC } from 'react';
import { Theme } from '@mui/material/styles';

interface IExternalLink {
  className?: string;
  to: string;
  type?: 'primary' | 'white' | 'secondary';
  target?: '_self' | '_blank' | '_parent' | '_top';
  title?: string;
}

export const ExternalLink: FC<IExternalLink> = ({
  to,
  children,
  className,
  type = 'primary',
  target,
  title,
}) => {
  const classes = useStyles();
  return (
    <a
      href={to}
      rel="noopener noreferrer"
      target={target}
      className={clsx(classes[type], className)}
      title={title}
    >
      {children}
    </a>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  white: {
    color: theme.palette.background.paper,
    textDecoration: 'none',
  },
  primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));
