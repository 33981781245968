import { FC } from 'react';
import { AutocompleteAsync, SelectAsync, TextField, Card } from '../../../components';
import { Box, Stack } from '@mui/material';
import { getPoolEquipmentByType } from '../../../fetch/sites';
import { IEquipmentLookup } from '../../../models/sites';
import { useFormikContext } from 'formik';

interface IStructureEquipmentFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

const transformResponse = (response: IEquipmentLookup[]) =>  
  response.map(item => item.name === 'None' ? 'None' : `${item.manufacturer} - ${item.name}`);

export const StructureEquipmentForm: FC<IStructureEquipmentFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the StructureEquipmentDisplay component as well
  const { handleBlur } = useFormikContext();
  return (
    <Box mt={2}>
      <Card>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <AutocompleteAsync
              name="filter"
              label="Filter"
              apiRequest={() => getPoolEquipmentByType('filters')}
              transformResponse={transformResponse}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              name="filterPressure"
              label="Filter Pressure"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <AutocompleteAsync
              name="poolPump"
              label="Pool Pump"
              apiRequest={() => getPoolEquipmentByType('pool-pumps')}
              transformResponse={transformResponse}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              name="timerBrandModel"
              label="Timer Brand/Model"
              inputProps={{
                readOnly,
              }}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <SelectAsync
              name="heater"
              label="Heater"
              apiRequest={() => getPoolEquipmentByType('heaters')}
              transformResponse={(response: IEquipmentLookup[]) =>
                response.map(item => ({ label: item.name, value: item.name }))
              }
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              name="otherEquipment"
              label="Other Equipment"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <TextField
              name="automaticControls"
              label="Automatic Controls"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              name="waterFeature"
              label="Water Feature (Describe)"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <TextField
              name="backwash"
              label="Backwash"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              name="piping"
              label="Piping"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
          <Box flex={1}>
            <AutocompleteAsync
              name="automaticChlorinator"
              label="Automatic Chlorinator"
              apiRequest={() => getPoolEquipmentByType('automatic-chlorinators')}
              transformResponse={transformResponse}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box flex={1} />
        </Stack>
      </Card>
    </Box>
  );
};
