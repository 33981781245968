import { FC, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import {
  CardFiltersLayout,
  CardTitle,
  FilterForm,
  GridDataFetcher,
  ServerSideDataGrid,
  useDataGrid,
  useFilters,
  Card,
} from '../../components';
import { IFilterLayout, ILead } from '../../models';
import { getLeads, getLeadsFilters } from '../../fetch';
import { leadsColumns } from './leads-columns';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const filtersLayout: Record<string, IFilterLayout> = {
  Lead: {
    sortOrder: 0,
    xs: 12,
    md: 6,
    lg: 5,
  },
  Statuses: {
    sortOrder: 1,
    xs: 12,
    md: 6,
    lg: 3,
  },
  ServiceType: {
    sortOrder: 2,
    xs: 12,
    md: 6,
    lg: 4,
  },
  CustomerSearch: {
    sortOrder: 3,
    xs: 12,
    md: 6,
  },
  Source: {
    sortOrder: 4,
    xs: 12,
    md: 6,
  },
  UserId: {
    sortOrder: 5,
    xs: 12,
    md: 6,
  },
  DescriptionSearch: {
    sortOrder: 6,
    xs: 12,
    lg: 6,
  },
};

export const LeadsGrid: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getLeadsFilters(), []),
  });

  const dataFetcher: GridDataFetcher<ILead> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        if (!filtersInitialized) {
          return {
            continueLoading: true,
          };
        }
        const params = {
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'date',
          perPage,
          page: page + 1,
          ...appliedFilters,
          leadStartDate: appliedFilters?.Lead?.[0] ?? undefined,
          leadEndDate: appliedFilters?.Lead?.[1] ?? undefined,
        };

        const res = await getLeads(params);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading leads, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<ILead>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'leads-grid',
      sortColumn: 'date',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        title="In-Progress Leads"
        action={
          <Button
            onClick={onFilterToggle}
            startIcon={<FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />}
            className={clsx('print--none')}
            color="secondary"
            size="small"
            disabled={isLoading}
          >
            Filters
          </Button>
        }
      />
      <CardFiltersLayout isOpen={isShowingFilters}>
        <FilterForm
          filters={filters}
          values={filterValues}
          filterLayouts={filtersLayout}
          defaultLayout={{ xs: 12 }}
          onSubmit={values => {
            onPageChange(0);
            onSubmitFilters(values);
          }}
          onChange={onFiltersChange}
          isSubmitting={isLoading}
          onReset={() =>
            onReset({
              Statuses: ['Pending', 'Won', 'Lost', 'Reassigned'],
            })
          }
        />
      </CardFiltersLayout>
      <ServerSideDataGrid
        disableColumnFilter
        loading={isLoading}
        disableColumnSelector
        autoHeight
        getRowId={(row: ILead) => row.leadId}
        rows={rows}
        columns={leadsColumns}
        sortModel={sortModel}
        page={page}
        pageSize={perPage}
        rowCount={recordCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
      />
    </Card>
  );
};
