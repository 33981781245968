import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { IconPageHeading } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideIconPageHeading = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const [currentCustomer] = useState<{
    accountId: string;
    name: string;
    firstName: string;
    lastName: string;
  }>({
    accountId: '980357b3-1fb4-48d2-b3cb-0be9db864af5',
    name: 'Adams Family',
    firstName: 'Adams',
    lastName: 'Family',
  });

  return (
    <StyleGuidePage title="Icon Page Heading">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Icon Page Heading component is meant to act as a sub-page title with the option to
            display a single breadcrumb with a back button.
          </Typography>
          <Typography mb={1}>
            This component is used for most related / child pages to allow the user to navigate to
            the related / parent page.
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- icon?: IconDefinition
                <br />- heading: string
                <br />- breadcrumb?: IBreadcrumb
                <br />- isCustomer?: boolean
                <br />- currentCustomer?: IAccountDetail
                <br />- accountId?: string <br />- showDivider?: boolean
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                Breadcrumb Props:
                <br />- icon?: IconDefinition
                <br />- text: string
                <br />- title?: string
                <br />- link?: string
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={1}>
            <IconPageHeading showDivider heading="Custom Page Heading" />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;IconPageHeading showDivider heading="Custom Page Heading" /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <IconPageHeading
              showDivider
              heading="Custom Page Heading"
              breadcrumb={{
                text: 'Parent Heading',
              }}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;IconPageHeading <br />
            &nbsp;&nbsp;&nbsp;showDivider <br />
            &nbsp;&nbsp;&nbsp;heading="Custom Page Heading" <br />
            &nbsp;&nbsp;&nbsp;breadcrumb= &#123;&#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;text: 'Parent Heading', <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <IconPageHeading
              showDivider
              heading="Custom Page Heading"
              breadcrumb={{
                text: 'Parent Link',
                title: 'Back to Custom Page',
                link: '/',
              }}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;IconPageHeading <br />
            &nbsp;&nbsp;&nbsp;showDivider <br />
            &nbsp;&nbsp;&nbsp;heading="Custom Page Heading" <br />
            &nbsp;&nbsp;&nbsp;breadcrumb= &#123;&#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;text: 'Parent Link', <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Back to Custom Page', <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;link: `/`, <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <IconPageHeading
              showDivider
              heading="Custom Page Heading"
              icon={faEdit}
              breadcrumb={{
                text: 'Parent Link',
                title: 'Back to Custom Page',
                link: '/',
              }}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;IconPageHeading <br />
            &nbsp;&nbsp;&nbsp;showDivider <br />
            &nbsp;&nbsp;&nbsp;heading="Custom Page Heading" <br />
            &nbsp;&nbsp;&nbsp;icon=&#123;faEdit&#125; <br />
            &nbsp;&nbsp;&nbsp;breadcrumb= &#123;&#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;text: 'Parent Link', <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Back to Custom Page', <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;link: `/`, <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon: faWpforms, <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <IconPageHeading
              showDivider
              isCustomer
              heading="Custom Page Heading"
              icon={faEdit}
              breadcrumb={
                Boolean(currentCustomer?.accountId)
                  ? {
                      text:
                        currentCustomer?.name ||
                        `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
                      title: 'Back to Customer Detail',
                      link: `/customers/${currentCustomer?.accountId}`,
                    }
                  : undefined
              }
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;IconPageHeading <br />
            &nbsp;&nbsp;&nbsp;showDivider <br />
            &nbsp;&nbsp;&nbsp;isCustomer <br />
            &nbsp;&nbsp;&nbsp;heading="Custom Page Heading" <br />
            &nbsp;&nbsp;&nbsp;icon=&#123;faEdit&#125; <br />
            &nbsp;&nbsp;&nbsp;breadcrumb= &#123;&#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Boolean(currentCustomer?.accountId) ? &#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;text: currentCustomer?.name ||
            `$&#123;currentCustomer?.firstName&#125; $&#123;currentCustomer?.lastName&#125;`, <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Back to Customer Detail', <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;link:
            `/customers/$&#123;currentCustomer?.accountId&#125;`, <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125; : undefined <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
