import { axios } from '../helpers';
import {
  ICommunicationSendPost
} from '../models';
import { userSignal } from '../signals';

export const sendCommunication = async (
  payload: ICommunicationSendPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/communications/send`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};