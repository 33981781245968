export const styleGuideClientName = 'PCP';

export const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const handleCopyClick = (
  setCopy: (val: { [x: string]: boolean }) => void,
  copyText: string
) => {
  // Asynchronously call copyTextToClipboard
  copyTextToClipboard(copyText)
    .then(() => {
      // If successful, update the isCopied state value
      setCopy({ [copyText]: true });
      setTimeout(() => {
        setCopy({ [copyText]: false });
      }, 1500);
    })
    .catch(err => {
      console.log(err);
    });
};
