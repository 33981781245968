import { useCallback, useContext } from 'react';
import { Card, GridDataFetcher, useDataGrid } from '../../components';
import { IInvoice } from '../../models';
import { getInvoices } from '../../fetch';
import { UserContext } from '../../context/user';
import { useSnackbar } from 'notistack';
import { PendingInvoicesDataGrid } from './pending-invoices-data-grid';

export const PendingInvoices = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const dataFetcher: GridDataFetcher<IInvoice> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getInvoices({
          page: page + 1,
          perPage,
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          transactionType: 'Invoice',
          officeId: user?.officeId,
          isProcessed: false,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading pending invoices, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageSizeChange,
    onSortModelChange,
    onPageChange,
  } = useDataGrid<IInvoice>({
    initialOptions: {
      page: 0,
      pageSize: 25,
      sortColumn: 'invoiceDate',
      sortDirection: 'desc',
      gridKeyName: 'pending-invoices-grid',
    },
    dataFetcher,
  });

  return (
    <Card
      cardTitleProps={{
        title: 'Pending Invoices',
      }}
    >
      <PendingInvoicesDataGrid
        rows={rows}
        loading={isLoading}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        sortModel={sortModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
      />
    </Card>
  );
};
