import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { styleGuideClientName } from './constants';

export const KitchenSink: FC = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={9}>
          <Box mb={1}>
            <Typography mb={2}>
              Welcome to the {styleGuideClientName} Kitchen Sink, a library of documentation for all
              custom (non-MUI) components and UI patterns. {styleGuideClientName} is built upon the{' '}
              <Link href="https://mui.com/" target="_blank">
                Material UI
              </Link>{' '}
              library, including a custom theme and a foundation using MUI components. Explore the
              component library using the navigation for examples of common components and their
              props.
            </Typography>
          </Box>
          <Box mb={1}>
            <Alert severity="info">
              NOTE: This is a living style guide and is subject to grow and change. Please remember
              to update the style guide when adding new components or updating props.
            </Alert>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ margin: theme => theme.spacing(1, 0, 2) }} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <Typography>
            Below is a visual representation of the {styleGuideClientName} MUI theme using{' '}
            <strong>MUI default components</strong>, including default theme settings for typography
            and colors. This is meant to act as a visual aid when making updates to the{' '}
            {styleGuideClientName} MUI Theme files. This is not meant to represent the final
            appearance of any of the components displayed.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h2" color="primary" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" color="primary" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" color="primary" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" color="primary" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" color="secondary" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
            tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
            tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
            numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body2" gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
            numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
          <Grid container spacing={1} my={1}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', p: 2 }}>
                primary.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'primary.light', color: 'primary.contrastText', p: 2 }}>
                primary.light
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'primary.dark', color: 'primary.contrastText', p: 2 }}>
                primary.dark
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  bgcolor: 'secondary.main',
                  color: 'secondary.contrastText',
                  p: 2,
                }}
              >
                secondary.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'secondary.light', color: 'primary.contrastText', p: 2 }}>
                secondary.light
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'secondary.dark', color: 'primary.contrastText', p: 2 }}>
                secondary.dark
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  bgcolor: 'tertiary.main',
                  color: 'tertiary.contrastText',
                  p: 2,
                }}
              >
                tertiary.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'error.main', color: 'error.contrastText', p: 2 }}>
                error.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'warning.main', color: 'warning.contrastText', p: 2 }}>
                warning.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'info.main', color: 'info.contrastText', p: 2 }}>info.main</Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'success.main', color: 'success.contrastText', p: 2 }}>
                success.main
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'text.primary', color: 'background.paper', p: 2 }}>
                text.primary
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'text.secondary', color: 'background.paper', p: 2 }}>
                text.secondary
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
                text.disabled
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'typography.tableHeaders', color: 'background.paper', p: 2 }}>
                typography.tableHeaders
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ bgcolor: 'typography.labels', color: 'background.paper', p: 2 }}>
                typography.labels
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" component="h3" color="primary" gutterBottom>
            Buttons
          </Typography>
          <Stack direction="row" gap={1} marginBottom={2}>
            <Button variant="contained" color="inherit">
              Default
            </Button>
            <Button variant="contained" color="primary">
              Primary
            </Button>
            <Button variant="contained" color="secondary">
              Secondary
            </Button>
            <Button variant="contained" disabled>
              Disabled
            </Button>
            <Button variant="contained" color="primary" href="#contained-buttons">
              Link
            </Button>
          </Stack>
          <Stack direction="row" gap={1} marginBottom={2}>
            <Button variant="text" color="inherit">
              Default
            </Button>
            <Button variant="text" color="primary">
              Primary
            </Button>
            <Button variant="text" color="secondary">
              Secondary
            </Button>
            <Button variant="text" disabled>
              Disabled
            </Button>
            <Button variant="text" color="primary" href="#contained-buttons">
              Link
            </Button>
          </Stack>
          <Stack direction="row" gap={1} marginBottom={3}>
            <Button variant="outlined" color="inherit">
              Default
            </Button>
            <Button variant="outlined" color="primary">
              Primary
            </Button>
            <Button variant="outlined" color="secondary">
              Secondary
            </Button>
            <Button variant="outlined" disabled>
              Disabled
            </Button>
            <Button variant="outlined" color="primary" href="#contained-buttons">
              Link
            </Button>
          </Stack>

          <Typography variant="h5" component="h3" color="primary" gutterBottom>
            Text Fields
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box marginBottom={2}>
                <Stack gap={1} direction="column">
                  <TextField
                    id="text-field-empty"
                    label="Text Field"
                    helperText="Some important help text"
                    required
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-empty-disabled"
                    label="Text Field Disabled"
                    helperText="Some important help text"
                    disabled
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-empty-read-only"
                    label="Text Field Read Only"
                    helperText="Some important help text"
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-empty-invalid"
                    label="Text Field Invalid"
                    helperText="Required"
                    error
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-empty-multiline"
                    label="Text Field Multiline"
                    helperText="Some important help text"
                    multiline
                    rows={4}
                    size="small"
                    fullWidth
                  />
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box marginBottom={2}>
                <Stack gap={1} direction="column">
                  <TextField
                    id="text-field-filled"
                    label="Text Field"
                    helperText="Some important help text"
                    defaultValue="Default Value"
                    required
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-filled-disabled"
                    label="Text Field Disabled"
                    helperText="Some important help text"
                    defaultValue="Default Value"
                    disabled
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-filled-read-only"
                    label="Text Field Read Only"
                    helperText="Some important help text"
                    defaultValue="Default Value"
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-filled-invalid"
                    label="Text Field Invalid"
                    helperText="Required"
                    error
                    defaultValue="Default Value"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="text-field-filled-multiline"
                    label="Text Field Multiline"
                    helperText="Some important help text"
                    defaultValue="Default Value"
                    multiline
                    rows={4}
                    size="small"
                    fullWidth
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={10}
                  label="Age"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={''}
                  label="Age"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} my={1}>
              <FormControl component="fieldset" size="small" fullWidth>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={'female'}>
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                  <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                    label="(Disabled option)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} my={1}>
              <FormControl component="fieldset" size="small" fullWidth>
                <FormLabel component="legend">Name</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={true} name="gilad" />}
                    label="Gilad Gray"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={false} name="jason" />}
                    label="Jason Killian"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={false} name="antoine" />}
                    label="Antoine Llorca"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
