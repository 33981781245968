import { Box, Stack, FormLabel, FormControlLabel, Checkbox, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, Card } from '../../components';
import { faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import SignaturePad from 'react-signature-canvas';
import { useState, FC, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { IAgreementInitialValues } from './contract-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { BrandingContext } from '../../context/branding-context';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ISignature {
  hasSubmitted: boolean;
  values: IAgreementInitialValues;
  setFieldValue: (key: string, val: any) => void;
  isValid: boolean;
  isSubmitting: boolean;
  setSigPadInstance: Dispatch<SetStateAction<any>>;
  sigPadInstance: any;
  clearSigPadOnResize?: boolean;
  sigContainerClass?: string;
  sigCanvasClass?: string;
  officeName?: string | null;
  handleAdyenAgreeAndContinue: () => void;
  display: boolean;
  isInvalidCreditCard: boolean;
}
export const Signature: FC<ISignature> = ({
  hasSubmitted,
  values,
  setFieldValue,
  isValid,
  isSubmitting,
  sigPadInstance,
  setSigPadInstance,
  clearSigPadOnResize = true,
  sigContainerClass,
  sigCanvasClass,
  officeName,
  handleAdyenAgreeAndContinue,
  display = true,
  isInvalidCreditCard,
}) => {
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const { authorizeName, isPoolService } = useContext(BrandingContext);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const classes = useStyles();
  const { adyen } = useFlags();

  useEffect(() => {
    if (isInvalidCreditCard) {
      values.hasConfirmedAuthorization = false;
      setSubmitButtonEnabled(false);
    }
    if (
      (!values.creditCardRequired &&
        !isInvalidCreditCard &&
        values.hasConfirmedAuthorization &&
        values.cardNumber !== '') ||
      (values.creditCardRequired && !isInvalidCreditCard && values.hasConfirmedAuthorization) ||
      (!values.creditCardRequired &&
        values.cardNumber === '' &&
        !values.hasConfirmedAuthorization) ||
      (!values.creditCardRequired && values.cardNumber === '')
    ) {
      setSubmitButtonEnabled(true);
    } else {
      values.hasConfirmedAuthorization = false;
      setSubmitButtonEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInvalidCreditCard,
    values.hasConfirmedAuthorization,
    values.creditCardRequired,
    values.cardNumber,
  ]);

  return (
    <Card
      cardTitleProps={{
        title: 'Signature',
      }}
      sxProps={{ display: display ? 'block' : 'none' }}
    >
      <Stack gap={2}>
        <TextField name="printedName" label="Printed Name" required disabled={hasSubmitted} />
        <Box>
          <FormLabel required>Sign Below</FormLabel>
          <Box className={clsx(classes.sigContainer, sigContainerClass)}>
            <SignaturePad
              canvasProps={{ className: clsx(classes.sigPad, sigCanvasClass) }}
              onEnd={() => {
                setIsSigned(true);
              }}
              ref={ref => {
                setSigPadInstance(ref);
              }}
              clearOnResize={clearSigPadOnResize}
            />
          </Box>
        </Box>
        {isSigned && !hasSubmitted && (
          <Box>
            <Button
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={() => {
                sigPadInstance?.clear();
                setIsSigned(false);
              }}
            >
              Clear
            </Button>
          </Box>
        )}
        <FormControlLabel
          control={<Checkbox name="aggreement" checked={values.hasConfirmedAgreement} />}
          label="BY SIGNING ABOVE, CUSTOMER AFFIRMS THAT CUSTOMER HAS READ AND UNDERSTANDS THE TERMS AND CONDITIONS OF THIS RESIDENTIAL SERVICE AGREEMENT, INCLUDING THE PARAGRAPHS RELATING TO WARRANTIES AND LIMITATION OF LIABILITY."
          sx={{ textTransform: 'uppercase' }}
          onChange={(_, checked) => setFieldValue('hasConfirmedAgreement', checked)}
          disabled={hasSubmitted}
          required
          className={classes.agreementCheckbox}
        />
        {(values.creditCardRequired || (values.cardNumber !== '' && !isInvalidCreditCard)) && (
          <FormControlLabel
            control={<Checkbox name="authorization" checked={values.hasConfirmedAuthorization} />}
            label={`CUSTOMER AUTHORIZES ${
              isPoolService ? officeName : authorizeName
            } TO CHARGE THE LISTED CREDIT CARD FOR THE TOTAL SHOWN ABOVE`}
            sx={{ textTransform: 'uppercase' }}
            onChange={(_, checked) => setFieldValue('hasConfirmedAuthorization', checked)}
            disabled={hasSubmitted}
            required
            className={classes.agreementCheckbox}
          />
        )}

        {!hasSubmitted && (
          <Box>
            <Button
              color="primary"
              type={adyen ? 'button' : 'submit'}
              startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
              onClick={adyen ? handleAdyenAgreeAndContinue : undefined}
              disabled={
                !isSigned ||
                !isValid ||
                isSubmitting ||
                !values.hasConfirmedAgreement ||
                !submitButtonEnabled
              }
            >
              {adyen ? 'Agree & Continue to Payment' : `Agree and Submit`}
            </Button>
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  sigContainer: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  sigPad: {
    width: '100%',
    height: '100%',
  },
  agreementCheckbox: {
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-label': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
}));
