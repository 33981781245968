import { FC } from 'react';
import { TextField } from '../../../../components/formikMui';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';

interface IConditionAndNotesFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

export const ConditionAndNotesForm: FC<IConditionAndNotesFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the ConditionAndNotesDisplay component as well
  // REMINDER: When adding fields, also update the conditionNotesSectionFields array in pool-structure-card
  const { handleBlur } = useFormikContext();
  return (
    <Box mt={2.5}>
      <Box mb={3}>
        <TextField
          name="condition"
          label="General Condition"
          onBlur={e => {
            handleBlur(e);
            handleFormChange();
          }}
          inputProps={{
            readOnly,
          }}
        />
      </Box>
      <Box>
        <TextField
          multiline
          rows={4}
          name="notes"
          label="Notes"
          onBlur={e => {
            handleBlur(e);
            handleFormChange();
          }}
          inputProps={{
            readOnly,
          }}
        />
      </Box>
    </Box>
  );
};
