import { FC } from 'react';
import { TextField } from '../../../../components/formikMui';
import { Box, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

interface IPipingInformationFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

export const PipingInformationForm: FC<IPipingInformationFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the PipingInformationDisplay component as well
  // REMINDER: When adding fields, also update the pipingInfoSectionFields array in pool-structure-card
  const { handleBlur } = useFormikContext();
  return (
    <Box mt={2.5}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="gauge"
            label="Gauge"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="valveSize"
            label="Valve Size"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="mainDrain"
            label="Main Drain"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="controlValve"
            label="Control Valve"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1} />
      </Stack>
    </Box>
  );
};
