import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { RateDetailsTable } from './rate-details-table';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider, FormControl, FormGroup, Checkbox, FormLabel, FormControlLabel } from '@mui/material';

// fetch
import { updateRate, createRate } from '../../../fetch';
import { IRateItem } from '../../../models';

interface IAddEditRateModal {
  open: boolean;
  onClose: () => void;
  currentRate?: IRateItem | null;
  fetchRates: () => void;
}

const Schema = Yup.object().shape({
  rateId: Yup.string(),
  monthsApply: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export const AddEditRateModal: FC<IAddEditRateModal> = ({
  open,
  onClose,
  currentRate,
  fetchRates,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const months = [
    {title: 'January', label: 'Jan', value: 'jan'},
    {title: 'February', label: 'Feb', value: 'feb'},
    {title: 'March', label: 'Mar', value: 'mar'},
    {title: 'April', label: 'Apr', value: 'apr'},
    {title: 'May', label: 'May', value: 'may'},
    {title: 'June', label: 'Jun', value: 'jun'},
    {title: 'July', label: 'Jul', value: 'jul'},
    {title: 'August', label: 'Aug', value: 'aug'},
    {title: 'September', label: 'Sep', value: 'sep'},
    {title: 'October', label: 'Oct', value: 'oct'},
    {title: 'November', label: 'Nov', value: 'nov'},
    {title: 'December', label: 'Dec', value: 'dec'},
  ];

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          rateId: currentRate?.rateId ?? '',
          officeId: user?.officeId ?? '',
          code: currentRate?.code ?? '',
          description: currentRate?.description ?? '',
          monthsApply: currentRate?.monthsApply ?? '',
          jan: currentRate?.jan ?? true,
          feb: currentRate?.feb ?? true,
          mar: currentRate?.mar ?? true,
          apr: currentRate?.apr ?? true,
          may: currentRate?.may ?? true,
          jun: currentRate?.jun ?? true,
          jul: currentRate?.jul ?? true,
          aug: currentRate?.aug ?? true,
          sep: currentRate?.sep ?? true,
          oct: currentRate?.oct ?? true,
          nov: currentRate?.nov ?? true,
          dec: currentRate?.dec ?? true,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {

          // Assign Y/N values based on month checkboxes
          // The result will go into the database in that format
          values.monthsApply = "";
          values.monthsApply += values.jan ? "Y" : "N";
          values.monthsApply += values.feb ? "Y" : "N";
          values.monthsApply += values.mar ? "Y" : "N";
          values.monthsApply += values.apr ? "Y" : "N";
          values.monthsApply += values.may ? "Y" : "N";
          values.monthsApply += values.jun ? "Y" : "N";
          values.monthsApply += values.jul ? "Y" : "N";
          values.monthsApply += values.aug ? "Y" : "N";
          values.monthsApply += values.sep ? "Y" : "N";
          values.monthsApply += values.oct ? "Y" : "N";
          values.monthsApply += values.nov ? "Y" : "N";
          values.monthsApply += values.dec ? "Y" : "N";

          if(currentRate) {
            currentRate.monthsApply = values.monthsApply;
          }
          
          const data = {
            ...values
          };
          try {
            if(currentRate) {
              await updateRate(data);
            }
            else {
              await createRate(data);
              enqueueSnackbar(
                'Please add details for this new rate.',
                {
                  variant: 'info',
                }
              );
            }
            enqueueSnackbar(
              currentRate
                ? 'Successfully updated rate!'
                : 'Successfully created rate!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchRates();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving rate, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          handleChange,
          isValid,
          values
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentRate ? 'Edit Rate' : 'Add Rate'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" inputProps={{ maxLength: 10 }} required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" inputProps={{ maxLength: 60 }} required />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Months (select all that apply)</FormLabel>
                        <FormGroup sx={{position: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems:'left'}}>
                          <FormControlLabel title={months[0].title}
                            control={
                              <Checkbox checked={values.jan} onChange={handleChange} name={months[0].value} />
                            }
                            label={months[0].label}
                          />
                          <FormControlLabel title={months[1].title}
                            control={
                              <Checkbox checked={values.feb} onChange={handleChange} name={months[1].value} />
                            }
                            label={months[1].label}
                          />
                          <FormControlLabel title={months[2].title}
                            control={
                              <Checkbox checked={values.mar} onChange={handleChange} name={months[2].value} />
                            }
                            label={months[2].label}
                          />
                          <FormControlLabel title={months[3].title}
                            control={
                              <Checkbox checked={values.apr} onChange={handleChange} name={months[3].value} />
                            }
                            label={months[3].label}
                          />
                          <FormControlLabel title={months[4].title}
                            control={
                              <Checkbox checked={values.may} onChange={handleChange} name={months[4].value} />
                            }
                            label={months[4].label}
                          />
                          <FormControlLabel title={months[5].title}
                            control={
                              <Checkbox checked={values.jun} onChange={handleChange} name={months[5].value} />
                            }
                            label={months[5].label}
                          />
                        </FormGroup>
                        <FormGroup sx={{position: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems:'left'}}>
                          <FormControlLabel title={months[6].title}
                            control={
                              <Checkbox checked={values.jul} onChange={handleChange} name={months[6].value} />
                            }
                            label={months[6].label}
                          />
                          <FormControlLabel title={months[7].title}
                            control={
                              <Checkbox checked={values.aug} onChange={handleChange} name={months[7].value} />
                            }
                            label={months[7].label}
                          />
                          <FormControlLabel title={months[8].title}
                            control={
                              <Checkbox checked={values.sep} onChange={handleChange} name={months[8].value} />
                            }
                            label={months[8].label}
                          />
                          <FormControlLabel title={months[9].title}
                            control={
                              <Checkbox checked={values.oct} onChange={handleChange} name={months[9].value} />
                            }
                            label={months[9].label}
                          />
                          <FormControlLabel title={months[10].title}
                            control={
                              <Checkbox checked={values.nov} onChange={handleChange} name={months[10].value} />
                            }
                            label={months[10].label}
                          />
                          <FormControlLabel title={months[11].title}
                            control={
                              <Checkbox checked={values.dec} onChange={handleChange} name={months[11].value} />
                            }
                            label={months[11].label}
                          />
                        </FormGroup>
                      </FormControl>
                      </Grid>
                    </Grid>
                    {currentRate && <RateDetailsTable rateId={currentRate.rateId} />}
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
