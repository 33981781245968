import { FC } from 'react';
import { useFormikContext } from 'formik';
import { formatInputPhoneNumber } from '../../helpers/format';
import { TextField, TextFieldProps } from './TextField';

interface PhoneTextFieldProps extends TextFieldProps {}

export const PhoneTextField: FC<PhoneTextFieldProps> = ({ name, ...props }) => {
  const { setFieldValue, handleBlur } = useFormikContext<any>();
  return (
    <TextField
      {...props}
      name={name}
      onBlur={e => {
        handleBlur(e);
        props?.onBlur?.(e);
        setFieldValue(name, formatInputPhoneNumber(e.target.value));
      }}
    />
  );
};
