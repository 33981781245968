import { axios } from '../helpers';
import { GridSortDirection } from '@mui/x-data-grid';
import qs from 'querystring';
import {
  IFeedbackPost,
  IFeedbackRes,
  IFeedback,
  IGetFiltersResponse,
  IFeedbackItem,
} from '../models';
import { userSignal } from '../signals';

export const getFeedback = async (filters?: {
  startDate?: string;
  endDate?: string;
  officeId?: string;
  userId?: string;
  type?: string;
  sortBy?: string;
  sortDirection?: GridSortDirection | string;
  page?: number;
  perPage?: number | string;
}): Promise<IFeedbackRes> => {
  try {
    const { data } = await axios.get(`/api/feedback`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getFeedbackFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/feedback/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getSingleFeedbackItem = async (
  feedbackId: string,
  officeId?: string | null
): Promise<IFeedbackItem> => {
  try {
    const { data } = await axios.get(`/api/feedback/${feedbackId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getFeedbackTypes = async (officeId?: string | null): Promise<IFeedback> => {
  try {
    const { data } = await axios.get(`/api/feedback/types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createFeedbackItem = async (
  body: IFeedbackPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/feedback`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
