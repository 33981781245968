import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { ICommissionType } from '../../../models';
import { deleteCommissionType, getCommissionTypes } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddEditCommissionTypeModal } from './add-edit-commission-type-modal';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';

export const CommissionTypesTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentCommissionType, setCurrentCommissionType] = useState<ICommissionType | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ICommissionType> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getCommissionTypes(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading Commission Types, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommissionType>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'commission-type-grid',
      sortColumn: 'code',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleDelete = async (commissionType: ICommissionType) => {
    try {
      const result = await confirm('Are you sure you want to delete this Commission Type?');
      if (result) {
        setDeleting(true);
        await deleteCommissionType(commissionType.commissionTypeId);
        await refetch();
        enqueueSnackbar(`Commission Type Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting Commission Type, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ICommissionType>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Commission Type"
                onClick={() => {
                  showModal(true);
                  setCurrentCommissionType(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Commission Type"
                onClick={() => handleDelete(params.row)}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Commission Types"
          mobileWrap
          overrideMobileView={500}
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Commission Type
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ICommissionType) => row.commissionTypeId}
          rows={rows}
          columns={columns}
          loading={isLoading || isDeleting}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            handleEdit: (commissionType: ICommissionType) => {
              showModal(true);
              setCurrentCommissionType(commissionType);
            },
            handleDelete: (commissionType: ICommissionType) => {
              handleDelete(commissionType);
            },
          }}
        />
      </Card>
      <AddEditCommissionTypeModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentCommissionType(null);
        }}
        currentCommissionType={currentCommissionType}
        fetchCommissionTypes={() => refetch()}
      />
    </>
  );
};
