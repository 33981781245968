import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { IFilterInputProps } from '../../../../models';

export const RadioButtonsFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;
  const [value = ''] = values;

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    onChange([newValue], filter);
  };

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={handleChange}>
        {filter.selectListItems?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
