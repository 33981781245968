import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { ConfirmPrompt, Page, Loader } from '../../components';
import { UserContext } from '../../context';
import { getServiceRouteDetails } from '../../fetch/service-route';
import { IServiceRouteDetail, ITechnician } from '../../models';
import { colorizeRoute } from '../routes/utils';
import { ServiceRouteDetailContent } from './service-route-detail-content';
import { useConfirm, useUnload } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRouteChanges } from '../routes';
import { defaultUnsavedChangesMessage } from '../../constants';
import { RouteDayPicker } from './route-day-picker';
import { formatDayMonthYearDate } from '../../helpers';

const ticksToDate = require('ticks-to-date');

interface IServiceRouteDetailPage {}

export const ServiceRouteDetailPage: FC<IServiceRouteDetailPage> = () => {
  const [serviceRoutes, setServiceRoutes] = useState<IServiceRouteDetail[]>([]);
  const [isLoadingSelectedRoute, setLoadingSelectedRoute] = useState(false);
  const { serviceRouteId }: { serviceRouteId: string } = useParams();
  const [currentServiceRouteId, setCurrentServiceRouteId] = useState<string | number>(
    serviceRouteId
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPrompt, setShowPrompt] = useState(true);
  const { user } = useContext(UserContext);
  const location = useLocation();
  const search = location.search;
  const userId = new URLSearchParams(search).get('userId');
  const [selectedTechs, setSelectedTechs] = useState<ITechnician[]>([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [hasChanges, setHasChanges] = useState(false);
  const [isEmptyServiceDay, setShowEmptyServiceDay] = useState(false);
  const history = useHistory();

  const tickDate = useMemo(() => ticksToDate(Number(serviceRouteId)), [serviceRouteId]);

  const { updateMode, onUpdateModeChange } = useRouteChanges({
    serviceRoutes: serviceRoutes,
    updateMode: 'Single',
  });

  const fetchServiceRouteDetail = async () => {
    setLoadingSelectedRoute(true);
    // reset these if we go to a the next page
    setServiceRoutes([]);
    try {
      setShowEmptyServiceDay(false);
      const route = await getServiceRouteDetails(currentServiceRouteId, {
        userId: userId ? [userId] : undefined,
        officeId: user?.officeId,
      });

      const colorizedRoute = colorizeRoute(route);

      setServiceRoutes([colorizedRoute]);
      setShowPrompt(true);
    } catch (error: any) {
      setShowEmptyServiceDay(true);
      if (error?.Detail?.includes('no services')) {
        return setErrorMessage(error?.Detail);
      }
      enqueueSnackbar(error?.Detail || `Error getting service route details, please try again.`, {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setLoadingSelectedRoute(false);
    }
  };

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, hasChanges);

  useEffect(() => {
    if (currentServiceRouteId) {
      fetchServiceRouteDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServiceRouteId]);

  const confirmChangesLoss = async () => {
    const result = await confirm(defaultUnsavedChangesMessage);
    if (result) {
      setShowPrompt(false);
    }
    return result;
  };
  return (
    <div key={currentServiceRouteId}>
      <Page
        title="Edit Service Route"
        breadcrumb={{
          text: 'Service Routes',
          title: 'Back to Service Routes',
          link: `/routes`,
        }}
      >
        <ConfirmPrompt when={hasChanges && showPrompt} message={defaultUnsavedChangesMessage} />
        {isLoadingSelectedRoute && (
          <Box
            height="20rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Loader position="centered" />
          </Box>
        )}

        {isEmptyServiceDay && (
          <>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <RouteDayPicker
                  hasChanges={hasChanges}
                  confirmChangesLoss={confirmChangesLoss}
                  isLoadingSelectedRoute={isLoadingSelectedRoute}
                  setCurrentServiceRouteId={setCurrentServiceRouteId}
                  selectedServiceDate={formatDayMonthYearDate(tickDate)}
                />
              </Box>
              <Button
                onClick={() => {
                  history.push(`/routes`);
                }}
                size="small"
                color="inherit"
                startIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" />}
              >
                Back to Routes
              </Button>
            </Box>
            <Box
              height="10rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography
                sx={{
                  color: theme => theme.palette.primary.main,
                  fontWeight: 'bold',
                }}
                variant={'h6'}
              >
                {errorMessage}
              </Typography>
            </Box>
          </>
        )}
        {serviceRoutes?.length > 0 && (
          <ServiceRouteDetailContent
            selectedTechs={selectedTechs}
            fetchServiceRouteDetail={fetchServiceRouteDetail}
            serviceRoutes={serviceRoutes}
            isLoadingSelectedRoute={isLoadingSelectedRoute}
            setSelectedTechs={setSelectedTechs}
            setHasChanges={setHasChanges}
            currentServiceRouteId={currentServiceRouteId}
            setCurrentServiceRouteId={setCurrentServiceRouteId}
            confirmChangesLoss={confirmChangesLoss}
            onUpdateModeChange={onUpdateModeChange}
            updateMode={updateMode}
          />
        )}
      </Page>
    </div>
  );
};
