import { Page } from '../../../components';
import { Tasks } from './tasks-list';

export const TasksPage = () => {
  return (
    <Page title="Tasks">
      <Tasks cardTitle="Tasks" showFilters redirect="/scheduling/tasks" />
    </Page>
  );
};
