import { FC, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// fetch
import { updateSalesTax, createSalesTax } from '../../../fetch';
import { ISalesTax } from '../../../models';

interface IAddEditSalesTaxModal {
  open: boolean;
  onClose: () => void;
  currentSalesTax?: ISalesTax | null;
  fetchSalesTaxes: () => void;
}

const Schema = Yup.object().shape({
  salesTaxId: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  taxRateText: Yup.string().required('Required'),
});

export const AddEditSalesTaxModal: FC<IAddEditSalesTaxModal> = ({
  open,
  onClose,
  currentSalesTax,
  fetchSalesTaxes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [minExpireDate, setMinExpireDate] = useState(new Date());

  useEffect(() => {
    if (currentSalesTax?.effectiveDate != null && currentSalesTax.expireDate == null) {
      setMinExpireDate(new Date(currentSalesTax?.effectiveDate));
    } else if (currentSalesTax?.effectiveDate == null && currentSalesTax?.expireDate == null)
      setMinExpireDate(new Date());
  }, [currentSalesTax]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          salesTaxId: currentSalesTax?.salesTaxId ?? '',
          officeId: user?.officeId ?? '',
          code: currentSalesTax?.code ?? '',
          description: currentSalesTax?.description ?? '',
          taxRate: 0,
          taxRateText: currentSalesTax?.taxRateText ?? '',
          effectiveDate: currentSalesTax?.effectiveDate ?? null,
          expireDate: currentSalesTax?.expireDate ?? null,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
          };
          try {
            currentSalesTax ? await updateSalesTax(data) : await createSalesTax(data);
            enqueueSnackbar(
              currentSalesTax
                ? 'Successfully updated sales tax!'
                : 'Successfully created sales tax!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchSalesTaxes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving sales tax, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentSalesTax ? 'Edit Sales Tax' : 'Add Sales Tax'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="taxRateText"
                          label="Tax Rate %"
                          type="number"
                          value={Number(values.taxRate).toFixed(2)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label="Effective Date"
                          format="MM/dd/yyyy"
                          onChange={(date: any) => {
                            setFieldValue('effectiveDate', date);
                            setMinExpireDate(date);
                          }}
                          value={values.effectiveDate ? new Date(values.effectiveDate) : null}
                          slotProps={{
                            textField: {
                              error: !!errors?.effectiveDate && !!touched?.effectiveDate,
                              size: 'small',
                              fullWidth: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label="Expire Date"
                          format="MM/dd/yyyy"
                          onChange={(date: any) => {
                            setFieldValue('expireDate', date);
                          }}
                          minDate={minExpireDate}
                          value={values.expireDate ? new Date(values.expireDate) : null}
                          slotProps={{
                            textField: {
                              error: !!errors?.expireDate && !!touched?.expireDate,
                              size: 'small',
                              fullWidth: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
