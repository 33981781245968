import { Grid, Box, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { ILeadsByMonth, ILeadsBySource } from '../../models';
import { getLeadsByMonth, getLeadsBySource } from '../../fetch/leads';
import { CardTitle, Loader, Card } from '../../components';
import { LeadsByMonthChart } from './leads-by-month-graph';
import { LeadsBySourceChart } from './leads-by-source-graph';
import { useSnackbar } from 'notistack';

export const NewLeadsSection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [graphsAreLoading, setGraphsAreLoading] = useState(false);
  const [leadsByMonth, setLeadsByMonth] = useState<ILeadsByMonth[]>([]);
  const [leadsBySource, setLeadsBySource] = useState<ILeadsBySource[]>([]);

  const isSmMobile = useMediaQuery(`(max-width: 560px)`);

  useEffect(() => {
    const loadData = async () => {
      try {
        setGraphsAreLoading(true);
        const leadsByMonth = await getLeadsByMonth();
        setLeadsByMonth(leadsByMonth);

        const leadsBySource = await getLeadsBySource();
        setLeadsBySource(leadsBySource);
      } catch (e) {
        enqueueSnackbar('An unexpected error occurred while loading the graphs', {
          variant: 'error',
        });
      } finally {
        setGraphsAreLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = graphsAreLoading;

  const showGraphsSection = !!(leadsByMonth.length || leadsBySource.length);

  return (
    <Box mb={2}>
      {isLoading && (
        <Box height="10rem">
          <Loader position="centered" type="inline" />
        </Box>
      )}
      {!isLoading && (
        <>
          <Grid container spacing={2}>
            {showGraphsSection && (
              <Grid item xs={12} lg={12} xl={6}>
                <Card>
                  <CardTitle title="LEADS BY MONTH - CHART VIEW" />

                  <Box sx={{ height: 350 }}>
                    <LeadsByMonthChart data={leadsByMonth} />
                  </Box>
                </Card>
              </Grid>
            )}
            {showGraphsSection && (
              <Grid item xs={12} lg={12} xl={6}>
                <Card>
                  <CardTitle title="LEADS BY MONTH - COUNT VIEW" />

                  <Box
                    sx={{
                      height: isSmMobile ? 775 : 350,
                      marginTop: isSmMobile ? '-13rem' : 0,
                    }}
                  >
                    <LeadsBySourceChart data={leadsBySource} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};
