import { ILookupModel } from './util';

export enum VIEW_VISIBILITY {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export interface ISearchCondition {
  operator: 'AND' | 'OR' | 'NONE' | '';
  columnName: string;
  comparator: string;
  compareValue: string;
  isPending?: boolean;
  isGrouped: boolean;
  groupId: number;
}

export interface ICustomViewFormValues {
  viewName: string;
  viewVisibility: VIEW_VISIBILITY.PRIVATE | VIEW_VISIBILITY.PUBLIC;
  searchConditions: ISearchCondition[];
  viewColumns: ILookupModel[];
}

export interface ICustomViewRestrictions {
  columnName: string;
  comparator: string;
  compareValue: string;
  sortOrder: number;
  operator: 'AND' | 'OR' | 'NONE' | '';
  isGrouped: boolean;
  groupId: number;
  customViewId?: string;
  customViewRestrictionId?: string;
}

interface ICreateViewColumns {
  columnName: string;
  sortOrder: number;
}

interface IGetViewColumns {
  customViewColumnId: string;
  customViewId: string;
  columnName: string;
  sortOrder: 0
}

export interface ICreateCustomView {
  officeId: string;
  description: string;
  isPublic: boolean;
  restrictions: ICustomViewRestrictions[];
  columns: ICreateViewColumns[];
}

export interface IGetCustomViewResponse {
  data: {
    customViewId: string;
    officeId: string;
    userId: string;
    description: string;
    isPublic: boolean;
    restrictions: ICustomViewRestrictions[];
    columns: IGetViewColumns[];
  };
}

export interface ICustomCustomerView {
  customViewId: string;
  customViewDescription: string;
}

export interface ILookupModelResponse {
  data: ILookupModel[];
}
