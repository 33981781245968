import { Page } from '../../components';
import { VideoDisplay } from '../../components/videoDisplay';

export const HelpPage = () => {
  return (
    <Page title="Help">
      <VideoDisplay />
    </Page>
  );
};
