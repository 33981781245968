import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Box, FormControlLabel, Checkbox, Button, Grid } from '@mui/material';
import { FC, useState, useContext, useCallback } from 'react';
import {
  CardTitle,
  CardFiltersLayout,
  Card,
  GridDataFetcher,
  useDataGrid,
  SelectAsyncInput,
} from '../../../components';
import { IRepair, IPaginatedResponse, IUserGroup } from '../../../models';
import clsx from 'clsx';
import { UserContext } from '../../../context';
import { getRepairVisits, getUserGroups } from '../../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { RepairVisitsDataGrid } from './repair-visits-data-grid';
import { OTS_FIELD_REPORT_USER_GROUP } from '../../../helpers';

export interface IRepairVisits {
  selectedUserFilter?: string;
  selectedDateFilter?: string;
  redirect?: string;
}

export const RepairVisits: FC<IRepairVisits> = ({
  selectedUserFilter,
  selectedDateFilter,
  redirect,
}) => {
  const localStorageUserGroupId = localStorage?.getItem(OTS_FIELD_REPORT_USER_GROUP);
  const [isShowingFilters, setIsShowingFilters] = useState(true);
  const [includeCancelledRepairs, setIncludeCancelledRepairs] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>(localStorageUserGroupId ?? '');
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<IRepair> = useCallback(
    async ({ page, perPage, sortDirection, sortColumn }) => {
      try {
        const res = await getRepairVisits({
          perPage,
          page: page + 1,
          officeId: user?.officeId,
          sortBy: sortColumn || 'serviceDate',
          sortDirection: sortDirection || 'desc',
          includeCancelledRepairs,
          userGroupId: selectedUserGroup === 'all' ? '' : selectedUserGroup,
          userId: selectedUserFilter,
          serviceDate: selectedDateFilter,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading repair visits, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateFilter, selectedUserFilter, selectedUserGroup, includeCancelledRepairs]
  );

  const {
    rows,
    isLoading: isLoadingRepairVisits,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchRepairVisits,
  } = useDataGrid<IRepair>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'field-report-repair-visits',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        title="One-Time Services"
        mobileWrap
        action={
          <>
            <Button
              onClick={() => setIsShowingFilters(!isShowingFilters)}
              className={clsx('print--none')}
              startIcon={
                <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
              }
              color="secondary"
              size="small"
              disabled={isLoadingRepairVisits}
              data-testid="ots-filters-button"
            >
              Filters
            </Button>
          </>
        }
      >
        <CardFiltersLayout isOpen={isShowingFilters}>
          <Grid container spacing={2} className="print--none">
            <Grid item xs={12} sm={6} md={4}>
              <SelectAsyncInput
                label="User Group"
                name="otsUserGroup"
                value={selectedUserGroup}
                handleChange={value => {
                  setSelectedUserGroup(value);
                  localStorage.setItem(OTS_FIELD_REPORT_USER_GROUP, value);
                }}
                apiRequest={() => {
                  return getUserGroups({
                    perPage: -1,
                    officeId: user?.officeId,
                    isForFieldReport: true,
                  });
                }}
                handleResponseOptions={(response: IPaginatedResponse<IUserGroup>) => {
                  if (!localStorageUserGroupId) {
                    setSelectedUserGroup(response?.data?.[0]?.userGroupId ?? '');
                  }
                }}
                transformResponse={(res: IPaginatedResponse<IUserGroup>) => {
                  const resWithAll = [
                    {
                      label: 'All',
                      value: 'all',
                    },
                    ...(res.data ?? []).map(r => ({
                      label: r.userGroupName,
                      value: r.userGroupId,
                    })),
                  ];
                  return resWithAll;
                }}
                hasClear={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={<Checkbox checked={includeCancelledRepairs} />}
                label="Include Cancelled Visits"
                onChange={(_, checked) => {
                  onPageChange(0);
                  setIncludeCancelledRepairs(checked);
                }}
              />
            </Grid>
          </Grid>
        </CardFiltersLayout>
      </CardTitle>
      <Box mt={0.5}>
        <RepairVisitsDataGrid
          loading={isLoadingRepairVisits}
          rows={rows.filter(x => x.siteName !== 'Route Start' && x.siteName !== 'Route End')}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          refetch={refetchRepairVisits}
          redirect={redirect}
          noResultsMessage="No One-Time Services."
        />
      </Box>
    </Card>
  );
};
