import { TextField } from '@mui/material';
import { FC } from 'react';
import { IParameterInputProps } from '../../../../models';

export const TextboxParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const { parameterName, promptText } = parameter;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value, parameter);
  };

  return (
    <TextField
      fullWidth
      size="small"
      label={promptText}
      name={parameterName}
      value={value}
      onChange={handleChange}
    />
  );
};
