import { useState, FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { faClose, faSave, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '../../components';
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  useMediaQuery,
  Button,
} from '@mui/material';
import { ILeadNote } from '../../models';
import {
  createSingleLeadNote,
  updateSingleLeadNote,
  deleteSingleLeadNote,
} from '../../fetch/leads';
import { formatShortFriendlyDateWithTime } from '../../helpers';
import { useConfirm } from '../../hooks';
import { QueryObserverResult } from 'react-query';

interface ILeadNoteCard {
  note: ILeadNote;
  leadId: string;
  refetchNotes: () => Promise<QueryObserverResult<ILeadNote[], Error>>;
  isInEditMode: boolean;
  notes: ILeadNote[];
  setNotes: Dispatch<SetStateAction<ILeadNote[]>>;
  setLatestLeadNoteId: Dispatch<SetStateAction<number | null>>;
}

export const LeadNoteCard: FC<ILeadNoteCard> = ({
  note,
  leadId,
  refetchNotes,
  isInEditMode,
  setLatestLeadNoteId,
  setNotes,
  notes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const isSmMobile = useMediaQuery(`(max-width: 567px)`);
  const [isEditing, setIsEditing] = useState(isInEditMode || false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentNote, setCurrentNote] = useState(note.note);

  useEffect(() => {
    setCurrentNote(note.note);
  }, [note]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} flexWrap="nowrap">
          {isUpdating && <Loader type="overlay" position="centered" />}
          <Grid item xs={1} sx={{ color: theme => theme.palette.secondary.main }}>
            <FontAwesomeIcon icon={faStickyNote} size={isSmMobile ? 'lg' : 'xl'} />
          </Grid>
          <Grid item xs={11}>
            <Stack gap={2}>
              {isEditing ? (
                <TextField
                  value={currentNote}
                  size="small"
                  multiline
                  rows={3}
                  autoFocus
                  onChange={e => setCurrentNote(e.target.value)}
                />
              ) : (
                <Typography>{currentNote}</Typography>
              )}
              <Box display="flex" flexWrap="wrap">
                <Typography variant="body2" sx={{ marginRight: '1rem' }}>
                  {note.createdBy}
                </Typography>

                <Typography variant="body2">
                  {note.createdDate ? formatShortFriendlyDateWithTime(note.createdDate) : ''}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions
        sx={{
          padding: theme => theme.spacing(1),
          backgroundColor: theme => theme.palette.grey[100],
        }}
      >
        <Button
          color="secondary"
          size="small"
          disabled={isUpdating || !currentNote}
          onClick={async () => {
            if (isEditing) {
              try {
                setIsUpdating(true);
                if (note?.leadNoteId < 0) {
                  await await createSingleLeadNote(leadId, currentNote);
                  enqueueSnackbar('Note Created!', {
                    variant: 'success',
                  });
                } else {
                  await updateSingleLeadNote(leadId, note.leadNoteId, currentNote);
                  enqueueSnackbar('Note Updated!', {
                    variant: 'success',
                  });
                }
                await refetchNotes();
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error saving note, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsUpdating(false);
                setLatestLeadNoteId(null);
              }
              return setIsEditing(false);
            }

            setIsEditing(true);
          }}
          startIcon={<FontAwesomeIcon icon={isEditing ? faSave : faEdit} size="lg" />}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
        <Button
          color={isEditing ? 'inherit' : 'error'}
          size="small"
          disabled={isUpdating}
          onClick={async () => {
            // user hit "Cancel" so we reset state and reset the current note back to what it was
            if (isEditing) {
              setCurrentNote(note.note);
              if (note?.leadNoteId < 0) {
                setNotes(notes.filter(n => n.leadNoteId !== note.leadNoteId));
              }
              setLatestLeadNoteId(null);
              return setIsEditing(false);
            }
            // user hit "Delete" so we call the api and delete the note and then refetch the data
            const result = await confirm('Are you are sure want to delete this note?');
            if (result) {
              try {
                setIsUpdating(true);
                await deleteSingleLeadNote(leadId, note.leadNoteId);
                await refetchNotes();
                enqueueSnackbar('Note Deleted!', {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error deleting note, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsUpdating(false);
                setIsEditing(false);
                setLatestLeadNoteId(null);
              }
            }
          }}
          startIcon={<FontAwesomeIcon icon={isEditing ? faClose : faTrash} size="lg" />}
        >
          {isEditing ? 'Cancel' : 'Delete'}
        </Button>
      </CardActions>
    </Card>
  );
};
