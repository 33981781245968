import { ReactNode } from 'react';
import {
  ICalendarDateRange,
  ITimelessEventChange,
  ITimelessEvent,
  ICalendarView,
  IWeekViewSettings,
  IDayViewSettings,
  IMonthViewSettings,
} from '../../../models';
import { TimelessWeekView } from './views/Week/WeekView';
import { DEFAULT_WEEK_SETTINGS, DEFAULT_WORK_WEEK_SETTINGS } from '../config';
import { TimelessMonthView } from './views/Month/MonthView';

export interface TimelessCalendarProps<T> {
  view: ICalendarView;
  dateRange: ICalendarDateRange;
  events: ITimelessEvent<T>[];
  daySettings?: IDayViewSettings;
  workWeekSettings?: IWeekViewSettings;
  weekSettings?: IWeekViewSettings;
  monthSettings?: IMonthViewSettings;
  renderEvent: (event: ITimelessEvent<T>) => ReactNode;
  onDateChange?: (change: ITimelessEventChange<T>) => unknown;
  allowDragging?: boolean;
}

export function TimelessCalendar<T>({
  view,
  events,
  renderEvent,
  dateRange,
  onDateChange,
  weekSettings = DEFAULT_WEEK_SETTINGS,
  workWeekSettings = DEFAULT_WORK_WEEK_SETTINGS,
  allowDragging = true,
}: TimelessCalendarProps<T>) {
  switch (view) {
    case ICalendarView.Day:
      return (
        <TimelessWeekView
          dateRange={dateRange}
          events={events}
          weekdays={[dateRange.startDate.getDay()]}
          renderEvent={renderEvent}
          onDateChange={onDateChange}
          allowDragging={allowDragging}
        />
      );
    case ICalendarView.Week:
      return (
        <TimelessWeekView
          dateRange={dateRange}
          events={events}
          weekdays={weekSettings.weekdays}
          renderEvent={renderEvent}
          onDateChange={onDateChange}
          allowDragging={allowDragging}
        />
      );
    case ICalendarView.WorkWeek:
      return (
        <TimelessWeekView
          dateRange={dateRange}
          events={events}
          weekdays={workWeekSettings.weekdays}
          renderEvent={renderEvent}
          onDateChange={onDateChange}
          allowDragging={allowDragging}
        />
      );
      case ICalendarView.Month:
        return (
          <TimelessMonthView
            dateRange={dateRange}
            events={events}
            renderEvent={renderEvent}
            onDateChange={onDateChange}
            allowDragging={allowDragging}
          />
        );
  }

  return null;
}
