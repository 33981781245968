import { FC, ReactNode } from 'react';
import { RadioGroup as MuiRadioGroup, RadioGroupProps as StandardRadioGroupProps, FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';

interface RadioGroupProps extends StandardRadioGroupProps {
  name: string,
  children: ReactNode,
};

export const RadioGroup: FC<RadioGroupProps> = ({
  name,
  children,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl>
      <MuiRadioGroup {...field} {...props}>
        { children }
      </MuiRadioGroup>
      { meta.touched && meta.error &&
        <FormHelperText error>{meta.error}</FormHelperText>
      }
    </FormControl>
  )
};
