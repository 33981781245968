import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, ITreatment, ISingleTreatment, ICreateTreatments } from '../models';
import { userSignal } from '../signals';

export const getTreatments = async (
  siteId: string | number,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number;
    scheduledServiceId?: string;
    officeId?: string | null;
  }
): Promise<IResponse<ITreatment[]>> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}/treatments`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const getTreatmentsByDate = async (
  siteId: string,
  whenAdded: string,
  officeId?: string | null
): Promise<ISingleTreatment[]> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}/treatments/${whenAdded}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const createTreatment = async (
  siteId: string | number,
  whenAdded: string,
  postData: ICreateTreatments,
  serviceId?: string,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(
      `/api/sites/${siteId}/treatments/${whenAdded}`,
      {
        ...postData,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      {
        params: { scheduledServiceId: serviceId },
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const deleteTreatment = async (treatmentId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/treatments/${treatmentId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};
