import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, CardTitle, Card } from '../../components';
import { ISite } from '../../models';
import { deleteSite } from '../../fetch';
import { useConfirm } from '../../hooks';
import { SitesDataGrid } from './sites-list-data-grid';
import { GridSortModel } from '@mui/x-data-grid';

interface ISitesList {
  sites: ISite[];
  handleViewSite: (site: ISite) => void;
  handleEditSite: (site: ISite) => void;
  handleNewSite: () => void;
  fetchSites: () => void;
  page: number;
  recordCount: number;
  pageSize: number;
  sortModel: GridSortModel;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
}

export const SitesList: FC<ISitesList> = ({
  sites,
  handleEditSite,
  handleViewSite,
  handleNewSite,
  fetchSites,
  page,
  pageSize,
  recordCount,
  sortModel,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  isLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isDeleting, setDeleting] = useState(false);
  const handleDelete = async (site: ISite) => {
    try {
      const result = await confirm('Are you sure you want to delete this site?');
      if (result) {
        setDeleting(true);
        await deleteSite(site.siteId);
        enqueueSnackbar(`Site Deleted!`, {
          variant: 'success',
        });
        fetchSites();
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting site, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Card>
      {isDeleting && <Loader position="centered" type="overlay" />}
      <CardTitle
        action={
          <Button
            onClick={handleNewSite}
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            size="small"
          >
            Add Additional Site
          </Button>
        }
      />
      <Box>
        <SitesDataGrid
          rows={sites}
          page={page}
          pageSize={pageSize}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          rowCount={recordCount}
          refetch={fetchSites}
          handleEdit={handleEditSite}
          handleView={handleViewSite}
          handleDelete={handleDelete}
          isLoading={isLoading}
        />
      </Box>
    </Card>
  );
};
