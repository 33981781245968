import { FC, Fragment } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import { Loader, ConfirmPrompt, CardTitle, Card, EditAddressInformation } from '../../components';
import { ISiteGetResponse, IAccountDetail, IAddress, ISiteDefinitionValue } from '../../models';
import { EditSiteInformation, EditAdditionalInformation } from './';
import { zipCodeRegExp } from '../../helpers';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IAddEditSite {
  isNewSite: boolean;
  site: ISiteGetResponse;
  siteDefinitions: ISiteDefinitionValue[];
  isLoadingSiteDefinitions: boolean;
  accountAddress: IAddress | null;
  isLoading: boolean;
  isDeleting: boolean;
  isModal?: boolean;
  showConfirmPrompt?: boolean;
  pushPinLocation: Microsoft.Maps.Location | null;
  setPushPinLocation: (val: Microsoft.Maps.Location | null) => void;
  isExpanded?: boolean;
  currentCustomer: IAccountDetail | null;
  globalFormik?: {
    values: any;
    handleChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (name: string, val: any) => void;
  };
}

export const SITE_DETAIL_VALIDATION = Yup.object().shape({
  siteDescription: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Site description is required'),
  addressName: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('Addressee is required'),
    }),
  street: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('Street is required'),
    }),
  city: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('City is required'),
    }),
  state: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('State is required'),
    }),
  postalCode: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string()
        .matches(zipCodeRegExp, {
          excludeEmptyString: true,
          message: 'Invalid Postal code',
        })
        .required('Postal Code is required'),
    }),
  latitude: Yup.number().required('Latitude is required'),
  longitude: Yup.number().required('Longitude is required'),
  additionalSiteInformation: Yup.array().of(
    Yup.object().shape({ value: Yup.string().max(255, 'Max 255 characters') })
  ),
  chargeForChemicals: Yup.boolean(),
});

export const AddEditSite: FC<IAddEditSite> = ({
  isNewSite,
  site,
  siteDefinitions,
  isLoadingSiteDefinitions,
  accountAddress,
  isLoading,
  isDeleting,
  showConfirmPrompt = true,
  pushPinLocation,
  setPushPinLocation,
  isExpanded = true,
  currentCustomer,
  globalFormik,
}) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          ...(site || {}),
          siteDescription: site?.siteDescription || currentCustomer?.name,
          addressName: !!site?.addressName
            ? site?.addressName
            : currentCustomer?.address?.addressName ?? '',
          street: isNewSite ? accountAddress?.street : site.street ?? '',
          city: isNewSite ? accountAddress?.city : site.city ?? '',
          state: isNewSite ? accountAddress?.state : site.state ?? '',
          postalCode: isNewSite ? accountAddress?.postalCode : site.postalCode ?? '',
          whenVerified: site?.whenVerified ?? '',
          mgrAccountId: site?.mgrAccountId ?? '',
          userId: site?.userId ?? '',
          useAccountAddress:
            site?.useAccountAddress !== null && site?.useAccountAddress !== undefined
              ? site?.useAccountAddress
              : true,
          additionalSiteInformation: siteDefinitions?.map(field => {
            return {
              ...field,
              value:
                site?.additionalSiteInformation?.find(
                  val => val.userDefinedSiteDefId === field.userDefinedSiteDefId
                )?.value ?? '',
            };
          }),
          chargeForChemicals: site?.chargeForChemicals ?? false,
          latitude: site?.latitude ?? 0,
          longitude: site?.longitude ?? 0,
        }}
        enableReinitialize={true}
        validationSchema={SITE_DETAIL_VALIDATION}
        validateOnChange={false}
        onSubmit={async (values, actions) => {
          // Form save handled elsewhere
        }}
      >
        {({ isSubmitting, dirty, values }) => {
          const handleFormChange = (val?: any) => {
            // Set global formik context values
            globalFormik?.setFieldValue('addEditSite', val ?? values);
          };
          if (isLoading)
            return (
              <Card>
                <Box my={20}>
                  <Loader position="centered" title="Loading" />
                </Box>
              </Card>
            );
          if (isDeleting)
            return (
              <Card>
                <Box my={20}>
                  <Loader position="centered" title="Deleting" type="overlay" />
                </Box>
              </Card>
            );
          return (
            <>
              <Form>
                {isSubmitting && (
                  <Loader type="overlay" position="centered" title="Saving" zIndex={1200} />
                )}
                {showConfirmPrompt && (
                  <ConfirmPrompt
                    when={dirty && !isSubmitting && !isLoading}
                    message={defaultUnsavedChangesMessage}
                  />
                )}
                <Box mb={2}>
                  <Card>
                    <CardTitle
                      title="Site Information"
                      withExpand
                      initialExpand
                      overrideExpand={isExpanded}
                    >
                      <Grid container spacing={{ xs: 3, md: 5 }} mt={{ xs: -2, md: -4 }}>
                        <Grid item xs={12} md={4} xl={5}>
                          <EditSiteInformation handleFormChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={8} xl={7}>
                          <EditAddressInformation
                            accountAddress={accountAddress!}
                            handleFormChange={handleFormChange}
                            setPushPinLocation={setPushPinLocation}
                            pushPinLocation={pushPinLocation}
                            site={site}
                            addressNameLabel="Site Addressee Name"
                            showAccountAddressButton
                          />
                        </Grid>
                      </Grid>
                    </CardTitle>
                  </Card>
                </Box>
                <Box mb={2}>
                  <EditAdditionalInformation
                    handleFormChange={handleFormChange}
                    isExpanded={isExpanded}
                    isLoadingSiteDefinitions={isLoadingSiteDefinitions}
                  />
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </Fragment>
  );
};
