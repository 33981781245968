import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IPaymentSetup } from '../models';
import { userSignal } from '../signals';

export const getAllPaymentSetups = async ({
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IPaymentSetup[]>> => {
  try {
    const { data } = await axios.get(`api/payment-setups`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSinglePaymentSetup = async (
  id: string | number,
  officeId?: string | null
): Promise<IPaymentSetup> => {
  try {
    const { data } = await axios.get(`/api/payment-setups/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createPaymentSetup = async (
  body: IPaymentSetup,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/payment-setups`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updatePaymentSetup = async (
  body: IPaymentSetup,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/payment-setups/${body.systemSettingId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deletePaymentSetup = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/payment-setups/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
