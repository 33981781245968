import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link as MLink } from 'react-router-dom';

interface ILink {
  className?: string;
  to: string;
  type?: 'primary' | 'white' | 'grey' | 'secondary' | 'error' | 'black';
  hasTextDecoration?: boolean;
  title?: string;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const Link: FC<ILink> = ({
  to,
  children,
  hasTextDecoration,
  className,
  type = 'primary',
  title,
  target,
  onClick,
  ...rest
}) => {
  const classes = useStyles({ hasTextDecoration });

  return (
    <MLink
      onClick={onClick}
      to={to}
      target={target}
      className={clsx(classes[type], className)}
      title={title}
      {...rest}
    >
      {children}
    </MLink>
  );
};

const useStyles = makeStyles<Theme, { hasTextDecoration?: boolean }>((theme: Theme) => ({
  white: {
    color: theme.palette.background.paper,
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  grey: {
    color: theme.palette.grey[300],
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.grey[500],
    },
  },
  primary: {
    color: theme.palette.primary.main,
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  error: {
    color: theme.palette.error.main,
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  black: {
    color: theme.palette.common.black,
    textDecoration: ({ hasTextDecoration }) => (hasTextDecoration ? 'underline' : 'none'),
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}));
