// Generate a Bing Maps link from available address attributes
export const getAddressMapLink = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null
) => {
  const formattedStreet = !!street ? `${street}, ` : '';
  const formattedCity = !!city ? `${city}, ` : '';
  const formattedState = !!state ? `${state} ` : '';
  const formattedPostalCode = !!postalCode ? postalCode : '';

  return `http://www.bing.com/maps/default.aspx?where1=${formattedStreet}${formattedCity}${formattedState}${formattedPostalCode}`;
};
