import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Link, Typography, Button, Fade } from '@mui/material';
import { useState } from 'react';
import { Loader, Modal } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { useConfirm } from '../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyleGuideModals = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const confirm = useConfirm();
  const [open, setOpen] = useState<boolean>(false);
  const [showModalLoading, setShowModalLoading] = useState<boolean>(false);

  const handleClose = async () => {
    const result = await confirm('Are you sure you want to exit?');
    if (result) {
      setOpen(false);
    } else {
      return;
    }
  };

  return (
    <StyleGuidePage title="Modals">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The modal component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-progress/#circular" target="_blank">
              MUI Dialog
            </Link>{' '}
            . It used for displaying small-medium forms and informational data in a dialog / modal.
            The Modal itself can be comprised of multiple parts, depending on the scenario:
            <ul className="my-3">
              <li>
                <strong>Modal</strong>: The bulk of the component, the modal form displays the
                dialog, including a close button, title, and overlay.
              </li>
              <li className="mt-2">
                <strong>Fade</strong>: This is an MUI component that is meant to be placed just
                inside the Modal component, wrapped around the modal content.
              </li>
            </ul>
          </Typography>
          <Typography mb={1}>
            When working with forms, Formik is usually used to surround the modal and a Form element
            is placed just inside the defined Modal component tags. Due to the varied nature, a new
            component specific to each modal instance is created in an appropriate folder where the
            Modal component is placed (i.e. create a component called AddUserModal to house the
            Formik definition, Yup validation schema, and Modal component instance.)
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- DialogProps
                <br />- actions?: JSX.Element
                <br />- children: ReactNode
                <br />- customButtonHandler?: Function
                <br />- customButtonIcon?: JSX.Element
                <br />- DialogContentProps?: DialogContentProps <br />- DialogTitleProps?:
                DialogTitleProps
                <br />- fullWidth?: boolean
                <br />- isLoading?: boolean
                <br />- maxWidth?: DialogProps['maxWidth']
                <br />- noPaddingContent?: boolean
                <br />- subtitle?: string
                <br />- title?: string
                <br />- showCloseButton?: boolean
                <br />- customMouseLeave?: () =&gt; void
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                Typical Modal Instance Props:
                <br />- open: boolean
                <br />- onClose: () =&gt; void
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(true);
                setShowModalLoading(true);
                setTimeout(() => {
                  setShowModalLoading(false);
                }, 5000);
              }}
            >
              Open Modal
            </Button>
            <Modal
              title="Example Md Modal"
              open={open}
              onClose={() => {
                handleClose();
              }}
              maxWidth="md"
            >
              {showModalLoading && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Box mt={1}>
                  <Typography>This can be a section of content or form fields</Typography>

                  <Box
                    margin="1rem 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box marginRight="1rem">
                      <Button
                        type="button"
                        color="inherit"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Button
                      onClick={() => handleClose()}
                      type="button"
                      color="secondary"
                      startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
                &lt;Modal title="Example Md Modal" open=&#123;open&#125; onClose=&#123;() =&gt;
                &#123; handleClose(); &#125;&#125; maxWidth="md" &gt; <br />
                &nbsp;&nbsp;&nbsp;&#123;showModalLoading && &lt;Loader type="overlay"
                position="centered" /&gt;&#125;
                <br />
                &nbsp;&nbsp;&nbsp;&lt;Fade in=&#123;open&#125;&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box mt=&#123;1&#125;&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Card title="Info"
                icon=&#123;&lt;Assignment /&gt;&#125;&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Typography&gt;This
                can be a section of content or form fields &lt;/Typography&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Card&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Card title="More Details"
                icon=&#123;&lt;Subject /&gt;&#125;&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Typography&gt;This
                can be a section of content or form fields &lt;/Typography&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Card&gt; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box margin="1rem 0 0"
                display="flex" alignItems="center" justifyContent="flex-end"&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box
                marginRight="1rem"&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
                type="button" color="inherit" onClick=&#123;() =&gt; &#123; handleClose();
                &#125;&#125;&gt;Cancel&lt;/Button&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
                onClick=&#123;() =&gt; handleClose()&#125; type="button" color="secondary"
                startIcon=&#123;faFloppyDisk&#125;&gt;Save&lt;/Button&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
                <br />
                &nbsp;&nbsp;&nbsp;&lt;/Fade&gt; <br />
                &lt;/Modal&gt;
              </CodeBlock>
            </Grid>
            <Grid item xs={12} md={6}>
              <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
                const handleClose = async () =&gt; &#123; <br />
                &nbsp;&nbsp;&nbsp;const result = await confirm('Are you sure you want to exit?');{' '}
                <br />
                &nbsp;&nbsp;&nbsp;if (result) &#123; <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setOpen(false); <br />
                &nbsp;&nbsp;&nbsp;&#125; else &#123; return; &#125; <br />
                &#125;;
              </CodeBlock>
              <Typography variant="body2" mt={1} color={theme => theme.palette.grey[600]}>
                Example of possible close function scenario. Not necessary and customizeable per
                instance (i.e. can be tied to unsaved changes when using Formik, etc.).
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
