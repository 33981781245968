import { axios } from '../helpers';
import qs from 'querystring';
import { IServiceDefinition, ILookupModel, IResponse } from '../models';
import { IReportLayout } from '../models/report-layout';
import { userSignal } from '../signals';

export const getServiceDefinitions = async (filters?: {
  description?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  siteId?: string;
}): Promise<IResponse<IServiceDefinition[]>> => {
  try {
    const { data } = await axios.get(`/api/service-definitions`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getServiceStatuses = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/service-definitions/statuses`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReportLayoutList = async (filters?: {
  description?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  siteId?: string;
}): Promise<IReportLayout[]> => {
  try {
    const { data } = await axios.get(`/api/service-definitions/reportLayoutList`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleServiceDefinition = async (
  id: string | number,
  officeId?: string | null
): Promise<IServiceDefinition> => {
  try {
    const { data } = await axios.get(`/api/service-definitions/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createServiceDefinition = async (
  body: IServiceDefinition,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/service-definitions`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateServiceDefinition = async (
  body: IServiceDefinition,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/service-definitions/${body.serviceDefId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteServiceDefinition = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/service-definitions/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
