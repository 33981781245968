import { Page } from '../../components';
import { AccountDetailsForm } from './account-details-form';
import { ManagePasswordForm } from './manage-password-form';
import { Stack } from '@mui/material';

export const MyAccountPage = () => {
  return (
    <Page title="My Account">
      <Stack gap={2}>
        <AccountDetailsForm />
        <ManagePasswordForm />
      </Stack>
    </Page>
  );
};
