import { Avatar } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC } from 'react';

interface IUserAvatarProps {
  src?: string;
  userName?: string | null;
  className?: string;
  noMarginRight?: boolean;
  backgroundColor?: string;
}

export const getInitials = (userName: string | undefined | null) => {
  if (!userName?.trim().length) {
    return '?';
  }
  const nameParts = userName.split(' ').filter(s => !!s.trim());
  const initials = [];
  initials.push(nameParts[0][0]);
  if (nameParts.length > 1) {
    initials.push(nameParts[nameParts.length - 1][0]);
  }
  return initials.join('').toUpperCase();
};

export const UserAvatar: FC<IUserAvatarProps> = ({
  className,
  src,
  userName,
  noMarginRight,
  backgroundColor,
}) => {
  const classes = useStyles({ noMarginRight, backgroundColor });
  return (
    <Avatar
      alt={userName || ''}
      src={src || undefined}
      className={clsx(className, classes.userAvatar)}
      data-testid="UserAvatar"
    >
      {getInitials(userName)}
    </Avatar>
  );
};

const useStyles = makeStyles<Theme, { noMarginRight?: boolean; backgroundColor?: string }>(
  (theme: Theme) => {
    return {
      userAvatar: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        marginRight: ({ noMarginRight }) => (noMarginRight ? 0 : 12),
        fontSize: 14,
        borderColor: theme.palette.secondary.main,
        height: 30,
        width: 30,
      },
    };
  }
);
