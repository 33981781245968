import { Typography, styled, Card } from '@mui/material';
import { FC } from 'react';
import clsx from 'clsx';
import { Link } from '../../../../link';
import { UserAvatar } from '../../../../user-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faWrench } from '@fortawesome/free-solid-svg-icons';
import { CalendarEventTypes, ICalendarEventDetail } from '../../../../../models';
import { IColorSet } from '../../../../../models/colors';

interface ScheduledWorkCardProps {
  workDetail: ICalendarEventDetail;
  colorSet?: IColorSet;
}

interface StyledCardProps {
  hasChanged?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
}

export const ScheduledWorkCard: FC<ScheduledWorkCardProps> = ({ workDetail, colorSet }) => {
  const { userInformation, event, hasChanged } = workDetail;
  const { username, avatarUrl } = userInformation;

  const backgroundColor = colorSet?.backgroundColor || userInformation.backgroundColor;
  const foregroundColor = colorSet?.foregroundColor || userInformation.foregroundColor;

  let workRefUrl = '';
  switch (event.eventType) {
    case CalendarEventTypes.RepairVisit:
      workRefUrl = `/services/repair/${event.eventId}`;
      break;
    case CalendarEventTypes.ScheduledTask:
      workRefUrl = `/scheduling/tasks/${event.eventId}`;
      break;
  }

  const openLink = () => {
    window.open(workRefUrl, '_blank');
  };

  return (
    <StyledCard
      className={classes.employeeTaskCard}
      hasChanged={hasChanged}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <div className={classes.employeeTaskDetailsContainer}>
        <div className={classes.taskNameContainer}>
          <Link to={workRefUrl} target="_blank" className={classes.taskName}>
            {event.siteName}
          </Link>
          <FontAwesomeIcon
            icon={faGripVertical}
            className={clsx(classes.gripIcon, 'print--none')}
          />
        </div>
        {event.address && (
          <Typography className={classes.employeeAddress}>{event.address.street}</Typography>
        )}
        <div className={classes.userAvatarContainer}>
          <StyledUserAvatar
            src={avatarUrl || undefined}
            userName={username}
            backgroundColor={backgroundColor}
            hasChanged={hasChanged}
            foregroundColor={foregroundColor}
          />
          <Typography className={classes.employeeUsername}>{username}</Typography>
        </div>
      </div>
      <div className={classes.serviceTypeStrip}>
        <FontAwesomeIcon icon={faWrench} size="sm" />
        <div onClick={openLink}>
          <Link to={workRefUrl} target="_blank" className={classes.serviceName}>
            {event.description?.trim() ||
              `See ${event.eventType === CalendarEventTypes.RepairVisit ? 'Repair' : 'Task'}`}
          </Link>
        </div>
      </div>
    </StyledCard>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const StyledUserAvatar = styled(UserAvatar, {
  shouldForwardProp: prop => prop !== 'hasChange' && prop !== 'foregroundColor',
})<{ hasChanged?: boolean; foregroundColor?: string }>(({ hasChanged, foregroundColor }) => ({
  height: '20px',
  width: '20px',
  fontSize: '10px',
  marginRight: 0,
  borderStyle: hasChanged ? 'solid' : '',
  borderWidth: '1px',
  borderColor: hasChanged ? foregroundColor : '',
  '@media print': {
    display: 'none',
  },
}));

const PREFIX = 'ScheduledWorkCard';

const classes = {
  employeeTaskCard: `${PREFIX}-employeeTaskCard`,
  employeeTaskDetailsContainer: `${PREFIX}-employeeTaskDetailsContainer`,
  taskNameContainer: `${PREFIX}-taskNameContainer`,
  taskName: `${PREFIX}-taskName`,
  gripIcon: `${PREFIX}-gripIcon`,
  employeeAddress: `${PREFIX}-employeeAddress`,
  userAvatarContainer: `${PREFIX}-userAvatarContainer`,
  userAvatar: `${PREFIX}-userAvatar`,
  employeeUsername: `${PREFIX}-employeeUsername`,
  serviceTypeStrip: `${PREFIX}-serviceTypeStrip`,
  serviceName: `${PREFIX}-serviceName`,
};

const StyledCard = styled(Card, {
  shouldForwardProp: prop =>
    prop !== 'hasChanged' && prop !== 'backgroundColor' && prop !== 'foregroundColor',
})<StyledCardProps>(({ theme, backgroundColor, foregroundColor, hasChanged }) => ({
  [`& .${classes.employeeTaskCard}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 5px',
    borderColor: backgroundColor,
    backgroundColor: hasChanged ? backgroundColor : '',
    color: hasChanged ? foregroundColor : '',
    [MOBILE_MEDIA_QUERY]: {
      borderWidth: '1px 1px 1px 1px',
    },
    '@media print': {
      boxShadow: 'none !important',
    },
  },

  [`& .${classes.employeeTaskDetailsContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%',
    textAlign: 'left',
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
      textAlign: 'center',
      gap: 5,
    },
  },

  [`& .${classes.taskNameContainer}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  [`& .${classes.taskName}`]: {
    color: hasChanged ? foregroundColor : backgroundColor,
    textDecoration: 'underline',
    fontSize: 12,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 10,
    },
  },

  [`& .${classes.gripIcon}`]: {
    [MOBILE_MEDIA_QUERY]: {
      display: 'none',
    },
  },

  [`& .${classes.employeeAddress}`]: {
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 10,
    },
    '@media print': {
      position: 'static',
      color: `${theme.palette.common.black} !important`,
    },
  },

  [`& .${classes.userAvatarContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.userAvatar}`]: {
    height: '20px',
    width: '20px',
    fontSize: '10px',
    marginRight: 0,
    borderStyle: hasChanged ? 'solid' : '',
    borderWidth: '1px',
    borderColor: hasChanged ? foregroundColor : '',
  },

  [`& .${classes.employeeUsername}`]: {
    fontSize: 12,
    [MOBILE_MEDIA_QUERY]: {
      textAlign: 'center',
      fontSize: 10,
    },
  },

  [`& .${classes.serviceTypeStrip}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    width: '100%',
    padding: 5,
    whiteSpace: 'pre',
    backgroundColor: backgroundColor,
    color: foregroundColor,
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
      textAlign: 'center',
      gap: 0,
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },

  [`& .${classes.serviceName}`]: {
    color: foregroundColor,
    textDecoration: 'underline',
    fontSize: 13,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 8,
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
    },
  },
}));
