import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AccountDetailsTab } from './AccountDetailsTab';
import { SitesTab } from './SitesTab';
import { Tasks } from '../../scheduling/tasks';
import { ConfirmPrompt, Documents, Tabs, ITab } from '../../../components';
import { IAccountDetail } from '../../../models';
import { useConfirm, useUnload } from '../../../hooks';
import { RatesTab } from './RatesTab';
import { TransactionsTab } from './transactions-tab';
import {
  createAccountDocuments,
  getAccountDocuments,
  deleteAccountDocument,
  updateAccountDocument,
  getLeads,
} from '../../../fetch';
import { CustomerStatementTab } from './CustomerStatementTab';
import { EstimatesTab } from './estimates-tab';
import { UserContext } from '../../../context';
import { Permissions, defaultUnsavedChangesMessage } from '../../../constants';
import { hasCorrectUserPermissions } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ICustomersDetailDisplay {
  accountId: string;
  currentCustomer: IAccountDetail | null;
  isLoadingCustomer: boolean;
  isModal?: boolean;
}

export const CustomersDetailDisplay: FC<ICustomersDetailDisplay> = ({
  accountId,
  currentCustomer,
  isLoadingCustomer,
  isModal,
}) => {
  const { user } = useContext(UserContext);
  const searchParams = new URLSearchParams(window.location.search);
  const activeTab = searchParams.get('activeTab');
  const [selectedTab, setSelectedTab] = useState<string>(activeTab || 'account');
  const [isTabDirty, setIsTabDirty] = useState(false);
  const [hasSalesLeads, setHasSalesLeads] = useState(false);
  const confirm = useConfirm();

  const { v2Ots } = useFlags();

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, isTabDirty);

  useEffect(() => {
    // Check to see if there are any leads before displaying the Sales tab
    const fetchCustomerLeads = async () => {
      try {
        const res = await getLeads({
          sortBy: 'whenCreated',
          sortDirection: 'Desc',
          perPage: 1,
          accountId,
          officeId: user?.officeId,
        });
        if (res.records?.length !== 0) {
          setHasSalesLeads(true);
        } else {
          setHasSalesLeads(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    // Only perform check, if permissions are OFF
    if (!hasCorrectUserPermissions(Permissions.ViewEstimates, user!)) {
      fetchCustomerLeads();
    }
  }, [accountId, user]);

  if (currentCustomer?.accountId && !isLoadingCustomer) {
    return (
      <>
        <ConfirmPrompt when={isTabDirty} message={defaultUnsavedChangesMessage} />
        <Tabs
          id="customer-details"
          isSticky
          color="secondary"
          size="lg"
          backgroundColor={isModal ? 'white' : 'grey'}
          selectedTab={selectedTab}
          setSelectedTab={async val => {
            if (isTabDirty && val !== selectedTab) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                setIsTabDirty(false);
                return setSelectedTab(val);
              }
              setIsTabDirty(false);
              return;
            }
            setSelectedTab(val);
          }}
          tabs={
            [
              {
                key: 'account',
                title: 'Details',
                // Prevent mounting and API calls when not the selectedTab
                children: selectedTab === 'account' && (
                  <AccountDetailsTab currentCustomer={currentCustomer} isEditable={!isModal} />
                ),
              },
              !isModal && {
                key: 'sites',
                title: 'Sites',
                children: (
                  <SitesTab
                    accountId={accountId}
                    currentCustomer={currentCustomer}
                    setIsTabDirty={setIsTabDirty}
                  />
                ),
              },
              {
                key: 'rates',
                title: 'Rates',
                children: (
                  <RatesTab
                    accountId={accountId}
                    setIsTabDirty={setIsTabDirty}
                    isEditable={!isModal}
                  />
                ),
              },
              hasCorrectUserPermissions(Permissions.ViewTransactions, user!) && {
                key: 'transactions',
                title: 'Transactions',
                children: (
                  <TransactionsTab
                    accountId={accountId}
                    currentCustomer={currentCustomer}
                    isEditable={!isModal}
                  />
                ),
              },
              !isModal && {
                key: 'statement',
                title: 'Statement',
                children: (
                  <CustomerStatementTab
                    accountCode={currentCustomer.accountCode || ''}
                    accountId={accountId}
                    accountEmails={currentCustomer.emails}
                  />
                ),
              },
              {
                key: 'tasks',
                title: 'Scheduled Tasks',
                children: (
                  <Box marginTop="1rem">
                    <Tasks
                      cardTitle="Scheduled Tasks"
                      accountId={accountId}
                      currentCustomer={currentCustomer}
                      showFilters
                      redirect={`/customers/${currentCustomer?.accountId}?activeTab=tasks`}
                      isEditable={!isModal}
                    />
                  </Box>
                ),
              },
              v2Ots &&
                (hasCorrectUserPermissions(Permissions.ViewEstimates, user!) || hasSalesLeads) && {
                  key: 'estimates',
                  title: 'Estimates',
                  children: (
                    <EstimatesTab
                      accountId={accountId}
                      currentCustomer={currentCustomer}
                      isEditable={!isModal}
                    />
                  ),
                },
              {
                key: 'documents',
                title: 'Documents',
                children: (
                  <Documents
                    gridKeyName="customer-documents-grid"
                    labelContext="Customer"
                    getApiRequest={getAccountDocuments}
                    deleteApiRequest={deleteAccountDocument}
                    postApiRequest={createAccountDocuments}
                    putApiRequest={updateAccountDocument}
                    getFilters={{ accountId: accountId }}
                    postFilterId={accountId}
                    cardTitle=""
                    marginTop={2}
                    isEditable={!isModal}
                    disableDelete={item => {
                      return !!item.repairId ? true : false; // If repairId, disable delete button
                    }}
                  />
                ),
              },
            ].filter(Boolean) as ITab[]
          }
        />
      </>
    );
  }
  return null;
};
