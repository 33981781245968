import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, FC, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import { CardTitle, Card, ServerSideDataGrid } from '../../components';
import { ServiceLogModal } from './ServiceLogModal';
import { getScheduledServiceServiceLog } from '../../fetch';
import { IScheduledServiceLog } from '../../models';
import {
  formatShortFriendlyDateWithTime,
  getLocalPageSize,
  SERVICE_LOG_GRID_KEY,
} from '../../helpers';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface IServiceLog {
  serviceId: string | number;
  isModal?: boolean;
  isExpanded?: boolean;
}

export const ServiceLog: FC<IServiceLog> = ({ serviceId, isModal, isExpanded }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [serviceLogs, setServiceLogs] = useState<IScheduledServiceLog[]>([]);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(getLocalPageSize(SERVICE_LOG_GRID_KEY));
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchServiceLogs = async () => {
    try {
      setLoading(true);
      const res = await getScheduledServiceServiceLog(serviceId);
      setServiceLogs(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchServiceLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'whenCreated',
          headerName: 'Log Date/Time',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<IScheduledServiceLog>) => {
            const { row: original } = params;
            return formatShortFriendlyDateWithTime(original.whenCreated);
          },
        },
        {
          headerName: 'User',
          field: 'userName',
          sortable: false,
          flex: 1,
          disableColumnMenu: true,
        },
        {
          headerName: 'Log Entry',
          field: 'logEntry',
          sortable: false,
          flex: 1,
          disableColumnMenu: true,
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceLogs]
  );
  return (
    <>
      <Card className="print--avoid-break">
        <CardTitle
          withExpand
          overrideExpand={isExpanded}
          initialExpand={false}
          title="Service Log"
          mobileWrap
          action={
            <>
              {!isModal && (
                <Button
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} size="lg" />}
                  size="small"
                  onClick={() => setModalOpen(true)}
                >
                  Add Service Log
                </Button>
              )}
            </>
          }
        >
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: IScheduledServiceLog) => row.serviceLogId}
            rows={serviceLogs}
            columns={columns}
            loading={isLoading}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={page => setPage(page)}
            onPageSizeChange={num => {
              setRowsPerPage(num);
              localStorage.setItem(SERVICE_LOG_GRID_KEY, String(num));
            }}
            hasMobileLayout
            mobileProps={{
              useFirstCell: true,
            }}
          />
        </CardTitle>
      </Card>
      <ServiceLogModal
        open={isModalOpen}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            fetchServiceLogs();
          }
          setModalOpen(false);
        }}
        serviceId={serviceId}
      />
    </>
  );
};
