import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, ICommissionType } from '../models';
import { userSignal } from '../signals';

export const getCommissionTypes = async ({
  filters,
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  filters?: Record<string, string[]>;
}): Promise<IResponse<ICommissionType[]>> => {
  try {
    const { data } = await axios.get('/api/commission-types', {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getSingleCommissionType = async (
  id: string | number,
  officeId?: string | null
): Promise<ICommissionType> => {
  try {
    const { data } = await axios.get(`/api/commission-types/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createCommissionType = async (
  body: ICommissionType,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/commission-types`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateCommissionType = async (
  body: ICommissionType,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/commission-types/${body.commissionTypeId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteCommissionType = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/commission-types/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
