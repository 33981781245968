import {
  CardTitle,
  SelectAsync,
  Loader,
  ReportPdfPreview,
  ReportParameterForm,
  Card,
} from '../../components';
import { Grid, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import {
  IResponse,
  IReport,
  IReportParameter,
  IGenerateReportParameter,
  ReportFormat,
} from '../../models';
import {
  getReportOptions,
  generateReportAndSave,
  getReportParameters,
  generateReport,
} from '../../fetch';
import { scrollToTop, downloadFile } from '../../helpers';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { REPORT_EMAIL_PARAMS } from '../../constants';

export const ReportDetails = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reportGuid, setReportGuid] = useState<string | null>(null);
  const [reportParams, setReportParams] = useState<IReportParameter[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const reportId = params.get('reportId');

  const loadReportParams = async (reportId: string) => {
    try {
      setIsLoading(true);
      setReportParams([]);
      const params = await getReportParameters(user?.officeId as string, reportId);
      setReportParams(params);
    } catch (e: any) {
      enqueueSnackbar(e?.Detail ?? 'An error occurred while generating your report', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateReportPreview = async (values: Record<string, string>, reportId: string) => {
    try {
      scrollToTop();
      setIsGenerating(true);
      setReportGuid(null);
      const parameters: IGenerateReportParameter[] = [];

      let sendEmailCheckboxValue = 'false';

      Object.entries(values).forEach(([key, value]) => {
        if (key === REPORT_EMAIL_PARAMS.EnhancedCustomerStatements.toLowerCase()) {
          sendEmailCheckboxValue = value;
        }
        parameters.push({
          reportParameterId: key,
          value: value,
        });
      });
      const generatedReportGuid = await generateReportAndSave(reportId, {
        parameters,
      });
      setReportGuid(generatedReportGuid);

      if (sendEmailCheckboxValue === 'true') {
        enqueueSnackbar('Email was successfully sent.', {
          variant: 'success',
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'An error occurred while generating your report', {
        variant: 'error',
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const downloadReportAsExcel = async (values: Record<string, string>, reportId: string) => {
    try {
      setIsExporting(true);
      const parameters: IGenerateReportParameter[] = [];
      Object.entries(values).forEach(([key, value]) => {
        parameters.push({
          reportParameterId: key,
          value: value,
        });
      });

      const res = await generateReport(
        reportId,
        {
          parameters,
        },
        ReportFormat.Xls
      );

      downloadFile(res, 'export.xls');
    } catch (e) {
      enqueueSnackbar('An error occurred while generating the report', {
        variant: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      loadReportParams(reportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        reportId: reportId ?? '',
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Card>
                  {isLoading && <Loader type="overlay" position="centered" />}
                  <CardTitle title="Report Settings" />
                  <SelectAsync
                    name="reportId"
                    label="Select Report"
                    required
                    apiRequest={() => getReportOptions({ perPage: -1, officeId: user?.officeId })}
                    transformResponse={(response: IResponse<IReport[]>) => {
                      return response.records.map(record => ({
                        label: record.description,
                        value: record.reportId,
                      }));
                    }}
                    hasClear
                    onClear={() => {
                      setReportParams([]);
                    }}
                    onChange={e => {
                      setFieldValue('reportId', e.target.value);
                      loadReportParams(e.target.value);
                    }}
                  />
                  <Box marginTop={3}>
                    <ReportParameterForm
                      parameters={reportParams}
                      onSubmit={data => generateReportPreview(data, values.reportId)}
                      onExport={data => downloadReportAsExcel(data, values.reportId)}
                      isSubmitting={isGenerating}
                      isExporting={isExporting}
                      isForm={false}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={9}>
                {isGenerating && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="30rem"
                    justifyContent="center"
                    sx={{ background: theme => theme.palette.common.white }}
                  >
                    <Loader position="centered" />
                  </Box>
                )}
                {reportGuid && <ReportPdfPreview reportGuid={reportGuid} />}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
