import { axios } from '../helpers';
import qs from 'querystring';
import {
  ICreateCustomView,
  IGetCustomViewResponse,
  ICustomCustomerView,
  ILookupModelResponse,
  IErrorResponse,
} from '../models';
import { userSignal } from '../signals';

export const getColumnNames = async (officeId?: string | null): Promise<ILookupModelResponse> => {
  return axios.get('/api/custom-views/column-names', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
};

export const getComparators = async (officeId?: string | null): Promise<ILookupModelResponse> => {
  return axios.get('/api/custom-views/comparators', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
};

export const getCustomView = async (
  viewId: string,
  officeId?: string | null
): Promise<IGetCustomViewResponse> => {
  return axios.get(`/api/custom-views/${viewId}`, {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
};

export const createCustomView = async (
  payload: ICreateCustomView,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/custom-views`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const updateCustomView = async (
  viewId: string,
  payload: ICreateCustomView,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.put(`/api/custom-views/${viewId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteCustomView = async (viewId: string): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.delete(`/api/custom-views/${viewId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCustomViews = async (filters?: {
  officeId?: string;
}): Promise<ICustomCustomerView[]> => {
  try {
    const { data } = await axios.get(`/api/custom-views`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
    });
    return data.sort((a: ICustomCustomerView, b: ICustomCustomerView) =>
      a.customViewDescription > b.customViewDescription ? 1 : -1
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
