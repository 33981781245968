import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { FC } from 'react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { IFilterInputProps } from '../../../../models';

interface ICurrencyInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const currencyMask = createNumberMask({ allowDecimal: true });

const CurrencyInput = forwardRef(function TextMaskCustom(props: ICurrencyInputProps, _ref) {
  const { onChange, ...other } = props;
  return <MaskedInput {...other} onChange={onChange} mask={currencyMask} />;
});

export const MoneyFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;
  const [value = ''] = values;
  
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange([e.target.value], filter);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      label={label}
      value={value}
      onChange={handleChange}
      name={name}
      InputProps={{
        inputComponent: CurrencyInput as any,
      }}
    />
  );
};
