import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IAccountAlert } from '../models';
import { userSignal } from '../signals';

export const getAccountAlerts = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<IAccountAlert[]>> => {
  try {
    const { data } = await axios.get(`/api/account-alert-defs`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAcountAlert = async (
  id: string | number,
  officeId?: string | null
): Promise<IAccountAlert> => {
  try {
    const { data } = await axios.get(`/api/account-alert-defs/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createAccountAlert = async (
  body: IAccountAlert,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/account-alert-defs`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateAccountAlert = async (
  body: IAccountAlert,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/account-alert-defs/${body.accountAlertDefId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteAccountAlert = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/account-alert-defs/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
