import { FC } from 'react';
import clsx from 'clsx';
import { Grid, SxProps } from '@mui/material';

// Custom component for display columns, to reduce duplicated code and make it easier to update all at once
export interface IAccountDetailsCol {
  children: any;
  className?: string;
  printClasses?: string;
  xs?: number;
  sm?: number;
  md?: number;
  xl?: number;
  disableMargin?: boolean;
  wrapperStyles?: SxProps;
}

export const AccountDetailsCol: FC<IAccountDetailsCol> = ({
  children,
  className,
  printClasses = 'col-print-6',
  wrapperStyles,
  xs = 12,
  sm = 6,
  md = 4,
  xl = 3,
}) => {
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      xl={xl}
      className={clsx('print--avoid-break', printClasses, className)}
      sx={wrapperStyles}
    >
      {children}
    </Grid>
  );
};
