import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import { ISite } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

interface SitesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: ISite[];
  refetch: () => void;
  handleView: (site: ISite) => void;
  handleEdit: (site: ISite) => void;
  handleDelete: (site: ISite) => Promise<void>;
  isLoading: boolean;
}

export const SitesDataGrid: FC<SitesDataGridProps> = ({
  rows,
  refetch,
  handleView,
  handleEdit,
  handleDelete,
  isLoading,
  ...gridProps
}) => {
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'description',
        headerName: 'Site Description',
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
      },
      {
        field: 'street',
        headerName: 'Street',
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<ISite>) => {
          const { row: site } = params;
          return site.address.street ? <>{site.address.street}</> : <></>;
        },
      },
      {
        field: 'city',
        headerName: 'City',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<ISite>) => {
          const { row: site } = params;
          return site.address.city ? <>{site.address.city}</> : <></>;
        },
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 1,
        minWidth: 50,
        renderCell: (params: GridRenderCellParams<ISite>) => {
          const { row: site } = params;
          return site.address.state ? <>{site.address.state}</> : <></>;
        },
      },
      {
        field: 'zip',
        headerName: 'Zip Code',
        flex: 1,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams<ISite>) => {
          const { row: site } = params;
          return site.address.zipCode ? <>{site.address.zipCode}</> : <></>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        minWidth: 150,
        maxWidth: 300,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ISite>) => {
          const { row: site } = params;
          return (
            <Box display="flex" gap={1}>
              <IconButton color="secondary" title="View Site" onClick={() => handleView(site)}>
                <FontAwesomeIcon icon={faEye} size="sm" />
              </IconButton>
              <IconButton color="primary" title="Edit Site" onClick={() => handleEdit(site)}>
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              {site.canDelete && (
                <IconButton
                  color="error"
                  title="Delete Site"
                  onClick={async () => handleDelete(site)}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: ISite) => row.siteId!}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      noResultsMessage="There are no sites to display."
      loading={isLoading}
      hasMobileLayout
      mobileProps={{
        handleView: (val: ISite) => {
          handleView(val);
        },
        handleEdit: (val: ISite) => {
          handleEdit(val);
        },
        handleDelete: (val: ISite) => {
          handleDelete(val);
        },
      }}
      {...gridProps}
    />
  );
};
