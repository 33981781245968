import { Page } from '../../components';

import { VerifyAddressesPageDetails } from './verify-addresses-page-details';

export const VerifyAddresses = () => {
  return (
    <Page title="Verify Addresses">
      <VerifyAddressesPageDetails />
    </Page>
  );
};
