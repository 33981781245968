import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuidePageTitle = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Page Title">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The page title component was built to render the page title of the page (h1) with
            various different configurations, including subTitle.
          </Typography>
          <Typography mb={1}>
            This component is used for all pages, however, it is specifically meant to describe the
            module a page belongs to (i.e. Customers). If there is more than one page belonging to a
            module, then the page title should be followed by an{' '}
            <Link to="/kitchen-sink/components/icon-page-heading">Icon Page Heading</Link>.
          </Typography>
          <code>
            Props:
            <br />- title?: string
            <br />- marginOff?: boolean
            <br />- centerHeader?: boolean
            <br />- button?: JSX.Element
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={1}>
            <PageTitle title="Custom Page Title" style={{ height: '3rem' }} />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;PageTitle title="Custom Page Title" /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
