import { GridSortDirection } from '@mui/x-data-grid';
import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  ISearchAccountResult,
  ISearchResults,
  ISearchWaterTestAccountResult,
  ISiteSearchResult,
} from '../models';
import { userSignal } from '../signals';

export const getGlobalSearchResults = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  search: string;
}): Promise<ISearchResults> => {
  try {
    const { data } = await axios.get(`/api/search/global`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSiteSearchResults = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  search: string;
}): Promise<IResponse<ISiteSearchResult[]>> => {
  try {
    const { data } = await axios.get(`/api/search/sites`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountSearchResults = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  search: string;
}): Promise<IResponse<ISearchAccountResult[]>> => {
  try {
    const { data } = await axios.get(`/api/search/accounts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getWaterTestAccountSearchResults = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  search: string;
}): Promise<IResponse<ISearchWaterTestAccountResult[]>> => {
  try {
    const { data } = await axios.get(`/api/search/water-test-accounts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
