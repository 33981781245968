import { FC, useContext, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
// Components
import { Modal, Loader } from '../../components';
import {
  Box,
  Fade,
  Typography,
  Divider,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@mui/material';
// fetch
import {
  getEquipmentStatuses,
  getEquipmentTypes,
  getEquipmentManufacturers,
  getEquipmentModels,
  getSiteParentEquipment,
  updateEquipmentDetail,
  createEquipment,
} from '../../fetch';
import { IDropdownResponse } from '../../models';
import { Select, TextField } from '../../components/formikMui';
import { DatePicker } from '@mui/x-date-pickers';
import { UserContext } from '../../context';
import {
  IEquipmentManufacturer,
  IEquipmentModel,
  IEquipmentType,
  IParentEquipment,
  ISiteEquipment,
} from '../../models/equipment';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';

interface ISiteEquipmentModal {
  open: boolean;
  onClose: () => void;
  currentEquipment: ISiteEquipment | null;
  siteId: string | number;
  isLoading?: boolean;
  reloadList: () => void;
}

const SiteEquipmentSchema = Yup.object().shape({
  status: Yup.string(),
  manufacturerId: Yup.string().nullable(),
  equipmentTypeId: Yup.string().nullable(),
  installedOn: Yup.string().nullable(),
  whenRemoved: Yup.string().nullable(),
  modelId: Yup.string().nullable(),
  parentEquipmentId: Yup.string().nullable(),
  serialNumber: Yup.string().max(255, 'Max 255 characters').nullable(),
  warrantyEnds: Yup.string().nullable(),
  partNumber: Yup.string().max(255, 'Max 255 characters').nullable(),
  notes: Yup.string().max(255, 'Max 255 characters'),
});

export const SiteEquipmentModal: FC<ISiteEquipmentModal> = ({
  open,
  onClose,
  currentEquipment,
  siteId,
  isLoading = false,
  reloadList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();

  //Dropdowns
  const [, setIsLoadingEquipmentStatuses] = useState(false);
  const [statuses, setStatuses] = useState<IDropdownResponse[]>([]);
  const fetchEquipmentStatuses = async () => {
    setIsLoadingEquipmentStatuses(true);
    try {
      const res = await getEquipmentStatuses();
      setStatuses(res);
    } catch (error) {
      enqueueSnackbar(`Error loading equipment statuses, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEquipmentStatuses(false);
    }
  };

  const [, setIsLoadingEquipmentTypes] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const fetchEquipmentTypes = async () => {
    setIsLoadingEquipmentTypes(true);
    try {
      const res = await getEquipmentTypes({ perPage: -1, officeId: user?.officeId });
      setEquipmentTypes(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading equipment types, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEquipmentTypes(false);
    }
  };

  const [, setIsLoadingEquipmentManufacturers] = useState(false);
  const [equipmentManufacturers, setEquipmentManufacturers] = useState<IEquipmentManufacturer[]>(
    []
  );
  const fetchEquipmentManufacturers = async () => {
    setIsLoadingEquipmentManufacturers(true);
    try {
      const res = await getEquipmentManufacturers({ perPage: -1, officeId: user?.officeId });
      setEquipmentManufacturers(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading equipment manufacturers, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEquipmentManufacturers(false);
    }
  };

  const [, setIsLoadingEquipmentModels] = useState(false);
  const [equipmentModels, setEquipmentModels] = useState<IEquipmentModel[]>([]);
  const fetchEquipmentModels = async (
    equipmentTypeId: string,
    equipmentManufacturerId: string | null
  ) => {
    setIsLoadingEquipmentModels(true);
    try {
      const res = await getEquipmentModels(equipmentTypeId, {
        perPage: -1,
        equipmentManufacturerId: equipmentManufacturerId,
      });
      setEquipmentModels(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading equipment models, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEquipmentModels(false);
    }
  };

  const [, setIsLoadingParentEquipment] = useState(false);
  const [parentEquipment, setParentEquipment] = useState<IParentEquipment[]>([]);
  const fetchParentEquipment = async () => {
    setIsLoadingParentEquipment(true);
    try {
      const res = await getSiteParentEquipment(siteId);
      setParentEquipment(res);
    } catch (error) {
      enqueueSnackbar(`Error loading parent equipment, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingParentEquipment(false);
    }
  };

  useEffect(() => {
    fetchEquipmentStatuses();
    fetchEquipmentTypes();
    fetchEquipmentManufacturers();
    fetchParentEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, currentEquipment]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          status: currentEquipment?.status ?? 'InUse',
          manufacturerId: currentEquipment?.manufacturerId ?? '',
          equipmentTypeId:
            currentEquipment?.equipmentTypeId ?? '70c58a8a-6934-49c6-8b79-73283a8a86fb',
          installedOn: currentEquipment?.installedOn ?? '',
          whenRemoved: currentEquipment?.whenRemoved ?? '',
          modelId: currentEquipment?.modelId ?? '',
          parentEquipmentId: currentEquipment?.parentEquipmentId ?? '',
          serialNumber: currentEquipment?.serialNumber ?? '',
          warrantyEnds: currentEquipment?.warrantyEnds ?? '',
          partNumber: currentEquipment?.partNumber ?? '',
          notes: currentEquipment?.notes ?? '',
        }}
        validationSchema={SiteEquipmentSchema}
        onSubmit={async (values, actions) => {
          try {
            const res = currentEquipment
              ? await updateEquipmentDetail(siteId, currentEquipment?.equipmentId, values)
              : await createEquipment(siteId, values);
            if (res?.Detail) {
              return enqueueSnackbar(`${res.Detail}`, { variant: 'error' });
            }
            enqueueSnackbar(
              `Successfully ${!currentEquipment ? 'created' : 'updated'} site equipment!`,
              {
                variant: 'success',
              }
            );
            onClose();
            reloadList();
            actions.resetForm();
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          handleChange,
        }) => {
          const reset = async (resetForm: any) => {
            if (!deepEqual(initialValues, values)) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                resetForm();
                onClose();
              } else {
                return;
              }
            } else {
              onClose();
              resetForm();
            }
          };
          return (
            <Modal
              open={open}
              onClose={() => {
                reset(resetForm);
              }}
              maxWidth="lg"
            >
              {isSubmitting && <Loader type="overlay" position="centered" title="Saving..." />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  {isLoading && (
                    <Box height="10rem">
                      <Loader position="centered" type="inline" />
                    </Box>
                  )}
                  {!isLoading && (
                    <>
                      <Box marginBottom="2rem">
                        <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                          View Site Equipment
                        </Typography>
                        <Divider />
                      </Box>
                      <div className={classes.content}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Select
                              disabled
                              fullWidth
                              size="small"
                              autoComplete="nope"
                              label="Status"
                              name="status"
                            >
                              {statuses.map(status => (
                                <MenuItem key={`status-${status.value}`} value={status.value}>
                                  {status.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <MuiTextField
                              disabled
                              fullWidth
                              select
                              size="small"
                              autoComplete="nope"
                              label="Equipment Type"
                              name="equipmentTypeId"
                              value={values.equipmentTypeId}
                              onChange={e => {
                                setFieldValue('equipmentTypeId', e.target.value);
                                fetchEquipmentModels(
                                  e.target.value,
                                  !!values.manufacturerId ? values.manufacturerId : null
                                );
                                setFieldValue('modelId', ''); //Reset field, in case the value they selected is no longer available
                              }}
                            >
                              {equipmentTypes.map(type => (
                                <MenuItem
                                  key={`equipment-type-${type.equipmentTypeId}`}
                                  value={type.equipmentTypeId}
                                >
                                  {type.description}
                                </MenuItem>
                              ))}
                            </MuiTextField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <MuiTextField
                              disabled
                              fullWidth
                              select
                              size="small"
                              autoComplete="nope"
                              label="Manufacturer"
                              name="manufacturerId"
                              value={values.manufacturerId}
                              onChange={e => {
                                setFieldValue('manufacturerId', e.target.value);
                                if (!!values.equipmentTypeId) {
                                  fetchEquipmentModels(values.equipmentTypeId, e.target.value);
                                  setFieldValue('modelId', ''); //Reset field, in case the value they selected is no longer available
                                }
                              }}
                            >
                              {equipmentManufacturers.map(manufacturer => (
                                <MenuItem
                                  key={`manufacturer-${manufacturer.equipmentManufacturerId}`}
                                  value={manufacturer.equipmentManufacturerId}
                                >
                                  {manufacturer.description}
                                </MenuItem>
                              ))}
                            </MuiTextField>
                          </Grid>
                          {/* Only show field if the prerequisite dropdown has a selected value,
                              and there are available options for this dropdown  */}
                          {!!values.equipmentTypeId && equipmentModels?.length > 0 && (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Select
                                disabled
                                fullWidth
                                size="small"
                                autoComplete="nope"
                                label="Model"
                                name="modelId"
                              >
                                {equipmentModels.map(model => (
                                  <MenuItem
                                    key={`model-${model.equipmentModelId}`}
                                    value={model.equipmentModelId}
                                  >
                                    {model.description}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Select
                              disabled
                              fullWidth
                              size="small"
                              autoComplete="nope"
                              label="SubItem Of"
                              name="parentEquipmentId"
                            >
                              {parentEquipment.map(parent => (
                                <MenuItem
                                  key={`parent-equipment-${parent.equipmentId}`}
                                  value={parent.equipmentId}
                                >
                                  {parent.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                              disabled
                              label="Installed"
                              format="MM/dd/yyyy"
                              onChange={(date: any) => {
                                setFieldValue('installedOn', date);
                              }}
                              value={values.installedOn ? new Date(values.installedOn) : null}
                              maxDate={new Date()}
                              slotProps={{
                                textField: {
                                  error: !!errors?.installedOn && !!touched?.installedOn,
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                              disabled
                              label="Removed"
                              format="MM/dd/yyyy"
                              onChange={(date: any) => {
                                setFieldValue('whenRemoved', date);
                              }}
                              value={values.whenRemoved ? new Date(values.whenRemoved) : null}
                              maxDate={new Date()}
                              slotProps={{
                                textField: {
                                  error: !!errors?.whenRemoved && !!touched?.whenRemoved,
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                              disabled
                              label="Warranty Ends"
                              format="MM/dd/yyyy"
                              onChange={(date: any) => {
                                setFieldValue('warrantyEnds', date);
                              }}
                              value={values.warrantyEnds ? new Date(values.warrantyEnds) : null}
                              slotProps={{
                                textField: {
                                  error: !!errors?.warrantyEnds && !!touched?.warrantyEnds,
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TextField
                              disabled
                              fullWidth
                              size="small"
                              autoComplete="nope"
                              label="Serial Number"
                              name="serialNumber"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TextField
                              disabled
                              fullWidth
                              size="small"
                              autoComplete="nope"
                              label="Part Number"
                              name="partNumber"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled
                              fullWidth
                              multiline
                              rows={4}
                              size="small"
                              autoComplete="nope"
                              label="Notes"
                              name="notes"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2,
  },
  content: {
    marginTop: theme.spacing(1),
  },
  cardContent: {
    paddingTop: 0,
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));
