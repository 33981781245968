import { axios } from '../helpers';
import qs from 'querystring';
import {
  IAccountAgingReport,
  IResponse,
  IErrorResponse,
  IAccountDetail,
  IAccountRate,
  IRatePayload,
  IAccountDetailFormValues,
  IAccoutResponse,
  IDropdownResponse,
  IReferralType,
  IBillingGroup,
  ISalesTax,
  IAdditionalInfoField,
  IAccountAddress,
  IAccountTransaction,
  IAccountSearch,
  IInitialEmailFormValues,
  IAccountPostTransaction,
  IAccountQuickCreateValues,
  IAccountSimple,
  IDuplicateAccountCheck,
  IDuplicateAccount,
} from '../models';
import { userSignal } from '../signals';

export const getAccounts = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  search?: string;
  officeId?: string | null;
  filterLetter?: string;
  customViewId?: string;
  accountType?: string;
  isInitialSort?: boolean;
}): Promise<IAccoutResponse> => {
  try {
    const { data } = await axios.get(`/api/accounts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountsSimple = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
  status?: string[] | null;
}): Promise<IResponse<IAccountSimple[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/simple`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgingReportBySite = async (
  siteId: string,
  filters?: {
    agingDate?: string;
    agingCutoffDate?: string;
  },
  officeId?: string | null
): Promise<IAccountAgingReport> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}/aging-report`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgingReportByAccount = async (
  accountId: string,
  filters?: {
    agingDate?: string;
    agingCutoffDate?: string;
  },
  officeId?: string | null
): Promise<IAccountAgingReport> => {
  try {
    const { data } = await axios.get(`/api/accounts/${accountId}/aging-report`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccount = async (
  customerId: string,
  officeId?: string | null
): Promise<IAccountDetail> => {
  try {
    const { data } = await axios.get(`/api/accounts/${customerId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountAddress = async (
  customerId: string,
  officeId?: string | null
): Promise<IAccountAddress> => {
  try {
    const { data } = await axios.get(`/api/accounts/${customerId}/address`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountLetterFilterOptions = async (filters?: {
  officeId?: string | null;
}): Promise<string[]> => {
  try {
    const { data } = await axios.get(`/api/accounts/letter-filter`, {
      params: {
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountRates = async (
  accountId: string,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number;
  },
  officeId?: string | null
): Promise<IResponse<IAccountRate[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/${accountId}/rates`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRates = async (filters?: {
  officeId?: string;
  isDeleted?: boolean;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IAccountRate[]>> => {
  try {
    const { data } = await axios.get(`/api/rates`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAccountRate = async (rateId: string, accountId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/accounts/${accountId}/rates/${rateId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAccountRate = async (
  accountId: string,
  payload: IRatePayload,
  officeId?: string | null
): Promise<IErrorResponse> => {
  payload.sortOrder = payload.sortOrder || 0;

  try {
    const { data } = await axios.post(`/api/accounts/${accountId}/rates`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

// The usual way of doing this does not support updating multiple rates at the same time.
// This is because the await keyword inside each function call waits for each request to complete.
// This way returns a promise instead of waiting for a response.
export const updateAccountRate = (
  accountId: string,
  accountRateId: string,
  payload: IRatePayload,
  officeId?: string | null
): Promise<IErrorResponse> => {
  payload.sortOrder = payload.sortOrder || 0;

  return axios.put(`/api/accounts/${accountId}/rates/${accountRateId}`, {
    ...payload,
    officeId: officeId ?? userSignal.value?.officeId ?? '',
  });
};

export const createAccount = async (
  payload: IAccountDetailFormValues,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.post(`/api/accounts`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const createQuickAccount = async (
  payload: IAccountQuickCreateValues,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(`/api/accounts`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const updateAccount = async (
  id: number | string,
  payload: IAccountDetailFormValues,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`/api/accounts/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getAccountStatuses = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/accounts/statuses`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountPhoneTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/accounts/phone-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountTypes = async (officeId?: string | null): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/accounts/types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReferralTypes = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<IReferralType[]>> => {
  try {
    const { data } = await axios.get(`/api/referral-types`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBillingGroups = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<IBillingGroup[]>> => {
  try {
    const { data } = await axios.get(`/api/billing-groups`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSalesTaxes = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<ISalesTax[]>> => {
  try {
    const { data } = await axios.get(`/api/sales-taxes`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAdditionalInfoFields = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<IAdditionalInfoField[]>> => {
  try {
    const { data } = await axios.get(`/api/user-defined-account-defs`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountTransactions = async (
  accountId: string,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number;
  },
  officeId?: string | null
): Promise<IResponse<IAccountTransaction[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/${accountId}/transactions`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const searchAccounts = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  keyword?: string | null;
  officeId?: string | null;
}): Promise<IResponse<IAccountSearch[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/search`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendStatementEmail = async (
  accountId: string,
  payload: IInitialEmailFormValues,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post(`/api/accounts/${accountId}/enhanced-statement-email`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createAccountTransaction = async (
  accountId: string,
  payload: IAccountPostTransaction,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post(`/api/accounts/${accountId}/transactions`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getWaterTestAccountId = async (
  waterTestCustomerId: string | number,
  officeId?: string | null
): Promise<string | null> => {
  try {
    const { data } = await axios.get(`/api/accounts/water-test/${waterTestCustomerId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountBalance = async (
  customerId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/accounts/${customerId}/balance`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const checkDuplicateAccounts = async (
  payload: IDuplicateAccountCheck,
  officeId?: string | null
): Promise<IErrorResponse | IResponse<IDuplicateAccount[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/check-duplicates`, {
      params: {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};
