import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { PrimaryIcon } from './primary-icon';

interface IPrimaryIconButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon: IconProp;
}

export const PrimaryIconButton: FC<IPrimaryIconButton> = ({ onClick, icon }) => {
  return (
    <IconButton color="secondary" sx={{ padding: 0 }} onClick={onClick}>
      <PrimaryIcon icon={icon} />
    </IconButton>
  );
};
