import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Close } from '@mui/icons-material';
import { Box, Button, FormLabel, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { useConfirm } from '../../hooks';

interface IDocumentUpload {
  handleFileChange: (val: any, file?: File) => void;
  disabled?: boolean;
  isRequired?: boolean;
  showSource?: boolean;
  handleUploadClick?: () => void;
  label?: string;
  isEditable?: boolean;
  id: string;
  buttonLabel?: string;
}

export const DocumentUpload: FC<IDocumentUpload> = ({
  handleUploadClick,
  handleFileChange,
  disabled = false,
  isRequired = false,
  showSource = true,
  label,
  isEditable = true,
  id,
  buttonLabel,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [source, setSource] = useState<string>('');
  const [fileName, setFileName] = useState<string>('document.pdf');

  const createTempFile = async (file: Blob) => {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCapture = async (target: EventTarget & HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const files = [];
        const base64Docs: Array<{
          title: string;
          urlPath: string;
          contents: string;
          isUploaded: boolean;
          file: File;
        }> = [];
        // Get fileList Object, convert to an array and get the values before they disappear
        // https://stackoverflow.com/questions/25333488/why-isnt-the-filelist-object-an-array
        const allFiles: File[] = Array.from(target.files);
        const fileLength = target.files.length;
        for (let i = 0; i < fileLength; i++) {
          const file = allFiles[i];
          const base64 = (await createTempFile(file)) as string;
          const newUrl = URL.createObjectURL(file);

          files.push(file);
          base64Docs.push({
            title: file.name,
            urlPath: newUrl,
            contents: base64.split(',')[1],
            isUploaded: false,
            file: file,
          });
        }
        handleFileChange(base64Docs[0], files[0]);
        setSource(base64Docs[0].title);
        setFileName(base64Docs[0].title);
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.buttonsContainer}>
        {!!label && (
          <FormLabel required={isRequired} className={classes.heading}>
            {label}
          </FormLabel>
        )}
        {isRequired && !source && (
          <Typography variant="caption" className={classes.helpText}>
            Uploading a file is required.
          </Typography>
        )}
        {source && showSource && (
          <>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <>{fileName}</>
              {isEditable && (
                <Button
                  onClick={async () => {
                    const result = await confirm('Are you sure you want to remove this file?');
                    if (result) {
                      setSource('');
                      handleFileChange('');
                    }
                  }}
                  className={classes.removeButton}
                  startIcon={<Close />}
                  data-testid="remove-file-button"
                >
                  Remove File
                </Button>
              )}
            </Box>
          </>
        )}
        <input
          disabled={disabled}
          className={classes.input}
          id={id}
          type="file"
          accept=".pdf,.tiff,.xlsx,.xls,.doc,.docx"
          capture="environment"
          onChange={e => {
            handleCapture(e.target);
            // reset the input value so you can re-upload the same photo if you want to
            e.target.value = '';
          }}
          data-testid="document-file-name-field"
        />

        {(!source || !showSource) && (
          <Box>
            <label htmlFor={id}>
              <Button
                onClick={handleUploadClick}
                disabled={disabled}
                startIcon={<FontAwesomeIcon icon={faFile} size="lg" />}
                size={'large'}
                variant={'contained'}
                component={'span'}
                data-testid="upload-document-button"
              >
                <span>{buttonLabel ?? 'UPLOAD DOCUMENT'}</span>
              </Button>
            </label>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  heading: {
    display: 'block',
    marginBottom: theme.spacing(0.75),
  },
  buttonsContainer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  imgBox: {
    maxWidth: '100%',
    flexDirection: 'column',
  },
  img: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  removeButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  helpText: {
    color: theme.palette.error.main,
  },
  button: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));
