import qs from 'qs';
import { axios } from '../helpers';
import { IErrorResponse, IResponse } from '../models';
import { IServiceLocation, IServiceLocationUpdate } from '../models/service-locations';
import { userSignal } from '../signals';

export const getServiceLocations = async (filters?: {
  officeId?: string | null;
  search?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IServiceLocation[]>> => {
  try {
    const { data } = await axios.get(`/api/service-locations`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateServiceLocation = async (
  data: IServiceLocationUpdate,
  serviceLocationId: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = data;
  try {
    const { data } = await axios.put(`/api/service-locations/${serviceLocationId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createServiceLocation = async (
  data: IServiceLocationUpdate,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = data;
  try {
    const { data } = await axios.post(`/api/service-locations`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteServiceLocation = async (serviceLocationId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/service-locations/${serviceLocationId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
