import { axios } from '../helpers';
import qs from 'qs';
import {
  IServiceRoute,
  IServiceRouteDetail,
  IResponse,
  IOptimizeRouteLaterPayload,
  IOptimizeRoutePayload,
  IPayloadRoute,
  IUpdateMode,
  IUpdateRoutesPayload,
  IUpdateRoutesResponse,
  IOptimizedServiceRoutesResponse,
} from '../models';
import { userSignal } from '../signals';

export const getServiceRoutes = async (
  req: {
    startDate?: string;
    endDate?: string;
    userIds?: string[];
    sortBy?: number;
    sortDirection?: number;
    page?: number;
    perPage?: number;
    officeId?: string | null;
  } = {}
): Promise<IResponse<IServiceRoute[]>> => {
  try {
    const { ...filters } = req;
    const { data } = await axios.get(`/api/routes/full-routes`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (queryParams: any) =>
        qs.stringify(queryParams, { arrayFormat: 'brackets' }),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getServiceRouteDetails = async (
  serviceRouteId: string | number,
  filters?: { userId?: string[]; officeId?: string | null }
): Promise<IServiceRouteDetail> => {
  try {
    const { data } = await axios.get(`/api/routes/${serviceRouteId}`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateServiceRoutes = async (
  payload: IUpdateRoutesPayload,
  officeId?: string | null
): Promise<IUpdateRoutesResponse> => {
  try {
    const { data } = await axios.put(`/api/routes`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postRoutesToOptimize = async (
  payload: IOptimizeRoutePayload,
  officeId?: string | null
): Promise<IOptimizedServiceRoutesResponse> => {
  try {
    const { data } = await axios.post(`/api/routes/optimize`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const postRoutesToOptimizeLater = async (
  payload: IOptimizeRouteLaterPayload,
  officeId?: string | null
): Promise<IPayloadRoute[]> => {
  try {
    const { data } = await axios.post(`/api/routes/optimize-later`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOptimizeRoutesMethods = async (officeId?: string | null): Promise<any> => {
  try {
    const { data } = await axios.get(`/api/routes/optimize-methods`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getOptimizedRoute = async (
  optimizedRouteId: string,
  officeId?: string | null
): Promise<IServiceRoute[]> => {
  try {
    const { data } = await axios.get(`/api/routes/optimizations/${optimizedRouteId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getUpdateModes = async (officeId?: string | null): Promise<IUpdateMode[]> => {
  const { data } = await axios.get('/api/routes/update-modes', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  return data;
};
