import React, { Component } from 'react';
import { Fade, Button, Box } from '@mui/material';
import { Modal } from '../modal';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
}
class ErrorBoundaryBase extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.toString() };
  }

  // this block can be used to log the errors in the future
  // componentDidCatch(error: Error, info: ErrorInfo) {}

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Modal
          open={true}
          onClose={() => null}
          showCloseButton={false}
          title="Something went wrong. Please refresh and try again."
          maxWidth="sm"
        >
          <Fade in={true}>
            <div>
              <Box display="flex" justifyContent="center" mt={1}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => window.location.reload()}
                >
                  Ok
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      );
    }

    // If there is no error just render the children component.
    return children;
  }
}

export const ErrorBoundary = ErrorBoundaryBase;
