import { FC } from 'react';
import { TextField, Checkbox } from '../../../../components/formikMui';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { IPoolEquipment } from '../../../../models';

interface IPumpFilterCleanerFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

export const PumpFilterCleanerForm: FC<IPumpFilterCleanerFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the PumpFilterCleanerDisplay component as well
  // REMINDER: When adding fields, also update the pumpFilterCleanerSectionFields array in pool-structure-card
  const { handleBlur, setFieldValue, values } = useFormikContext<IPoolEquipment>();
  return (
    <Box mt={2.5}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="pumpBasket"
            label="Pump Basket"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="airRelief"
            label="Air Relief"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="poolMotor"
            label="Pool Motor"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="cleanerStyle"
            label="Cleaner Style"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="poolHp"
            label="Pool HP"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="cleanerPump"
            label="Cleaner Pump"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <FormControlLabel
            control={
              <Checkbox
                name="hasConduit"
                disabled={readOnly}
                onChange={e => {
                  setFieldValue('hasConduit', Boolean(e.target.checked));
                  handleFormChange({ ...values, hasConduit: Boolean(e.target.checked) });
                }}
              />
            }
            label={<Typography variant="body2">Conduit</Typography>}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="cleanerClock"
            label="Cleaner Clock"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="conduitCondition"
            label="Conduit Condition"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <FormControlLabel
            control={
              <Checkbox
                name="autoCleaner"
                disabled={readOnly}
                onChange={e => {
                  setFieldValue('autoCleaner', Boolean(e.target.checked));
                  handleFormChange({ ...values, autoCleaner: Boolean(e.target.checked) });
                }}
              />
            }
            label={<Typography variant="body2">Auto Cleaner</Typography>}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="timeClock"
            label="Time Clock"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="cleanerVolts"
            label="Cleaner Volts"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="timeClockVolts"
            label="Time Clock Volts"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1} />
      </Stack>
    </Box>
  );
};
