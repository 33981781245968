import { IAccountQuickCreateValues, ISalesTax, IUser } from '../../models';
import { createQuickAccount, getSitesSimple } from '../../fetch';
import { getAreaCode, removeAreaCode, convertToNumber, alphaSort } from '../../helpers';
import { format } from 'date-fns';

export const handleCreateNewCustomer = async (
  newCustomer: IAccountQuickCreateValues,
  enqueueSnackbar: any,
  user: IUser
): Promise<string> => {
  const formattedPhone = [
    {
      phoneNumberType: 'Home',
      phoneNumber: newCustomer?.phone ? removeAreaCode(newCustomer?.phone) : '',
      areaCode: newCustomer?.phone ? getAreaCode(newCustomer?.phone) : '',
      isPrimary: true,
    },
  ];
  const data = {
    //Defaults
    dateStarted: format(new Date(), 'MM/dd/yyyy'),
    accountType: 'Owner',
    officeId: user?.officeId,
    primaryPhoneNumberType: 'Home',
    //General Info
    accountName: newCustomer.accountName,
    firstName: newCustomer.firstName,
    lastName: newCustomer.lastName,
    //Contact Info
    phoneNumbers: formattedPhone ?? [],
    emails: !!newCustomer.email ? [newCustomer.email] : [],
    address: {
      addressName: newCustomer.addressName ? newCustomer.addressName : null,
      street: newCustomer.street ? newCustomer.street : null,
      city: newCustomer.city ? newCustomer.city : null,
      state: newCustomer.state ? newCustomer.state : null,
      postalCode: newCustomer.postalCode ? newCustomer.postalCode : null,
      longitude: newCustomer.longitude ? convertToNumber(newCustomer.longitude) : undefined,
      latitude: newCustomer.latitude ? convertToNumber(newCustomer.latitude) : undefined,
      copyAddressToSite: true,
    },

    status: newCustomer.status,
    billingGroupId: newCustomer.billingGroupId,
    salesTaxId: newCustomer.salesTaxId
  };
  const newCustomerId = await createQuickAccount(data);
  if (newCustomerId?.Detail) {
    return enqueueSnackbar(`Error, ${newCustomerId?.Detail}`, {
      variant: 'error',
    });
  }
  enqueueSnackbar(`Successfully created customer!`, {
    variant: 'success',
  });
  return newCustomerId ?? '';
};

export const handleGetNewCustomerSite = async (id: string, user: IUser): Promise<string> => {
  const customerSites = await getSitesSimple({
    accountId: id,
    perPage: -1,
    officeId: user?.officeId,
  });
  return customerSites?.records?.[0]?.siteId ?? '';
};
export const getDefaultSalesTaxId = (salesTaxes?:ISalesTax[]) =>{

      if (salesTaxes && salesTaxes.length > 0) {
        
        if (salesTaxes.length === 1) {
          return salesTaxes[0].salesTaxId;
        }
    
        if (salesTaxes.length === 2) {
          const nonZeroTaxRate = salesTaxes.find(val => val.taxRate !== 0);
          return nonZeroTaxRate ? nonZeroTaxRate.salesTaxId : '';
        }
    
        return alphaSort(salesTaxes, 'code')[0].salesTaxId;
      }
  
      return '';
};

