import { Page } from '../../components';
import { LoginDetails } from './login-details';

export const Login = () => {
  return (
    <Page title="Login" isExternal={true} customPageHeader={true}>
      <LoginDetails />
    </Page>
  );
};
