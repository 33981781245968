import { useContext } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { Formik, FormikHelpers, FormikProps, Form } from 'formik';
import { FullPageImageLayout, PublicPageTitle, AuthInput } from '../../components';
import { UserContext } from '../../context';
import { sendResetLink } from '../../fetch';

interface IForgotPasswordValues {
  email: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Required'),
});

export const ForgotPasswordDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isFetching } = useContext(UserContext);
  const history = useHistory();

  const handleOnSubmit = () => {
    enqueueSnackbar(`Redirecting to Login...`, {
      variant: 'info',
    });
    setTimeout(function () {
      history.push(`/`);
    }, 3000);
  };

  // have flag and checking state of user
  if (isFetching) {
    return null;
  }
  // have flag and we have a user and they are loaded redirect to correct page
  if (user && !isFetching) {
    return <Redirect to="/" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async (
        values: IForgotPasswordValues,
        actions: FormikHelpers<IForgotPasswordValues>
      ) => {
        try {
          await sendResetLink({
            email: values.email,
          });
          enqueueSnackbar(
            'If you are a user that exists in our system we will send you an email.',
            {
              variant: 'success',
              onClose: () => {
                handleOnSubmit();
              },
            }
          );
          actions.resetForm({
            values: {
              email: '',
            },
          });
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? 'Error sending reset link, please try again', {
            variant: 'error',
          });
          actions.resetForm();
          const emailInput = document.getElementById('email');
          if (emailInput) {
            emailInput.focus();
          }
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={ForgotPasswordSchema}
    >
      {({
        dirty,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        touched,
        errors,
        values,
        setFieldValue,
      }: FormikProps<IForgotPasswordValues>) => (
        <FullPageImageLayout>
          <PublicPageTitle
            title="Forgot Password"
            subTitle="Enter your email address below and we will email you a forgot password request."
          />
          <Form data-testid="forgot-password-form" onSubmit={handleSubmit}>
            <Box
              mt={{
                xs: 2,
                sm: 4,
              }}
              mb={4}
            >
              <AuthInput
                errors={errors}
                values={values}
                touched={touched}
                label="Email"
                inputKey="email"
                id="forgot-password"
                onChange={e => {
                  setFieldValue('email', e.target.value);
                }}
                onBlur={handleBlur}
              />
            </Box>

            <Button
              // @ts-ignore
              color="tertiary"
              size="large"
              fullWidth
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
                padding: theme => theme.spacing(1.5),
                borderRadius: '30px',
              }}
              disabled={!dirty || isSubmitting || !isValid}
              id="submit"
              type="submit"
              onKeyDown={(e: { key: any }) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              {!isSubmitting ? 'Send Reset Link' : 'Sending...'}
            </Button>
          </Form>
        </FullPageImageLayout>
      )}
    </Formik>
  );
};
