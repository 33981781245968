import { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { getAccountLetterFilterOptions } from '../../fetch';
import { UserContext } from '../../context';

interface ICustomerssFiltersProps {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setSelectedLetterOption: (val: string) => void;
  selectedLetterOption: string;
  isDisabled: boolean;
  searchValue: string;
  searchedValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSearchedValue: (val: string) => void;
}

export const CustomersFilters: FC<ICustomerssFiltersProps> = ({
  isLoading,
  applyFilters,
  setSelectedLetterOption,
  selectedLetterOption,
  isDisabled,
  searchValue,
  searchedValue,
  setSearchValue,
  setSearchedValue,
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 1300px)');
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [isLoadingLetterFilter, setLoadingLetterFilter] = useState(false);
  const [letterFilterOptions, setLetterFilterOptions] = useState<string[] | null>(null);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchInputFocus, setSearchInputFocus] = useState<boolean>(false);

  const fetchLetterFilterOptions = async () => {
    setLoadingLetterFilter(true);
    try {
      const res = await getAccountLetterFilterOptions({
        officeId: user?.officeId as string,
      });
      setLetterFilterOptions(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLetterFilter(false);
    }
  };
  useEffect(() => {
    fetchLetterFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (searchedValue && searchInputRef.current && !selectedLetterOption) {
        searchInputRef.current.focus();
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (searchInputFocus) {
      searchInputRef.current && searchInputRef?.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputFocus, isLoading]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setSearchInputFocus(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchInputRef]);

  return (
    <>
      <Grid container spacing={2} mb={1} alignItems="center">
        <Grid item flex={1}>
          <TextField
            sx={{ width: '100%', maxWidth: { xs: 'none', md: '20rem' } }}
            placeholder="Search Within Custom View..."
            size="small"
            onChange={({ target: { value } }) => {
              setSearchValue(value);
            }}
            value={searchValue}
            disabled={isLoading || isLoadingLetterFilter}
            onFocus={() => setSearchInputFocus(true)}
            InputProps={{
              inputRef: searchInputRef,
              endAdornment: searchValue && (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    title="Clear"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': 'search-custom-view'
            }}
          />
        </Grid>
        <Grid item>
          {isMobile && (
            <Box sx={{ textAlign: 'right', marginBottom: theme => theme.spacing(1) }}>
              <Button
                onClick={() => setIsShowingFilters(!isShowingFilters)}
                className={clsx('print--none', classes.filtersButton)}
                color="secondary"
                size="small"
                disabled={isDisabled || isLoadingLetterFilter}
                startIcon={
                  <FontAwesomeIcon
                    icon={isShowingFilters ? faFilterCircleXmark : faFilter}
                    size="lg"
                  />
                }
              >
                Filters
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item sx={{ width: isMobile ? '100%' : 'auto' }}>
          {((isShowingFilters && isMobile) || !isMobile) && !isLoadingLetterFilter && (
            <Box sx={{ textAlign: 'right' }}>
              <FormControl>
                <RadioGroup
                  className={classes.letterFilterList}
                  name="letter-filter-group"
                  value={selectedLetterOption}
                  onChange={e => {
                    setSelectedLetterOption(e.target.value);
                    applyFilters();
                  }}
                >
                  <FormControlLabel
                    className={classes.letterFilterLink}
                    value=""
                    control={<Radio sx={{ display: 'none' }} />}
                    label="All"
                    disabled={isDisabled || isLoadingLetterFilter}
                  />
                  {!!letterFilterOptions &&
                    letterFilterOptions.map(letter => {
                      return (
                        <FormControlLabel
                          key={letter}
                          className={classes.letterFilterLink}
                          value={letter}
                          control={<Radio sx={{ display: 'none' }} />}
                          label={letter}
                          disabled={isDisabled || isLoadingLetterFilter}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  letterFilterList: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '&& li': {
      display: 'flex',
      '& + li': {
        marginLeft: theme.spacing(1),
      },
      '&:first-child': {
        paddingRight: theme.spacing(1),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  },
  letterFilterLink: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    display: 'flex',
    padding: theme.spacing(1),
    minWidth: 0,
    margin: 0,
    [theme.breakpoints.up(600)]: {
      padding: theme.spacing(0.5),
    },
    '& + &': {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up(600)]: {
        marginLeft: theme.spacing(1.5),
      },
    },
    '&:nth-child(2)': {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    '&&, &&:hover, &&:focus': {
      backgroundColor: 'transparent',
    },
    '&&:hover, &&:focus': {
      color: theme.palette.secondary.main,
    },
    '&& .Mui-checked + .MuiFormControlLabel-label': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    '&& .Mui-disabled + .MuiFormControlLabel-label': {
      opacity: '.4',
    },
  },
  filtersButton: {
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
    '&:disabled': {
      color: 'black',
      backgroundColor: theme.palette.grey[400],
      borderColor: theme.palette.grey[400],
    },
  },
}));
