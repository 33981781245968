import { capital } from 'case';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { ExternalLink, Loader, Link, Card } from '../../components';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IResponse, IRecentItem } from '../../models';
import { getRecentItems } from '../../fetch';
import { getLegacyUrl } from '../../helpers';

export const RecentItems = () => {
  const { isLoading: isLoadingRecentItems, data: recentItemsData } = useQuery<
    IResponse<IRecentItem[]>,
    Error
  >(['recentItems'], () => getRecentItems({ sortDirection: 'Desc', perPage: 10 }), {
    staleTime: Infinity,
  });
  const items: IRecentItem[] | null | undefined = recentItemsData?.records ?? null;
  const hasRecentItems = items && items.length > 0 ? true : false;
  const classes = useStyles();
  const legacyUrl = getLegacyUrl?.();

  const { v2Customers, v2Services, v2Scheduling } = useFlags();

  return (
    <Card
      cardTitleProps={{
        title: 'Recent Customers',
      }}
      sxProps={{ height: '100%', paddingBottom: 0 }}
    >
      {isLoadingRecentItems && (
        <Box
          display="flex"
          flexDirection="column"
          height="13rem"
          alignItems="center"
          justifyContent="center"
        >
          <Loader position="centered" />
        </Box>
      )}
      {hasRecentItems && (
        <>
          <List sx={{ padding: 0, marginTop: '-0.75rem', marginBottom: '-.65rem' }}>
            {items?.map((item: IRecentItem, index) => {
              const label = capital(item.description);
              return (
                <ListItem sx={{ padding: 0 }} key={`${index}`}>
                  <ListItemText sx={{ margin: 0 }}>
                    {item.recordType === 'Service' && (
                      <>
                        {v2Services ? (
                          <Link
                            className={classes.link}
                            to={`/services/maintenance/${item.recordId}`}
                          >
                            {label}
                          </Link>
                        ) : (
                          <ExternalLink
                            className={classes.link}
                            target="_blank"
                            to={`${legacyUrl}/Office/ScheduledService`}
                          >
                            {label}
                          </ExternalLink>
                        )}
                      </>
                    )}
                    {item.recordType === 'Account' && (
                      <>
                        {v2Customers ? (
                          <Link className={classes.link} to={`/customers/${item.recordId}`}>
                            {label}
                          </Link>
                        ) : (
                          <ExternalLink
                            className={classes.link}
                            target="_blank"
                            to={`${legacyUrl}/Office/${item.recordType}/View/${item.recordId}`}
                          >
                            {label}
                          </ExternalLink>
                        )}
                      </>
                    )}
                    {item.recordType === 'Task' && (
                      <>
                        {v2Scheduling ? (
                          <Link className={classes.link} to={`/tasks/${item.recordId}`}>
                            {label}
                          </Link>
                        ) : (
                          <ExternalLink
                            className={classes.link}
                            target="_blank"
                            to={`${legacyUrl}/Office/ScheduledTask`}
                          >
                            {label}
                          </ExternalLink>
                        )}
                      </>
                    )}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
      {!hasRecentItems && !isLoadingRecentItems && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
        >
          <Typography>No Recent Items</Typography>
        </Box>
      )}
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    fontSize: 14,
  },
}));
