import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Theme } from '@mui/material/styles';
import { createLDProvider } from './ldProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: {
      main: string;
      contrastText: string;
    };
    changed: {
      main: string;
    };
    typography: {
      labels: string;
      tableHeaders: string;
      helpText: string;
    };
    dividers: {
      grey: string;
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: {
      main?: string;
      contrastText?: string;
    };
    changed?: {
      main?: string;
    };
    typography?: {
      labels?: string;
      tableHeaders?: string;
      helpText?: string;
    };
    dividers?: {
      grey?: string;
    };
  }
}

(async () => {
  const LDProvider = await createLDProvider();
  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
