import { axios } from '../helpers';
import qs from 'querystring';
import {
  IRecipient,
  IResponse
} from '../models';
import { userSignal } from '../signals';

export const getAccountRecipients = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsByServiceDate = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
    serviceDate: Date | null;
    techId?: string | null;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/by-service-date`, {
      params: {
        ...filters,
        serviceDate: filters?.serviceDate?.toISOString().split('T')[0],
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsBySanitizer = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
    sanitizerName: string | null;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/by-sanitizer`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsByRate = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
    rateId: string | null;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/by-rate`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsByServiceType = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
    serviceTypeId: string | null;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/by-service-type`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsByEquipmentTypeId = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
    equipmentTypeId: string | null;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/by-equipment-type`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAccountRecipientsWithOtsAndNoWeekly = async (
  filters?: {
    sortBy?: string | null;
    sortDirection?: string | null;
    page?: number | null;
    perPage?: number | string;
    officeId?: string | null;
    accountStatus?: number | 1;
  }): Promise<IResponse<IRecipient[]>> => {
  try {
    const { data } = await axios.get(`/api/recipients/with-ots-and-no-weekly`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
