import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse } from '../models';
import { IVisitPhoto } from '../models/photos';
import { userSignal } from '../signals';

export const getVisitPhotos = async (
    repairVisitId: string,
    filters?: { sortBy?: string; sortDirection?: string; page?: number; perPage?: number | string },
    officeId?: string | null
): Promise<IResponse<IVisitPhoto[]>> => {
    try {
        const { data } = await axios.get(`/api/repairs/visits/${repairVisitId}/visit-photos`, {
            params: {
                ...filters,
                officeId: officeId ?? userSignal.value?.officeId ?? '',
            },
            paramsSerializer: params => qs.stringify(params),
        });
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};

export const getVisitPhotosByRepair = async (
    repairId: string,
    filters?: { sortBy?: string; sortDirection?: string; page?: number; perPage?: number | string },
    officeId?: string | null
): Promise<IResponse<IVisitPhoto[]>> => {
    try {
        const { data } = await axios.get(`/api/repairs/${repairId}/visit-photos`, {
            params: {
                ...filters,
                officeId: officeId ?? userSignal.value?.officeId ?? '',
            },
            paramsSerializer: params => qs.stringify(params),
        });
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};

export const getVisitPhoto = async (
    repairVisitId: string,
    visitPhotoId: number,
    officeId?: string | null
): Promise<IResponse<IVisitPhoto[]>> => {
    try {
        const { data } = await axios.get(`/api/repairs/visits/${repairVisitId}/visit-photos/${visitPhotoId}`, {
            params: {
                officeId: officeId ?? userSignal.value?.officeId ?? '',
            },
            paramsSerializer: params => qs.stringify(params),
        });
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};

export const updateVisitPhoto = async (
    repairVisitId: string,
    visitPhotoId: number,     
    payload: {
        photoName: string,
        orderTaken: number,   
    },
    officeId?: string | null
): Promise<IResponse<IVisitPhoto[]>> => {
    try {
        const { data } = await axios.put(`/api/repairs/visits/${repairVisitId}/visit-photos/${visitPhotoId}`, {
            ...payload,
            officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};

export const createVisitPhoto = async (    
    repairVisitId: string,
    payload: File,
    photoMeta: IVisitPhoto,
    officeId?: string | null,    
): Promise<void> => {
    const formData = new FormData();
    formData.append('File', payload ?? '');
    formData.append('officeId', officeId ?? userSignal.value?.officeId ?? '');
    formData.append('repairId', photoMeta.repairId);
    formData.append('siteId', photoMeta.siteId);
    formData.append('orderTaken', photoMeta.orderTaken.toString());
    formData.append('photoName', photoMeta.photoName);
    try {
        const { data } = await axios.post(`/api/repairs/visits/${repairVisitId}/visit-photos`, formData);
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};

export const deleteVisitPhoto = async (
    repairVisitId: string,
    visitPhotoId: number
): Promise<void> => {
    try {
        const { data } = await axios.delete(`/api/repairs/visits/${repairVisitId}/visit-photos/${visitPhotoId}`);
        return data;
    } catch (error: any) {
        return Promise.reject(error?.response?.data);
    }
};
