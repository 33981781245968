import { ITab, Tabs } from '../../../components';
import { useState, useContext, useEffect } from 'react';
import { useConfirm, useUnload } from '../../../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserContext } from '../../../context';
import { UserGroupsTable } from './user-groups.table';
import { UsersTable } from './users-table';
import { getLegacyUrl } from '../../../helpers';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { BrandingContext } from '../../../context/branding-context';

export const UserManagementDetails = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const activeTab = searchParams.get('activeTab');
  const [selectedTab, setSelectedTab] = useState<string>(activeTab || 'users');
  const [isTabDirty, setIsTabDirty] = useState(false);
  const confirm = useConfirm();
  const featureFlags = useFlags();
  const { user } = useContext(UserContext);
  const legacyUrl = getLegacyUrl?.();
  const { isPoolService } = useContext(BrandingContext);
  const { isSuperAdmin } = useContext(UserContext);

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, isTabDirty);

  useEffect(() => {
    if (isPoolService) {
      return;
    }
    if (
      !featureFlags.v2UserManagement ||
      (user?.userType !== 'Administrator' && user?.userType !== 'Office')
    ) {
      window.location.href = `${legacyUrl}/Office/User/List`;
    }
  }, [featureFlags, user, legacyUrl, isPoolService]);

  useEffect(() => {
    // since we are hiding the users tab for pool service for non super admins, 
    // we need to set the default tab to user-groups on first load

    if (!isSuperAdmin && isPoolService) {
      setSelectedTab('user-groups');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      id="user-management"
      isSticky
      color="secondary"
      size="lg"
      selectedTab={selectedTab}
      setSelectedTab={async val => {
        if (isTabDirty) {
          const result = await confirm(defaultUnsavedChangesMessage);
          if (result) {
            setIsTabDirty(false);
            return setSelectedTab(val);
          }
          setIsTabDirty(false);
          return;
        }
        setSelectedTab(val);
      }}
      tabs={
        [
          ((isSuperAdmin && isPoolService) || !isPoolService) && {
            key: 'users',
            title: 'Users',
            // Prevent mounting and API calls when not the selectedTab
            children: selectedTab === 'users' && <UsersTable />,
          },
          {
            key: 'user-groups',
            title: isPoolService ? 'Role Permissions' : 'User Groups',
            children: selectedTab === 'user-groups' && <UserGroupsTable />,
          },
        ].filter(Boolean) as ITab[]
      }
    />
  );
};
