import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { formatDate } from '../../helpers';
import { INews } from '../../models';

interface INewsListItemProps {
  newsItem: INews;
}

export const NewsListItem: FC<INewsListItemProps> = ({ newsItem }) => {
  const theme = useTheme();
  return (
    <Box component="article">
      <Typography variant="h5" component="h3" fontWeight={600} color="primary">
        {newsItem.subject}
      </Typography>
      <Typography variant="subtitle1" color={theme.palette.typography.helpText} mb={1}>
        Created: {formatDate(newsItem.createdDate)}
      </Typography>
      {newsItem.message && (
        <div
          style={{ fontSize: theme.typography.body1.fontSize }}
          dangerouslySetInnerHTML={{ __html: newsItem.message }}
        />
      )}
    </Box>
  );
};
