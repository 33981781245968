import { Box, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { FC, useState } from 'react';
import { IFilterInputProps } from '../../../../models';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { isValidDate } from '../../../../helpers';

export const DateRangeFilter: FC<IFilterInputProps> = ({ filter, values, onChange, filters }) => {
  const classes = useStyles();
  const { name, label } = filter;
  const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
  const [startDate = '', endDate = ''] = values;

  const hasMultipleDateRanges =
    (filters && filters?.filter(f => f.type === 'DateRange')?.length > 1) ?? false;

  const isSameDate = (start: string, end: string) => {
    const diff = differenceInDays(new Date(end), new Date(start));
    return diff === 0;
  };

  const handleStartDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      setIsInvalidStartDate(false);
      date!.setHours(0, 0, 0, 0);
      const value = date ? date.toISOString() : '';

      if (isSameDate(value, endDate)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([value, endDate], filter);
      }
    } else {
      if (date === null) {
        onChange(['', endDate], filter);
        setIsInvalidStartDate(false);
      } else {
        setIsInvalidStartDate(true);
      }
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      setIsInvalidEndDate(false);
      date!.setHours(0, 0, 0, 0);
      const value = date ? date.toISOString() : '';

      if (isSameDate(startDate, value)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([startDate, value], filter);
      }
    } else {
      if (date === null) {
        onChange([startDate, ''], filter);
        setIsInvalidEndDate(false);
      } else {
        setIsInvalidEndDate(true);
      }
    }
  };

  let minEndDate: Date | undefined = undefined;

  let invalidRange = false;

  if (startDate && endDate) {
    const diff = differenceInDays(new Date(endDate), new Date(startDate));
    invalidRange = diff < 0;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box className={classes.datePickerRow}>
        <Box className={classes.datePickerWrapper}>
          <DatePicker
            label={hasMultipleDateRanges ? `${label} Start Date` : `Start Date`}
            format="MM/dd/yyyy"
            onChange={handleStartDateChange}
            value={startDate ? new Date(startDate) : null}
            slotProps={{
              textField: {
                inputProps: {
                  'data-testid': 'start-date-field'
                },
                name,
                size: 'small',
                error: invalidRange || isInvalidStartDate,
                InputLabelProps: { shrink: true },
                fullWidth: true,
              },
            }}
          />
          {invalidRange && (
            <FormHelperText error>
              Invalid Date Range. Start Date must be after End Date.
            </FormHelperText>
          )}
          {isInvalidStartDate && <FormHelperText error>Invalid Start Date.</FormHelperText>}
        </Box>
        <Box>to</Box>
        <Box className={classes.datePickerWrapper}>
          <DatePicker
            label={hasMultipleDateRanges ? `${label} End Date` : `End Date`}
            minDate={minEndDate}
            format="MM/dd/yyyy"
            onChange={handleEndDateChange}
            value={endDate ? new Date(endDate) : null}
            slotProps={{
              textField: {
                inputProps: {
                  'data-testid': 'end-date-field'
                },
                name,
                size: 'small',
                error: invalidRange || isInvalidEndDate,
                InputLabelProps: { shrink: true },
                fullWidth: true,
              },
            }}
          />
          {isInvalidEndDate && <FormHelperText error>Invalid End Date.</FormHelperText>}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  datePickerSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    width: '100%',
  },
  datePickerRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
  },
  datePickerWrapper: {
    flex: 1,
  },
}));
