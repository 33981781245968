import { Grid, Link, Typography } from '@mui/material';
import { Card } from '../../components';
import { FeedbackForm } from './feedback-form';

export const FeedbackPageDetails = () => {
  return (
    <Card
      cardTitleProps={{
        title: `Give us Feedback`,
      }}
    >
      <Grid container spacing={3} maxWidth={'900px'}>
        <Grid item xs={12}>
          <Typography>
            Do you have a question or comment? Please complete the form below, and we will get back
            to you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FeedbackForm />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={'bold'}>Contact Information</Typography>
          <Typography>
            To contact us by phone, please call <Link href="tel:180009347890">1-800-934-7890</Link>{' '}
            Option 3.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
