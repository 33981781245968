import { FC, useCallback, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, IconButton, TextField, useMediaQuery } from '@mui/material';
import { faTrash, faEdit, faTable, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Loader,
  Modal,
  ModalSaveSection,
  CardTitle,
  Card,
  ImageModal,
  useDataGrid,
  GridDataFetcher,
  ServerSideDataGrid,
} from '../../../components';
import { MultiImageUpload } from '../../../components/file/multi-image-upload';
import { createVisitPhoto, deleteVisitPhoto, getVisitPhotosByRepair, updateVisitPhoto, getRepairVisits } from '../../../fetch';
import { formatDate } from '../../../helpers';
import { IVisitPhoto } from '../../../models';
import { useConfirm } from '../../../hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import VisitImageGrid from '../../../components/data-grid/VisitImageGrid';

interface IVisitPhotosProps {
  repairId: string;
  repairVisitId: string | null;
  siteId: string;
  isEditable?: boolean;
  showPagination?: boolean;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  onAddPhotos?: (photos: string[]) => void;
  onRemovePhoto?: (photo: string) => void;
}

export const VisitPhotos: FC<IVisitPhotosProps> = ({
  repairId,
  siteId,
  isEditable = false,
  showPagination = false,
  isExpanded,
  isCollapsible = true,
  initialExpand = true,
  onAddPhotos,
  onRemovePhoto,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles({ isEditable });
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isModalOpen, setisModalOpen] = useState(false);
  const [source, setSource] = useState<string>('');

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<IVisitPhoto | null>(null);
  const [imageNameValue, setImageNameValue] = useState<string>('');
  const [orderTakenValue, setOrderTakenValue] = useState<number>(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [viewImageGrid, setViewImageGrid] = useState(false);
  const [currentSiteId] = useState(siteId);
  const [currentRepairId, setCurrentRepairId] = useState(repairId);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Prevent datafetcher from running right away and creating 2 API calls.
    let mounted = true;
    if (repairId && mounted) {
      setCurrentRepairId(repairId)
      setIsInitialized(true);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairId]);

  const dataFetcher: GridDataFetcher<IVisitPhoto> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {

        if (!isInitialized) {
          return {
            continueLoading: true,
          };
        }
        let res: { records: IVisitPhoto[]; totalRecordCount: number } = {
          records: [],
          totalRecordCount: 0,
        };

        if (currentRepairId && currentRepairId !== 'new') {
          res = await getVisitPhotosByRepair(currentRepairId, {
          perPage: showPagination ? perPage : -1,
          sortBy: sortColumn || 'whenTaken',
          sortDirection: sortDirection || 'desc',
          page: page + 1,
        });
      }
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading visit photos, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInitialized]
  );

  const {
    rows,
    setRows,
    page,
    pageSize: perPage,
    isLoading: isLoadingVisitPhotos,
    refetch: fetchVisitPhotos,
    onSortModelChange,
    onPageChange,
    rowCount: recordCount,
    onPageSizeChange,
  } = useDataGrid<IVisitPhoto>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'visit-photos-grid',
      sortColumn: 'whenTaken',
      sortDirection: showPagination ? 'desc' : 'asc',
    },
    dataFetcher,
  });

  async function getVisitId(repairId: string) {
    let visits = await getRepairVisits({
      sortBy: 'ServiceDate',
      sortDirection: 'desc',
      page: 1,
      perPage: 5,
      repairId: repairId,
    });
    
    // Filter visits to get only those with an open status
    let repairVisits = visits.records.filter(visit => visit.repairId === repairId);
    if (repairVisits.length > 0) {
      let currentOpenVisit = repairVisits.find(visit => visit.status === 'Open' && visit.serviceDate && new Date(visit.serviceDate) <= new Date());
      if (currentOpenVisit) {
        return currentOpenVisit.repairVisitId;
      } else {
        return repairVisits[0].repairVisitId;
      }
    }
    return null; // Return null if no visits are found
  }

  const uploadVisitPhoto = async (payload: File, photoMeta: IVisitPhoto) => {
    photoMeta.siteId = currentSiteId;
    photoMeta.repairId = repairId;
    photoMeta.orderTaken = rows.length + 1;
    photoMeta.photoName = payload.name;
    // Get the latest visit with an open status
    let latestRepairVisitId = await getVisitId(repairId);
    photoMeta.repairVisitId = latestRepairVisitId ?? '';
    
      setIsUploadingPhoto(true);
      try {
        if (latestRepairVisitId) {
          await createVisitPhoto(latestRepairVisitId, payload, photoMeta);
        } else {
          enqueueSnackbar(`There was no repair visit that matched that repair.`, {
            variant: 'error',
          });
        }
        enqueueSnackbar(`Visit Photo Uploaded!`, {
          variant: 'success',
        });
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error uploading visit photos, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsUploadingPhoto(false);
      }
  };

  const removeVisitPhoto = async (repairVisitId: string, visitPhotoId: number) => {
    setIsDeletingPhoto(true);
    try {
      await deleteVisitPhoto(repairVisitId, visitPhotoId);
      enqueueSnackbar(`Visit Photo Deleted!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting visit photo, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingPhoto(false);
      await fetchVisitPhotos();
    }
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'thumbnail',
        headerName: '',
        disableColumnMenu: true,
        flex: 1,
        maxWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IVisitPhoto>) => {
          const { row: photo } = params;
          return (
            <Button
              variant="text"
              onClick={() => {
                setSource(photo.urlPath);
                setisModalOpen(true);
              }}
              className={classes.imgButton}
            >
              <img src={photo.urlPath} alt={photo.photoName} style={{ width: '100%' }} />
            </Button>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IVisitPhoto>) => {
          const { row: photo } = params;

          return (
            (
              <Button
                variant="text"
                disabled={isUploadingPhoto || isLoadingVisitPhotos || isDeletingPhoto}
                onClick={() => {
                  setSource(photo.urlPath);
                  setisModalOpen(true);
                }}
                sx={{ padding: 0 }}
              >
                {photo.photoName}
              </Button>
            ) || '--'
          );
        },
      },
      {
        field: 'whenTaken',
        headerName: 'Taken Date',
        disableColumnMenu: true,
        minWidth: 125,
        renderCell: (params: GridRenderCellParams<IVisitPhoto>) => {
          const { row: photo } = params;

          return (
            (
              <span title={isEditable ? undefined : 'Taken Date'}>
                {!!photo.whenTaken ? formatDate(photo.whenTaken) : ''}
              </span>
            ) || '--'
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 300,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IVisitPhoto>) => {
          const { row: photo } = params;

          if (isEditable) {
            return (
              <Box textAlign="right" display="flex" justifyContent="flex-end">
                <Box className={clsx('print--none')} display="flex">
                  <IconButton
                    color="error"
                    title="Delete Photo"
                    disabled={
                      isUploadingPhoto ||
                      isLoadingVisitPhotos ||
                      isDeletingPhoto ||
                      rows.some(photo => !photo.whenTaken)
                    }
                    onClick={async () => {
                      const result = await confirm('Are you sure you want to delete this?');
                      if (result) {
                        await removeVisitPhoto(photo.repairVisitId, photo.visitPhotoId);
                        onRemovePhoto?.(photo.photoName);
                      } else {
                        return;
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={
                      isUploadingPhoto ||
                      isLoadingVisitPhotos ||
                      isDeletingPhoto ||
                      rows.some(photo => !photo.whenTaken)
                    }
                    title={`Edit Visit Photo Name`}
                    onClick={() => {
                      setImageNameValue('');
                      setOrderTakenValue(0);
                      setCurrentImage(null);
                      setShowEditModal(true);
                      setCurrentImage(photo);
                      setImageNameValue(photo.photoName);
                      setOrderTakenValue(photo.orderTaken);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                </Box>
              </Box>
            );
          }
          return null;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVisitPhotos, isEditable, rows, isLoadingVisitPhotos]);

  const toggleGrid = () => {
    setViewImageGrid(!viewImageGrid);
  }

  return (
    <Card>
      <CardTitle
        title="Visit Photo(s)"
        withExpand={isCollapsible}
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
        mobileWrap
        action={
          isEditable ? (
            <MultiImageUpload
              handleFileChange={async fileImages => {
                setRows(prev => [...prev, ...fileImages]);
                await Promise.all(
                  fileImages.map(async photo => {
                    if (photo?.whenTaken) {
                      return;
                    }
                    if (photo?.file) {
                      await uploadVisitPhoto(photo.file, photo);
                    }
                  })
                );
                await fetchVisitPhotos();
                onAddPhotos?.(fileImages.filter(img => !img?.whenTaken).map(img => img.title));
              }}
              images={rows.map(photo => photo.urlPath)}
              disabled={isLoadingVisitPhotos}
              photoId="visitPhotoId"
              buttonSize="small"
              buttonColor="secondary"
              isButtonFullWidth={isMobile}
            />
          ) : undefined
        }
      >
        <Box
          textAlign="right"
          display="flex"
          justifyContent="flex-end"
          className="print--none"
        >
          <IconButton
            color="primary"
            onClick={toggleGrid}
            disabled={!viewImageGrid}
            title={`Show visit images as a list`}
          >
            <FontAwesomeIcon icon={faList} size="sm" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={toggleGrid}
            title={`Show visit images as a tile gallery`}
            disabled={viewImageGrid}
          >
            <FontAwesomeIcon icon={faTable} size="sm" />
          </IconButton>
        </Box>
        <ImageModal isOpen={isModalOpen} onClose={() => setisModalOpen(false)} source={source} />
        <Modal
          maxWidth="xs"
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          title="Edit Visit Photo Name"
        >
          {isUpdating && <Loader type="overlay" position="centered" />}
          <Box mt={2}>
            <TextField
              fullWidth
              value={imageNameValue}
              onChange={e => setImageNameValue(e.target.value)}
              label="Photo Name"
              size="small"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={orderTakenValue}
              type="number"
              onChange={e => setOrderTakenValue(parseInt(e.target.value))}
              label="Order Taken"
              size="small"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Box>
          <ModalSaveSection
            isSaveDisabled={!imageNameValue}
            handleSave={async () => {
              try {
                if (currentImage !== null) {
                  setIsUpdating(true);
                  await updateVisitPhoto(currentImage.repairVisitId, currentImage.visitPhotoId, {
                    photoName: imageNameValue,
                    orderTaken: orderTakenValue,
                  });
                  fetchVisitPhotos();
                  setShowEditModal(false);
                }

              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error updating visit photo, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsUpdating(false);
              }
            }}
            handleCancel={() => {
              setShowEditModal(false);
            }}
          />
        </Modal>
        {viewImageGrid ? (
          <div>
            <VisitImageGrid openModal={setisModalOpen} setSource={setSource} images={rows} />{' '}
          </div>
        ) : (
          <div>
            <ServerSideDataGrid
              getRowId={(row: IVisitPhoto) => {
                return row.visitPhotoId!;
              }}
              rows={rows}
              columns={columns}
              autoHeight
              columnHeaderHeight={36}
              onSortModelChange={onSortModelChange}
              hideFooter={!showPagination}
              loading={isLoadingVisitPhotos || isUploadingPhoto || isDeletingPhoto}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              noResultsMessage="No visit photos found."
            />
          </div>
        )}
      </CardTitle>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { isEditable: boolean }>(theme => ({
  imgButton: {
    padding: 0,
    maxHeight: 80,
    maxWidth: 80,
    minHeight: 30,
    minWidth: 30,
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
    borderRadius: 0,
    display: 'block',
  },
}));
