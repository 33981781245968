import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { nameColMinWidth } from '../../constants/data-grid';
import { ILead } from '../../models';
import { formatInputPhoneNumber } from '../../helpers';
import { theme } from '../../styles';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export const newLeadsColumns: GridColDef[] = [
  {
    field: 'customerName',
    headerName: 'Name',
    type: 'string',
    minWidth: nameColMinWidth,
  },
  {
    field: 'serviceType',
    headerName: 'Service Type',
    type: 'string',
    minWidth: 250,
    flex: 0.3,
  },
  {
    field: 'customerAddress',
    headerName: 'Address',
    type: 'string',
    minWidth: 200,
    flex: 1,
  },

  {
    field: 'customerEmail',
    headerName: 'Email',
    type: 'string',
    minWidth: 150,
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<ILead>) => {
      const { row: lead } = params;
      return (
        <a style={{ color: theme.palette.secondary.main }} href={`mailto:${lead.customerEmail}`}>
          {params.value}
        </a>
      );
    },
  },
  {
    field: 'customerPhone',
    headerName: 'Phone',
    type: 'string',
    minWidth: 150,
    flex: 0.5,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      return formatInputPhoneNumber(params.value);
    },
  },
  {
    field: 'action',
    headerName: '',
    type: 'string',
    minWidth: 150,
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<ILead>) => {
      const { row: lead } = params;
      return (
        <Link to={`/leads/${lead.leadId}`}>
          <IconButton size="small" color="primary">
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        </Link>
      );
    },
  },
];
