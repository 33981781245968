import { FC } from 'react';
import { Page } from '../../../components';
import { NewsManagementTable } from './news-management-table';

export const NewsManagementPage: FC = () => {
  return (
    <Page title="News Management">
      <NewsManagementTable />
    </Page>
  );
};
