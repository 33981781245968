import { FC, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  CardTitle,
  GridDataFetcher,
  ServerSideDataGrid,
  useDataGrid,
  Card,
} from '../../../components';
import { IRecommendation } from '../../../models';
import { getRecommendations } from '../../../fetch';

import { recommendationColumns } from './columns';
import { Treatments } from '../../../components/treaments';
import { Divider } from '@mui/material';

export interface ServiceRecommendationsGridProps {
  serviceId: string;
  isExpanded?: boolean;
  showNoResults?: boolean;
  isCardLayout?: boolean;
  siteId?: string;
  withTreatments?: boolean;
}

export const ServiceRecommendations: FC<ServiceRecommendationsGridProps> = ({
  serviceId,
  isExpanded,
  showNoResults = true,
  isCardLayout = true,
  siteId,
  withTreatments,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const dataFetcher: GridDataFetcher<IRecommendation> = useCallback(
    async ({ perPage, sortColumn, sortDirection }) => {
      try {
        const params = {
          scheduledServiceId: serviceId,
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'date',
          perPage,
        };

        const res = await getRecommendations(params);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading recommendations, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IRecommendation>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'service-recommendations-grid',
      sortColumn: 'chemicalName',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  if ((!showNoResults && recordCount > 0) || showNoResults) {
    if (!isCardLayout) {
      return (
        <>
          <ServerSideDataGrid
            disableColumnFilter
            loading={isLoading}
            disableColumnSelector
            autoHeight
            getRowId={(row: IRecommendation) => row.recommendationId}
            rows={rows}
            columns={recommendationColumns}
            sortModel={sortModel}
            page={page}
            pageSize={perPage}
            rowCount={recordCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
          />
          {withTreatments && (
            <>
              <Divider />
              <Treatments
                siteId={siteId!}
                serviceId={serviceId}
                isCardLayout={false}
                isEditable={false}
              />
            </>
          )}
        </>
      );
    }

    return (
      <Card>
        <CardTitle
          title="Recommendations"
          withExpand
          initialExpand={false}
          overrideExpand={isExpanded}
        >
          <ServerSideDataGrid
            disableColumnFilter
            loading={isLoading}
            disableColumnSelector
            autoHeight
            getRowId={(row: IRecommendation) => row.recommendationId}
            rows={rows}
            columns={recommendationColumns}
            sortModel={sortModel}
            page={page}
            pageSize={perPage}
            rowCount={recordCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
            hasMobileLayout
            mobileProps={{
              mobileCustomDefaultAccessor: (val: IRecommendation) => {
                return `Recommendation for ${val?.treatmentFor}`
              },
              stackAccordionDetails: true
            }}
          />
        </CardTitle>
      </Card>
    );
  } else {
    return null;
  }
};
