import { Box } from '@mui/material';
import { Card } from '../../components';
import { FC, ReactNode } from 'react';

interface IDocumentsLayout {
  display?: 'card' | 'div';
  marginTop?: number;
  children: ReactNode;
}

export const DocumentsLayout: FC<IDocumentsLayout> = ({
  display = 'card',
  marginTop = 4,
  children,
}) => {
  if (display === 'card') {
    return (
      <Box mt={marginTop}>
        <Card>{children}</Card>
      </Box>
    );
  } else {
    return <Box mt={marginTop}>{children}</Box>;
  }
};
