import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IPage, Page, Card } from '../../components';
import { styleGuideLinks } from '../../constants';

interface IStyleGuidePage extends IPage {
  showCard?: boolean;
}

export const StyleGuidePage: FC<IStyleGuidePage> = ({ children, title, showCard = true }) => {
  const { path } = useRouteMatch();

  return (
    <Page
      title={title}
      sideBarLinks={styleGuideLinks}
      showAlerts={false}
      breadcrumb={
        path !== '/kitchen-sink'
          ? {
              text: 'Kitchen Sink',
              title: 'Back to Kitchen Sink',
              link: `/admin/kitchen-sink`,
            }
          : undefined
      }
    >
      {showCard ? <Card>{children}</Card> : children}
    </Page>
  );
};
