import { Datum, ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { ILeadsByMonth } from '../../models';
import { theme } from '../../styles';

interface LeadsByMonthChartProps {
  data: ILeadsByMonth[];
}

export const LeadsByMonthChart: FC<LeadsByMonthChartProps> = ({ data }) => {
  const streamData: Datum[] = useMemo(() => {
    return data.map(leadsByMonth => {
      return {
        y: leadsByMonth.numberOfLeads,
        x: format(new Date(2023, leadsByMonth.month - 1, 1), 'MMM'),
      };
    });
  }, [data]);

  return (
    <ResponsiveLine
      data={[{ id: 'numberOfLeads', data: streamData }]}
      curve="catmullRom"
      margin={{ top: 20, right: 40, bottom: 50, left: 40 }}
      axisTop={null}
      axisRight={null}
      useMesh={true}
      // fixes issue of console errors firing when responsive screen changes
      // https://github.com/plouc/nivo/issues/2111
      animate={false}
      colors={[theme.palette.secondary.main]}
      enableGridX={false}
      enableGridY={false}
      pointColor={theme.palette.grey[600]}
    />
  );
};
