import { useSnackbar } from 'notistack';
import { FC, useState, useEffect } from 'react';
import { Box, Grid, TextField, MenuItem, Button } from '@mui/material';
import { IDropdownResponse } from '../../models';
import { getEstimateStatuses } from '../../fetch';

interface IEstimatesFiltersProps {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  hasAppliedFilters?: boolean;
  setHasAppliedFilters: (val: boolean) => void;
  selectedStatus: string;
  setSelectedStatus: (val: any) => void;
}

export const EstimatesFilters: FC<IEstimatesFiltersProps> = ({
  isLoading,
  applyFilters,
  hasAppliedFilters,
  setHasAppliedFilters,
  selectedStatus,
  setSelectedStatus,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingEstimateStatuses, setIsLoadingEstimateStatuses] = useState(false);
  const [statuses, setStatuses] = useState<IDropdownResponse[]>([]);
  const fetchEstimateStatuses = async () => {
    setIsLoadingEstimateStatuses(true);
    try {
      const res = await getEstimateStatuses();
      setStatuses(res);
    } catch (error) {
      enqueueSnackbar(`Error loading estimate statuses, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEstimateStatuses(false);
    }
  };

  useEffect(() => {
    fetchEstimateStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} className="print--none">
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          select
          label="Status"
          size="small"
          onChange={({ target: { value } }) => {
            setSelectedStatus(value);
          }}
          value={selectedStatus}
          disabled={isLoadingEstimateStatuses}
          data-testid="status-select"
        >
          {statuses?.map((def, index) => {
            return (
              <MenuItem key={`${index}`} value={def.value}>
                {def.description}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12} md={7} sx={{ textAlign: 'right' }}>
        <Box display="flex" gap={1}>
          {hasAppliedFilters && (
            <Button
              size="small"
              disabled={isLoading}
              onClick={() => {
                setHasAppliedFilters(false);
                setSelectedStatus('');
                applyFilters(true);
              }}
              color="inherit"
              data-testid="reset-button"
            >
              Reset
            </Button>
          )}
          <Button
            color="primary"
            size="small"
            disabled={isLoading}
            onClick={() => {
              setHasAppliedFilters(true);
              applyFilters();
            }}
            data-testid="apply-filters-button"
          >
            Apply Filters
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
