import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useContext } from 'react';
import clsx from 'clsx';
import { BrandingContext } from '../../context/branding-context';

interface IPrintHeaderProps {}

export const PrintHeader: FC<IPrintHeaderProps> = () => {
  const classes = useStyles();
  const { printLogo } = useContext(BrandingContext);

  return (
    <Box className={clsx('print--only', classes.printHeader)}>
      <img src={printLogo} alt="Print Logo" />
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    printHeader: {
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(1.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});
