import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IRateDetail } from '../../../models';
import { deleteRateDetail, getAllRateDetails } from '../../../fetch';
import {
  faPlusCircle,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { AddEditRateDetailsModal } from './add-edit-rate-details-modal';
import { useConfirm } from '../../../hooks';
import { formatMoney } from '../../../helpers';

interface IRateParams {
  rateId: string;
}

export const RateDetailsTable: FC<IRateParams> = ({
  rateId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentRateDetail, setCurrentRateDetail] = useState<IRateDetail | null>(null);
  const confirm = useConfirm();

  const dataFetcher: GridDataFetcher<IRateDetail> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          rateId
        };

        const res = await getAllRateDetails(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading rates, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, rateId]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IRateDetail>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'rate-details-grid',
      sortColumn: 'sortOrder',
      sortDirection: 'asc'
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        sortable: false,
        valueGetter: (params) => {
          return formatMoney(params.value);
        },
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IRateDetail>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Rate Detail"
                disabled={isLoading || isDeleting}
                onClick={() => {
                  showModal(true);
                  setCurrentRateDetail(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Rate Detail"
                disabled={isLoading || isDeleting}
                onClick={async () => {
                  try {
                    const result = await confirm('Are you sure you want to delete this rate detail?');
                    if (result) {
                      setDeleting(true);
                      await deleteRateDetail(params.row.rateDetailId);
                      await refetch();
                      enqueueSnackbar(`Rate Detail Deleted!`, {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(`Error deleting rate detail, please try again.`, {
                      variant: 'error',
                    });
                  } finally {
                    setDeleting(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [isDeleting, isLoading, confirm, enqueueSnackbar, refetch]);

  return (
    <>
      <Card>
        <CardTitle
          title="Rate Details"
          action={
            <>
              <Box textAlign="right" className="print--none" marginLeft={1}>
                <Button
                  onClick={() => showModal(true)}
                  color="secondary"
                  size="small"
                  disabled={isLoading || isDeleting}
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                >
                  Add Rate Detail
                </Button>
              </Box>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IRateDetail) => row.rateDetailId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
        />
      </Card>
      <AddEditRateDetailsModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentRateDetail(null);
        }}
        currentRateDetail={currentRateDetail}
        rateId={rateId}
        fetchRateDetails={() => refetch()}
      />
    </>
  );
};
