import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

interface IPrimaryCardProps {
  children?: any;
  avatar?: JSX.Element;
  icon?: IconProp;
  title: string | null;
  subheader?: string | null;
  onClick?: () => void;
  fullHeight?: boolean;
  alignCenter?: boolean;
  iconSize?: SizeProp;
  iconMobileSize?: SizeProp;
}

export const CardButton: FC<IPrimaryCardProps> = ({
  children,
  avatar,
  icon,
  title,
  subheader,
  onClick,
  fullHeight = true,
  alignCenter = false,
  iconSize = 'xl',
  iconMobileSize = 'lg',
}) => {
  const classes = useStyles({ fullHeight, hasContent: !!children, alignCenter });
  const isSmMobile = useMediaQuery(`(max-width: 567px)`);
  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClick} sx={{ height: '100%' }} disableRipple>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            (avatar || icon) && (
              <Avatar
                sx={{
                  bgcolor: theme => theme.palette.secondary.main,
                  width: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(6),
                  height: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(6),
                  color: theme => theme.palette.common.white,
                }}
              >
                {avatar}
                {icon && (
                  <FontAwesomeIcon icon={icon} size={isSmMobile ? iconMobileSize : iconSize} />
                )}
              </Avatar>
            )
          }
          title={title}
          subheader={subheader}
          titleTypographyProps={{
            variant: 'h6',
            fontWeight: 'bold',
            color: theme => theme.palette.secondary.main,
          }}
        />
        {children && (
          <CardContent
            sx={{
              backgroundColor: theme => theme.palette.grey[100],
            }}
          >
            {children}
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles<
  Theme,
  { fullHeight: boolean; hasContent: boolean; alignCenter: boolean }
>((theme: Theme) => ({
  card: {
    height: ({ fullHeight }) => (fullHeight ? '100%' : 'auto'),
    border: '1px solid transparent',
    '&&:hover, &&:focus, &&:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '&& .MuiCardContent-root': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.common.white,
    height: ({ fullHeight, hasContent }) => (fullHeight && !hasContent ? '100%' : 'auto'),
    alignItems: ({ alignCenter }) => (alignCenter ? 'center' : 'flex-start'),
  },
}));
