import { FC, useState, useEffect } from 'react';
import { TextField, StandardTextFieldProps, MenuItem, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ISelectOption } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';

interface ISelectAsyncInputProps extends StandardTextFieldProps {
  name: string;
  apiRequest: () => Promise<any>;
  transformResponse: (response: any) => ISelectOption[];
  hasClear?: boolean;
  onClear?: () => void;
  handleOptions?: (val: ISelectOption[]) => void;
  handleResponseOptions?: (val: any) => void;
  handleChange: (val: string) => void;
  value: any;
}

export const SelectAsyncInput: FC<ISelectAsyncInputProps> = ({
  name,
  apiRequest,
  transformResponse,
  hasClear = false,
  onClear,
  handleOptions,
  handleResponseOptions,
  handleChange,
  value,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiRequest();
        const data = transformResponse(response);
        setOptions(data);
        handleOptions && handleOptions(data);
        handleResponseOptions && handleResponseOptions(response);
      } catch (err) {
        enqueueSnackbar(`Error loading ${name} dropdown`, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    value,
    select: true,
    label: isLoading ? 'Loading...' : props.label,
    onChange: (e: any) => handleChange(e.target.value),
    disabled: props.disabled ?? isLoading,
    InputProps: {
      ...props.InputProps,
      ...(hasClear
        ? {
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                position: 'absolute',
                right: 35,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                title="Clear"
                onClick={() => {
                  handleChange('');
                  onClear && onClear();
                }}
              />
            </InputAdornment>
          ),
        }
        : undefined),
    },
  };

  return (
    <TextField {...config} inputProps={{
      ...props.inputProps,
      'data-testid': `${kebabCase(name)}-select`
    }}>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
