import { FC, Fragment, useContext } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Tab, Tabs as MuiTabs, useMediaQuery, Alert } from '@mui/material';
import clsx from 'clsx';
import { theme } from '../../styles';
import { SideNavContext } from '../../context';
import { HEADER_HEIGHT } from '../skeleton';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebabCase } from 'lodash';

export interface ITab {
  key: string;
  title: string | JSX.Element;
  mobileTitle?: string | JSX.Element;
  icon?: IconDefinition;
  children?: any;
  disabled?: boolean;
  isInvalid?: boolean;
  hasUnsavedChanges?: boolean;
}

interface ITabs {
  tabs?: ITab[];
  id: string;
  selectedTab: string | number;
  setSelectedTab: (val: string) => void;
  printTabstrip?: boolean; // Print Tabstrip vs hide on print
  printAllTabs?: boolean; // Print all tabs vs selected tab
  isSticky?: boolean;
  size?: 'md' | 'lg';
  color?: 'primary' | 'secondary';
  backgroundColor?: 'white' | 'grey';
}

interface ITabStyles {
  headerHeight: number;
  tabCount: number;
  isSmMobile?: boolean;
  isMobile?: boolean;
  size?: string;
  color?: string;
  backgroundColor?: string;
}

export const Tabs: FC<ITabs> = ({
  id,
  tabs,
  selectedTab,
  setSelectedTab,
  printTabstrip = false,
  printAllTabs = false,
  isSticky,
  size = 'md',
  color = 'primary',
  backgroundColor = size === 'lg' ? 'grey' : 'white',
}) => {
  const { isSmMobile } = useContext(SideNavContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isPrint = useMediaQuery('print');

  const tabCount = tabs?.length ?? 0;

  const invalidTabs = tabs?.filter(tab => tab.isInvalid);
  const unsavedTabs = tabs?.filter(tab => tab.hasUnsavedChanges);

  const classes = useStyles({
    headerHeight: HEADER_HEIGHT,
    tabCount,
    isSmMobile,
    isMobile,
    size,
    color,
  });

  const renderTab = (tab: ITab) => {
    const isCurrentTabOnly =
      (invalidTabs?.length === 1 && invalidTabs?.[0]?.key === selectedTab) ||
      (unsavedTabs?.length === 1 && unsavedTabs?.[0]?.key === selectedTab);
    const tabContent = (
      <>
        {invalidTabs && invalidTabs?.length > 0 && !isCurrentTabOnly && (
          <Alert
            severity="error"
            sx={{
              marginBottom: theme => theme.spacing(2),
              marginTop: theme => theme.spacing(2),
            }}
            className="print--none"
          >
            There are required fields and/or validation errors on the following tabs that need to be
            resolved: {invalidTabs?.map(t => t.title).join(', ')}
          </Alert>
        )}
        {unsavedTabs && unsavedTabs?.length > 0 && !isCurrentTabOnly && (
          <Alert
            severity="warning"
            sx={{
              marginBottom: theme => theme.spacing(2),
              marginTop: theme => theme.spacing(2),
            }}
            className="print--none"
          >
            There are unsaved changes on the following tabs:{' '}
            {unsavedTabs?.map(t => t.title).join(', ')}
          </Alert>
        )}
        {tab.children}
      </>
    );
    if (isPrint && printAllTabs) {
      return tabContent;
    }
    return selectedTab === tab.key ? tabContent : null;
  };

  if (tabs) {
    return (
      <>
        <AppBar
          id={id}
          position={isSticky ? 'sticky' : 'static'}
          color="inherit"
          component="div"
          sx={{
            boxShadow: 'none',
            backgroundColor:
              backgroundColor === 'white' ? theme.palette.common.white : theme.palette.grey[100],
          }}
          className={clsx(
            !printTabstrip ? 'print--none' : undefined,
            isSticky ? classes.sticky : undefined
          )}
        >
          <MuiTabs
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            value={selectedTab}
            indicatorColor={color}
            textColor={color === 'secondary' ? undefined : color}
            onChange={(event: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)}
            aria-label={`${id}-tabs`}
            className={classes.tabs}
          >
            {tabs &&
              tabs.map(tab => {
                const renderLabel = () => {
                  const label = isMobile && tab.mobileTitle ? tab.mobileTitle : tab.title;
                  const icon = tab.icon ? <FontAwesomeIcon icon={tab.icon} /> : '';
                  if (tab.icon) {
                    return (
                      <span>
                        {icon} {label}
                      </span>
                    );
                  }
                  return label;
                };
                return (
                  <Tab
                    key={tab.key}
                    data-testid={`${kebabCase(tab.title.toString())}-tab`}
                    label={renderLabel()}
                    value={tab.key}
                    disabled={tab.disabled}
                    classes={{
                      root: classes.tabRoot,
                      textColorPrimary: clsx(
                        classes.tabText,
                        tab.isInvalid || tab.hasUnsavedChanges ? 'text--danger' : undefined
                      ),
                      selected: clsx(
                        classes.tabSelected,
                        tab.isInvalid || tab.hasUnsavedChanges ? 'text--danger' : undefined
                      ),
                    }}
                  />
                );
              })}
          </MuiTabs>
        </AppBar>
        {tabs.map(tab => {
          return <Fragment key={tab.key}>{renderTab(tab)}</Fragment>;
        })}
      </>
    );
  }
  return null;
};

const useStyles = makeStyles<Theme, ITabStyles>(theme => ({
  tabs: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tabRoot: {
    minWidth: 0,
    textTransform: 'none',
    fontSize: ({ size, tabCount }) =>
      size === 'lg' && tabCount > 5 ? '1rem' : size === 'lg' ? '1.25rem' : undefined,
    padding: ({ size }) => (size === 'lg' ? '8px 16px' : `15px 20px`),

    [theme.breakpoints.up(1200)]: {
      padding: ({ size, tabCount }: ITabStyles) =>
        size === 'lg' && tabCount > 6 ? '0.5rem' : undefined,
    },

    [theme.breakpoints.up(1400)]: {
      fontSize: ({ size, tabCount }: ITabStyles) =>
        size === 'lg' && tabCount > 5 ? '1.15rem' : undefined,
      padding: ({ size, tabCount }: ITabStyles) =>
        size === 'lg' && tabCount > 6 ? '0.5rem .75rem' : undefined,
    },

    [theme.breakpoints.up(1600)]: {
      fontSize: ({ size, tabCount }: ITabStyles) =>
        size === 'lg' && tabCount > 5 ? '1.25rem' : undefined,
      padding: ({ size, tabCount }: ITabStyles) =>
        size === 'lg' && tabCount > 6 ? '0.5rem 1rem' : undefined,
    },
    '&:hover': {
      fontWeight: ({ color }: ITabStyles) => (color === 'secondary' ? 'bold' : undefined),
    },
    '@media print': {
      display: 'none',
    },
  },
  tabText: {
    color: theme.palette.grey[600],
    borderBottom: `${theme.spacing(0.25)} solid transparent`,
    '&:hover': {
      fontWeight: ({ size }: ITabStyles) => (size === 'lg' ? 'bold' : 'normal'),
      borderBottomColor: ({ color }: ITabStyles) =>
        color === 'secondary'
          ? `${theme.palette.secondary.light}`
          : `${theme.palette.primary.light}`,
    },
    '&.text--danger': {
      color: theme.palette.error.main,
    },
  },
  tabSelected: {
    fontWeight: 'bold',
    color: ({ color }) =>
      color === 'secondary' ? `${theme.palette.grey[600]} !important` : undefined,
    '@media print': {
      display: 'flex',
    },
    '&.text--danger': {
      color: theme.palette.error.main,
    },
  },
}));
