import { createContext, FC, useContext, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getGlobalSearchResults } from '../fetch';
import { ISearchResults, ISearchResultsPage, ISearchWaterTestAccountResult } from '../models';
import { UserContext } from './user';

interface ISearchProps {
  results: ISearchResults | null;
  isLoadingSearchResults: boolean;
  setPage: Dispatch<SetStateAction<ISearchResultsPage[]>>;
  setRowsPerPage: Dispatch<SetStateAction<ISearchResultsPage[]>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  page: ISearchResultsPage[];
  initialPage: ISearchResultsPage[];
  perPage: ISearchResultsPage[];
  recordCount: number;
  waterTestAccount?: ISearchWaterTestAccountResult | null;
  setWaterTestAccount: (record: ISearchWaterTestAccountResult | null) => void;
}

export const SearchContext = createContext<ISearchProps>({
  results: null,
  isLoadingSearchResults: false,
  setPage: () => { },
  setRowsPerPage: () => { },
  setSearchValue: () => { },
  searchValue: '',
  page: [
    { type: 'global', page: 0 },
    { type: 'sites', page: 0 },
    { type: 'customers', page: 0 },
    { type: 'waterTestAccounts', page: 0 },
  ],
  initialPage: [
    { type: 'global', page: 0 },
    { type: 'sites', page: 0 },
    { type: 'customers', page: 0 },
    { type: 'waterTestAccounts', page: 0 },
  ],
  perPage: [
    { type: 'global', perPage: 10 },
    { type: 'sites', perPage: 10 },
    { type: 'customers', perPage: 10 },
    { type: 'waterTestAccounts', page: 0 },
  ],
  recordCount: 0,
  waterTestAccount: null,
  setWaterTestAccount: () => { },
});

interface ISearchContextHandlerProps { }

export const SearchContextHandler: FC<ISearchContextHandlerProps> = ({ children }): JSX.Element => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const [searchValue, setSearchValue] = useState<string>(query ?? '');
  const [recordCount, setRecordCount] = useState<number>(0);
  const [waterTestAccount, setWaterTestAccount] = useState<ISearchWaterTestAccountResult | null>(
    null
  );

  const initialPage: ISearchResultsPage[] = [
    {
      type: 'global',
      page: 0,
    },
    { type: 'sites', page: 0 },
    { type: 'customers', page: 0 },
    { type: 'waterTestAccounts', page: 0 },
  ];

  const [perPage, setRowsPerPage] = useState<ISearchResultsPage[]>([
    { type: 'global', perPage: 10 },
    { type: 'sites', perPage: 10 },
    { type: 'customers', perPage: 10 },
    { type: 'waterTestAccounts', page: 10 },
  ]);
  const [page, setPage] = useState<ISearchResultsPage[]>(initialPage);

  const { isLoading, data } = useQuery<ISearchResults, Error>(
    ['getGlobalSearchResults', query, user],
    () =>
      getGlobalSearchResults({
        page: Number(page?.find(item => item.type === 'global')?.page) + 1,
        perPage: Number(perPage?.find(item => item.type === 'global')?.perPage),
        officeId: user?.officeId,
        search: query ?? '',
      }),
    {
      enabled: !!query && !!user,
      onSuccess: data => {
        setRecordCount(data.totalRecordCount ?? 0);
      },
    }
  );
  const results = useMemo(() => data ?? null, [data]);

  return (
    <SearchContext.Provider
      value={{
        isLoadingSearchResults: isLoading,
        results,
        setPage,
        setRowsPerPage,
        setSearchValue,
        searchValue,
        page,
        initialPage,
        perPage,
        recordCount,
        waterTestAccount,
        setWaterTestAccount,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
