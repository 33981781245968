import { Typography } from '@mui/material';
import { StyleGuidePaths } from '../../constants';
import { Link } from 'react-router-dom';

export const StyleGuideValidationCallout = () => {
  return (
    <Typography mb={1}>
      For Formik validation, define the validation schema using Yup. See{' '}
      <Link to={StyleGuidePaths.uiUxPatterns.url}>UI/UX patterns</Link> for more details.
    </Typography>
  );
};
