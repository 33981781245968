import { IResponse } from './util';

export interface ISearchPhoneNumber {
  phoneNumberType: string | null;
  areaCode: string | null;
  phoneNumber: string | null;
  extension?: string | null;
  description?: string | null;
}

export interface ISiteSearchResult {
  siteId: string | null;
  description: string | null;
  accountId: string | null;
  officeId?: string | null;
  accountName: string | null;
  addressId: string | null;
  addressType: string | null;
  addressName: string | null;
  addressStreet: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressPostCode: string | null;
}

export interface ISearchAccountResult {
  accountId: string | null;
  officeId?: string | null;
  code: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  emailExact: string | null;
  status: string | null;
  type: string | null;
  phoneNumbers: ISearchPhoneNumber[] | null;
}
export interface ISearchWaterTestAccountResult {
  customerId: string | null;
  officeId?: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  status: string | null;
  addressType: string | null;
  addressStreet1: string | null;
  addressStreet2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressPostalCode: string | null;
  accountNumber: string | null;
  fullPhoneNumbers: string | null;
  phoneNumbers: ISearchPhoneNumber[] | null;
}
export interface ISearchResults {
  accounts: IResponse<ISearchAccountResult[]>;
  sites: IResponse<ISiteSearchResult[]>;
  waterTestAccounts: IResponse<ISearchWaterTestAccountResult[]>;
  totalRecordCount: number;
}

export type TSearchType = 'global' | 'sites' | 'customers' | 'waterTestAccounts';
export const SearchTypes = ['global', 'sites', 'customers', 'waterTestAccounts'];

export interface ISearchResultsPage {
  type: TSearchType;
  page?: number;
  perPage?: number;
}

export interface ISearchResultsTab {
  label: string;
  mobileLabel?: string;
  value: TSearchType | string;
  sort: number;
}
