import { FC, Dispatch, useContext, SetStateAction, useState } from 'react';
import { Alert, Box, TextField as MuiTextField, Grid } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { UserContext } from '../../context';
import {
  Modal,
  Loader,
  InventorySearch,
  TextField,
  SelectAsync,
  ModalSaveSection,
} from '../../components';
import { getAllTranCodes } from '../../fetch';
import { ITranCode, IInventory, ISelectOption, IFormLineItem } from '../../models';
import { DECIMAL_REGEX, formatMoney, convertToNumber } from '../../helpers';

interface ILineItemsModalProps {
  isOpen: boolean;
  isSaveDisabled?: boolean;
  isEditable?: boolean;
  isLoading?: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  handleCancel: () => void;
  handleInventoryItemSelect: (item: IInventory, tranCodeId: string) => void;
  setTranCodes?: Dispatch<SetStateAction<ISelectOption[]>>;
  setActualRate?: Dispatch<SetStateAction<number>>;
  isEstimate?: boolean;
  itemId?: string;
  currentLineNumbers?: number[];
  lineItem: IFormLineItem;
  lineItemType?: string;
}

export const LineItemsModal: FC<ILineItemsModalProps> = ({
  isOpen,
  onClose,
  isSaveDisabled,
  handleInventoryItemSelect,
  handleCancel,
  isLoading,
  setTranCodes,
  setActualRate,
  itemId,
  currentLineNumbers,
  lineItem,
  isEditable = true,
  isEstimate,
  lineItemType,
}) => {
  const [localCodes, setLocalCodes] = useState<ISelectOption[] | null>(null);
  const [hasSelectedItem, setHasSelectedItem] = useState(!!itemId ? true : false);
  const { user } = useContext(UserContext);
  const { errors, values, touched, setFieldValue, handleBlur } = useFormikContext<IFormLineItem>();
  const showAddtionalField =
    localCodes?.find(code => code.value === values.tranCodeId)?.label === 'Department';
  const departmentId = localCodes?.filter(val => val.label === 'Department')?.[0].value;
  const currentFormOrderNumber = Number(values.sortOrder);
  const currentOrderNumber = Number(lineItem.sortOrder);
  const hasSameOrderNumber = !itemId
    ? // if we are adding a new line item check its current form value with previous existing line item values
      currentLineNumbers?.includes(currentFormOrderNumber)
    : currentLineNumbers?.includes(currentFormOrderNumber) &&
      currentOrderNumber !== currentFormOrderNumber;

  const isFieldDisabled = itemId ? false : values.tranCodeId === departmentId && !hasSelectedItem;

  const isQtyValid =
    (lineItem.isSerialized && convertToNumber(values.quantity) === 1) ||
    convertToNumber(values.quantity) === -1 ||
    (!lineItem.isSerialized && convertToNumber(values.quantity) !== 0)
      ? true
      : false;

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setHasSelectedItem(false);
      }}
      title={!!itemId && !isEditable ? 'View Item' : !!itemId ? 'Edit Item' : 'Add Item'}
      maxWidth="lg"
      fullHeight
      PaperProps={{
        style: {
          scrollbarGutter: 'stable',
        },
      }}
    >
      <Grid container spacing={2} mt={1}>
        {isEditable && (
          <Grid item xs={12} md={6}>
            <InventorySearch
              onSelectInventoryItem={item => {
                handleInventoryItemSelect(item, departmentId!);
                setHasSelectedItem(true);
              }}
              isEstimate={isEstimate}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Form>
            {isLoading && <Loader position="centered" title="Loading" type="overlay" />}
            <>
              {isEditable && isFieldDisabled && (
                <Alert data-testid="warning-alert" severity="warning">
                  Search and select an item to enable the rest of the fields
                </Alert>
              )}
              <Box my={2}>
                <MuiTextField
                  fullWidth
                  name="sortOrder"
                  required
                  size="small"
                  label="Line"
                  type="number"
                  onKeyDown={event => {
                    if (
                      !/[\d]/.test(event.key) &&
                      !/Backspace|Delete|ArrowLeft|ArrowRight/.test(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  value={values.sortOrder}
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue('sortOrder', e.target.value);
                  }}
                  disabled={isFieldDisabled || !isEditable}
                  error={hasSameOrderNumber ? true : touched.sortOrder && !!errors.sortOrder}
                  helperText={
                    hasSameOrderNumber
                      ? 'Line is already in use'
                      : touched.sortOrder && errors.sortOrder
                      ? errors.sortOrder
                      : ''
                  }
                  InputProps={
                    !isFieldDisabled || isEditable
                      ? {
                          onFocus: e => {
                            e.currentTarget.select();
                          },
                        }
                      : undefined
                  }
                  inputProps={{
                    'data-testid': 'line-field'
                  }}
                />
              </Box>
              <Box my={2}>
                <SelectAsync
                  name="tranCodeId"
                  label="Tran Code"
                  required
                  value={values.tranCodeId}
                  apiRequest={() =>
                    getAllTranCodes({
                      officeId: user?.officeId,
                      perPage: -1,
                      tranCodeType: 'Invoice',
                    })
                  }
                  transformResponse={response =>
                    response.records.map((item: ITranCode) => ({
                      label: item.description,
                      value: item.tranCodeId,
                    }))
                  }
                  handleOptions={data => {
                    if (!itemId) {
                      const departmentId = data.filter(val => val.label === 'Department')[0].value;
                      setFieldValue('tranCodeId', departmentId);
                    }
                    setTranCodes && setTranCodes(data);
                    setLocalCodes(data);
                  }}
                  onChange={e => {
                    if (e.target.value !== departmentId && !itemId) {
                      setFieldValue('details', 'Monthly Pool Service');
                    } else {
                      setFieldValue('details', '');
                    }
                    setFieldValue('tranCodeId', e.target.value);
                    setFieldValue('lookupCode', '');
                    setFieldValue('serialNumber', '');
                    setFieldValue('quantity', '1');
                  }}
                  disabled={!!itemId ? values.tranCodeId === departmentId : !!values.lookupCode}
                  inputProps={{
                    'data-testid': 'tran-code-select'
                  }}
                />
              </Box>
              {showAddtionalField && (
                <Box my={2}>
                  <TextField name="lookupCode" label="Lookup Code" disabled inputProps={{
                    'data-testid': 'lookup-code-field'
                  }} />
                </Box>
              )}
              <Box my={2}>
                <MuiTextField
                  name="details"
                  label="Details"
                  value={values.details}
                  required
                  disabled={isFieldDisabled || !isEditable}
                  fullWidth
                  size="small"
                  InputProps={{
                    onFocus:
                      !isFieldDisabled || isEditable
                        ? e => {
                            e.currentTarget.select();
                          }
                        : undefined,
                  }}
                  onChange={e => setFieldValue('details', e.target.value)}
                  inputProps={{
                    'data-testid': 'details-field'
                  }}
                />
              </Box>
              {showAddtionalField && (
                <Box my={2}>
                  <TextField
                    name="serialNumber"
                    label={lineItem.isSerialized ? 'Serial # required for invoicing' : 'Serial #'}
                    disabled={isFieldDisabled || !isEditable}
                    autoSelectOnFocus={!isFieldDisabled || isEditable}
                    inputProps={{
                      'data-testid': 'serial-number-field'
                    }}
                  />
                </Box>
              )}
              <Box my={2}>
                <TextField
                  name="rate"
                  label="Price"
                  required
                  placeholder="0.00"
                  onBlur={e => {
                    handleBlur(e);
                    // Need to strip out all non numeric values from formatted value
                    const value = e.target.value.replace('$', '').replace(',', '');
                    // make sure it is number only before we update state
                    if (value === '' || DECIMAL_REGEX.test(value)) {
                      setActualRate && setActualRate(Number(value));
                    }
                    setFieldValue('rate', formatMoney(e.target.value, 2));
                  }}
                  disabled={isFieldDisabled || !isEditable}
                  autoSelectOnFocus={!isFieldDisabled || isEditable}
                  inputProps={{
                    'data-testid': 'rate-field'
                  }}
                />
              </Box>
              <Box my={2}>
                <MuiTextField
                  fullWidth
                  size="small"
                  type="number"
                  name="quantity"
                  label="Quantity"
                  required
                  value={values.quantity}
                  onChange={e => {
                    setFieldValue('quantity', e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={touched.quantity && !isQtyValid ? true : false}
                  helperText={
                    touched.quantity && lineItem.isSerialized && !isQtyValid
                      ? 'Value must be 1 or -1 for serialized items'
                      : touched.quantity && !lineItem.isSerialized && !isQtyValid
                      ? 'Value must not be zero'
                      : ''
                  }
                  disabled={isFieldDisabled || !isEditable}
                  InputProps={
                    !isFieldDisabled || isEditable
                      ? {
                          onFocus: e => {
                            e.currentTarget.select();
                          },
                        }
                      : undefined
                  }
                  inputProps={{
                    'data-testid': 'quantity-field'
                  }}
                />
              </Box>
            </>
            <ModalSaveSection
              isSaveDisabled={isSaveDisabled || hasSameOrderNumber || !isEditable || !isQtyValid}
              handleCancel={() => {
                handleCancel();
                setHasSelectedItem(false);
              }}
            />
          </Form>
        </Grid>
      </Grid>
    </Modal>
  );
};
