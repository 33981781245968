import {
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  CardTitle,
  Loader,
  useFilters,
  CardFiltersLayout,
  FilterForm,
  Card,
} from '../../../components';
import { Box, IconButton, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useMemo, useCallback, useState, useContext } from 'react';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import {
  faTrash,
  faEdit,
  faPlusCircle,
  faFilter,
  faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { getUsers, getUsersFilters, deleteUser, setUserIsDisabled } from '../../../fetch';
import { IListUser, IFilterLayout } from '../../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { EditUserModal } from './edit-user-modal';
import { BrandingContext } from '../../../context/branding-context';

const filtersLayout: Record<string, IFilterLayout> = {
  UserGroupId: {
    sortOrder: 0,
    xs: 12,
    md: 6,
  },
  InventoryLocationId: {
    sortOrder: 1,
    xs: 12,
    sm: 6,
  },
  IsDisabled: {
    sortOrder: 2,
    xs: 12,
    sm: 6,
  },
};

export const UsersTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { user: currentUser, loadUser: refetchLoggedInUser } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const [selectedUser, setSelectedUser] = useState<IListUser | null>(null);
  const [isShowingEditModal, setIsShowingEditModal] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isTogglingDisabled, setIsTogglingDisaled] = useState(false);

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getUsersFilters(), []),
  });

  const dataFetcher: GridDataFetcher<IListUser, number> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      if (!filtersInitialized) {
        return {
          continueLoading: true,
        };
      }
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: currentUser?.officeId as string,
          ...appliedFilters,
        };

        const res = await getUsers(options);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading users, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchUsers,
  } = useDataGrid<IListUser, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'users-grid',
      sortColumn: 'userName',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleEdit = async (userRow: IListUser) => {
    setSelectedUser(userRow);
    setIsShowingEditModal(true);
  };

  const handleInlineDisabledToggle = async (userRecord: IListUser) => {
    try {
      setIsTogglingDisaled(true);
      await setUserIsDisabled(userRecord.userId, {
        isDisabled: !userRecord.isDisabled,
      });
      enqueueSnackbar(`User was succesfully ${userRecord.isDisabled ? 'enabled' : 'disabled'}`, {
        variant: 'success',
      });
      // if updates are done to the current user, refetch the logged in user
      if (currentUser?.userId === userRecord?.userId) {
        refetchLoggedInUser(false);
      }
      fetchUsers();
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error updating user, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsTogglingDisaled(false);
    }
  };

  const handleDelete = async (val: IListUser) => {
    try {
      const result = await confirm('Are you sure you want to delete this user?');
      if (result) {
        setIsDeletingUser(true);
        await deleteUser(val.userId);
        enqueueSnackbar(`User Deleted!`, {
          variant: 'success',
        });
        fetchUsers();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting user, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingUser(false);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'userName',
        headerName: 'User Name',
        flex: 1,
      },
      {
        field: 'loginName',
        headerName: 'Login Name',
        flex: 1,
      },
      {
        field: 'isDisabled',
        headerName: 'Disabled',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IListUser>) => {
          const userRecord = params.row;
          return (
            <Box>
              <FormControlLabel
                control={<Checkbox name="is-disabled" checked={userRecord.isDisabled} />}
                label=""
                onChange={async () => {
                  const result = await confirm(
                    `Are you sure you want to ${
                      params.row.isDisabled ? 'enable' : 'disable'
                    } this user?`
                  );

                  if (result) {
                    handleInlineDisabledToggle(userRecord);
                  }
                }}
              />
            </Box>
          );
        },
      },
      !isPoolService && {
        field: 'inventoryLocationDescription',
        headerName: 'Inventory Location',
        flex: 1,
      },
      {
        field: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: '',
        renderCell: (params: GridRenderCellParams<IListUser>) => {
          return (
            <Box>
              <IconButton
                color="primary"
                title="Edit user"
                sx={{ marginRight: theme => theme.spacing(1) }}
                onClick={() => {
                  handleEdit(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              {!isPoolService && (
                <IconButton
                  color="error"
                  title="Delete user"
                  onClick={() => {
                    handleDelete(params.row);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isPoolService]);

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          {(isDeletingUser || isTogglingDisabled) && <Loader type="overlay" position="centered" />}
          <CardTitle
            action={
              <>
                <Button
                  onClick={onFilterToggle}
                  startIcon={
                    <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                  }
                  className="print--none"
                  color="secondary"
                  size="small"
                  disabled={isLoading}
                >
                  Filters
                </Button>
                {!isPoolService && (
                  <Button
                    onClick={() => {
                      setIsShowingEditModal(true);
                    }}
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  >
                    Add User
                  </Button>
                )}
              </>
            }
          />
          <CardFiltersLayout isOpen={isShowingFilters}>
            <FilterForm
              // only give the user group filter if pool service
              filters={isPoolService ? filters.filter(f => f.name === 'UserGroupId') : filters}
              values={filterValues}
              filterLayouts={filtersLayout}
              defaultLayout={{ xs: 12 }}
              onSubmit={values => {
                onPageChange(0);
                onSubmitFilters(values);
              }}
              onChange={onFiltersChange}
              isSubmitting={isLoading}
              onReset={onReset}
            />
          </CardFiltersLayout>
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: IListUser) => row.userId}
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            sortModel={sortModel}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
            hasMobileLayout
            mobileProps={{
              handleEdit: (val: IListUser) => {
                handleEdit(val);
              },
              handleDelete: (val: IListUser) => {
                handleDelete(val);
              },
            }}
          />
        </Card>
      </Box>
      <EditUserModal
        isOpen={isShowingEditModal}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            fetchUsers();
          }
          setIsShowingEditModal(false);
          setSelectedUser(null);
        }}
        userId={selectedUser?.userId}
      />
    </>
  );
};
