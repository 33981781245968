import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../components';

export const StructureEquipmentDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the StructureEquipmentForm component as well
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={0} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Filter" labelId="filter">
          {values?.filter ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Filter Pressure" labelId="filterPressure">
          {values?.filterPressure ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Pool Pump" labelId="poolPump">
          {values?.poolPump ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Timer Brand/Model" labelId="timerBrandModel">
          {values?.timerBrandModel ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Heater" labelId="heater">
          {values?.heater ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Other Equipment" labelId="otherEquipment">
          {values?.otherEquipment ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Automatic Controls" labelId="automaticControls">
          {values?.automaticControls ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Water Feature (Describe)" labelId="waterFeature">
          {values?.waterFeature ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Backwash" labelId="backwash">
          {values?.backwash ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Piping" labelId="piping">
          {values?.piping ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Automatic Chlorinator" labelId="automaticChlorinator">
          {values?.automaticChlorinator ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
