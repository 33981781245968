import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IErrorResponse, IDropdownResponse } from '../models';
import {
  IEquipmentManufacturer,
  IEquipmentModel,
  IEquipmentType,
  ISiteEquipment,
  ISiteEquipmentPUT,
} from '../models/equipment';
import { userSignal } from '../signals';

export const getEquipmentStatuses = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/equipment/status`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEquipmentDetail = async (
  equipmentId: string,
  officeId?: string | null
): Promise<ISiteEquipment> => {
  try {
    const { data } = await axios.get(`/api/equipment/${equipmentId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createEquipment = async (
  siteId: string | number,
  data: ISiteEquipmentPUT,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = {
    siteId: siteId,
    ...data,
  };
  try {
    const { data } = await axios.post(`/api/equipment`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateEquipmentDetail = async (
  siteId: number | string,
  equipmentId: number | string,
  data: ISiteEquipmentPUT,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = {
    siteId: siteId,
    ...data,
  };
  try {
    const { data } = await axios.put(`/api/equipment/${equipmentId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const deleteEquipment = async (id: string): Promise<void> => {
  try {
    const { data } = await axios.delete(`/api/equipment/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEquipmentTypes = async (filters?: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IEquipmentType[]>> => {
  try {
    const { data } = await axios.get(`/api/equipment/types`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEquipmentModels = async (
  equipmentTypeId: string,
  filters?: {
    equipmentManufacturerId?: string | null;
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  },
  officeId?: string | null
): Promise<IResponse<IEquipmentModel[]>> => {
  try {
    const { data } = await axios.get(`/api/equipment/types/${equipmentTypeId}/models`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEquipmentManufacturers = async (filters?: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IEquipmentManufacturer[]>> => {
  try {
    const { data } = await axios.get(`/api/equipment/manufacturers`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
