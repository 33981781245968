import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  ICommissionRule,
  ICommissionRulePost,
  ICommissionRuleDetail,
  IDropdownResponse,
} from '../models';
import { userSignal } from '../signals';

export const getCommissionRules = async ({
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<ICommissionRule[]>> => {
  try {
    const { data } = await axios.get(`/api/commissions/rules`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const putCommissionRule = async (
  commissionRuleId: string,
  payload: ICommissionRulePost,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/commissions/rules/${commissionRuleId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const postCommissionRule = async (
  payload: ICommissionRulePost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/commissions/rules`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommissionRule = async (
  commissionRuleId: string,
  officeId?: string | null
): Promise<ICommissionRuleDetail> => {
  try {
    const { data } = await axios.get(`/api/commissions/rules/${commissionRuleId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteCommissionRule = async (commissionRuleId: string | number) => {
  try {
    const { data } = await axios.delete(`/api/commissions/rules/${commissionRuleId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommissionRulePayToTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/commissions/rules/pay-to-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
