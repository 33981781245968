import { ResponsivePie } from '@nivo/pie';
import { FC, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { ILeadsBySource } from '../../models';
import { useNivoTheme } from '../../hooks';

interface PieDatum {
  id: string;
  label: string;
  value: number;
}

interface LeadsBySourceChartProps {
  data: ILeadsBySource[];
}

export const LeadsBySourceChart: FC<LeadsBySourceChartProps> = ({ data }) => {
  const { colors } = useNivoTheme();
  const isXsSmMobile = useMediaQuery(`(max-width: 500px)`);
  const isSmMobile = useMediaQuery(`(max-width: 560px)`);
  const isSmMobileOffset = useMediaQuery(`(min-width: 561px) and (max-width: 578px)`);
  const isMobile = useMediaQuery(`(min-width: 561px) and (max-width: 1200px)`);
  const isMiddleDesktop = useMediaQuery(`(min-width: 1200px) and (max-width: 1450px)`);

  const pieData: PieDatum[] = useMemo(() => {
    return data.map(leadsByMonth => {
      return {
        id: leadsByMonth.sourceDisplayName,
        label: leadsByMonth.sourceDisplayName,
        value: leadsByMonth.numberOfLeads,
      };
    });
  }, [data]);
  const calcLegendsXPosition = (): number => {
    if (isSmMobileOffset) {
      return -185;
    }
    if (isSmMobile) {
      return 10;
    }
    if (isMiddleDesktop || isSmMobile) {
      return -180;
    }
    if (isMobile) {
      return -200;
    }
    return 0;
  };
  const calcLegendsYPosition = (): number => {
    if (isSmMobile) {
      return 40;
    }
    if (isMiddleDesktop || isSmMobile) {
      return 20;
    }
    if (isMobile || isSmMobile) {
      return -30;
    }
    return 45;
  };
  return (
    <ResponsivePie
      data={pieData}
      margin={{
        top: 10,
        right: isXsSmMobile ? 50 : isSmMobile ? 100 : isMiddleDesktop ? 30 : 80,
        bottom: 80,
        left: isXsSmMobile ? 50 : isSmMobile ? 100 : 80,
      }}
      // fixes issue of console errors firing when responsive screen changes
      // https://github.com/plouc/nivo/issues/2111
      animate={false}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={colors}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['brighter', 5]],
      }}
      enableArcLinkLabels={false}
      legends={[
        {
          anchor: 'bottom',
          direction: isMobile || isMiddleDesktop || isSmMobile ? 'column' : 'row',
          justify: false,
          itemsSpacing: isMobile || isSmMobile ? 30 : 50,
          translateX: calcLegendsXPosition(),
          translateY: calcLegendsYPosition(),
          itemWidth: isMobile || isMiddleDesktop || isSmMobile ? 180 : 60,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'top-to-bottom',
          itemOpacity: 1,
          symbolSize: 16,
          symbolShape: 'square',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};
