import { FC } from 'react';
import { FloatingToolbar } from '../../floating-toolbar';
import { ModalSaveSection as SaveSection } from '../../modal';

interface SaveAndCancelStripProps {
  allowSave: boolean;
  allowCancel: boolean;
  onSave: () => unknown;
  onCancel: () => unknown;
  changeCount: number;
}

export const SaveAndCancelStrip: FC<SaveAndCancelStripProps> = ({
  allowCancel = true,
  allowSave = true,
  onSave,
  onCancel,
  changeCount,
}) => {
  let saveLabel = 'Save';

  if (changeCount) {
    if (changeCount === 1) {
      saveLabel = 'Save (1 CHANGE)';
    } else {
      saveLabel = `Save (${changeCount} CHANGES)`;
    }
  }
  return (
    <FloatingToolbar>
      <SaveSection
        hasNoMarginTop
        handleCancel={onCancel}
        isCancelDisabled={!allowCancel}
        handleSave={onSave}
        isSaveDisabled={!allowSave}
        submitLabel={saveLabel}
      />
    </FloatingToolbar>
  );
};
