import { FC, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { IInitialTaskCompleteValues, ITask, ITaskPost } from '../../../models';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DisplayGroup, Loader, Modal, ModalSaveSection, Wysiwyg } from '../../../components';
import { Box, Fade } from '@mui/material';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getSingleTask, updateTask } from '../../../fetch';
import { truncate } from '../../../helpers';

interface IQuickCompleteModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  modalTitle?: string;
  subtitle?: string;
  task: ITask;
}
const QuickCompleteModalSchema = yup.object().shape({
  completionNotes: yup.string().max(255, 'Max 255 characters').nullable(),
});

export const QuickCompleteModal: FC<IQuickCompleteModalProps> = ({
  open,
  onClose,
  onSubmit,
  modalTitle = 'Complete Task',
  subtitle,
  task,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const COMPLETE_TASK_FORM_VALUES: IInitialTaskCompleteValues = { completionNotes: '' };
  const { resetForm, isSubmitting, handleSubmit, values, isValid, setFieldValue } =
    useFormik<IInitialTaskCompleteValues>({
      validateOnChange: true,
      initialValues: { ...COMPLETE_TASK_FORM_VALUES },
      validationSchema: QuickCompleteModalSchema,
      onSubmit: async (values, actions) => {
        try {
          const taskDetails = await getSingleTask(task.scheduledTaskId);
          const data: ITaskPost = {
            ...values,
            taskDefId: taskDetails.taskDefId,
            priorityType: taskDetails.priorityType,
            userId: taskDetails?.userId ?? null,
            accountId: taskDetails?.accountId ?? null,
            isCompleted: true,
            whenCompleted: new Date().toISOString(),
            comments: taskDetails?.comments ?? null,
            estimatedMinutes: taskDetails?.estimatedMinutes ?? 0,
            estimatedHours: taskDetails?.estimatedHours ?? 0,
            whenScheduledDate: null,
            siteId: taskDetails?.siteId ?? null,
            scheduledServiceId: taskDetails?.scheduledServiceId ?? null,
          };
          await updateTask(task.scheduledTaskId, data);
          enqueueSnackbar('Task Completed successfully', {
            variant: 'success',
          });
          onSubmit();
          onClose();
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(
            error?.data?.Detail ?? 'An unexpected error occurred while completing the task',
            {
              variant: 'error',
            }
          );
        }
      },
    });
  const reset = useCallback(() => {
    resetForm({
      values: {
        ...COMPLETE_TASK_FORM_VALUES,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);
  const handleClose = () => {
    onClose();
    reset();
  };
  return (
    <Modal open={open} onClose={handleClose} maxWidth="sm" title={modalTitle} subtitle={subtitle}>
      {isSubmitting && <Loader type="overlay" position="centered" title="Completing..." />}
      <Fade in={open}>
        <form onSubmit={handleSubmit} autoComplete="off">
          {task.accountName ? (
            <Box my={1}>
              <DisplayGroup label="Customer" labelId="task-customer">
                {task.accountName}
              </DisplayGroup>
            </Box>
          ) : (
            ''
          )}
          {task.description ? (
            <Box my={1}>
              <DisplayGroup label="Description" labelId="task-description">
                <div dangerouslySetInnerHTML={{ __html: truncate(task.description ?? '', 150) }} />
              </DisplayGroup>
            </Box>
          ) : (
            ''
          )}
          <Box my={1}>
            <Wysiwyg
              label="Completion Notes"
              value={values.completionNotes}
              onChange={val => setFieldValue('completionNotes', val)}
              placeholder="Add completion notes..."
            />
          </Box>
          <ModalSaveSection
            handleCancel={handleClose}
            submitLabel="Complete"
            submitIcon={faCheck}
            isSaveDisabled={isSubmitting || !isValid}
          />
        </form>
      </Fade>
    </Modal>
  );
};
