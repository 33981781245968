import { axios } from '../helpers';
import qs from 'querystring';
import {
  IListUser,
  IUpdateUser,
  IUser,
  IResponse,
  IGetFiltersResponse,
  ICreateUser,
  IUpdateUserPassword,
  ITechnicianAvailability,
  ITechnicianAvailabilityPUT,
  ISetUserIsDisabled,
} from '../models';
import { userSignal } from '../signals';

export const getUser = async (userId: string, officeId?: string | null): Promise<IUser> => {
  try {
    const { data } = await axios.get(`/api/users/${userId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsers = async (filters?: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  search?: string;
  userGroupId?: string;
  hasTransactionBatches?: boolean;
  isDisabled?: boolean;
  isForRouting?: boolean;
  onlyIncludeRepairTechs?: boolean;
}): Promise<IResponse<IListUser[]>> => {
  try {
    const { data } = await axios.get(`/api/users`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/users/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: (params: any) => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const createUser = async (payload: ICreateUser, officeId?: string | null): Promise<void> => {
  try {
    await axios.post(`/api/users`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateUser = async (
  userId: string,
  payload: IUpdateUser,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.put(`/api/users/${userId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const setUserIsDisabled = async (
  userId: string,
  payload: ISetUserIsDisabled,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.put(`/api/users/${userId}/disabled`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteUser = async (userId: string): Promise<void> => {
  try {
    await axios.delete(`/api/users/${userId}`);
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getUserAvatar = async (userId: string, officeId?: string | null): Promise<string> => {
  try {
    const { data } = await axios.get<string>(`/api/users/${userId}/avatar`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadUserAvatar = async (userId: string, newAvatarFile: File): Promise<void> => {
  try {
    const formData = new FormData();
    // officeId isn't needed here
    formData.append('file', newAvatarFile);
    await axios.post(`/api/users/${userId}/avatar`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserPassword = async (
  userId: string,
  payload: IUpdateUserPassword,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.put(`/api/users/${userId}/password`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairTechAvailability = async (filters?: {
  userId?: string;
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<ITechnicianAvailability[]>> => {
  try {
    const { data } = await axios.get(`/api/users/repair-tech-availability`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateRepairTechAvailability = async (
  payload: ITechnicianAvailabilityPUT,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.put(`/api/users/repair-tech-availability`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSuperAdminAuditOfficeLog = async (payload: {
  userId: string;
  officeId: string;
}): Promise<void> => {
  try {
    await axios.post(`/api/users/super-admin-audit-office-log`, payload);
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
