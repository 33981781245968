import { Fade, Box, Divider } from '@mui/material';
import { EditAddressInformation, Loader, Modal, ModalSaveSection } from '../../components';
import { Form, Formik } from 'formik';
import { IAddress, ISiteGetResponse } from '../../models';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { updateSite } from '../../fetch';

interface ISiteAddressModal {
  isOpen: boolean;
  handleClose: (shouldReload?: boolean) => void;
  site: any;
  isLoading: boolean;
  accountAddress?: IAddress | null;
  accountId?: string;
}

const FORM_VALIDATION = Yup.object().shape({
  addressName: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('Addressee is required'),
    }),
  street: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('Street is required'),
    }),
  city: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('City is required'),
    }),
  state: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('State is required'),
    }),
  postalCode: Yup.string()
    .max(255, 'Max 255 characters')
    .when('useAccountAddress', {
      is: false,
      then: Yup.string().max(255, 'Max 255 characters').required('Postal code is required'),
    }),
  latitude: Yup.number().required('Latitude is required'),
  longitude: Yup.number().required('Longitude is required'),
});

export const SiteAddressModal: FC<ISiteAddressModal> = ({
  isOpen,
  handleClose,
  site,
  isLoading,
  accountAddress,
  accountId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      title="Edit Site Address"
    >
      <Fade in={isOpen}>
        <div>
          <Formik
            initialValues={{
              ...site,
              street: site?.street ?? '',
              addressName: site?.addressName ?? '',
              city: site?.city ?? '',
              state: site?.state ?? '',
              postalCode: site?.postalCode ?? '',
            }}
            enableReinitialize={true}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, actions) => {
              try {
                await updateSite(
                  {
                    ...values,
                    longitude: pushPinLocation?.longitude ?? undefined,
                    latitude: pushPinLocation?.latitude ?? undefined,
                  } as ISiteGetResponse,
                  accountId!,
                  site?.siteId!
                );
                enqueueSnackbar(`Site Updated!`, {
                  variant: 'success',
                });
                handleClose(true);
              } catch (error: any) {
                console.log(error);
                enqueueSnackbar(error?.Detail ?? `Error updating site, please try again.`, {
                  variant: 'error',
                });
              }
            }}
          >
            {({ isSubmitting, values, handleSubmit }) => {
              return (
                <>
                  {(isLoading || isSubmitting) && (
                    <Loader position="centered" type="overlay" zIndex={3} />
                  )}
                  <Form onSubmit={handleSubmit}>
                    <Box mt={2}>
                      <EditAddressInformation
                        accountAddress={accountAddress}
                        pushPinLocation={pushPinLocation}
                        setPushPinLocation={setPushPinLocation}
                        site={site}
                        addressNameLabel="Site Addressee Name"
                        showAccountAddressButton
                      />
                    </Box>
                    <Divider
                      sx={{ borderColor: theme => theme.palette.dividers.grey, marginTop: 2 }}
                    />
                    <ModalSaveSection
                      handleCancel={() => handleClose()}
                      isSaveDisabled={
                        isSubmitting ||
                        !values.street ||
                        !values.city ||
                        !values.state ||
                        !values.postalCode
                      }
                    />
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Fade>
    </Modal>
  );
};
