import { ListItem, ListItemButton } from '@mui/material';
import { IUserGroup } from '../../../models';
import { Dispatch, SetStateAction, FC } from 'react';
import { TableActionsMenu } from '../../../components';

interface IGroupsKebabMenu {
  row: IUserGroup;
  setCurrentUserGroup: Dispatch<SetStateAction<IUserGroup | null>>;
  setIsShowingUsersModal: Dispatch<SetStateAction<boolean>>;
  setIsShowingEditModal: Dispatch<SetStateAction<boolean>>;
}

export const GroupsKebabMenu: FC<IGroupsKebabMenu> = ({
  row,
  setCurrentUserGroup,
  setIsShowingUsersModal,
  setIsShowingEditModal,
}) => {
  return (
    <TableActionsMenu labelContext="User Group" id={`user-group-actions-${row.userGroupId}`}>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            setCurrentUserGroup(row);
            setIsShowingUsersModal(true);
          }}
        >
          Users
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            setCurrentUserGroup(row);
            setIsShowingEditModal(true);
          }}
        >
          Rename Group
        </ListItemButton>
      </ListItem>
    </TableActionsMenu>
  );
};
