import { FC } from 'react';
import { Page } from '../../../components';
import { CalendarDetails } from './calendar-details';

export const CalendarPage: FC = () => {
  return (
    <Page title="Calendar">
      <CalendarDetails />
    </Page>
  );
};
