import { FC } from 'react';
import { useQuery } from 'react-query';
import { DisplayGroup } from '../../../components';
import { getEmailEventTemplateParams } from '../../../fetch';
import { IDropdownResponse } from '../../../models';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface IEmailTemplateSubstitutions {
  selectedEmailEvent: string;
}

export const EmailTemplateSubstitutions: FC<IEmailTemplateSubstitutions> = ({
  selectedEmailEvent,
}) => {
  const classes = useStyles();

  const { data: currentEmailEventParams } = useQuery<IDropdownResponse[], Error>(
    ['getEmailTemplateEventParams', selectedEmailEvent],
    () => getEmailEventTemplateParams({ emailEvent: selectedEmailEvent! }),
    {
      enabled: !!selectedEmailEvent,
    }
  );

  return (
    <DisplayGroup
      label="Substitutions"
      component="div"
      labelId="substitutions"
      className={classes.substitutionsList}
    >
      {currentEmailEventParams?.map((v, index) => {
        return (
          <p key={`${index}`}>
            &#123;{index}&#125; {v.value}
          </p>
        );
      })}
    </DisplayGroup>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  substitutionsList: {
    '& > p': {
      marginBottom: 0,
    },
  },
}));
