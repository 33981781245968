import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  ITechnicianUser,
  ITechnicianUserDetail,
  ICreateTechnicianUser,
  IUpdateTechnicianUser,
  ILookupModel,
  ITechnicianRole,
  ITechnicianDayOff,
} from '../models';
import { userSignal } from '../signals';

export const getTechnicianUsers = async (filters?: {
  officeId?: string | null;
  search?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  roles?: ITechnicianRole;
  isDisabled?: boolean;
}): Promise<IResponse<ITechnicianUser[]>> => {
  try {
    const { data } = await axios.get(`/api/technicians`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const getTechnicianUser = async (
  technicianId: string,
  officeId?: string | null
): Promise<ITechnicianUserDetail> => {
  try {
    const { data } = await axios.get(`/api/technicians/${technicianId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const createTechnicianUser = async (
  createData: ICreateTechnicianUser,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post(`/api/technicians`, {
      ...createData,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const updateTechnicianUser = async (
  technicianId: string,
  updateData: IUpdateTechnicianUser,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.put(`/api/technicians/${technicianId}`, {
      ...updateData,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteTechnicianUser = async (technicianId: string): Promise<void> => {
  try {
    await axios.delete(`/api/technicians/${technicianId}`);
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTechnicianRoles = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/technicians/roles`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTechnicianDaysOff = async (
  technicianId: string,
  params?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number;
    officeId?: string | null;
  }
): Promise<IResponse<ITechnicianDayOff[]>> => {
  try {
    const { data } = await axios.get(`/api/technicians/${technicianId}/days-off`, {
      params: {
        technicianId,
        officeId: params?.officeId ?? userSignal?.value?.officeId ?? '',
        ...params,
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const techDateHasServices = async (
  userId: string,
  serviceDate: string
): Promise<boolean> => {
  try {
    const { data } = await axios.get(`/api/technicians/${userId}/days-off/${serviceDate}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
