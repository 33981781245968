import { Page } from '../../components';
import { BillingDetail } from './billing-page-detail';

export const BillingPage = () => {
  return (
    <Page title="Billing">
      <BillingDetail />
    </Page>
  );
};
