import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { formatDate, formatMoney } from '../../helpers';

const ALLOWED_ID_OPERATORS = ['equals'];
const ALLOWED_STRING_OPERATORS = ['contains'];
const ALLOWED_NUMBER_OPERATORS = ['>=', '<='];
const ALLOWED_DATE_OPERATORS = ['onOrAfter', 'onOrBefore'];

export const idOperators = getGridStringOperators().filter(op =>
  ALLOWED_ID_OPERATORS.includes(op.value)
);

const stringOperators = getGridStringOperators().filter(op =>
  ALLOWED_STRING_OPERATORS.includes(op.value)
);

const numberOperators = getGridNumericOperators().filter(op =>
  ALLOWED_NUMBER_OPERATORS.includes(op.value)
);

export const dateOperators = getGridDateOperators().filter(op =>
  ALLOWED_DATE_OPERATORS.includes(op.value)
);
export const inventoryColumns: GridColDef[] = [
  {
    field: 'itemId',
    headerName: 'Item ID',
    type: 'string',
    filterOperators: idOperators,
    disableColumnMenu: true,
    width: 80,
  },
  {
    field: 'itemLookupCode',
    headerName: 'Lookup Code',
    type: 'string',
    filterOperators: stringOperators,
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    filterOperators: stringOperators,
    disableColumnMenu: true,
    width: 300,
  },
  {
    field: 'manufacturerNumber',
    headerName: 'Mfg. Num',
    type: 'string',
    filterOperators: stringOperators,
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'aliases',
    headerName: 'Aliases',
    disableColumnMenu: true,
    width: 100,
    valueFormatter: (params: GridValueFormatterParams<string[]>) => {
      return params.value?.join(', ');
    },
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    type: 'number',
    filterOperators: numberOperators,
    disableColumnMenu: true,
    width: 80,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return formatMoney(params.value, 2);
    },
    filterOperators: numberOperators,
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return formatMoney(params.value, 2);
    },
    filterOperators: numberOperators,
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'isTaxable',
    headerName: 'Taxable',
    type: 'boolean',
    disableColumnMenu: true,
    width: 85,
  },
  {
    field: 'dateUpdated',
    headerName: 'Updated',
    type: 'dateTime',
    filterOperators: dateOperators,
    disableColumnMenu: true,
    width: 125,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return !!params.value ? formatDate(params.value) : '';
    },
  },
  {
    field: 'saleStartDate',
    headerName: 'Sale Start',
    type: 'dateTime',
    filterOperators: dateOperators,
    width: 125,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return !!params.value ? formatDate(params.value) : '';
    },
  },
  {
    field: 'saleEndDate',
    headerName: 'Sale End',
    type: 'dateTime',
    filterOperators: dateOperators,
    width: 125,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return !!params.value ? formatDate(params.value) : '';
    },
  },
  {
    field: 'salePrice',
    headerName: 'Sale Price',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return formatMoney(params.value, 2);
    },
    filterOperators: numberOperators,
    disableColumnMenu: true,
    width: 125,
  },
];
