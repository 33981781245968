import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';
import { updateOfficePhoneNumber, createOfficePhoneNumber } from '../../../fetch';
import { ICompanyPhone } from '../../../models';

interface IAddEditPhoneNumberModal {
  open: boolean;
  onClose: () => void;
  currentPhoneNumber?: ICompanyPhone | null;
  fetchPhoneNumbers: () => void;
}

const Schema = Yup.object().shape({
  phoneNumberType: Yup.string().required('Required').max(20, "Phone Type must be 20 characters or less."),
  areaCode: Yup.number()
    .typeError('Area Code must be a number.')
    .required('Required')
    .max(1000, 'Area Code should not exceed 3 characters!'),
  phoneNumber: Yup.number()
    .typeError('Phone Number must be a number.')
    .required('Required')
    .max(10000000, 'Phone Number should not exceed 7 characters!')
    .min(1000000, 'Phone Number must be at least 7 characters!'),
  description: Yup.string().max(50),
  extension: Yup.number()
    .typeError('Extension must be a number.')
    .max(10000000000, 'Phone Number should not exceed 10 characters!'),
});

export const AddEditPhoneNumberModal: FC<IAddEditPhoneNumberModal> = ({
  open,
  onClose,
  currentPhoneNumber,
  fetchPhoneNumbers,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          officePhoneNumberId: currentPhoneNumber?.officePhoneNumberId ?? '',
          phoneNumberType: currentPhoneNumber?.phoneNumberType ?? '',
          description: currentPhoneNumber?.description ?? '',
          areaCode: currentPhoneNumber?.areaCode ?? '',
          phoneNumber: currentPhoneNumber?.phoneNumber ?? '',
          officeId: user?.officeId ?? '',
          extension: currentPhoneNumber?.extension ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: ICompanyPhone = {
            officePhoneNumberId: values?.officePhoneNumberId ?? '',
            phoneNumberType: values?.phoneNumberType ?? '',
            description: values?.description ?? '',
            areaCode: values?.areaCode ?? '',
            phoneNumber: values?.phoneNumber ?? '',
            officeId: values?.officeId ?? '',
            extension: values?.extension ?? '',
          };
          try {
            currentPhoneNumber
              ? await updateOfficePhoneNumber(data)
              : await createOfficePhoneNumber(data);
            enqueueSnackbar(
              currentPhoneNumber
                ? 'Successfully updated Phone Number!'
                : 'Successfully created Phone Number!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchPhoneNumbers();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Phone Number. Please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentPhoneNumber ? 'Edit Phone Number' : 'Add Phone Number'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="phoneNumberType" label="Phone Type" required inputProps={{
                          'data-testid': 'phone-type-field'
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" inputProps={{
                          'data-testid': 'description-field'
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="areaCode" label="Area Code" required inputProps={{
                          'data-testid': 'area-code-field'
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="phoneNumber" label="Phone Number" required inputProps={{
                          'data-testid': 'phone-number-field'
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="extension" label="Extension" inputProps={{
                          'data-testid': 'extension-field'
                        }} />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
