import { FC } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps as StandardCheckboxProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';

interface CheckboxProps extends StandardCheckboxProps {
  name: string
};

export const Checkbox: FC<CheckboxProps> = ({
  name,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { checked } = target;
    setFieldValue(name, Boolean(checked))
  };

  const config = {
    checked: Boolean(field.value),
    ...field,
    ...props,
    onChange: props.onChange ? props.onChange : handleChange,
    
  }

  return (
    <MuiCheckbox { ...config } />
  )
};
