import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IPrimaryIcon {
  icon: IconProp;
  iconTitle?: string;
}

export const PrimaryIcon: FC<IPrimaryIcon> = ({ icon, iconTitle }) => {
  const classes = useStyles();
  return <FontAwesomeIcon className={classes.icon} icon={icon} title={iconTitle} />;
};
const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    borderRadius: '50%',
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontSize: '14px',
    marginRight: '5px',
    backgroundColor: '#C8eaf3',
  },
}));
