import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { CardButton } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideCardButton = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const { enqueueSnackbar } = useSnackbar();

  return (
    <StyleGuidePage title="Card Button">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Card Button component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-card/" target="_blank">
              MUI Card
            </Link>{' '}
            component. Meant for landing page use, the card button was originally designed to
            display a selection of large buttons to help move the user through a process (i.e. lead
            type creation). The coloring is built-in with the option to customize the icon, heading,
            and subheading.
          </Typography>

          <code>
            Props:
            <br />- children?: any
            <br />- avatar?: JSX.Element
            <br />- icon?: IconProp
            <br />- title?: string
            <br />- subheader?: string
            <br />- onClick?: () =&gt; void
            <br />- fullHeight?: boolean
            <br />- alignCenter?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardButton
              alignCenter
              title="Custom Title"
              icon={faUser}
              onClick={() => {
                enqueueSnackbar('Perform some sort of action onClick', {
                  variant: 'info',
                });
              }}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;alignCenter <br />
            &nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform some sort of action
            onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardButton
              title="Custom Title"
              subheader="Instructional text that describes an action or purpose for the button"
              icon={faUser}
              onClick={() => {
                enqueueSnackbar('Perform some sort of action onClick', {
                  variant: 'info',
                });
              }}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;subheader="Instructional text that describes an action or purpose for
            the button" <br />
            &nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform some sort of action
            onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <CardButton
                  title="Custom Title"
                  subheader="Instructional text that describes an action or purpose for the button"
                  icon={faUser}
                  onClick={() => {
                    enqueueSnackbar('Perform some sort of action onClick', {
                      variant: 'info',
                    });
                  }}
                />
              </Grid>

              <Grid item md={6}>
                <CardButton
                  title="Custom Title"
                  icon={faUser}
                  onClick={() => {
                    enqueueSnackbar('Perform some sort of action onClick', {
                      variant: 'info',
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Grid container spacing=&#123;2&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item md=&#123;6&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subheader="Instructional text that
            describes an action or purpose for the button" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform
            some sort of action onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item md=&#123;6&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subheader="Instructional text that
            describes an action or purpose for the button" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform
            some sort of action onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &lt;/Grid&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <CardButton
                  fullHeight={false}
                  title="Custom Title"
                  subheader="Instructional text that describes an action or purpose for the button"
                  icon={faUser}
                  onClick={() => {
                    enqueueSnackbar('Perform some sort of action onClick', {
                      variant: 'info',
                    });
                  }}
                />
              </Grid>

              <Grid item md={6}>
                <CardButton
                  alignCenter
                  fullHeight={false}
                  title="Custom Title"
                  icon={faUser}
                  onClick={() => {
                    enqueueSnackbar('Perform some sort of action onClick', {
                      variant: 'info',
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Grid container spacing=&#123;2&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item md=&#123;6&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fullHeight=&#123;false&#125;{' '}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subheader="Instructional text that
            describes an action or purpose for the button" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform
            some sort of action onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Grid item md=&#123;6&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;CardButton <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;alignCenter
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fullHeight=&#123;false&#125;{' '}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title="Custom Title" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subheader="Instructional text that
            describes an action or purpose for the button" <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon=&#123;faUser&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar('Perform
            some sort of action onClick', &#123; variant: 'info', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &lt;/Grid&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
