import { getLocalStorage, removeLocalStorage, setLocalStorage } from './local-storage';
import { getEnvironment } from './environment';
import { deleteCookie, getEnvCookieKey } from './cookies';
import { IUser } from '../models';
/**
 * Set user to local storage
 * @param user ILoginUser
 */
export const setUserLocalStorage = (user: IUser | undefined): void => {
  if (user) {
    setLocalStorage(`pcp-${getEnvironment()}-user`, user);
  }
};

/**
 * Remove user from local storage
 */
export const removeUserLocalStorage = async (): Promise<void> => {
  await removeLocalStorage(`pcp-${getEnvironment()}-user`);
};

/**
 * Get user from local storage
 */
export const getUserLocalStorage = async (): Promise<IUser | undefined> => {
  return await getLocalStorage(`pcp-${getEnvironment()}-user`);
};

/**
 * Set access token and refresh token cookies for a user
 * @param accessToken string
 * @param tokenExpiresAt string
 */

/**
 * When logging a user out, remove their tokens and local storage
 */
export const userLogout = () => {
  deleteCookie(
    getEnvCookieKey(),
    '/',
    getEnvironment() === 'localhost' ? 'localhost' : `.pinchapenny.com`
  );
  removeUserLocalStorage();
};
