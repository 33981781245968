import { ContentCopy } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FC } from 'react';

interface ICodeBlockProps {
  children: any;
  id?: string;
  handleCopyClick?: (val: string) => void;
  isCopied?: {
    [val: string]: boolean;
  };
  canCopy?: boolean;
}

export const CodeBlock: FC<ICodeBlockProps> = ({
  children,
  id,
  handleCopyClick,
  isCopied,
  canCopy = true,
}) => {
  const copyText = String(children)
    .replaceAll(', ,[object Object],', '')
    .replaceAll(',[object Object],', '');

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.grey[200],
        overflowX: 'auto',
        padding: theme => theme.spacing(1.5),
      }}
    >
      <pre style={{ margin: 0 }}>
        <code id={id}>{children}</code>
      </pre>
      {canCopy && (
        <Button
          title="Copy Code to Clipboard"
          startIcon={<ContentCopy />}
          color="secondary"
          variant="text"
          size="small"
          disabled={isCopied && isCopied[copyText]}
          sx={{ marginTop: theme => theme.spacing(1) }}
          onClick={() => {
            handleCopyClick && handleCopyClick(copyText);
          }}
        >
          Copy Code
        </Button>
      )}
    </Box>
  );
};
