export interface ILeadUser {
  userId: string;
  userLoginName: string;
  userName: string;
}

export interface ILead {
  leadId: string | number;
  date: string;
  status: string;
  serviceType: LeadServiceType;
  description: string | null;
  customerName: string | null;
  customerAddress: string | null;
  customerPhone: string | null;
  customerEmail: string | null;
  source: string;
  user?: ILeadUser | null;
}

export interface ILeadNote {
  leadNoteId: number;
  note: string;
  createdBy: string;
  createdDate: string;
}

export type LeadServiceType =
  | 'Pool Cleaning & Maintenance'
  | 'Pool Equipment Installation & Repair'
  | 'Pool Lead Detection & Repair'
  | 'Pool Renovations'
  | 'Acid Washing & Stain Treatment'
  | 'Pressure Washing Service'
  | 'Home Landscape Lighting Design'
  | 'Outdoor Living Design'
  | 'Screen Repair & Rescreening Services'
  | 'Other';

export enum LeadServiceEnum {
  Maintenance = 'Pool Cleaning & Maintenance',
  InstallRepair = 'Pool Equipment Installation & Repair',
  DetectionRepair = 'Pool Lead Detection & Repair',
  Renovations = 'Pool Renovations',
  Treatment = 'Acid Washing & Stain Treatment',
  Pressure = 'Pressure Washing Service',
  LandscapeDesign = 'Home Landscape Lighting Design',
  OutdoorDesign = 'Outdoor Living Design',
  ScreenRepair = 'Screen Repair & Rescreening Services',
  Other = 'Other',
}

export interface ILeadEstimatePost {
  estimateNumber: string;
  estimateStatus: string;
  userId: string;
  details: string;
  termsAndConditions: string;
  whenExpires: string;
  leadId: number | null;
}
export interface ILeadsByMonth {
  month: number;
  numberOfLeads: number;
  year: number;
}

export interface ILeadsBySource {
  numberOfLeads: number;
  source: string;
  sourceDisplayName: string;
}

export interface ILeadPost {
  officeId?: string | null;
  source: string;
  date: string;
  status: string;
  serviceType: string;
  description: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
  userId: string | null;
  addressModel: {
    address: string;
    city: string;
    state: string;
    postalCode: string;
  };
  createTask: boolean;
}

export interface ILeadUpdate {
  officeId?: string | null;
  source: string;
  date: string;
  status: string;
  serviceType: string;
  description: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
  userId: string | null;
  addressModel: {
    address: string;
    city: string;
    state: string;
    postalCode: string;
  };
}

export interface ILeadDetail {
  leadId: number;
  date: string;
  status: string;
  serviceType: LeadServiceType;
  serviceTypeValue: string;
  description: string;
  customerName: string;
  source: string;
  user: {
    userId: string;
    userLoginName: string;
  };
  customerAddress: string;
  customerEmail: string;
  customerPhone: string;
  customerCity: string;
  customerPostalCode: string;
  customerState: string;
  sourceType: string;
  statusBarStatus: string;
  customer: {
    accountName: string | null;
    accountId: string;
  } | null;
  potentialAccountId: string | null;
  syncLeadId: number | null;
}
