import { Box, Divider, Typography } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { Loader, Pagination } from '../../components';
import { getNews } from '../../fetch';
import { INews } from '../../models';
import { NewsListItem } from './news-list-item';
import { prioritizeNewsItems } from '../../helpers';

interface INewsListProps {
  category: string;
  sortBy?: string;
  sortDirection?: GridSortDirection;
  onlyActive?: boolean;
  isPrioritized?: boolean;
  showPagination?: boolean;
}

export const NewsList: FC<INewsListProps> = ({
  category,
  sortBy = 'whenCreated',
  sortDirection = 'desc',
  onlyActive = true,
  isPrioritized = true,
  showPagination = true,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingNews, setIsLoadingNews] = useState(true);
  const [newsItems, setNewsItems] = useState<INews[]>([]);

  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);

  const fetchNewsItems = async () => {
    setIsLoadingNews(true);
    try {
      const res = await getNews({
        category: category,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page + 1,
        perPage: showPagination ? perPage : -1,
        onlyActive: onlyActive,
      });
      setNewsItems(isPrioritized ? prioritizeNewsItems(res.records) : res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error loading ${category} items, please try again.`, {
        variant: 'error',
      });
      enqueueSnackbar(`Error loading ${category} items, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingNews(false);
    }
  };

  useEffect(() => {
    fetchNewsItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, category]);
  return (
    <>
      {isLoadingNews && (
        <Box height="10rem">
          <Loader position="centered" type="inline" />
        </Box>
      )}
      {!isLoadingNews && (newsItems?.length === 0 || !newsItems) && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="10rem"
        >
          <Typography>There are no {category} items to display.</Typography>
        </Box>
      )}
      {!isLoadingNews &&
        newsItems &&
        newsItems?.length > 0 &&
        newsItems.map((item, index) => {
          return (
            <Box marginTop={theme => theme.spacing(1.5)} key={item.newsItemId}>
              {index !== 0 && (
                <Divider sx={{ marginBottom: theme => theme.spacing(1.5) }} className="grey" />
              )}
              <NewsListItem newsItem={item} />
            </Box>
          );
        })}
      {showPagination &&
        !isLoadingNews &&
        newsItems &&
        newsItems?.length > 0 &&
        newsItems.length > perPage && (
          <>
            <Divider sx={{ marginTop: theme => theme.spacing(2) }} />
            <Box
              paddingTop={1}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              alignItems="center"
              justifyContent={{
                xs: 'center',
                sm: 'space-between',
              }}
            >
              <Box marginBottom={1}>
                <Pagination
                  page={page}
                  count={recordCount}
                  rowsPerPage={perPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          </>
        )}
    </>
  );
};
