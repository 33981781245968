import { Button, Typography, Card, Box, styled } from '@mui/material';
import { FC, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { UserAvatar } from '../../../../user-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBroom,
  faFlask,
  faWrench,
  faGripVertical,
  faRepeat,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ICalendarChanges,
  ICalendarView,
  ICalendarEventDetail,
  CalendarEventTypes,
} from '../../../../../models';
import { OTSDetailPage, ServiceDetailPage, TasksDetailPage } from '../../../../../pages';
import { EditSiteModal } from '../../../../../pages/sites';
import { IColorSet } from '../../../../../models/colors';
import { getLegacyUrl } from '../../../../../helpers';

export interface UnscheduledWorkCardProps {
  workDetail: ICalendarEventDetail;
  changes?: ICalendarChanges;
  colorSet?: IColorSet;
  view: ICalendarView;
}

interface StyledCardProps {
  hasChange?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  view: ICalendarView;
}

export const UnscheduledWorkCard: FC<UnscheduledWorkCardProps> = ({
  workDetail,
  changes,
  colorSet,
  view,
}) => {
  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [isSiteModalOpen, setIsSiteModalOpen] = useState<boolean>(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [isOtsModalOpen, setIsOtsModalOpen] = useState<boolean>(false);

  const { v2Customers } = useFlags();
  const { enqueueSnackbar } = useSnackbar();

  const { userInformation } = workDetail;
  const { username, avatarUrl } = userInformation;
  const backgroundColor = colorSet?.backgroundColor || userInformation.backgroundColor;
  const foregroundColor = colorSet?.foregroundColor || userInformation.foregroundColor;
  const { event } = workDetail;
  const legacyUrl = getLegacyUrl?.();

  let hasChange = !!changes?.[event.eventId];

  return (
    <>
      <StyledCard
        className={clsx(classes.employeeTaskCard, 'print--avoid-break')}
        hasChange={hasChange}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        view={view}
      >
        <div className={classes.employeeTaskDetailsContainer}>
          <Box className={classes.taskInfoContainer}>
            {event.eventType !== CalendarEventTypes.ScheduledTask && (
              <Box className={classes.gripContainer}>
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.siteName}
                  onClick={() => {
                    if (v2Customers) {
                      setIsSiteModalOpen(true);
                    } else {
                      enqueueSnackbar(`Redirecting...`, {
                        variant: 'info',
                      });
                      window.location.href = `${legacyUrl}/Office/Site/View/${event?.siteId}`;
                    }
                  }}
                  data-testid="site-name"
                >
                  <strong>{event.siteName}</strong>
                </Button>
                <FontAwesomeIcon
                  icon={faGripVertical}
                  size="lg"
                  className={clsx(classes.gripIcon, 'print--none')}
                />
              </Box>
            )}
            {event.address && (
              <Typography className={classes.employeeAddress}>{event.address.street}</Typography>
            )}
          </Box>
          <div className={classes.userAvatarContainer}>
            <Box display="flex" alignItems="center" gap={1} flex={1}>
              <StyledUserAvatar
                src={avatarUrl || undefined}
                userName={username}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
              />
              <Typography className={classes.employeeUsername}>{username}</Typography>
            </Box>
          </div>
        </div>
        <div className={classes.serviceTypeStrip}>
          <div className={classes.serviceTypeIconContainer}>
            {event?.eventType === CalendarEventTypes.ScheduledTask && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faListCheck} size="sm" />
              </div>
            )}
            {!!event?.recurringServiceId && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faRepeat} size="sm" />
              </div>
            )}
            {!!event?.showBeakerIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faFlask} size="sm" />
              </div>
            )}
            {!!event?.showBrushIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faBroom} size="sm" />
              </div>
            )}
            {!!event?.showWrenchIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faWrench} size="sm" />
              </div>
            )}
          </div>
          <Button
            variant="text"
            color="inherit"
            className={classes.serviceName}
            onClick={() => {
              if (event.eventType === CalendarEventTypes.ScheduledTask) {
                return setIsTaskModalOpen(true);
              }
              if (event.eventType === CalendarEventTypes.RepairVisit) {
                return setIsOtsModalOpen(true);
              }
              setIsServiceModalOpen(true);
            }}
            data-testid="service-name"
          >
            {event?.description}
          </Button>
        </div>
      </StyledCard>
      {event?.eventId && isServiceModalOpen && event?.eventId && (
        <ServiceDetailPage
          isModal
          isModalOpen={isServiceModalOpen}
          handleModalClose={() => setIsServiceModalOpen(false)}
          currentServiceId={event?.eventId!}
        />
      )}
      {event && isTaskModalOpen && event?.eventId && (
        <TasksDetailPage
          isModal
          isModalOpen={isTaskModalOpen}
          handleModalClose={() => setIsTaskModalOpen(false)}
          currentTaskId={event.eventId}
        />
      )}
      {event?.siteId && isSiteModalOpen && event?.accountId && (
        <EditSiteModal
          isModalOpen={isSiteModalOpen}
          handleModalClose={() => setIsSiteModalOpen(false)}
          siteId={event.siteId}
          accountId={event.accountId}
        />
      )}
      {event?.eventId && isOtsModalOpen && (
        <OTSDetailPage
          isModal
          isModalOpen={isOtsModalOpen}
          currentRepairId={event.eventId}
          handleModalClose={() => {
            setIsOtsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const LAPTOP_L_MEDIA_QUERY = '@media (max-width: 1440px)';
const LAPTOP_MEDIA_QUERY = '@media (max-width: 1250px)';
const MOBILE_MEDIA_QUERY = '@media (max-width: 768px)';

const StyledUserAvatar = styled(UserAvatar, {
  shouldForwardProp: prop => prop !== 'hasChange' && prop !== 'foregroundColor',
})<{ hasChange?: boolean; foregroundColor?: string }>(({ hasChange, foregroundColor }) => ({
  height: '35px',
  width: '35px',
  fontSize: '10px',
  marginRight: 0,
  borderStyle: hasChange ? 'solid' : '',
  borderWidth: '1px',
  borderColor: hasChange ? foregroundColor : '',
  [LAPTOP_MEDIA_QUERY]: {
    height: '25px',
    width: '25px',
  },
}));

const PREFIX = 'UnscheduledWorkCard';

const classes = {
  employeeTaskCard: `${PREFIX}-employeeTaskCard`,
  employeeTaskDetailsContainer: `${PREFIX}-employeeTaskDetailsContainer`,
  gripContainer: `${PREFIX}-gripContainer`,
  taskInfoContainer: `${PREFIX}-taskInfoContainer`,
  siteName: `${PREFIX}-siteName`,
  gripIcon: `${PREFIX}-gripIcon`,
  employeeAddress: `${PREFIX}-employeeAddress`,
  userAvatarContainer: `${PREFIX}-userAvatarContainer`,
  userAvatar: `${PREFIX}-userAvatar`,
  employeeUsername: `${PREFIX}-employeeUsername`,
  serviceTypeStrip: `${PREFIX}-serviceTypeStrip`,
  serviceTypeIconContainer: `${PREFIX}-serviceTypeIconContainer`,
  iconCircle: `${PREFIX}-iconCircle`,
  serviceName: `${PREFIX}-serviceName`,
};

const StyledCard = styled(Card, {
  shouldForwardProp: prop =>
    prop !== 'hasChange' &&
    prop !== 'backgroundColor' &&
    prop !== 'foregroundColor' &&
    prop !== 'view',
})<StyledCardProps>(({ theme, backgroundColor, foregroundColor, hasChange, view }) => ({
  [`&.${classes.employeeTaskCard}`]: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: hasChange ? '1px 1px 1px 1px' : '0px 0px 0px 8px',
    borderColor: backgroundColor,
    backgroundColor: hasChange ? backgroundColor : '',
    color: hasChange ? foregroundColor : '',
    [LAPTOP_MEDIA_QUERY]: {
      borderWidth: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return '1px 1px 1px 1px';
          default:
            return;
        }
      },
    },
    '@media print': {
      boxShadow: 'none !important',
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.employeeTaskDetailsContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 10,
    width: '100%',
    [LAPTOP_MEDIA_QUERY]: {
      padding: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 5;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      padding: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 3;
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.gripContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [LAPTOP_L_MEDIA_QUERY]: {
      alignItems: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'flex-start';
          default:
            return;
        }
      },
    },
    [LAPTOP_MEDIA_QUERY]: {
      justifyContent: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.taskInfoContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [LAPTOP_MEDIA_QUERY]: {
      alignItems: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.siteName}`]: {
    color: hasChange ? foregroundColor : backgroundColor,
    textDecoration: 'underline',
    fontSize: 14,
    padding: 0,
    textAlign: 'left',
    backgroundColor: `transparent !important`,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.gripIcon}`]: {
    [LAPTOP_MEDIA_QUERY]: {
      display: 'none',
    },
  },

  [`& .${classes.employeeAddress}`]: {
    fontSize: 14,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.userAvatarContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
    [LAPTOP_MEDIA_QUERY]: {
      flexDirection: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.userAvatar}`]: {
    height: '35px',
    width: '35px',
    fontSize: '10px',
    marginRight: 0,
    borderStyle: hasChange ? 'solid' : '',
    borderWidth: '1px',
    borderColor: hasChange ? foregroundColor : '',
    [LAPTOP_MEDIA_QUERY]: {
      height: '25px',
      width: '25px',
    },
  },

  [`& .${classes.employeeUsername}`]: {
    fontSize: 16,
    textAlign: 'left',
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
  },

  [`& .${classes.serviceTypeStrip}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    padding: 5,
    backgroundColor: '#F2F2F2',
    color: theme.palette.primary.main,
    width: '100%',
    [LAPTOP_L_MEDIA_QUERY]: {
      flexDirection: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
    },
    '@media print': {
      gap: 0,
    },
  },

  [`& .${classes.serviceTypeIconContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 5,
    '@media print': {
      color: `${theme.palette.common.black} !important`,
      backgroundColor: `${theme.palette.grey[200]} !important`,
      padding: theme.spacing(1),
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },

  [`& .${classes.iconCircle}`]: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#BEE7F1',
    borderRadius: 45,
    fontSize: 14,
    padding: 8,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 8;
          default:
            return;
        }
      },
    },
  },

  [`& .${classes.serviceName}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: 14,
    padding: 2,
    backgroundColor: `transparent !important`,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: () => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 8;
          default:
            return;
        }
      },
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
    },
  },
}));
