import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalLink, Link } from '../link';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IUserMenuItem {
  to: string;
  icon: IconProp;
  text: string;
  isExternal?: boolean;
  isVisible?: boolean;
  iconClassName?: string;
  titleClassName?: string;
  handleMenuClick?: () => void;
}

export const UserMenuItem: FC<IUserMenuItem> = ({
  to,
  icon,
  text,
  iconClassName,
  titleClassName,
  isExternal = true,
  isVisible = true,
  handleMenuClick,
}) => {
  const classes = useStyles();
  if (!isVisible) {
    return null;
  }
  if (isExternal) {
    return (
      <MenuItem>
        <ExternalLink to={to} className={classes.linkWrapper}>
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} className={iconClassName} />
          </ListItemIcon>
          <ListItemText className={titleClassName ? titleClassName : classes.linkTitle}>
            {text}
          </ListItemText>
        </ExternalLink>
      </MenuItem>
    );
  } else {
    return (
      <MenuItem onClick={handleMenuClick}>
        <Link to={to} className={classes.linkWrapper}>
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} className={iconClassName} />
          </ListItemIcon>
          <ListItemText className={titleClassName ? titleClassName : classes.linkTitle}>
            {text}
          </ListItemText>
        </Link>
      </MenuItem>
    );
  }
};
const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    linkWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    linkTitle: {
      color: theme.palette.primary.main,
    },
  };
});
