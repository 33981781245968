import { Page } from '../../components';
import { SearchDetails } from './search-details';

export const SearchPage = () => {
  return (
    <Page title="Search">
      <SearchDetails />
    </Page>
  );
};
