import { Box, Grid, Link, Typography, Divider, ListItem, ListItemButton } from '@mui/material';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { CodeBlock } from '../code-block';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyleGuideServerSideDataGridExample } from './data-grid-example';

export const StyleGuideServerSideDataGrid = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  return (
    <StyleGuidePage title="Data Grid">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The ServerSideDataGrid component is a custom table component that is based on the{' '}
            <Link href="https://mui.com/x/react-data-grid/" target="_blank">
              MUI Data Grid
            </Link>{' '}
            component with built-in branding styles, defaults, pagination, and sorting.
          </Typography>
          <Typography mb={1}>
            NOTE: Filters and the Filters API can be used in conjunction with this component. See{' '}
            <Link href="/kitchen-sink/components/grids">Grids</Link> for more info and examples.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 3 }} mb={1}>
        <Grid item xs={12} md={7} lg={4} xl={4}>
          <Typography>
            <code>
              Props:
              <br />- MuiDataGridProps
              <br />- rowCount: number
              <br />- onPageChange: (page: number) =&gt; void
              <br />- onSortModelChange?: (model: GridSortModel, details:
              GridCallbackDetails&lt;any&gt;) =&gt; void
              <br />- page: number
              <br />- pageSize: number
              <br />- onPageSizeChange: (pageSize: number) =&gt; void
              <br />- mobileBreakPoint?: number
              <br />- hasMobileLayout?: boolean
              <br />- mobileProps?: &#123;
              <br /> &nbsp;&nbsp; useFirstCell?: boolean
              <br /> &nbsp;&nbsp; handleView?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; handleEdit?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; handleDelete?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; handleDownload?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; handleAccordionLabel?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; mobileDefaultAccessor?: string
              <br /> &nbsp;&nbsp; mobileCustomDefaultAccessor?: (val: any) =&gt; void
              <br /> &nbsp;&nbsp; truncateAccordionLabel?: boolean
              <br /> &nbsp;&nbsp; type?: string
              <br /> &nbsp;&nbsp; showHandleActions?: boolean
              <br />
              &#125;
              <br />- noResultsMessage?: string
              <br />- stickyActionsColumn?: boolean
            </code>
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={5} xl={4}>
          <Typography>
            <code>
              Data Grid Column Common Props:
              <br />- field: string
              <br />- headerName?: string
              <br />- description?: string
              <br />- width?: number
              <br />- flex?: number
              <br />- minWidth?: number
              <br />- maxWidth?: number
              <br />- hideable?: boolean
              <br />- sortable?: boolean
              <br />- sortingOrder?: GridSortDirection[]
              <br />- type?: GridColType
              <br />- align?: GridAlignment
              <br />- headerAlign?: GridAlignment
              <br />- disableColumnMenu?: boolean
              <br />- filterable?: boolean
              <br />- colSpan?: number | ((params: GridCellParams) =&gt; number | undefined)
              <br />- valueGetter?: (params: GridValueGetterParams) =&gt; any
              <br />- valueFormatter?: (params: GridValueFormatterParams) =&gt; any
              <br />- renderCell?: (params: GridRenderCellParams&lt;any&gt;) =&gt; any
              <br />
              NOTE: More props can be found in{' '}
              <Link
                href="https://mui.com/x/api/data-grid/data-grid/#data-grid-prop-columns"
                target="_blank"
              >
                MUI Docs
              </Link>
            </code>
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={3} xl={4}>
          <code>
            Common variables:
            <br />
            Note: "Data" should be replaced with appropriate context/verbiage
            <br />- dataFetcher: (useCallback) GridDataFetcher; perPage, sortColumn, sortDirection,
            beforeItemId, afterItemId
            <br />- fetchData: (fetch / try-catch or useQuery)
            <br />- columns: (useMemo) cell definitions should use the appropriate data model (not
            "any") and useMemo should reference anything that affects the grid (i.e. filters, etc.)
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample id="data-grid-example" />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Box mt={1}&gt;
            <br />
            &nbsp;&nbsp;&lt;ServerSideDataGrid
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;autoHeight
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;getRowId=&#123;(row: INews) =&gt; row.newsItemId&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;rows=&#123;rows&#125;
            <br />
            &nbsp;&nbsp; &nbsp;&nbsp;columns=&#123;columns as any[]&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;hasMobileLayout
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;mobileProps=&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mobileCustomDefaultAccessor: (val: INews) =&gt;
            `$&#123;format(new Date(val.displayDate), 'L/d/yy')&#125; $&#123;val.category&#125; -
            $&#123;val.subject&#125;`,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;showHandleActions: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;truncateAccordionLabel: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;hideFooter=&#123;!showPagination&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;rowCount=&#123;recordCount&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;onPageChange=&#123;onKeysetPageChange&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;onPageSizeChange=&#123;onPageSizeChange&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;page=&#123;page&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;pageSize=&#123;perPage&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;sortModel=&#123;sortModel&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;onSortModelChange=&#123;onSortModelChange&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;loading=&#123;isLoading&#125;
            <br />
            &nbsp;&nbsp;/&gt;
            <br />
            &lt;/Box&gt;
          </CodeBlock>
        </Grid>
      </Grid>

      <Typography mb={0.5} variant="h5" component="h2" color="primary">
        dataFetcher
      </Typography>
      <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography>
            Common method of fetching data for a Data Grid. This is where the fetch call is made,
            whether that is try-catch call or an external function. NOTE: be sure to define the
            proper model for the GridDataFetcher based on expected results.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            const dataFetcher: GridDataFetcher&lt;IData&gt; = useCallback(
            <br />
            &nbsp;&nbsp;async (&#123; perPage, sortColumn, sortDirection, page &#125;) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;if (!filtersInitialized) &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return &#123; continueLoading: true, &#125;;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;try &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const res = await getData(&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sortDirection: sortDirection || 'desc',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sortBy: sortColumn || 'displayDate',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;perPage,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;page: page + 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;category: appliedFilters?.Category?.[0]
            ?? undefined,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return &#123; rows: res.records, rowCount:
            res.totalRecordCount, &#125;;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125; catch (error) &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enqueueSnackbar(`Error loading data, please try
            again.`, &#123; variant: 'error', &#125;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;throw error;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;// eslint-disable-next-line react-hooks/exhaustive-deps
            <br />
            &nbsp;&nbsp;[filtersInitialized, appliedFilters]
            <br />
            );
          </CodeBlock>
        </Grid>
      </Grid>
      <Typography mb={0.5} variant="h5" component="h2" color="primary">
        useDataGrid
      </Typography>
      <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography>
            Instance specific to the useDataGrid hook that pulls in required props and allows for
            default value definition.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            const &#123;
            <br />
            &nbsp;&nbsp;rows,
            <br />
            &nbsp;&nbsp;isLoading,
            <br />
            &nbsp;&nbsp;page,
            <br />
            &nbsp;&nbsp;pageSize: perPage,
            <br />
            &nbsp;&nbsp;rowCount: recordCount,
            <br />
            &nbsp;&nbsp;sortModel,
            <br />
            &nbsp;&nbsp;onPageChange,
            <br />
            &nbsp;&nbsp;onPageSizeChange,
            <br />
            &nbsp;&nbsp;onSortModelChange,
            <br />
            &nbsp;&nbsp;refetch,
            <br />
            &#125; = useDataGrid&lt;IData&gt;(&#123;
            <br />
            &nbsp;&nbsp;initialOptions: &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;page: 0,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;pageSize: 10,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;gridKeyName: 'example-grid',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;sortColumn: 'displayDate',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;sortDirection: 'desc',
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;dataFetcher,
            <br />
            &#125;);
          </CodeBlock>
        </Grid>
      </Grid>
      <Typography mb={0.5} variant="h5" component="h2" color="primary">
        Columns
      </Typography>
      <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
      <Typography mb={2}>
        The following include common column formatting and definitions for the Server Side Data Grid
        component.
      </Typography>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            const columns: GridColDef[] = useMemo( () =&gt; [<br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'serviceDate',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Date',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'dateTime',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;flex: 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;maxWidth: 120,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;valueFormatter: (params: GridValueFormatterParams&lt;any&gt;)
            =&gt; &#123; return !!params.value ? formatDate(params.value) : ''; &#125;,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'siteName',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Site',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'string',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;flex: 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt;
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const &#123; row: original &#125; = params;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const childComponent = (<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Tooltip title=&#123;`View customer's
            site`&#125; placement="bottom"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span&gt;&#123;original.siteName&#125;&lt;/span&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Tooltip&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return &lt;Link
            to=&#123;`#`&#125;&gt;&#123;childComponent&#125;&lt;/Link&gt;;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'serviceType',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Type',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'string',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;flex: 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'assignedTo',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Tech',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'string',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;flex: 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'frequency',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Repeat Link',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'string',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;flex: 1,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;sortable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt;
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const &#123; row: original &#125; = params;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const childComponent = (<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Tooltip title=&#123;`Edit recurring
            appointment schedule`&#125; placement="bottom"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span&gt;&#123;original.frequency&#125;&lt;/span&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Tooltip&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return &lt;Link
            to=&#123;`#`&#125;&gt;&#123;childComponent&#125;&lt;/Link&gt;;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;headerName: 'Status',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;field: 'status',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;type: 'string',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;sortable: false,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt;
            &#123; return (<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Typography sx=&#123; params.row.status ===
            'Open' ? &#123; color: '#41D090', fontWeight: 'bold', fontSize: '14px' &#125; : &#123;
            fontWeight: 'bold', fontSize: '14px' &#125; &#125; &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;params.row.status&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Typography&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;);&#125;,
            <br />
            &nbsp;&nbsp;&#125;,
            <br />
            ].filter(Boolean),
            <br />
            &#47;&#47; eslint-disable-next-line react-hooks/exhaustive-deps
            <br />
            [rows]);
          </CodeBlock>
        </Grid>
      </Grid>
      <Typography mb={0.5} variant="h5" component="h2" color="primary">
        Actions Columns
      </Typography>
      <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample id="action-button-example" perPageOverride={3} />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;field: 'actions',
            <br />
            &nbsp;&nbsp;&nbsp;headerName: '',
            <br />
            &nbsp;&nbsp;&nbsp;sortable: false,
            <br />
            &nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return (
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box textAlign="right"
            display="flex" justifyContent="flex-end" className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Stack
            direction="row" gap=&#123;1&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IconButton
            color="primary" title="Edit Test Item" sx=&#123;&#123; marginRight: theme =&gt;
            theme.spacing(1) &#125;&#125; onClick=&#123;async () =&gt; enqueueSnackbar('Edit
            Action', &#123; variant: 'info', &#125;) &#125; &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon
            icon=&#123;faEdit&#125; size="sm" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IconButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IconButton
            color="error" title="Delete Test Item" sx=&#123;&#123; marginRight: theme =&gt;
            theme.spacing(1) &#125;&#125; onClick=&#123;async () =&gt; enqueueSnackbar('Delete
            Action', &#123; variant: 'error', &#125;) &#125; &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon
            icon=&#123;faTrash&#125; size="sm" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IconButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Stack&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &#125;,
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample
              id="action-menu-example"
              perPageOverride={3}
              showActionMenu
              actionMenuItems={
                <>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Service</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Recurring Service</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Site</ListItemButton>
                    </ListItem>
                  </Link>
                </>
              }
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;field: 'actions',
            <br />
            &nbsp;&nbsp;&nbsp;headerName: '',
            <br />
            &nbsp;&nbsp;&nbsp;sortable: false,
            <br />
            &nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return (
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box textAlign="right"
            display="flex" justifyContent="flex-end" className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableActionsMenu
            id="service-actions"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;Edit
            Service&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;Edit
            Recurring Service&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;Edit
            Site&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/TableActionsMenu&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &#125;,
          </CodeBlock>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample
              id="action-menu-icons-example"
              perPageOverride={3}
              showActionMenu
              actionMenuItems={
                <>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Recurring Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEye} style={{ marginRight: '.5rem' }} />
                        View Site
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Site
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Divider sx={{ borderColor: theme => theme.palette.dividers.grey }} />
                  <Link
                    href="#"
                    underline="none"
                    color="error"
                    sx={{ '&&:hover': { color: theme => theme.palette.error.dark } }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
                        Delete Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              }
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;field: 'actions',
            <br />
            &nbsp;&nbsp;&nbsp;headerName: '',
            <br />
            &nbsp;&nbsp;&nbsp;sortable: false,
            <br />
            &nbsp;&nbsp;&nbsp;filterable: false,
            <br />
            &nbsp;&nbsp;&nbsp;disableColumnMenu: true,
            <br />
            &nbsp;&nbsp;&nbsp;renderCell: (params: GridRenderCellParams&lt;any&gt;) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return (
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box textAlign="right"
            display="flex" justifyContent="flex-end" className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableActionsMenu
            id="service-actions"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Recurring Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEye&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;View Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Divider
            sx=&#123;&#123; borderColor: theme =&gt; theme.palette.dividers.grey &#125;&#125; /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link
            href="#" underline="none" color="error" sx= &#123;&#123; '&&:hover': &#123; color: theme
            =&gt; theme.palette.error.dark &#125; &#125;&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem
            disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faTrash&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Delete Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/TableActionsMenu&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &#125;,
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
