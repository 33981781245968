import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IServiceLocation } from '../../../models/service-locations';
import { formatAddressSingleLine } from '../../../helpers';
import { Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

interface ServiceLocationsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
    rows: IServiceLocation[];
    refetch: () => Promise<void>;
    handleDelete: (transactionBatchId: string) => Promise<void>;
    handleEdit: (serviceLocation: IServiceLocation) => Promise<void>;
}

export const ServiceLocationsDataGrid: FC<ServiceLocationsDataGridProps> = ({
    rows,
    refetch,
    handleDelete,
    handleEdit,
    ...gridProps
}) => {
    const columns = useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Location Name',
                flex: 1,
                filterable: false,
                sortable: true,
                disableColumnMenu: true,
            },
            {
                field: 'address',
                headerName: 'Address',
                flex: 1,
                filterable: false,
                sortable: true,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams<IServiceLocation>) => {
                    const { row: location } = params;
                    return (
                        formatAddressSingleLine(location?.street, location?.city, location?.state, location?.postalCode)
                    );
                },
            },
            {
                field: 'actions',
                headerName: '',
                filterable: false,
                disableColumnMenu: true,
                maxWidth: 100,
                renderCell: (params: GridRenderCellParams<IServiceLocation>) => {
                    const { row: location } = params;
                    return (
                        <Box>
                            <IconButton
                                color="primary"
                                title="Edit Service Location"
                                onClick={() => handleEdit(location!)}
                                sx={{ marginRight: theme => theme.spacing(1) }}
                            >
                                <FontAwesomeIcon icon={faEdit} size="sm" />
                            </IconButton>

                            <IconButton
                                color="error"
                                title="Delete Service Location"
                                onClick={async () => handleDelete(location.serviceLocationId)}
                            >
                                <FontAwesomeIcon icon={faTrash} size="sm" />
                            </IconButton>
                        </Box>
                    );
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);

    return (
        <ServerSideDataGrid
            autoHeight
            getRowId={(row: IServiceLocation) => row.serviceLocationId!}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            columnHeaderHeight={36}
            {...gridProps}
        />
    );
};