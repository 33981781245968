import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, hyphenSeparateTwoInputs } from '../../helpers';
import { IBillingLog } from '../../models';
import { BillingLogActionButton } from './billing-log-kebab';

interface BillingLogDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IBillingLog[];
  refetch: () => Promise<void>;
  handleDelete: (transactionBatchId: string) => Promise<void>;
  handleDownload: (transactionBatchId: string, reference: string) => Promise<void>;
}

export const BillingLogDataGrid: FC<BillingLogDataGridProps> = ({
  rows,
  refetch,
  handleDelete,
  handleDownload,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: 'whenCreated',
        headerName: 'Created',
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IBillingLog>) => {
          const { row: logItem } = params;
          return logItem.whenCreated ? formatDate(logItem.whenCreated) : '';
        },
      },
      {
        field: 'businessDate',
        headerName: 'Business Date',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IBillingLog>) => {
          const { row: logItem } = params;
          return logItem.businessDate ? formatDate(logItem.businessDate) : '';
        },
      },
      {
        field: 'userName',
        headerName: 'Posted By',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'reference',
        headerName: 'Reference',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'billingGroupDescription',
        headerName: 'Billing Group',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 100,
        renderCell: (params: GridRenderCellParams<IBillingLog>) => {
          const { row: logItem } = params;
          return (
            <BillingLogActionButton
              billingLog={logItem}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <ServerSideDataGrid
      isKeysetTable
      autoHeight
      getRowId={(row: IBillingLog) => row.transactionBatchId!}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (params: IBillingLog) => {
          let date = formatDate(params?.whenCreated);
          let ref = params?.reference;
          if (params?.whenCreated && params?.reference) {
            return hyphenSeparateTwoInputs(date as string, ref);
          }
          return formatDate(date);
        },
      }}
      {...gridProps}
    />
  );
};
