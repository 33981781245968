import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { BrandingContext } from '../../../context/branding-context';

export const TermsOfUse = () => {
  const classes = useStyles();
  const { appLongName, LEGAL_NAME, SUPPORT_EMAIL } = useContext(BrandingContext);
  return (
    <>
      <Box className={classes.bodyWrapper}>
        <h2>{LEGAL_NAME} Terms of Use Agreement</h2>
        <p>
          BY CLICKING THE "I ACCEPT" BUTTON DISPLAYED AS PART OF THE ORDERING PROCESS, YOU AGREE TO
          THE FOLLOWING TERMS AND CONDITIONS (THE "AGREEMENT") GOVERNING YOUR USE OF Pinch A Penny
          Inc.'S ONLINE SERVICE, INCLUDING OFFLINE COMPONENTS (COLLECTIVELY, THE "SERVICE"). IF YOU
          ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
          REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THESE TERMS AND CONDITIONS,
          IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE
          SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST SELECT
          THE "I DECLINE" BUTTON AND MAY NOT USE THE SERVICE.
        </p>
        <h2>Welcome</h2>
        <p>
          As part of the Service, {LEGAL_NAME} will provide you with use of the Service, including a
          browser interface and data encryption, transmission, access and storage. Your registration
          for, or use of, the Service shall be deemed to be your agreement to abide by this
          Agreement including any materials available on the website incorporated by reference
          herein, including but not limited to {LEGAL_NAME} privacy and security policies. For
          reference, a Definitions section is included at the end of this Agreement.
        </p>
        <p>We offer a 30-day free trial of our Service with no further obligation.</p>
        <h2>1. Privacy &amp; Security; Disclosure</h2>
        <p>
          {LEGAL_NAME}'s privacy and security policies may be viewed at and. {LEGAL_NAME}
          reserves the right to modify its privacy and security policies in its reasonable
          discretion from time to time. Individual users, when they initially log in, will be asked
          whether or not they wish to receive marketing and other non-critical Service-related
          communications from {LEGAL_NAME} from time to time. They may opt out of receiving such
          communications at that time or at any subsequent time by changing their preference under
          Personal Setup. Note that because the Service is a hosted, online application, Pinch A
          Penny Inc. occasionally may need to notify all users of the Service (whether or not they
          have opted out as described above) of important announcements regarding the operation of
          the Service. If you become a paying customer of the Service, you agree that Pinch A Penny
          Inc. can disclose the fact that you are a paying customer.
        </p>
        <h2>2. License Grant &amp; Restrictions</h2>
        <p>
          {LEGAL_NAME} hereby grants you a non-exclusive, non-transferable right to use the Service,
          solely for your own internal business purposes, subject to the terms and conditions of
          this Agreement. All rights not expressly granted to you are reserved by
          {LEGAL_NAME} and its licensors.
        </p>
        <p>
          You may not access the Service if you are a direct competitor of {LEGAL_NAME}, except with{' '}
          {LEGAL_NAME}'s prior written consent. In addition, you may not access the Service for
          purposes of monitoring its availability, performance or functionality, or for any other
          benchmarking or competitive purposes.
        </p>
        <p>
          You shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or
          otherwise commercially exploit or make available to any third party the Service or the
          Content in any way; (ii) modify or make derivative works based upon the Service or the
          Content; (iii) reverse engineer or access the Service in order to (a) build a competitive
          product or service, (b) build a product using similar ideas, features, functions or
          graphics of the Service, or (c) copy any ideas, features, functions or graphics of the
          Service. User licenses cannot be shared or used by more than one individual User but may
          be reassigned from time to time to new Users who are replacing former Users who have
          terminated employment or otherwise changed job status or function and no longer use the
          Service.
        </p>
        <p>
          You may use the Service only for your internal business purposes and shall not: (i) send
          spam or otherwise duplicative or unsolicited messages in violation of applicable laws;
          (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or
          tortious material, including material harmful to children or violative of third party
          privacy rights; (iii) send or store material containing software viruses, worms, Trojan
          horses or other harmful computer code, files, scripts, agents or programs; (iv) interfere
          with or disrupt the integrity or performance of the Service or the data contained therein;
          or (v) attempt to gain unauthorized access to the Service or its related systems or
          networks.
        </p>
        <h2>3. Your Responsibilities</h2>
        <p>
          You are responsible for all activity occurring under your User accounts and shall abide by
          all applicable local, state, national and foreign laws, treaties and regulations in
          connection with your use of the Service, including those related to data privacy,
          international communications and the transmission of technical or personal data. You
          shall: (i) notify {LEGAL_NAME} immediately of any unauthorized use of any password or
          account or any other known or suspected breach of security; (ii) report to Pinch A Penny
          Inc. immediately and use reasonable efforts to stop immediately any copying or
          distribution of Content that is known or suspected by you or your Users; and (iii) not
          impersonate another {appLongName} user or provide false identity information to gain
          access to or use the Service.
        </p>
        <h2>4. Account Information and Data</h2>
        <p>
          {LEGAL_NAME} does not own any data, information or material that you submit to the Service
          in the course of using the Service ("Customer Data"). You, not {LEGAL_NAME}, shall have
          sole responsibility for the accuracy, quality, integrity, legality, reliability,
          appropriateness, and intellectual property ownership or right to use of all Customer Data,
          and {LEGAL_NAME} shall not be responsible or liable for the deletion, correction,
          destruction, damage, loss or failure to store any Customer Data. In the event this
          Agreement is terminated (other than by reason of your breach), {LEGAL_NAME}
          will make available to you a file of the Customer Data within 30 days of termination if
          you so request at the time of termination. {LEGAL_NAME} reserves the right to withhold,
          remove and/or discard Customer Data without notice for any breach, including, without
          limitation, your non-payment. Upon termination for cause, your right to access or use
          Customer Data immediately ceases, and {LEGAL_NAME} shall have no obligation to maintain or
          forward any Customer Data.
        </p>
        <h2>5. Intellectual Property Ownership</h2>
        <p>
          {LEGAL_NAME} alone (and its licensors, where applicable) shall own all right, title and
          interest, including all related Intellectual Property Rights, in and to the Pinch A Penny
          Inc. Technology, the Content and the Service and any suggestions, ideas, enhancement
          requests, feedback, recommendations or other information provided by you or any other
          party relating to the Service. This Agreement is not a sale and does not convey to you any
          rights of ownership in or related to the Service, the {LEGAL_NAME} Technology or the
          Intellectual Property Rights owned by {LEGAL_NAME}. The {appLongName} name, the{' '}
          {appLongName}
          logo, and the product names associated with the Service are trademarks of Pinch A Penny
          Inc. or third parties, and no right or license is granted to use them.
        </p>
        <h2>6. Third Party Interactions</h2>
        <p>
          During use of the Service, you may enter into correspondence with, purchase goods and/or
          services from, or participate in promotions of advertisers or sponsors showing their goods
          and/or services through the Service. Any such activity, and any terms, conditions,
          warranties or representations associated with such activity, is solely between you and the
          applicable third-party. {LEGAL_NAME} and its licensors shall have no liability, obligation
          or responsibility for any such correspondence, purchase or promotion between you and any
          such third-party. {LEGAL_NAME} does not endorse any sites on the Internet that are linked
          through the Service. {LEGAL_NAME} provides these links to you only as a matter of
          convenience, and in no event shall {LEGAL_NAME} or its licensors be responsible for any
          content, products, or other materials on or available from such sites. {LEGAL_NAME}{' '}
          provides the Service to you pursuant to the terms and conditions of this Agreement. You
          recognize, however, that certain third-party providers of ancillary software, hardware or
          services may require your agreement to additional or different license or other terms
          prior to your use of or access to such software, hardware or services.
        </p>
        <h2>7. Charges and Payment of Fees</h2>
        <p>
          You shall pay all fees or charges to your account in accordance with the fees, charges,
          and billing terms' in effect at the time a fee or charge is due and payable. The initial
          charges will be equal to the current number of total User licenses requested times the
          User license fee currently in effect. Payments may be made annually, monthly, or
          quarterly, consistent with the Initial Term, or as otherwise mutually agreed upon. All
          payment obligations are noncancelable and all amounts paid are nonrefundable. You are
          responsible for paying for all User licenses ordered for the entire License Term, whether
          or not such User licenses are actively used. You must provide {LEGAL_NAME} with valid
          credit card or approved purchase order information as a condition to signing up for the
          Service. An authorized License Administrator may add licenses by executing an additional
          written Order Form or using the Online Order Center. Added licenses will be subject to the
          following: (i) added licenses will be coterminous with the preexisting License Term
          (either Initial Term or renewal term); (ii) the license fee for the added licenses will be
          the then current, generally applicable license fee; and (iii) licenses added in the middle
          of a billing month will be charged in full for that billing month.
          {LEGAL_NAME} reserves the right to modify its fees and charges and to introduce new
          charges at any time, upon at least 30 days prior notice to you, which notice may be
          provided by e-mail. All pricing terms are confidential, and you agree not to disclose them
          to any third party.
        </p>
        <h2>8. Excess Data Storage Fees</h2>
        <p>
          The maximum disk storage space provided to you at no additional charge is 1 GB. If the
          amount of disk storage required exceeds these limits, you will be charged the then-current
          storage fees. {LEGAL_NAME} will use reasonable efforts to notify you when the average
          storage used per license reaches approximately 90% of the maximum; however, any failure by{' '}
          {LEGAL_NAME} to so notify you shall not affect your responsibility for such additional
          storage charges. {LEGAL_NAME} reserves the right to establish or modify its general
          practices and limits relating to storage of Customer Data.
        </p>
        <h2>9. Billing and Renewal</h2>
        <p>
          {LEGAL_NAME} charges and collects in advance for use of the Service. {LEGAL_NAME}
          will automatically renew and bill your credit card or issue an invoice to you (a) every
          month for monthly licenses, (b) every quarter for quarterly licenses, (c) each year on the
          subsequent anniversary for annual licenses, or (d) as otherwise mutually agreed upon. The
          renewal charge will be equal to the then-current number of total User licenses times the
          license fee in effect during the prior term, unless {LEGAL_NAME} has given you at least 30
          days prior written notice of a fee increase, which shall be effective upon renewal and
          thereafter. Fees for other services will be charged on an as-quoted basis.
          {LEGAL_NAME}'s fees are exclusive of all taxes, levies, or duties imposed by taxing
          authorities, and you shall be responsible for payment of all such taxes, levies, or
          duties, excluding only United States (federal or state) taxes based solely on Pinch A
          Penny Inc.'s income.
        </p>
        <p>
          You agree to provide {LEGAL_NAME} with complete and accurate billing and contact
          information. This information includes your legal company name, street address, e-mail
          address, and name and telephone number of an authorized billing contact and License
          Administrator. You agree to update this information within 30 days of any change to it. If
          the contact information you have provided is false or fraudulent, {LEGAL_NAME}
          reserves the right to terminate your access to the Service in addition to any other legal
          remedies.
        </p>
        <p>All customers will be billed in U.S. dollars.</p>
        <p>
          If you believe your bill is incorrect, you must contact us in writing within 60 days of
          the invoice date of the invoice containing the amount in question to be eligible to
          receive an adjustment or credit.
        </p>
        <h2>10. Non-Payment and Suspension</h2>
        <p>
          In addition to any other rights granted to {LEGAL_NAME} herein, {LEGAL_NAME}
          reserves the right to suspend or terminate this Agreement and your access to the Service
          if your account becomes delinquent (falls into arrears). Delinquent invoices (accounts in
          arrears) are subject to interest of 1.0% per month on any outstanding balance, or the
          maximum permitted by law, whichever is less, plus all expenses of collection. You will
          continue to be charged for User licenses during any period of suspension. If you or Pinch
          A Penny Inc. initiates termination of this Agreement, you will be obligated to pay the
          balance due on your account computed in accordance with the Charges and Payment of Fees
          section above. You agree that {LEGAL_NAME} may charge such unpaid fees to your credit card
          or otherwise bill you for such unpaid fees.
        </p>
        <p>
          {LEGAL_NAME} reserves the right to impose a reconnection fee in the event you are
          suspended and thereafter request access to the Service. You agree and acknowledge that
          {LEGAL_NAME} has no obligation to retain Customer Data and that such Customer Data may be
          irretrievably deleted if your account is 30 days or more delinquent.
        </p>
        <h2>11. Termination upon Expiration/Reduction in Number of Licenses</h2>
        <p>
          This Agreement commences on the Effective Date. The term is one month, one quarter, or one
          year, depending on the plan made available by {LEGAL_NAME} and chosen by you. Upon the
          expiration of the Initial Term, this Agreement will automatically renew for successive
          renewal terms equal in duration to the Initial Term at {LEGAL_NAME}'s then current fees.
          Either party may terminate this Agreement or reduce the number of licenses, effective only
          upon the expiration of the then current License Term, by notifying the other party in
          writing at least five (5) business days prior to the date of the invoice for the following
          term. In the case of free trials, notifications provided through the Service indicating
          the remaining number of days in the free trial shall constitute notice of termination. In
          the event this Agreement is terminated (other than by reason of your breach), {LEGAL_NAME}{' '}
          will make available to you a file of the Customer Data within 30 days of termination if
          you so request at the time of termination. You agree and acknowledge that {LEGAL_NAME} has
          no obligation to retain the Customer Data, and may delete such Customer Data, more than 30
          days after termination.
        </p>
        <h2>12. Termination for Cause</h2>
        <p>
          Any breach of your payment obligations or unauthorized use of the {LEGAL_NAME}
          Technology or Service will be deemed a material breach of this Agreement. Pinch A Penny
          Inc., in its sole discretion, may terminate your password, account or use of the Service
          if you breach or otherwise fail to comply with this Agreement. In addition, Pinch A Penny
          Inc. may terminate a free account at any time in its sole discretion. You agree and
          acknowledge that {LEGAL_NAME} has no obligation to retain the Customer Data, and may
          delete such Customer Data, if you have materially breached this Agreement, including but
          not limited to failure to pay outstanding fees, and such breach has not been cured within
          30 days of notice of such breach.
        </p>
        <h2>13. Representations &amp; Warranties</h2>
        <p>
          Each party represents and warrants that it has the legal power and authority to enter into
          this Agreement. {LEGAL_NAME} represents and warrants that it will provide the Service in a
          manner consistent with general industry standards reasonably applicable to the provision
          thereof and that the Service will perform substantially in accordance with the online{' '}
          {LEGAL_NAME} help documentation under normal use and circumstances. You represent and
          warrant that you have not falsely identified yourself nor provided any false information
          to gain access to the Service and that your billing information is correct.
        </p>
        <h2>14. Mutual Indemnification</h2>
        <p>
          You shall indemnify and hold {LEGAL_NAME}, its licensors and each such party's parent
          organizations, subsidiaries, affiliates, officers, directors, employees, attorneys and
          agents harmless from and against any and all claims, costs, damages, losses, liabilities
          and expenses (including attorneys' fees and costs) arising out of or in connection with:
          (i) a claim alleging that use of the Customer Data infringes the rights of, or has caused
          harm to, a third party; (ii) a claim, which if true, would constitute a violation by you
          of your representations and warranties; or (iii) a claim arising from the breach by you or
          your Users of this Agreement, provided in any such case that {LEGAL_NAME} (a) gives
          written notice of the claim promptly to you; (b) gives you sole control of the defense and
          settlement of the claim (provided that you may not settle or defend any claim unless you
          unconditionally release {LEGAL_NAME} of all liability and such settlement does not affect{' '}
          {LEGAL_NAME}'s business or Service); (c) provides to you all available information and
          assistance; and (d) has not compromised or settled such claim.
        </p>
        <p>
          {LEGAL_NAME} shall indemnify and hold you and your parent organizations, subsidiaries,
          affiliates, officers, directors, employees, attorneys and agents harmless from and against
          any and all claims, costs, damages, losses, liabilities and expenses (including attorneys'
          fees and costs) arising out of or in connection with: (i) a claim alleging that the
          Service directly infringes a copyright, a U.S. patent issued as of the Effective Date, or
          a trademark of a third party; (ii) a claim, which if true, would constitute a violation by{' '}
          {LEGAL_NAME} of its representations or warranties; or (iii) a claim arising from breach of
          this Agreement by {LEGAL_NAME}; provided that you (a) promptly give written notice of the
          claim to {LEGAL_NAME}; (b) give {LEGAL_NAME} sole control of the defense and settlement of
          the claim (provided that {LEGAL_NAME} may not settle or defend any claim unless it
          unconditionally releases you of all liability); (c) provide to {LEGAL_NAME} all available
          information and assistance; and (d) have not compromised or settled such claim.{' '}
          {LEGAL_NAME} shall have no indemnification obligation, and you shall indemnify{' '}
          {LEGAL_NAME} pursuant to this Agreement, for claims arising from any infringement arising
          from the combination of the Service with any of your products, service, hardware or
          business process(s).
        </p>
        <h2>15. Disclaimer of Warranties</h2>
        <p>
          {LEGAL_NAME} AND ITS LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE
          RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR
          COMPLETENESS OF THE SERVICE OR ANY CONTENT. {LEGAL_NAME} AND ITS LICENSORS DO NOT
          REPRESENT OR WARRANT THAT (A) THE USE OF THE SERVICE WILL BE SECURE, TIMELY, UNINTERRUPTED
          OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA,
          (B) THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE
          ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
          MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR REQUIREMENTS OR
          EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F) THE SERVICE OR THE SERVER(S)
          THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE
          SERVICE AND ALL CONTENT IS PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS,
          REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO
          THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY {LEGAL_NAME} AND ITS LICENSORS.
        </p>
        <h2>16. Internet Delays</h2>
        <p>
          {LEGAL_NAME}'S SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT
          IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. {LEGAL_NAME} IS NOT RESPONSIBLE
          FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.
        </p>
        <h2>17. Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL EITHER PARTY'S AGGREGATE LIABILITY EXCEED THE AMOUNTS ACTUALLY PAID BY
          AND/OR DUE FROM YOU IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING
          RISE TO SUCH CLAIM. IN NO EVENT SHALL EITHER PARTY AND/OR ITS LICENSORS BE LIABLE TO
          ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER
          DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER
          ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS SERVICE, INCLUDING
          BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICE, OR FOR ANY CONTENT OBTAINED
          FROM OR THROUGH THE SERVICE, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS
          OF CAUSE IN THE CONTENT, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH
          PARTY'S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h2>18. Additional Rights</h2>
        <p>
          Certain states and/or jurisdictions do not allow the exclusion of implied warranties or
          limitation of liability for incidental, consequential or certain other types of damages,
          so the exclusions set forth above may not apply to you.
        </p>
        <h2>19. Local Laws and Export Control</h2>
        <p>
          This site provides services and uses software and technology that may be subject to United
          States export controls administered by the U.S. Department of Commerce, the United States
          Department of Treasury Office of Foreign Assets Control, and other U.S. agencies and the
          export control regulations of Switzerland and the European Union. The user of this site
          ("User") acknowledges and agrees that the site shall not be used, and none of the
          underlying information, software, or technology may be transferred or otherwise exported
          or re-exported to countries as to which the United States, Switzerland and/or the European
          Union maintains an embargo (collectively, "Embargoed Countries"), or to or by a national
          or resident thereof, or any person or entity on the U.S. Department of Treasury's List of
          Specially Designated Nationals or the U.S. Department of Commerce's Table of Denial Orders
          (collectively, "Designated Nationals"). The lists of Embargoed Countries and Designated
          Nationals are subject to change without notice. By using the Service, you represent and
          warrant that you are not located in, under the control of, or a national or resident of an
          Embargoed Country or Designated National. You agree to comply strictly with all U.S.,
          Swiss and European Union export laws and assume sole responsibility for obtaining licenses
          to export or re-export as may be required.
        </p>
        This site may use encryption technology that is subject to licensing requirements under the
        U.S. Export Administration Regulations, 15 C.F.R. Parts 730-774 and Council Regulation (EC)
        No. 1334/2000
        <p>
          {LEGAL_NAME} and its licensors make no representation that the Service is appropriate or
          available for use in other locations. If you use the Service from outside the United
          States of America, Switzerland and/or the European Union, you are solely responsible for
          compliance with all applicable laws, including without limitation export and import
          regulations of other countries. Any diversion of the Content contrary to United States,
          Swiss or European Union (including European Union Member States) law is prohibited. None
          of the Content, nor any information acquired through the use of the Service, is or will be
          used for nuclear activities, chemical or biological weapons, or missile projects, unless
          specifically authorized by the United States government or appropriate European body for
          such purposes.
        </p>
        <h2>20. Notice</h2>
        <p>
          {LEGAL_NAME} may give notice by means of a general notice on the Service, electronic mail
          to your e-mail address on record in {LEGAL_NAME}'s account information, or by written
          communication sent by first class mail or pre-paid post to your address on record in{' '}
          {LEGAL_NAME}'s account information. Such notice shall be deemed to have been given upon
          the expiration of 48 hours after mailing or posting (if sent by first class mail or
          pre-paid post) or 12 hours after sending (if sent by email). You may give notice to{' '}
          {LEGAL_NAME} (such notice shall be deemed given when received by {LEGAL_NAME}) at any time
          by any of the following: letter sent by confirmed facsimile to {LEGAL_NAME}
          at the following fax numbers (whichever is appropriate): (480) 626-2177; letter delivered
          by nationally recognized overnight delivery service or first class postage prepaid mail to{' '}
          {LEGAL_NAME} at the following address: {LEGAL_NAME}, 6385 150th Avenue North, Clearwater,
          FL 33760.
        </p>
        <h2>21. Modification to Terms</h2>
        <p>
          {LEGAL_NAME} reserves the right to modify the terms and conditions of this Agreement or
          its policies relating to the Service at any time, effective upon posting of an updated
          version of this Agreement on the Service. You are responsible for regularly reviewing this
          Agreement. Continued use of the Service after any such changes shall constitute your
          consent to such changes.
        </p>
        <h2>22. Assignment; Change in Control</h2>
        <p>
          This Agreement may not be assigned by you without the prior written approval of Pinch A
          Penny Inc. but may be assigned without your consent by {LEGAL_NAME} to (i) a parent or
          subsidiary, (ii) an acquirer of assets, or (iii) a successor by merger. Any purported
          assignment in violation of this section shall be void. Any actual or proposed change in
          control of you that results or would result in a direct competitor of {LEGAL_NAME}
          directly or indirectly owning or controlling 50% or more of you shall entitle Pinch A
          Penny Inc. to terminate this Agreement for cause immediately upon written notice.
        </p>
        <h2>23. General</h2>
        <p>
          This Agreement shall be governed by Arizona law and controlling United States federal law,
          without regard to the choice or conflicts of law provisions of any jurisdiction, and any
          disputes, actions, claims or causes of action arising out of or in connection with this
          Agreement or the Service shall be subject to the exclusive jurisdiction of the state and
          federal courts located in Phoenix, Arizona. No text or information set forth on any other
          purchase order, preprinted form or document (other than an Order Form, if applicable)
          shall add to or vary the terms and conditions of this Agreement. If any provision of this
          Agreement is held by a court of competent jurisdiction to be invalid or unenforceable,
          then such provision(s) shall be construed, as nearly as possible, to reflect the
          intentions of the invalid or unenforceable provision(s), with all other provisions
          remaining in full force and effect. No joint venture, partnership, employment, or agency
          relationship exists between you and {LEGAL_NAME} as a result of this agreement or use of
          the Service. The failure of {LEGAL_NAME} to enforce any right or provision in this
          Agreement shall not constitute a waiver of such right or provision unless acknowledged and
          agreed to by {LEGAL_NAME} in writing. This Agreement, together with any applicable Order
          Form, comprises the entire agreement between you and {LEGAL_NAME} and supersedes all prior
          or contemporaneous negotiations, discussions or agreements, whether written or oral,
          between the parties regarding the subject matter contained herein.
        </p>
        <h2>24. Definitions</h2>
        <p>
          As used in this Agreement and in any Order Forms now or hereafter associated herewith:
          "Agreement" means these online terms of use, any Order Forms, whether written or submitted
          online via the Online Order Center, and any materials available on the {appLongName}{' '}
          website specifically incorporated by reference herein, as such materials, including the
          terms of this Agreement, may be updated by {LEGAL_NAME} from time to time in its sole
          discretion; "Content" means the audio and visual information, documents, software,
          products and services contained or made available to you in the course of using the
          Service; "Customer Data" means any data, information or material provided or submitted by
          you to the Service in the course of using the Service; "Effective Date" means the earlier
          of either the date this Agreement is accepted by selecting the "I Accept" option presented
          on the screen after this Agreement is displayed or the date you begin using the Service;
          "Initial Term" means the initial period during which you are obligated to pay for the
          Service equal to the billing frequency selected by you during the subscription process
          (e.g., if the billing frequency is quarterly, the Initial Term is the first quarter);
          "Intellectual Property Rights" means unpatented inventions, patent applications, patents,
          design rights, copyrights, trademarks, service marks, trade names, domain name rights,
          mask work rights, know-how and other trade secret rights, and all other intellectual
          property rights, derivatives thereof, and forms of protection of a similar nature anywhere
          in the world; "License Administrator(s)" means those Users designated by you who are
          authorized to purchase licenses online using the Online Order Center or by executing
          written Order Forms and to create User accounts and otherwise administer your use of the
          Service; "License Term(s)" means the period(s) during which a specified number of Users
          are licensed to use the Service pursuant to the Order Form(s); "Order Form(s)" means the
          form evidencing the initial subscription for the Service and any subsequent order forms
          submitted online or in written form, specifying, among other things, the number of
          licenses and other services contracted for, the applicable fees, the billing period, and
          other charges as agreed to between the parties, each such Order Form to be incorporated
          into and to become a part of this Agreement (in the event of any conflict between the
          terms of this Agreement and the terms of any such Order Form, the terms of this Agreement
          shall prevail); "Online Order Center" means {LEGAL_NAME}'s online application that allows
          the License Administrator designated by you to, among other things, add additional Users
          to the Service; "{LEGAL_NAME}" means collectively {LEGAL_NAME}, inc., an Arizona
          corporation, having its principal place of business at 6385 150th Avenue North,
          Clearwater, FL 33760; "{LEGAL_NAME} Technology" means all of {LEGAL_NAME}'s proprietary
          technology (including software, hardware, products, processes, algorithms, user
          interfaces, know-how, techniques, designs and other tangible or intangible technical
          material or information) made available to you by {LEGAL_NAME} in providing the Service;
          "Service(s)" means the specific edition of {LEGAL_NAME}'s online service management,
          billing, data analysis, or other services identified during the ordering process,
          developed, operated, and maintained by {LEGAL_NAME}, accessible via
          dev-pcp.pinchapenny.com or another designated web site or IP address, or ancillary online
          or offline products and services provided to you by {LEGAL_NAME}, to which you are being
          granted access under this Agreement; "User(s)" means your employees, representatives,
          consultants, contractors or agents who are authorized to use the Service and have been
          supplied user identifications and passwords by you (or by {LEGAL_NAME}
          at your request).
        </p>
        <h2>Questions or Additional Information:</h2>
        <p>
          If you have questions regarding this Agreement or wish to obtain additional information,
          please send an e-mail to {SUPPORT_EMAIL} .
        </p>
      </Box>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    bodyWrapper: {
      paddingTop: theme.spacing(1),
      '& h2': {
        fontWeight: 'bold',
        fontSize: '1rem',
        marginBottom: theme.spacing(1),
      },
      '& p': {
        marginBottom: theme.spacing(1),
      },
    },
  };
});
