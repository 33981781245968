import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { CardTitle, Loader, Card, Tabs, ITab } from '../../../components';
import { EPoolEquipmentTab, IPoolEquipment } from '../../../models';
import { useQuery } from 'react-query';
import {
  StructureDetailsForm,
  StructureEquipmentForm,
  StructureDetailsDisplay,
  StructureEquipmentDisplay,
  SafetyChecklistForm,
  SafetyChecklistDisplay,
  LegacyPoolInformation,
  PoolEquipmentAlert,
} from '.';
import { getPoolEquipment } from '../../../fetch';

const cleanInitialValue = (
  value: string | undefined | null,
  isNumberOnly?: boolean,
  isUppercase?: boolean
) => {
  if (!value) return '';

  if (isNumberOnly) return value.replace(/[^0-9.]/g, '');

  const lowerCase = value.toLowerCase();
  if (lowerCase === 'select') return '';

  if (isUppercase) return value.toUpperCase();

  return value;
};

export const poolStructurePerField = {
  poolVolume: Yup.number().typeError('Number Only').nullable(),
  filterPressure: Yup.number().typeError('Number Only').nullable(),
  sanitizer: Yup.string().nullable(),
  surface: Yup.string().nullable(),
};

export const generalInfoSectionFields: { name: string; type: 'string' | 'boolean' | 'number' }[] = [
  { name: 'size', type: 'string' },
  { name: 'depth', type: 'string' },
  { name: 'tile', type: 'string' },
  { name: 'skimBasket', type: 'string' },
  { name: 'coping', type: 'string' },
  { name: 'skimCover', type: 'string' },
  { name: 'mastic', type: 'string' },
  { name: 'coverType', type: 'string' },
];

export const conditionNotesSectionFields: {
  name: string;
  type: 'string' | 'boolean' | 'number';
}[] = [
  { name: 'condition', type: 'string' },
  { name: 'notes', type: 'string' },
];

export const spaInfoSectionFields: {
  name: string;
  type: 'string' | 'boolean' | 'number';
}[] = [
  { name: 'hasSpaBoosterPump', type: 'boolean' },
  { name: 'spaVolume', type: 'string' },
  { name: 'spaBoosterStyle', type: 'string' },
  { name: 'spaBrand', type: 'string' },
  { name: 'spaBoosterHp', type: 'string' },
  { name: 'spaModel', type: 'string' },
  { name: 'spaSerialNumber', type: 'string' },
  { name: 'spaDateOfPurchase', type: 'string' },
];

export const pumpFilterCleanerSectionFields: {
  name: string;
  type: 'string' | 'boolean' | 'number';
}[] = [
  { name: 'pumpBasket', type: 'string' },
  { name: 'airRelief', type: 'string' },
  { name: 'poolMotor', type: 'string' },
  { name: 'cleanerStyle', type: 'string' },
  { name: 'poolHp', type: 'string' },
  { name: 'cleanerPump', type: 'string' },
  { name: 'hasConduit', type: 'boolean' },
  { name: 'cleanerClock', type: 'string' },
  { name: 'conduitCondition', type: 'string' },
  { name: 'autoCleaner', type: 'boolean' },
  { name: 'timeClock', type: 'string' },
  { name: 'cleanerVolts', type: 'string' },
  { name: 'timeClockVolts', type: 'string' },
];

export const pipingInfoSectionFields: {
  name: string;
  type: 'string' | 'boolean' | 'number';
}[] = [
  { name: 'gauge', type: 'string' },
  { name: 'valveSize', type: 'string' },
  { name: 'mainDrain', type: 'string' },
  { name: 'controlValve', type: 'string' },
];

interface IPoolStructureCard {
  globalFormik?: {
    values: any;
    handleChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (name: string, val: any) => void;
  };
  readOnly?: boolean;
  isServiceDetailPage?: boolean;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  siteId: string;
  shouldPoolRefresh?: boolean;
  shouldFetch: boolean;
  isCardLayout?: boolean;
  triggerFormValidation?: boolean;
}

export const PoolStructureCard: FC<IPoolStructureCard> = ({
  globalFormik,
  readOnly,
  shouldPoolRefresh,
  isServiceDetailPage,
  isExpanded,
  isCollapsible = false,
  initialExpand = false,
  siteId,
  shouldFetch,
  isCardLayout = true,
  triggerFormValidation,
}) => {
  const [selectedTab, setSelectedTab] = useState<EPoolEquipmentTab>(
    EPoolEquipmentTab.StructureDetails
  );
  const { isLoading: isLoadingPoolEquipment, data: poolEquipment } = useQuery<
    IPoolEquipment,
    Error
  >(['getPoolEquipment', shouldPoolRefresh], () => getPoolEquipment(siteId), {
    notifyOnChangeProps: 'tracked',
    enabled: shouldFetch && !!siteId,
  });

  const renderChildren = (
    <>
      {isLoadingPoolEquipment && <Loader type="overlay" position="centered" />}
      <Box mt={isServiceDetailPage || isCollapsible ? -1.75 : 0}>
        <Formik
          initialValues={{
            // Structure Details
            appearance: poolEquipment?.appearance
              ? cleanInitialValue(poolEquipment?.appearance, undefined, true)
              : 'NONE',
            surface: cleanInitialValue(poolEquipment?.surface, undefined, true),
            poolVolume: cleanInitialValue(poolEquipment?.poolVolume, true),
            sanitizer: cleanInitialValue(poolEquipment?.sanitizer, undefined, true),
            hasSpa: poolEquipment?.hasSpa,
            saltChlorinator: poolEquipment?.saltChlorinator
              ? cleanInitialValue(poolEquipment?.saltChlorinator, undefined, true)
              : 'NONE',
            algaeType: poolEquipment?.algaeType ?? 'None',
            stains: poolEquipment?.stains ?? '',
            poolFinish: poolEquipment?.poolFinish ?? '',
            poolType: poolEquipment?.poolType ?? 'InGround',
            lastResurfaced: !!poolEquipment?.lastResurfaced
              ? new Date(poolEquipment?.lastResurfaced, 0, 1)
              : '',
            poolBuilt: !!poolEquipment?.poolBuilt ? new Date(poolEquipment?.poolBuilt, 0, 1) : '',

            // Structure Equipment
            filter: poolEquipment?.filter ? cleanInitialValue(poolEquipment?.filter) : '',
            poolPump: cleanInitialValue(poolEquipment?.poolPump),
            heater: poolEquipment?.heater ? cleanInitialValue(poolEquipment?.heater) : '',
            automaticControls: poolEquipment?.automaticControls ?? '',
            backwash: poolEquipment?.backwash ?? '',
            automaticChlorinator: poolEquipment?.automaticChlorinator
              ? cleanInitialValue(poolEquipment?.automaticChlorinator)
              : '',
            filterPressure: poolEquipment?.filterPressure ?? '',
            timerBrandModel: poolEquipment?.timerBrandModel ?? '',
            otherEquipment: poolEquipment?.otherEquipment ?? '',
            waterFeature: poolEquipment?.waterFeature ?? '',
            piping: poolEquipment?.piping ?? '',

            // Safety Checklist
            dualMainDrain: poolEquipment?.dualMainDrain ?? '',
            drainCover: poolEquipment?.drainCover ?? '',
            suctionSafetyLock: poolEquipment?.suctionSafetyLock ?? '',
            secondaryBarrier: poolEquipment?.secondaryBarrier ?? '',
            safetyVacuumRelease: poolEquipment?.safetyVacuumRelease ?? '',
            boards: poolEquipment?.boards ?? '',
            additionalRecommendations: poolEquipment?.additionalRecommendations ?? '',

            // Legacy Pool Information - General Info
            size: poolEquipment?.size ?? '',
            depth: poolEquipment?.depth ?? '',
            tile: poolEquipment?.tile ?? '',
            skimBasket: poolEquipment?.skimBasket ?? '',
            coping: poolEquipment?.coping ?? '',
            skimCover: poolEquipment?.skimCover ?? '',
            mastic: poolEquipment?.mastic ?? '',
            coverType: poolEquipment?.coverType ?? '',
            // Legacy Pool Information - Condition and Notes
            condition: poolEquipment?.condition ?? '',
            notes: poolEquipment?.notes ?? '',
            // Legacy Pool Information - Spa Info
            hasSpaBoosterPump: poolEquipment?.hasSpaBoosterPump
              ? poolEquipment.hasSpaBoosterPump
              : false,
            spaVolume: poolEquipment?.spaVolume ?? '',
            spaBoosterStyle: poolEquipment?.spaBoosterStyle ?? '',
            spaBrand: poolEquipment?.spaBrand ?? '',
            spaBoosterHp: poolEquipment?.spaBoosterHp ?? '',
            spaModel: poolEquipment?.spaModel ?? '',
            spaSerialNumber: poolEquipment?.spaSerialNumber ?? '',
            spaDateOfPurchase: poolEquipment?.spaDateOfPurchase ?? '',
            // Legacy Pool Information - Pump / Filter / Cleaner
            pumpBasket: poolEquipment?.pumpBasket ?? '',
            airRelief: poolEquipment?.airRelief ?? '',
            poolMotor: poolEquipment?.poolMotor ?? '',
            cleanerStyle: poolEquipment?.cleanerStyle ?? '',
            poolHp: poolEquipment?.poolHp ?? '',
            cleanerPump: poolEquipment?.cleanerPump ?? '',
            hasConduit: poolEquipment?.hasConduit ? poolEquipment.hasConduit : false,
            cleanerClock: poolEquipment?.cleanerClock ?? '',
            conduitCondition: poolEquipment?.conduitCondition ?? '',
            autoCleaner: poolEquipment?.autoCleaner ? poolEquipment.autoCleaner : false,
            timeClock: poolEquipment?.timeClock ?? '',
            cleanerVolts: poolEquipment?.cleanerVolts ?? '',
            timeClockVolts: poolEquipment?.timeClockVolts ?? '',
            // Legacy Pool Information - Piping Info
            gauge: poolEquipment?.gauge ?? '',
            valveSize: poolEquipment?.valveSize ?? '',
            mainDrain: poolEquipment?.mainDrain ?? '',
            controlValve: poolEquipment?.controlValve ?? '',
          }}
          enableReinitialize={true}
          validateOnMount={true}
          validationSchema={Yup.object().shape(poolStructurePerField)}
          onSubmit={async values => {
            //do nothing, will be handled by external save button
          }}
        >
          {({ isSubmitting, values, setFieldValue, setFieldTouched }) => {
            const handleFormChange = async (val?: any) => {
              if (globalFormik) {
                // Set global formik context values
                globalFormik?.setFieldValue('poolStructure', val ?? values);
              }
            };
            const clearSectionFields = async (
              fields: { name: string; type: 'string' | 'boolean' | 'number' }[]
            ) => {
              let copy = { ...values };
              fields.forEach(field => {
                const newValue = () => {
                  if (field.type === 'number') {
                    return 0;
                  }
                  if (field.type === 'boolean') {
                    return false;
                  }
                  return '';
                };
                setFieldValue(field.name, newValue(), false); // Reset Section Field Value
                setFieldTouched(field.name, true, true); // Ensure Field Validation Updates
                copy = { ...copy, [field.name]: newValue() };
              });
              await handleFormChange(copy); // Ensure Global Form is Updated
            };
            const checkSectionFields = (
              fields: { name: string; type: 'string' | 'boolean' | 'number' }[]
            ) => {
              let hasValues = false;
              fields.forEach(field => {
                const vals = values as any;
                if (field.type === 'number' && Number(vals[field.name]) !== 0) {
                  hasValues = true;
                }
                if (field.type === 'boolean' && !!vals[field.name]) {
                  hasValues = true;
                }
                if (field.type === 'string' && !!vals[field.name]) {
                  hasValues = true;
                }
              });
              return hasValues;
            };

            const renderDisplayTabs = () => {
              return (
                <>
                  {selectedTab === EPoolEquipmentTab.StructureDetails && (
                    <StructureDetailsDisplay />
                  )}
                  {selectedTab === EPoolEquipmentTab.StructureEquipment && (
                    <StructureEquipmentDisplay />
                  )}
                  {selectedTab === EPoolEquipmentTab.SafetyChecklist && !isServiceDetailPage && (
                    <SafetyChecklistDisplay />
                  )}
                  {selectedTab === EPoolEquipmentTab.LegacyPoolInfo && !isServiceDetailPage && (
                    <LegacyPoolInformation
                      handleFormChange={handleFormChange}
                      clearSectionFields={clearSectionFields}
                      checkSectionFields={checkSectionFields}
                      readOnly={true}
                      showClearButtons={!isServiceDetailPage}
                    />
                  )}
                </>
              );
            };
            const renderFormTabs = () => {
              return (
                <>
                  {selectedTab === EPoolEquipmentTab.StructureDetails && (
                    <StructureDetailsForm
                      handleFormChange={data => handleFormChange(data)}
                      triggerFormValidation={triggerFormValidation}
                    />
                  )}
                  {selectedTab === EPoolEquipmentTab.StructureEquipment && (
                    <StructureEquipmentForm handleFormChange={data => handleFormChange(data)} />
                  )}
                  {selectedTab === EPoolEquipmentTab.SafetyChecklist && !isServiceDetailPage && (
                    <SafetyChecklistForm handleFormChange={data => handleFormChange(data)} />
                  )}
                  {selectedTab === EPoolEquipmentTab.LegacyPoolInfo && !isServiceDetailPage && (
                    <LegacyPoolInformation
                      handleFormChange={data => handleFormChange(data)}
                      readOnly={true}
                      clearSectionFields={clearSectionFields}
                      checkSectionFields={checkSectionFields}
                      showClearButtons={!isServiceDetailPage}
                    />
                  )}
                </>
              );
            };
            const renderTabstrip = () => {
              return (
                <Tabs
                  id="pool-equipment-tabs"
                  selectedTab={selectedTab}
                  setSelectedTab={async val => {
                    setSelectedTab(val as EPoolEquipmentTab);
                  }}
                  tabs={
                    [
                      {
                        key: EPoolEquipmentTab.StructureDetails,
                        title: 'Structure Information',
                      },
                      {
                        key: EPoolEquipmentTab.StructureEquipment,
                        title: 'Structure Equipment',
                      },
                      !isServiceDetailPage && {
                        key: EPoolEquipmentTab.SafetyChecklist,
                        title: 'Safety Checklist',
                      },
                      !isServiceDetailPage &&
                        poolEquipment?.showLegacyInfo && {
                          key: EPoolEquipmentTab.LegacyPoolInfo,
                          title: 'Legacy Pool Information',
                        },
                    ].filter(Boolean) as ITab[]
                  }
                />
              );
            };
            if (isSubmitting) {
              return (
                <>
                  {renderTabstrip()}
                  <Card>
                    <Box my={5}>
                      <Loader position="centered" title="Saving" />
                    </Box>
                  </Card>
                </>
              );
            }
            return (
              <>
                {renderTabstrip()}
                <PoolEquipmentAlert
                  isVisible={!values.poolVolume || !values.sanitizer || !values.surface}
                />
                {readOnly ? renderDisplayTabs() : <Form>{renderFormTabs()}</Form>}
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
  if (!isCardLayout) {
    return renderChildren;
  }
  return (
    <Card>
      <CardTitle
        title="Pool Structure"
        withExpand={isServiceDetailPage || isCollapsible}
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
      >
        {renderChildren}
      </CardTitle>
    </Card>
  );
};
