import { axios } from '../helpers';
import qs from 'querystring';
import {
  ISite,
  IResponse,
  IErrorResponse,
  ISiteGetResponse,
  ISiteDefinitionValue,
  IEquipmentLookup,
  EquipmentType,
  IPoolEquipment,
  IPoolEquipmentShape,
  IPoolEquipmentFinish,
  IPoolEquipmentSanitizer,
  IDropdownResponse,
  ISiteSimple,
} from '../models';
import { IParentEquipment, ISiteEquipment } from '../models/equipment';
import { ILookupModel } from '../models/util';
import { userSignal } from '../signals';

export const getSites = async (filters?: {
  accountId?: string;
  addressId?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  siteName?: string;
  description?: string;
  officeId?: string | null;
}): Promise<IResponse<ISite[]>> => {
  try {
    const { data } = await axios.get(`/api/sites`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSitesSimple = async (filters?: {
  accountId?: string;
  addressId?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  siteName?: string;
  description?: string;
  officeId?: string | null;
}): Promise<IResponse<ISiteSimple[]>> => {
  try {
    const { data } = await axios.get(`/api/sites/simple`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSite = async (
  siteId: string,
  officeId?: string | null
): Promise<ISiteGetResponse> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSite = async (
  data: ISiteGetResponse,
  accountId: string,
  poolEquipment: IPoolEquipment,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = {
    accountId,
    description: data.siteDescription,
    mgrAccountId: data.mgrAccountId,
    chargeForChemicals: data.chargeForChemicals,
    userId: data.userId, // Site Supervisor ID
    useAccountAddress: data.useAccountAddress,
    addressModel: {
      addressType: 'Site',
      addressName: data.addressName,
      street: data.street,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      longitude: data.longitude,
      latitude: data.latitude,
    },
    additionalSiteInformation:
      data?.additionalSiteInformation?.flatMap((field, index) => {
        if (field.value) {
          return {
            userDefinedSiteDefId: field.userDefinedSiteDefId,
            value: field.value,
          };
        }
        return [];
      }) ?? [],
    ...poolEquipment,
  };
  try {
    const { data } = await axios.post(`/api/sites`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateSite = async (
  data: ISiteGetResponse,
  accountId: string,
  siteId: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const payload = {
    accountId,
    description: data.siteDescription,
    mgrAccountId: data.mgrAccountId,
    chargeForChemicals: data.chargeForChemicals,
    userId: data.userId,
    useAccountAddress: data.useAccountAddress,
    addressModel: {
      addressType: 'Site',
      addressName: data.addressName,
      street: data.street,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      longitude: data.longitude,
      latitude: data.latitude,
      isVerified: data.isVerified,
    },
    additionalSiteInformation:
      data?.additionalSiteInformation?.flatMap((field, index) => {
        if (field.value) {
          return {
            userDefinedSiteDefInfoId: field.userDefinedSiteDefInfoId,
            userDefinedSiteDefId: field.userDefinedSiteDefId,
            value: field.value,
          };
        }
        return [];
      }) ?? [],
  };
  try {
    const { data } = await axios.put(`/api/sites/${siteId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteSite = async (siteId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/sites/${siteId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAllSiteDefs = async ({
  ...otherParams
}: {
  sortBy: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<ISiteDefinitionValue[]>> => {
  try {
    const { data } = await axios.get(`/api/user-defined-site-defs`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSingleSiteDef = async (
  id: string | number,
  officeId?: string | null
): Promise<ISiteDefinitionValue> => {
  try {
    const { data } = await axios.get(`/api/user-defined-site-defs/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSiteDef = async (
  body: ISiteDefinitionValue,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/user-defined-site-defs`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateSiteDef = async (
  body: ISiteDefinitionValue,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/user-defined-site-defs/${body.userDefinedSiteDefId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteSiteDef = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/user-defined-site-defs/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSiteEquipment = async (
  siteId: string | number,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  },
  officeId?: string | null
): Promise<IResponse<ISiteEquipment[]>> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}/equipment`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSiteParentEquipment = async (
  siteId: string | number,
  officeId?: string | null
): Promise<IParentEquipment[]> => {
  try {
    const { data } = await axios.get(`/api/sites/${siteId}/parent-equipment`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipmentByType = async (
  equipmentType: EquipmentType,
  officeId?: string | null
): Promise<IEquipmentLookup[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/${equipmentType}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCoverTypes = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/cover-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolTypes = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/pool-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipment = async (
  siteId: string,
  officeId?: string | null
): Promise<IPoolEquipment> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/${siteId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePoolEquipment = async (
  payload: IPoolEquipment,
  siteId: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/pool-equipment/${siteId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createPoolEquipment = async (
  payload: IPoolEquipment,
  siteId: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/pool-equipment/${siteId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getPoolEquipmentShapes = async (
  officeId?: string | null
): Promise<IPoolEquipmentShape[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/shapes`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipmentFinishes = async (
  structureType: string,
  officeId?: string | null,
): Promise<IPoolEquipmentFinish[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/finishes`, {
      params: {
        structureType: structureType ?? 'POOL',
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipmentSanitizers = async (
  officeId?: string | null
): Promise<IPoolEquipmentSanitizer[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/sanitizers`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipmentSaltChlorinators = async (
  officeId?: string | null
): Promise<string[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/salt-chlorinators`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPoolEquipmentAlgaeTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/pool-equipment/algae-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
