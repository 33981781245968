import { differenceInDays, differenceInMinutes, format, parseISO } from 'date-fns';

// Calling val.toFixed(2) drops decimals down and creates a string
// representation of the number. If we then parse out the string into a float
// and call .toString() on that we can remove any 0s padded on at the end. I.e.
// '16.00` becomes '16'. Take that final string value and parse it back to a
// float and return.
const trimDecimals = (val: number, decimals = 2): number =>
  parseFloat(parseFloat(val.toFixed(decimals)).toString());

// format percent and ensure at least 1 decimal place even if it is .0%
export const formatPercent = (val: number): string => {
  let percent = `${trimDecimals(parseFloat((val * 100).toString()))}`;
  if (percent.indexOf('.') === -1) {
    return `${percent}.0%`;
  }
  return `${percent}%`;
};
export const convertPercentToNumber = (val: number) => {
  return val / 100;
};
export const formatMoney = (value: number | string | undefined | null, digits = 2): string => {
  let amount = 0;

  if (value) {
    if (typeof value === 'string') {
      // strip out any commas or dollar signs so that $9,999.99 passes !isNaN test
      value =
        value.includes(',') || value.includes('$')
          ? value.replace(',', '').replace('$', '')
          : value;
      // make sure the string is a number
      if (!isNaN(value as unknown as number)) {
        amount = Number(value);
      }
    } else if (typeof value === 'number') {
      amount = value;
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

/**
 * Format date string to Date
 * @example "2020-06-09T00:00:00+00:00" => Tue Jun 09 2020 00:00:00 GMT-0400
 * @param date string
 * @returns Date
 */
const stringToDate = (date: string): Date => {
  const d = date.split('T')[0].split('-');
  // Months are 0 index so subtract 1
  return new Date(+d[0], +d[1] - 1, +d[2]);
};

/**
 * Format date
 * @example 1/6/2020
 * @example 12/12/2020
 * @param date Date | number | string
 * @returns string
 */
export const formatDate = (date: Date | number | string | null | undefined): string | null => {
  if (date) {
    const stringDate: Date | number = typeof date === 'string' ? stringToDate(date) : date;
    try {
      // try and format the passed in date
      return format(stringDate, 'L/d/yyyy');
    } catch (error) {
      // if we get any error just return the string date as is
      return typeof date === 'string' ? date : '';
    }
  }
  return null;
};

export const DECIMAL_REGEX = /^\d*(\.)?(\d{0,4})?$/;

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDateWithTime = (date: Date | number | string): string | null => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yyyy h:mma');
  }
  return null;
};

/**
 * Formats a date into a short friendly date string.
 * @example 1/6/2020
 * @example 12/12/2020
 * @param {Date | number | string} date
 * @returns {string | null}
 */
export const formatShortFriendlyDate = (date: Date | number | string): string | null => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yyyy');
  }
  return null;
};

/**
 * Format time
 * @example 2:00pm
 * @example 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatTime = (date: Date | number | string | undefined): string | null => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'h:mma');
  }
  return null;
};

export const passwordRegex = new RegExp(
  /^(?=.*[A-Z])(?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])\S{12,60}$/
);

export const zipCodeRegExp = /(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/;
export const phoneRegExp = /1?\W*([0-9][0-9][0-9])\W*([0-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
export const formatInputPhoneNumber = (val: string | undefined) => {
  let number = val?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // taken from here, https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  return (
    number &&
    (!number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`)
  );
};

export const pascalCaseToString = (value: string) => {
  return value.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
};

export const unCamelCase = (str: string) => {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};
// Generate formatted address markup for use as a single line
// We want conditional commas and spaces based on values available
// i.e. Street, City, State ZIP
export const formatAddressSingleLine = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null
) => {
  const streetSeparator = !!city || !!state || !!postalCode ? `, ` : '';
  const formattedStreet = !!street ? `${street}${streetSeparator}` : '';
  const citySeparator = !!state || !!postalCode ? `, ` : '';
  const formattedCity = !!city ? `${city}${citySeparator}` : '';
  const formattedState = !!state ? `${state}${!!postalCode ? ' ' : ''}` : '';
  const formattedPostalCode = !!postalCode ? postalCode : '';

  return `${formattedStreet}${formattedCity}${formattedState}${formattedPostalCode}`;
};

// Verify date manually entered is valid
export const isValidDate = (date: Date | null | undefined) => {
  return !!date && `${new Date(date)?.getFullYear()}`?.length === 4;
};

export const isInvalidDateRange = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  checkTime?: boolean
) => {
  let invalidRange = false;
  if (startDate && endDate) {
    const dayDiff = differenceInDays(endDate, startDate);
    const timeDiff = checkTime ? differenceInMinutes(endDate, startDate) : 0;
    invalidRange = dayDiff > 0 || (dayDiff === 0 && timeDiff > 0);
  }
  return invalidRange;
};

export const formatAddressBlock = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null
) => {
  const formattedStreet = !!street ? (
    <>
      {street}
      <br />
    </>
  ) : (
    ''
  );
  const citySeparator = !!state || !!postalCode ? `, ` : '';
  const formattedCity = !!city ? `${city}${citySeparator}` : '';
  const formattedState = !!state ? `${state} ` : '';
  const formattedPostalCode = !!postalCode ? postalCode : '';

  return (
    <>
      {formattedStreet}
      {formattedCity}
      {formattedState}
      {formattedPostalCode}
    </>
  );
};
