import { FC } from 'react';
import { Page } from '../../components';
import { EstimateListPageDetails } from './estimates-list-details';

export const EstimateListPage: FC = () => {

  return (
    <Page title="Estimates">
      <EstimateListPageDetails />
    </Page>
  );
};