import { GridRenderCellParams } from '@mui/x-data-grid';
import { getWaterTestAccountId } from '../../fetch';
import { FC, useEffect, useState } from 'react';
import { ISearchWaterTestAccountResult } from '../../models';
import { CircleProgress } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';

interface ICustomerWaterTestCustomerColumn {
  params: GridRenderCellParams<ISearchWaterTestAccountResult>;
}

export const CustomerWaterTestCustomerColumn: FC<ICustomerWaterTestCustomerColumn> = ({
  params,
}) => {
  const [hasCustomer, setHasCustomer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();
  useEffect(() => {
    const fetchWaterTestCustomerInfo = async () => {
      try {
        setIsLoading(true);
        if (params.row.customerId) {
          const res = await getWaterTestAccountId(params.row.customerId);
          if (res) {
            setHasCustomer(true);
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchWaterTestCustomerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <CircleProgress size="small" />;
  }
  if (hasCustomer) {
    return (
      <Tooltip title="Pool Service Customer">
        <span>
          <FontAwesomeIcon icon={faUser} color={theme.palette.secondary.main} />
        </span>
      </Tooltip>
    );
  }
  return <></>;
};
