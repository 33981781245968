import { Page } from '../../components';
import { PostPaymentPageDetails } from './post-payment-page-details';

export const PostPaymentPage = () => {
  return (
    <Page
      title="Transaction Batch"
      breadcrumb={{
        text: 'Payments',
        title: 'Back to Payments',
        link: `/payments`,
      }}
    >
      <PostPaymentPageDetails />
    </Page>
  );
};
