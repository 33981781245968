import React, { useState, useEffect, FC } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useConfirm } from '../../hooks';
import { getPathAndSearch } from '../../helpers';

interface ConfirmPromptProps {
  when: boolean | undefined;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmPrompt: FC<ConfirmPromptProps> = ({ when, message, onConfirm, onCancel }) => {
  const [isBlocked, setIsBlocked] = useState<boolean>(!!when);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<Location | undefined>(undefined);
  const history = useHistory();
  const confirm = useConfirm();
  const location = useLocation();

  useEffect(() => {
    setIsBlocked(!!when);
  }, [when]);

  const handleBlockedNavigation = (newNextLocation: unknown, action: string): boolean => {
    const isDifferentLocation = (newNextLocation as Location)?.pathname !== location.pathname;
    if (isBlocked && isDifferentLocation) {
      setNextLocation(newNextLocation as Location);
      setShowPrompt(true);
      return false;
    }

    return true;
  };

  const handleConfirm = () => {
    setIsBlocked(false);
    setShowPrompt(false);
    onConfirm && onConfirm();
    if (nextLocation) {
      history.push(getPathAndSearch(nextLocation));
    }
  };

  const handleCancel = () => {
    setShowPrompt(false);
    onCancel && onCancel();
  };

  useEffect(() => {
    if (showPrompt) {
      confirm(message).then(result => {
        if (result) {
          handleConfirm();
        } else {
          handleCancel();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPrompt, confirm, message, nextLocation]);

  return <Prompt when={isBlocked} message={handleBlockedNavigation} />;
};
