import { FC } from 'react';
import { SearchResultsGrid } from './search-results-grid';
import { ISiteSearchResult, ISearchResults, ISearchResultsPage } from '../../models';
import { getSiteSearchResults } from '../../fetch';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { Link } from '../../components';

interface ISiteSearchResults {
  results: ISearchResults;
  page: ISearchResultsPage[];
  perPage: ISearchResultsPage[];
}

export const SiteSearchResults: FC<ISiteSearchResults> = ({ results, page, perPage }) => {
  return (
    <SearchResultsGrid
      labelContext="Site"
      searchType="sites"
      rowId="siteId"
      totalCount={results?.sites?.totalRecordCount}
      gridResults={results?.sites?.records}
      getApiRequest={getSiteSearchResults}
      pageNumber={page?.find(type => type.type === 'sites')?.page}
      pageSize={perPage?.find(type => type.type === 'sites')?.perPage}
      columns={[
        {
          headerName: 'Site Description',
          field: 'description',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISiteSearchResult>) => {
            const { row: original } = params;
            const childComponent = (
              <Tooltip placement="bottom" title="Edit site">
                <span>{original.description}</span>
              </Tooltip>
            );
            return (
              <Link
                to={`/customers/${original.accountId}?activeTab=sites&siteId=${original.siteId}&isEdit=true`}
              >
                {childComponent}
              </Link>
            );
          },
        },
        {
          headerName: 'Customer',
          field: 'accountName',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISiteSearchResult>) => {
            const { row: original } = params;
            const childComponent = (
              <Tooltip placement="bottom" title="Edit customer">
                <span>{original.accountName}</span>
              </Tooltip>
            );
            return <Link to={`/customers/${original.accountId}`}>{childComponent}</Link>;
          },
        },
        {
          headerName: 'Street',
          field: 'addressStreet',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
        },
        {
          headerName: 'City',
          field: 'addressCity',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          headerName: 'State',
          field: 'addressState',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 80,
        },
        {
          headerName: 'ZIP Code',
          field: 'addressPostCode',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
      ]}
    />
  );
};
