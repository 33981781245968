import { IRoute } from '../models';
import {
  Ballot,
  DateRange,
  Description,
  DomainVerification,
  DynamicFeed,
  KeyboardBackspace,
  Padding,
  Pattern,
  Pending,
  SettingsEthernet,
  ShortText,
  SmartButton,
  Style,
  Tab,
  TableChart,
  TableRows,
  Title,
  WebAsset,
  Widgets,
  Event,
  TextFields,
  SettingsPhone,
  TextFormat,
  Segment,
} from '@mui/icons-material';
import { ROLES } from './roles';

// NOTE: This is the starting index for the style guide links id's, so that they can be dynamically generated
// This is starting at a larger number because the main link is included in the main nav
let styleGuideRouteIndex = 1001;

// NOTE: These route bases are used as a URL base for child links, ensure there is no trailing slash
const styleGuideRouteBase = '/admin/kitchen-sink';
const styleGuideComponentsRouteBase = `${styleGuideRouteBase}/components`;
const styleGuideGridsRouteBase = `${styleGuideComponentsRouteBase}/grids`;
const styleGuideInputsRouteBase = `${styleGuideComponentsRouteBase}/inputs`;

// NOTE: These paths are used to generate the links and route url's in the style guide, so that they are shared and can be updated in one location
export const StyleGuidePaths = {
  kitchenSink: {
    url: styleGuideRouteBase,
    label: 'Kitchen Sink',
  },
  buttons: {
    url: `${styleGuideComponentsRouteBase}/buttons`,
    label: 'Buttons',
  },
  cardsPrimary: {
    url: `${styleGuideComponentsRouteBase}/cards`,
    label: 'Cards - Primary',
  },
  cardButton: {
    url: `${styleGuideComponentsRouteBase}/card-button`,
    label: 'Card Button',
  },
  cardTitle: {
    url: `${styleGuideComponentsRouteBase}/card-title`,
    label: 'Card Title',
  },
  grids: {
    url: `${styleGuideGridsRouteBase}`,
    label: 'Grids',
  },
  dataGrid: {
    url: `${styleGuideGridsRouteBase}/data-grid`,
    label: 'Data Grid',
  },
  tableLegend: {
    url: `${styleGuideGridsRouteBase}/table-legend`,
    label: 'Table Legend',
  },
  pagination: {
    url: `${styleGuideGridsRouteBase}/pagination`,
    label: 'Pagination',
  },
  textField: {
    url: `${styleGuideInputsRouteBase}/text-field`,
    label: 'Text Field',
  },
  phoneTextField: {
    url: `${styleGuideInputsRouteBase}/phone-text-field`,
    label: 'Phone Text Field',
  },
  selectField: {
    url: `${styleGuideInputsRouteBase}/select`,
    label: 'Select',
  },
  wysiwyg: {
    url: `${styleGuideInputsRouteBase}/wysiwyg`,
    label: 'WYSIWYG',
  },
  dateRangePicker: {
    url: `${styleGuideInputsRouteBase}/date-range-picker`,
    label: 'Date Range Picker',
  },
  dateRangeNavigator: {
    url: `${styleGuideInputsRouteBase}/date-range-navigator`,
    label: 'Date Range Navigator',
  },
  displayGroup: {
    url: `${styleGuideComponentsRouteBase}/display-group`,
    label: 'Display Group',
  },
  loader: {
    url: `${styleGuideComponentsRouteBase}/loader`,
    label: 'Loader',
  },
  modals: {
    url: `${styleGuideComponentsRouteBase}/modals`,
    label: 'Modals',
  },
  page: {
    url: `${styleGuideComponentsRouteBase}/page`,
    label: 'Page',
  },
  pageTitle: {
    url: `${styleGuideComponentsRouteBase}/page-title`,
    label: 'Page Title',
  },
  iconPageHeading: {
    url: `${styleGuideComponentsRouteBase}/icon-page-heading`,
    label: 'Icon Page Heading',
  },
  tabs: {
    url: `${styleGuideComponentsRouteBase}/tabs`,
    label: 'Tabs',
  },
  floatingToolbar: {
    url: `${styleGuideComponentsRouteBase}/floating-toolbar`,
    label: 'Floating Toolbar',
  },
  uiUxPatterns: {
    url: `${styleGuideRouteBase}/ui-ux-patterns`,
    label: 'UI/UX Patterns',
  },
};

// NOTE: This parent link is separate because it is included in 2 different navigation menus
export const styleGuideParentLink: IRoute = {
  id: styleGuideRouteIndex,
  label: StyleGuidePaths.kitchenSink.label,
  link: StyleGuidePaths.kitchenSink.url,
  icon: <Style fontSize="medium" />,
  subNav: null,
  roles: [ROLES.SuperAdmin],
};

// NOTE: The following are the links for the style guide specific navigation menu
// Roles are currently set-up to share the roles of the parent link
// Links commented out below are intended for the next expansion phase of the Kitchen Sink
export const styleGuideLinks: IRoute[] = [
  styleGuideParentLink,
  {
    id: styleGuideRouteIndex++,
    label: 'Components',
    link: '',
    icon: <Widgets fontSize="medium" />,
    roles: styleGuideParentLink.roles,
    subNav: [
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.buttons.label,
        link: StyleGuidePaths.buttons.url,
        icon: <SmartButton fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.cardsPrimary.label,
        link: StyleGuidePaths.cardsPrimary.url,
        icon: <WebAsset fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.cardButton.label,
        link: StyleGuidePaths.cardButton.url,
        icon: <DomainVerification fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.cardTitle.label,
        link: StyleGuidePaths.cardTitle.url,
        icon: <Title fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.grids.label,
        link: StyleGuidePaths.grids.url,
        icon: <TableChart fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.dataGrid.label,
        link: StyleGuidePaths.dataGrid.url,
        icon: <TableRows fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.tableLegend.label,
        link: StyleGuidePaths.tableLegend.url,
        icon: <Ballot fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.pagination.label,
        link: StyleGuidePaths.pagination.url,
        icon: <SettingsEthernet fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.textField.label,
        link: StyleGuidePaths.textField.url,
        icon: <TextFields fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.phoneTextField.label,
        link: StyleGuidePaths.phoneTextField.url,
        icon: <SettingsPhone fontSize="medium" />,
      },
      // {
      //   id: styleGuideRouteIndex++,
      //   label: 'Textarea',
      //   link: '/kitchen-sink/components/inputs/textarea',
      //   icon: <AspectRatio fontSize="medium" />,
      // },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.selectField.label,
        link: StyleGuidePaths.selectField.url,
        icon: <Segment fontSize="medium" />,
      },
      // {
      //   id: styleGuideRouteIndex++,
      //   label: 'Autocomplete',
      //   link: '/kitchen-sink/components/inputs/autocomplete',
      //   icon: <EditAttributes fontSize="medium" />,
      // },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.wysiwyg.label,
        link: StyleGuidePaths.wysiwyg.url,
        icon: <TextFormat fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.dateRangePicker.label,
        link: StyleGuidePaths.dateRangePicker.url,
        icon: <DateRange fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.dateRangeNavigator.label,
        link: StyleGuidePaths.dateRangeNavigator.url,
        icon: <Event fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.displayGroup.label,
        link: StyleGuidePaths.displayGroup.url,
        icon: <ShortText fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.loader.label,
        link: StyleGuidePaths.loader.url,
        icon: <Pending fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.modals.label,
        link: StyleGuidePaths.modals.url,
        icon: <DynamicFeed fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.page.label,
        link: StyleGuidePaths.page.url,
        icon: <Description fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.pageTitle.label,
        link: StyleGuidePaths.pageTitle.url,
        icon: <Title fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.iconPageHeading.label,
        link: StyleGuidePaths.iconPageHeading.url,
        icon: <KeyboardBackspace fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.tabs.label,
        link: StyleGuidePaths.tabs.url,
        icon: <Tab fontSize="medium" />,
      },
      {
        id: styleGuideRouteIndex++,
        label: StyleGuidePaths.floatingToolbar.label,
        link: StyleGuidePaths.floatingToolbar.url,
        icon: <Padding fontSize="medium" />,
      },
    ],
  },
  {
    id: styleGuideRouteIndex++,
    label: StyleGuidePaths.uiUxPatterns.label,
    link: StyleGuidePaths.uiUxPatterns.url,
    icon: <Pattern fontSize="medium" />,
    roles: styleGuideParentLink.roles,
    subNav: null,
  },
];
