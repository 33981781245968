
export enum WORK_ORDER_REPORT_IDS {
    StartUp = '64FFA49D-CA48-4339-86BD-308647EB5531',
    OpenClose = 'B9216175-1B93-4E3C-9D18-312CE362AB21',
    Repair = 'F6EAB9F6-8A29-4D98-95BE-BEFD2457F9DB',
    Service = '2D87E49F-78BF-4FAC-B22D-C53512ADD965'
  }
  export enum WORK_ORDER_REPORT_PARAMS {
    ServiceDate = "ServiceDate",
    ReportLayout = "ReportLayout",
    ScheduledServiceId = "ScheduledServiceId"
  }
  export enum REPORT_EMAIL_PARAMS {
    EnhancedCustomerStatements = "EB4141B5-626C-49F4-8B10-4BAF90635F5D"
  }