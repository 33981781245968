import { FC } from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { Card, RadioGroup } from '../../../components';

export const ViewVisibility: FC = () => (
  <Card cardTitleProps={{ title: 'View Visibility' }}>
    <RadioGroup name="viewVisibility">
      <FormControlLabel
        value="public"
        control={<Radio />}
        label={<Typography variant="body2">This view is accessible by all users</Typography>}
      />
      <FormControlLabel
        value="private"
        control={<Radio />}
        label={<Typography variant="body2">This view is accessible by me</Typography>}
      />
    </RadioGroup>
  </Card>
);
