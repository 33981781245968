import { FC } from 'react';
import { ConfirmCheckboxModal } from '../../../components';

interface IDuplicatePostModal {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export const DuplicatePostModal: FC<IDuplicatePostModal> = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  return (
    <ConfirmCheckboxModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="This posting appears to be a duplicate. Please confirm that this has not already been posted."
      checkboxLabel="I understand that this billing looks like a duplicate of a billing that has already been posted. I confirm that this is not a duplicate and needs to be posted."
    />
  );
};
