import { TextField } from '@mui/material';
import React, { FC, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { IParameterInputProps } from '../../../../models';

interface ICurrencyInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const currencyMask = createNumberMask({ allowDecimal: true });

const CurrencyInput = forwardRef(function TextMaskCustom(props: ICurrencyInputProps, _ref) {
  const { onChange, ...other } = props;
  return <MaskedInput {...other} onChange={onChange} mask={currencyMask} />;
});

export const MoneyParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const { parameterName, promptText } = parameter;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value, parameter);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      label={promptText}
      value={value}
      onChange={handleChange}
      name={parameterName}
      InputProps={{
        inputComponent: CurrencyInput as any,
      }}
    />
  );
};
