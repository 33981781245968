import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { IAnalysis } from '../../models';
import { GridDataFetcher, ServerSideDataGrid, useDataGrid } from '../data-grid';
import { getAnalysis } from '../../fetch';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../context';
import { analysisColumns } from './columns';
import { formatDate } from '../../helpers';

export interface IWaterAnalysisDataGridProps {
  siteId?: string;
  serviceId?: string;
  isHistory?: boolean;
  selectionModel: GridRowSelectionModel;
  setSelectionModel: (selection: GridRowSelectionModel) => void;
}

export const WaterAnalysisDataGrid: FC<IWaterAnalysisDataGridProps> = ({
  siteId,
  serviceId,
  selectionModel,
  setSelectionModel,
  isHistory = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const clearSelection = () => {
    setSelectionModel([]);
  };

  const dataFetcher: GridDataFetcher<IAnalysis> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getAnalysis({
          scheduledServiceId: !isHistory ? serviceId : undefined,
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'createDate',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
          siteId: isHistory ? siteId : undefined,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading analysis${isHistory && ' history'}, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IAnalysis>({
    initialOptions: {
      page: 0,
      pageSize: 5,
      gridKeyName: isHistory ? 'analysis-history-grid' : 'service-analysis-grid',
      sortColumn: 'createDate',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  useEffect(() => {
    if (rows.length && !selectionModel.length) {
      setSelectionModel([rows[0].analysisId]);
    }
  }, [rows, selectionModel, setSelectionModel]);

  const columns = useMemo(() => {
    if (!isHistory) {
      return analysisColumns.filter(col => col.field !== 'serviceType');
    }
    return analysisColumns.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isHistory, refetch]);

  return (
    <ServerSideDataGrid
      onRowSelectionModelChange={newSelectionModel => {
        setSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={selectionModel}
      disableColumnFilter
      loading={isLoading}
      disableColumnSelector
      disableRowSelectionOnClick={false}
      autoHeight
      getRowId={(row: IAnalysis) => row.analysisId}
      rows={rows}
      columns={columns}
      sortModel={sortModel}
      page={page}
      pageSize={perPage}
      rowCount={recordCount}
      onPageChange={page => {
        onPageChange(page);
        clearSelection();
      }}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={model => {
        onSortModelChange(model);
        clearSelection();
      }}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (val: IAnalysis) =>
          val?.createDate && formatDate(val?.createDate),
        handleView: (val: IAnalysis) => setSelectionModel(val ? [val.analysisId] : []),
      }}
    />
  );
};
