import { Stack } from '@mui/material';
import { Page } from '../../components';
import { LeadsGrid } from './leads-grid';
import { NewLeadsGrid } from './new-leads-grid';
import { NewLeadsSection } from './new-leads-section';

export const LeadsPage = () => {
  return (
    <Page title="Leads">
      <NewLeadsSection />
      <Stack gap={2}>
        <NewLeadsGrid />
        <LeadsGrid />
      </Stack>
    </Page>
  );
};
