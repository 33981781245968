import { FC, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Typography, Fade } from '@mui/material';
import { Page, Loader, Link, Modal } from '../../../components';
import { getAccount, getAdditionalInfoFields } from '../../../fetch';
import { IAccountDetail, IAdditionalInfoField } from '../../../models';
import { CustomersDetailDisplay } from './CustomersDetailDisplay';
import { CustomersDetailCRUD } from './CustomersDetailCRUD';
import { UserContext } from '../../../context';
import { Permissions } from '../../../constants';
import { hasCorrectUserPermissions } from '../../../helpers';

interface IPageParams {
  paramsAccountId: string;
}

interface ICustomersDetail {
  isEditMode?: boolean; // Is editing a specific customer record
  isModal?: boolean;
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  currentAccountId?: string;
}

export const CustomersDetail: FC<ICustomersDetail> = ({
  isEditMode = false,
  isModal,
  isModalOpen,
  handleModalClose,
  currentAccountId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const searchParams = new URLSearchParams(window.location.search);
  const needsRefresh = !!currentAccountId || searchParams.get('refresh');

  // used for editing/viewing a customer
  const { paramsAccountId } = useParams() as IPageParams;
  const accountId = paramsAccountId ?? currentAccountId;
  // used for creating a new customer
  const isNewCustomer = accountId === 'new';

  const [currentCustomer, setCurrentCustomer] = useState<IAccountDetail | null>(null);
  const [isLoadingCustomer, setLoadingCustomer] = useState<boolean>(true);
  const [hasCustomerLoaded, setCustomerLoaded] = useState<boolean>(false);

  const [actualSpendingLimit, setActualSpendingLimit] = useState<number>(0);

  const fetchCustomerDetail = async () => {
    if (
      !isNewCustomer &&
      accountId &&
      hasCorrectUserPermissions(Permissions.ViewCustomers, user!)
    ) {
      setLoadingCustomer(true);
      try {
        const res = await getAccount(accountId);
        setCurrentCustomer(res);
        setCustomerLoaded(true);
        // wait till the page is loading before flipping this flag so the form is in correct state
        const timer = setTimeout(() => {
          setCustomerLoaded(false);
        }, 500);

        // Clear timer on unmount
        return () => {
          clearTimeout(timer);
        };
      } catch (error) {
        enqueueSnackbar(`Error loading customer, please try again.`, {
          variant: 'error',
        });
      } finally {
        setLoadingCustomer(false);
      }
    } else {
      setLoadingCustomer(false);
    }
  };

  //Get Additional Info Fields
  const [additionalInfoFields, setAdditionalInfoFields] = useState<IAdditionalInfoField[]>([]);
  const fetchAdditionalInfoFields = async () => {
    try {
      const res = await getAdditionalInfoFields({ perPage: -1, officeId: user?.officeId });
      setAdditionalInfoFields(res.records);
      setCustomerLoaded(true);
      // wait till the page is loading before flipping this flag so the form is in correct state
      setTimeout(() => {
        setCustomerLoaded(false);
      }, 500);
    } catch (error) {
      enqueueSnackbar(`Error loading sales taxes, please try again.`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fetchCustomerDetail();
    fetchAdditionalInfoFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsRefresh]);

  const children = (
    <>
      {isLoadingCustomer && (
        <Box mt={4} mb={2}>
          <Box py={5}>
            <Loader position="centered" />
          </Box>
        </Box>
      )}
      {!currentCustomer && !isLoadingCustomer && !isNewCustomer && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="10rem"
        >
          <Typography>No customer found.</Typography>
          <Link to="/customers">Back to customers</Link>
        </Box>
      )}
      {/* Render display only view with tabs */}
      {!isLoadingCustomer && !isEditMode && (
        <CustomersDetailDisplay
          accountId={accountId}
          currentCustomer={currentCustomer}
          isLoadingCustomer={isLoadingCustomer}
          isModal={isModal}
        />
      )}
      {!isLoadingCustomer && (isEditMode || isNewCustomer) && (
        <CustomersDetailCRUD
          accountId={accountId}
          isNewCustomer={isNewCustomer}
          isEditing={isEditMode}
          currentCustomer={currentCustomer}
          hasCustomerLoaded={hasCustomerLoaded}
          actualSpendingLimit={actualSpendingLimit}
          setActualSpendingLimit={setActualSpendingLimit}
          additionalInfoFields={additionalInfoFields}
        />
      )}
    </>
  );
  if (isModal) {
    const title =
      currentCustomer?.firstName && currentCustomer?.lastName
        ? `Customer - ${currentCustomer?.firstName} ${currentCustomer?.lastName}`
        : '';
    const accountTitle = currentCustomer?.name ? `Customer - ${currentCustomer?.name}` : '';
    return (
      <Modal
        open={isModalOpen!}
        onClose={handleModalClose}
        maxWidth="xl"
        title={title ?? accountTitle ?? 'Customer'}
      >
        <Fade in={isModalOpen!}>
          <Box mt={2} mb={4}>
            {children}
          </Box>
        </Fade>
      </Modal>
    );
  }

  return (
    <Page
      title={
        isLoadingCustomer
          ? 'Loading...'
          : Boolean(currentCustomer?.accountId)
          ? `${isEditMode ? 'Edit ' : ''}${currentCustomer?.name}` ||
            `${currentCustomer?.firstName} ${currentCustomer?.lastName}`
          : 'New Customer'
      }
      breadcrumb={
        Boolean(currentCustomer?.accountId) && isEditMode
          ? {
              text:
                currentCustomer?.name ||
                `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
              title: 'Back to Customer Detail',
              link: `/customers/${accountId}`,
            }
          : {
              text: 'Customers',
              title: 'Back to Customers',
              link: `/customers`,
            }
      }
    >
      {children}
    </Page>
  );
};
