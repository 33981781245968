import { IReportParameter, IReportParameterUpdate } from '../../models';

export const updateParameters = (
  parameters: IReportParameter[],
  parameterUpdates: IReportParameterUpdate
): IReportParameter[] => {
  return parameters.map(p => {
    const update = parameterUpdates[p.parameterName];
    if (!update) {
      return p;
    }
    return {
      ...p,
      ...update,
    };
  });
};
