import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { appInsights } from './appinsights';
import { msalConfig } from './msal-config';

/* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.initialize().then(() => {
  appInsights.trackEvent({ name: 'MSAL Initialize' });
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    appInsights.trackEvent({ name: 'MSAL Accounts', properties: { accounts } });
    msalInstance.setActiveAccount(accounts[0]);
  }

  // Register Callbacks for Redirect flow
  msalInstance.handleRedirectPromise();

  msalInstance.addEventCallback((event: EventMessage) => {
    appInsights.trackEvent({ name: 'MSAL Event', properties: { event } });
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      appInsights.trackEvent({ name: 'MSAL Account', properties: { account } });
    }
  });
});
