import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  ICommission,
  IGetFiltersResponse,
  ICommissionForm,
  IErrorResponse,
  ICommissionPost,
} from '../models';
import { userSignal } from '../signals';

export const getCommissions = async ({
  filters,
  ...otherParams
}: {
  dateType?: string;
  startDate?: string;
  endDate?: string;
  commissionTypeId?: string;
  accountId?: string;
  userId?: string;
  serviceDefId?: string;
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  filters?: Record<string, string[]>;
}): Promise<IResponse<ICommission[]>> => {
  try {
    const { data } = await axios.get(`/api/commissions`, {
      params: {
        ...otherParams,
        ...(filters || {}),
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommissionsFilters = async (officeId?: string | null) => {
  try {
    const { data } = await axios.get<IGetFiltersResponse>('/api/commissions/filters', {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    const { filters } = data;
    return filters;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const putCommission = async (
  commissionId: string,
  payload: ICommissionPost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/commissions/${commissionId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const postCommission = async (
  payload: ICommissionPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/commissions`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommission = async (
  commissionId: string,
  officeId?: string | null
): Promise<ICommissionForm> => {
  try {
    const { data } = await axios.get(`/api/commissions/${commissionId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteCommission = async (commissionId: string | number): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.delete(`/api/commissions/${commissionId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
