export enum ReportFormat {
  Pdf = 'Pdf',
  Html = 'Html',
  Rtf = 'Rtf',
  Tiff = 'Tiff',
  Xls = 'Xls',
  Text = 'Text',
}

export interface IReportParameterOption {
  disabled: boolean;
  group: string;
  selected: boolean;
  text: string;
  value: string;
}

export interface IReportParameter {
  defaultValue: string;
  parameterName: string;
  parameterType:
    | 'checkbox'
    | 'checkboxgroup'
    | 'date'
    | 'datetime'
    | 'datetime2'
    | 'dropdown'
    | 'integer'
    | 'money'
    | 'radiobuttons'
    | 'string'
    | 'textarea'
    | 'textbox';
  promptText: string;
  reportParameterId: string;
  selectListItems: IReportParameterOption[];
  hidden?: boolean;
  savedValue?: string;
}

export interface IReportParameterUpdate {
  [key: string]: Partial<IReportParameter>;
}

export interface IReport {
  reportId: string;
  officeId: string;
  office: string;
  description: string;
  menuPath: string;
}

export interface IParameterInputProps {
  parameter: IReportParameter;
  value: string;
  onChange: (value: string, param: IReportParameter) => unknown;
  onBlur?: (value: string, param: IReportParameter) => unknown;
}

export interface IGenerateReportParameter {
  reportParameterId: string;
  value: string;
}

export interface IGenerateReportOptions {
  parameters: IGenerateReportParameter[];
}

export interface IReport {
  basedOnReportId: string;
  description: string;
  isHidden: boolean;
  isSubReport: boolean;
  menuPath: string;
  office: string;
  officeId: string;
  reportId: string;
  sortOrder: number;
}
