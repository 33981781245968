import { FC, ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { ConfirmPrompt, Card } from '../../components';
import { useUnload } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IFormikCardProps {
  children: ReactNode;
  showConfirm?: boolean;
  shouldUnload?: boolean;
}

export const FormikCard: FC<IFormikCardProps> = ({ children, showConfirm, shouldUnload }) => {
  const { dirty, isSubmitting } = useFormikContext();

  // Prevent unload/refresh if there are unsaved changes from the form
  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, shouldUnload ?? dirty);

  return (
    <Card>
      <ConfirmPrompt
        when={showConfirm ?? (dirty && !isSubmitting)}
        message={defaultUnsavedChangesMessage}
      />
      {children}
    </Card>
  );
};
