import { Autocomplete, Chip, SxProps, TextField, styled } from '@mui/material';
import { FC } from 'react';
import { IAccountSimple } from '../../../models';

interface IAccountFilterProps {
  accounts: IAccountSimple[];
  selectedAccounts: IAccountSimple[];
  onDelete: (account: IAccountSimple) => unknown;
  onChange: (accounts: IAccountSimple[]) => unknown;
  sx?: SxProps;
}

export const AccountFilter: FC<IAccountFilterProps> = ({
  accounts,
  selectedAccounts,
  onDelete,
  onChange,
  sx,
}) => {
  return (
    <Autocomplete
      fullWidth
      value={selectedAccounts}
      multiple
      disableClearable
      onChange={(_event, newValue: IAccountSimple[]) => {
        if (newValue && newValue.length > 0) {
          onChange?.(newValue);
        } else {
          onChange?.([]);
        }
      }}
      sx={{
        ...sx,
        borderRadius: theme => theme.spacing(0.5),
        '&& .MuiOutlinedInput-root': {
          padding: '6px 9px',
        },
      }}
      options={accounts.filter(t => !selectedAccounts.map(t => t.accountId).includes(t.accountId))}
      getOptionLabel={(option: IAccountSimple) => {
        return option.accountName;
      }}
      renderOption={(props, option: IAccountSimple) => {
        return (
          <li {...props} key={option.accountId}>
            {option.accountName}
          </li>
        );
      }}
      renderTags={accounts =>
        accounts.map(account => (
          <StyledChip
            key={account.accountId}
            label={account.accountName}
            variant="outlined"
            onDelete={() => onDelete?.(account)}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            fullWidth
            autoComplete="none"
            variant="outlined"
            label="Customers"
            placeholder={selectedAccounts.length ? '' : 'Select a customer'}
          />
        );
      }}
    />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderRight: 'none',
  textOverflow: 'ellipsis',
  maxWidth: '175px',
  '&& .MuiChip-deleteIcon': {
    fontSize: '24px',
    marginRight: '-14px',
    color: theme.palette.primary.main,
  },
}));
