import { FC, useCallback, useContext, useState } from 'react';
import { Card, CardTitle, GridDataFetcher, Loader, useDataGrid } from '../../../components';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context';
import { useConfirm } from '../../../hooks';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ITechnicianUser } from '../../../models';
import { deleteTechnicianUser, getTechnicianUsers } from '../../../fetch';
import { TechManagementDataGrid } from './tech-management-data-grid';
import { AddEditTechManagementModal } from './tech-management-add-edit-modal';

export const TechManagementDetails: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTechnicianId, setSelectedTecnicianId] = useState<string | null>(null);
  const [selectedTechnicianName, setSelectedTechnicianName] = useState<string | null>(null);
  const [isDeletingTechnician, setIsDeletingTechnician] = useState(false);

  const dataFetcher: GridDataFetcher<ITechnicianUser> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getTechnicianUsers({
          sortBy: sortColumn || 'userName',
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading technician users, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITechnicianUser>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tech-management-grid',
      sortColumn: 'userName',
      sortDirection: 'asc',
    },
    dataFetcher,
  });
  const handleEdit = async (tech: ITechnicianUser) => {
    setSelectedTecnicianId(tech.technicianId);
    setSelectedTechnicianName(tech.userName);
    setModalOpen(true);
  };
  const handleDelete = async (tech: ITechnicianUser) => {
    const result = await confirm('Are you sure you want to delete this technician?');
    if (result) {
      try {
        setIsDeletingTechnician(true);
        await deleteTechnicianUser(tech.technicianId);
        enqueueSnackbar('Successfully deleted technician!', {
          variant: 'success',
        });
        refetch();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error deleting technician, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsDeletingTechnician(false);
      }
    }
  };
  return (
    <Card>
      <CardTitle
        title="Tech Management"
        mobileWrap
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              setModalOpen(true);
            }}
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            Add Technician
          </Button>
        }
      />
      <Box marginTop={-2}>
        {isDeletingTechnician && <Loader type="overlay" position="centered" title="Deleting..." />}
        <TechManagementDataGrid
          loading={isLoading}
          rows={rows}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          refetch={refetch}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        <AddEditTechManagementModal
          isOpen={isModalOpen}
          onClose={(shouldUpdate?: boolean) => {
            if (shouldUpdate) {
              refetch();
            }
            setSelectedTecnicianId(null);
            setSelectedTechnicianName(null);
            setModalOpen(false);
          }}
          selectedTechnicianId={selectedTechnicianId}
          selectedTechnicianName={selectedTechnicianName}
        />
      </Box>
    </Card>
  );
};
