import { Page } from '../../components';
import { WelcomePageDetails } from './welcome-page-details';

export const WelcomePage = () => {
  return (
    <Page title="Welcome" isExternal={true} customPageHeader={true}>
      <WelcomePageDetails />
    </Page>
  );
};
