import { Grid, Button, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { WeekModeSelect } from './WeekModeSelect';
import { UpdateModeSelect } from './UpdateModeSelect';
import { SaveButton } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo } from '@fortawesome/free-solid-svg-icons';
import { ICalendarView, IRouteUpdateMode } from '../../models';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { useConfirm } from '../../hooks';

interface IButtonsToolbar {
  showWeekViewSelector: boolean;
  isSingleViewMode: boolean;
  view?: ICalendarView;
  handleWeekViewChange: (newView: ICalendarView) => void;
  showUpdateMode: boolean;
  updateMode: IRouteUpdateMode;
  onUpdateModeChange?: (value: IRouteUpdateMode) => void;
  disableUpdateMode: boolean;
  isTechUser: boolean;
  showReset: boolean;
  isSaving: boolean;
  hasChanges?: boolean;
  isLoading: boolean;
  onReset?: () => void;
  showSave: boolean;
  onSave?: () => void;
  toolbarControls: React.ReactNode;
  showConfirmPrompt?: boolean;
  showReload?: boolean;
  onReload?: () => void;
}

export const ButtonsToolbar: FC<IButtonsToolbar> = ({
  showWeekViewSelector,
  isSingleViewMode,
  view,
  handleWeekViewChange,
  showUpdateMode,
  updateMode,
  onUpdateModeChange,
  disableUpdateMode,
  isTechUser,
  showReset,
  isSaving,
  hasChanges,
  isLoading,
  onReset,
  showSave,
  onSave,
  toolbarControls,
  showConfirmPrompt,
  showReload,
  onReload
}) => {
  const isMobile = useMediaQuery(`(max-width: 599px)`);
  const classes = useStyles({ isMobile });

  const confirm = useConfirm();

  return (
    <Grid item xs={12} className={clsx('print--none', classes.mobileButtonStyles)}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: theme => theme.spacing(1),
          margin: theme => theme.spacing(0.5, 0, 2),
        }}
        spacing={0.5}
      >
        {showReload && (
          <Grid xs={12} sm="auto" sx={{ marginRight: 'auto' }} item>
            <Button
              disabled={isSaving || isLoading}
              color="primary"
              variant="contained"
              onClick={onReload}
              startIcon={<FontAwesomeIcon icon={faRedo} size="lg" />}
              data-testid="refresh-data-button"
            >
              Refresh Data
            </Button>
          </Grid>
        )}
        {showWeekViewSelector && !isSingleViewMode && (
          <Grid xs={12} sm="auto" item>
            <WeekModeSelect value={view} onChange={handleWeekViewChange} />
          </Grid>
        )}
        {showUpdateMode && !isTechUser && (
          <Grid xs={12} sm="auto" item>
            <UpdateModeSelect
              value={updateMode}
              onChange={onUpdateModeChange}
              disabled={disableUpdateMode || view === ICalendarView.DateRange}
            />
          </Grid>
        )}
        {showReset && !isTechUser && (
          <Grid xs={12} sm="auto" item>
            <Button
              disabled={isSaving || !hasChanges || isLoading}
              color="error"
              variant="contained"
              onClick={onReset}
              startIcon={<FontAwesomeIcon icon={faUndo} size="lg" />}
              data-testid="discard-changes-button"
            >
              Discard Changes
            </Button>
          </Grid>
        )}
        {showSave && !isTechUser && (
          <Grid xs={12} sm="auto" item>
            <SaveButton
              handleSave={async () => {
                if (showConfirmPrompt) {
                  const result = await confirm(
                    'Please note: Any changes made in recurring mode will update the recurring series of each service, starting today.',
                    {
                      confirmationText: 'Save',
                    }
                  );
                  if (result) {
                    onSave?.();
                  }
                } else {
                  onSave?.();
                }
              }}
              disabled={isSaving || !hasChanges || isLoading}
              text={isSaving ? 'Saving...' : 'Save'}
            />
          </Grid>
        )}
        {toolbarControls}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) => ({
  mobileButtonStyles: {
    '& .MuiButton-root': {
      width: ({ isMobile }) => (isMobile ? '100%' : 'auto'),
    },
  },
}));
