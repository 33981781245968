import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider, FormControl, FormControlLabel, Checkbox } from '@mui/material';

// fetch
import { updateReferralType, createReferralType } from '../../../fetch';
import { IReferralType } from '../../../models';

interface IAddEditReferralTypeModal {
  open: boolean;
  onClose: () => void;
  currentReferralType?: IReferralType | null;
  fetchReferralTypes: () => void;
}

const Schema = Yup.object().shape({
  referralTypeId: Yup.string(),
  infoRequired: Yup.boolean(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export const AddEditReferralTypeModal: FC<IAddEditReferralTypeModal> = ({
  open,
  onClose,
  currentReferralType,
  fetchReferralTypes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          referralTypeId: currentReferralType?.referralTypeId ?? '',
          officeId: user?.officeId ?? '',
          code: currentReferralType?.code ?? '',
          description: currentReferralType?.description ?? '',
          infoRequired: currentReferralType?.infoRequired ?? false
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values
          };
          try {
            currentReferralType
              ? await updateReferralType(data)
              : await createReferralType(data);
            enqueueSnackbar(
              currentReferralType
                ? 'Successfully updated referral type!'
                : 'Successfully created referral type!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchReferralTypes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving referral type, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleChange,
          handleSubmit,
          dirty,
          isValid,
          values
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentReferralType ? 'Edit Referral Type' : 'Add Referral Type'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="infoRequired"
                                checked={values.infoRequired}
                                onChange={handleChange}
                              />
                            }
                            label="Info Required"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
