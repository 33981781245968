import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  Accordion,
  Grid,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, DisplayGroup, Loader, Pagination, Card } from '../../components';
import { deleteEquipment, getEquipmentDetail, getSiteEquipment } from '../../fetch';
import { SiteEquipmentModal } from './SiteEquipmentModal';
import { ISiteEquipment } from '../../models/equipment';
import { formatDate, pascalCaseToString } from '../../helpers';
import { useConfirm } from '../../hooks';
import { ExpandMore } from '@mui/icons-material';

interface ISiteEquipmentProps {
  siteId: string | number;
  showPagination?: boolean;
  isModal?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isExpanded?: boolean;
}

export const SiteEquipment: FC<ISiteEquipmentProps> = ({
  siteId,
  isModal,
  showPagination = true,
  isCollapsible = false,
  initialExpand = false,
  isExpanded,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const notesBreakpoint = useMediaQuery(`(min-width: 1200px)`);
  const isMobile = useMediaQuery(`(max-width: 1023px)`);

  const [isModalOpen, setModalOpen] = useState(false);

  const [siteEquipment, setSiteEquipment] = useState<ISiteEquipment[]>([]);
  const [isLoadingSiteEquipment, setIsLoadingSiteEquipment] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);

  const [currentEquipment, setCurrentEquipment] = useState<ISiteEquipment | null>(null);
  const [isLoadingSiteEquipmentDetails, setIsLoadingSiteEquipmentDetails] = useState(false);

  const fetchSiteEquipment = async () => {
    setIsLoadingSiteEquipment(true);
    try {
      const res = await getSiteEquipment(siteId, {
        perPage: showPagination ? perPage : -1,
        sortBy: 'description',
        sortDirection: 'Desc',
        page: page + 1,
      });
      setSiteEquipment(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error) {
      enqueueSnackbar(`Error loading site equipment, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSiteEquipment(false);
    }
  };

  const fetchSiteEquipmentDetails = async (id: string, isCurrent: boolean = false) => {
    setIsLoadingSiteEquipmentDetails(true);
    try {
      const res = await getEquipmentDetail(id);
      const copy = siteEquipment.map(item => {
        if (item.equipmentId === id) {
          return { ...item, ...res };
        }
        return item;
      });
      setSiteEquipment(copy);
      if (isCurrent) {
        setCurrentEquipment(copy.find(item => item.equipmentId === id) ?? null);
      }
    } catch (error) {
      enqueueSnackbar(`Error loading site equipment details, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSiteEquipmentDetails(false);
    }
  };

  const setFullSiteDetails = (id: string, setCurrent: boolean = false) => {
    const selectedEquipment = siteEquipment.find(item => item.equipmentId === id);
    if (selectedEquipment && !('equipmentType' in selectedEquipment)) {
      fetchSiteEquipmentDetails(id, setCurrent);
    }
    if (setCurrent && selectedEquipment) {
      setCurrentEquipment(selectedEquipment);
    }
  };

  const [isDeletingSiteEquipment, setIsDeletingSiteEquipment] = useState(false);
  const removeSiteEquipment = async (equipmentId: string) => {
    setIsDeletingSiteEquipment(true);
    try {
      await deleteEquipment(equipmentId);
      enqueueSnackbar(`Site Equipment Deleted!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Error deleting site equipment, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingSiteEquipment(false);
      await fetchSiteEquipment();
    }
  };

  useEffect(() => {
    fetchSiteEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  if (!isLoadingSiteEquipment && !siteEquipment.length) {
    return null;
  }

  return (
    <Box mb={2}>
      <Card>
        <CardTitle
          title="Site Equipment"
          withExpand={isCollapsible}
          initialExpand={initialExpand}
          overrideExpand={isExpanded}
        >
          {isLoadingSiteEquipment && (
            <Box height="10rem">
              <Loader position="centered" type="inline" />
            </Box>
          )}
          {!isLoadingSiteEquipment && siteEquipment && siteEquipment?.length === 0 && (
            <Typography variant="body1">There is no site equipment to display.</Typography>
          )}
          {!isLoadingSiteEquipment && siteEquipment && siteEquipment?.length > 0 && (
            <>
              {siteEquipment.map((equipment, index) => {
                return (
                  <Accordion key={`${index}`} slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      onClick={() => {
                        setFullSiteDetails(equipment.equipmentId);
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <DisplayGroup
                            label="Description:"
                            labelId={`description-${index}`}
                            labelClasses={clsx(classes.label, classes.labelMargin)}
                          >
                            {equipment.description ?? '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={classes.manufacturerCol}>
                          <DisplayGroup
                            label="Manufacturer:"
                            labelId={`manufacturer-${index}`}
                            labelClasses={clsx(classes.label, classes.labelMargin)}
                          >
                            {equipment.manufacturer ?? '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <DisplayGroup
                            label="Model:"
                            labelId={`model-${index}`}
                            labelClasses={clsx(classes.label, classes.labelMargin)}
                          >
                            {equipment.model ?? '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <DisplayGroup
                            label="Status:"
                            labelId={`status-${index}`}
                            labelClasses={clsx(classes.label, classes.labelMargin)}
                          >
                            {pascalCaseToString(equipment.status)}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <DisplayGroup
                            label="Notes:"
                            labelId={`notes-${index}`}
                            labelClasses={classes.label}
                          >
                            {equipment.notes ?? '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={'auto'} className={classes.actionsCol}>
                          <Box display="flex" gap={2} className="print--none">
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                setFullSiteDetails(equipment.equipmentId, true);
                                setModalOpen(true);
                              }}
                              title="View Equipment"
                              color="primary"
                              size="small"
                              disabled={isLoadingSiteEquipment || isDeletingSiteEquipment}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </IconButton>
                            <IconButton
                              onClick={async e => {
                                e.stopPropagation();
                                const result = await confirm(
                                  'Are you sure you want to delete this?'
                                );
                                if (result) {
                                  removeSiteEquipment(equipment.equipmentId);
                                } else {
                                  return;
                                }
                              }}
                              title="Delete Equipment"
                              color="error"
                              size="small"
                              disabled={isLoadingSiteEquipment || isDeletingSiteEquipment}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionBody}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Equipment Type:"
                            labelId={`equipment-type-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {!!equipment.equipmentType ? equipment.equipmentType : '-'}
                          </DisplayGroup>
                        </Grid>
                        {isMobile && (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <DisplayGroup
                              label="Manufacturer:"
                              labelId={`manufacturer-${index}-2`}
                              labelClasses={classes.labelMargin}
                            >
                              {!!equipment.manufacturer ? equipment.manufacturer : '-'}
                            </DisplayGroup>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="SubItem Of:"
                            labelId={`parent-equipment-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {!!equipment.parentEquipmentDescription
                              ? equipment.parentEquipmentDescription
                              : '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Installed:"
                            labelId={`installed-on-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {equipment.installedOn ? formatDate(equipment.installedOn) : '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Removed:"
                            labelId={`when-removed-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {equipment.whenRemoved ? formatDate(equipment.whenRemoved) : '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Warranty Ends:"
                            labelId={`warranty-ends-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {!!equipment.warrantyEnds ? formatDate(equipment.warrantyEnds) : '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Serial Number:"
                            labelId={`serial-number-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {!!equipment.serialNumber ? equipment.serialNumber : '-'}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <DisplayGroup
                            label="Part Number:"
                            labelId={`part-number-${index}-2`}
                            labelClasses={classes.labelMargin}
                          >
                            {!!equipment.partNumber ? equipment.partNumber : '-'}
                          </DisplayGroup>
                        </Grid>
                        {!notesBreakpoint && (
                          <Grid xs={12} lg={6} xl={'auto'} style={{ flex: '1' }}>
                            <DisplayGroup
                              label="Notes:"
                              labelId={`notes-${index}-2`}
                              labelClasses={classes.labelMargin}
                            >
                              {!!equipment.notes ? equipment.notes : '-'}
                            </DisplayGroup>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}

          {!isLoadingSiteEquipment &&
            siteEquipment &&
            siteEquipment?.length > 0 &&
            showPagination && (
              <>
                <Divider sx={{ marginTop: theme => theme.spacing(2) }} />
                <Box
                  display="flex"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  alignItems="center"
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-end',
                  }}
                >
                  <Box marginBottom={1}>
                    <Pagination
                      page={page}
                      count={recordCount}
                      rowsPerPage={perPage}
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </Box>
                </Box>
              </>
            )}
        </CardTitle>
      </Card>
      <SiteEquipmentModal
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setCurrentEquipment(null);
        }}
        currentEquipment={currentEquipment}
        siteId={siteId}
        isLoading={isLoadingSiteEquipmentDetails}
        reloadList={fetchSiteEquipment}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    marginRight: '5px',
  },
  tableHeader: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.text.primary} !important`,
    borderColor: 'transparent',
    boxShadow: 'none !important',
    '&&::after': {
      opacity: 0,
    },
    '&& strong': {
      color: '#99B2C6',
      whiteSpace: 'nowrap',
    },
  },
  accordionHeader: {
    '& .accordion-button:not(.collapsed)': {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },
  actionsCol: {
    width: theme.spacing(10),
    marginRight: theme.spacing(3),
  },
  manufacturerCol: {
    minWidth: theme.spacing(10),
  },
  accordionBody: {
    padding: '1rem',
    backgroundColor: theme.palette.grey[100],
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },
  label: {},
  labelMargin: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(768)]: {
      marginTop: 0,
    },
  },
}));
