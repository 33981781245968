import { FC, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { deepEqual } from 'fast-equals';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Grid,
  MenuItem,
  InputAdornment,
  TextField as MuiTextField,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
} from '@mui/material';
import {
  CardTitle,
  TextField,
  Loader,
  SaveButton,
  Card,
  ModalSaveSection,
  CreditCardInputs,
  AdyenDropIn,
} from '../../components';
import { convertToNumber, daysOfMonthPayment, generateUUID } from '../../helpers';
import { IAccountDetail, IRecurringPayment } from '../../models';
import {
  createPaymentCapture,
  postExternalInvoicePayment,
  postRecurringPayment,
  putRecurringPayments,
} from '../../fetch';
import { usePaymentInputs } from 'react-payment-inputs';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CommonInfoSchema = {
  creditCardNumber: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationMonth: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationYear: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  cvv: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  adyen: Yup.boolean(),
};

const PaymentInfoSchema = Yup.object().shape({
  ...CommonInfoSchema,
  amount: Yup.number().required('Required').min(0, 'Must be positive'),
  invoiceNumber: Yup.string(),
  notes: Yup.string(),
});
const RecurringPaymentInfoSchema = Yup.object().shape({
  ...CommonInfoSchema,
  payOutstandingBalance: Yup.boolean(),
  dayOfMonth: Yup.string().required('Required'),
  amount: Yup.number().required('Required').min(0, 'Must be positive'),
});

const UpdateRecurringPaymentInfoSchema = Yup.object().shape({
  payOutstandingBalance: Yup.boolean(),
  amount: Yup.number().required('Required').min(0, 'Must be positive'),
  dayOfMonth: Yup.string().required('Required'),
});

interface IPaymentInfoProps {
  currentCustomer: IAccountDetail;
  isRecurring?: boolean;
  closeModal?: (shouldReload?: boolean) => void;
  currentRecurringPayment?: IRecurringPayment | null;
  invoiceNumber?: string;
  defaultAmount?: string;
  isExternalInvoicePayment?: boolean;
  transactionId?: string;
  reloadExternalInvoice?: () => void;
  authToken?: string; //used for posting external payment with JWT
  showCancelButton?: boolean;
  showNotesField?: boolean;
}

export const PaymentInfo: FC<IPaymentInfoProps> = ({
  currentCustomer,
  isRecurring = false,
  closeModal,
  currentRecurringPayment,
  invoiceNumber,
  defaultAmount,
  isExternalInvoicePayment = false,
  transactionId,
  reloadExternalInvoice,
  authToken,
  showCancelButton = true,
  showNotesField = true,
}) => {
  const { adyen } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const confirm = useConfirm();
  const [shouldCallAdyenSession, setShouldCallAdyenSession] = useState<boolean>(false);
  const [isShowingSession, setIsShowingSession] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>('');

  const [shouldCallAdyenRecurringSession, setShouldCallAdyenRecurringSession] = useState<boolean>(false);
  const [isShowingRecurringSession, setIsShowingRecurringSession] = useState<boolean>(false);
  const [guidForRecurring, setGuidForRecurring] = useState<string>('');

  const handleCallAdyenRecurringSession = () => {
    setShouldCallAdyenRecurringSession(true);
    setGuidForRecurring(generateUUID());
    setTimeout(() => setShouldCallAdyenRecurringSession(false), 1000);
  };

  const { meta } = usePaymentInputs();
  const commonData = useMemo(() => {
    return {
      creditCardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      cvv: '',
      firstName: currentCustomer?.firstName ?? currentCustomer?.name?.split(' ')?.[0] ?? '',
      lastName: currentCustomer?.lastName ?? currentCustomer?.name?.split(' ')?.[1] ?? '',
      street: currentCustomer?.address?.street ?? '',
      city: currentCustomer?.address?.city ?? '',
      state: currentCustomer?.address?.state ?? '',
      postalCode: currentCustomer?.address?.postalCode ?? '',
      amount: currentRecurringPayment?.paymentAmount
        ? currentRecurringPayment?.paymentAmount
        : defaultAmount
          ? defaultAmount
          : isRecurring
            ? '0'
            : '',
      adyen: isExternalInvoicePayment ? false : adyen,
      payOutstandingBalance: false,
      dayOfMonth: '1',
      invoiceNumber: invoiceNumber ?? '',
      notes: '',
    };
  }, [
    currentRecurringPayment,
    currentCustomer,
    defaultAmount,
    isRecurring,
    adyen,
    invoiceNumber,
    isExternalInvoicePayment,
  ]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        currentRecurringPayment
          ? {
            ...commonData,
            payOutstandingBalance:
              currentRecurringPayment?.payOutstandingBalance !== null
                ? currentRecurringPayment?.payOutstandingBalance
                : true,
            dayOfMonth: currentRecurringPayment?.dayOfMonth ?? '1',
          }
          : isRecurring
            ? {
              ...commonData,
              payOutstandingBalance: true,
              dayOfMonth: '1',
            }
            : {
              ...commonData,
              invoiceNumber: invoiceNumber ?? '',
              notes: '',
            }
      }
      validationSchema={
        currentRecurringPayment
          ? UpdateRecurringPaymentInfoSchema
          : isRecurring
            ? RecurringPaymentInfoSchema
            : PaymentInfoSchema
      }
      onSubmit={async (values, actions) => {
        try {
          if (isRecurring && !currentRecurringPayment) {
            await postRecurringPayment(currentCustomer.accountId,
              adyen ? {
                paymentAmount:
                  values?.payOutstandingBalance === true ? 0 : convertToNumber(values.amount),
                payOutstandingBalance:
                  values.payOutstandingBalance !== null ? !!values.payOutstandingBalance : true,
                dayOfMonth: convertToNumber(values.dayOfMonth),
                reference: guidForRecurring,
              } : {
                creditCardNumber: values.creditCardNumber,
                expirationMonth: Number(values.expirationMonth),
                expirationYear: Number(values.expirationYear),
                cvv: values.cvv,
                firstName: values.firstName,
                lastName: values.lastName,
                address: `${values?.street}, ${values?.city} ${values?.state}`,
                postalCode: values.postalCode,
                paymentAmount:
                  values?.payOutstandingBalance === true ? 0 : convertToNumber(values.amount),
                payOutstandingBalance:
                  values.payOutstandingBalance !== null ? !!values.payOutstandingBalance : true,
                dayOfMonth: convertToNumber(values.dayOfMonth),
              });
          } else if (isRecurring && currentRecurringPayment) {
            await putRecurringPayments(currentRecurringPayment.recurringPaymentId, {
              paymentAmount:
                values?.payOutstandingBalance === true ? 0 : convertToNumber(values.amount),
              payBalance:
                values.payOutstandingBalance !== null ? !!values.payOutstandingBalance : true,
              dayOfMonth: convertToNumber(values.dayOfMonth),
            });
          } else if (isExternalInvoicePayment && transactionId && authToken) {
            await postExternalInvoicePayment(authToken as string, transactionId, {
              recurringPaymentId: currentRecurringPayment?.recurringPaymentId ?? '',
              creditCardNumber: values.creditCardNumber,
              expirationMonth: Number(values.expirationMonth),
              expirationYear: Number(values.expirationYear),
              cvv: values.cvv,
              firstName: values.firstName,
              lastName: values.lastName,
              address: `${values?.street}, ${values?.city}, ${values?.state}`,
              postalCode: values.postalCode,
            });
            reloadExternalInvoice && reloadExternalInvoice();
          } else {
            await createPaymentCapture({
              accountId: currentCustomer.accountId,
              creditCardNumber: values.creditCardNumber,
              expirationMonth: Number(values.expirationMonth),
              expirationYear: Number(values.expirationYear),
              cvv: values.cvv,
              firstName: values.firstName,
              lastName: values.lastName,
              address: `${values?.street}, ${values?.city}, ${values?.state}`,
              postalCode: values.postalCode,
              amount: convertToNumber(values.amount),
              invoiceNumber: values.invoiceNumber ?? '',
              notes: values.notes ?? '',
            });
          }
          actions.resetForm();
          enqueueSnackbar(
            isRecurring
              ? `Successfully created recurring payment!`
              : `Successfully processed payment!`,
            {
              variant: 'success',
            }
          );
          if (isRecurring && closeModal) {
            closeModal(true);
          }
          if (isExternalInvoicePayment) {
            return;
          } else {
            history.push(`/customers/${currentCustomer.accountId}?activeTab=transactions`);
          }
        } catch (error: any) {
          if (error.Detail.includes('invalid authentication values')) {
            enqueueSnackbar(
              'Payment could not be created because your store has invalid Authorize.Net credentials.',
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(
              error.Detail ||
              (isRecurring ? 'Error, creating recurring payment.' : 'Error, processing payment.'),
              { variant: 'error' }
            );
          }
        }
      }}
    >
      {({
        resetForm,
        isSubmitting,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        values,
        handleBlur,
        errors,
        touched,
      }) => {
        const handleAdyenRecurringPayment = () => {
          handleSubmit();
        }
        return (
          <>
            {isSubmitting && <Loader position="centered" type="overlay" />}
            <Form onSubmit={handleSubmit}>
              <Card>
                {!isRecurring && <CardTitle title="Payment Info" />}
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                  {!currentRecurringPayment && ((isRecurring && !adyen) || !isRecurring) && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField label="First Name" name="firstName" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField label="Last Name" name="lastName" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField label="Street" name="street" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField label="City" name="city" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <TextField label="State" name="state" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <TextField label="Postal Code" name="postalCode" required />
                      </Grid>
                    </>
                  )}
                  {!currentRecurringPayment &&
                    ((isRecurring && !adyen) || isExternalInvoicePayment || !adyen) && (
                      <Grid item xs={12} xl={isRecurring ? 12 : 7}>
                        <CreditCardInputs
                          isInline
                          numberValue={values.creditCardNumber}
                          cvvValue={values.cvv}
                          required
                          handleCreditCardNumberChange={val => {
                            setFieldValue('creditCardNumber', val);
                            // reset if you change the card number
                            setFieldValue('cvv', '');
                            setFieldValue('expirationMonth', '');
                            setFieldValue('expirationYear', '');
                          }}
                          resetOnCreditCardNumberChange
                          handleCvvChange={val => setFieldValue('cvv', val)}
                          handleBlur={handleBlur}
                        />
                      </Grid>
                    )}

                  <Grid
                    item
                    xs={12}
                    sm={adyen && !isRecurring ? 6 : isRecurring ? 3 : 5}
                    order={isRecurring ? 2 : undefined}
                  >
                    <MuiTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required
                      type="number"
                      placeholder="0.00"
                      inputProps={{ step: '0.01' }}
                      value={values.amount}
                      disabled={values.payOutstandingBalance || !!defaultAmount || (currentRecurringPayment && adyen)}
                      onChange={e => {
                        const target = e.target as HTMLInputElement;
                        const { value } = target;
                        let split = value.split('.');
                        if (split[1]) {
                          split[1] = split[1].substring(0, 2);
                        }
                        const formatted = split[1] ? split.join('.') : value;
                        setFieldValue('amount', formatted);
                      }}
                      name="amount"
                      label="Pay Amount"
                      size="small"
                      fullWidth
                      onBlur={handleBlur}
                      error={!!touched.amount && !!errors?.amount}
                      helperText={!!touched.amount && !!errors?.amount ? errors.amount : ''}
                    />
                  </Grid>
                  {!isRecurring && !adyen && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Invoice Number"
                          name="invoiceNumber"
                          disabled={!!invoiceNumber}
                        />
                      </Grid>
                      {showNotesField && !adyen && (
                        <Grid item xs={12}>
                          <TextField label="Notes" name="notes" multiline rows={3} />
                        </Grid>
                      )}
                    </>
                  )}
                  {isRecurring && (
                    <>
                      <Grid item xs={12} sm={3} order={1}>
                        <MuiTextField
                          label="Day of Month"
                          name="dayOfMonth"
                          required
                          size="small"
                          fullWidth
                          select
                          onChange={(e: any) => {
                            setFieldValue('dayOfMonth', e.target.value);
                          }}
                          value={values.dayOfMonth}
                        >
                          {daysOfMonthPayment.map(day => (
                            <MenuItem key={day} value={day}>
                              {day === 0 ? 'Last' : day}
                            </MenuItem>
                          ))}
                        </MuiTextField>
                      </Grid>
                      <Grid item xs={12} sm={adyen ? 3 : 6} order={3}>
                        <FormControlLabel
                          color="primary"
                          control={
                            <Checkbox
                              checked={values.payOutstandingBalance}
                              id="payOutstandingBalance"
                              color="primary"
                              onChange={(_, checked) => {
                                setFieldValue('payOutstandingBalance', checked);
                                if (checked) {
                                  setFieldValue('amount', '0');
                                  adyen && handleCallAdyenRecurringSession();
                                }

                              }}
                              disabled={currentRecurringPayment && adyen}
                            />
                          }
                          label="Pay Balance"
                        />
                      </Grid>
                      {adyen && !currentRecurringPayment && (
                        <>
                          <Grid item xs={12} sm={3} order={4}>
                            <Button
                              type="button"
                              color="secondary"
                              disabled={
                                (!isValid && values.amount !== '0')
                                || (isShowingRecurringSession && values.payOutstandingBalance)
                              }
                              onClick={() => handleCallAdyenRecurringSession()}
                            >
                              {isShowingRecurringSession ? 'Update Amount' : 'Continue to Payment'}
                            </Button>
                          </Grid>
                          <Grid container item xs={12} order={4}>
                            <Grid item xs={12}>
                              <AdyenDropIn
                                amount={convertToNumber(values.amount)}
                                accountId={currentCustomer.accountId!}
                                shouldCallAdyenSession={shouldCallAdyenRecurringSession}
                                isRecurring
                                payBalance={values.payOutstandingBalance}
                                setIsShowingSession={setIsShowingRecurringSession}
                                reference={guidForRecurring}
                                afterPaymentComplete={() => handleAdyenRecurringPayment()}
                                dropInContainerId='dropin-container-recurring'
                                createAdyenTransRecord={false}
                              />
                            </Grid>
                            {isShowingRecurringSession && (
                              <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                <Button
                                  type="button"
                                  color="inherit"
                                  onClick={async () => {
                                    if (!deepEqual(initialValues, values)) {
                                      const result = await confirm(defaultUnsavedChangesMessage);
                                      if (result) {
                                        resetForm();
                                        closeModal && closeModal(true);
                                      } else {
                                        return;
                                      }
                                    }

                                    closeModal && closeModal(true);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                {!isRecurring && (
                  <Box
                    margin="1rem 0"
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {showCancelButton && !isShowingSession && (
                      <Button
                        type="button"
                        color="inherit"
                        onClick={async () => {
                          if (!deepEqual(initialValues, values)) {
                            const result = await confirm(defaultUnsavedChangesMessage);
                            if (result) {
                              resetForm();
                              return history.push(params.get('redirect') ?? '/customers');
                            } else {
                              return;
                            }
                          }

                          history.push(params.get('redirect') ?? '/customers');
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    {adyen ? (
                      <Stack gap={1} display={'flex'} direction={{ xs: 'column', sm: 'row' }}>
                        {isShowingSession && (
                          <Button
                            type="button"
                            onClick={() => {
                              setShouldCallAdyenSession(false);
                              setIsShowingSession(false);
                              setGuid('');
                            }}
                            color='inherit'
                            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="secondary"
                          disabled={!isValid && values.amount !== '0'}
                          onClick={() => {
                            setShouldCallAdyenSession(true);
                            setIsShowingSession(true);
                            setGuid(generateUUID());
                            setTimeout(() => setShouldCallAdyenSession(false), 1000);
                          }}
                        >
                          {isShowingSession ? 'Update Payment' : 'Continue to Payment'}
                        </Button>
                      </Stack>
                    ) : (
                      <SaveButton
                        disabled={
                          !dirty ||
                          isSubmitting ||
                          !isValid ||
                          !!meta?.erroredInputs?.cvc ||
                          !!meta?.erroredInputs?.cardNumber
                        }
                        text={isRecurring ? 'Save' : 'Process Payment'}
                      />
                    )}
                  </Box>
                )}
                {adyen && isShowingSession && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <AdyenDropIn
                          amount={convertToNumber(values.amount)}
                          accountId={currentCustomer.accountId!}
                          shouldCallAdyenSession={shouldCallAdyenSession}
                          setIsShowingSession={setIsShowingSession}
                          reference={guid}
                          afterPaymentComplete={() => {
                            history.push(`/customers/${currentCustomer.accountId}?activeTab=transactions`);
                          }}
                        />
                      </Grid>
                    </Grid>
                    {isShowingSession && (
                      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={{ xs: 2, sm: 1 }}>
                        <Button
                          type="button"
                          color="inherit"
                          onClick={async () => {
                            if (!deepEqual(initialValues, values)) {
                              const result = await confirm(defaultUnsavedChangesMessage);
                              if (result) {
                                resetForm();
                                return history.push(params.get('redirect') ?? '/customers');
                              } else {
                                return;
                              }
                            }

                            history.push(params.get('redirect') ?? '/customers');
                          }}
                          sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Card>
              {((isRecurring && !adyen) || (isRecurring && adyen && currentRecurringPayment)) && (
                <ModalSaveSection
                  handleCancel={async () => {
                    if (!deepEqual(initialValues, values)) {
                      const result = await confirm(defaultUnsavedChangesMessage);
                      if (result) {
                        resetForm();
                        if (isRecurring && closeModal) {
                          return closeModal();
                        }
                      } else {
                        return;
                      }
                    }
                    if (isRecurring && closeModal) {
                      return closeModal();
                    }
                  }}
                  isSaveDisabled={
                    !dirty ||
                    isSubmitting ||
                    !isValid ||
                    (!currentCustomer &&
                      (!!meta?.erroredInputs?.cvc || !!meta?.erroredInputs?.cardNumber))
                  }
                />
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
