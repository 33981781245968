import { AxiosError, CancelTokenSource } from 'axios';
import { startOfDay, endOfDay, startOfMonth, endOfMonth, isEqual, addDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCalendarEvents, updateCalendar } from '../../../fetch';
import {
  buildCalendarItemWorkDetails,
  buildInitialCalendarState,
  cloneViaSerialization,
  createFinalOrderList,
  groupByCalendarDate,
  initializeCalendarItems,
  toCalendarDate,
  setLocalStorage,
  getLocalStorage,
  CALENDAR_VIEW,
  setStartOfWeek,
  setEndOfWeek,
} from '../../../helpers';
import {
  ICalendarItem,
  ICalendarDateRange,
  ICalendarView,
  ICalendarChanges,
  IInitialCalendarState,
  IAPIError,
  ICalendarEventDetail,
  IScheduledWorkDragChangeDetails,
  ITimelessEventChange,
  CalendarEventTypes,
} from '../../../models';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

const SAVED_OUTDATED_EVENT_ERROR =
  'One or more Scheduled Services have been modified by another user:';

interface UseCalendarOptions {
  dateRange: ICalendarDateRange;
  userIds?: string[];
  accountIds?: string[];
  refresh?: () => Promise<void>;
  view?: ICalendarView;
  preventLoad?: boolean;
  onInitialLoad?: (calendarItems: ICalendarItem[]) => unknown;
}

export const useCalendar = ({
  dateRange: initialDateRange,
  view: initialView,
  userIds,
  accountIds,
  preventLoad = false,
  onInitialLoad,
}: UseCalendarOptions) => {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorDateRange, setAnchorDateRange] = useState<ICalendarDateRange>(initialDateRange);
  const [dateRange, setDateRange] = useState<ICalendarDateRange>(initialDateRange);
  const [calendarItems, setCalendarItems] = useState<ICalendarItem[]>([]);
  const [view, setView] = useState<ICalendarView>(initialView || ICalendarView.WorkWeek);
  const [timeStep, setTimeStep] = useState<number>(30);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const initialLoad = useRef<boolean>(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [updatedCalendarItems, setUpdatedCalendarItems] = useState<ICalendarItem[]>([]);
  const [changes, setChanges] = useState<ICalendarChanges>({});
  const [initialState, setInitialState] = useState<IInitialCalendarState>({});

  const getViewDates = (selectedView: ICalendarView) => {
    if (selectedView === ICalendarView.Day) {
      return {
        startDate: startOfDay(anchorDateRange.startDate),
        endDate: endOfDay(anchorDateRange.startDate),
      };
    }
    if (selectedView === ICalendarView.Week) {
      return {
        startDate: setStartOfWeek(anchorDateRange.startDate),
        endDate: setEndOfWeek(anchorDateRange.startDate),
      };
    }
    if (selectedView === ICalendarView.Month) {
      const monthStart = setStartOfWeek(startOfMonth(anchorDateRange.startDate));
      const monthEnd = setEndOfWeek(endOfMonth(anchorDateRange.startDate));
      return {
        startDate: monthStart,
        endDate: monthEnd,
      };
    }
    //Default is Work Week
    const workWeekStart = setStartOfWeek(anchorDateRange.startDate);
    return {
      startDate: workWeekStart,
      endDate: addDays(workWeekStart, 4),
    };
  };
  const onViewChange = useCallback(
    (newView: ICalendarView) => {
      if (newView === view) {
        return;
      }
      setDateRange(getViewDates(newView));
      setLocalStorage(CALENDAR_VIEW, newView);
      setView(newView);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anchorDateRange, view]
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const checkLocalStorageCalendarView = async () => {
        const value: ICalendarView = await getLocalStorage(CALENDAR_VIEW);
        if (value && view !== value) {
          onViewChange(value);
        } else {
          setLocalStorage(CALENDAR_VIEW, initialView ?? ICalendarView.WorkWeek);
        }
      };
      checkLocalStorageCalendarView();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCalendarEvents = async () => {
    try {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel();
      }
      if (preventLoad) {
        return;
      }
      setIsLoading(true);
      const items = await getCalendarEvents({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        userIds,
        accountIds,
      });
      initializeCalendarItems(items);
      setCalendarItems(items);

      if (initialLoad.current) {
        onInitialLoad?.(items);
      }
      initialLoad.current = false;
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error loading calendar events. Please try again.`, {
        variant: 'error',
      });
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchCalendarEvents();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, userIds, accountIds, preventLoad]);

  const onTimeStepChange = (step: number) => {
    setTimeStep(step);
  };

  const onDateRangeChange = (newRange: ICalendarDateRange) => {
    setDateRange(newRange);
    setAnchorDateRange(newRange);
  };

  const setInitialCalendarItems = (calendarItems: ICalendarItem[]) => {
    const clonedItems = cloneViaSerialization(calendarItems);
    setUpdatedCalendarItems(clonedItems);
    setChanges({});
    setInitialState(buildInitialCalendarState(clonedItems));
  };

  useEffect(() => {
    setInitialCalendarItems(calendarItems);
  }, [calendarItems]);

  const reset = useCallback(() => {
    setInitialCalendarItems(calendarItems);
  }, [calendarItems]);

  const { scheduledWorkList, unscheduledWorkList } = useMemo(
    () => buildCalendarItemWorkDetails(updatedCalendarItems, changes),
    [updatedCalendarItems, changes]
  );
  const unscheduledWorkByDate = useMemo(
    () => groupByCalendarDate(unscheduledWorkList),
    [unscheduledWorkList]
  );

  const onUnscheduledWorkChange = useCallback(
    (change: ITimelessEventChange<ICalendarEventDetail>) => {
      const { event, targetCalendarDate, targetIndex } = change;
      const unscheduledWork = event.data;
      const { calendarItem, calendarItemUser, event: calendarEvent } = unscheduledWork;
      const targetDateIso = toCalendarDate(targetCalendarDate);

      const targetCalendarItem = updatedCalendarItems.find(
        item => item.calendarDate === targetDateIso
      );

      if (!targetCalendarItem) {
        return;
      }

      let targetCalendarUser = targetCalendarItem.users.find(
        user => user.userInformation.userId === calendarItemUser.userInformation.userId
      );

      const isSameDate = targetCalendarItem.calendarDate === calendarItem.calendarDate;

      const initialEventState = initialState[calendarEvent.eventId];
      const hasChanged =
        initialEventState.calendarDate !== targetDateIso || initialEventState.index !== targetIndex;

      const newChanges: ICalendarChanges = {
        ...changes,
      };
      delete newChanges[calendarEvent.eventId];
      if (hasChanged) {
        newChanges[calendarEvent.eventId] = {
          eventId: calendarEvent.eventId,
          eventType: calendarEvent.eventType,
          eventDate: targetDateIso,
          originalEventDate: initialEventState.calendarDate,
          index: targetIndex,
          version: calendarEvent.version,
        };
        setChanges(newChanges);
      }

      if (!isSameDate) {
        calendarItemUser.events = calendarItemUser.events.filter(e => e !== calendarEvent);

        if (targetCalendarUser) {
          targetCalendarUser.events.push(calendarEvent);
        } else {
          targetCalendarUser = {
            userInformation: calendarItemUser.userInformation,
            scheduledTaskCount: 0,
            scheduledServiceCount: 0,
            repairCount: 0,
            events: [calendarEvent],
          };
          targetCalendarItem.users.push(targetCalendarUser);
        }

        let scheduledTaskCount = 0;
        let scheduledServiceCount = 0;
        let repairCount = 0;

        targetCalendarUser.events.forEach(e => {
          if (e.eventType === CalendarEventTypes.RepairVisit) {
            repairCount++;
            return;
          }
          if (e.eventType === CalendarEventTypes.ScheduledTask) {
            scheduledTaskCount++;
            return;
          }
          if (e.eventType === CalendarEventTypes.ScheduledService) {
            scheduledServiceCount++;
            return;
          }
        });

        targetCalendarUser.scheduledTaskCount = scheduledTaskCount;
        targetCalendarUser.scheduledServiceCount = scheduledServiceCount;
        targetCalendarUser.repairCount = repairCount;
      }

      let currentDate = unscheduledWork.calendarItem.calendarDate;
      let currentIndex = calendarEvent.index;
      calendarEvent.index = targetIndex;

      if (isSameDate) {
        const isDraggingDown = targetIndex > currentIndex;
        unscheduledWorkByDate[targetDateIso]?.forEach(detail => {
          const { event } = detail;
          if (unscheduledWork.event === event) {
            return;
          }
          if (isDraggingDown) {
            if (event.index > targetIndex || event.index < currentIndex) {
              return;
            }
            event.index--;
          } else {
            if (event.index < targetIndex || event.index > currentIndex) {
              return;
            }
            event.index++;
          }
        });
      } else {
        unscheduledWorkByDate[currentDate]?.forEach(detail => {
          const { event } = detail;
          if (unscheduledWork.event === event) {
            return;
          }
          if (event.index < currentIndex) {
            return;
          }
          event.index--;
        });

        unscheduledWorkByDate[targetDateIso]?.forEach(detail => {
          const { event } = detail;
          if (unscheduledWork.event === event) {
            return;
          }
          if (event.index < targetIndex) {
            return;
          }
          event.index++;
        });
      }

      setUpdatedCalendarItems([...updatedCalendarItems]);
    },
    [initialState, changes, updatedCalendarItems, unscheduledWorkByDate]
  );

  const onScheduledWorkChange = useCallback(
    (change: IScheduledWorkDragChangeDetails) => {
      const { scheduledWork, startTime, endTime } = change;

      const { event } = scheduledWork;

      if (!event.startTime || !event.endTime) {
        return;
      }

      const newChanges: ICalendarChanges = {
        ...changes,
      };

      const initialTaskState = initialState[event.eventId];

      if (!initialTaskState.startTime || !initialTaskState.endTime) {
        return;
      }

      const initialStartTime = new Date(initialTaskState.startTime);
      const initialEndTime = new Date(initialTaskState.endTime);
      const hasChanged = !isEqual(initialStartTime, startTime) || !isEqual(initialEndTime, endTime);

      delete newChanges[event.eventId];
      if (hasChanged) {
        newChanges[event.eventId] = {
          eventId: event.eventId,
          version: event.version,
          eventType: event.eventType,
          startTime,
          endTime,
        };
      }

      event.startTime = startTime.toISOString();
      event.endTime = endTime.toISOString();

      setChanges(newChanges);
      setUpdatedCalendarItems([...updatedCalendarItems]);
    },
    [initialState, changes, updatedCalendarItems]
  );

  const saveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      const changeList = Object.values(changes);

      await updateCalendar({
        changes: changeList,
        finalOrder: createFinalOrderList(updatedCalendarItems),
      });
      setInitialCalendarItems(updatedCalendarItems);
      enqueueSnackbar('Calendar saved successfully', {
        variant: 'success',
      });
    } catch (e) {
      const resError = e as AxiosError<IAPIError>;
      if (resError.response?.data.Detail.startsWith(SAVED_OUTDATED_EVENT_ERROR)) {
        enqueueSnackbar('Please refresh and redo your changes', {
          variant: 'info',
          persist: true,
          action: () => (
            <Button
              variant="text"
              color="inherit"
              onClick={() => {
                fetchCalendarEvents();
                closeSnackbar();
              }}
            >
              Refresh
            </Button>
          ),
        });
        enqueueSnackbar('The calendar has been updated by another user so it could not be saved.', {
          variant: 'error',
          persist: true,
        });
        return;
      }
      enqueueSnackbar('An error ocurred while saving the calendar', {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes, updatedCalendarItems]);

  const changeCount = Object.keys(changes).length;

  const hasChanges = changeCount > 0;

  return {
    isLoading,
    dateRange,
    setDateRange,
    anchorDateRange,
    setAnchorDateRange,
    onDateRangeChange,
    calendarItems,
    refresh: fetchCalendarEvents,
    view,
    setView,
    onViewChange,
    getViewDates,
    timeStep,
    onTimeStepChange,
    updatedCalendarItems,
    scheduledWorkList,
    unscheduledWorkList,
    unscheduledWorkByDate,
    changes,
    changeCount,
    hasChanges,
    reset,
    onUnscheduledWorkChange,
    onScheduledWorkChange,
    saveChanges,
    isSaving,
  };
};
