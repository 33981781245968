import { FC } from 'react';
import { EstimatesCard } from '../../estimates';

export interface IOTSEstimates {
  accountId: string | undefined;
  officeId: string | null | undefined;
  repairId: string;
  siteId?: string;
  isEditable?: boolean;
  isNewService?: boolean;
  isAllExpanded: boolean | undefined;
  isModal?: boolean;
  isAgreementSigned: boolean;
  refetchRepairStatus?: () => void;
  onNavigation?: (route: string) => unknown;
  shouldAddBeDisabled?: boolean;
}

export const OTSEstimates: FC<IOTSEstimates> = ({
  accountId,
  repairId,
  isAllExpanded,
  siteId,
  isModal,
  isAgreementSigned,
  refetchRepairStatus,
  onNavigation,
  shouldAddBeDisabled,
}) => {
  return (
    <EstimatesCard
      repairId={repairId}
      siteId={siteId}
      withExpand
      isExpanded={isAllExpanded}
      isEditable={!isModal}
      accountId={accountId}
      shouldAddBeDisabled={shouldAddBeDisabled}
      showFilters={false}
      hidePagination={true}
      columnSorting={false}
      redirect={
        repairId && repairId !== 'new'
          ? `/services/ots/${repairId}&repairId=${repairId}`
          : '/services/ots'
      }
      isOTSEstimates
      refetchRepairStatus={refetchRepairStatus}
      onNavigation={onNavigation}
    />
  );
};
