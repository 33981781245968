import { Page } from '../../components';
import { TransactionPageDetails } from './transaction-page-details';

export const TransactionPage = () => {
  return (
    <Page
      title="Transaction"
      breadcrumb={{
        text: 'Billing',
        title: 'Back to Billing',
        link: `/billing`,
      }}
    >
      <TransactionPageDetails />
    </Page>
  );
};
