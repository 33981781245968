import { FC, useState, useContext } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Fade, Grid, InputAdornment, Button } from '@mui/material';
import { Modal, Loader, SelectAsync, TextField, SaveButton, Card } from '../../../components';
import { UserContext } from '../../../context';
import {
  IResponse,
  IListUser,
  ICommissionRulePost,
  ICommissionType,
  ICommissionRule,
  IServiceDefinition,
  IDropdownResponse,
} from '../../../models';
import {
  getUsers,
  getCommissionTypes,
  deleteCommissionRule,
  putCommissionRule,
  postCommissionRule,
  getCommissionRulePayToTypes,
  getServiceDefinitions,
} from '../../../fetch';
import { useConfirm } from '../../../hooks';
import { convertToNumber } from '../../../helpers';
import { BrandingContext } from '../../../context/branding-context';
import { POOL_SERVICE_COMMISSION_TYPE_ID, PINCH_SERVICE_COMMISSION_TYPE_ID} from '../../../constants';


const FORM_VALIDATION = Yup.object().shape({
  commissionTypeId: Yup.string().required('Required'),
  serviceDefId: Yup.string().required('Required'),
  payTo: Yup.string().required('Required'),
  userid: Yup.string(),
  reason: Yup.string().max(500, 'Max 500 characters'),
  amount: Yup.number().min(0).required('Required'),
});

interface IAddEditCommissionRuleModal {
  open: boolean;
  onClose: () => void;
  currentCommissionRule?: ICommissionRule | null;
  fetchCommissionRules: () => void;
}

export const AddEditCommissionRuleModal: FC<IAddEditCommissionRuleModal> = ({
  open,
  onClose,
  currentCommissionRule,
  fetchCommissionRules
}) => {
  const { isPoolService } = useContext(BrandingContext);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { user } = useContext(UserContext);

  const history = useHistory();
  
  const handleDelete = async () => {
    const result = await confirm('Are you sure you want to delete this commission rule?');
    if (!result) return;
    if (!currentCommissionRule?.serviceCommissionRuleId) return;
    try {
      setIsDeleting(true);
      await deleteCommissionRule(currentCommissionRule?.serviceCommissionRuleId);
      enqueueSnackbar(`Commission Rule Deleted!`, {
        variant: 'success',
      });
      fetchCommissionRules();
      redirect && history.push(redirect);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting commission rule, please try again.`, {
        variant: 'error',
      });
    } finally {
      onClose();
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          commissionTypeId:
            // default to "Commision" commision type when creating a new one
            currentCommissionRule?.commissionTypeId
              ? currentCommissionRule?.commissionTypeId
              : isPoolService
              ? POOL_SERVICE_COMMISSION_TYPE_ID
              : PINCH_SERVICE_COMMISSION_TYPE_ID, 
          serviceDefId: currentCommissionRule?.serviceDefId ?? '',
          payTo: currentCommissionRule?.payTo.replace(' ', '') ?? '',
          userId: currentCommissionRule?.userId === null ? 'Any' : currentCommissionRule?.userId ?? '',
          reason: currentCommissionRule?.reason ?? '',
          amount: currentCommissionRule?.amount ?? '',
        }}
        enableReinitialize={true}
        validationSchema={FORM_VALIDATION}
        onSubmit={async values => {
          try {
            const payload: ICommissionRulePost = {
              amount: convertToNumber(values.amount),
              reason: values.reason ?? '',
              commissionTypeId: values.commissionTypeId ?? '',
              userId: values.userId === 'Any' ? null : values.userId ?? '',
              officeId: user?.officeId ?? '',
              serviceDefId: values.serviceDefId ?? '',
              payTo: values.payTo ?? '',
              serviceCommissionRuleId: currentCommissionRule?.serviceCommissionRuleId ?? null,
            };

            if (currentCommissionRule?.serviceCommissionRuleId) {
              await putCommissionRule(currentCommissionRule.serviceCommissionRuleId, {
                ...payload,
              });
            } else {
              await postCommissionRule({ ...payload });
            }
            enqueueSnackbar('Commission Rule saved!', { variant: 'success' });
            onClose();
            fetchCommissionRules();
          } catch (err: any) {
            enqueueSnackbar(err?.Detail ?? `Error saving commission rule. Please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, values, resetForm }) => {
          return (
            <Modal
            open={open}
            onClose={() => {
              onClose();
              resetForm();
            }}
            maxWidth="md"
          >
            {isSubmitting && <Loader type="overlay" position="centered" />}
            <Fade in={open}>            
            <Form style={{ height: '100%' }}>
              {(isDeleting || isSubmitting) && (
                <Loader position="centered" type="overlay" />
              )}
              <>
                <Box>
                  <Card
                    cardTitleProps={{
                      title: `${currentCommissionRule?.serviceCommissionRuleId == null ? 'Add' : 'Edit'} Commission Rule Details`,
                    }}
                  >
                    <Prompt
                      when={dirty && !isSubmitting}
                      message="You have unsaved changes, are you sure you want to leave?"
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="commissionTypeId"
                          label="Commission Type"
                          required
                          apiRequest={() =>
                            getCommissionTypes({
                              perPage: -1,
                              officeId: user?.officeId as string,
                            })
                          }
                          transformResponse={(response: IResponse<ICommissionType[]>) => {
                            return response.records.map(record => ({
                              label: record.description,
                              value: record.commissionTypeId,
                            }));
                          }}
                          hasClear={values.commissionTypeId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="serviceDefId"
                          label="Service Type"
                          required
                          apiRequest={() => getServiceDefinitions()}
                          transformResponse={(response: IResponse<IServiceDefinition[]>) => {
                            return response.records.map((record, index) => ({
                              label: record.description,
                              value: record.serviceDefId,
                            }));
                          }}
                          hasClear={values.serviceDefId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="payTo"
                          label="Pay To"
                          required
                          apiRequest={() => getCommissionRulePayToTypes()}
                          transformResponse={(response: IDropdownResponse[]) => {
                            return response.map(record => ({
                              label: record.description,
                              value: record.value,
                            }));
                          }}
                          hasClear={values.payTo ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="userId"
                          label="Person"
                          required
                          apiRequest={() => getUsers({ perPage: -1, officeId: user?.officeId })}
                          transformResponse={(response: IResponse<IListUser[]>) => {
                            return [
                              {
                                label: 'Any',
                                value: 'Any',
                              },
                              ...response.records.map(record => ({
                                label: `${record.userName} ${
                                  record.isDisabled ? '(Disabled)' : ''
                                }`,
                                value: record.userId,
                                disabled: record.isDisabled,
                              })),
                            ];
                          }}
                          hasClear={values.userId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="amount"
                          label="Amount"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          type="number"
                          placeholder="0.00"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField name="reason" label="Reason" multiline minRows={4} />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{
                    xs: 'space-between',
                  }}
                  className="print--none"
                  mt={2}
                >
                  <Box flex={1}>                    
                    {currentCommissionRule?.serviceCommissionRuleId && (
                      <Button
                        color="error"
                        type="button"
                        onClick={handleDelete}
                        title="Delete Commission Rule"
                        startIcon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                  <Box display="flex" gap={1}>
                    <Button
                      color="inherit"
                      onClick={() => {
                        onClose();
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <SaveButton disabled={!dirty || !isValid} />
                  </Box>
                </Box>
              </>
            </Form>
            </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
