import React, { createContext, FC, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getUserAvatar,
  updateUser,
  uploadUserAvatar,
  getMe,
  getTimeZone,
  getOffice,
} from '../fetch';
import { getCookie, getEnvCookieKey, userLogout } from '../helpers';
import { IOfficeDetail, IUpdateUser, IUser } from '../models';
import { useQuery } from 'react-query';
import { userSignal } from '../signals';
import { ROLES } from '../constants';

interface IUserContext {
  setUser: (user: IUser | undefined) => void;
  user: IUser | undefined;
  isFetching: boolean;
  loadUser: (shouldLoad?: boolean) => Promise<void>;
  technicianId: string | null;
  isSuperAdmin: boolean;
  isOfficeAdmin: boolean;
  isTechUser: boolean;
  userAvatarUrl?: string;
  updateCurrentUser: (updateData: IUpdateUser) => Promise<void>;
  updateCurrentUserAvatar: (newAvatarFile: File) => Promise<void>;
  permissions?: string[];
  timezone: string;
  office?: IOfficeDetail;
}

interface IUserContextHandlerProps {
  children: React.ReactNode;
}

export const UserContext = createContext<IUserContext>({
  user: undefined,
  isFetching: false,
  loadUser: () => Promise.resolve(),
  setUser: () => {},
  technicianId: null,
  isSuperAdmin: false,
  isOfficeAdmin: false,
  isTechUser: false,
  updateCurrentUser: () => Promise.resolve(),
  updateCurrentUserAvatar: () => Promise.resolve(),
  permissions: [],
  timezone: '',
  office: undefined,
});

export const UserContextHandler: FC<IUserContextHandlerProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [userAvatarUrl, setUserAvatarUrl] = useState<string>('');
  const [timezone, setTimezone] = useState('');
  const location = useLocation();

  const handleSetUser = (user: IUser | undefined) => {
    userSignal.value = user;
    setUser(user);
  };

  const fetchUser = async () => {
    try {
      // if a user is left over in state, but there is no cookie, trigger a logout in v2 and send to /login page
      if (user && !getCookie(getEnvCookieKey())) {
        userLogout();
        setFetching(false);
        return handleSetUser(undefined);
      }
      // reload the user if a cookie exists because they came from legacy v1
      if (getCookie(getEnvCookieKey()) && !user) {
        return loadUser(true);
      }
      // normal flow when logging into v2
      if (user) {
        const data = checkAndLoadEmulatingData(user);
        handleSetUser(data);
        if (!userAvatarUrl) {
          await loadUserAvatar(user.userId);
        }
        setFetching(false);
      }
      // if the cookie gets cleared because it expired and no user exists in context
      if (!user && !getCookie(getEnvCookieKey())) {
        setFetching(false);
      }
    } catch (e) {
      console.log(e, 'fetchUser');
    }
  };

  const checkAndLoadEmulatingData = (data: IUser) => {
    const emulatingData = !!localStorage.getItem('emulatingData')
      ? JSON.parse(localStorage.getItem('emulatingData')!)
      : null;
    if (data.userId === emulatingData?.userId && data.userType === ROLES.SuperAdmin) {
      data.officeId = emulatingData?.officeId;
      data.officeCode = emulatingData?.code;
      data.officeName = emulatingData?.officeName;
      data.userType = ROLES.Emulating;
    }
    return data;
  };

  const loadUser = async (shouldLoad?: boolean) => {
    try {
      if (shouldLoad) {
        setFetching(true);
      }
      const me = await getMe();
      const data = checkAndLoadEmulatingData(me);
      const user = {
        ...data,
        isEntraId: false,
      };
      handleSetUser(user);
      await loadUserAvatar(me.userId);
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const loadUserAvatar = async (userId: string) => {
    try {
      const res = await getUserAvatar(userId);
      // need to pass a query string in order to force the state to update with the new avatar url since it is always the same link
      setUserAvatarUrl(`${res}?date=${new Date().toISOString()}`);
    } catch (error: any) {}
  };

  const { data: officeData } = useQuery<IOfficeDetail, Error>(
    ['getOffice', user?.officeId],
    () => getOffice(user?.officeId!),
    {
      enabled: !!user?.officeId,
    }
  );

  useEffect(() => {
    const fetchTimeZone = async () => {
      const timezone = await getTimeZone(user?.officeId!);
      setTimezone(timezone);
    };
    if (user && user?.officeId) {
      fetchTimeZone();
    }
  }, [user]);

  useEffect(() => {
    // the agreement page needs to be accessed outside the /me auth context so don't bother fetching the user if the page is an
    // /agreement route
    if (!location.pathname.includes('/agreement')) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user]);

  const updateCurrentUser = async (updateData: IUpdateUser) => {
    if (!user?.userId) {
      return;
    }
    await updateUser(user.userId, updateData);

    const { loginName, userName, areaCode, phoneNumber } = updateData;
    handleSetUser({
      ...user,
      loginName: loginName!,
      userName: userName!,
      areaCode,
      phoneNumber,
    });
  };

  const updateCurrentUserAvatar = async (newAvatarFile: File) => {
    if (!user?.userId) {
      return;
    }
    try {
      await uploadUserAvatar(user.userId, newAvatarFile);
      await loadUserAvatar(user.userId);
    } catch (error) {
      console.log(error);
    }
  };

  const technicianId = useMemo(() => {
    if (user?.userType === ROLES.Office) {
      return user?.userId;
    } else {
      return null;
    }
  }, [user]);
  const isSuperAdmin =
    (user?.userType === ROLES.SuperAdmin || user?.userType === ROLES.Emulating) &&
    user?.isOfficeAdmin
      ? true
      : false;
  const isOfficeAdmin = user?.userType === ROLES.Office && user?.isOfficeAdmin ? true : false;
  const isTechUser = user?.userType === ROLES.Office && !user?.isOfficeAdmin ? true : false;
  const permissions = useMemo(() => user?.roles?.map(r => r.name) ?? [], [user]);
  const office = useMemo(() => officeData ?? undefined, [officeData]);

  return (
    <UserContext.Provider
      value={{
        user,
        userAvatarUrl,
        isFetching,
        loadUser,
        setUser: handleSetUser,
        technicianId,
        isSuperAdmin,
        isOfficeAdmin,
        isTechUser,
        updateCurrentUser,
        updateCurrentUserAvatar,
        permissions,
        timezone,
        office,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
