import {
  Fade,
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useContext, useState } from 'react';
import { Modal, SearchField, Loader, ModalSaveSection } from '../../../components';
import {
  getPermissionsInGroup,
  getPermissionsAvailable,
  updatePermissionsInGroup,
} from '../../../fetch';
import { IUserGroup, IPermission } from '../../../models';
import { useQuery } from 'react-query';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alphaSort } from '../../../helpers';
import { UserContext } from '../../../context';
import { defaultUnsavedChangesMessage } from '../../../constants';

interface IEditPermissionsInGroupModal {
  onClose: () => void;
  isOpen: boolean;
  currentUserGroup?: IUserGroup | null;
}

export const EditPermissionsInGroupModal: FC<IEditPermissionsInGroupModal> = ({
  onClose,
  isOpen,
  currentUserGroup,
}) => {
  const classes = useStyles();
  const { user, loadUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width: 767px)`);

  const confirm = useConfirm();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [currentPermissionsInGroup, setCurrentPermissionsInGroup] = useState<IPermission[] | null>(
    null
  );
  const [initialPermissionsInGroup, setInitialPermissionsInGroup] = useState<IPermission[] | null>(
    null
  );
  const [availablePermissions, setAvailablePermissions] = useState<IPermission[] | null>(null);

  const { isLoading: isLoadingPermissions } = useQuery<IPermission[], Error>(
    ['getPermissionsAvailable', currentUserGroup?.userGroupId],
    () => getPermissionsAvailable(),
    {
      onSuccess: d => setAvailablePermissions(d),
    }
  );

  const { isLoading: isLoadingPermissionsInGroup } = useQuery<IPermission[], Error>(
    ['getPermissionsInGroup', currentUserGroup?.userGroupId],
    () => getPermissionsInGroup(currentUserGroup?.userGroupId as string),
    {
      enabled: !!currentUserGroup?.userGroupId,
      onSuccess: d => {
        setCurrentPermissionsInGroup(d);
        setInitialPermissionsInGroup(d);
      },
    }
  );

  const handleClose = async () => {
    if (!deepEqual(initialPermissionsInGroup, currentPermissionsInGroup)) {
      const result = await confirm(defaultUnsavedChangesMessage);
      if (result) {
        onClose();
        setCurrentPermissionsInGroup(null);
        setSearchValue('');
        setAvailablePermissions(null);
        return setInitialPermissionsInGroup(null);
      }
      return;
    }
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="lg"
      title={`Edit Permissions ${currentUserGroup ? `- ${currentUserGroup?.userGroupName}` : ''}`}
    >
      <Fade in={isOpen}>
        <div>
          {isSaving && <Loader position="centered" type="overlay" />}
          <Box mt={1}>
            <Box mb={1}>
              <SearchField
                placeholder="Find permission..."
                searchValue={searchValue}
                setSearchValue={val => setSearchValue(val)}
              />
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack gap={1}>
                  <Box mt={1}>
                    <Typography variant="body1">Available Permissions</Typography>
                    <Divider />
                  </Box>
                  {isLoadingPermissions && (
                    <Box mt={2} mb={2}>
                      <Loader position="centered" />
                    </Box>
                  )}
                  {!isLoadingPermissions &&
                    availablePermissions &&
                    availablePermissions?.length > 0 && (
                      <List sx={{ overflow: 'auto', maxHeight: '28rem' }}>
                        {availablePermissions &&
                          alphaSort(
                            availablePermissions?.filter(perm =>
                              perm.name?.toLowerCase().includes(searchValue?.toLowerCase())
                            ),
                            'name'
                          ).map((permission, index) => {
                            // permission already exists in the group so exclude permission from list
                            if (
                              currentPermissionsInGroup
                                ?.map(u => u.roleId)
                                .includes(permission.roleId as string)
                            ) {
                              return null;
                            }
                            return (
                              <ListItem
                                key={`${index}`}
                                sx={{ fontSize: '0.9rem' }}
                                secondaryAction={
                                  isMobile ? (
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        setCurrentPermissionsInGroup([
                                          ...(currentPermissionsInGroup ?? []),
                                          {
                                            ...permission,
                                            isNew: true,
                                          },
                                        ]);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faPlusCircle} size="sm" />
                                    </IconButton>
                                  ) : (
                                    <Button
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        setCurrentPermissionsInGroup([
                                          ...(currentPermissionsInGroup ?? []),
                                          {
                                            ...permission,
                                            isNew: true,
                                          },
                                        ]);
                                      }}
                                      startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                    >
                                      Add
                                    </Button>
                                  )
                                }
                              >
                                <ListItemText
                                  primary={permission.name}
                                  sx={{ fontSize: '0.9rem' }}
                                />
                              </ListItem>
                            );
                          })}
                        <ListItem
                          sx={{ fontSize: '0.9rem', color: theme => theme.palette.text.disabled }}
                          className={classes.emptyState}
                        >
                          <ListItemText
                            primary="No permissions match"
                            sx={{ fontSize: '0.9rem' }}
                          />
                        </ListItem>
                      </List>
                    )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack gap={1}>
                  <Box mt={1}>
                    <Typography variant="body1">Permissions Added</Typography>
                    <Divider />
                  </Box>
                  {isLoadingPermissionsInGroup && (
                    <Box mt={2} mb={2}>
                      <Loader position="centered" />
                    </Box>
                  )}
                  {!isLoadingPermissionsInGroup && currentPermissionsInGroup?.length === 0 && (
                    <Box display="flex" justifyContent="center" mt={1} mb={1}>
                      <Typography align="center">No permissions added.</Typography>
                    </Box>
                  )}
                  {!isLoadingPermissionsInGroup &&
                    currentPermissionsInGroup &&
                    currentPermissionsInGroup?.length > 0 && (
                      <List sx={{ overflow: 'auto', maxHeight: '28rem' }}>
                        {currentPermissionsInGroup &&
                          alphaSort(
                            currentPermissionsInGroup?.filter(perm =>
                              perm.name?.toLowerCase().includes(searchValue?.toLowerCase())
                            ),
                            'name'
                          ).map((permission, index) => {
                            return (
                              <ListItem
                                key={`${index}`}
                                sx={{
                                  backgroundColor: theme =>
                                    permission?.isNew ? theme.palette.tertiary.main : 'inherit',
                                }}
                                secondaryAction={
                                  isMobile ? (
                                    <IconButton
                                      color="error"
                                      onClick={async () => {
                                        const result = await confirm(
                                          'Are you sure you want to remove this permission from this group?'
                                        );
                                        if (result) {
                                          setCurrentPermissionsInGroup(
                                            currentPermissionsInGroup.filter(
                                              p => p.roleId !== permission.roleId
                                            )
                                          );
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                  ) : (
                                    <Button
                                      color="error"
                                      size="small"
                                      onClick={async () => {
                                        const result = await confirm(
                                          'Are you sure you want to remove this permission from this group?'
                                        );
                                        if (result) {
                                          setCurrentPermissionsInGroup(
                                            currentPermissionsInGroup.filter(
                                              p => p.roleId !== permission.roleId
                                            )
                                          );
                                        }
                                      }}
                                      startIcon={<FontAwesomeIcon icon={faTrash} />}
                                    >
                                      Remove
                                    </Button>
                                  )
                                }
                              >
                                <ListItemText
                                  primary={permission.name}
                                  sx={{ fontSize: '0.9rem' }}
                                />
                              </ListItem>
                            );
                          })}
                        <ListItem
                          sx={{ fontSize: '0.9rem', color: theme => theme.palette.text.disabled }}
                          className={classes.emptyState}
                        >
                          <ListItemText
                            primary="No permissions match"
                            sx={{ fontSize: '0.9rem' }}
                          />
                        </ListItem>
                      </List>
                    )}
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <ModalSaveSection
              handleCancel={() => {
                handleClose();
              }}
              handleSave={async () => {
                try {
                  setIsSaving(true);
                  await updatePermissionsInGroup(currentUserGroup?.userGroupId as string, {
                    roleIds: currentPermissionsInGroup?.map(role => role.roleId) as string[],
                  });
                  onClose();
                  setCurrentPermissionsInGroup(null);
                  setSearchValue('');
                  setAvailablePermissions(null);
                  setInitialPermissionsInGroup(null);
                  // if the permissions changed affect the current logged in user, then behind the scenes reload the user from the api
                  const currentUserGroupIds = user?.userGroups?.map(group => group.userGroupId);
                  if (
                    currentUserGroupIds &&
                    currentUserGroupIds?.includes(currentUserGroup?.userGroupId as string)
                  ) {
                    loadUser();
                  }
                  enqueueSnackbar(`Permissions in group saved!`, {
                    variant: 'success',
                  });
                } catch (e: any) {
                  enqueueSnackbar(
                    e?.Detail ?? 'Error saving permissions in group, please try again.',
                    {
                      variant: 'error',
                    }
                  );
                } finally {
                  setIsSaving(false);
                }
              }}
              isSaveDisabled={deepEqual(initialPermissionsInGroup, currentPermissionsInGroup)}
            />
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  emptyState: {
    display: 'none',
    '&&:only-child': {
      display: 'block',
    },
  },
}));
