import { FC, ReactElement } from 'react';
import { Switch as MuiSwitch, FormControl, SwitchProps as StandardSwitchProps, FormControlLabel, FormHelperText } from '@mui/material';
import { useField } from 'formik';

interface SwitchProps extends StandardSwitchProps {
  name: string;
  label?: string | ReactElement;
};

export const Switch: FC<SwitchProps> = ({
  name,
  label,
  ...props
}) => {
  const [field, meta] = useField(name);

  const config = {
    checked: Boolean(field.value),
    ...field,
    ...props,
  }

  return (
    <FormControl>
      <FormControlLabel control={<MuiSwitch {...config} />} label={label} />
      { meta.touched && meta.error &&
        <FormHelperText error>{meta.error}</FormHelperText>
      }
    </FormControl>
  )
};
