import { FC, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { ILead } from '../../../models';
import { UserContext } from '../../../context';
import { getLeads } from '../../../fetch';
import { Card, SimpleDataGrid } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../helpers';

export interface ICustomerLeadsProps {
  accountId: string;
}

export const CustomerLeads: FC<ICustomerLeadsProps> = ({ accountId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [leads, setLeads] = useState<ILead[] | null>(null);

  const fetchCustomerLeads = async () => {
    setIsLoadingLeads(true);
    try {
      const res = await getLeads({
        sortBy: 'whenCreated',
        sortDirection: 'Desc',
        perPage: -1,
        accountId,
        officeId: user?.officeId,
      });
      setLeads(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading customer leads, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLeads(false);
    }
  };

  useEffect(() => {
    fetchCustomerLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (leads && leads.length > 0) {
    return (
      <>
        <Box mb={2}>
          <Card
            cardTitleProps={{
              title: 'Leads',
            }}
          >
            <SimpleDataGrid
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              loading={isLoadingLeads}
              getRowId={row => row.leadId}
              columns={[
                {
                  field: 'date',
                  headerName: 'Lead Date',
                  flex: 1,
                  valueFormatter: ({ value }) => formatDate(value),
                },
                {
                  field: 'serviceType',
                  headerName: 'Service Type',
                  flex: 1,
                },
                {
                  field: 'description',
                  headerName: 'Request Summary',
                  flex: 1,
                },
                {
                  field: 'actions',
                  headerName: '',
                  flex: 1,
                  renderCell: ({ row }) => (
                    <Box>
                      <IconButton
                        color="primary"
                        title="Edit Lead"
                        onClick={async () => history.push(`/leads/${row.leadId}`)}
                      >
                        <FontAwesomeIcon icon={faEdit} size="sm" />
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              rows={leads ?? []}
            />
          </Card>
        </Box>
      </>
    );
  } else return null;
};
