import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatMoney } from '../../../helpers';
import { CommissionsRulesActionButton } from './commission-rules-action-button';
import { ICommissionRule } from '../../../models';

interface CommissionsRulesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: ICommissionRule[];
  refetch: () => Promise<void>;
  setDeleting: (val: boolean) => void;
  handleEdit: (val: boolean) => void;
  setCurrentCommissionRule: (val: ICommissionRule | null) => void;
}

export const CommissionsRulesDataGrid: FC<CommissionsRulesDataGridProps> = ({
  rows,
  setDeleting,
  handleEdit,
  setCurrentCommissionRule,
  refetch,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: 'commissionType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'serviceType',
        headerName: 'Service Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'payTo',
        headerName: 'Pay To',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'person',
        headerName: 'Person',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommissionRule>) => {
          const { row: commission } = params;
          return <>{commission.person === null ? 'Any' : commission.person}</>;
        },
      },

      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommissionRule>) => {
          const { row: commission } = params;
          return commission.amount ? <>{formatMoney(commission.amount)}</> : <>$0.00</>;
        },
      },
      {
        field: 'reason',
        headerName: 'Reason',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommissionRule>) => {
          const { row: commissionRule } = params;
          return (
            <CommissionsRulesActionButton
              commissionRule={commissionRule}
              handleEdit={handleEdit}
              setCurrentCommissionRule={setCurrentCommissionRule}
              setDeleting={setDeleting}
              refetchData={refetch}
            />
          );
        },
      },
    ];
  }, [refetch, setDeleting, handleEdit, setCurrentCommissionRule]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: ICommissionRule) => row.serviceCommissionRuleId}
      rows={rows}
      columns={columns}
      hasMobileLayout
      mobileProps={{
        showHandleActions: true,
        mobileCustomDefaultAccessor: (val: ICommissionRule) =>
          `${val.commissionType} - ${val.serviceType} - ${val.payTo}`,
      }}
      {...gridProps}
    />
  );
};
