import {
  faDroplet,
  faEllipsis,
  faLightbulb,
  faMosquitoNet,
  faScrewdriverWrench,
  faSoap,
  faSprayCanSparkles,
  faUmbrellaBeach,
  faWaterLadder,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { LeadServiceEnum, LeadServiceType } from '../../models';

export const getServiceTypeIcon = (serviceType: LeadServiceType) => {
  if (serviceType === LeadServiceEnum.InstallRepair) {
    return faScrewdriverWrench;
  }
  if (serviceType === LeadServiceEnum.LandscapeDesign) {
    return faLightbulb;
  }
  if (serviceType === LeadServiceEnum.DetectionRepair) {
    return faDroplet;
  }
  if (serviceType === LeadServiceEnum.Other) {
    return faEllipsis;
  }
  if (serviceType === LeadServiceEnum.OutdoorDesign) {
    return faUmbrellaBeach;
  }
  if (serviceType === LeadServiceEnum.Pressure) {
    return faSprayCanSparkles;
  }
  if (serviceType === LeadServiceEnum.Renovations) {
    return faWaterLadder;
  }
  if (serviceType === LeadServiceEnum.ScreenRepair) {
    return faMosquitoNet;
  }
  if (serviceType === LeadServiceEnum.Treatment) {
    return faWrench;
  }
  return faSoap;
};
