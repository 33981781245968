import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';

// fetch
import { updateSiteDef, createSiteDef } from '../../../fetch';
import { ISiteDefinition, ISiteDefinitionValue } from '../../../models';
import { convertToNumber } from '../../../helpers';

interface IAddEditUserDefinedSiteDefModal {
  open: boolean;
  onClose: () => void;
  currentSiteDef?: ISiteDefinition | null;
  fetchSiteDefs: () => void;
}

const Schema = Yup.object().shape({
  userDefinedSiteDefId: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  sortOrder: Yup.number().required('Required')
});

export const AddEditUserDefinedSiteDefModal: FC<IAddEditUserDefinedSiteDefModal> = ({
  open,
  onClose,
  currentSiteDef,
  fetchSiteDefs,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          userDefinedSiteDefInfoId: '',
          userDefinedSiteDefId: currentSiteDef?.userDefinedSiteDefId ?? '',
          officeId: user?.officeId ?? '',
          code: currentSiteDef?.code ?? '',
          description: currentSiteDef?.description ?? '',
          sortOrder: currentSiteDef?.sortOrder ? currentSiteDef?.sortOrder.toString() : '0',
          isDeleted: false,
          value: ''
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: ISiteDefinitionValue = {
            userDefinedSiteDefInfoId: values.userDefinedSiteDefInfoId,
            userDefinedSiteDefId: values.userDefinedSiteDefId,
            officeId: values.officeId,
            description: values.description,
            code: values.code,
            isDeleted: values.isDeleted,
            value: values.value,
            sortOrder: convertToNumber(values.sortOrder)
          };
          try {
            currentSiteDef
              ? await updateSiteDef(data)
              : await createSiteDef(data);
            enqueueSnackbar(
              currentSiteDef
                ? 'Successfully updated site definition!'
                : 'Successfully created site definition!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchSiteDefs();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving site definition, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
          values
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentSiteDef ? 'Edit Site Attribute' : 'Add Site Attribute'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number"
                        inputProps={{ min: 0, max: 2147483647}}
                        value={values.sortOrder}
                        required
                        />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
