import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { CardTitle, Loader, Card } from '../../components';
import { getNewsCategories } from '../../fetch';
import { alphaSort } from '../../helpers';
import { ILookupModel } from '../../models';
import { NewsList } from './news-list';

export const NewsCategories = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categories, setCategories] = useState<ILookupModel[]>([]);

  const fetchNewsCategories = async () => {
    setIsLoadingCategories(true);
    try {
      const res = await getNewsCategories();
      const filteredCats = res.filter(cat => cat.value !== 'Alert');
      setCategories(alphaSort(filteredCats, 'value'));
    } catch (error) {
      enqueueSnackbar(`Error loading categories, please refresh the page to try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchNewsCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoadingCategories && (
        <Box height="10rem">
          <Loader position="centered" type="inline" />
        </Box>
      )}
      {!isLoadingCategories && (categories?.length === 0 || !categories) && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="10rem"
        >
          <Typography>There are no news categories to display.</Typography>
        </Box>
      )}
      {!isLoadingCategories &&
        categories &&
        categories?.length > 0 &&
        categories.map((category, index) => {
          return (
            <Box mt={index === 0 ? 0 : 2} key={category.value}>
              <Card>
                <CardTitle title={`${category.description}s`} />
                <NewsList category={category.description} />
              </Card>
            </Box>
          );
        })}
    </>
  );
};
