import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { FC } from 'react';
import { IFilterInputProps, IFilterSelectListItem } from '../../../../models';

export const CheckboxGroupFilter: FC<IFilterInputProps> = ({
  filter,
  values,
  onChange,
}) => {
  const { name, label } = filter;

  const paramValues = values.reduce((acc, current) => {
    if(!current) {
      return acc;
    }
    acc[current] = true;
    return acc;
  }, {} as Record<string, boolean>);

  const handleChange = (checked: boolean, option: IFilterSelectListItem) => {
    const newValues={
      ...paramValues,
      [option.value]: checked
    }
    const checkedOptions: string[] = []
    Object.entries(newValues).forEach(([value, checked]) => {
      if(!checked) {
        return
      }
      checkedOptions.push(value)
    })
    onChange(checkedOptions, filter);
  };

  

  return (
    <FormControl name={name} sx={{ m: 3 }} component="fieldset" variant="standard" style={{ margin: '0' }}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {filter.selectListItems?.map(option => (
          <FormControlLabel
            key={option.value}
            control={<Checkbox checked={paramValues[option.value] || false} />}
            label={option.text}
            onChange={(_e, checked) => handleChange(checked, option)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
