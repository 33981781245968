import { Typography, Box, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useContext } from 'react';
import { BrandingContext } from '../../context/branding-context';

interface IPageTitle {
  title: string;
  subTitle?: string;
}

export const PublicPageTitle: FC<IPageTitle> = ({ title, subTitle }) => {
  const classes = useStyles();
  const { appLongName, loginLogo } = useContext(BrandingContext);

  return (
    <Box
      display="flex"
      gap={2}
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
    >
      <Box>
        <img src={loginLogo} alt={`${appLongName} Logo`} className={classes.logo} />
      </Box>
      <Stack gap={1}>
        <Typography className={classes.header} variant="h2">
          {title}
        </Typography>
        {subTitle && (
          <Typography className={classes.subHeader} variant="body1">
            {subTitle}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  logo: {
    width: '40px',
    [theme.breakpoints.up('lg')]: {
      width: '50px',
      position: 'relative',
      top: '.25rem',
    },
  },
  header: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: '2rem',
    whiteSpace: 'normal',
    minWidth: '1px',

    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
      fontSize: '3rem',
    },
  },
  subHeader: {
    marginTop: theme.spacing(1),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
  },
}));
