import { FC, useState, useMemo, useEffect } from 'react';
import { TextField as MuiTextField, BaseTextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useDebounce } from '../../hooks';

export interface TextFieldProps extends BaseTextFieldProps {
  name: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  shouldDelay?: boolean;
  setIsValueInFlux?: (val: boolean) => void;
  autoSelectOnFocus?: boolean;
  clearValue?: boolean;
  validateOnChange?: boolean;
  onChange?: (val: string) => void;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  startAdornment,
  endAdornment,
  shouldDelay = true,
  setIsValueInFlux,
  autoSelectOnFocus,
  clearValue,
  validateOnChange,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, validateField } = useFormikContext<any>();
  const [newValue, setNewValue] = useState<string>('');
  const [isValueInFluxLocal, setIsValueInFluxLocal] = useState<boolean>(false);

  useDebounce(
    () => {
      // Allows for delayed update to the form so it doesn't re-render on every change
      setFieldValue(field.name, newValue);
      if (validateOnChange) {
        validateField(field.name);
      }
      onChange?.(newValue);
    },
    600,
    [newValue]
  );

  useEffect(() => {
    if (newValue !== field.value) {
      setIsValueInFlux?.(true);
      setIsValueInFluxLocal(true);
    } else {
      setIsValueInFlux?.(false);
      setIsValueInFluxLocal(false);
    }
  }, [newValue, field.value, setIsValueInFlux]);

  useEffect(() => {
    if (clearValue) {
      setIsValueInFlux?.(true);
      setIsValueInFluxLocal(true);
      setNewValue('');
    }
  }, [clearValue, setIsValueInFlux]);

  useMemo(() => {
    field.value && setNewValue(field.value);
  }, [field.value]);

  const config = {
    ...field,
    ...props,
    fullWidth: true,
    size: 'small' as 'small',
    value: newValue,
    onChange: (e: any) => {
      if (shouldDelay) {
        setNewValue(e.target.value);
      } else {
        setFieldValue(field.name, e.target.value);
        if (validateOnChange) {
          validateField(field.name);
        }
        onChange?.(e.target.value);
      }
    },
  };

  if (meta.touched && meta.error && !isValueInFluxLocal) {
    config.error = true;
    config.helperText = meta.error;
  }

  if (startAdornment || endAdornment) {
    return (
      <MuiTextField
        {...config}
        InputProps={{
          startAdornment,
          endAdornment,
          onFocus: autoSelectOnFocus
            ? e => {
                e.currentTarget.select();
              }
            : undefined,
        }}
        inputProps={{
          ...props.inputProps,
          'data-lpignore': 'true',
        }}
      />
    );
  }

  return (
    <MuiTextField
      {...config}
      InputProps={{
        onFocus: autoSelectOnFocus
          ? e => {
              e.currentTarget.select();
            }
          : undefined,
      }}
    />
  );
};
