import { FC, useState, useEffect } from 'react';
import { TextField, StandardTextFieldProps, Autocomplete } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

interface ISelectAsyncProps extends StandardTextFieldProps {
  name: string;
  apiRequest: () => Promise<any>;
  transformResponse: (response: any) => string[];
  onChange?: any;
  handleOptions?: (options: any[]) => void;
}

export const AutocompleteAsync: FC<ISelectAsyncProps> = ({
  name,
  apiRequest,
  transformResponse,
  onChange,
  handleOptions,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState<string[]>([]);
  const [rawOptions, setRawOptions] = useState<any[]>([]);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest();
        const data = transformResponse(response);
        handleOptions?.(response);
        setRawOptions(response);
        setOptions(data);
      } catch (err) {
        enqueueSnackbar(`Error loading ${name} dropdown`, { variant: 'error' });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setFieldValue(name, newValue || '');
  };

  const handleInputChange = (event: React.SyntheticEvent, newValue: string): void => {
    setFieldValue(name, newValue || '');
  };

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    ...field,
    ...props,
    onChange: (e: any, newValue: string) =>
      onChange ? onChange(e, newValue, rawOptions) : handleChange(e, newValue),
  };

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  return (
    <Autocomplete
      options={options}
      value={config.value}
      inputValue={config.value}
      onChange={config.onChange as any}
      onInputChange={handleInputChange}
      size={config.size}
      readOnly={props.InputProps?.readOnly}
      renderInput={params => <TextField {...config} onChange={() => {}} {...params} />}
    />
  );
};
