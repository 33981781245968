import { KitchenSink } from './kitchen-sink';
import { StyleGuidePage } from './style-guide-page';

export const StyleGuide = () => {
  return (
    <StyleGuidePage title="Kitchen Sink">
      <KitchenSink />
    </StyleGuidePage>
  );
};
