import { FC } from 'react';
import { Grid } from '@mui/material';
import { CardTitle, DisplayGroup, Card } from '../../components';
import { ISiteGetResponse } from '../../models';
import { SiteDetailsCol } from './site-details-col';

interface IDisplayAdditionalInformationProps {
  isExpanded?: boolean;
  initialExpand?: boolean;
  site: ISiteGetResponse;
}

export const DisplayAdditionalInformation: FC<IDisplayAdditionalInformationProps> = ({
  isExpanded,
  initialExpand = true,
  site,
}) => {
  return (
    <Card>
      <CardTitle
        title="Additional Information"
        withExpand
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
      >
        <Grid container spacing={2}>
          {site.additionalSiteInformation.length > 0 &&
            site.additionalSiteInformation.map((additionalSiteInformation, index) => {
              if (!!additionalSiteInformation.value) {
                return (
                  <SiteDetailsCol key={additionalSiteInformation.userDefinedSiteDefId}>
                    <DisplayGroup
                      label={additionalSiteInformation.description}
                      labelId={additionalSiteInformation.userDefinedSiteDefId}
                    >
                      {additionalSiteInformation.value}
                    </DisplayGroup>
                  </SiteDetailsCol>
                );
              } else {
                return null;
              }
            })}
          {site.chargeForChemicals && (
            <SiteDetailsCol>
              <DisplayGroup label="Billed for Chemical Treatments?" labelId="chargeForChemicals">
                {site.chargeForChemicals ? 'Yes' : 'No'}
              </DisplayGroup>
            </SiteDetailsCol>
          )}
        </Grid>
      </CardTitle>
    </Card>
  );
};
