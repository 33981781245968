import { Page } from '../../components';
import { ScheduledServices } from './ScheduledServices';

export const ServicesPage = () => {
  return (
    <Page title="Maintenance Services">
      <ScheduledServices
        dateRange={{
          selection: {
            startDate: new Date(), 
            endDate: null,
            key: 'selection',
          },
        }}
        currentStartDatePast30Days
      />
    </Page>
  );
};
