import { TextField } from '@mui/material';
import { FC } from 'react';
import { IFilterInputProps } from '../../../../models';

export const TextareaFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;
  const [value = ''] = values;
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange([e.target.value], filter);
  };

  return (
    <TextField
      multiline
      fullWidth
      rows={4}
      size="small"
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
    />
  );
};
