import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { TextField } from '../../../components';
import { CodeBlock } from '../code-block';
import { StyleGuideValidationCallout } from '../validation-callout';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';

export const StyleGuideTextField = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Text Field">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Text Field component is a custom build upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/" target="_blank">
              MUI Text Field
            </Link>{' '}
            and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has a built-in styles, and Formik props required for use with a Formik form and Yup
            form validation.
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be used specifically on forms that utilize Formik.
            For things like filters, you can use the standard MUI Text Field component, or other
            custom filter components defined in the project.
          </Typography>
          <StyleGuideValidationCallout />
          <code>
            Props:
            <br />- name: string
            <br />- startAdornment?: React.ReactNode
            <br />- endAdornment?: React.ReactNode
            <br />- shouldDelay?: boolean
            <br />- setIsValueInFlux?: (val: boolean) =&gt; void
            <br />- autoSelectOnFocus?: boolean
            <br />- clearValue?: boolean
            <br />- validateOnChange?: boolean
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: 'Test Field',
          testField2: 'Test Field 2',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box mb={1} p={0.5}>
                <TextField name="testField" label="Test" />
              </Box>
              <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
                &lt;TextField name="testField" label="Test" /&gt;
              </CodeBlock>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1} p={0.5}>
                <TextField name="testField2" label="Test" autoSelectOnFocus />
              </Box>
              <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
                &lt;TextField name="testField2" label="Test" autoSelectOnFocus /&gt;
              </CodeBlock>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </StyleGuidePage>
  );
};
