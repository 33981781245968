import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../../components';
import { formatDate } from '../../../../helpers';

export const SpaInformationDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the SpaInformationForm component as well
  // REMINDER: When adding fields, also update the spaInfoSectionFields array in pool-structure-card
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={-2} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Spa Booster Pump" labelId="hasSpaBoosterPump">
          {values?.hasSpaBoosterPump ? 'Yes' : 'No'}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Gallons" labelId="spaVolume">
          {values?.spaVolume ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa Booster Style" labelId="spaBoosterStyle">
          {values?.spaBoosterStyle ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa Brand" labelId="spaBrand">
          {values?.spaBrand ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa Booster HP" labelId="spaBoosterHp">
          {values?.spaBoosterHp ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa Model" labelId="spaModel">
          {values?.spaModel ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa Serial #" labelId="spaSerialNumber">
          {values?.spaSerialNumber ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Date of Purchase" labelId="spaDateOfPurchase">
          {values?.spaDateOfPurchase ? formatDate(values?.spaDateOfPurchase) : ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
