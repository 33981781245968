import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';

// fetch
import { updatePaymentSetup, createPaymentSetup } from '../../../fetch';
import { IPaymentSetup } from '../../../models';
import { getEnvironment } from '../../../helpers';

interface IAddEditPaymentSetupModal {
  open: boolean;
  onClose: () => void;
  currentPaymentSetup?: IPaymentSetup | null;
  fetchPaymentSetups: () => void;
}

const Schema = Yup.object().shape({
  systemSettingId: Yup.string(),
  testMode: Yup.boolean(),
  apiLoginId: Yup.string().required('Required'),
  transactionKey: Yup.string().required('Required'),
});

export const AddEditPaymentSetupModal: FC<IAddEditPaymentSetupModal> = ({
  open,
  onClose,
  currentPaymentSetup,
  fetchPaymentSetups,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isProd = getEnvironment() === 'prod';

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          systemSettingId: currentPaymentSetup?.systemSettingId ?? '',
          officeId: user?.officeId ?? '',
          apiLoginId: currentPaymentSetup?.apiLoginId ?? '',
          transactionKey: currentPaymentSetup?.transactionKey ?? '',
          testMode: !isProd
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: IPaymentSetup = {
            systemSettingId: values.systemSettingId,
            officeId: values.officeId,
            apiLoginId: values.apiLoginId,
            transactionKey: values.transactionKey,
            testMode: !isProd
          };

          try {
            currentPaymentSetup
              ? await updatePaymentSetup(data)
              : await createPaymentSetup(data);
            enqueueSnackbar(
              currentPaymentSetup
                ? 'Successfully updated payment setup!'
                : 'Successfully created payment setup!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchPaymentSetups();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving payment setup, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleChange,
          handleSubmit,
          dirty,
          isValid
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentPaymentSetup ? 'Edit Payment Setup' : 'Add Payment Setup'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="apiLoginId" label="API Login ID" disabled={currentPaymentSetup ? true : false} required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="transactionKey" label="Transaction Key" disabled={currentPaymentSetup ? true : false} required />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
