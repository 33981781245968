import { FC, useContext, useEffect } from 'react';
import { Page } from '../../components';
import { HomePageDetail } from './home-page-detail';
import { UserContext } from '../../context';
import { Paths, ROLES } from '../../constants';
import { useHistory } from 'react-router-dom';

export const Home: FC = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user?.userType === ROLES.SuperAdmin) {
      history.push(Paths.storeManagement.url);
    }
  }, [user, history]);

  return (
    <Page title="Home">
      <HomePageDetail />
    </Page>
  );
};
