import { Box, Alert } from '@mui/material';
import { FC } from 'react';

interface IPoolEquipmentAlertProps {
  isVisible?: boolean;
}
export const PoolEquipmentAlert: FC<IPoolEquipmentAlertProps> = ({ isVisible = true }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <Box mt={1}>
      <Alert severity="warning">Key data points are missing - site assessment is needed!</Alert>
    </Box>
  );
};
