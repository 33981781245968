import { ConfirmOptions } from 'material-ui-confirm';

export const defaultConfirmOptions: ConfirmOptions = {
  title: '',
  confirmationButtonProps: {
    variant: 'contained',
  },
  cancellationButtonProps: {
    variant: 'contained',
    color: 'inherit',
  },
  buttonOrder: ['cancel', 'confirm'],
  contentProps: {
    sx: {
      padding: 0,
    },
  },
  dialogActionsProps: {
    sx: {
      justifyContent: 'center',
    },
  },
};

export const defaultUnsavedChangesMessage =
  'You have unsaved changes, are you sure you want to exit?';

export const defaultSaveAndContinueMessage =
  'You have unsaved changes, would you like to save them now?';
