import { Box } from '@mui/material';
import { Page, Card } from '../../components';
import { NewsList } from '../news';

export const AlertsPage = () => {
  return (
    <Page title="Alerts">
      <Box>
        <Card cardTitleProps={{ title: 'All Alerts' }}>
          <NewsList category="Alert" />
        </Card>
      </Box>
    </Page>
  );
};
