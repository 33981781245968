import { FC } from 'react';
import { Grid } from '@mui/material';
import {
  Card,
} from '../../../components';
import { CommissionTypesTable } from './commission-types-table';
import { CommissionsRulesListDetails } from './commission-rules-list-details';

export const CommissionsSetupPage: FC = () => {
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CommissionsRulesListDetails />
        </Grid>
        <Grid item xs={12} md={12}>
          <CommissionTypesTable />
        </Grid>
      </Grid>
    </Card>
  );
};