import { Box, Grid, Typography } from '@mui/material';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { CodeBlock } from '../code-block';
import { useState } from 'react';
import { StyleGuideServerSideDataGridExample } from './data-grid-example';

export const StyleGuideTableLegend = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Table Legend">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Table Legend component is custom component built to render an icon/label pairing at
            the bottom of a ServerSideDataGrid component. This is an optional component that can be
            used where applicable to help define things like icon values in a table.
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }} mb={1}>
            <Grid item xs={12} md={'auto'}>
              <Typography mb={1}>
                <code>
                  Table Legend Props:
                  <br />- children
                </code>
              </Typography>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <Typography mb={1}>
                <code>
                  Table Legend Icon Props:
                  <br />- icon: IconDefinition
                  <br />- helpText: string
                </code>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample
              id="legend-example"
              showLegend
              perPageOverride={5}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &#123;!isLoadingData && data && data?.length &gt; 0 && (
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegend&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegendIcon
            icon=&#123;faWaterLadder&#125; helpText="Site has valid Pool Equipment" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegendIcon icon=&#123;faFlask&#125;
            helpText="Water Test has been Performed" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegendIcon icon=&#123;faVial&#125;
            helpText="Chemical Use has been Logged" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegendIcon icon=&#123;faCamera&#125;
            helpText="Service Photo attached" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;TableLegendIcon
            icon=&#123;faCircleCheck&#125; helpText="Service has been Completed" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;/TableLegend&gt;
            <br />
            )&#125;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
