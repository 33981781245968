import { FC } from 'react';
import { Loader, Modal, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography } from '@mui/material';
import { IDuplicateAccount } from '../../../models';
import { DuplicateCustomersTable } from './duplicate-customers-table';

export interface IDuplicateCustomersModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  duplicateCustomers: IDuplicateAccount[];
  isSubmitting?: boolean;
}

export const DuplicateCustomersModal: FC<IDuplicateCustomersModalProps> = ({
  open,
  onClose,
  onSubmit,
  duplicateCustomers,
  isSubmitting,
}) => {
  return (
    <Modal open={open} onClose={onClose} maxWidth="lg" title="Potential Duplicate Customer">
      {isSubmitting && <Loader position="centered" type="overlay" title="Saving..." />}
      <Fade in={open!}>
        <Box>
          <Box mt={2} mb={0}>
            <Typography>
              The customer information entered matches some information with the following
              customers. Please select one of the existing customer records or continue with
              creating a new customer.
            </Typography>
            <DuplicateCustomersTable duplicateCustomers={duplicateCustomers} />
          </Box>
          <ModalSaveSection
            handleCancel={onClose}
            submitLabel="Continue Creating New Customer"
            saveOnClick={() => {
              onClose();
              onSubmit();
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
