import { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import {
  GeneralInformationForm,
  ConditionAndNotesForm,
  SpaInformationForm,
  PumpFilterCleanerForm,
  PipingInformationForm,
  GeneralInformationDisplay,
  ConditionAndNotesDisplay,
  SpaInformationDisplay,
  PumpFilterCleanerDisplay,
  PipingInformationDisplay,
  generalInfoSectionFields,
  conditionNotesSectionFields,
  spaInfoSectionFields,
  pumpFilterCleanerSectionFields,
  pipingInfoSectionFields,
} from '..';
import { CardTitle, Card } from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface ILegacyPoolInformationProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
  showClearButtons?: boolean;
  clearSectionFields: (val: { name: string; type: 'string' | 'boolean' | 'number' }[]) => void;
  checkSectionFields: (val: { name: string; type: 'string' | 'boolean' | 'number' }[]) => boolean;
}

export const LegacyPoolInformation: FC<ILegacyPoolInformationProps> = ({
  handleFormChange,
  readOnly,
  showClearButtons = false,
  clearSectionFields,
  checkSectionFields,
}) => {
  const [showGeneralInfoClear, setShowGeneralInfoClear] = useState<boolean>(
    showClearButtons ?? false
  );
  const [showConditionNotesClear, setShowConditionNotesClear] = useState<boolean>(
    showClearButtons ?? false
  );
  const [showSpaInfoClear, setShowSpaInfoClear] = useState<boolean>(showClearButtons ?? false);
  const [showPumpFilterCleanerClear, setShowPumpFilterCleanerClear] = useState<boolean>(
    showClearButtons ?? false
  );
  const [showPipingInfoClear, setShowPipingInfoClear] = useState<boolean>(
    showClearButtons ?? false
  );
  return (
    <Box mt={2}>
      <Box>
        <Card>
          <CardTitle
            title="General Information"
            action={
              showClearButtons &&
              showGeneralInfoClear &&
              !!checkSectionFields(generalInfoSectionFields) ? (
                <Button
                  onClick={() => {
                    clearSectionFields(generalInfoSectionFields);
                    setShowGeneralInfoClear(false);
                  }}
                  startIcon={<FontAwesomeIcon icon={faClose} />}
                  className={'print--none'}
                  color="secondary"
                  size="small"
                >
                  Clear
                </Button>
              ) : undefined
            }
          />
          {readOnly ? (
            <GeneralInformationDisplay />
          ) : (
            <GeneralInformationForm handleFormChange={handleFormChange} />
          )}
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <CardTitle
            title="Condition and Notes"
            action={
              showClearButtons &&
              showConditionNotesClear &&
              !!checkSectionFields(conditionNotesSectionFields) ? (
                <Button
                  onClick={() => {
                    clearSectionFields(conditionNotesSectionFields);
                    setShowConditionNotesClear(false);
                  }}
                  startIcon={<FontAwesomeIcon icon={faClose} />}
                  className={'print--none'}
                  color="secondary"
                  size="small"
                >
                  Clear
                </Button>
              ) : undefined
            }
          />
          {readOnly ? (
            <ConditionAndNotesDisplay />
          ) : (
            <ConditionAndNotesForm handleFormChange={handleFormChange} />
          )}
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <CardTitle
            title="Spa Information"
            action={
              showClearButtons && showSpaInfoClear && !!checkSectionFields(spaInfoSectionFields) ? (
                <Button
                  onClick={() => {
                    clearSectionFields(spaInfoSectionFields);
                    setShowSpaInfoClear(false);
                  }}
                  startIcon={<FontAwesomeIcon icon={faClose} />}
                  className={'print--none'}
                  color="secondary"
                  size="small"
                >
                  Clear
                </Button>
              ) : undefined
            }
          />
          {readOnly ? (
            <SpaInformationDisplay />
          ) : (
            <SpaInformationForm handleFormChange={handleFormChange} />
          )}
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <CardTitle
            title="Pump / Filter / Cleaner"
            action={
              showClearButtons &&
              showPumpFilterCleanerClear &&
              !!checkSectionFields(pumpFilterCleanerSectionFields) ? (
                <Button
                  onClick={() => {
                    clearSectionFields(pumpFilterCleanerSectionFields);
                    setShowPumpFilterCleanerClear(false);
                  }}
                  startIcon={<FontAwesomeIcon icon={faClose} />}
                  className={'print--none'}
                  color="secondary"
                  size="small"
                >
                  Clear
                </Button>
              ) : undefined
            }
          />
          {readOnly ? (
            <PumpFilterCleanerDisplay />
          ) : (
            <PumpFilterCleanerForm handleFormChange={handleFormChange} />
          )}
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <CardTitle
            title="Piping Information"
            action={
              showClearButtons &&
              showPipingInfoClear &&
              !!checkSectionFields(pipingInfoSectionFields) ? (
                <Button
                  onClick={() => {
                    clearSectionFields(pipingInfoSectionFields);
                    setShowPipingInfoClear(false);
                  }}
                  startIcon={<FontAwesomeIcon icon={faClose} />}
                  className={'print--none'}
                  color="secondary"
                  size="small"
                >
                  Clear
                </Button>
              ) : undefined
            }
          />
          {readOnly ? (
            <PipingInformationDisplay />
          ) : (
            <PipingInformationForm handleFormChange={handleFormChange} />
          )}
        </Card>
      </Box>
    </Box>
  );
};
