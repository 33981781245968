import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { IFilterInputProps } from '../../../../models';
import { kebabCase } from 'lodash';

export const CheckboxFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;
  const [value = ''] = values;

  const handleChange = (_e: SyntheticEvent<Element, Event>, checked: boolean) => {
    onChange(checked ? ['true'] : [], filter);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox name={name} checked={value === 'true'} data-testid={kebabCase(name)} />}
        label={label}
        onChange={handleChange}
      />
    </FormGroup>
  );
};
