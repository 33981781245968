import { ConfirmOptions, useConfirm as useConfirmOriginal } from 'material-ui-confirm';
import { useCallback } from 'react';
import { ConfirmDialog } from '../components';

export const useConfirm = () => {
  const confirmOriginal = useConfirmOriginal();

  const confirm = useCallback(
    (message: string, options: ConfirmOptions = {}, showCloseButton?: boolean) => {
      return confirmOriginal({
        ...options,
        content: (
          <ConfirmDialog
            message={message}
            showCloseButton={showCloseButton ?? options?.allowClose}
          />
        ),
      })
        .then(() => true)
        .catch(() => false);
    },
    [confirmOriginal]
  );
  return confirm;
};
