import { FC, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { AccountDetailsFields } from './AccountDetailsFields';
import { Loader, FloatingToolbar, ConfirmPrompt, SaveButton } from '../../../components';
import {
  checkDuplicateAccounts,
  createAccount,
  getAccount,
  getAccountPhoneTypes,
  getAccountTypes,
  updateAccount,
} from '../../../fetch';
import {
  IAccountAlert,
  IAccountClass,
  IAccountDetail,
  IAccountDetailFormValues,
  IAdditionalInfoField,
  IBillingGroup,
  IDropdownResponse,
  IDuplicateAccount,
  IDuplicateAccountCheck,
  IPhoneNumber,
  IReferralType,
  ISalesTax,
  ITerminationType,
} from '../../../models';
import { Formik, Form } from 'formik';
import { SearchContext, UserContext } from '../../../context';
import { deepEqual } from 'fast-equals';
import * as Yup from 'yup';
import { formatInputPhoneNumber, getAreaCode, phoneRegExp, removeAreaCode } from '../../../helpers';
import { Alert, Button } from '@mui/material';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { DuplicateCustomersModal } from './duplicate-customers-modal';

const DEFAULT_PHONE_NUMBER_TYPE = 'Home';

export const statementDeliveryOptions = [
  { label: 'Email Only', value: 'email-only' },
  { label: 'Print Only', value: 'print-only' },
  { label: 'Email and Print', value: 'email-and-print' },
  { label: 'No Statement', value: 'no-statement' },
];

export const getStatementDeliveryOption = (currentCustomer: IAccountDetail | null): string => {
  if (currentCustomer) {
    if (currentCustomer?.printNoStatement && currentCustomer?.emailStatement) {
      return 'email-only';
    }
    if (!currentCustomer?.printNoStatement && currentCustomer?.emailStatement) {
      return 'email-and-print';
    }
    if (!currentCustomer?.printNoStatement && !currentCustomer?.emailStatement) {
      return 'print-only';
    }
    return 'no-statement';
  }
  return 'no-statement';
};

interface ICustomersDetailCRUD {
  accountId: string;
  isNewCustomer: boolean;
  isEditing: boolean;
  currentCustomer: IAccountDetail | null;
  hasCustomerLoaded: boolean;
  actualSpendingLimit: number;
  setActualSpendingLimit: (value: number) => void;
  additionalInfoFields: IAdditionalInfoField[];
}

const CustomerSchema = Yup.object().shape({
  accountName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  class: Yup.string(),
  accountType: Yup.string().required('Required'),
  accountCode: Yup.number(),
  status: Yup.string().required('Required'),
  additionalInfo: Yup.array().of(
    Yup.object().shape({
      userDefinedAccountDefId: Yup.string(),
      value: Yup.string(),
      description: Yup.string(),
    })
  ),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      phoneNumberType: Yup.string(),
      value: Yup.string().matches(phoneRegExp, {
        excludeEmptyString: true,
        message: 'Invalid phone number',
      }),
    })
  ),
  email: Yup.string().max(255, 'Max 255 characters').email('Email address invalid'),
  dateStarted: Yup.string(),
  dateTerminated: Yup.string(),
  referralInfo: Yup.string().max(255, 'Max 255 characters'),
  referralTypeId: Yup.string(),
  accountAlert: Yup.string(),
  addressName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  street: Yup.string().max(255, 'Max 255 characters').required('Required'),
  city: Yup.string().max(255, 'Max 255 characters').required('Required'),
  state: Yup.string().max(255, 'Max 255 characters').required('Required'),
  postalCode: Yup.string().max(255, 'Max 255 characters').required('Required'),
  latitude: Yup.number().required('Latitude is required'),
  longitude: Yup.number().required('Longitude is required'),
  billingGroupId: Yup.string().required('Required'),
  statementDelivery: Yup.string(),
  salesTaxId: Yup.string(),
  emailWhenServiceClosed: Yup.boolean(),
  seeAlso: Yup.string(),
  comments: Yup.string().max(2000, 'Max 2000 characters'),
  primaryPhoneNumberType: Yup.string().required('Required'),
  terminationTypeId: Yup.string().when('status', {
    is: 'Inactive',
    then: Yup.string().required('Required'),
  }),
  terminationReason: Yup.string(),
  waterTestCustomerId: Yup.string().nullable(),
});

export const generateDefaultName = (account: any) => {
  const nameSeparator = !!account?.lastName && !!account?.firstName ? ` ` : '';
  const firstName = !!account?.firstName ? `${account?.firstName}` : '';
  const lastName = !!account?.lastName ? `${account?.lastName}` : '';

  return `${firstName}${nameSeparator}${lastName}`;
};

// Update certain terminology to reflect Customer (for UI facing)
// Update certain terminology to Account for backend facing
export const CustomersDetailCRUD: FC<ICustomersDetailCRUD> = ({
  accountId,
  isNewCustomer,
  isEditing,
  currentCustomer,
  actualSpendingLimit,
  setActualSpendingLimit,
  additionalInfoFields,
}) => {
  const { user } = useContext(UserContext);
  const { waterTestAccount, setWaterTestAccount } = useContext(SearchContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');

  //Dropdown States
  //Due to different expectations between GET and POST, these live here
  const [accountClasses, setAccountClasses] = useState<IAccountClass[]>([]);
  const [referralTypes, setReferralTypes] = useState<IReferralType[]>([]);
  const [accountAlerts, setAccountAlerts] = useState<IAccountAlert[]>([]);
  const [billingGroups, setBillingGroups] = useState<IBillingGroup[]>([]);
  const [salesTaxes, setSalesTaxes] = useState<ISalesTax[]>([]);
  const [accountTypes, setAccountTypes] = useState<IDropdownResponse[]>([]);
  const [terminationTypes, setTerminationTypes] = useState<ITerminationType[]>([]);

  const [phoneTypes, setPhoneTypes] = useState<IDropdownResponse[]>([]);
  const [account, setAccount] = useState<IAccountDetail>();
  const [isLoadingPhoneTypes, setIsLoadingPhoneTypes] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [isLoadingAccountTypes, setIsLoadingAccountTypes] = useState(false);

  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);

  useEffect(() => {
    const fetchAccount = async () => {
      setIsLoadingAccount(true);
      try {
        const res = await getAccount(accountId);
        setAccount(res);
      } catch (error) {
        enqueueSnackbar(`Error loading account, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingAccount(false);
      }
    };

    const fetchPhoneTypes = async () => {
      setIsLoadingPhoneTypes(true);
      try {
        const res = await getAccountPhoneTypes();
        // filter out the "Bill Phone" option at the moment, since it is not in use
        setPhoneTypes(res.filter(phone => phone.value !== 'Bill'));
      } catch (error) {
        enqueueSnackbar(`Error loading phone types, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingPhoneTypes(false);
      }
    };

    const fetchAccountTypes = async () => {
      setIsLoadingAccountTypes(true);
      try {
        const res = await getAccountTypes();
        setAccountTypes(res);
      } catch (error) {
        enqueueSnackbar(`Error loading account types, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingAccountTypes(false);
      }
    };

    fetchAccountTypes();
    fetchPhoneTypes();
    if (accountId !== 'new') {
      fetchAccount();
    }
  }, [enqueueSnackbar, accountId]);

  const primaryPhone = currentCustomer?.phoneNumbers?.find(
    phoneNumber => phoneNumber.phoneNumberType === 'Primary'
  );

  let primaryPhoneNumberType = DEFAULT_PHONE_NUMBER_TYPE;

  if (primaryPhone) {
    const nonPrimaryPhones: IPhoneNumber[] =
      currentCustomer?.phoneNumbers?.filter(phone => phone.phoneNumberType !== 'Primary') || [];

    // check if the default phone option matches the primary phone number
    const defaultPhone = nonPrimaryPhones.find(
      phone => phone.phoneNumberType === DEFAULT_PHONE_NUMBER_TYPE
    );

    if (defaultPhone?.phoneNumber === primaryPhone?.phoneNumber) {
      primaryPhoneNumberType = DEFAULT_PHONE_NUMBER_TYPE;
    } else {
      // otherwise, automatically select the phone type that matches the primary phone number
      primaryPhoneNumberType =
        nonPrimaryPhones.find(phone => phone.phoneNumber === primaryPhone.phoneNumber)
          ?.phoneNumberType || DEFAULT_PHONE_NUMBER_TYPE;
    }
  }
  //Function for getting proper ID to send to API, instead of the given value/description
  const getSelectId = (value: string, prop: string, list: any[]) => {
    return list.length > 0 && !!value
      ? list.filter(item => item.description === value)[0][prop]
      : '';
  };
  const compileAccountDetailFormValues = (values: any) => {
    const formattedValues: IAccountDetailFormValues = {
      officeId: user?.officeId,
      accountName: values.accountName,
      firstName: values.firstName ?? '',
      lastName: values.lastName ?? '',
      accountClassDefId:
        accountClasses.find(accountClass => accountClass.description === values.class)
          ?.accountClassDefId ?? '',
      accountType: values.accountType,
      status: values.status ?? 'Active',
      phoneNumbers: values.phoneNumbers.flatMap(
        (phoneNumber: {
          value: string | null;
          accountPhoneNumberId: string;
          phoneNumberType: string;
        }) => {
          if (phoneNumber.value) {
            return {
              accountPhoneNumberId: phoneNumber?.accountPhoneNumberId,
              phoneNumberType: phoneNumber.phoneNumberType,
              phoneNumber: removeAreaCode(phoneNumber.value) ?? '',
              areaCode: getAreaCode(phoneNumber.value) ?? '',
              isPrimary: phoneNumber.phoneNumberType === values.primaryPhoneNumberType,
            };
          }
          return [];
        }
      ),
      emails: values.emails || [],
      accountCode: values.accountCode ?? '',
      dateStarted: values.dateStarted ?? '',
      dateTerminated: values.dateTerminated ?? '',
      referralInfo: values.referralInfo ?? '',
      referralTypeId:
        referralTypes.find(referralType => referralType.description === values.referralTypeId)
          ?.referralTypeId ?? '',
      accountAlertDefId: getSelectId(values.accountAlert, 'accountAlertDefId', accountAlerts),
      address: {
        addressName: values.addressName ?? '',
        street: values.street ?? '',
        city: values.city ?? '',
        state: values.state ?? '',
        postalCode: values.postalCode ?? '',
        latitude: values.latitude ?? pushPinLocation?.latitude ?? 0,
        longitude: values.longitude ?? pushPinLocation?.longitude ?? 0,
        whenVerified: values.whenVerified ?? '',
        manualLocation: values.manualLocation ?? false,
      },
      additionalInfo:
        additionalInfoFields && additionalInfoFields.length > 0
          ? additionalInfoFields.map(field => {
              const compareField =
                values.additionalInfo && values.additionalInfo.length > 0
                  ? values.additionalInfo.filter(
                      (item: { description: string | null | undefined }) =>
                        item?.description === field?.description
                    )
                  : null;

              return {
                userDefinedAccountDefId: field.userDefinedAccountDefId,
                value: !!compareField && compareField.length > 0 ? compareField[0]?.value : '',
              };
            })
          : null,
      printNoStatement:
        values.statementDelivery === 'print-only' || values.statementDelivery === 'email-and-print'
          ? false
          : true,
      billingGroupId: values.billingGroupId,
      emailStatement:
        values.statementDelivery === 'email-only' || values.statementDelivery === 'email-and-print'
          ? true
          : false,
      salesTaxId: values.salesTaxId,
      emailWhenServiceClosed: values.emailWhenServiceClosed ?? false,
      comments: values.comments ?? '',
      primaryPhoneNumberType: values.primaryPhoneNumberType,
      terminationReason: values.terminationReason ?? '',
      terminationTypeId: values.terminationTypeId ?? '',
      waterTestCustomerId: values.waterTestCustomerId ?? undefined,
    };
    return formattedValues;
  };
  const handleCreate = async (data: IAccountDetailFormValues) => {
    // Actions for creating a new customer. To be used in both onSubmit and duplicate customer check modal.
    const res: any = await createAccount(data);
    enqueueSnackbar('Successfully created a new customer account!', {
      variant: 'success',
    });
    setWaterTestAccount(null);
    history.push(`/customers/${res}?refresh=true`);
  };

  const [showDuplicateCustomersModal, setShowDuplicateCustomersModal] = useState<boolean>(false);
  const [duplicateCustomers, setDuplicateCustomers] = useState<IDuplicateAccount[]>([]);
  return (
    <Formik
      initialValues={{
        accountName:
          currentCustomer?.name ??
          waterTestAccount?.name ??
          generateDefaultName(currentCustomer ?? waterTestAccount),
        firstName: currentCustomer?.firstName ?? waterTestAccount?.firstName ?? '',
        lastName: currentCustomer?.lastName ?? waterTestAccount?.lastName ?? '',
        class: currentCustomer?.class ?? '',
        accountType: currentCustomer?.accountType?.replaceAll(' ', '') ?? 'Owner',
        status: currentCustomer?.status ?? 'Active',
        phoneNumbers: phoneTypes.map(phoneType => {
          const waterTestAccountPhoneNumberMatch = waterTestAccount?.phoneNumbers?.find(
            phoneNumber => phoneNumber.phoneNumberType === phoneType.description
          );
          if (!account && waterTestAccount && waterTestAccountPhoneNumberMatch) {
            return {
              phoneNumberType: phoneType.description,
              value:
                waterTestAccountPhoneNumberMatch?.phoneNumber &&
                waterTestAccountPhoneNumberMatch.areaCode
                  ? formatInputPhoneNumber(
                      `${waterTestAccountPhoneNumberMatch.areaCode}${waterTestAccountPhoneNumberMatch?.phoneNumber}`
                    )
                  : '',
            };
          }
          const accountPhoneNumberMatch = account?.phoneNumbers?.find(
            phoneNumber => phoneNumber.phoneNumberType === phoneType.description
          );
          return {
            accountPhoneNumberId: accountPhoneNumberMatch?.accountPhoneNumberId,
            phoneNumberType: phoneType.description,
            value: accountPhoneNumberMatch?.phoneNumber || '',
          };
        }),
        emails: currentCustomer?.emails
          ? currentCustomer.emails
          : !!waterTestAccount?.email
          ? [waterTestAccount.email]
          : undefined, // Empty string is not a valid email on backend
        dateStarted: currentCustomer?.dateStarted ?? format(new Date(), 'MM/dd/yyyy'),
        dateTerminated: currentCustomer?.dateTerminated ?? '',
        terminationReason: currentCustomer?.terminationInfo ?? '',
        terminationTypeId: currentCustomer?.terminationTypeId ?? '',
        referralInfo: currentCustomer?.referralInfo ?? '',
        referralTypeId: currentCustomer?.referralTypeId ?? '',
        accountAlert: currentCustomer?.accountAlert ?? '',
        addressName:
          currentCustomer?.address?.addressName ??
          generateDefaultName(currentCustomer ?? waterTestAccount),
        street: currentCustomer?.address?.street ?? waterTestAccount?.addressStreet1 ?? '',
        city: currentCustomer?.address?.city ?? waterTestAccount?.addressCity ?? '',
        state: currentCustomer?.address?.state ?? waterTestAccount?.addressState ?? '',
        postalCode:
          currentCustomer?.address?.postalCode ?? waterTestAccount?.addressPostalCode ?? '',
        latitude: currentCustomer?.address?.latitude ?? 0,
        longitude: currentCustomer?.address?.longitude ?? 0,
        whenVerified: currentCustomer?.address?.whenVerified ?? '',
        manualLocation: currentCustomer?.address?.manualLocation ?? false,
        additionalInfo: additionalInfoFields.map(field => {
          const compareField = currentCustomer?.additionalInfo
            ? currentCustomer?.additionalInfo.filter(item => item.description === field.description)
            : null;

          return {
            userDefinedAccountDefId: field.userDefinedAccountDefId,
            description: field.description,
            value: !!compareField && compareField.length > 0 ? compareField[0]?.value : '',
          };
        }),
        billingGroupId: currentCustomer?.billingGroupId ?? '',
        statementDelivery: getStatementDeliveryOption(currentCustomer),
        salesTaxId: currentCustomer?.salesTaxId ?? '',
        emailWhenServiceClosed: currentCustomer?.emailWhenServiceClosed ?? false,
        comments: currentCustomer?.comments ?? '',
        primaryPhoneNumberType,
        accountCode: currentCustomer?.accountCode ?? '',
        waterTestCustomerId:
          currentCustomer?.waterTestCustomerId ?? waterTestAccount?.customerId ?? undefined,
      }}
      enableReinitialize
      validationSchema={CustomerSchema}
      onSubmit={async (values, actions) => {
        try {
          // Stop submit if not primary phone set
          if (!values.primaryPhoneNumberType) {
            enqueueSnackbar('You must select a primary phone number type', {
              variant: 'warning',
            });
            return;
          }

          const primaryPhone = values.phoneNumbers.find(
            p => p.phoneNumberType === values.primaryPhoneNumberType
          );
          // Stop submit if primary phone set but no value provided
          if (!primaryPhone?.value) {
            enqueueSnackbar(
              `${values.primaryPhoneNumberType} was selected as the primary phone but it has no value`,
              {
                variant: 'warning',
              }
            );
            return;
          }

          const data: IAccountDetailFormValues = compileAccountDetailFormValues(values);

          const duplicateCheck: IDuplicateAccountCheck = {
            accountName: values.accountName,
            firstName: values.firstName ?? '',
            lastName: values.lastName ?? '',
            primaryPhoneNumber: primaryPhone?.value ?? '',
            emails: values.emails || [],
            accountStreet: values.street ?? '',
          };
          if (isNewCustomer) {
            // Check if customer already exists (if it's a duplicate)
            const res: any = await checkDuplicateAccounts(duplicateCheck, user?.officeId);
            if (res && res.length === 0) {
              // If duplicate check has no duplicates to display, create new customer as normal
              return await handleCreate(data);
            }
            if (!res || res.Detail) {
              // If duplicate check fails or errors, show a message and exit creation process
              return enqueueSnackbar(
                res.Detail || 'Error checking for duplicates, please try again',
                {
                  variant: 'error',
                }
              );
            }
            setDuplicateCustomers(res);
            setShowDuplicateCustomersModal(true);
          } else {
            // If isEditing, update account as normal
            await updateAccount(accountId, data);
            enqueueSnackbar('Customer Account Updated!', {
              variant: 'success',
            });
            history.push(`/customers/${accountId}?refresh=true`);
          }
        } catch (error: any) {
          enqueueSnackbar(
            error?.Detail ? error?.Detail : 'An error occurred. Please try again later.',
            {
              variant: 'error',
            }
          );
        }
      }}
    >
      {({
        resetForm,
        isSubmitting,
        values,
        initialValues,
        errors,
        touched,
        handleBlur,
        handleChange,
        setSubmitting,
      }) => {
        const reset = (resetForm: any) => {
          if (accountId === 'new') {
            return history.push(redirect ?? '/customers');
          }
          return history.push(`/customers/${accountId}`);
        };
        return (
          <>
            <ConfirmPrompt
              when={
                !deepEqual(initialValues, values) &&
                !isSubmitting &&
                !isLoadingAccount &&
                !showDuplicateCustomersModal
              }
              message={defaultUnsavedChangesMessage}
              onConfirm={() => {
                setWaterTestAccount(null);
              }}
            />
            <Form>
              {(isLoadingPhoneTypes || isLoadingAccount || isLoadingAccountTypes) && (
                <Loader position="centered" type="overlay" />
              )}
              {isSubmitting && <Loader position="centered" type="overlay" title="Saving..." />}
              {waterTestAccount && (
                <Alert severity="warning" sx={{ mb: 1 }}>
                  Confirm account data from Water Test for new Service Customer
                </Alert>
              )}
              <AccountDetailsFields
                currentCustomer={currentCustomer}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                isEditable={true}
                actualSpendingLimit={actualSpendingLimit}
                setActualSpendingLimit={setActualSpendingLimit}
                isNewCustomer={isNewCustomer}
                //Dropdown states
                accountClasses={accountClasses}
                setAccountClasses={setAccountClasses}
                referralTypes={referralTypes}
                setReferralTypes={setReferralTypes}
                accountAlerts={accountAlerts}
                setAccountAlerts={setAccountAlerts}
                billingGroups={billingGroups}
                setBillingGroups={setBillingGroups}
                salesTaxes={salesTaxes}
                setSalesTaxes={setSalesTaxes}
                accountTypes={accountTypes}
                setAccountTypes={setAccountTypes}
                terminationTypes={terminationTypes}
                setTerminationTypes={setTerminationTypes}
                pushPinLocation={pushPinLocation}
                setPushPinLocation={setPushPinLocation}
              />
              <FloatingToolbar>
                <Button
                  color="inherit"
                  disabled={
                    isLoadingPhoneTypes || isLoadingAccount || isLoadingAccountTypes || isSubmitting
                  }
                  onClick={() => reset(resetForm)}
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <SaveButton
                  disabled={
                    isSubmitting || isLoadingPhoneTypes || isLoadingAccount || isLoadingAccountTypes
                  }
                />
              </FloatingToolbar>
              <DuplicateCustomersModal
                open={showDuplicateCustomersModal}
                onClose={() => {
                  setDuplicateCustomers([]);
                  setShowDuplicateCustomersModal(false);
                }}
                onSubmit={async () => {
                  try {
                    setSubmitting(true);
                    await handleCreate(compileAccountDetailFormValues(values));
                  } catch (error: any) {
                    enqueueSnackbar(
                      error?.Detail ? error?.Detail : 'An error occurred. Please try again later.',
                      {
                        variant: 'error',
                      }
                    );
                  } finally {
                    setSubmitting(false);
                  }
                }}
                duplicateCustomers={duplicateCustomers}
                isSubmitting={isSubmitting}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
