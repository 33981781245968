import { Autocomplete, TextField, Box } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { UserContext } from '../../context';
import { IServiceLocation } from '../../models';
import { getServiceLocations } from '../../fetch';
import { useQuery } from 'react-query';
import { formatAddressSingleLine } from '../../helpers';

interface IServiceLocationAutocomplete {
  setSelectedServiceLocation: (val: IServiceLocation | null) => void;
  isDisabled?: boolean;
  handleOptions?: (val: IServiceLocation[]) => void;
  selectedServiceLocation: IServiceLocation | null;
  hasError?: boolean;
  helperText?: string;
  handleBlur?: (e: any) => void;
  isRequired?: boolean;
  labelText?: string;
  placeholder?: string;
  shouldRefetch?: boolean;
}

export const ServiceLocationAutocomplete: FC<IServiceLocationAutocomplete> = ({
  isDisabled,
  handleOptions,
  hasError,
  helperText,
  selectedServiceLocation,
  setSelectedServiceLocation,
  handleBlur,
  isRequired,
  labelText,
  shouldRefetch,
  placeholder,
}) => {
  const { user } = useContext(UserContext);

  const {
    isLoading: isLoadingServiceLocations,
    data: locations,
    refetch,
  } = useQuery(
    ['getServiceLocations', user],
    async () => {
      const res = await getServiceLocations({ officeId: user?.officeId, perPage: -1 });
      return res.records;
    },
    {
      notifyOnChangeProps: 'tracked',
      onSuccess: options => {
        handleOptions?.(options);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      <Autocomplete
        fullWidth
        value={selectedServiceLocation}
        disabled={isLoadingServiceLocations || isDisabled}
        onChange={(e, newValue: IServiceLocation | null) => {
          setSelectedServiceLocation(newValue);
        }}
        onBlur={handleBlur}
        options={locations || []}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.addressName} (${formatAddressSingleLine(
            option?.street,
            option?.city,
            option?.state,
            option?.postalCode
          )})`;
        }}
        renderOption={(props: any, option: IServiceLocation) => {
          return (
            <li {...props} key={option.serviceLocationId}>
              {option.addressName} (
              {formatAddressSingleLine(
                option?.street,
                option?.city,
                option?.state,
                option?.postalCode
              )}
              )
            </li>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              size="small"
              label={isLoadingServiceLocations ? 'Loading...' : labelText ?? 'Service Location'}
              required={isRequired ?? true}
              fullWidth
              autoComplete="none"
              variant="outlined"
              placeholder={placeholder ?? 'Select a service location'}
              error={hasError}
              helperText={helperText}
              name="serviceLocationId"
              title={`${selectedServiceLocation?.addressName} (${formatAddressSingleLine(
                selectedServiceLocation?.street,
                selectedServiceLocation?.city,
                selectedServiceLocation?.state,
                selectedServiceLocation?.postalCode
              )})`}
            />
          );
        }}
      />
    </Box>
  );
};
