import { FC, useEffect, useState } from 'react';
import { getGeneratedReport } from '../../fetch';
import { useSnackbar } from 'notistack';

let buildConfig = require('../../buildSettings');

interface ReportPdfPreviewProps {
  reportGuid: string;
}

const blobToDataUrl = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) {
        reject(new Error('Failed to read blob'));
        return;
      }
      // Cast safe in this case since readAsDataURL guarantees a base 64 string
      resolve(e.target.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const ReportPdfPreview: FC<ReportPdfPreviewProps> = ({ reportGuid }) => {
  const [base64DataUrl, setBase64DataUrl] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const fileUrl = `${buildConfig.REACT_APP_API_URL}/api/reports/generated/${reportGuid}`;

  useEffect(() => {
    getGeneratedReport(reportGuid)
      .then((blob) => blobToDataUrl(blob))
      .then((dataUrl) => {
        setBase64DataUrl(dataUrl);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('An error occurred while displaying your report', {
          variant: 'error',
        });
      });
  }, [enqueueSnackbar, fileUrl, reportGuid]);

  if (!base64DataUrl) {
    return null;
  }

  return (
    <div style={{ height: 950 }}>
      <object data={base64DataUrl} type="application/pdf" width="100%" height="100%">
        <embed src={base64DataUrl} type="application/pdf" />
        <p>This browser does not support PDFs. Please download the PDF to view it: <a href={fileUrl}>Download PDF</a>.
        </p>
      </object>
    </div>
  );
};