import { Box, Divider } from '@mui/material';
import { FC } from 'react';

export const TableLegend: FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <Divider />
      <Box
        mt={1}
        mb={1}
        sx={{
          overflowX: 'auto',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{
            xs: 'flex-start',
            lg: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
      <Divider />
    </>
  );
};
