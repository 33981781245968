import { GridSortModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { createContext, FC, useCallback, useContext } from 'react';
import { GridDataFetcher, useDataGrid, useFilters } from '../components';
import { getCommissions, getCommissionsFilters } from '../fetch';
import { ICommission, IFilter } from '../models';
import { UserContext } from './user';

interface ICommissionsListProps {
  isShowingFilters: boolean;
  filtersInitialized: boolean;
  appliedFilters: Record<string, string[]>;
  filters: IFilter[];
  filterValues: Record<string, string[]>;
  onFilterToggle: () => void;
  onSubmitFilters: (values: Record<string, string[]>) => void;
  onFiltersChange: (values: Record<string, string[]>) => void;
  onReset: (values: Record<string, string[]>) => void;
  rows: ICommission[];
  isLoading: boolean;
  page: number;
  perPage: number;
  recordCount: number;
  sortModel: GridSortModel;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  refetch: () => Promise<void>;
}

export const CommissionsListContext = createContext<ICommissionsListProps>({
  isShowingFilters: false,
  filtersInitialized: false,
  appliedFilters: {},
  filters: [],
  filterValues: {},
  onFilterToggle: () => {},
  onSubmitFilters: () => {},
  onFiltersChange: () => {},
  onReset: () => {},
  rows: [],
  isLoading: false,
  page: 1,
  perPage: 10,
  recordCount: Number(),
  sortModel: [],
  onPageChange: () => {},
  onPageSizeChange: () => {},
  onSortModelChange: () => {},
  refetch: async () => {},
});

interface ICommissionsListContextHandlerProps {}

export const CommissionsListContextHandler: FC<ICommissionsListContextHandlerProps> = ({
  children,
}): JSX.Element => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getCommissionsFilters(), []),
  });

  const dataFetcher: GridDataFetcher<ICommission> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        if (!filtersInitialized) {
          return {
            continueLoading: true,
          };
        }
        const res = await getCommissions({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
          filters: appliedFilters,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading commissions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommission>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'commission-grid',
      sortColumn: 'commissionDate',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  return (
    <CommissionsListContext.Provider
      value={{
        isShowingFilters,
        filtersInitialized,
        appliedFilters,
        filters,
        filterValues,
        onFilterToggle,
        onSubmitFilters: values => {
          // reset the pagination to 0 page so we get results
          onPageChange(0);
          onSubmitFilters(values);
        },
        onFiltersChange,
        onReset,
        rows,
        isLoading: isLoading,
        page,
        perPage,
        recordCount,
        sortModel,
        onPageChange,
        onPageSizeChange,
        onSortModelChange,
        refetch,
      }}
    >
      {children}
    </CommissionsListContext.Provider>
  );
};
