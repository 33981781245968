import { FC, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import {
  Box,
  Fade,
  Typography,
  Grid,
  Divider,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Chip,
} from '@mui/material';

// fetch
import { updateSalesTaxGroup, createSalesTaxGroup } from '../../../fetch';
import { ISalesTax, ISalesTaxGroup } from '../../../models';

interface IAddEditSalesTaxGroupModal {
  open: boolean;
  onClose: () => void;
  currentSalesTaxGroup?: ISalesTaxGroup | null;
  currentSalesTaxes?: ISalesTax[] | [];
  initialMembers: string[];
  fetchSalesTaxGroups: () => void;
}

const Schema = Yup.object().shape({
  salesTaxGroupId: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export const AddEditSalesTaxGroupModal: FC<IAddEditSalesTaxGroupModal> = ({
  open,
  onClose,
  currentSalesTaxGroup,
  currentSalesTaxes,
  initialMembers,
  fetchSalesTaxGroups,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const handleMemberChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    setSelectedMembers(value as string[]);
  };

  const isDirty = () => {
    if (selectedMembers.length === 0 && initialMembers.length === 0) {
      return false;
    }

    if (selectedMembers.length !== initialMembers.length) {
      return true;
    }

    for (let item of selectedMembers) {
      if (initialMembers.indexOf(item) < 0) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setSelectedMembers(initialMembers);
  }, [setSelectedMembers, initialMembers]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          salesTaxGroupId: currentSalesTaxGroup?.salesTaxGroupId ?? '',
          officeId: user?.officeId ?? '',
          code: currentSalesTaxGroup?.code ?? '',
          description: currentSalesTaxGroup?.description ?? '',
          salesTaxMembers: currentSalesTaxGroup?.salesTaxMembers ?? [],
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          values.salesTaxMembers = selectedMembers;
          if (currentSalesTaxGroup) {
            currentSalesTaxGroup.salesTaxMembers = selectedMembers;
          }
          const data = {
            ...values,
          };
          try {
            currentSalesTaxGroup
              ? await updateSalesTaxGroup(data)
              : await createSalesTaxGroup(data);
            enqueueSnackbar(
              currentSalesTaxGroup
                ? 'Successfully updated sales tax group!'
                : 'Successfully created sales tax group!',
              {
                variant: 'success',
              }
            );
            onClose();
            setSelectedMembers([]);
            fetchSalesTaxGroups();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving sales tax group, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                setSelectedMembers([]);
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentSalesTaxGroup ? 'Edit Sales Tax Group' : 'Add Sales Tax Group'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                          <InputLabel id="lblSalesTaxes">Sales Tax Members</InputLabel>
                          <Select
                            multiple
                            name="salesTaxMembers"
                            labelId="lblSalesTaxes"
                            input={<OutlinedInput label="Sales Tax Members" />}
                            value={selectedMembers}
                            renderValue={selected => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map(val => (
                                  <Chip key={val} label={val} />
                                ))}
                              </Box>
                            )}
                            onChange={handleMemberChange}
                          >
                            {currentSalesTaxes?.map(taxItem => (
                              <MenuItem key={taxItem.salesTaxId} value={`${taxItem.code}`}>
                                {taxItem.code} ({taxItem.description})
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        setSelectedMembers([]);
                        resetForm();
                      }}
                      isSaveDisabled={!(dirty || isDirty()) || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
