import { FormControl, Box } from '@mui/material';

export const PasswordSecurity = () => {
  return (
    <FormControl fullWidth={true} size="small">
      <Box
        component="ul"
        sx={{
          fontSize: '0.875rem',
          margin: 0,
          padding: '0 0 0 1rem',
        }}
      >
        <li>Must be at least 12 characters long</li>
        <li>Must not be more than 60 characters long</li>
        <li>Must have at least one upper-case letter</li>
        <li>Must have at least one lower-case letter</li>
        <li>Must have at least one numeric character</li>
        <li>Must have at least one &quot;special&quot; character, ex: !$#@%</li>
      </Box>
    </FormControl>
  );
};
