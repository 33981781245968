import { useHistory, useLocation } from 'react-router-dom';
import { SearchField } from '../inputs';
import { FC, useContext, useState } from 'react';
import { SearchContext } from '../../context';

interface IGlobalSearchProps {
  v2Customers: boolean;
}

export const GlobalSearch: FC<IGlobalSearchProps> = ({ v2Customers }) => {
  const { searchValue, setSearchValue, setPage, initialPage } = useContext(SearchContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const [searchBoxValue, setSearchBoxValue] = useState<string>(query ?? '');
  return (
    <>
      {v2Customers && (
        <SearchField
          handleSearch={val => history.push(`/search?query=${searchValue}`)}
          setSearchValue={val => {
            setSearchValue(val);
            setSearchBoxValue(val);
            // Reset pagination when changing the search term
            setPage(initialPage);
          }}
          handleClearSearch={() => {
            setSearchBoxValue('');
          }}
          searchValue={searchBoxValue}
          placeholder="Find All..."
        />
      )}
    </>
  );
};
