export function getPathAndSearch(location: Location): string {
  const path = location.pathname.startsWith('/') ? location.pathname : `/${location.pathname}`;
  const searchParams = new URLSearchParams(location.search);
  const searchParamsString = searchParams.toString();
  return `${path}${searchParamsString ? `?${searchParamsString}` : ''}`;
}

export function getCurrentPathAndSearch(): string {
  return getPathAndSearch(window.location);
}
