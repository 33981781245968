import { FC, useCallback, useContext, useState } from 'react';
import { Card, CardTitle, GridDataFetcher, Loader, useDataGrid } from '../../../components';
import { IServiceLocation } from '../../../models/service-locations';
import { deleteServiceLocation, getServiceLocations } from '../../../fetch/service-locations';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { ServiceLocationsDataGrid } from './service-locations-data-grid';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ServiceLocationModal } from './service-location-modal';

interface IServiceLocationsListProps {}

export const ServiceLocationsList: FC<IServiceLocationsListProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const [isDeleting, setDeleting] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState<IServiceLocation>();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

  const dataFetcher: GridDataFetcher<IServiceLocation> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      const params = {
        sortDirection: sortDirection || 'Desc',
        sortBy: sortColumn || 'whenCreated',
        perPage,
        page: page + 1,
        officeId: user?.officeId as string,
      };
      try {
        const res = await getServiceLocations(params);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading service locations, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageSizeChange,
    onSortModelChange,
    onPageChange,
    refetch: fetchServiceLocations,
  } = useDataGrid<IServiceLocation>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'name',
      sortDirection: 'desc',
      gridKeyName: 'service-location-grid',
    },
    dataFetcher,
  });

  const handleEdit = async (serviceLocation: IServiceLocation) => {
    setSelectedServiceLocation(serviceLocation);
    setIsShowingModal(true);
  };

  const handleClose = (shouldReload?: boolean) => {
    setIsShowingModal(false);
    setSelectedServiceLocation(undefined);
    if (shouldReload) {
      fetchServiceLocations();
    }
  };

  const handleDelete = async (serviceLocationId: string) => {
    const result = await confirm('Are you sure you want to delete this service location?');
    if (result) {
      try {
        setDeleting(true);
        await deleteServiceLocation(serviceLocationId);
        enqueueSnackbar('Service location deleted!', {
          variant: 'success',
        });
        fetchServiceLocations();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? 'An error occurred while deleting your service location', {
          variant: 'error',
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          {isDeleting && <Loader position="centered" type="overlay" title="Deleting..." />}
          <CardTitle
            title="Service Locations"
            cardTitleWrapperClassName={classes.cardTitle}
            mobileWrap
            action={
              <>
                <Button
                  onClick={() => {
                    setSelectedServiceLocation(undefined);
                    setIsShowingModal(true);
                  }}
                  color="secondary"
                  size="small"
                  disabled={isLoading}
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                >
                  Add Service Location
                </Button>
              </>
            }
          ></CardTitle>
          <Box marginTop="1rem">
            <ServiceLocationsDataGrid
              rows={rows}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              loading={isLoading}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              refetch={fetchServiceLocations}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </Box>
        </Card>
      </Box>
      <ServiceLocationModal
        isOpen={isShowingModal}
        handleClose={handleClose}
        serviceLocation={selectedServiceLocation}
        isLoading={isLoading}
      />
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  cardTitle: { marginBottom: 0 },
  textIconSpace: {
    marginLeft: theme.spacing(1),
  },
  filtersButton: {
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
    '&:disabled': {
      color: 'black',
      backgroundColor: theme.palette.grey[400],
      borderColor: theme.palette.grey[400],
    },
  },
  filtersWrapper: {
    [theme.breakpoints.up('sm')]: {
      '& [role=radiogroup]': {
        flexDirection: 'row',
      },
    },
  },
}));
