import { FC, useState } from 'react';
import { Loader, Modal } from '../../components';
import { Fade, Box, Button } from '@mui/material';
import { IAccountDetail, ISiteGetResponse } from '../../models';
import { SiteDetailCRUDView } from './site-detail-crud-view';
import { useQuery } from 'react-query';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getSite, getAccount } from '../../fetch';

interface IEditSiteModal {
  isModalOpen: boolean;
  handleModalClose: () => void;
  siteId: string;
  accountId: string;
}

export const EditSiteModal: FC<IEditSiteModal> = ({
  isModalOpen,
  handleModalClose,
  accountId,
  siteId,
}) => {
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);
  const {
    isLoading: isLoadingSite,
    data: site,
    refetch: fetchSite,
  } = useQuery<ISiteGetResponse, Error>(['getSite', siteId], () => getSite(siteId!), {
    enabled: !!siteId,
  });

  const { isLoading: isLoadingCustomer, data: currentCustomer } = useQuery<IAccountDetail, Error>(
    ['getAccount', accountId],
    () => getAccount(accountId!),
    {
      enabled: !!accountId,
    }
  );

  return (
    <Modal open={isModalOpen!} onClose={handleModalClose!} maxWidth="lg" title="Edit Site">
      <Fade in={isModalOpen!}>
        <Box mt={2} mb={4}>
          <Box
            mb={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="print--none"
          >
            <Button
              variant="text"
              onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
              startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
            >
              {isAllExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
          </Box>
          {(isLoadingSite || isLoadingCustomer) && <Loader type="overlay" />}
          {!isLoadingSite && !isLoadingCustomer && (
            <SiteDetailCRUDView
              accountId={accountId}
              siteId={site?.siteId!}
              site={site as ISiteGetResponse}
              fetchSite={() => fetchSite()}
              accountAddress={currentCustomer?.address!}
              isAllExpanded={isAllExpanded}
              currentCustomer={currentCustomer!}
              isLoading={isLoadingSite || isLoadingCustomer}
              isModal
              handleModalClose={handleModalClose!}
            />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
