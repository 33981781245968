import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../components';
import { getPoolEquipmentAlgaeTypes } from '../../../fetch';
import { IDropdownResponse } from '../../../models';
import { unCamelCase } from '../../../helpers';
import { getYear } from 'date-fns';

export const StructureDetailsDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the StructureDetailsForm component as well
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={0} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Shape" labelId="appearance">
          {values?.appearance ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Gallons" labelId="poolVolume">
          {values?.poolVolume ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Finish" labelId="surface">
          {values?.surface ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Pool Type" labelId="poolType">
          {values?.poolType ? unCamelCase(values?.poolType) : ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Sanitizer" labelId="sanitizer">
          {values?.sanitizer ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Salt Chlorinator" labelId="saltChlorinator">
          {values?.saltChlorinator ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup
          label="Algae Types"
          labelId="algaeType"
          isDropdownResponse
          dropdownValue={values.algaeType}
          apiRequest={getPoolEquipmentAlgaeTypes}
          transformResponse={(response: IDropdownResponse[]) => {
            return response.map(record => ({
              label: record.description ?? '',
              value: record.value ?? '',
            }));
          }}
        >
          {values?.algaeType ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Stains" labelId="stains">
          {values?.stains ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Pool Finish Condition" labelId="poolFinish">
          {values?.poolFinish ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="When Last Resurfaced" labelId="lastResurfaced">
          {values?.lastResurfaced ? getYear(new Date(values.lastResurfaced)) : ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Year Pool Built" labelId="poolBuilt">
          {values?.poolBuilt ? getYear(new Date(values.poolBuilt)) : ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Spa" labelId="hasSpa">
          {values?.hasSpa ? 'Yes' : 'No'}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
