import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import clsx from 'clsx';
import { ICalendarDateRange, ICalendarView } from '../../../models';
import { Box, IconButton, Popover, styled, useMediaQuery } from '@mui/material';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import {
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  subWeeks,
  addWeeks,
  addDays,
  addMonths,
  subDays,
  subMonths,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { createDateRangeLabel, setStartOfWeek, setEndOfWeek } from '../../../helpers';

interface DateRangeNavigatorProps {
  view: ICalendarView;
  dateRange: ICalendarDateRange;
  onChange?: (dateRange: ICalendarDateRange) => unknown;
  color?: string;
  willHandleDateChange?: boolean;
  hasBorder?: boolean;
  hasContainerPadding?: boolean;
}

interface CustomPickerDayProps extends PickersDayProps<Date> {
  dateRange: ICalendarDateRange;
  startDateWithoutTime: Date;
  endDateWithoutTime: Date;
}

interface IDateRangeStyleProps {
  color?: string;
  hasContainerPadding?: boolean;
  hasBorder?: boolean;
}

export const DateRangeNavigator: FC<DateRangeNavigatorProps> = ({
  view,
  dateRange,
  onChange,
  color,
  willHandleDateChange,
  hasBorder,
  hasContainerPadding = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentMonth, setCurrentMonth] = useState<Date>(startOfMonth(new Date()));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(`(max-width: 567px)`);

  const dateRangeLabel = createDateRangeLabel(dateRange.startDate, dateRange.endDate, isMobile);
  const startDateWithoutTime = startOfDay(dateRange.startDate);
  const endDateWithoutTime = startOfDay(dateRange.endDate);

  const handleChange = (dateSelected: Date | null) => {
    if (!onChange || !dateSelected) {
      return;
    }

    let newDateRange: ICalendarDateRange | undefined;

    switch (view) {
      case ICalendarView.Day:
        const dayStart = startOfDay(dateSelected);
        newDateRange = {
          startDate: dayStart,
          endDate: endOfDay(dayStart),
        };
        break;
      case ICalendarView.WorkWeek:
        const workWeekStart = setStartOfWeek(dateSelected);
        newDateRange = {
          startDate: workWeekStart,
          endDate: addDays(workWeekStart, 4),
        };
        break;
      case ICalendarView.Week:
        if (willHandleDateChange) {
          newDateRange = {
            startDate: startOfDay(dateSelected),
            endDate: endOfDay(dateSelected),
          };
        } else {
          const weekStart = setStartOfWeek(dateSelected);
          newDateRange = {
            startDate: weekStart,
            endDate: setEndOfWeek(weekStart),
          };
        }
        break;
      case ICalendarView.Month:
        const monthStart = setStartOfWeek(startOfMonth(dateSelected));
        const monthEnd = setEndOfWeek(endOfMonth(dateSelected));
        newDateRange = {
          startDate: monthStart,
          endDate: monthEnd,
        };
        break;
    }

    if (!newDateRange) {
      return;
    }

    onChange(newDateRange);
    handleClose();
  };

  const onPrevious = () => {
    if (!onChange) {
      return;
    }
    let newDateRange: ICalendarDateRange | undefined;
    if (view === ICalendarView.Day) {
      const dayStart = subDays(dateRange.startDate, 1);
      newDateRange = {
        startDate: dayStart,
        endDate: endOfDay(dayStart),
      };
    }
    if (view === ICalendarView.Month) {
      const month = subMonths(currentMonth, 1);
      setCurrentMonth(month);
      const monthStart = setStartOfWeek(month);
      const monthEnd = setEndOfWeek(endOfMonth(month));
      newDateRange = {
        startDate: monthStart,
        endDate: monthEnd,
      };
    }
    if (view === ICalendarView.Week) {
      const weekStart = subWeeks(dateRange.startDate, 1);
      newDateRange = {
        startDate: weekStart,
        endDate: setEndOfWeek(weekStart),
      };
    }
    if (view === ICalendarView.WorkWeek) {
      const weekStart = subWeeks(dateRange.startDate, 1);
      newDateRange = {
        startDate: weekStart,
        endDate: addDays(weekStart, 4),
      };
    }

    if (!newDateRange) {
      return;
    }

    onChange(newDateRange);
  };

  const onNext = () => {
    if (!onChange) {
      return;
    }

    let newDateRange: ICalendarDateRange | undefined;
    if (view === ICalendarView.Day) {
      const dayStart = addDays(dateRange.startDate, 1);
      newDateRange = {
        startDate: dayStart,
        endDate: endOfDay(dayStart),
      };
    }
    if (view === ICalendarView.Month) {
      const month = addMonths(currentMonth, 1);
      setCurrentMonth(month);
      const monthStart = setStartOfWeek(month);
      const monthEnd = setEndOfWeek(endOfMonth(month));
      newDateRange = {
        startDate: monthStart,
        endDate: monthEnd,
      };
    }
    if (view === ICalendarView.Week) {
      const weekStart = addWeeks(dateRange.startDate, 1);
      newDateRange = {
        startDate: weekStart,
        endDate: setEndOfWeek(weekStart),
      };
    }
    if (view === ICalendarView.WorkWeek) {
      const weekStart = addWeeks(dateRange.startDate, 1);
      newDateRange = {
        startDate: weekStart,
        endDate: addDays(weekStart, 4),
      };
    }

    if (!newDateRange) {
      return;
    }

    onChange(newDateRange);
  };

  const shouldDisableDate = (date: Date) => {
    if (view === ICalendarView.WorkWeek) {
      return date.getDay() === 0 || date.getDay() === 6;
    }
    return false;
  };

  return (
    <CalendarSelectionWrapper
      className={classes.calendarSelectionContainer}
      color={color}
      hasContainerPadding={hasContainerPadding}
      hasBorder={hasBorder}
    >
      <Box display="flex" gap={{ xs: 4, sm: 2 }} alignItems={'center'}>
        <IconButton onClick={onPrevious} className={clsx(classes.calendarButtons, 'print--none')}>
          <FontAwesomeIcon icon={faChevronLeft} className={classes.calendarIcons} />
        </IconButton>

        <Typography>{dateRangeLabel}</Typography>
        <IconButton onClick={onNext} className={clsx(classes.calendarButtons, 'print--none')}>
          <FontAwesomeIcon icon={faChevronRight} className={classes.calendarIcons} />
        </IconButton>
      </Box>
      <div>
        <IconButton onClick={handleClick} className={clsx(classes.datePickerButton, 'print--none')}>
          <FontAwesomeIcon icon={faCalendar} className={classes.calendarIcons} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={dateRange.startDate}
            onAccept={handleChange}
            slotProps={{
              day: {
                endDateWithoutTime,
                startDateWithoutTime,
                dateRange,
              } as any,
            }}
            slots={{
              day: CustomPickersDay,
            }}
            shouldDisableDate={shouldDisableDate}
            views={['year', 'month', 'day']}
            data-testid="day-picker"
          />
        </Popover>
      </div>
    </CalendarSelectionWrapper>
  );
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dateWithoutTime' &&
    prop !== 'dateRange' &&
    prop !== 'startDateWithoutTime' &&
    prop !== 'endDateWithoutTime',
})<CustomPickerDayProps>(
  ({ theme, dateRange, startDateWithoutTime, endDateWithoutTime, ...props }) => {
    const dateWithoutTime = startOfDay(props.day);
    const dayIsBetween =
      isAfter(dateWithoutTime, dateRange.startDate) && isBefore(dateWithoutTime, dateRange.endDate);
    const isFirstDay = isEqual(dateWithoutTime, startDateWithoutTime);
    const isLastDay = isEqual(dateWithoutTime, endDateWithoutTime);
    return {
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: 0,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
      ...(isFirstDay && {
        borderRadius: 0,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        margin: 0,
      }),
      ...(isLastDay && {
        borderRadius: 0,
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        margin: 0,
      }),
    };
  }
) as any;

const PREFIX = 'DateRangeNavigator';

const classes = {
  calendarSelectionContainer: `${PREFIX}-calendarSelectionContainer`,
  datePickerButton: `${PREFIX}-datePickerButton`,
  calendarButtons: `${PREFIX}-calendarButtons`,
  calendarIcons: `${PREFIX}-calendarIcons`,
};

const CalendarSelectionWrapper = styled('div')<IDateRangeStyleProps>(
  ({ theme, color, hasContainerPadding, hasBorder }) => ({
    [`&.${classes.calendarSelectionContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: color ?? theme.palette.primary.main,
      textAlign: 'center',
      '@media print': {
        paddingLeft: 0,
      },
      padding: hasContainerPadding || hasBorder ? '6px' : 0,
      border: hasBorder ? `1px solid #c4c4c4` : 'none',
      borderRadius: hasBorder ? theme.shape.borderRadius : 0,
    },

    [`& .${classes.datePickerButton}`]: {
      color: color ?? theme.palette.primary.main,
      marginBottom: 1,
    },

    [`& .${classes.calendarButtons}`]: {
      color: color ?? theme.palette.primary.main,
      marginBottom: 1,
    },

    [`& .${classes.calendarIcons}`]: {
      fontSize: '1rem',
    },
  })
);
