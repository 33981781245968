import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider, FormControlLabel, Checkbox } from '@mui/material';

// fetch
import { updateTranCode, createTranCode } from '../../../fetch';
import { ITranCode } from '../../../models';
import { convertToNumber } from '../../../helpers';

interface IAddEditTranCodeModal {
  open: boolean;
  onClose: () => void;
  currentTranCode?: ITranCode | null;
  fetchTranCodes: () => void;
}

const Schema = Yup.object().shape({
  tranCodeId: Yup.string(),
  defaultAmount: Yup.number(),
  isCredit: Yup.boolean(),
  isRevenue: Yup.boolean(),
  isTaxable: Yup.boolean(),
  isSummarized: Yup.boolean(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  sortOrder: Yup.number().required('Required'),
});

export const AddEditTranCodeModal: FC<IAddEditTranCodeModal> = ({
  open,
  onClose,
  currentTranCode,
  fetchTranCodes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          tranCodeId: currentTranCode?.tranCodeId ?? '',
          officeId: user?.officeId ?? '',
          code: currentTranCode?.code ?? '',
          description: currentTranCode?.description ?? '',
          sortOrder: currentTranCode?.sortOrder ? currentTranCode?.sortOrder.toString() : '0',
          isCredit: currentTranCode?.isCredit ?? false,
          isRevenue: currentTranCode?.isRevenue ?? false,
          isTaxable: currentTranCode?.isTaxable ?? false,
          isSummarized: currentTranCode?.isSummarized ?? false,
          defaultAmount: currentTranCode?.defaultAmount ? Number(currentTranCode?.defaultAmount).toFixed(2) : '0.00'
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: ITranCode = {
            tranCodeId: values.tranCodeId,
            officeId: values.officeId,
            code: values.code,
            description: values.description,
            isCredit: values.isCredit,
            isRevenue: values.isRevenue,
            isTaxable: values.isTaxable,
            isSummarized: values.isSummarized,
            sortOrder: convertToNumber(values.sortOrder),
            defaultAmount: convertToNumber(values.defaultAmount)
          };
          try {
            currentTranCode
              ? await updateTranCode(data)
              : await createTranCode(data);
            enqueueSnackbar(
              currentTranCode
                ? 'Successfully updated transaction code!'
                : 'Successfully created transaction code!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchTranCodes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving transaction codes, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          handleChange,
          dirty,
          isValid,
          values
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentTranCode ? 'Edit Transaction Code' : 'Add Transaction Code'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number"
                        inputProps={{ min: 0, max: 2147483647}}
                        value={values.sortOrder}
                        required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="defaultAmount" label="Default Amount" type="number"
                        inputProps={{ min: 0, max: 10000000000, step: 0.01 }}
                        value={Number(values.defaultAmount).toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isCredit} onChange={handleChange} name="isCredit" />
                          }
                          label="Credit"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isRevenue} onChange={handleChange} name="isRevenue" />
                          }
                          label="Revenue"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isTaxable} onChange={handleChange} name="isTaxable" />
                          }
                          label="Taxable"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isSummarized} onChange={handleChange} name="isSummarized" />
                          }
                          label="Summarized on Statement"
                        />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
