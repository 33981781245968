import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '../../components';
import { formatMoney } from '../../helpers';
import { ITransaction } from '../../models';

export const transactionGridColumns: GridColDef[] = [
  {
    field: 'tranCodeDescription',
    headerName: 'Transaction Type',
    flex: 1,
  },
  {
    field: 'accountName',
    headerName: 'Customer',
    flex: 1,
    renderCell: (params: GridRenderCellParams<ITransaction>) => {
      const { row: transaction } = params;
      return (
        <>
          {transaction.accountId ? (
            <Link to={`/customers/${transaction?.accountId}`}>{transaction.accountName}</Link>
          ) : (
            transaction.accountName
          )}
        </>
      );
    },
  },
  {
    field: 'accountCode',
    headerName: 'Code',
    flex: 1,
  },
  {
    field: 'reference',
    headerName: 'Check #',
    flex: 1,
    editable: true,
  },
  {
    field: 'transactionAmount',
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    editable: true,
    renderCell: (params: GridRenderCellParams<ITransaction>) => {
      const { row: transaction } = params;
      return formatMoney(transaction.transactionAmount, 2);
    },
  },
];
