import { IAccount, IAccountAvailableColumn } from '../../models';
import { camel } from 'case';
import { FC, useMemo } from 'react';
import { customerColumns } from './customer-columns';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../components';

interface CustomersDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IAccount[];
  availableColumns: IAccountAvailableColumn[];
}

export const CustomersDataGrid: FC<CustomersDataGridProps> = ({
  rows,
  availableColumns,
  ...gridProps
}) => {
  const columns = useMemo(
    () => {
      const availableColumnsHashTable: Record<string, IAccountAvailableColumn> = {};
      availableColumns.forEach(ac => {
        const fieldName = camel(ac.column);
        availableColumnsHashTable[fieldName] = ac;
      });
      return customerColumns
        .filter(c => availableColumnsHashTable[c.field])
        .sort((a, b) => {
          const availableA = availableColumnsHashTable[a.field];
          const availableB = availableColumnsHashTable[b.field];
          return availableA.sortOrder - availableB.sortOrder;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, availableColumns]
  );

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IAccount) => row.accountId}
      rows={rows}
      columns={columns}
      hasMobileLayout
      mobileProps={{
        mobileDefaultAccessor: 'accountName',
      }}
      {...gridProps}
    />
  );
};
