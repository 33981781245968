import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  DisplayGroup,
  Loader,
  ServerSideDataGrid,
  useDataGrid,
  GridDataFetcher,
  Card,
  Link,
} from '../../components';
import { ITransaction } from '../../models';
import { getTransactionBatch, getTransactions } from '../../fetch';
import { Divider, Grid, Box, Tooltip, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, formatMoney, formatShortFriendlyDateWithTime } from '../../helpers';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TransactionBatchDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { transactionBatchId }: { transactionBatchId: string } = useParams();
  const { isLoading: isLoadingBatch, data: batch } = useQuery(
    'transactionBatch',
    () => getTransactionBatch(transactionBatchId),
    {
      onError: (d: any) => {
        history.push('/payments');
        enqueueSnackbar(d?.Detail ?? 'An error occurred while retrieving this transaction batch', {
          variant: 'error',
        });
      },
    }
  );

  const dataFetcher: GridDataFetcher<ITransaction> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const res = await getTransactions({
          transactionBatchId: transactionBatchId,
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'whenCreated',
          perPage,
          page: page + 1,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading transactions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionBatchId]
  );

  const {
    refetch: refetchTransactions,
    isLoading: isLoadingTransactions,
    ...gridProps
  } = useDataGrid<ITransaction>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'payments-grid',
      sortColumn: 'transactionId',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const transactionGridColumns: GridColDef[] = [
    {
      field: 'accountName',
      headerName: 'Customer',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ITransaction>) => {
        const { row: transaction } = params;
        return (
          <>
            {transaction.accountId ? (
              <Link to={`/customers/${transaction?.accountId}`}>{transaction.accountName}</Link>
            ) : (
              transaction.accountName
            )}
          </>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ITransaction>) => {
        return params?.row?.whenPosted ? formatDate(params.row.whenPosted) : '';
      },
    },
    {
      field: 'reference',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'transactionAmount',
      headerName: 'Amount',
      type: 'number',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ITransaction>) => {
        const { row: transaction } = params;
        return formatMoney(transaction?.transactionAmount);
      },
    },
    {
      field: 'actions',
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true,
      headerName: '',
      renderCell: (params: GridRenderCellParams<ITransaction>) => {
        const { row: transaction } = params;
        return (
          transaction && (
            <Box display="flex" width="100%" justifyContent="center">
              <Tooltip title="View Transaction" placement="bottom">
                <span>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      return history.push(
                        `/billing/transactions/${transaction.transactionId}?redirect=/payments/online/${transactionBatchId}`
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )
        );
      },
    },
  ].filter(Boolean) as GridColDef[];

  return (
    <Box marginTop="1rem">
      <Card
        cardTitleProps={{
          title: 'Online Batch list',
        }}
      >
        <Box position="relative">
          {isLoadingBatch && <Loader type="overlay" position="centered" />}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="When Created">
                {batch?.whenCreated ? formatShortFriendlyDateWithTime(batch?.whenCreated) : ''}
              </DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Created By">{batch?.userName ?? ''}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Reference">{batch?.description ?? ''}</DisplayGroup>
            </Grid>
          </Grid>
          <Box mt={1} mb={2}>
            <Divider />
          </Box>
        </Box>
        <ServerSideDataGrid
          autoHeight
          hasMobileLayout
          loading={isLoadingTransactions}
          mobileProps={{
            handleView: (val: ITransaction) =>
              history.push(
                `/billing/transactions/${val.transactionId}?redirect=/payments/online/${transactionBatchId}`
              ),
          }}
          getRowId={(row: ITransaction) => row.transactionId}
          columns={transactionGridColumns}
          {...gridProps}
        />
      </Card>
    </Box>
  );
};
