import { Link } from '../../components';
import { IAccount } from '../../models';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export const customerColumns: GridColDef[] = [
  {
    field: 'accountName',
    headerName: 'Customer',
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<IAccount>) => {
      const { row: account } = params;
      return <Link to={`/customers/${account.accountId}`}>{account.accountName}</Link>;
    },
  },
  {
    field: 'accountStatus',
    headerName: 'Status',
    filterable: false,
    width: 85,
    renderCell: (params: GridRenderCellParams<IAccount>) => {
      const { row: account } = params;
      return (
        <Typography
          sx={
            account.accountStatus === 'Active'
              ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
              : { fontWeight: 'bold', fontSize: '14px' }
          }
        >
          {account.accountStatus}
        </Typography>
      );
    },
  },
  {
    field: 'primaryPhone',
    headerName: 'Primary Phone',
    filterable: false,
    flex: 1,
  },
  {
    field: 'accountStreet',
    headerName: 'Account Street',
    filterable: false,
    flex: 1,
  },
  {
    field: 'rateCode',
    headerName: 'Rate Code',
    filterable: false,
    width: 100,
  },
  {
    field: 'accountCode',
    headerName: 'Code',
    filterable: false,
    width: 64,
  },
  {
    field: 'accountCity',
    headerName: 'Account City',
    filterable: false,
    flex: 1,
  },
  {
    field: 'accountEmail',
    headerName: 'Email',
    filterable: false,
    flex: 1,
  },
  {
    field: 'accountPostCode',
    headerName: 'Account Postal Code',
    filterable: false,
    flex: 1,
  },
  {
    field: 'accountState',
    headerName: 'Account State',
    filterable: false,
    flex: 1,
  },
  {
    field: 'homePhoneNumber',
    headerName: 'Home Phone Number',
    filterable: false,
    flex: 1,
  },
  {
    field: 'routeCode',
    headerName: 'Route Code',
    filterable: false,
    flex: 1,
  },
  {
    field: 'siteCity',
    headerName: 'Site City',
    filterable: false,
    flex: 1,
  },
  {
    field: 'siteStreet',
    headerName: 'Site Street',
    filterable: false,
    flex: 1,
  },
  {
    field: 'siteState',
    headerName: 'Site State',
    filterable: false,
    flex: 1,
  },
  {
    field: 'sitePostCode',
    headerName: 'Site Postal Code',
    filterable: false,
    flex: 1,
  },
  {
    field: 'siteDescription',
    headerName: 'Site Description',
    filterable: false,
    flex: 1,
  },
  {
    field: 'startupInfo',
    headerName: 'Startup Info',
    filterable: false,
    flex: 1,
  },
  {
    field: 'accountFirstName',
    headerName: 'First Name',
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<IAccount>) => {
      const { row: account } = params;
      return <Link to={`/customers/${account.accountId}`}>{account.accountFirstName}</Link>;
    },
  },
  {
    field: 'accountLastName',
    headerName: 'Last Name',
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<IAccount>) => {
      const { row: account } = params;
      return <Link to={`/customers/${account.accountId}`}>{account.accountLastName}</Link>;
    },
  },
  {
    field: 'billingGroup',
    headerName: 'Billing Group',
    filterable: false,
    flex: 1,
  },
];
