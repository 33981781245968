import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IUserDefinedAccount } from '../models';
import { userSignal } from '../signals';

export const getUserDefinedAccounts = async ({
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IUserDefinedAccount[]>> => {
  try {
    const { data } = await axios.get('/api/user-defined-account-defs', {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleUserDefinedAccount = async (
  id: string | number,
  officeId?: string | null
): Promise<IUserDefinedAccount> => {
  try {
    const { data } = await axios.get(`/api/user-defined-account-defs/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createUserDefinedAccount = async (
  body: IUserDefinedAccount,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/user-defined-account-defs`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateUserDefinedAccount = async (
  body: IUserDefinedAccount,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(
      `/api/user-defined-account-defs/${body.userDefinedAccountDefId}`,
      {
        ...body,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteUserDefinedAccount = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/user-defined-account-defs/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
