import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { BrandingContext } from '../../../context/branding-context';

export const PrivacyStatement = () => {
  const classes = useStyles();
  const { appLongName, LEGAL_NAME, SUPPORT_EMAIL } = useContext(BrandingContext);
  return (
    <>
      <Box className={classes.bodyWrapper}>
        <h2>{LEGAL_NAME} Privacy Statement:</h2>
        <p>
          {LEGAL_NAME} has created this privacy statement ("Statement") in order to demonstrate our
          commitment to customer privacy. Privacy on the {appLongName} web site (the "Site") is of
          great importance to us. Because we gather important information from our visitors and
          customers, we have established this Statement as a means to communicate our information
          gathering and dissemination practices. We reserve the right to change this Statement and
          will provide notification of the change at least thirty (30) business days prior to the
          change taking effect. The notification willinclude directions on how users may respond to
          the change.
        </p>
        <h2>Collected Information:</h2>
        <p>
          We require customers who register to use the services offered on our Site (collectively,
          the "Service") to give us contact information, such as their name, company name, address,
          phone number, and e-mail address, and financial qualification and billing information,
          such as billing name and address, credit card number, and the number of users within the
          organization that will be using the Service. At the time you express interest in attaining
          additional information, or when you register for the Service, we may also ask for
          additional personal information, such as title, department name, fax number, or additional
          company information, such as annual revenues, number of employees, or industry. Customers
          can opt out of providing this additional information by not entering it when asked.
          Customers can update or remove their personal information at any time by logging into the
          Website and editing their Personal Information within Setup. Customers can view their
          updated profile to confirm their edits have been made.
        </p>
        <p>
          {LEGAL_NAME} uses the information that we collect to set up the Service for individuals
          and their organizations. We may also use the information to contact customers to further
          discuss customer interest in our company, the Service that we provide, and to send
          information regarding our company or partners, such as promotions and events. Customers
          are invited to receive an email newsletter by providing an email address. Customer email
          addresses and any personal customer information will not be distributed or shared with
          third parties. Customers can opt out of being contacted by us, or receiving such
          information from us, at any time by sending an email to {SUPPORT_EMAIL}. Separately,
          customers are also asked to provide an email address when registering for the Service, in
          order to receive a username and password. We may also email information regarding updates
          to the Service or company, and will send a Customer Newsletter. Again, email will not be
          distributed or shared and customers can opt out of receiving any communication by emailing
          {SUPPORT_EMAIL} at the time it is distributed, or at the time any customer registers for
          the Service.
        </p>
        <p>
          Except as we explicitly state at the time we request information, or as provided for in
          the {LEGAL_NAME} Master Subscription Agreement, we do not disclose to third parties the
          information provided. All financial and billing information that we collect through the
          Site is used solely to check the qualifications of prospective customers and to bill for
          the Service. This billing information is not used by {LEGAL_NAME} for marketing or
          promotional purposes. {LEGAL_NAME} uses a third-party intermediary to manage the credit
          card processing. This intermediary is solely a link in the distribution chain, and is not
          permitted to store, retain, or use the information provided, except for the sole purpose
          of credit card processing. Other third parties, such as content providers, may provide
          content on the web Site but they are not permitted to collect any information nor does{' '}
          {LEGAL_NAME} share user information with these parties.
        </p>
        <p>
          Customers of the Service will be using the Site to host data and information ("Data").
          {LEGAL_NAME} will not review, share, distribute, print, or reference any such Data except
          as provided in the {LEGAL_NAME} Master Subscription Agreement, or as may be required by
          law. Individual records may at times be viewed or accessed only for the purpose of
          resolving a problem, support issue, or suspected violation of the Master Subscription
          Agreement, or as may be required by law. Of course, customers are responsible for
          maintaining the confidentiality and security of their user registration and password.
        </p>
        <p>
          {LEGAL_NAME} may also collect certain information from visitors to and customers of the
          Site, such as Internet addresses. This information is logged to help diagnose technical
          problems, and to administer our Site in order to constantly improve the quality of the
          Service. We may also track and analyze non-identifying and aggregate usage and volume
          statistical information from our visitors and customers and provide such information to
          third parties.
        </p>
        <p>
          If a user elects to use our referral service for informing a friend about our site, we ask
          them for the friend's name and email address. {LEGAL_NAME} will automatically send the
          friend a one-time email inviting them to visit the site. {LEGAL_NAME} does not store this
          information.
        </p>
        <h2>Cookies:</h2>
        <p>
          When you interact with the {LEGAL_NAME} Website we strive to make that experience easy and
          meaningful. When you come to our Web site, our Web server sends a cookie to your computer.
          Cookies are files that Web browsers place on a computer's hard drive and are used to tell
          us whether customers and visitors have visited the Site previously.
        </p>
        <p>
          Standing alone, cookies do not identify you personally. They merely recognize your
          browser. Unless you choose to identify yourself to {LEGAL_NAME}, either by responding to a
          promotional offer, opening an account, or registering for a 30-day Test Drive, you remain
          anonymous to {LEGAL_NAME}. Cookies come in two flavors: session and persistent-based.
          Session cookies exist only during an online session. They disappear from your computer
          when you close your browser software or turn off your computer. Persistent cookies remain
          on your computer after you've closed your browser or turned off your computer. They
          include such information as a unique identifier for your browser.
        </p>
        <p>
          {LEGAL_NAME} uses session cookies containing encrypted information to allow the system to
          uniquely identify you while you are logged in. This information allows {LEGAL_NAME}
          to process your online transactions and requests. Session cookies help us make sure you
          are who you say you are after you've logged in and are required in order to use the{' '}
          {LEGAL_NAME} application. {LEGAL_NAME} uses persistent cookies, that only
          {LEGAL_NAME} can read and use, to identify the fact that you are a {LEGAL_NAME}
          customer or prior {LEGAL_NAME} Website visitor (whatever the case may be). We are
          especially careful about the security and confidentiality of the information stored in
          persistent cookies. For example, we do not store account numbers or passwords in
          persistent cookies. Users who disable their Web browsers' ability to accept cookies will
          be able to browse our Website but will not be able to successfully use our Service.
        </p>
        <p>
          Third Party Cookies: We may from time to time engage third parties to track and analyze
          non-personally identifiable usage and volume statistical information from visitors to our
          website to help us administer our website and improve its quality. Such third parties may
          use cookies to help track visitor behavior. Such cookies will not be used to associate
          individual website visitors to any personally identifiable information. All data collected
          by such third parties on behalf of {LEGAL_NAME} is used only to provide us with
          information on site usage and is not shared with any other third parties.
        </p>
        <h2>Third-Party Sites:</h2>
        <p>
          The Site contains links to other web sites. {LEGAL_NAME} is not responsible for the
          privacy practices or the content of these other web sites. Customers and visitors will
          need to check the policy statement of these others web sites to understand their policies.
          Customers and visitors who access a linked site may be disclosing their private
          information. It is the responsibility of the user to keep such information private and
          confidential.
        </p>
        <h2>Security:</h2>
        <p>
          Our Site has security measures in place to help protect against the loss, misuse, and
          alteration of the Data under our control. When our Site is accessed using Netscape
          Navigator, or Microsoft Internet Explorer versions 5.5 or higher, Secure Socket Layer
          (SSL) technology protects information using both server authentication and data encryption
          to help ensure that Data is safe, secure, and available only to you. {LEGAL_NAME}
          also implements an advanced security method based on dynamic data and encoded session
          identifications, and hosts the Site in a secure server environment that uses a firewall
          and other advanced technology to prevent interference or access from outside intruders.
          Finally, {LEGAL_NAME} provides unique user names and passwords that must be entered each
          time a customer logs on. These safeguards help prevent unauthorized access, maintain data
          accuracy, and ensure the appropriate use of Data.
        </p>
        <h2>Opt-Out Policy:</h2>
        <p>
          {LEGAL_NAME} offers its visitors and customers a means to choose how we may use
          information provided. If, at any time after registering for information or ordering the
          Service, you change your mind about receiving information from us or about sharing your
          information with third parties, send us a request specifying your new choice. Simply send
          your request to {SUPPORT_EMAIL} .
        </p>
        <h2>Correcting &amp; Updating Your Information:</h2>
        <p>
          If customers need to update or change registration information they may do so by editing
          the user or organization record. To update a User Profile, log on to {appLongName}. and
          select Setup to add or update information. To update Organization's data, log on to{' '}
          {appLongName} and select Organization Setup. To update billing information please email
          {SUPPORT_EMAIL} or call 1-800-934-7890 Option 3. To discontinue the Service and to have
          data returned, email {SUPPORT_EMAIL} . or call 1-800-934-7890 Option 3. Pinch A Penny
          Inc.. will respond to your correction or update request within at most 30 days from the
          date of your request.
        </p>
        <h2>Additional Information:</h2>
        <p>
          Questions regarding this Statement or the practices of this Site should be directed to
          {LEGAL_NAME}'s Security Administrator by e-mailing such questions to
          {SUPPORT_EMAIL} or by regular mail addressed to {LEGAL_NAME}, Attn: Security
          Administrator, 6385 150th Avenue North, Clearwater, FL 33760.
        </p>
      </Box>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    bodyWrapper: {
      paddingTop: theme.spacing(1),
      '& h2': {
        fontWeight: 'bold',
        fontSize: '1rem',
        marginBottom: theme.spacing(1),
      },
      '& p': {
        marginBottom: theme.spacing(1),
      },
    },
  };
});
