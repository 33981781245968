import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  IOffice,
  IGetFiltersResponse,
  IOfficeDetail,
  ILookupModel,
  ICompanyPhone,
  IOfficeInfo,
} from '../models';
import { userSignal } from '../signals';

export const getOffices = async (filters?: {
  page?: number;
  perPage?: number | string;
  sortBy?: string;
  sortDirection?: string;
  inactive?: boolean | string;
  code?: string;
  name?: string;
  officeId?: string | null;
}): Promise<IResponse<IOffice[]>> => {
  try {
    const { data } = await axios.get('/api/offices', {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOfficesFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/offices/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getOffice = async (
  id: string | number,
  officeId?: string | null
): Promise<IOfficeDetail> => {
  try {
    const { data } = await axios.get(`/api/offices/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOffice = async (
  id: string | number,
  update: { isCustomerContractRequired: boolean | null },
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/offices/${id}`, {
      ...update,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOfficeInformation = async (filters?: {
  officeId?: string | null;
}): Promise<IOfficeInfo> => {
  try {
    const { data } = await axios.get(`/api/offices/info/${filters?.officeId}`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOfficeInformation = async (body: IOfficeInfo): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/offices/info/${body.officeId}`, {
      ...body,
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTimeZone = async (officeId: string | number): Promise<string> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/time-zone`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTimeZoneList = async (): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/offices/time-zone-list`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getCompanyPhoneNumbers = async (filters?: {
  page?: number;
  perPage?: number | string;
  sortBy?: string;
  sortDirection?: string;
  inactive?: boolean | string;
  officeId?: string | null;
}): Promise<IResponse<ICompanyPhone[]>> => {
  try {
    const { data } = await axios.get('/api/offices/phone-list', {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createOfficePhoneNumber = async (
  body: ICompanyPhone,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/offices/phone`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOfficePhoneNumber = async (body: ICompanyPhone): Promise<string> => {
  try {
    var OfficePhoneNumberId = body.officePhoneNumberId;
    const { data } = await axios.put(`/api/offices/phone/${OfficePhoneNumberId}`, {
      ...body,
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteCompanyPhoneNumber = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/offices/phone/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
