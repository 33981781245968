import React, {
  FC,
  Fragment,
  Dispatch,
  useState,
  SetStateAction,
  useMemo,
  useContext,
} from 'react';
import { useQuery } from 'react-query';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import { deepEqual } from 'fast-equals';
import { format } from 'date-fns';
import { Modal, Loader, SaveButton } from '../../components';
import {
  Grid,
  Box,
  Fade,
  Typography,
  Slider,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
// fetch
import { getTreatmentTypes, createTreatment } from '../../fetch';
import { ITreatmentType, IResponse, ICreateTreatment } from '../../models';
import { useConfirm } from '../../hooks';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { defaultUnsavedChangesMessage } from '../../constants';
import { UserContext } from '../../context';

interface ITreatmentModal {
  open: boolean;
  onClose: () => void;
  selectedTreatments: ICreateTreatment[];
  getTreatments: () => void;
  siteName?: string;
  siteId: string;
  serviceId?: string;
  setSelectedTreatments: Dispatch<SetStateAction<ICreateTreatment[]>>;
  whenAdded: Date | string | null;
  setWhenAdded: (val: Date) => void;
  defaultTreatments: ICreateTreatment[];
  setInitialTreatments: Dispatch<SetStateAction<ICreateTreatment[]>>;
  setTreatmentsForDate: (val: string | Date) => void;
  isLoadingAllTreatments: boolean;
  hasTreatments: boolean;
}
export const TreatmentModal: FC<ITreatmentModal> = ({
  open,
  onClose,
  siteName,
  selectedTreatments,
  setSelectedTreatments,
  siteId,
  serviceId,
  getTreatments,
  setWhenAdded,
  whenAdded,
  defaultTreatments,
  setInitialTreatments,
  setTreatmentsForDate,
  isLoadingAllTreatments,
  hasTreatments,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setSaving] = useState(false);
  const confirm = useConfirm();
  const { office } = useContext(UserContext);

  const isBillableTreatments = useMemo(() => office?.billForTreatments ?? false, [office]);
  const { data: treatmentTypesData } = useQuery<IResponse<ITreatmentType[]>, Error>(
    ['treatment-types'],
    () => getTreatmentTypes({ perPage: -1 }),
    {
      onSuccess: d => {
        const formatTreatments = d.records.map(t => ({
          treatmentTypeId: t.treatmentTypeId,
          serviceId,
          unitsAdded: 0,
          isBillable: false,
        }));
        setSelectedTreatments(formatTreatments);
        setInitialTreatments(formatTreatments);
        return d;
      },
      notifyOnChangeProps: 'tracked',
    }
  );

  const types: ITreatmentType[] = useMemo(
    () => treatmentTypesData?.records ?? [],
    [treatmentTypesData]
  );
  return (
    <Modal
      open={open}
      onClose={async () => {
        if (!deepEqual(defaultTreatments, selectedTreatments)) {
          const result = await confirm(defaultUnsavedChangesMessage);
          if (result) {
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
        }
      }}
      maxWidth="sm"
      title={`${hasTreatments ? 'Edit' : 'Add'} Treatments`}
    >
      {(isSaving || isLoadingAllTreatments) && <Loader type="overlay" />}
      <Fade in={open}>
        <div>
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom>Site Name: {siteName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="When Added"
                  format="MM/dd/yyyy"
                  onChange={(date: any) => {
                    setWhenAdded(date);
                    setTreatmentsForDate(date);
                  }}
                  value={whenAdded ? new Date(whenAdded) : null}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Box maxHeight="30rem" overflow="auto" width="100%">
                {types.map((type, index) => {
                  const selected = selectedTreatments.find(
                    treatment => treatment.treatmentTypeId === type.treatmentTypeId
                  );
                  const selectedIndex = selectedTreatments.findIndex(
                    treatment => treatment.treatmentTypeId === type.treatmentTypeId
                  );
                  return (
                    <Fragment key={`${index}`}>
                      <TreatmentSlider
                        label={type.description}
                        value={selected?.unitsAdded}
                        setFieldValue={val => {
                          const copy = JSON.parse(JSON.stringify(selectedTreatments));
                          // if we are updating a pre-existing treament
                          if (copy?.[selectedIndex]) {
                            copy[selectedIndex].unitsAdded = val;
                            setSelectedTreatments(copy);
                          } else {
                            // adding a new selected treatment
                            setSelectedTreatments([
                              ...selectedTreatments,
                              {
                                treatmentTypeId: type.treatmentTypeId,
                                serviceId,
                                unitsAdded: val,
                                isBillable: selected?.isBillable ?? false,
                              } as ICreateTreatment,
                            ]);
                          }
                        }}
                        max={type.highestToAdd}
                        measure={type.unitDescription.toLowerCase()}
                        id={type.code}
                        step={type.unitIncrement}
                        isBillable={selected?.isBillable}
                        setBillable={val => {
                          const copy = JSON.parse(JSON.stringify(selectedTreatments));

                          if (copy?.[selectedIndex]) {
                            copy[selectedIndex].isBillable = val;
                            setSelectedTreatments(copy);
                          } else {
                            // adding a new selected treatment
                            setSelectedTreatments([
                              ...selectedTreatments,
                              {
                                treatmentTypeId: type.treatmentTypeId,
                                serviceId,
                                unitsAdded: selected?.unitsAdded ?? 0,
                                isBillable: val ?? false,
                              } as ICreateTreatment,
                            ]);
                          }
                        }}
                        showBillable={isBillableTreatments}
                      />
                    </Fragment>
                  );
                })}
              </Box>
            </Grid>

            <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
              <Button
                type="button"
                color="inherit"
                onClick={async () => {
                  if (!deepEqual(defaultTreatments, selectedTreatments)) {
                    const result = await confirm(defaultUnsavedChangesMessage);
                    if (result) {
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={deepEqual(defaultTreatments, selectedTreatments)}
                handleSave={async () => {
                  try {
                    setSaving(true);
                    await createTreatment(
                      siteId,
                      whenAdded ? format(new Date(whenAdded), 'yyyy-L-d') : '',
                      {
                        treatments: selectedTreatments,
                      },
                      serviceId
                    );
                    enqueueSnackbar('Treatment saved!', {
                      variant: 'success',
                    });
                    getTreatments();
                    onClose();
                  } catch (error: any) {
                    enqueueSnackbar(error?.data?.Detail || 'Error saving treatment.', {
                      variant: 'error',
                    });
                  } finally {
                    setSaving(false);
                  }
                }}
              />
            </Box>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

interface ITreatmentSlider {
  label: string;
  value: any;
  max: number;
  measure: string;
  setFieldValue: (val: any) => void;
  id: string;
  step: number;
  isBillable?: boolean;
  setBillable?: (val: boolean) => void;
  showBillable?: boolean;
}

const TreatmentSlider: FC<ITreatmentSlider> = ({
  label,
  value,
  max,
  measure,
  setFieldValue,
  id,
  step,
  isBillable,
  setBillable,
  showBillable,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sx={{ padding: `0.75rem 1.75rem 0 !important` }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography id={`input-slider-${id}`} gutterBottom>
          {label}
        </Typography>
        {showBillable && (
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  setBillable && setBillable(!isBillable ?? false);
                }}
                checked={isBillable ?? false}
              />
            }
            label="Billable"
            value={isBillable ?? false}
          />
        )}
      </Box>
      <Slider
        value={typeof value === 'number' ? value : 0}
        onChange={(e, val) => setFieldValue(val)}
        aria-labelledby={`input-slider-${id}`}
        marks={[
          {
            value: 0,
            label: `0/${measure}`,
          },
          {
            value: max,
            label: `${max}/${measure}`,
          },
        ]}
        min={0}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        getAriaValueText={val => `${val}/${measure}`}
        className={classes.slider}
      />
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  // styles for the labels on the front and end of the slider
  slider: {
    '&& span:nth-child(4)': {
      left: `4% !important`,
    },
    '&& span:nth-child(6)': {
      left: `97% !important`,
    },
  },
}));
