import React, { createContext, FC, useState, useMemo, useEffect, useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { SIDENAV_OPEN, prioritizeNewsItems } from '../helpers';
import { useSnackbar } from 'notistack';
import { IMessageResponse } from '../models';
import { getMessages, getNews } from '../fetch';
import { UserContext } from './user';

export const SIDENAV_WIDTH_COLLAPSED = 80;
export const SIDENAV_WIDTH_EXPANDED = 275;

export interface ISideNavContext {
  showNav: boolean;
  setShowNav: (val: boolean) => void;
  sideNavWidth: number;
  isMobile: boolean;
  isSmMobile: boolean;
  isPrint: boolean;
  isLoadingMessages: boolean;
  messages: IMessageResponse | null;
}

export const SideNavContext = createContext<ISideNavContext>({
  showNav: localStorage.getItem(SIDENAV_OPEN) === 'false' ? false : true,
  setShowNav: () => {},
  sideNavWidth: 0,
  isMobile: false,
  isSmMobile: false,
  isPrint: false,
  isLoadingMessages: false,
  messages: null,
});

interface ISideNavContextHandlerProps {}

export const SideNavHandler: FC<ISideNavContextHandlerProps> = ({ children }): JSX.Element => {
  const [showNav, setShowNav] = useState(
    localStorage.getItem(SIDENAV_OPEN) === 'false' ? false : true
  );
  const [isLoadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState<IMessageResponse | null>(null);
  const isMobile = useMediaQuery(`(max-width: 930px)`);
  const isSmMobile = useMediaQuery(`(max-width: 600px)`);
  const isPrint = useMediaQuery(`print`);
  const { user } = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();

  const fetchMessages = async () => {
    setLoadingMessages(true);
    try {
      const messageRes = await getMessages();     
      const newsRes = await getNews({
        category: 'Alert',
        sortBy: 'whenCreated',
        sortDirection: 'desc',
        onlyActive: true,
      });

      const filteredAlerts = newsRes.records.length > 0 ? prioritizeNewsItems(newsRes.records) : [];

      setMessages({
        ...messageRes,
        alerts: filteredAlerts,
      });
    } catch (error) {
      enqueueSnackbar(`Error loading messages and alerts, please refresh the page to try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const sideNavWidth = useMemo(() => {
    if ((!showNav && isMobile) || isPrint) {
      return 0;
    }
    if (!showNav && !isMobile) {
      // Only show Icons
      return SIDENAV_WIDTH_COLLAPSED;
    }
    if (showNav || !isMobile) {
      // Show Icons and Labels
      return SIDENAV_WIDTH_EXPANDED;
    }
    // Hide completely
    return 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNav, isMobile, isPrint]);
  return (
    <SideNavContext.Provider
      value={{
        showNav,
        setShowNav,
        sideNavWidth,
        isSmMobile,
        isMobile,
        isPrint,
        isLoadingMessages,
        messages,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
};
