import { axios } from '../helpers';
import qs from 'querystring';
import { IUserGroup, IPaginatedResponse, IListUser, IPermission } from '../models';
import { userSignal } from '../signals';

export const getUserGroups = async (filters?: {
  sortBy?: string;
  first?: boolean;
  last?: boolean;
  before?: string;
  after?: string;
  perPage?: number;
  officeId?: string | null;
  sortDirection?: string;
  isForFieldReport?: boolean;
}): Promise<IPaginatedResponse<IUserGroup>> => {
  try {
    const { data } = await axios.get(`/api/user-groups`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createUserGroup = async (post: { name: string; officeId: string }) => {
  try {
    const { data } = await axios.post(`/api/user-groups`, {
      ...post,
      officeId: post.officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateUserGroupName = async (
  id: string,
  update: { name: string },
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/user-groups/${id}/name`, {
      ...update,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteUserGroup = async (id: string) => {
  try {
    const { data } = await axios.delete(`/api/user-groups/${id}`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getUsersInGroup = async (
  id: string,
  officeId?: string | null
): Promise<IListUser[]> => {
  try {
    const { data } = await axios.get(`/api/user-groups/${id}/users`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateUsersInGroup = async (
  id: string,
  update: { userIds: string[] },
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/user-groups/${id}/users`, {
      ...update,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getPermissionsAvailable = async (officeId?: string | null): Promise<IPermission[]> => {
  try {
    const { data } = await axios.get(`/api/user-groups/roles`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getPermissionsInGroup = async (
  id: string,
  officeId?: string | null
): Promise<IPermission[]> => {
  try {
    const { data } = await axios.get(`/api/user-groups/${id}/roles`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updatePermissionsInGroup = async (
  id: string,
  update: { roleIds: string[] },
  officeId?: string | null
): Promise<any[]> => {
  try {
    const { data } = await axios.put(`/api/user-groups/${id}/roles`, {
      ...update,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
