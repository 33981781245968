import { Page } from '../../components';
import { FeedbackPageDetails } from './feedback-details';

export const FeedbackPage = () => {
  return (
    <Page title="Feedback">
      <FeedbackPageDetails />
    </Page>
  );
};
