import { axios } from '../helpers';
import { IServiceType } from '../models';
import { userSignal } from '../signals';

export const listServiceTypes = async (officeId?: string | null): Promise<IServiceType[]> => {
  const { data } = await axios.get<IServiceType[]>('/api/service-types', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
  });
  return data;
};

export const getServiceTypeTermsAndConditions = async (
  serviceTypeId: string,
  officeId?: string | null
): Promise<string> => {
  const { data } = await axios.get<string>(
    `/api/service-types/${serviceTypeId}/terms-and-conditions`,
    {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
    }
  );
  return data;
};
