import { FC, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { Link, ServerSideDataGrid, ServerSideDataGridProps, ExternalLink } from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IRecurringService, IScheduledService } from '../../models';
import { ServicesActionButton } from './services-actions-button';
import { formatDate, getLegacyUrl } from '../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface RecurringServicesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IRecurringService[];
  redirect?: string;
  isEditable?: boolean;
}

export const RecurringServicesDataGrid: FC<RecurringServicesDataGridProps> = ({
  rows,
  redirect,
  isEditable,
  ...gridProps
}) => {
  const { v2Customers } = useFlags();
  const legacyUrl = getLegacyUrl?.();
  const columns = useMemo(() => {
    return [
      {
        headerName: 'Site',
        field: 'siteName',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const childComponent = (
            <Tooltip placement="bottom" title={`View customer's site`}>
              <span>{params?.row.siteName}</span>
            </Tooltip>
          );
          return v2Customers ? (
            <Link
              to={`/customers/${params?.row.accountId}?activeTab=sites&siteId=${params?.row.siteId}`}
            >
              {childComponent}
            </Link>
          ) : (
            <ExternalLink to={`${legacyUrl}/Office/Site/View/${params?.row.siteId}`}>
              {childComponent}
            </ExternalLink>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'repeatLink',
        headerName: 'Repeat Link',
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return (
            original.frequency && (
              <Link
                to={`/services/maintenance/recurring/${original.recurringServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`}
              >
                <Tooltip title={`Edit recurring appointment schedule`} placement="bottom">
                  <span>{original.frequency}</span>
                </Tooltip>
              </Link>
            )
          );
        },
      },
      {
        field: 'userName',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: 'initialDate',
        headerName: 'Initial Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.initialDate ? formatDate(original.initialDate) : '';
        },
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.endDate ? formatDate(original.endDate) : '';
        },
      },
      isEditable && {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <ServicesActionButton service={original} redirect={redirect} />;
        },
      },
    ].filter(Boolean);
  }, [redirect, isEditable, v2Customers, legacyUrl]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IRecurringService) => row.recurringServiceId}
        rows={rows}
        columns={columns as any[]}
        hasMobileLayout
        {...gridProps}
      />
    </>
  );
};
