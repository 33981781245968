import { Autocomplete, Avatar, Chip, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC, SetStateAction } from 'react';
import { ITechnician } from '../../models';

interface ITechnicianFilters {
  technicians: ITechnician[];
  selectedTechs: ITechnician[];
  setSelectedTechs: Dispatch<SetStateAction<ITechnician[]>>;
  isLoading: boolean;
  inputSize?: 'small' | 'medium';
  disabled?: boolean;
}

export const TechnicianFilters: FC<ITechnicianFilters> = ({
  technicians,
  selectedTechs,
  setSelectedTechs,
  isLoading,
  inputSize = 'medium',
  disabled,
}) => {
  const handleDelete = (tech: ITechnician) => {
    setSelectedTechs(prev => prev.filter(t => t.userId !== tech.userId));
  };
  const classes = useStyles();

  return (
    <Autocomplete
      value={selectedTechs}
      multiple
      disableClearable
      className={classes.cardBody}
      classes={{
        paper: classes.paperBorder,
      }}
      onChange={(event, newValue: ITechnician[]) => {
        if (newValue && newValue.length > 0) {
          setSelectedTechs(newValue);
        } else {
          setSelectedTechs([]);
        }
      }}
      id="technicians"
      options={technicians.filter(t => !selectedTechs.map(t => t.userId).includes(t.userId))}
      disabled={isLoading || disabled}
      getOptionLabel={(option: ITechnician) => {
        return option.userName;
      }}
      renderOption={(props, option: ITechnician) => {
        return (
          <li {...props} key={option.userId}>
            {option.userName}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <Chip
            key={tag.userId}
            avatar={<Avatar alt={tag.userName}>{tag.userName.slice(0, 1)}</Avatar>}
            label={tag.userName}
            variant="outlined"
            onDelete={() => {
              handleDelete(tag);
            }}
            disabled={isLoading}
            className={classes.chip}
            classes={{ root: classes.chipRoot }}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size={inputSize}
            name="technicians"
            autoComplete="none"
            label={selectedTechs?.length || !!params.inputProps.value ? '' : 'Select Technician'}
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              'data-testid': 'technician-select'
            }}
          />
        );
      }}
    />
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  header: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  container: {
    padding: 0,
  },
  map: {
    marginTop: theme.spacing(4),
  },
  card: {},
  cardBody: {
    borderRadius: theme.spacing(0.5),
    '&& .MuiOutlinedInput-root': {
      padding: '6px 9px',
    },
  },
  chip: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
  },
  chipRoot: {
    '& .MuiChip-deleteIcon': {
      fontSize: '24px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  },
}));
