import { FC, useMemo } from 'react';
import { Link, SimpleDataGrid } from '../../../components';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { IDuplicateAccount } from '../../../models';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  formatAddressBlock,
  formatAddressSingleLine,
  formatInputPhoneNumber,
} from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export interface IDuplicateCustomersTableProps {
  duplicateCustomers: IDuplicateAccount[];
}

export const DuplicateCustomersTable: FC<IDuplicateCustomersTableProps> = ({
  duplicateCustomers,
}) => {
  const history = useHistory();
  const handleSelect = async (accountId: string) => {
    history.push(`/customers/${accountId}/edit?refresh=true`);
  };
  const isDesktop = useMediaQuery(`(min-width: 960px)`);
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'accountName',
        headerName: 'Customer',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<IDuplicateAccount>) => {
          const { row: account } = params;
          const childComponent = (
            <Tooltip placement="bottom" title="Edit customer">
              <span>
                {account.accountName
                  ? account.accountName
                  : `${account.accountFirstName} ${account.accountLastName}`}
              </span>
            </Tooltip>
          );
          return (
            <Link to={`/customers/${account.accountId}/edit?refresh=true`}>{childComponent}</Link>
          );
        },
      },
      {
        field: 'accountFirstName',
        headerName: 'First Name',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'accountLastName',
        headerName: 'Last Name',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 50,
      },
      {
        field: 'accountStreet',
        headerName: 'Address',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<IDuplicateAccount>) => {
          const { row: account } = params;
          const address = isDesktop
            ? formatAddressSingleLine(
                account?.accountStreet,
                account?.accountCity,
                account?.accountState,
                account?.accountPostCode
              )
            : formatAddressBlock(
                account?.accountStreet,
                account?.accountCity,
                account?.accountState,
                account?.accountPostCode
              );
          return (
            <Tooltip placement="bottom" title={address}>
              <span>{address}</span>
            </Tooltip>
          );
        },
      },
      {
        headerName: 'Phone Number',
        field: 'primaryPhone',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<IDuplicateAccount>) => {
          const { row: account } = params;
          const primaryPhone = account?.primaryPhone;
          const formattedNumber = primaryPhone ? formatInputPhoneNumber(primaryPhone) : '';

          return (
            <Tooltip placement="bottom" title={formattedNumber}>
              <span>{formattedNumber}</span>
            </Tooltip>
          );
        },
      },
      {
        headerName: 'Emails',
        field: 'emails',
        disableColumnMenu: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<IDuplicateAccount>) => {
          const { row: account } = params;
          const firstEmail = account?.emails?.[0] ?? '';
          const formattedTooltip = (
            <>
              {account?.emails?.map(email => {
                return (
                  <>
                    {email}
                    <br />
                  </>
                );
              })}
            </>
          );
          return (
            <Tooltip placement="bottom" title={formattedTooltip}>
              <span>{isDesktop ? firstEmail : formattedTooltip}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IDuplicateAccount>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Customer"
                onClick={() => {
                  handleSelect(params.row.accountId);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duplicateCustomers, isDesktop]
  );
  return (
    <Box my={1}>
      <SimpleDataGrid
        getRowId={(row: IDuplicateAccount) => {
          return row.accountId!;
        }}
        rows={duplicateCustomers ?? []}
        columns={columns}
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        hideFooter
        noResultsMessage="No customers to show."
        hasMobileLayout
        mobileProps={{
          stackAccordionDetails: true,
          handleEdit: (val: IDuplicateAccount) => {
            handleSelect(val.accountId);
          },
        }}
      />
    </Box>
  );
};
