import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Email } from '@mui/icons-material';
import { Loader } from '../../components';
import { createFeedbackItem } from '../../fetch/feedback';

const EmailTestSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

export const FeedbackForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        type: 'question',
        message: '',
      }}
      validationSchema={EmailTestSchema}
      onSubmit={async (values, actions) => {
        try {
          await createFeedbackItem(values);
          enqueueSnackbar(`Feedback submitted!`, {
            variant: 'success',
          });
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? 'Error submitting feedback, please try again.', {
            variant: 'error',
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            {isSubmitting && <Loader type="overlay" position="centered" title="Sending..." />}

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" size="small" fullWidth>
                      <FormLabel component="legend">Regarding</FormLabel>
                      <RadioGroup
                        aria-label="Email type"
                        id={`type`}
                        name="type"
                        defaultValue="question"
                        value={values.type}
                        onChange={e => setFieldValue('type', e.target.value)}
                      >
                        <FormControlLabel value="question" control={<Radio />} label="A Question" />
                        <FormControlLabel value="comment" control={<Radio />} label="A Comment" />
                        <FormControlLabel
                          value="suggestion"
                          control={<Radio />}
                          label="A Suggestion"
                        />
                        <FormControlLabel value="error" control={<Radio />} label="An Error" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      name={`message`}
                      id={`message`}
                      label={`Details`}
                      variant="outlined"
                      multiline
                      rows={8}
                      size="small"
                      value={values.message}
                      onBlur={handleBlur}
                      onChange={e => setFieldValue('message', e.target.value)}
                      error={Boolean(touched.message && errors.message)}
                      helperText={touched.message && errors.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Button
                disabled={!dirty || isSubmitting || !isValid}
                type="submit"
                startIcon={<Email />}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
