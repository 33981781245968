import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Stack, Button, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Loader, SaveButton } from '../../components';
import { ViewName, ViewVisibility, SearchConditions, ViewColumns } from './components';
import { INITIAL_FORM_STATE, MIN_VIEW_COLUMNS, MAX_VIEW_COLUMNS } from './constants';
import {
  getColumnNames,
  getCustomView,
  createCustomView,
  updateCustomView,
  deleteCustomView,
  getCustomViews,
} from '../../fetch';
import { UserContext } from '../../context/user';
import {
  ILookupModel,
  ICustomCustomerView,
  ICreateCustomView,
  ICustomViewFormValues,
  VIEW_VISIBILITY,
} from '../../models';
import { removeLocalStorage, setLocalStorage } from '../../helpers';
import { LSKeys } from '../../constants';
import { useConfirm } from '../../hooks';

const FORM_VALIDATION = Yup.object().shape({
  viewName: Yup.string().required('View name is required'),
  viewVisibility: Yup.string().required('View visibility is required'),
  searchConditions: Yup.array().of(
    Yup.object().shape({
      operator: Yup.string(),
      columnName: Yup.string().required('Required'),
      comparator: Yup.string().required('Required'),
      compareValue: Yup.string().required('Required'),
    })
  ),
  viewColumns: Yup.array()
    .min(MIN_VIEW_COLUMNS, 'Minimum of 1 view column required')
    .max(MAX_VIEW_COLUMNS, 'Maximum of 10 view columns allowed'),
});

export const CustomViewDetails = () => {
  const { viewId }: { viewId: string } = useParams();
  const { user } = useContext(UserContext);
  const [columnEnums, setColumnEnums] = useState<ILookupModel[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setLoadingCustomView] = useState<boolean>(false);
  const [customViews, setCustomViews] = useState<ICustomCustomerView[]>([]);
  const [initialFormState, setInitialFormState] =
    useState<ICustomViewFormValues>(INITIAL_FORM_STATE);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isNewCustomView = viewId === 'new';
  useEffect(() => {
    (async () => {
      const data = await getCustomViews();
      setCustomViews(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getColumnNames();
        response.data.sort((a, b) => (a.description > b.description ? 1 : -1));
        setColumnEnums(response.data);
      } catch (error) {
        enqueueSnackbar(`Error loading column names. Please try again.`, { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      if (!isNewCustomView) {
        try {
          setLoadingCustomView(true);
          const result = await getCustomView(viewId);
          const { description, isPublic, restrictions: searchConditions, columns } = result.data;

          // On edit view, the first row operator must always be set to NONE.
          if (searchConditions[0]) {
            searchConditions[0].operator = 'NONE';
          }

          // GET request for custom view only returns view columns in PascalCase
          // Drag and drop for view columns needs PascalCase and the user-friendly description
          // Match the API response with the correct ENUM columns to get both properties.
          // viewColumns expects ENUM objects like { value: 'AccountCode', description: 'Account Code' }
          // API response only returns { columnName: AccountCode }
          const viewColumns = columns.flatMap(column => {
            const match = columnEnums.find(columnEnum => column.columnName === columnEnum.value);
            return match ? match : [];
          });

          setInitialFormState({
            viewName: description,
            viewVisibility: isPublic ? VIEW_VISIBILITY.PUBLIC : VIEW_VISIBILITY.PRIVATE,
            searchConditions,
            viewColumns,
          });
        } catch (err) {
          enqueueSnackbar(`Error loading custom view. Please try again.`, { variant: 'error' });
        } finally {
          setLoadingCustomView(false);
        }
      }
    })();
  }, [enqueueSnackbar, isNewCustomView, viewId, columnEnums]);

  const handleDelete = async () => {
    const result = await confirm('Are you sure you want to delete this view?');
    if (!result) return;

    try {
      setIsDeleting(true);
      await deleteCustomView(viewId);
      enqueueSnackbar(`Custom view deleted!`, { variant: 'success' });
      await removeLocalStorage(LSKeys.CUSTOM_VIEW);
      history.push('/customers');
    } catch (err: any) {
      enqueueSnackbar(err.Detail || `Error deleting custom view, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <Box position="relative">
      {isLoading && <Loader type="overlay" position="centered" />}
      <Formik
        initialValues={initialFormState}
        enableReinitialize={true}
        validationSchema={FORM_VALIDATION}
        onSubmit={async values => {
          const { viewName, viewVisibility, searchConditions, viewColumns } = values;

          // Do not allow an operator for a single query.
          if (searchConditions.length === 1) searchConditions[0].operator = '';

          const payload: ICreateCustomView = {
            description: viewName,
            isPublic: viewVisibility === 'public' ? true : false,
            officeId: user?.officeId ?? '',
            restrictions: searchConditions.map((condition, index) => ({
              ...condition,
              sortOrder: index + 1,
              operator: ['AND', 'OR'].includes(condition.operator) ? condition.operator : '',
            })),
            columns: viewColumns.map((column, index) => {
              return {
                columnName: column.value,
                sortOrder: index + 1,
              };
            }),
          };

          try {
            isNewCustomView
              ? await createCustomView(payload)
              : await updateCustomView(viewId, payload);
            // set local storage with the latest custom view updates/created so it sets it on the customers custom view filter list
            await setLocalStorage(LSKeys.CUSTOM_VIEW, payload?.description);
            enqueueSnackbar(`Custom view ${isNewCustomView ? 'created' : 'edited'}!`, {
              variant: 'success',
            });
            history.push('/customers');
          } catch (err: any) {
            enqueueSnackbar(
              err?.Detail ||
                `Error ${isNewCustomView ? 'creating' : 'editing'} custom view, please try again.`,
              { variant: 'error' }
            );
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => {
          return (
            <Form>
              {(isSubmitting || isDeleting) && <Loader position="centered" type="overlay" />}
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
                <Box flex={4}>
                  <ViewName />
                </Box>
                <Box flex={3}>
                  <ViewVisibility />
                </Box>
              </Stack>
              <Box mb={2}>
                <SearchConditions columnEnums={columnEnums} />
              </Box>
              <Box mb={2}>
                <ViewColumns columnEnums={columnEnums} />
              </Box>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Box flex={1}>
                  {!isNewCustomView && (
                    <Box display="inline-flex">
                      <Tooltip
                        title={
                          customViews?.length === 1 ? `Last custom view cannot be deleted` : ''
                        }
                      >
                        <div>
                          <Button
                            color="error"
                            type="button"
                            startIcon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                            onClick={handleDelete}
                            disabled={customViews?.length === 1}
                          >
                            Delete View
                          </Button>
                        </div>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <SaveButton disabled={!isValid || isSubmitting || (isNewCustomView && !dirty)} />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
