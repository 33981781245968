import { Dispatch, FC, useContext, useEffect, useState, SetStateAction, useRef } from 'react';
import { faChevronLeft, faEnvelope, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, useMediaQuery, Button, Grid, Stack, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers';
import { DisplayGroup, Loader, SendEmailModal, TextField, Link, ExternalLink } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {
  IAccountDetail,
  IAddress,
  IInvoiceDetail,
  ITransactionAccountingStatus,
} from '../../models';
import {
  deleteInvoice,
  getInvoice,
  putInvoice,
  generateReport,
  sendInvoiceEmail,
  getAccount,
  createInvoice,
  putInvoiceReference,
  getTransactionAccountingStatus,
} from '../../fetch';
import { UserContext } from '../../context';
import {
  downloadFile,
  getLegacyUrl,
  formatAddressBlock,
  formatShortFriendlyDateWithTime,
} from '../../helpers';
import { InvoiceLines } from './invoice-lines';
import { useConfirm } from '../../hooks';
import { SaveButton, Card } from '../../components';
import { defaultUnsavedChangesMessage } from '../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';
import { BrandingContext } from '../../context/branding-context';

interface FormValues {
  userId: string;
  userName: string;
  invoiceDate: string;
  description: string;
}

const DEFAULT_VALUES = {
  userId: '',
  userName: '',
  invoiceDate: '',
  description: '',
};

const InvoiceDetailSchema = Yup.object().shape({
  userId: Yup.string().required('Required'),
  invoiceDate: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export interface InvoiceDetailProps {
  invoiceId: string;
  accountId?: string | null;
  needsRefresh?: string | null;
  isForm?: boolean;
  fetchScheduledService?: () => void;
  handleModalClose?: () => void;
  setInvoiceNumber?: Dispatch<SetStateAction<number | null>>;
}

export const InvoiceDetail: FC<InvoiceDetailProps> = ({
  invoiceId,
  accountId,
  needsRefresh,
  isForm,
  fetchScheduledService,
  handleModalClose,
  setInvoiceNumber,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width: 567px)`);
  const { user } = useContext(UserContext);
  const isNewInvoice = invoiceId === 'new';
  const confirm = useConfirm();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const { v2Customers } = useFlags();
  const { isPoolService } = useContext(BrandingContext);

  const shouldRedirectRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<IInvoiceDetail | null>(null);
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
  const [isPrinting, setPrinting] = useState(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false);
  const [account, setAccount] = useState<IAccountDetail | undefined>();
  const [initialValues, setInitialValues] = useState<FormValues>(DEFAULT_VALUES);

  const handleRedirect = () => {
    history.push(redirect ?? '/billing');
  };

  const { data: accountingStatus } = useQuery<ITransactionAccountingStatus, Error>(
    ['getTransactionAccountingStatus', invoiceId],
    () => getTransactionAccountingStatus(invoiceId!),
    {
      enabled: !isNewInvoice && isPoolService,
    }
  );

  const loadInvoiceDetail = async (invoiceId: string) => {
    setIsLoading(true);
    try {
      const invoice = await getInvoice(invoiceId);
      setCurrentInvoice(invoice);
      setInvoiceNumber?.(invoice.invoiceNumber);
      setInitialValues({
        userId: invoice.userId,
        userName: invoice.userName,
        invoiceDate: invoice.invoiceDate,
        description: invoice.reference || '',
      });
    } catch (error) {
      enqueueSnackbar(`Error loading invoice, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAccount = async (accountId: string) => {
    try {
      setIsLoading(true);
      const loadedAccount = await getAccount(accountId);
      setAccount(loadedAccount);

      setInitialValues({
        userId: user?.userId || '',
        userName: '',
        invoiceDate: new Date().toISOString(),
        description: '',
      });
    } catch (error) {
      enqueueSnackbar(`Error loading new invoice information, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isNewInvoice && accountId) {
      loadAccount(accountId);
    }
    if (invoiceId && !isNewInvoice) {
      loadInvoiceDetail(invoiceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, accountId, isNewInvoice, needsRefresh]);

  const handlePrint = async () => {
    try {
      setPrinting(true);
      const res = await generateReport('f52fb5d3-8ec2-4c8d-9ca1-1d1e39017d9a', {
        parameters: [
          {
            reportParameterId: '3f545c51-d316-4d49-a170-161854994323',
            value: invoiceId,
          },
        ],
      });
      if (res && res.type.includes('application/pdf')) {
        return downloadFile(
          res,
          currentInvoice?.accountName
            ? `${currentInvoice?.accountName} - ${currentInvoice?.invoiceDate}.pdf`
            : ''
        );
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'An error occurred while printing your report', {
        variant: 'error',
      });
    } finally {
      setPrinting(false);
    }
  };

  const handleDelete = async () => {
    const confirmed = await confirm('Are you sure you want to delete this invoice?');
    if (!confirmed) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteInvoice(invoiceId);
      if (isForm) {
        handleRedirect();
      } else {
        fetchScheduledService && fetchScheduledService();
      }
      enqueueSnackbar(`Invoice was successfully deleted`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Error deleting invoice, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  let accountName: string | undefined | null;
  let address: IAddress | undefined | null;
  if (isNewInvoice) {
    if (account) {
      accountName = account.name;
      address = account.address;
    }
  } else {
    address = currentInvoice?.address;
    accountName = currentInvoice?.accountName;
  }

  return (
    <div>
      <Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={InvoiceDetailSchema}
          onSubmit={async (values: FormValues, { setSubmitting }) => {
            try {
              setSubmitting(true);
              if (isNewInvoice) {
                const newInvoiceId = await createInvoice({
                  accountId: account!.accountId,
                  officeId: user!.officeId!,
                  ...values,
                });
                enqueueSnackbar('Invoice was created successfully', {
                  variant: 'success',
                });
                if (isForm) {
                  history.replace(`/billing/invoices/${newInvoiceId}?redirect=${redirect}`);
                }
              } else if (currentInvoice?.isProcessed) {
                await putInvoiceReference({ description: values.description }, invoiceId);
                enqueueSnackbar('Invoice description updated!', {
                  variant: 'success',
                });
                if (handleModalClose) {
                  return handleModalClose();
                }
                if (isForm && shouldRedirectRef.current) {
                  handleRedirect();
                }
              } else {
                await putInvoice(
                  {
                    ...values,
                    accountId: currentInvoice!.accountId,
                    officeId: user!.officeId!,
                    details: currentInvoice?.details ?? '',
                  },
                  invoiceId
                );
                enqueueSnackbar('Invoice updated!', {
                  variant: 'success',
                });
                if (handleModalClose) {
                  return handleModalClose();
                }
                if (isForm && shouldRedirectRef.current) {
                  return handleRedirect();
                }
              }
              setSubmitting(false);
            } catch (err: any) {
              enqueueSnackbar(
                err?.Detail ||
                `Error ${isNewInvoice ? 'creating' : 'editing'} invoice, please try again.`,
                { variant: 'error' }
              );
              setSubmitting(false);
            }
          }}
        >
          {({
            resetForm,
            isSubmitting,
            values,
            initialValues,
            setFieldValue,
            errors,
            touched,
            dirty,
            isValid,
            handleSubmit,
          }) => {
            const goBack = () => {
              if (isNewInvoice) {
                history.goBack();
              } else {
                history.push(redirect ?? '/billing');
              }
            };
            const reset = async (resetForm: any) => {
              if (deepEqual(initialValues, values)) {
                resetForm();
                goBack();
                return;
              }
              if (await confirm(defaultUnsavedChangesMessage)) {
                resetForm();
                goBack();
              }
            };
            const renderChildContent = () => {
              return (
                <>
                  <Box mt={2} mb={2}>
                    <Card>
                      {isPrinting && (
                        <Loader position="centered" type="overlay" title="Printing..." />
                      )}
                      {(isLoading || isSubmitting) && (
                        <Loader
                          position="centered"
                          type="overlay"
                          title={isSubmitting ? 'Saving...' : 'Loading...'}
                        />
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} xl={4} className="col-print-6">
                              <DisplayGroup label="Customer" labelId="accountName">
                                {v2Customers ? (
                                  <Link to={`/customers/${currentInvoice?.accountId}`}>
                                    {accountName}
                                  </Link>
                                ) : (
                                  <ExternalLink
                                    to={`${getLegacyUrl()}/Office/Account/View/${
                                      currentInvoice?.accountId
                                    }`}
                                  >
                                    {accountName}
                                  </ExternalLink>
                                )}
                              </DisplayGroup>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              xl={isNewInvoice ? 6 : 4}
                              className="col-print-6"
                            >
                              <DisplayGroup
                                label="Account Address"
                                labelId="accountAddress"
                                component="div"
                              >
                                {!!address && (
                                  <>
                                    {!!address.addressName && (
                                      <Typography component="span">
                                        {address?.addressName}
                                      </Typography>
                                    )}
                                    <Typography component="address">
                                      {formatAddressBlock(
                                        address?.street,
                                        address?.city,
                                        address?.state,
                                        address?.postalCode
                                      )}
                                    </Typography>
                                  </>
                                )}
                              </DisplayGroup>
                            </Grid>

                            {!isNewInvoice && (
                              <Grid item xs={12} md={6} xl={4} className="col-print-6">
                                <DisplayGroup label="Created By" labelId="userId">
                                  {values?.userName ?? ''}
                                </DisplayGroup>
                              </Grid>
                            )}

                            {!isNewInvoice &&
                              (!!accountingStatus?.accountingIntegrationStatus ||
                                !!accountingStatus?.exportStatus) && (
                                <Grid item xs={12} md={6} xl={4} className="col-print-6">
                                  <DisplayGroup
                                    label={`Accounting ${
                                      accountingStatus?.exportStatus ? 'Exported' : 'Integration'
                                    } Status`}
                                  >
                                    <>
                                      <Typography>
                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                          {accountingStatus?.exportStatus?.exportDateTime
                                            ? 'Date: '
                                            : 'Queued as of: '}
                                        </Typography>
                                        <Typography component="span">
                                          {formatShortFriendlyDateWithTime(
                                            accountingStatus?.exportStatus?.exportDateTime! ??
                                              accountingStatus?.accountingIntegrationStatus
                                                ?.queuedDate
                                          )}
                                        </Typography>
                                      </Typography>
                                      <Typography>
                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                          {accountingStatus?.exportStatus?.exportDateTime
                                            ? 'User: '
                                            : 'Status: '}
                                        </Typography>

                                        <Typography component="span">
                                          {accountingStatus?.exportStatus?.username ??
                                            `${
                                              accountingStatus?.accountingIntegrationStatus?.status
                                            } as of ${formatShortFriendlyDateWithTime(
                                              accountingStatus?.accountingIntegrationStatus
                                                ?.statusDate!
                                            )}`}
                                        </Typography>
                                        {!!accountingStatus?.accountingIntegrationStatus &&
                                          accountingStatus?.accountingIntegrationStatus?.status ===
                                            'Failed' && (
                                            <Typography>
                                              <Typography
                                                component="span"
                                                sx={{ fontWeight: 'bold' }}
                                              >
                                                Reason Code:{' '}
                                              </Typography>
                                              <Typography component="span">
                                                {
                                                  accountingStatus?.accountingIntegrationStatus
                                                    ?.reasonCode
                                                }
                                              </Typography>
                                            </Typography>
                                          )}
                                      </Typography>
                                    </>
                                  </DisplayGroup>
                                </Grid>
                              )}

                            <Grid item xs={12} md={12} xl={3} className="col-print-12">
                              <Stack
                                gap={2}
                                flexDirection={{
                                  xs: 'column',
                                  md: 'row',
                                  xl: 'column',
                                }}
                              >
                                <DatePicker
                                  label="Invoice Date"
                                  format="MM/dd/yyyy"
                                  onChange={(date: any) => {
                                    setFieldValue('invoiceDate', date);
                                  }}
                                  disabled={currentInvoice?.isProcessed}
                                  value={values.invoiceDate ? new Date(values.invoiceDate) : null}
                                  slotProps={{
                                    textField: {
                                      error: !!errors?.invoiceDate && !!touched?.invoiceDate,
                                      size: 'small',
                                      className: classes.input,
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} md={12} xl={9} className="col-print-12">
                              <TextField
                                fullWidth
                                multiline
                                required
                                rows={4}
                                size="small"
                                autoComplete="nope"
                                label="Description"
                                name="description"
                                className={clsx('print--none', classes.input)}
                              />
                              {/* Used for the print screen only */}
                              {values.description && (
                                <div className={clsx('print--only', classes.input)}>
                                  <label className="print--shrink-label MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-ahtaft-MuiFormLabel-root-MuiInputLabel-root">
                                    Description
                                  </label>
                                  {values.description}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {!isNewInvoice && (
                          <Grid item xs={12} sm={2}>
                            <Box display="flex" justifyContent="flex-end" className="print--none">
                              <Box display="flex" gap={2}>
                                <Button
                                  color="primary"
                                  size="small"
                                  onClick={handlePrint}
                                  variant="outlined"
                                  startIcon={<FontAwesomeIcon icon={faPrint} size="sm" />}
                                >
                                  Print
                                </Button>
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  onClick={() => setEmailModalIsOpen(true)}
                                  startIcon={<FontAwesomeIcon icon={faEnvelope} size="sm" />}
                                >
                                  Email
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      {!isLoading && (
                        <>
                          <Grid item xs={12} mt={2}>
                            <Divider />
                          </Grid>
                          <Grid container justifyContent={'space-between'}>
                            <Stack
                              gap={2}
                              flexDirection={isMobile ? 'column' : 'row'}
                              width={isMobile ? '100%' : 'auto'}
                              mt={1}
                            >
                              <Button
                                color="inherit"
                                onClick={() => {
                                  if (handleModalClose) {
                                    return handleModalClose();
                                  }
                                  if (isForm) {
                                    reset(resetForm);
                                  } else {
                                    resetForm();
                                  }
                                }}
                                startIcon={dirty ? '' : <FontAwesomeIcon icon={faChevronLeft} />}
                              >
                                {dirty ? `Cancel` : `Back`}
                              </Button>
                              <SaveButton
                                handleSave={() => {
                                  shouldRedirectRef.current = false;
                                  handleSubmit();
                                  resetForm({ values: values })
                                }}
                                disabled={
                                  (!dirty && deepEqual(initialValues, values)) ||
                                  isSubmitting ||
                                  !isValid
                                }
                                text={isNewInvoice ? 'Create' : 'Save'}
                              />
                              <SaveButton
                                handleSave={() => {
                                  shouldRedirectRef.current = true;
                                  handleSubmit();
                                }}
                                disabled={
                                  (!dirty && deepEqual(initialValues, values)) ||
                                  isSubmitting ||
                                  !isValid
                                }
                                text={isNewInvoice ? 'Create and Close' : 'Save and Close'}
                              />
                            </Stack>
                            <Stack
                              gap={2}
                              flexDirection={isMobile ? 'column' : 'row'}
                              width={isMobile ? '100%' : 'auto'}
                              mt={1}
                            >
                              {!currentInvoice?.isProcessed &&
                                !isNewInvoice &&
                                !currentInvoice?.isForRepair && (
                                  <Button
                                    color="error"
                                    title="Delete Invoice"
                                    onClick={handleDelete}
                                    disabled={isLoading || isPrinting}
                                    startIcon={
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        size="lg"
                                        className={classes.icon}
                                      />
                                    }
                                    fullWidth={isMobile}
                                  >
                                    Delete
                                  </Button>
                                )}
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Card>
                  </Box>
                </>
              );
            };
            const renderChildWrapper = () => {
              return isForm ? (
                <Form>{renderChildContent()}</Form>
              ) : (
                <div>{renderChildContent()}</div>
              );
            };
            return <>{renderChildWrapper()}</>;
          }}
        </Formik>
      </Box>
      {(isDeleting || isUpdatingInvoice) && (
        <Loader
          type="overlay"
          position="centered"
          title={isDeleting ? 'Deleting...' : 'Updating...'}
        />
      )}
      {!isNewInvoice && (
        <Box mt={2}>
          <InvoiceLines
            transactionId={invoiceId}
            isProcessed={currentInvoice?.isProcessed}
            setIsUpdatingInvoice={setIsUpdatingInvoice}
            accountId={currentInvoice?.accountId ?? null}
            invoice={currentInvoice as IInvoiceDetail}
            isUpdatingInvoice={isUpdatingInvoice}
            transactionDate={initialValues.invoiceDate}
          />
        </Box>
      )}
      <SendEmailModal
        isInvoiceType
        open={emailModalIsOpen}
        onClose={() => setEmailModalIsOpen(false)}
        modalTitle="Send Invoice Email"
        onSubmit={async formValues => {
          await sendInvoiceEmail(invoiceId, formValues);
        }}
        defaultToAddress={currentInvoice?.emails ?? []}
        showSendAsAgreementToggle={false}
      />
    </div>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  input: {
    margin: theme.spacing(1, 0, 0, 0),
    lineHeight: '24px',
    '@media print': {
      marginTop: theme.spacing(2),
    },
    '&& .MuiInputLabel-root': {
      maxWidth: `calc(100% - 48px)`,
    },
  },
}));
