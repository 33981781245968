import { FC, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Stack } from '@mui/material';
import {
  TableActionsMenu,
  TableLegend,
  TableLegendIcon,
  Card,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
} from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCamera,
  faCircleCheck,
  faEdit,
  faFlask,
  faTrash,
  faVial,
  faWaterLadder,
} from '@fortawesome/free-solid-svg-icons';
import { getNews } from '../../../fetch';
import { dataGridExampleColumns } from './data-grid-example-columns';
import { INews } from '../../../models';
import { GridRenderCellParams, GridSortDirection } from '@mui/x-data-grid';
import { format } from 'date-fns';

interface IServerSideDataGridExample {
  showEmpty?: boolean;
  showActionButtons?: boolean;
  showActionMenu?: boolean;
  showPagination?: boolean;
  showLegend?: boolean;
  actionMenuItems?: any;
  id: string;
  perPageOverride?: number;
}

export const StyleGuideServerSideDataGridExample: FC<IServerSideDataGridExample> = ({
  id,
  showEmpty = false,
  showActionButtons = true,
  showActionMenu = false,
  showPagination = false,
  showLegend = false,
  actionMenuItems,
  perPageOverride,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const fetchTestData = async ({
    sortColumn,
    sortDirection,
    perPage,
    page,
  }: {
    sortColumn?: string;
    sortDirection?: GridSortDirection;
    perPage: number;
    page: number;
  }) => {
    const params = {
      sortBy: sortColumn,
      sortDirection: sortDirection ?? 'asc',
      perPage,
      page: page + 1,
    };

    try {
      if (showEmpty) {
        return {
          rows: [],
          rowCount: 0,
        };
      }
      const res = await getNews(params);
      const data = res.records.map((row, index) => ({ ...row, index }));
      return {
        rows: data,
        rowCount: res.totalRecordCount,
      };
    } catch (error) {
      enqueueSnackbar(`Error loading the example table data, please try again.`, {
        variant: 'error',
      });
      throw error;
    }
  };

  const dataFetcher: GridDataFetcher<INews> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      return fetchTestData({
        sortColumn,
        sortDirection,
        perPage,
        page,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    isLoading,
  } = useDataGrid<INews>({
    initialOptions: {
      page: 0,
      pageSize: perPageOverride ?? 10,
      gridKeyName: `data-grid-example`,
      sortColumn: 'displayDate',
      sortDirection: 'asc',
    },
    dataFetcher: dataFetcher,
  });

  const actionButtons = (
    <Stack direction="row" gap={1}>
      <IconButton
        color="primary"
        title="Edit Test Item"
        onClick={async () =>
          enqueueSnackbar('Edit Action', {
            variant: 'info',
          })
        }
      >
        <FontAwesomeIcon icon={faEdit} size="sm" />
      </IconButton>
      <IconButton
        color="error"
        title="Delete Test Item"
        onClick={async () =>
          enqueueSnackbar('Delete Action', {
            variant: 'error',
          })
        }
      >
        <FontAwesomeIcon icon={faTrash} size="sm" />
      </IconButton>
    </Stack>
  );
  const actionMenu = <TableActionsMenu id={`${id}-actions`}>{actionMenuItems}</TableActionsMenu>;
  const columns = useMemo(
    () =>
      [
        ...dataGridExampleColumns,
        (showActionButtons || showActionMenu) && {
          id: 'actions',
          Header: '',
          accessor: '',
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<any>) => {
            return (
              <Box
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                className="print--none"
              >
                {showActionMenu && actionMenuItems
                  ? actionMenu
                  : showActionButtons
                  ? actionButtons
                  : null}
              </Box>
            );
          },
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showEmpty, showPagination, showActionButtons, showActionMenu]
  );

  return (
    <Card>
      <Box mt={1}>
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: INews) => row.newsItemId}
          rows={rows}
          columns={columns as any[]}
          hasMobileLayout
          mobileProps={{
            mobileCustomDefaultAccessor: (val: INews) =>
              `${format(new Date(val.displayDate), 'L/d/yy')} ${val.category} - ${val.subject}`,
            showHandleActions: true,
            truncateAccordionLabel: true,
          }}
          hideFooter={!showPagination}
          rowCount={recordCount}
          onPageSizeChange={onPageSizeChange}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          loading={isLoading}
          onPageChange={onPageChange}
        />
      </Box>
      {showLegend && (
        <TableLegend>
          <TableLegendIcon icon={faWaterLadder} helpText="Site has valid Pool Equipment" />
          <TableLegendIcon icon={faFlask} helpText="Water Test has been Performed" />
          <TableLegendIcon icon={faVial} helpText="Chemical Use has been Logged" />
          <TableLegendIcon icon={faCamera} helpText="Service Photo attached" />
          <TableLegendIcon icon={faCircleCheck} helpText="Service has been Completed" />
        </TableLegend>
      )}
    </Card>
  );
};
