import { FC, useState, useEffect } from 'react';
import { TextField, StandardTextFieldProps, MenuItem, InputAdornment } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { ISelectOption } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface ISelectAsyncProps extends StandardTextFieldProps {
  name: string;
  apiRequest: () => Promise<any>;
  transformResponse: (response: any) => ISelectOption[];
  hasClear?: boolean;
  onClear?: () => void;
  handleOptions?: (val: ISelectOption[]) => void;
  handleResponseOptions?: (val: any) => void;
}

export const SelectAsync: FC<ISelectAsyncProps> = ({
  name,
  apiRequest,
  transformResponse,
  hasClear = false,
  onClear,
  handleOptions,
  handleResponseOptions,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiRequest();
        const data = transformResponse(response);
        setOptions(data);
        handleOptions && handleOptions(data);
        handleResponseOptions && handleResponseOptions(response);
      } catch (err) {
        enqueueSnackbar(`Error loading ${name} dropdown`, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { value } = target;
    setFieldValue(name, value);
  };

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    ...field,
    ...props,
    select: true,
    label: isLoading ? 'Loading...' : props.label,
    onChange: props.onChange ?? handleChange,
    disabled: props.disabled ?? isLoading,
    InputProps: {
      ...props.InputProps,
      ...(hasClear
        ? {
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  position: 'absolute',
                  right: 35,
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  title="Clear"
                  onClick={() => {
                    setFieldValue(name, '');
                    onClear && onClear();
                  }}
                />
              </InputAdornment>
            ),
          }
        : undefined),
    },
  };

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <TextField {...config}>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
