import { axios } from '../helpers';
import {
  IGenerateReportOptions,
  IReport,
  IReportParameter,
  IResponse,
  ReportFormat,
} from '../models';
import qs from 'querystring';
import { userSignal } from '../signals';

export const getReport = async (reportId: string, officeId?: string | null) => {
  const res = await axios.get<IReport>(`/api/reports/${reportId}`, {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: (params: any) => qs.stringify(params),
  });
  return res.data;
};

export const getReportParameters = async (officeId: string, reportId: string) => {
  try {
    const res = await axios.get<IReportParameter[]>(
      `/api/reports/${reportId}/parameters?OfficeId=${officeId}`,
      {
        params: {
          officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        paramsSerializer: (params: any) => qs.stringify(params),
      }
    );
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const generateReportAndSave = async (
  reportId: string,
  options: IGenerateReportOptions,
  format: ReportFormat = ReportFormat.Pdf,
  officeId?: string | null
) => {
  try {
    const res = await axios.post<string>(`/api/reports/${reportId}/generate/${format}/save`, {
      ...options,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getGeneratedReport = async (reportGuid: string): Promise<Blob> => {
  try {
    const res = await axios.get(`/api/reports/generated/${reportGuid}`, {
      responseType: 'blob',
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
}

export const generateReport = async (
  reportId: string,
  post: IGenerateReportOptions,
  format: ReportFormat = ReportFormat.Pdf,
  officeId?: string | null
) => {
  try {
    const res = await axios.post(
      `/api/reports/${reportId}/generate/${format}`,
      {
        ...post,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      {
        responseType: 'blob',
      }
    );
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};


export const getReportOptions = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  officeId?: string | null;
}): Promise<IResponse<IReport[]>> => {
  try {
    const res = await axios.get(`/api/reports`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
