import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

interface IConfirmDialogProps {
  message: string;
  showCloseButton?: boolean;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({ message, showCloseButton }) => {
  const classes = useStyles({ showCloseButton });

  return (
    <Box className={classes.confirmDialogContainer}>
      <div className={classes.confirmDialogHeader}>
        <Typography className={classes.headerTitle}>{message}</Typography>
        {showCloseButton && (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => {
              const element = document.querySelector('.MuiBackdrop-root') as HTMLElement;
              if (element) {
                element.click();
              }
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        )}
      </div>
      <Divider className={classes.divider} />
    </Box>
  );
};

const useStyles = makeStyles<Theme, { showCloseButton: boolean | undefined }>((theme: Theme) => {
  return {
    confirmDialogContainer: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: ({ showCloseButton }) => (showCloseButton ? '1rem' : undefined),
    },
    confirmDialogHeader: {
      width: '100%',
      padding: 16,
    },
    headerTitle: {
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    divider: {
      borderColor: theme.palette.secondary.main,
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  };
});
