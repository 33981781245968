import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { PrimaryCard } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import logo from '../../../styles/images/PCP-Print-Logo.jpg';

export const StyleGuideCards = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Cards - Primary">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The primary card component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-card/#basic-card" target="_blank">
              MUI Card
            </Link>{' '}
            component. This custom component allows for less code, embedding the header and body
            components within it, and custom coloring by implementing a <em>primary</em> colored
            background for the card header with a <em>secondary</em> border for an accent. This
            component also has <em>avatar</em> functionality baked into it, so that a card can
            render an avatar image or a secondary colored FortAwesome icon.
          </Typography>
          <Typography mb={1}>
            This component is primarily used for special card list displays (i.e. new leads).
          </Typography>
          <code>
            Props:
            <br />- children?: any
            <br />- avatar?: JSX.Element
            <br />- icon?: IconProp
            <br />- title: string
            <br />- subheader?: string
            <br />- action?: JSX.Element
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <PrimaryCard
              title="Card Title"
              subheader="This is a primary card example"
              icon={faWrench}
            >
              This is the card content.
            </PrimaryCard>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;PrimaryCard title="Card Title" subheader="This is a primary card example"
            icon=&#123;faWrench&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;This is the card content. <br />
            &lt;/PrimaryCard&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <PrimaryCard title="Avatar Image Example" avatar={<img src={logo} alt="" />}>
              This is the card content.
            </PrimaryCard>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;PrimaryCard title="Avatar Image Example" avatar=&#123;&lt;img src=&#123;logo&#125;
            alt="" /&gt;&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;This is the card content. <br />
            &lt;/PrimaryCard&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
