import { Grid, Box, Stack } from '@mui/material';
import { PendingInvoices } from './pending-invoices';
import { MonthlyBilling } from './monthly-billing';
import { BillingLog } from './billing-log';
import { useState } from 'react';

export const BillingDetail = () => {
  const [needsRefresh, setNeedsRefresh] = useState(false);

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Stack gap={2}>
            <MonthlyBilling setRefresh={setNeedsRefresh} />
            <BillingLog refresh={needsRefresh} setRefresh={setNeedsRefresh} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <PendingInvoices />
        </Grid>
      </Grid>
    </Box>
  );
};