import { Autocomplete, TextField, Box, IconButton } from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import { UserContext } from '../../context';
import { ISiteSimple } from '../../models';
import { getSitesSimple } from '../../fetch';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

interface ISiteAutocomplete {
  handleChange: (val: string) => void;
  siteId?: string | null;
  isDisabled?: boolean;
  isRequired?: boolean;
  selectedSite?: ISiteSimple | null;
  setSelectedSite?: (val: ISiteSimple | null) => void;
  handleOptions?: (val: ISiteSimple[]) => void;
  handleBlur?: (e: any) => void;
  showInvalidAddress?: boolean;
  showSiteLink?: boolean;
  accountId?: string;
  autoFill?: boolean;
}

export const SiteAutocomplete: FC<ISiteAutocomplete> = ({
  handleChange,
  siteId,
  isDisabled,
  selectedSite,
  handleOptions,
  handleBlur,
  isRequired = true,
  showInvalidAddress = true,
  showSiteLink = true,
  accountId,
  setSelectedSite,
  autoFill = true,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState<ISiteSimple | null>(
    selectedSite ?? null
  );
  const [siteNames, setSiteNames] = useState<ISiteSimple[]>([]);
  const [isLoadingSiteNames, setIsLoadingSiteNames] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const fetchSiteNames = async () => {
    setIsLoadingSiteNames(true);
    try {
      setAutocompleteValue(null);
      const res = await getSitesSimple({ perPage: -1, officeId: user?.officeId, accountId });
      setSiteNames(res.records);
      handleOptions?.(res.records);
      if (siteId) {
        setAutocompleteValue(
          (res.records.find(site => site.siteId === siteId) as ISiteSimple) ?? null
        );
        return handleChange(siteId);
      }
      if (res.records?.length === 1 && autoFill) {
        setAutocompleteValue(res.records[0]);
        return handleChange(res.records?.[0].siteId);
      }
    } catch (error) {
      enqueueSnackbar(`Error loading sites, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSiteNames(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchSiteNames();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (selectedSite !== undefined) {
      setAutocompleteValue(selectedSite);
    }
  }, [selectedSite]);

  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      <Autocomplete
        fullWidth
        value={autocompleteValue}
        onChange={(event, newValue: ISiteSimple | null, reason) => {
          setAutocompleteValue(newValue);
          if (reason === 'clear') {
            return handleChange('');
          }
          setSelectedSite?.(newValue);
          handleChange(newValue?.siteId as string);
        }}
        disabled={isDisabled || isLoadingSiteNames}
        selectOnFocus
        handleHomeEndKeys
        onBlur={handleBlur}
        loading={isLoadingSiteNames}
        id="selected-site"
        options={siteNames || []}
        getOptionLabel={(option: ISiteSimple) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          return `${option.description} (${option.address.street})`;
        }}
        renderOption={(props, option: ISiteSimple) => {
          return (
            <li
              {...props}
              style={{
                fontSize: '14px',
                textTransform: 'capitalize',
              }}
              key={option.siteId}
            >
              {`${option.description} (${option.address.street} ${option.address.city}, ${option.address.state})`}
            </li>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              required={isRequired}
              placeholder="Select Site"
              key={params.id}
              size="small"
              autoComplete="on"
              label={isLoadingSiteNames ? 'Loading...' : 'Site'}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                'data-testid': 'site-select'
              }}
              error={
                showInvalidAddress &&
                !isLoadingSiteNames &&
                !!autocompleteValue &&
                !autocompleteValue?.address?.hasValidLatLong
              }
              helperText={
                showInvalidAddress &&
                !isLoadingSiteNames &&
                autocompleteValue &&
                !autocompleteValue?.address?.hasValidLatLong && (
                  <>
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      style={{ marginRight: '.25rem' }}
                    />
                    Site Address Invalid
                  </>
                )
              }
            />
          );
        }}
      />
      {showSiteLink && (accountId || autocompleteValue?.accountId) && autocompleteValue?.siteId && (
        <IconButton
          color="secondary"
          onClick={() => {
            window.open(
              `/customers/${autocompleteValue?.accountId}?activeTab=sites&siteId=${autocompleteValue?.siteId}`,
              '_blank'
            );
          }}
        >
          <FontAwesomeIcon fontSize="16px" icon={faExternalLink} title="Open Site" />
        </IconButton>
      )}
    </Box>
  );
};
