import React, { FC, useEffect, useRef } from "react";
import { Box, Button, Popover } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { formatDate } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../styles";

interface IDatePickerPopoverProps {
  defaultDate?: Date;
  onChange: (date: string | Date | null) => void;
  anchorEl: HTMLElement | null;
  disabledDates?: (string | Date)[];
  anchorOrigin?: { vertical: 'top' | 'center' | 'bottom', horizontal: 'left' | 'center' | 'right' };
  handleClose: () => void;
};

export const DatePickerPopover: FC<IDatePickerPopoverProps> = ({
  defaultDate,
  onChange,
  handleClose,
  disabledDates,
  anchorOrigin,
  anchorEl
}) => {
  const open = Boolean(anchorEl);
  const newRef = useRef<HTMLDivElement>(null);
  const handleDisabledDates = (date: Date) => {
    if (date && disabledDates) {
      return disabledDates.includes(formatDate(date) ?? '');
    }
    return false;
  };

  const handleClickOutside = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      handleClose();
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'left',
        }}
    >
      <div ref={newRef}>
        <Box textAlign={'right'}>
          <Button
            style={{
              height: '100%',
              background: 'transparent',
              cursor: 'pointer',
              boxShadow: 'none',
              minWidth: 'auto',
              marginTop: '5px',
            }}
            onClick={handleClose}
          >
            <FontAwesomeIcon
              icon={faClose}
              title="Close"
              color={theme.palette.primary.main}
              size="lg"
            />
          </Button>
        </Box>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          defaultValue={defaultDate ?? new Date()}
          onChange={(date) => onChange(date)}
          shouldDisableDate={handleDisabledDates}
        />
      </div>
    </Popover>
  )
};