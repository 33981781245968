import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuidePageComponent = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Page Components">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The page component is the basis on which all pages are built. It handles the document
            title (browser title) rendering. It also houses references and logic pertaining to the
            side navigation, the header, and footer components.
          </Typography>
          <Typography mb={1}>
            This component will be the most used to create new pages in the application. However,
            there are other page type components created for different areas of the application that
            you should be aware of when developing:
            <ul className="my-3">
              <li>
                <strong>StyleGuidePage</strong>: For rendering the style guide navigation, built
                upon the Page component
              </li>
            </ul>
          </Typography>
          <code>
            Props:
            <br />- children: ReactNode
            <br />- title: string <br />- heading?: string
            <br />- customPageHeader?: boolean
            <br />- hasOverflow?: boolean
            <br />- isExternal?: boolean
            <br />- className?: string
            <br />- button?: JSX.Element
            <br />- sideBarLinks?: IRoute[]
            <br />- showAlerts?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Page title="Custom Page Title"&gt;Page Content Goes Here&lt;/Page&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
