import { Calendar, dateFnsLocalizer, Event, ToolbarProps, View } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import enUS from 'date-fns/locale/en-US';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  lighten,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  ButtonGroup,
  Button,
} from '@mui/material';
import { IRepair, ICalendarDateRange, IListUser, IOneTimeServiceDetail } from '../../../models';
import {
  isBefore,
  format,
  parse,
  startOfWeek,
  getDay,
  startOfDay,
  endOfWeek,
  endOfDay,
} from 'date-fns';
import { formatInputPhoneNumber, formatTime, generateUUID } from '../../../helpers';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { IColorSetMap } from '../../../models/colors';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const locales = {
  'en-US': enUS,
};

const DnDCalendar = withDragAndDrop(Calendar as any);

interface ICustomEvent extends Event {
  assignedTo?: string;
  userId?: string;
  repairVisitId?: string;
  visit?: IRepair | null;
  startTime?: string | Date | null;
  endTime?: string | Date | null;
  isNew?: boolean;
  isMoved?: boolean;
  siteName?: string;
  siteAddress?: any;
  accountPhoneNumber?: any;
  problemDescription?: string;
  serviceNotes?: string;
}

interface IOTSVisitCalendar {
  allVisits: any[];
  visits: any[];
  techs: IListUser[];
  handleUpdateVisits: (val: any[]) => void;
  selectedTech: IListUser | null;
  selectedTechId: string;
  selectedDateRange: ICalendarDateRange | undefined;
  visitsColorMap: IColorSetMap;
  service?: IOneTimeServiceDetail | null;
  allowCreate?: boolean;
  setDeletedVisits: (val: string[]) => void;
  deletedVisits: string[];
  setIsSingleViewMode: (val: boolean) => void;
  isMobile: boolean;
  setSelectedDateRange?: (val: ICalendarDateRange | undefined) => void;
  isSingleViewMode?: boolean;
  setSelectedTechnician: (userId: string, visitId: string, selectedTech: string) => void;
}

export const OTSVisitCalendar: FC<IOTSVisitCalendar> = ({
  allVisits,
  visits,
  handleUpdateVisits,
  selectedTech,
  selectedTechId,
  selectedDateRange,
  visitsColorMap,
  service,
  techs,
  allowCreate = true,
  setDeletedVisits,
  deletedVisits,
  setIsSingleViewMode,
  isMobile,
  setSelectedDateRange,
  isSingleViewMode,
  setSelectedTechnician,
}) => {
  const theme = useTheme();
  const [view, setView] = useState<View>('week');
  const { enqueueSnackbar } = useSnackbar();
  const key = `${new Date().getTime()}`;

  useEffect(() => {
    if (isMobile) {
      setSelectedDateRange?.({
        startDate: startOfDay(selectedDateRange?.startDate!),
        endDate: endOfDay(selectedDateRange?.startDate!),
      });
      return setView('day');
    }
    setSelectedDateRange?.({
      startDate: startOfWeek(startOfDay(selectedDateRange?.startDate!), { weekStartsOn: 1 }),
      endDate: endOfWeek(selectedDateRange?.startDate!, {
        weekStartsOn: 1,
      }),
    });
    return setView('week');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const localizer = useMemo(() => {
    return dateFnsLocalizer({
      format,
      parse,
      startOfWeek: () =>
        selectedDateRange
          ? startOfWeek(selectedDateRange?.startDate!, {
              weekStartsOn: selectedDateRange.startDate!.getDay() as any,
            })
          : startOfWeek,
      getDay,
      locales,
    });
  }, [selectedDateRange]);

  const handleResizeAndMove = (customEvent: ICustomEvent, data: any) => {
    const existing = visits.find(
      ev => ev?.visit?.repairVisitId === customEvent?.visit?.repairVisitId
    );

    const filtered = allVisits.filter(ev => ev.repairVisitId !== customEvent?.visit?.repairVisitId);
    if (existing) {
      handleUpdateVisits([
        ...filtered,
        {
          ...existing,
          ...data,
          ...customEvent.visit,
          serviceDate: startOfDay(data.start),
          startTime: data.start,
          endTime: data.end,
        },
      ]);
    }
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const isDateInPast = (date: Date | string) => isBefore(new Date(date), today);
  const isUnavailableDate = (date: Date | string, daysAvailable: string[]) =>
    !daysAvailable.includes(format(new Date(date), 'EEEE'));
  // 7am
  const minTime = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    7,
    0,
    0,
    0
  );
  // 8pm
  const maxTime = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    20,
    0,
    0,
    0
  );
  const checkDateIsValid = (startDate: Date, daysAvailable: string[], endDate?: Date): boolean => {
    if (startDate.getHours() < 7 || (endDate && endDate.getHours() > 20)) {
      enqueueSnackbar(`OTS visit can't be scheduled in that time frame`, {
        variant: 'warning',
      });
      return false;
    }
    if (isDateInPast(startDate)) {
      enqueueSnackbar(`OTS visit can't be scheduled in the past`, {
        variant: 'warning',
      });
      return false;
    }
    if (isUnavailableDate(startDate, daysAvailable)) {
      enqueueSnackbar('Tech not available on that day.', {
        variant: 'info',
      });
      return false;
    }
    return true;
  };

  return (
    <Box>
      {/* key is here to force the calendar to re-render when these values change */}
      <div key={key}>
        <DnDCalendar
          onNavigate={date => {
            setSelectedDateRange?.({
              startDate: startOfDay(new Date(date)),
              endDate: endOfDay(new Date(date)),
            });
          }}
          date={startOfDay(selectedDateRange?.startDate!)}
          defaultDate={startOfDay(selectedDateRange?.startDate!)}
          defaultView={isMobile ? 'day' : 'week'}
          view={view}
          views={['day', 'week']}
          components={{
            toolbar: (props: ToolbarProps) => {
              return (
                <Box
                  display="flex"
                  mb={1}
                  justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                  gap={2}
                  alignItems="center"
                >
                  {isSingleViewMode && (
                    <Typography>{format(selectedDateRange?.startDate!, 'EEEE')}</Typography>
                  )}
                  <ButtonGroup>
                    {isMobile
                      ? ['day']
                      : ['day', 'week'].map(view => {
                          return (
                            <Button
                              key={`${view}`}
                              color="primary"
                              size="small"
                              variant={view === props.view ? 'contained' : 'outlined'}
                              onClick={() => {
                                props.onView(view as View);
                                if (view === 'day') {
                                  setIsSingleViewMode(true);
                                  setSelectedDateRange?.({
                                    startDate: startOfDay(new Date()),
                                    endDate: endOfDay(new Date()),
                                  });
                                } else {
                                  setIsSingleViewMode(false);
                                  setSelectedDateRange?.({
                                    startDate: startOfWeek(
                                      startOfDay(selectedDateRange?.startDate!),
                                      { weekStartsOn: 1 }
                                    ),
                                    endDate: endOfWeek(selectedDateRange?.startDate!, {
                                      weekStartsOn: 1,
                                    }),
                                  });
                                }
                              }}
                            >
                              {view}
                            </Button>
                          );
                        })}
                  </ButtonGroup>
                </Box>
              );
            },
            day: {
              event: (props: any) => {
                return (
                  <CustomEvent
                    {...props}
                    events={allVisits}
                    setEvents={(events: any) => {
                      handleUpdateVisits(events);
                    }}
                    setDeletedEvents={setDeletedVisits}
                    deletedEvents={deletedVisits}
                    selectedTechId={selectedTechId}
                    setSelectedTechnician={setSelectedTechnician}
                    technicians={techs}
                  />
                );
              },
            },
            week: {
              event: (props: any) => {
                return (
                  <CustomEvent
                    {...props}
                    events={allVisits}
                    setEvents={(events: any) => {
                      handleUpdateVisits(events);
                    }}
                    setDeletedEvents={setDeletedVisits}
                    deletedEvents={deletedVisits}
                    selectedTechId={selectedTechId}
                    setSelectedTechnician={setSelectedTechnician}
                    technicians={techs}
                  />
                );
              },
            },
          }}
          onView={view => {
            setView(view);
            if (view === 'day') {
              setIsSingleViewMode(true);
            } else {
              setIsSingleViewMode(false);
            }
          }}
          dayLayoutAlgorithm={'no-overlap'}
          eventPropGetter={event => {
            const customEvent = event as ICustomEvent;
            const backgroundColor =
              visitsColorMap?.[customEvent?.visit?.assignedTo!]?.backgroundColor;
            const foregroundColor =
              visitsColorMap?.[customEvent?.visit?.assignedTo!]?.foregroundColor;

            if (customEvent?.visit?.startTime && isDateInPast(customEvent?.visit?.startTime)) {
              return {
                style: {
                  backgroundColor: theme.palette.grey[300],
                  color: '#000',
                },
              };
            }

            if (customEvent.isNew || customEvent?.visit?.isMoved) {
              return {
                style: {
                  backgroundColor: theme.palette.changed.main,
                  color: '#000',
                },
              };
            }
            if (backgroundColor) {
              return {
                style: {
                  backgroundColor,
                  color: foregroundColor,
                },
              };
            }

            return {};
          }}
          slotPropGetter={date => {
            // style the days based on the selected techs days available
            if (
              selectedTech &&
              selectedTech.daysAvailable.includes(format(new Date(date), 'EEEE'))
            ) {
              return {
                style: {
                  backgroundColor: lighten(theme.palette.secondary.main, 0.5),
                },
              };
            }
            return {};
          }}
          events={visits}
          min={minTime}
          max={maxTime}
          localizer={localizer}
          selectable
          resizable
          onEventDrop={({ event, ...rest }) => {
            const customEvent = event as ICustomEvent;
            const selectedTech = techs.find(tech => tech.userId === customEvent?.visit?.userId);
            if (
              checkDateIsValid(
                rest.start as Date,
                selectedTech?.daysAvailable as string[],
                rest.end as Date
              )
            ) {
              handleResizeAndMove(customEvent, { ...rest, isMoved: true });
            }
          }}
          onEventResize={({ event, ...rest }) => {
            const customEvent = event as ICustomEvent;
            const selectedTech = techs.find(tech => tech.userId === customEvent?.visit?.userId);
            if (
              checkDateIsValid(
                rest.start as Date,
                selectedTech?.daysAvailable as string[],
                rest.end as Date
              )
            ) {
              handleResizeAndMove(customEvent, { ...rest, isMoved: true });
            }
          }}
          onSelectSlot={slotInfo => {
            if (
              allowCreate &&
              checkDateIsValid(slotInfo.start, selectedTech?.daysAvailable as string[])
            ) {
              handleUpdateVisits([
                ...allVisits,
                {
                  startTime: slotInfo.start,
                  endTime: slotInfo.end,
                  assignedTo: selectedTech?.userName!,
                  userId: selectedTech?.userId!,
                  repairVisitId: generateUUID(),
                  isNew: true,
                  siteName: service?.siteName,
                  siteAddress: service?.accountAddress,
                  accountPhoneNumber: service?.accountPhoneNumber,
                  problemDescription: service?.problemDescription,
                  serviceNotes: service?.repairNotes,
                } as ICustomEvent,
              ]);
            }
          }}
        />
      </div>
    </Box>
  );
};

interface ICustomEvent {
  event: ICustomEvent;
  setEvents: (events: ICustomEvent[]) => void;
  events: ICustomEvent[];
  deletedEvents: string[];
  setDeletedEvents: (val: string[]) => void;
  selectedTechId: string;
  setSelectedTechnician: (userId: string, visitId: string, selectedTech: string) => void;
  technicians: IListUser[];
}

const CustomEvent: FC<ICustomEvent> = ({
  event,
  events,
  setEvents,
  deletedEvents,
  setDeletedEvents,
  selectedTechId,
  setSelectedTechnician,
  technicians,
}) => {
  const { visit } = event;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const isPast = isBefore(new Date(event.start!), today);
  const { push } = useHistory();
  const [assignedTech, setAssignedTech] = useState(visit?.assignedTo);
  const [technicianChanged, setTechnicianChanged] = useState(false);
  const classes = useStyles();

  const displayLinkToChangeTechnician = () => {
    if (
      selectedTechId === visit?.userId ||
      technicianChanged ||
      isPast ||
      unavailableDate(selectedTechId, visit?.serviceDate!)
    ) {
      return false;
    }
    return true;
  };
  const handleUpdateTech = (userId: string) => {
    const selectedTech = technicians.find(tech => tech.userId === userId)?.userName;
    setAssignedTech(selectedTech);
    setTechnicianChanged(true);
    setSelectedTechnician(userId, visit?.repairVisitId!, selectedTech!);
  };

  const unavailableDate = (userId: string, startDate: string) => {
    if (startDate !== undefined) {
      const tech = technicians.find(tech => tech.userId === userId);
      const dayAvailable = tech?.daysAvailable.includes(format(new Date(startDate), 'EEEE'));
      return !dayAvailable;
    } else {
      return false;
    }
  };

  return (
    <Tooltip
      title={
        <Box>
          <Stack>
            <Typography variant="caption">{visit?.siteName}</Typography>
            <Typography variant="caption">Tech: {assignedTech}</Typography>
          </Stack>
          <Box mt={1}>
            <Stack>
              <Typography variant="caption">{visit?.siteAddress?.street}</Typography>
              <Typography variant="caption">
                {visit?.siteAddress?.city && `${visit?.siteAddress?.city}, `}{' '}
                {visit?.siteAddress?.state} {visit?.siteAddress?.postalCode}
              </Typography>
              {visit?.accountPhoneNumber?.phoneNumber && (
                <Typography variant="caption">
                  {visit?.accountPhoneNumber?.phoneNumber
                    ? `${formatInputPhoneNumber(visit?.accountPhoneNumber.phoneNumber)}`
                    : ''}
                </Typography>
              )}
            </Stack>
            <Box mt={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {formatTime(visit?.startTime)} - {formatTime(visit?.endTime)}
              </Typography>
            </Box>
            {visit?.problemDescription && (
              <Box mt={1}>
                <Typography variant="caption">
                  Service Description: {visit?.problemDescription.substring(0, 200)}
                </Typography>
              </Box>
            )}
            {visit?.repairNotes && (
              <Box mt={1}>
                <Typography variant="caption">
                  Service Notes: {visit?.repairNotes.substring(0, 200)}
                </Typography>
              </Box>
            )}
          </Box>
          {displayLinkToChangeTechnician() && (
            <Box mt={1}>
              <Typography variant="caption">
                <span
                  className={classes.linkButton}
                  onClick={() => {
                    if (
                      !(
                        selectedTechId === visit?.userId ||
                        technicianChanged ||
                        isPast ||
                        unavailableDate(selectedTechId, visit?.serviceDate!)
                      )
                    ) {
                      handleUpdateTech(selectedTechId);
                    }
                  }}
                >
                  Assign to selected technician
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      }
      arrow
      placement={'top'}
    >
      <Box onDoubleClick={() => push(`/services/ots/${visit?.repairId}`)}>
        <Stack>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {!isPast && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setEvents(events.filter(e => e.repairVisitId !== event?.visit?.repairVisitId));
                  if (!event.isNew) {
                    setDeletedEvents([...deletedEvents, event?.visit?.repairVisitId!]);
                  }
                }}
                sx={{
                  padding: 0,
                  position: 'absolute',
                  right: '-5px',
                  top: '-1px',
                  background: theme => `${theme.palette.common.white} !important`,
                  zIndex: 1,
                }}
              >
                {visit?.status !== 'Closed' && <Close sx={{ width: '16px', height: '16px' }} />}
              </IconButton>
            )}
          </Box>
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            {visit?.siteName}
          </Typography>
          <Typography variant="caption">{assignedTech}</Typography>
        </Stack>
        <Box mt={1}>
          <Stack>
            <Typography variant="caption">{visit?.siteAddress?.street}</Typography>
            <Typography variant="caption">
              {visit?.siteAddress?.city && `${visit?.siteAddress?.city}, `}{' '}
              {visit?.siteAddress?.state}
              {visit?.siteAddress?.postalCode}
            </Typography>
            <Typography variant="caption">
              {visit?.accountPhoneNumber?.phoneNumber
                ? `${formatInputPhoneNumber(visit?.accountPhoneNumber.phoneNumber)}`
                : ''}
            </Typography>
          </Stack>
          <Box mt={1}>
            <Typography variant="caption">
              {`${formatTime(visit?.startTime)} - ${formatTime(visit?.endTime)}`}
            </Typography>
          </Box>
          {visit?.problemDescription && (
            <Box mt={1}>
              <Typography variant="caption">
                Service Description: {visit?.problemDescription.substring(0, 200)}
              </Typography>
            </Box>
          )}
          {visit?.repairNotes && (
            <Box mt={1}>
              <Typography variant="caption">
                Service Notes: {visit?.repairNotes.substring(0, 200)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  linkButton: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#00BCE7',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
});
