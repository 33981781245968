import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { toCalendarDate } from '../../../../../helpers';
import { isEqual, startOfDay } from 'date-fns';

interface DroppableMonthDayCellProps {
  date: Date;
  children: ReactNode;
}

export const DroppableMonthDayCell: FC<DroppableMonthDayCellProps> = ({ date, children }) => {
  const isToday = isEqual(startOfDay(date), startOfDay(new Date()));

  return (
    <Droppable droppableId={toCalendarDate(date)} type="droppableItem">
      {provided => (
        <StyledMonthDayCell
          isToday={isToday}
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classes.monthDayCell}
        >
          <div className={classes.dateLabel}>{date.getDate()}</div>
          {children}
          {provided.placeholder}
        </StyledMonthDayCell>
      )}
    </Droppable>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const PREFIX = 'DroppableMonthDayCell';

const classes = {
  monthDayCell: `${PREFIX}-monthDayCell`,
  dateLabel: `${PREFIX}-dateLabel`,
};

const StyledMonthDayCell = styled('div')<{ isToday: boolean }>(({ theme, isToday }) => ({
  [`&.${classes.monthDayCell}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: 10,
    padding: 10,
    overflow: 'hidden',
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
    },
    '@media print': {
      display: 'block',
    },
  },

  [`& .${classes.dateLabel}`]: {
    color: isToday ? theme.palette.primary.main : theme.palette.secondary.main,
    fontWeight: isToday ? 600 : '',
  },
}));
