import { Box, Collapse, Divider } from '@mui/material';
import { FC } from 'react';

interface ICardFiltersLayout {
  isOpen: boolean;
  children: React.ReactNode;
}

export const CardFiltersLayout: FC<ICardFiltersLayout> = ({ isOpen, children }) => {
  return (
    <Collapse in={isOpen} timeout="auto">
      <Box mt={1.5}>
        {children}
        <Box marginTop="1rem" marginBottom="0.5rem">
          <Divider />
        </Box>
      </Box>
    </Collapse>
  );
};
