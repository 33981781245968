import { FC, useContext } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { Formik, FormikHelpers, FormikProps, Form } from 'formik';
import {
  FullPageImageLayout,
  PublicPageTitle,
  PasswordSecurity,
  AuthInput,
} from '../../components';
import { UserContext } from '../../context';
import { login, resetPassword } from '../../fetch';
import { passwordRegex } from '../../helpers';
import { IResetPasswordPost } from '../../models';

interface IResetPasswordDetailsProps {
  // a way to bypass the token check and still render the form like normal
  // mainly used for unit tests
  overrideToken?: any;
}

interface IResetPasswordValues {
  confirm: string;
  email: string;
  password: string;
  passwordHint: string;
}

const ResetPasswordSchema = Yup.object().shape({
  confirm: Yup.string()
    .required('Required')
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid Password',
    })
    .oneOf([Yup.ref('password'), null], `Passwords don't match`),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .required('Required')
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid password',
    }),
  passwordHint: Yup.string().required('Required'),
});

export const ResetPasswordDetails: FC<IResetPasswordDetailsProps> = ({ overrideToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isFetching, setUser } = useContext(UserContext);
  const history = useHistory();

  // Get token from URL
  const location = useLocation();
  const params = location.search;
  const tokenCode = params.split('?token=')[1]; // Remove token query string and return exact remaining from URL, don't decode

  const handleOnSubmit = () => {
    setTimeout(function () {
      history.push(`/`);
    }, 3000);
  };

  // have flag and checking state of user
  if (isFetching) {
    return null;
  }
  // have flag and we have a user and they are loaded redirect to correct page
  // Prevent users from getting to this screen without a token in the URL
  // or someone passes in an override flag
  if ((user && !isFetching) || (!tokenCode && !overrideToken)) {
    return <Redirect to="/" />;
  }

  return (
    <Formik
      initialValues={{
        confirm: '',
        email: '',
        password: '',
        passwordHint: '',
      }}
      onSubmit={async (
        values: IResetPasswordValues,
        actions: FormikHelpers<IResetPasswordValues>
      ) => {
        const data: IResetPasswordPost = {
          email: values.email,
          resetPasswordToken: tokenCode,
          newPassword: values.password,
          passwordHint: values.passwordHint,
        };
        try {
          const res: any = await resetPassword(data);
          if (!res || res.Detail || res.Errors) {
            ///reset-password?token=O1EXxWsJn5dw7twrFP11c9ON+EQoOwRg11N+a/TybT0/LEV/Yz2j2vnOUwrUp/vh54rOT19gTNQH5GncnBNTaA==
            if (res.Detail.includes('Sequence contains no elements')) {
              enqueueSnackbar(
                'Invalid/Expired Token. Please submit a new request. Redirecting...',
                {
                  variant: 'error',
                }
              );
              setTimeout(function () {
                history.push(`/forgot-password`);
              }, 3000);
            } else if (res.Detail) {
              enqueueSnackbar(`Error, ${res.Detail}`, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(`Error, ${res.Errors && res.Errors.Body && res.Errors.Body[0]}`, {
                variant: 'error',
              });
            }
            return;
          } else if (res) {
            enqueueSnackbar(`Password Reset Successfully. Logging in...`, {
              variant: 'success',
              onClose: () => {
                handleOnSubmit();
              },
            });
          } else {
            throw Error();
          }

          actions.resetForm({
            values: {
              confirm: '',
              email: '',
              password: '',
              passwordHint: '',
            },
          });

          // Log user in with new password upon password reset
          const res1: any = await login({ loginName: values.email, password: values.password });
          if (!res1 || res1.Detail) {
            enqueueSnackbar(`Error, ${res1.Detail}`, {
              variant: 'error',
            });
            return;
          } else if (res1 && res1.loginName) {
            setUser(res1);
          } else {
            throw Error();
          }
        } catch (error: any) {
          if (error?.Detail) {
            return enqueueSnackbar(`Error, ${error.Detail}`, {
              variant: 'error',
            });
          }
          if (!error?.Detail) {
            enqueueSnackbar('Error resetting password, please try again', {
              variant: 'error',
            });
          }
          actions.resetForm();
          const emailInput = document.getElementById('email');
          if (emailInput) {
            emailInput.focus();
          }
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={ResetPasswordSchema}
    >
      {({
        dirty,
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        isValid,
      }: FormikProps<IResetPasswordValues>) => (
        <FullPageImageLayout>
          <PublicPageTitle title="Reset Password" />
          <Form data-testid="reset-password-form" id="reset-password-form" onSubmit={handleSubmit}>
            <Stack
              gap={3}
              mt={{
                xs: 2,
                sm: 4,
              }}
            >
              <AuthInput
                errors={errors}
                values={values}
                touched={touched}
                inputKey="email"
                label="Email"
                onChange={e => setFieldValue('email', e.target.value)}
                onBlur={handleBlur}
                id="reset-password"
              />
              <AuthInput
                errors={errors}
                values={values}
                touched={touched}
                inputKey="password"
                label="Password"
                onChange={e => setFieldValue('password', e.target.value)}
                onBlur={handleBlur}
                id="reset-password"
                type="password"
              />
              <PasswordSecurity />
              <AuthInput
                errors={errors}
                values={values}
                touched={touched}
                inputKey="confirm"
                label="Confirm Password"
                onChange={e => setFieldValue('confirm', e.target.value)}
                onBlur={handleBlur}
                id="reset-password"
                type="password"
              />
              <AuthInput
                errors={errors}
                values={values}
                touched={touched}
                inputKey="passwordHint"
                label="Password Hint"
                onChange={e => setFieldValue('passwordHint', e.target.value)}
                onBlur={handleBlur}
                id="reset-password"
              />
              <Button
                // @ts-ignore
                color="tertiary"
                type="submit"
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  padding: theme => theme.spacing(1.5),
                  borderRadius: '30px',
                }}
                disabled={!dirty || isSubmitting || !isValid}
              >
                {!isSubmitting ? 'Save & Login' : 'Resetting...'}
              </Button>
            </Stack>
          </Form>
        </FullPageImageLayout>
      )}
    </Formik>
  );
};
