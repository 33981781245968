import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface FloatingButtonProps {
  children: ReactNode;
  isModal?: boolean;
}

export const FloatingToolbar: FC<FloatingButtonProps> = ({ children, isModal }) => {
  const isMobile = useMediaQuery(`(max-width: 567px)`);
  const classes = useStyles({ isMobile, isModal });

  return (
    <Box className={clsx(classes.saveWrapper, 'print--none')} data-testid="FloatingToolbar">
      <Box className={classes.buttonWrapper}>
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isMobile: boolean; isModal?: boolean }>(theme => ({
  saveWrapper: {
    padding: ({ isMobile }) => (isMobile ? theme.spacing(1, 3, 1, 3) : theme.spacing(2, 6, 2, 6)),
    position: 'fixed',
    bottom: ({ isModal }) => (isModal ? '32px' : theme.spacing(4)),
    zIndex: theme.zIndex.drawer + 2,
    right: 0,
    '@media (min-width: 1200px)': {
      right: ({ isModal }) => (isModal ? 'auto' : 0),
      left: ({ isModal }) => (isModal ? 'calc(50% - -22rem)' : 'auto'),
      bottom: ({ isModal }) => (isModal ? '32px' : theme.spacing(4)),
    },
  },
  buttonWrapper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0.25),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 20%)',
  },
}));
