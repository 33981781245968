import { FC, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ServiceCodeChecklistTable } from './service-code-checklists-table';
import { Modal, Loader, TextField, ModalSaveSection, SelectAsync } from '../../../components';
import {
  Box,
  Fade,
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
} from '@mui/material';
// fetch
import {
  updateServiceDefinition,
  createServiceDefinition,
  getAllTranCodes,
  getReportLayoutList,
} from '../../../fetch';

import { IServiceDefinition, ITranCode, IResponse, IReportLayout } from '../../../models';

import { convertToNumber } from '../../../helpers';

interface IAddEditServiceCodeModal {
  open: boolean;
  onClose: () => void;
  currentServiceCode?: IServiceDefinition | null;
  fetchServiceCodes: () => void;
}

const Schema = Yup.object().shape({
  terminationTypeId: Yup.string(),
  tbid: Yup.number(),
  code: Yup.string().required('Required').max(10),
  description: Yup.string().required('Required').max(100),
  sortOrder: Yup.number()
    .required('Required')
    .max(10000, 'Sort Order should not be more than 10000'),
  defaultAmountToCharge: Yup.number().max(1000000, 'Amount Charge should not be more than 1000000'),
});

export const AddEditServiceCodeModal: FC<IAddEditServiceCodeModal> = ({
  open,
  onClose,
  currentServiceCode,
  fetchServiceCodes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const parameters: any = {
    officeId: user?.officeId as string,
    perPage: -1,
  };

  const [reports, setReports] = useState<IReportLayout[]>([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getReportLayoutList();
      setReports(data);
    }
    if (reports.length === 0) {
      fetchData();
    }
  }, [reports.length]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          code: currentServiceCode?.code ?? '',
          description: currentServiceCode?.description ?? '',
          tbid: currentServiceCode?.tbid ?? 0,
          serviceDefId: currentServiceCode?.serviceDefId ?? '',
          officeId: user?.officeId ?? '',
          isDeleted: currentServiceCode?.isDeleted ?? false,
          icon: currentServiceCode?.icon ?? '',
          reportLayout: currentServiceCode?.reportLayout ?? '',
          defaultAmountToCharge: currentServiceCode?.defaultAmountToCharge
            ? Number(currentServiceCode?.defaultAmountToCharge).toFixed(2)
            : '0.00',
          transactionCode: currentServiceCode?.transactionCode ?? '',
          transactionCodeId: currentServiceCode?.transactionCodeId ?? '',
          reportLayoutId: currentServiceCode?.reportLayoutId ?? '',
          sortOrder: currentServiceCode?.sortOrder ? currentServiceCode?.sortOrder.toString() : '0',
          isOneTimeService: currentServiceCode?.isOneTimeService ?? false,
          reportLayoutList: currentServiceCode?.reportLayoutList ?? [],
          cleanIcon: currentServiceCode?.cleanIcon ?? false,
          serviceIcon: currentServiceCode?.serviceIcon ?? false,
          repairIcon: currentServiceCode?.repairIcon ?? false,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: IServiceDefinition = {
            serviceDefId: values.serviceDefId,
            officeId: values.officeId,
            code: values.code,
            description: values.description,
            icon: values.icon,
            cleanIcon: values.cleanIcon,
            serviceIcon: values.serviceIcon,
            repairIcon: values.repairIcon,
            reportLayout: values.reportLayout,
            defaultAmountToCharge: convertToNumber(values.defaultAmountToCharge),
            transactionCode: values.transactionCode,
            transactionCodeId: values.transactionCodeId,
            sortOrder: convertToNumber(values.sortOrder),
            tbid: values.tbid,
            isOneTimeService: values.isOneTimeService,
            isDeleted: values.isDeleted,
            reportLayoutList: values.reportLayoutList,
            reportLayoutId: values.reportLayoutId,
          };

          try {
            if (currentServiceCode) {
              await updateServiceDefinition(data);
            } else {
              await createServiceDefinition(data);
              enqueueSnackbar('Please add checklist items for this new code.', {
                variant: 'info',
              });
            }
            enqueueSnackbar(
              currentServiceCode
                ? 'Successfully updated Service Code!'
                : 'Successfully created Service Code!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchServiceCodes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Service Code, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          isValid,
          handleChange,
          values,
          setFieldValue,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
              title={currentServiceCode ? 'Edit Service Code' : 'Add Service Code'}
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={1.5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel className={classes.selectLabel}>Report Layout</InputLabel>
                          <Select
                            name="reportLayout"
                            label="Report Layout"
                            value={values.reportLayout}
                            onChange={e => {
                              setFieldValue('reportLayout', e.target.value);
                            }}
                          >
                            {reports.map(report => (
                              <MenuItem key={report.description} value={report.description}>
                                {report.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="defaultAmountToCharge"
                          label="Amount Charge"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          type="number"
                          value={Number(values.defaultAmountToCharge).toFixed(2)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="transactionCodeId"
                          label="Tran Code"
                          apiRequest={() => getAllTranCodes(parameters)}
                          transformResponse={(response: IResponse<ITranCode[]>) => {
                            return response.records.map(record => ({
                              label: record.description,
                              value: record.tranCodeId,
                            }));
                          }}
                          hasClear
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number" />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="cleanIcon"
                                checked={values.cleanIcon}
                                onChange={handleChange}
                              />
                            }
                            label="Clean Icon"
                          />
                        </FormControl>

                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="serviceIcon"
                                checked={values.serviceIcon}
                                onChange={handleChange}
                              />
                            }
                            label="Service Icon"
                          />
                        </FormControl>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="repairIcon"
                                checked={values.repairIcon}
                                onChange={handleChange}
                              />
                            }
                            label="Repair Icon"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {currentServiceCode && (
                      <ServiceCodeChecklistTable serviceDefId={currentServiceCode.serviceDefId} />
                    )}
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    background: 'white',
  },
}));
