import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC, useMemo } from 'react';
import { IParameterInputProps } from '../../../../models';
import { useDebounce } from 'react-use';

export const DateParameter: FC<IParameterInputProps> = ({ parameter, value, onChange, onBlur }) => {
  const { parameterName, promptText } = parameter;

  const isValidDate = useMemo(() => {
    // check the input value for date contains a full year i.e. 12/12/3333 and not just 21/12/12
    return value && `${new Date(value)?.getFullYear()}`?.length === 4;
  }, [value]);

  const handleChange = (date: Date | null) => {
    const value =
      date && `${new Date(date)?.getFullYear()}`?.length === 4 ? new Date(date).toISOString() : '';
    onChange(value, parameter);
  };

  useDebounce(
    () => {
      // Allows for delayed submit when typing and onChange trigger
      if (onBlur && isValidDate) {
        onBlur(value, parameter);
      }
    },
    800,
    [value]
  );

  return (
    <DatePicker
      label={promptText}
      format="MM/dd/yyyy"
      onChange={handleChange}
      value={value ? new Date(value) : null}
      slotProps={{
        textField: {
          name: parameterName,
          error: false,
          size: 'small',
        },
      }}
    />
  );
};
