import { FC, useContext, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Loader } from '../loader';
import { IMessage, IUser } from '../../models';
import { UserContext, SideNavContext } from '../../context';
import { getMessages } from '../../fetch';

export interface IMessagesProps {}

export const Messages: FC<IMessagesProps> = () => {
  const { showNav, setShowNav } = useContext(SideNavContext);
  const open = showNav;
  const classes = useStyles({ open });
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [isLoadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState<IMessage | null>(null);

  const sortAlerts = () => {
    // Sorts null officeIds last, so that the office specific alerts always render first
    return function (a: any, b: any) {
      // equal items sort equally
      if (a.officeId === b.officeId) {
        return 0;
      }

      // nulls sort after anything else
      if (a.officeId === null) {
        return 1;
      }
      if (b.officeId === null) {
        return -1;
      }

      return a.officeId < b.officeId ? 1 : -1;
    };
  };

  const fetchMessages = async (userInfo?: IUser | undefined) => {
    setLoadingMessages(true);
    try {
      const res = await getMessages();
      const filteredAlerts = {
        ...res,
        alerts:
          !!res.alerts && res.alerts.length
            ? res.alerts
                ?.filter(alert => alert?.officeId === user?.officeId || !alert?.officeId)
                .sort(sortAlerts())
            : [],
      };

      setMessages(filteredAlerts);
    } catch (error) {
      enqueueSnackbar(`Error loading messages and alerts, please refresh the page to try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMessages(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Box className={classes.wrapper} data-testid="Messages.Wrapper">
      {!open && (
        <IconButton
          onClick={() => {
            setShowNav(!open);
          }}
          title={`View Messages & Alerts`}
          className={classes.messageToggle}
        >
          <NotificationsNoneOutlined />
        </IconButton>
      )}
      {open && (
        <>
          <Box
            className={clsx(classes.sectionTitle, classes.displayTransition)}
            data-testid="Messages.Title"
          >
            <div className={classes.sectionTitleIcon}>
              <NotificationsNoneOutlined />
            </div>{' '}
            {`Messages & Alerts`}
          </Box>
          {isLoadingMessages && (
            <Box
              className={clsx(classes.innerWrapper, classes.messageItem, classes.displayTransition)}
              data-testid="Messages.Loader"
            >
              <Box className={classes.messageBody}>
                <Loader position="centered" type="inline" />
              </Box>
            </Box>
          )}
          {!isLoadingMessages && !messages && (
            <Box
              className={clsx(classes.innerWrapper, classes.messageItem, classes.displayTransition)}
              data-testid="Messages.NoResults"
            >
              <Box className={clsx(classes.messageBody, classes.emptyState)}>
                There are no messages or alerts to display.
              </Box>
            </Box>
          )}
          {!isLoadingMessages && messages && (
            <>
              {!!messages.alerts && messages.alerts.length > 0 && (
                <Box
                  className={clsx(
                    classes.innerWrapper,
                    classes.messageItem,
                    classes.displayTransition
                  )}
                >
                  <Box className={classes.messageTitle} data-testid="Messages.Alerts.Title">
                    <strong>Alerts</strong>
                  </Box>
                  <Box className={classes.messageBody}>
                    <List disablePadding className={classes.alertList}>
                      {messages.alerts.map((alert, index) => {
                        return (
                          <ListItem key={`${index}`}>
                            <ListItemText>{alert.message}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              )}

              {!!messages.tip && (
                <Box
                  className={clsx(
                    classes.innerWrapper,
                    classes.messageItem,
                    classes.displayTransition
                  )}
                  data-testid="Messages.Results"
                >
                  <Box className={classes.messageTitle}>
                    <strong>{`TIP #${messages.tip.tipsNumber}`}</strong>
                  </Box>
                  <Box className={classes.messageBody}>{messages.tip.description}</Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { open: boolean }>(theme => ({
  wrapper: {
    backgroundColor: `rgba(255, 255, 255, 0.1)`,
    padding: theme.spacing(1.5, 1.5),
    '&& > * + *': {
      marginTop: theme.spacing(2),
    },

    // small screen sizes
    '@media (max-width: 400px)': {
      marginBottom: '5rem',
    },
  },
  innerWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    padding: theme.spacing(2),
    '&& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  displayTransition: {
    opacity: ({ open }) => (open ? 1 : 0),
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  sectionTitle: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: '600',
  },
  sectionTitleIcon: {
    marginRight: ({ open }) => (open ? theme.spacing(1) : 0),
    '&& svg': {
      width: 25,
      height: 25,
    },
  },
  messageItem: {
    '&&, && *': {
      fontSize: '.85rem',
    },
  },
  messageTitle: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  messageBody: {
    wordBreak: 'break-word',
  },
  messageToggle: {
    padding: 0,
    color: theme.palette.common.white,
  },
  emptyState: {
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
  alertList: {
    '&& li': {
      padding: theme.spacing(0.5, 0, 0),
      marginTop: theme.spacing(0.5),
      borderTop: `1px solid ${theme.palette.grey[200]}`,

      '&:first-child': {
        paddingTop: 0,
        marginTop: 0,
        borderTop: 'none',
      },
    },
  },
}));
