import { useEffect, useState } from 'react';
import { IFilter } from '../../../models';
import { createFilterValueLookupTable, getCleanFilterValues } from '../utils';

export type FilterFetcher = () => Promise<IFilter[]>;

interface UseFilterOptions {
  filterFetcher: FilterFetcher;
}

export const useFilters = ({ filterFetcher }: UseFilterOptions) => {
  const [isLoading, setLoading] = useState(false);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [filterValues, setFilterValues] = useState<Record<string, string[]>>({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const loadedFilters = await filterFetcher();
        setFilters(loadedFilters);
        const initialValues = createFilterValueLookupTable(loadedFilters);
        setFilterValues(initialValues);
        setAppliedFilters(getCleanFilterValues(initialValues));
        setFiltersInitialized(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filterFetcher]);

  const onSubmit = (values: Record<string, string[]>) => {
    setAppliedFilters(values);
  };

  const onFilterToggle = () => setIsShowingFilters(!isShowingFilters);

  const onChange = (values: Record<string, string[]>) => {
    setFilterValues(values);
  };

  const onReset = (values: Record<string, string[]>) => {
    setFilterValues(values);
    setAppliedFilters({});
  };

  return {
    isLoading,
    isShowingFilters,
    filters,
    filtersInitialized,
    appliedFilters,
    filterValues,
    onChange,
    onSubmit,
    onReset,
    onFilterToggle,
    setFiltersInitialized,
  };
};
