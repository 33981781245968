import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Tabs } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick, styleGuideClientName } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export const StyleGuideTabs = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [selectedTab, setSelectedTab] = useState<string>('account');

  return (
    <StyleGuidePage title="Tabs">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Tabs component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-tabs/" target="_blank">
              MUI Tabs
            </Link>{' '}
            component. {styleGuideClientName} has 2 different styles of tabs:{' '}
            <ul className="my-2">
              <li>
                <strong>Large Secondary Color Tabs</strong>: This style of tab is meant to be used
                at the top level, where a page's content is organized into tabs (i.e. Routes has tab
                content for Adjust Routes and Optimize Routes). In certain instances, icons are used
                with tabs but only for this particular style.
              </li>
              <li className="mt-1">
                <strong>Small Primary Color Tabs</strong>: This style of tab is meant to be housed
                within cards and used for section content (i.e. Site Details has a section for Pool
                Equipment that has tabs for subsections). Icons are possible, but not generally used
                with this particular style.
              </li>
            </ul>
          </Typography>
          <Typography mb={1}>
            Though there are certain key differences between the different styles of tabs, there are
            shared factors. {styleGuideClientName} uses <em>theme.palette.grey[300]</em> for the
            bottom border color. Standard tab text should be <em>theme.palette.grey[600]</em>.
          </Typography>
          <Typography mb={1}>
            In addition to styling/branding being baked into this component, certain mobile props
            have been included so that tabs will scroll horizontally when needed, like on mobile.
            See the bottom of the page for more details.
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- tabs: ITab[]
                <br />- id: string
                <br />- selectedTab: string | number
                <br />- setSelectedTab: (val: string) =&gt; void
                <br />- printTabstrip?: boolean
                <br />- printAllTabs?: boolean
                <br />- isSticky?: boolean
                <br />- size?: 'md' | 'lg'
                <br />- color?: 'primary' | 'secondary'
                <br />- backgroundColor?: 'white' | 'grey'
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                interface ITab:
                <br />- key: string
                <br />- title: string | JSX.Element
                <br />- mobileTitle?: string | JSX.Element
                <br />- icon?: IconDefinition
                <br />- children?: any
                <br />- disabled?: boolean
                <br />- isInvalid?: boolean
                <br />- hasUnsavedChanges?: boolean
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Tabs
              id="example-1"
              isSticky
              color="secondary"
              size="lg"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                setSelectedTab(val);
              }}
              tabs={[
                {
                  key: 'account',
                  title: 'Account Details',
                  mobileTitle: 'Account',
                  icon: faPen,
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Account tab details
                    </Box>
                  ),
                },
                {
                  key: 'sites',
                  title: 'Sites',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Sites tab details
                    </Box>
                  ),
                },
                {
                  key: 'rates',
                  title: 'Rates',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Rates tab details
                    </Box>
                  ),
                },
                {
                  key: 'transactions',
                  title: 'Transactions',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Transactions tab details
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Tabs
            <br />
            &nbsp;&nbsp;&nbsp;id="example-1"
            <br />
            &nbsp;&nbsp;&nbsp;isSticky
            <br />
            &nbsp;&nbsp;&nbsp;color="secondary"
            <br />
            &nbsp;&nbsp;&nbsp;size="lg"
            <br />
            &nbsp;&nbsp;&nbsp;selectedTab=&#123;selectedTab&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setSelectedTab=&#123;val =&gt; &#123; setSelectedTab(val);
            &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;tabs=&#123;[
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Account Details',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mobileTitle: 'Account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icon: faPen,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Account tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Sites tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Rates tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Transactions tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;]&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Tabs
              id="example-2"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                setSelectedTab(val);
              }}
              tabs={[
                {
                  key: 'account',
                  title: 'Account Details',
                  mobileTitle: 'Account',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Account tab details
                    </Box>
                  ),
                },
                {
                  key: 'sites',
                  title: 'Sites',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Sites tab details
                    </Box>
                  ),
                },
                {
                  key: 'rates',
                  title: 'Rates',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Rates tab details
                    </Box>
                  ),
                },
                {
                  key: 'transactions',
                  title: 'Transactions',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Transactions tab details
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Tabs
            <br />
            &nbsp;&nbsp;&nbsp;id="example-2"
            <br />
            &nbsp;&nbsp;&nbsp;selectedTab=&#123;selectedTab&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setSelectedTab=&#123;val =&gt; &#123; setSelectedTab(val);
            &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;tabs=&#123;[
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Account Details',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mobileTitle: 'Account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Account tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Sites tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Rates tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Transactions tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;]&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: theme => theme.spacing(2) }} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography mt={2} mb={1}>
            When using MUI tabs, if the tabs do not fit on smaller screen sizes without wrapping,
            use the following props (these props are baked into the custom Tabs component):
          </Typography>
          <Box mb={1}>
            <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
              scrollButtons="auto"
              <br />
              variant="scrollable"
              <br />
              allowScrollButtonsMobile
            </CodeBlock>
          </Box>
        </Grid>
      </Grid>
      <Typography mb={0.5} mt={2} variant="h5" component="h2" color="primary">
        Tab Validation
      </Typography>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Typography mt={2} mb={1}>
            When implementing related tabs or tabs that contain forms, it might be a good idea to
            trigger tab validation so that the user knows if there is any action needed on another
            tab before being able to move forward, whether that's in a form or modal.
          </Typography>
          <Typography mt={2} mb={1}>
            The tab component has alert functionality baked into it, so the only thing you need to
            worry about is properly setting the isInvalid and/or hasUnsavedChanges props on a per
            Tab basis. The wording and coloring will differ depending on which prop you use. NOTE:
            The alert won't always display (i.e. if one invalid tab and it's currently selected).
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Tabs
              id="example-3"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                setSelectedTab(val);
              }}
              tabs={[
                {
                  key: 'account',
                  title: 'Account Details',
                  mobileTitle: 'Account',
                  isInvalid: true,
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Account tab details
                    </Box>
                  ),
                },
                {
                  key: 'sites',
                  title: 'Sites',
                  isInvalid: true,
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Sites tab details
                    </Box>
                  ),
                },
                {
                  key: 'rates',
                  title: 'Rates',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Rates tab details
                    </Box>
                  ),
                },
                {
                  key: 'transactions',
                  title: 'Transactions',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Transactions tab details
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Tabs
            <br />
            &nbsp;&nbsp;&nbsp;id="example-3"
            <br />
            &nbsp;&nbsp;&nbsp;selectedTab=&#123;selectedTab&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setSelectedTab=&#123;val =&gt; &#123; setSelectedTab(val);
            &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;tabs=&#123;[
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Account Details',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mobileTitle: 'Account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;isInvalid: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Account tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;isInvalid: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Sites tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Rates tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Transactions tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;]&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Tabs
              id="example-3"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                setSelectedTab(val);
              }}
              tabs={[
                {
                  key: 'account',
                  title: 'Account Details',
                  mobileTitle: 'Account',
                  hasUnsavedChanges: true,
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Account tab details
                    </Box>
                  ),
                },
                {
                  key: 'sites',
                  title: 'Sites',
                  hasUnsavedChanges: true,
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Sites tab details
                    </Box>
                  ),
                },
                {
                  key: 'rates',
                  title: 'Rates',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Rates tab details
                    </Box>
                  ),
                },
                {
                  key: 'transactions',
                  title: 'Transactions',
                  children: (
                    <Box marginTop="1rem" minHeight="10rem">
                      Transactions tab details
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Tabs
            <br />
            &nbsp;&nbsp;&nbsp;id="example-4"
            <br />
            &nbsp;&nbsp;&nbsp;selectedTab=&#123;selectedTab&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setSelectedTab=&#123;val =&gt; &#123; setSelectedTab(val);
            &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;tabs=&#123;[
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Account Details',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mobileTitle: 'Account',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hasUnsavedChanges: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Account tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Sites',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hasUnsavedChanges: true,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Sites tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Rates',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Rates tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;title: 'Transactions',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;children: &lt;Box
            marginTop="1rem"&gt;Transactions tab details&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
            <br />
            &nbsp;&nbsp;&nbsp;]&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
