import { FC, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { GridDataFetcher, useDataGrid } from '../../components';
import { getInventoryGridData } from '../../fetch';
import { InventoryDataGrid } from './InventoryDataGrid';
import { IGetInventoryGridOptions, IInventoryItem, IUser } from '../../models';
import { GridColDef } from '@mui/x-data-grid';

interface IInventoryListDetails {
  searchedValue: string;
  user?: IUser;
  setLoading: (val: boolean) => void;
  columns?: GridColDef[];
  refresh?: boolean;
  setRefresh?: (val: boolean) => void;
}

export const InventoryListDetails: FC<IInventoryListDetails> = ({
  searchedValue,
  user,
  setLoading,
  columns,
  refresh,
  setRefresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const dataFetcher: GridDataFetcher<IInventoryItem, number> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      if (searchedValue === '') {
        setLoading(true);
        try {
          const options: IGetInventoryGridOptions = {
            sortBy: sortColumn,
            sortDirection: sortDirection || 'asc',
            perPage,
            officeId: user?.officeId as string,
            first: isFirstPage ?? false,
            last: isLastPage ?? false,
          };

          if (beforeItemId) {
            options.before = beforeItemId;
          }

          if (afterItemId) {
            options.after = afterItemId;
          }

          const res = await getInventoryGridData(options);
          return {
            rows: res.data,
            rowCount: res.totalCount,
          };
        } catch (error) {
          enqueueSnackbar(`Error loading inventory, please try again.`, {
            variant: 'error',
          });
          throw error;
        } finally {
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );
  const {
    isLoading,
    page,
    rows,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onKeysetPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchInventoryItems,
  } = useDataGrid<IInventoryItem, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'inventory-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    keysetPagingKey: 'inventoryId',
    dataFetcher,
  });

  useEffect(() => {
    if (refresh) {
      refetchInventoryItems();
      setRefresh && setRefresh(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <InventoryDataGrid
      isKeysetTable
      loading={isLoading}
      rows={rows}
      rowCount={recordCount}
      page={page}
      pageSize={perPage}
      onPageChange={onKeysetPageChange}
      onPageSizeChange={onPageSizeChange}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      columns={columns}
    />
  );
};
