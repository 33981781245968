import {
  ICalendarMode,
  ICalendarModeOption,
  ICalendarView,
  ICalendarViewOption,
  IDayViewSettings,
  IMonthViewSettings,
  IWeekday,
  IWeekViewSettings,
} from '../../models';

export const DEFAULT_VIEW_OPTIONS: ICalendarViewOption[] = [
  {
    label: 'Day',
    value: ICalendarView.Day,
  },
  {
    label: 'Work Week',
    value: ICalendarView.WorkWeek,
  },
  {
    label: 'Week',
    value: ICalendarView.Week,
  },
  {
    label: 'Month',
    value: ICalendarView.Month,
  },
];

export const DEFAULT_MODE_OPTIONS: ICalendarModeOption[] = [
  {
    label: 'Summary',
    value: ICalendarMode.Summary,
  },
  {
    label: 'Details',
    value: ICalendarMode.DetailedWork,
  },
];

export const DEFAULT_DAY_SETTINGS: IDayViewSettings = {
  startHour: 8,
  endHour: 18,
  step: 30,
};

export const DEFAULT_MONTH_SETTINGS: IMonthViewSettings = {
  ...DEFAULT_DAY_SETTINGS,
  weekdays: [
    IWeekday.Monday,
    IWeekday.Tuesday,
    IWeekday.Wednesday,
    IWeekday.Thursday,
    IWeekday.Friday,
    IWeekday.Saturday,
    IWeekday.Sunday,
  ],
  weekStartOn: IWeekday.Monday,
};

export const DEFAULT_WEEK_SETTINGS: IWeekViewSettings = {
  ...DEFAULT_DAY_SETTINGS,
  weekdays: [
    IWeekday.Monday,
    IWeekday.Tuesday,
    IWeekday.Wednesday,
    IWeekday.Thursday,
    IWeekday.Friday,
    IWeekday.Saturday,
    IWeekday.Sunday,
  ],
  weekStartOn: IWeekday.Monday,
};

export const DEFAULT_WORK_WEEK_SETTINGS: IWeekViewSettings = {
  ...DEFAULT_DAY_SETTINGS,
  weekdays: [
    IWeekday.Monday,
    IWeekday.Tuesday,
    IWeekday.Wednesday,
    IWeekday.Thursday,
    IWeekday.Friday,
  ],
  weekStartOn: IWeekday.Monday,
};
