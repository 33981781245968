import { Fade, Box, Divider } from '@mui/material';
import {
  EditAddressInformation,
  Loader,
  Modal,
  ModalSaveSection,
  TextField,
} from '../../../components';
import { Form, Formik } from 'formik';
import { IAddress } from '../../../models';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { createServiceLocation, updateServiceLocation } from '../../../fetch/service-locations';
import { IServiceLocation } from '../../../models/service-locations';

interface IServiceLocationModalProps {
  isOpen: boolean;
  handleClose: (shouldReload?: boolean) => void;
  isLoading: boolean;
  accountAddress?: IAddress | null;
  serviceLocation?: IServiceLocation | null;
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().max(255, 'Max 255 characters').required('Service Location Name is required'),
  addressName: Yup.string().max(255, 'Max 255 characters').required('Addressee is required'),
  street: Yup.string().max(255, 'Max 255 characters').required('Street is required'),
  city: Yup.string().max(255, 'Max 255 characters').required('City is required'),
  state: Yup.string().max(255, 'Max 255 characters').required('State is required'),
  postalCode: Yup.string().max(255, 'Max 255 characters').required('Postal code is required'),
  latitude: Yup.number().required('Latitude is required'),
  longitude: Yup.number().required('Longitude is required'),
});

export const ServiceLocationModal: FC<IServiceLocationModalProps> = ({
  isOpen,
  handleClose,
  isLoading,
  accountAddress,
  serviceLocation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);
  return (
    <Modal open={isOpen} onClose={() => handleClose()} maxWidth="md" title="Edit Service Location">
      <Fade in={isOpen}>
        <div>
          <Formik
            initialValues={{
              ...serviceLocation,
              street: serviceLocation?.street ?? '',
              addressName: serviceLocation?.addressName ?? '',
              city: serviceLocation?.city ?? '',
              state: serviceLocation?.state ?? '',
              postalCode: serviceLocation?.postalCode ?? '',
              latitude: serviceLocation?.latitude ?? '',
              longitude: serviceLocation?.longitude ?? '',
            }}
            enableReinitialize={true}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, actions) => {
              try {
                if (serviceLocation?.serviceLocationId) {
                  await updateServiceLocation(
                    {
                      officeId: serviceLocation?.officeId!,
                      address: {
                        addressId: serviceLocation?.addressId!,
                        addressName: values.addressName,
                        street: values.street,
                        city: values.city,
                        state: values.state,
                        postalCode: values.postalCode,
                        longitude: pushPinLocation?.longitude ?? undefined,
                        latitude: pushPinLocation?.latitude ?? undefined,
                      },
                      name: values?.name,
                    },
                    serviceLocation?.serviceLocationId!
                  );
                  enqueueSnackbar(`Service Location Updated!`, {
                    variant: 'success',
                  });
                  handleClose(true);
                } else {
                  await createServiceLocation({
                    officeId: serviceLocation?.officeId!,
                    address: {
                      addressId: serviceLocation?.addressId!,
                      addressName: values.addressName,
                      street: values.street,
                      city: values.city,
                      state: values.state,
                      postalCode: values.postalCode,
                      longitude: pushPinLocation?.longitude ?? undefined,
                      latitude: pushPinLocation?.latitude ?? undefined,
                    },
                    name: values?.name,
                  });
                  enqueueSnackbar(`Service Location Created!`, {
                    variant: 'success',
                  });
                  handleClose(true);
                }
              } catch (error: any) {
                console.log(error);
                enqueueSnackbar(
                  error?.Detail ?? `Error updating service location, please try again.`,
                  {
                    variant: 'error',
                  }
                );
              }
            }}
          >
            {({ isSubmitting, values, handleSubmit }) => {
              return (
                <>
                  {(isLoading || isSubmitting) && (
                    <Loader position="centered" type="overlay" zIndex={3} />
                  )}
                  <Form onSubmit={handleSubmit}>
                    <Box mt={2}>
                      <TextField name="name" label={'Service Location Name'} required />
                    </Box>
                    <Box mt={2}>
                      <EditAddressInformation
                        accountAddress={accountAddress}
                        pushPinLocation={pushPinLocation}
                        setPushPinLocation={setPushPinLocation}
                        addressNameLabel="Address Name"
                        showAccountAddressButton={false}
                      />
                    </Box>
                    <Divider
                      sx={{ borderColor: theme => theme.palette.dividers.grey, marginTop: 2 }}
                    />
                    <ModalSaveSection
                      handleCancel={() => handleClose()}
                      isSaveDisabled={
                        isSubmitting ||
                        !values.street ||
                        !values.city ||
                        !values.state ||
                        !values.postalCode
                      }
                    />
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Fade>
    </Modal>
  );
};
