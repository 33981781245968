import { Page } from '../../components';
import { ForgotPasswordDetails } from './forgot-password-details';

export const ForgotPassword = () => {
  return (
    <Page title="Forgot Password" isExternal={true} customPageHeader={true}>
      <ForgotPasswordDetails />
    </Page>
  );
};
