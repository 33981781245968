import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import 'bingmaps';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ITechnician, IRepair } from '../../models';
import {
  isRouteStart,
  isRouteStartOrEnd,
  isVisitStart,
  isVisitStartOrEnd,
} from '../../pages/routes/utils';
import { Loader } from '../loader';
import { azureMap } from './azureMap-base64';

export interface MapWindow extends Window {
  directionsManagers: any[];
  bingAPIReady: () => void;
  Microsoft: any;
}
const filterDuplicates = (tech: ITechnician, isRoutes: boolean) => {
  let unique: any[] = [];
  if (isRoutes) {
    return tech.services
      .filter(route => !isRouteStartOrEnd(route))
      .filter(o => {
        if (
          unique.find(
            i => Number(i.longitude) === Number(o.longitude) && Number(i.latitude === o.latitude)
          )
        ) {
          return true;
        }

        unique.push(o);
        return false;
      });
  }
  return tech?.visits
    ?.filter(repair => !isVisitStartOrEnd(repair))
    .filter(o => {
      if (
        unique.find(
          i =>
            Number(i?.siteAddress?.longitude) === Number(o?.siteAddress?.longitude) &&
            Number(i?.siteAddress?.latitude === o?.siteAddress?.latitude)
        )
      ) {
        return true;
      }

      unique.push(o);
      return false;
    }) as IRepair[];
};
const constructMapData = (
  techs: ITechnician[],
  callback: () => void,
  isRoutes: boolean,
  mapRoutesAndWaypoints?: any
) => {
  const techRoutes: any = [];

  // For each tech, we need to create a Directions Manager, which corrosponds to a particular tech's route
  techs.forEach(tech => {
    const routeWaypoints: any[] = [];

    // This component supports mapping IRepair[] and IService[], depending on the isRoutes prop.
    // Due to the differences in the data structure, we need to handle them differently,
    // hence the various if statements checking the isRoutes prop in the following code.
    const duplicatePins: any[] = filterDuplicates(tech, isRoutes);
    const initialPins: any[] = isRoutes
      ? tech.services.filter(route => !!route.latitude || !!route.longitude) ?? []
      : tech?.visits
          ?.filter(visit => !!visit?.siteAddress)
          ?.filter(visit => !!visit?.siteAddress?.latitude || !!visit?.siteAddress?.longitude) ??
        [];
    // console.log("initialPins",initialPins)
    initialPins?.forEach((item, index) => {
      const filteredDuplicates = isRoutes
        ? duplicatePins.filter(r => r.longitude === item.longitude && r.latitude === item.latitude)
        : duplicatePins.filter(
            v =>
              v?.siteAddress?.longitude === item?.siteAddress?.longitude &&
              v?.siteAddress?.latitude === item?.siteAddress?.latitude
          );
      const multipleStops: any[] = [...filteredDuplicates, item];
      const hasDuplicates = multipleStops?.length > 1;
      const isStart = isRoutes ? isRouteStart(item) : isVisitStart(item);

      // some of the address strings have weird charcters in them, remove them
      const address = isRoutes
        ? `${item.address.replace(/\s+/g, ' ').trim()}, ${item.city
            .replace(/\s+/g, ' ')
            .trim()}, ${item.state.replace(/\s+/g, ' ').trim()}`
            .trim()
            .replace(/\r\n/g, '')
        : `${item?.siteAddress?.street?.replace(/\s+/g, ' ').trim()}, ${item?.siteAddress?.city
            ?.replace(/\s+/g, ' ')
            .trim()}, ${item?.siteAddress?.state?.replace(/\s+/g, ' ').trim()}`
            .trim()
            .replace(/\r\n/g, '');
      // pass both the address and location lat/lng so bing doesn't freak out

      let text = `${index}`;
      text = hasDuplicates ? `${text}+` : text;
      if (isStart) {
        text = 'S';
      }
      const title = `${item.siteName.trim()}`;
      const startTitle = isRoutes ? `${tech.userName.trim()} - ${title}` : title;

      const addressText = isRoutes
        ? `${item.address.trim()}`
        : `${item?.siteAddress?.street?.trim()}`;

      const longitude = isRoutes ? item.longitude : item?.siteAddress?.longitude;

      const latitude = isRoutes ? item.latitude : item?.siteAddress?.latitude;

      routeWaypoints.push([
        longitude,
        latitude,
        title,
        address,
        text,
        startTitle,
        addressText,
        hasDuplicates,
        isStart,
        tech.color,
      ]);
    });
    techRoutes.push(routeWaypoints);
    mapRoutesAndWaypoints.push(routeWaypoints);
  });
  callback();
  return techRoutes;
};

interface IBingMap {
  techs: ITechnician[];
  setPushPins?: Dispatch<SetStateAction<any>>;
  keyId?: string;
  isRoutes?: boolean;
  reload?: boolean;
}

export const BingMap: FC<IBingMap> = ({ techs, setPushPins, keyId, isRoutes = true, reload }) => {
  const [isBuildingMapData, setBuildingMapData] = useState(false);

  const [mapRoutesAndWaypoints, setmapRoutesAndWaypoints] = useState<any[]>([]);
  const [iframeData, setIframeData] = useState<string>('');
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [showIframe, setshowIframe] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    setmapRoutesAndWaypoints([]);
    setshowIframe(false);
    setBuildingMapData(true);
    const ret = constructMapData(
      techs,
      () => {
        setBuildingMapData(false);
      },
      isRoutes,
      mapRoutesAndWaypoints
    );
    setmapRoutesAndWaypoints(ret);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techs]);

  useEffect(() => {
    setIframeData(btoa(JSON.stringify(mapRoutesAndWaypoints)));
  }, [isBuildingMapData, mapRoutesAndWaypoints]);

  useEffect(() => {
    setshowIframe(true);
  }, [iframeData, isBuildingMapData]);

  return (
    <div className={classes.map}>
      {!showIframe ? (
        <Loader position="centered" type="overlay" />
      ) : (
        <iframe
          ref={iframeRef}
          name={iframeData}
          width="100%"
          height="100%"
          src={`data:text/html;base64,${azureMap}`}
          title="myFrame"
          scrolling="no"
          frameBorder="0"
        ></iframe>
      )}
    </div>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  map: {
    position: 'relative',
    '&&, && > div': {
      minHeight: '560px',
      '@media (min-height: 768px)': {
        minHeight: '63vh',
      },
      '@media print': {
        minHeight: '560px',
        width: '768px',
        'page-break-inside': 'avoid',
      },
    },
    height: '100%',
  },
}));
