import { Environments } from '../models';
const { PCP_ENVIRONMENT, PCP_LEGACY_URL } = require('../buildSettings.json');

/**
 * Get site environment (one of 'localhost' | 'dev' | 'stg' | 'prod')
 * @param {string} origin string
 * @returns {Environments} Environments
 */
export const getEnvironment = (): Environments => {
    return PCP_ENVIRONMENT;
};

/**
 * Get the buildSettings Legacy Url value
 * @returns {string} string
 */
export const getLegacyUrl = (): string => {
  return PCP_LEGACY_URL;
}
