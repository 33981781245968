import {
  Fade,
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  IconButton,
  Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useContext, useState } from 'react';
import { Modal, SearchField, Loader, ModalSaveSection } from '../../../components';
import { UserContext } from '../../../context';
import { getUsers, getUsersInGroup, updateUsersInGroup } from '../../../fetch';
import { IListUser, IUserGroup, IResponse } from '../../../models';
import { useQuery } from 'react-query';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { deepEqual } from 'fast-equals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultUnsavedChangesMessage } from '../../../constants';

interface IEditUsersInGroupModal {
  onClose: () => void;
  isOpen: boolean;
  currentUserGroup?: IUserGroup | null;
}

export const EditUsersInGroupModal: FC<IEditUsersInGroupModal> = ({
  onClose,
  isOpen,
  currentUserGroup,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width: 767px)`);

  const confirm = useConfirm();

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentUsersInGroup, setCurrentUsersInGroup] = useState<IListUser[] | null>(null);
  const [initialUsersInGroup, setInitialUsersInGroup] = useState<IListUser[] | null>(null);
  const [searchedUsers, setSearchedUsers] = useState<IListUser[] | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { isLoading: isLoadingUserGroup } = useQuery<IListUser[], Error>(
    ['getUsersInGroup', currentUserGroup?.userGroupId],
    () => getUsersInGroup(currentUserGroup?.userGroupId as string),
    {
      enabled: !!currentUserGroup?.userGroupId,
      onSuccess: d => {
        setCurrentUsersInGroup(d);
        setInitialUsersInGroup(d);
      },
    }
  );

  const {
    isLoading,
    isRefetching,
    refetch: searchUsers,
  } = useQuery<IResponse<IListUser[]>, Error>(
    ['getUserSearchResults'],
    () =>
      getUsers({
        sortDirection: 'desc',
        sortBy: 'UserName',
        search: searchValue,
        officeId: user?.officeId,
      }),
    {
      enabled: false,
      onSuccess: d => setSearchedUsers(d.records),
    }
  );
  const isSearching = isLoading || isRefetching;

  const handleClose = async () => {
    if (!deepEqual(initialUsersInGroup, currentUsersInGroup)) {
      const result = await confirm(defaultUnsavedChangesMessage);
      if (result) {
        onClose();
        setCurrentUsersInGroup(null);
        setSearchValue('');
        setSearchedUsers(null);
        setInitialUsersInGroup(null);
      }
      return;
    }
    onClose();
    setCurrentUsersInGroup(null);
    setSearchValue('');
    setSearchedUsers(null);
    setInitialUsersInGroup(null);
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="sm"
      title={`Edit Users ${currentUserGroup ? `- ${currentUserGroup?.userGroupName}` : ''}`}
    >
      <Fade in={isOpen}>
        <div>
          {isSaving && <Loader position="centered" type="overlay" />}
          <Box mt={1}>
            <Stack gap={1}>
              <SearchField
                isDisabled={isSearching}
                placeholder="Add user..."
                handleSearch={val => {
                  if (val) {
                    searchUsers();
                  }
                }}
                handleClearSearch={() => {
                  setSearchValue('');
                  setSearchedUsers(null);
                }}
                searchValue={searchValue}
                setSearchValue={val => setSearchValue(val)}
              />
              {isSearching && <Loader position="centered" />}
              {!isSearching && searchedUsers && searchedUsers?.length > 0 && (
                <Box mt={1}>
                  <Typography variant="body1">Search Results</Typography>
                  <Divider />
                </Box>
              )}
              {!isSearching && searchedUsers?.length === 0 && (
                <Box display="flex" justifyContent="center" mt={1} mb={1}>
                  <Typography align="center">No users found.</Typography>
                </Box>
              )}
              {!isSearching && searchedUsers && searchedUsers?.length > 0 && (
                <List>
                  {searchedUsers?.map((user, index) => {
                    return (
                      <ListItem
                        key={`${index}`}
                        secondaryAction={
                          isMobile ? (
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setCurrentUsersInGroup([
                                  ...(currentUsersInGroup ?? []),
                                  { ...user, isNew: true },
                                ]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} size="sm" />
                            </IconButton>
                          ) : (
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => {
                                setCurrentUsersInGroup([
                                  ...(currentUsersInGroup ?? []),
                                  { ...user, isNew: true },
                                ]);
                              }}
                              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                              // user already exists in the group so disable the ability to add the user again
                              disabled={currentUsersInGroup
                                ?.map(u => u.userId)
                                .includes(user.userId as string)}
                            >
                              Add User
                            </Button>
                          )
                        }
                      >
                        <ListItemText
                          primary={`${user.userName} ${user.isDisabled ? '(Disabled)' : ''}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Stack>
            <Box mt={2}>
              <Typography variant="body1">Users Added</Typography>
            </Box>
            <Divider />
            {isLoadingUserGroup && (
              <Box mt={1}>
                <Loader position="centered" />
              </Box>
            )}
            {!isLoadingUserGroup && currentUsersInGroup?.length === 0 && (
              <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <Typography align="center">No users added.</Typography>
              </Box>
            )}
            {!isLoadingUserGroup && (
              <List sx={{ overflow: 'auto', maxHeight: '28rem' }}>
                {currentUsersInGroup?.map((user, index) => {
                  return (
                    <ListItem
                      key={`${index}`}
                      sx={{
                        backgroundColor: theme =>
                          user?.isNew ? theme.palette.tertiary.main : 'inherit',
                      }}
                      secondaryAction={
                        isMobile ? (
                          <IconButton
                            color="error"
                            onClick={async () => {
                              const result = await confirm(
                                'Are you sure you want to remove this user from this group?'
                              );
                              if (result) {
                                setCurrentUsersInGroup(
                                  currentUsersInGroup.filter(u => u.userId !== user.userId)
                                );
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} size="sm" />
                          </IconButton>
                        ) : (
                          <Button
                            color="error"
                            size="small"
                            onClick={async () => {
                              const result = await confirm(
                                'Are you sure you want to remove this user from this group?'
                              );
                              if (result) {
                                setCurrentUsersInGroup(
                                  currentUsersInGroup.filter(u => u.userId !== user.userId)
                                );
                              }
                            }}
                            startIcon={<FontAwesomeIcon icon={faTrash} />}
                          >
                            Remove User
                          </Button>
                        )
                      }
                    >
                      <ListItemText
                        primary={`${user.userName} ${user.isDisabled ? '(Disabled)' : ''}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
            <ModalSaveSection
              handleCancel={() => {
                handleClose();
              }}
              handleSave={async () => {
                try {
                  setIsSaving(true);
                  await updateUsersInGroup(currentUserGroup?.userGroupId as string, {
                    userIds: currentUsersInGroup?.map(user => user.userId) as string[],
                  });
                  onClose();
                  setCurrentUsersInGroup(null);
                  setSearchValue('');
                  setSearchedUsers(null);
                  setInitialUsersInGroup(null);
                  enqueueSnackbar(`Users in group saved!`, {
                    variant: 'success',
                  });
                } catch (e: any) {
                  enqueueSnackbar(e?.Detail ?? 'Error saving users in group, please try again.', {
                    variant: 'error',
                  });
                } finally {
                  setIsSaving(false);
                }
              }}
              isSaveDisabled={deepEqual(initialUsersInGroup, currentUsersInGroup)}
            />
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};
