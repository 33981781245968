import {
  Box,
  Drawer,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  List,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, Fragment, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { IRoute } from '../../models';
import sideBarImg from '../../styles/images/LeftNav-Bkg.jpg';
import { Messages } from '../messages';
import { ROLES, mainRoutes } from '../../constants';
import { UserContext } from '../../context';
import { getRelativeLink, hasNavActiveFlags, hasNavAccess, hasNavPermissions } from '../../helpers';
import { Link, ExternalLink } from '../link';
import { SubNavMenu } from './sub-nav';
import { Alerts } from '../news';
import { HEADER_HEIGHT } from '../skeleton';

export interface ISideNavProps {
  open: boolean;
  isMobile: boolean;
  setShowNav: (b: boolean) => void;
  width: number;
  setSideNavWidth?: (val: number) => void;
  navItems?: IRoute[];
  showAlerts?: boolean;
  // used for testing purposes
  isTesting?: boolean;
}

export const SideNav: FC<ISideNavProps> = ({
  open,
  isMobile,
  setShowNav,
  width,
  navItems,
  showAlerts = true,
  isTesting,
}) => {
  const { pathname } = useLocation();
  const featureFlags = useFlags();
  const theme = useTheme();

  const classes = useStyles({ sideBarImg, isTesting });
  const { user } = useContext(UserContext);
  const links = navItems ?? mainRoutes;

  const [isSubNavOpen, setSubNavOpen] = useState<Record<string, boolean>>({
    Components: pathname.includes('components/') ? true : false,
    Setup: pathname.includes('/setup'),
    Admin: pathname.includes('/admin'),
    // Keep "More" expanded when on one of its children
    More:
      pathname.includes('inventory') ||
      pathname.includes('commissions') ||
      pathname.includes('estimates') ||
      pathname.includes('payments'),
  });

  const isSelected = useCallback(
    (route: string) => {
      const splitPathname = pathname?.split?.('/').filter(Boolean);
      const splitLinkUrl = route?.split?.('/').filter(Boolean);

      const doesFirstLevelMatch = splitPathname?.[0] === splitLinkUrl?.[0];
      const doesSecondLevelMatch = splitPathname?.[1] === splitLinkUrl?.[1];

      const isKitchenSink = splitPathname?.[0] === 'kitchen-sink'; // Kitchen sink nav is 1 level off from normal nav

      const isKitchenSinkItemActive = isKitchenSink && doesFirstLevelMatch && doesSecondLevelMatch;

      const isNavItemActive =
        !isKitchenSink && (pathname === route || pathname.includes('recurring-services'));

      if (isKitchenSinkItemActive || isNavItemActive) {
        return true;
      }
      return false;
    },
    [pathname]
  );

  const renderNavLink = (link: IRoute) => {
    const TooltipListItemContent = () => (
      <>
        {link.icon && (
          <ListItemIcon
            className={clsx(classes.itemIcon, isSelected(link.link) && 'navLinkActive')}
            classes={{ root: classes.listItemIconRoot }}
            sx={{
              minWidth: !open && !isMobile ? 20 : 35,
            }}
          >
            {link.icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={open ? link.label : ''}
          className={clsx(classes.drawerMobileListItem, isSelected(link.link) && 'navLinkActive')}
        />
      </>
    );
    const ToolTipListItem = ({ isExternal, linkUrl }: { isExternal: boolean; linkUrl: string }) => (
      <Tooltip title={!open ? link.label : ''} placement="right-end">
        <ListItem className={clsx(classes.routeItem, isSelected(link.link) && 'navLinkActive')}>
          {isExternal ? (
            <ExternalLink
              to={linkUrl}
              target={link?.isNewTab ? '_blank' : undefined}
              className={classes.drawerLink}
            >
              <TooltipListItemContent />
            </ExternalLink>
          ) : (
            <Link
              to={getRelativeLink(linkUrl)}
              className={clsx(classes.drawerLink, pathname === linkUrl && classes.drawerLinkActive)}
            >
              <TooltipListItemContent />
            </Link>
          )}
        </ListItem>
      </Tooltip>
    );
    const handleLinkRender = (link: IRoute, isLegacy: boolean) => {
      let linkUrl = isLegacy && link.legacyUrl ? link.legacyUrl : link.link;
      const hasFFOff =
        link.legacyUrl && link.featureFlags && !hasNavActiveFlags(link, featureFlags);
      if (link?.subNav?.filter(link => hasNavPermissions(link, user!)).length && !hasFFOff) {
        return (
          <SubNavMenu
            showText={open}
            isSubNavOpen={isSubNavOpen}
            setSubNavOpen={setSubNavOpen}
            setShowNav={val => {
              setShowNav(val);
            }}
            link={link}
            isNavOpen={open}
            isMobile={isMobile}
          />
        );
      }
      if (link.link.startsWith('https') || isLegacy) {
        return <ToolTipListItem isExternal linkUrl={linkUrl} />;
      }
      if (link.link) {
        return <ToolTipListItem isExternal={false} linkUrl={linkUrl} />;
      }
      return null;
    };

    const shouldUseLegacyUrl =
      link.featureFlags &&
      link.legacyUrl &&
      link.isFeatureFlagOn &&
      !hasNavActiveFlags(link, featureFlags);

    // the link's flag is turned off and they don't have a legacy url, we hide the top level nav item
    if (!hasNavActiveFlags(link, featureFlags) && !shouldUseLegacyUrl) {
      return null;
    }

    /* standard top level relative links */
    return handleLinkRender(link, shouldUseLegacyUrl ? true : false);
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      className="print--none"
      data-testid="side-menu-drawer"
      sx={{
        width: isMobile ? 0 : width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          top: HEADER_HEIGHT,
        },
      }}
      open={(isMobile && open) || !isMobile}
    >
      <Box
        className={classes.navWrapper}
        sx={{
          width,
          left: !!width ? 0 : '-64px',
          zIndex: isMobile ? theme.zIndex.drawer : 1,
          overflowY: !isMobile && open ? 'scroll' : 'auto',
        }}
      >
        <List className={classes.listGroup}>
          {links
            ?.filter(link => hasNavAccess(link, user!))
            // super admin is emulating a store, disregard roles/permissions for each page
            .filter(link =>
              user?.userType === ROLES.Emulating ? true : hasNavPermissions(link, user!)
            )
            .map((link, index) => {
              return <Fragment key={`${index}`}>{renderNavLink(link)}</Fragment>;
            })}
        </List>

        {showAlerts && <>{featureFlags.v2AncillaryFeatures ? <Alerts /> : <Messages />}</>}
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles<Theme, { sideBarImg: string; isTesting?: boolean }>(theme => ({
  wrapper: {
    backgroundImage: ({ sideBarImg, isTesting }) => (isTesting ? '' : `url(${sideBarImg})`),
  },
  navWrapper: {
    backgroundImage: ({ sideBarImg, isTesting }) => (isTesting ? '' : `url(${sideBarImg})`),
    position: 'absolute',
    height: `calc(100% - 64px)`,
    maxWidth: '100%',
    overflowX: 'hidden',
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  listGroup: {
    paddingTop: 0,
  },
  itemIcon: {
    color: theme.palette.common.white,
    '&& svg': {
      width: 20,
      height: 20,
    },
    '&.navLinkActive svg': {
      color: theme.palette.secondary.main,
    },
  },
  drawerMobileListItem: {
    marginBottom: 0,
    '&& span': {
      fontSize: 13,
      fontWeight: 600,
      color: theme.palette.common.white,
    },
    '&.navLinkActive span': {
      color: theme.palette.secondary.main,
    },
  },
  routeItem: {
    display: 'flex',
    background: 'transparent',
    color: theme.palette.common.white,
    alignItems: 'center',
    border: 0,
    padding: `0.75rem 1rem`,
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:focus': {
      backgroundColor: `rgba(255, 255, 255, 0.1) !important`,
      borderColor: `rgba(255, 255, 255, 0.1) !important`,
      color: `${theme.palette.secondary.main} !important`,
      boxShadow: `none !important`,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
    },
    '&.navLinkActive': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:focus > a > div > span': {
      color: theme.palette.secondary.main,
    },
    '&:focus > a > div > svg': {
      color: theme.palette.secondary.main,
    },
    '&:hover > a > div > svg': {
      color: theme.palette.secondary.main,
    },
    '&:hover > a > div > span': {
      color: theme.palette.secondary.main,
    },
  },
  closeArrowButton: {
    color: '#fff',
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  drawerLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));
