import { FC } from 'react';
import { Card, SelectChip } from '../../../components';
import { ILookupModel } from '../../../models/util';

interface IViewColumnsProps {
  columnEnums: ILookupModel[];
}

export const ViewColumns: FC<IViewColumnsProps> = ({ columnEnums }) => (
  <Card cardTitleProps={{ title: 'View Columns' }}>
    <SelectChip
      name="viewColumns"
      label="Select"
      fullWidth={false}
      options={columnEnums}
      sx={{ width: { xs: '100%', md: '50%' } }}
    />
  </Card>
);
