import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  IInventory,
  IPaginatedResponse,
  IInventoryItem,
  IGetInventoryGridOptions,
  IInventoryLocation,
  IInventoryItemForm,
  IErrorResponse,
  IPoolCommerceInventory,
  IPoolCommercePricingReq,
  IPoolCommercePricingRes,
  IPoolCommerceProductsRes,
  IPoolCommerceCartRes,
  IInventoryResetStatus,
} from '../models';
import { userSignal } from '../signals';

export const getInventory = async (filters?: {
  search?: string;
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  before?: number;
  after?: number;
  perPage?: number | string;
  isForLabor?: boolean | null;
  page?: number;
}): Promise<IResponse<IInventory[]>> => {
  try {
    const { data } = await axios.get(`/api/inventory`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

//https://localhost:5001/api/inventory?IsForLabor=true&SortBy=LookupCode

export const getInventoryGridData = async (
  options: IGetInventoryGridOptions,
  officeId?: string | null
): Promise<IPaginatedResponse<IInventoryItem>> => {
  try {
    const { data } = await axios.get(`/api/inventory/grid`, {
      params: {
        ...options,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInventoryItems = async ({
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IInventoryItem[]> => {
  try {
    const { data } = await axios.get('/api/inventory/items', {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInventoryLocations = async (filters?: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IInventoryLocation[]>> => {
  try {
    const { data } = await axios.get(`/api/inventory/locations`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const searchInventory = async (filters?: {
  search?: string;
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IInventoryItem[]>> => {
  try {
    const { data } = await axios.get(`/api/search/inventory`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postInventoryItem = async (
  payload: IInventoryItemForm,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/inventory`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
export const putInventoryItem = async (
  inventoryId: number,
  payload: IInventoryItemForm,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/inventory/${inventoryId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getPoolCommerceInventory = async (filters?: {
  search?: string;
  sortBy?: string;
  includeSuggestions?: boolean;
  pageSize?: string;
  page?: number;
  officeId?: string | null;
}): Promise<IResponse<IPoolCommerceInventory[]>> => {
  try {
    const { data } = await axios.get(`/api/inventory/pool-commerce/inventory-search`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getPoolCommerceRealTimePricing = async (
  payload: IPoolCommercePricingReq,
  officeId?: string | null
): Promise<IPoolCommercePricingRes> => {
  try {
    const { data } = await axios.post(`/api/inventory/pool-commerce/real-time-pricing`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getInventoryItemCost = async (
  inventoryId: string | number,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/inventory/${inventoryId}/cost`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getPoolCommerceProducts = async (
  poolCommerceInventoryIds: string[],
  officeId?: string | null
): Promise<IPoolCommerceProductsRes> => {
  try {
    const { data } = await axios.post(`/api/inventory/pool-commerce/products`, {
      productIds: poolCommerceInventoryIds,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createPoolCommerceProducts = async (
  payload: { productId: string; qtyOrdered: number }[],
  officeId?: string | null
): Promise<IPoolCommerceCartRes> => {
  try {
    const { data } = await axios.post(`/api/inventory/pool-commerce/carts`, {
      products: payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const checkPriceResetInventory = async (
  officeId: string
): Promise<IInventoryResetStatus> => {
  try {
    const { data } = await axios.get(`/api/inventory/${officeId}/price-reset`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const priceResetInventory = async (officeId?: string | null): Promise<void> => {
  try {
    const { data } = await axios.post(`/api/inventory/price-reset`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
