import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { ITranCodeGroups } from '../../../models';
import { deleteTranCodeGroup, getAllTranCodeGroups } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { AddEditTranCodeGroupsModal } from './add-edit-tran-code-groups-modal';

export const TranCodeGroupsTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentTranCodeGroup, setCurrentTranCodeGroup] = useState<ITranCodeGroups | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ITranCodeGroups> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllTranCodeGroups(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading Transaction Code Groups, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITranCodeGroups>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tran-code-groups-grid',
      sortColumn: 'code',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ITranCodeGroups>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Transaction Code Group"
                onClick={() => {
                  showModal(true);
                  setCurrentTranCodeGroup(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Transaction Code Group"
                onClick={async () => {
                  try {
                    const result = await confirm(
                      'Are you sure you want to delete this Transaction Code Group?'
                    );
                    if (result) {
                      setDeleting(true);
                      await deleteTranCodeGroup(params.row.tranCodeGroupId);
                      await refetch();
                      enqueueSnackbar(`Transaction Code Group Deleted!`, {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(`Error deleting Transaction Code Group, please try again.`, {
                      variant: 'error',
                    });
                  } finally {
                    setDeleting(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Transaction Code Groups"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Transaction Code Group
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ITranCodeGroups) => row.tranCodeGroupId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
        />
      </Card>
      <AddEditTranCodeGroupsModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentTranCodeGroup(null);
        }}
        currentTranCodeGroup={currentTranCodeGroup}
        fetchTranCodeGroups={() => refetch()}
      />
    </>
  );
};
