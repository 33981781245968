import { GridSortDirection } from '@mui/x-data-grid';
import { axios } from '../helpers';
import qs from 'qs';
import {
  IResponse,
  ILead,
  IGetFiltersResponse,
  ILeadNote,
  ILeadsByMonth,
  ILeadsBySource,
  IDropdownResponse,
  ILeadPost,
  ILeadDetail,
  ILeadUpdate,
  ILeadEstimatePost,
} from '../models';
import { stringNumNull } from '../helpers/util';
import { userSignal } from '../signals';

export const getLeads = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection | string;
  page?: number;
  perPage?: number | string;
  statuses?: string[];
  accountId?: string;
  officeId?: string | null;
  leadStartDate?: string;
  leadEndDate?: string;
}): Promise<IResponse<ILead[]>> => {
  try {
    const { data } = await axios.get(`/api/leads`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const createLead = async (payload: ILeadPost, officeId?: string | null) => {
  try {
    const { data } = await axios.post(`/api/leads`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const updateLead = async (
  leadId: string | number,
  payload: ILeadUpdate,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/leads/${leadId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getLead = async (
  leadId: string | number,
  officeId?: string | null
): Promise<ILeadDetail> => {
  try {
    const { data } = await axios.get(`/api/leads/${leadId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getLeadsFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/leads/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: (params: any) => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getLeadSources = async (officeId?: string | null): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/leads/sources`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getLeadServiceTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/leads/service-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getSingleLeadNotes = async (
  leadId: string | number,
  officeId?: string | null
): Promise<ILeadNote[]> => {
  try {
    const { data } = await axios.get(`/api/leads/${leadId}/notes`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const createSingleLeadNote = async (
  leadId: stringNumNull,
  note: string,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(`/api/leads/${leadId}/notes`, {
      note,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const updateSingleLeadNote = async (
  leadId: stringNumNull,
  noteId: stringNumNull,
  note: string,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/leads/${leadId}/notes/${noteId}`, {
      note,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const deleteSingleLeadNote = async (leadId: string | number, noteId: string | number) => {
  try {
    const { data } = await axios.delete(`/api/leads/${leadId}/notes/${noteId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const createEstimateFromLead = async (
  leadId: stringNumNull,
  payload: ILeadEstimatePost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/leads/${leadId}/estimates`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const createInvoiceFromLeadEstimate = async (
  leadId: stringNumNull,
  estimateId: string | number,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/leads/${leadId}/estimates/${estimateId}/bought`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getLeadsByMonth = async (officeId?: string | null): Promise<ILeadsByMonth[]> => {
  try {
    const { data } = await axios.get(`/api/leads/leads-per-month`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getLeadsBySource = async (officeId?: string | null): Promise<ILeadsBySource[]> => {
  try {
    const { data } = await axios.get(`/api/leads/leads-by-source`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const reassignLead = async (
  leadId: stringNumNull,
  payload: {
    officeId: string;
    note: string;
  },
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/leads/${leadId}/reassign`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};
