import { axios } from '../helpers';
import { IMessage } from '../models';
import { userSignal } from '../signals';
import qs from 'querystring';

export const getMessages = async (officeId?: string | null): Promise<IMessage> => {
  try {
    const { data } = await axios.get('/api/messages', {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
