import { IInventoryItem } from '../../models';
import { FC } from 'react';
import { inventoryColumns } from './inventory-columns';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridColDef } from '@mui/x-data-grid';

interface InventoryDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IInventoryItem[];
  columns?: GridColDef[];
}

export const InventoryDataGrid: FC<InventoryDataGridProps> = ({ columns, ...gridProps }) => {
  return (
    <ServerSideDataGrid
      autoHeight
      stickyActionsColumn={!!columns}
      getRowId={(row: IInventoryItem) => row.inventoryId}
      columns={columns ?? inventoryColumns}
      sx={{
        '&& .MuiDataGrid-row .MuiDataGrid-cell': {
          fontSize: 13,
        },
      }}
      {...gridProps}
    />
  );
};
