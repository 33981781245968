import {
  StyleGuide,
  StyleGuideButtons,
  StyleGuideCardButton,
  StyleGuideCardDisplayGroup,
  StyleGuideCardFloatingToolbar,
  StyleGuideCardTitle,
  StyleGuideCards,
  StyleGuideDateRangePicker,
  StyleGuideGridsOverview,
  StyleGuideIconPageHeading,
  StyleGuideLoader,
  StyleGuideModals,
  StyleGuidePageComponent,
  StyleGuidePageTitle,
  StyleGuidePagination,
  StyleGuideServerSideDataGrid,
  StyleGuideTableLegend,
  StyleGuideTabs,
  StyleGuideUIUXPatterns,
  StyleGuideDateRangeNavigator,
  StyleGuideTextField,
  StyleGuidePhoneTextField,
  StyleGuideWYSIWYG,
  StyleGuideSelectField,
} from '../pages';
import { StyleGuidePaths } from './style-guide-links';
// This file is intended to make sharing style guide routes easier across App and App.360
export const styleGuideRoutes = [
  { path: StyleGuidePaths.kitchenSink.url, child: <StyleGuide /> },
  { path: StyleGuidePaths.buttons.url, child: <StyleGuideButtons /> },
  { path: StyleGuidePaths.cardsPrimary.url, child: <StyleGuideCards /> },
  { path: StyleGuidePaths.cardButton.url, child: <StyleGuideCardButton /> },
  { path: StyleGuidePaths.cardTitle.url, child: <StyleGuideCardTitle /> },
  { path: StyleGuidePaths.grids.url, child: <StyleGuideGridsOverview /> },
  { path: StyleGuidePaths.dataGrid.url, child: <StyleGuideServerSideDataGrid /> },
  { path: StyleGuidePaths.tableLegend.url, child: <StyleGuideTableLegend /> },
  { path: StyleGuidePaths.pagination.url, child: <StyleGuidePagination /> },
  { path: StyleGuidePaths.textField.url, child: <StyleGuideTextField /> },
  { path: StyleGuidePaths.phoneTextField.url, child: <StyleGuidePhoneTextField /> },
  { path: StyleGuidePaths.selectField.url, child: <StyleGuideSelectField /> },
  { path: StyleGuidePaths.wysiwyg.url, child: <StyleGuideWYSIWYG /> },
  { path: StyleGuidePaths.dateRangePicker.url, child: <StyleGuideDateRangePicker /> },
  { path: StyleGuidePaths.dateRangeNavigator.url, child: <StyleGuideDateRangeNavigator /> },
  { path: StyleGuidePaths.displayGroup.url, child: <StyleGuideCardDisplayGroup /> },
  { path: StyleGuidePaths.loader.url, child: <StyleGuideLoader /> },
  { path: StyleGuidePaths.modals.url, child: <StyleGuideModals /> },
  { path: StyleGuidePaths.page.url, child: <StyleGuidePageComponent /> },
  { path: StyleGuidePaths.pageTitle.url, child: <StyleGuidePageTitle /> },
  { path: StyleGuidePaths.iconPageHeading.url, child: <StyleGuideIconPageHeading /> },
  { path: StyleGuidePaths.tabs.url, child: <StyleGuideTabs /> },
  { path: StyleGuidePaths.floatingToolbar.url, child: <StyleGuideCardFloatingToolbar /> },
  { path: StyleGuidePaths.uiUxPatterns.url, child: <StyleGuideUIUXPatterns /> },
];
