import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, formatMoney } from '../../helpers';
import { Box, IconButton } from '@mui/material';
import { IEstimate, ILeadDetail } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileInvoiceDollar, faTrash } from '@fortawesome/free-solid-svg-icons';

interface RelatedEstimatesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IEstimate[];
  refetch: () => Promise<void>;
  handleEdit: (estimate: IEstimate) => void;
  handleDelete: (estimateId: string | number) => Promise<void>;
  leadDetail: ILeadDetail | null;
  leadId: string;
  setActiveEstimate: React.Dispatch<React.SetStateAction<IEstimate | null>>;
}

export const RelatedEstimatesDataGrid: FC<RelatedEstimatesDataGridProps> = ({
  rows,
  refetch,
  handleEdit,
  handleDelete,
  leadDetail,
  leadId,
  setActiveEstimate,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: 'estimateNumber',
        headerName: 'Number',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'whenCreated',
        headerName: 'Created',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return <>{estimate.whenCreated ? formatDate(estimate.whenCreated) : ''}</>;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'total',
        headerName: 'Total',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return <>{estimate.total ? formatMoney(estimate.total) : '$0.00'}</>;
        },
      },
      {
        field: 'details',
        headerName: '',
        flex: 1,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Estimate"
                onClick={() => handleEdit(estimate)}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              {leadDetail?.status !== 'Won' && (
                <IconButton
                  color="secondary"
                  title="Create Invoice"
                  onClick={() => setActiveEstimate(estimate)}
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" />
                </IconButton>
              )}
              {!estimate?.agreementStatus?.includes('Signed') && (
                <IconButton
                  color="error"
                  title="Delete Estimate"
                  onClick={async () => handleDelete(estimate.estimateId)}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IEstimate) => row.estimateId!}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      {...gridProps}
    />
  );
};
