import qs from 'querystring';
import { axios } from '../helpers';
import { userSignal } from '../signals';

export const getCustomerAgreement = async (
  agreementId: string,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.get(`/api/custom-agreements/${agreementId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
