import { ISearchCondition, ICustomViewFormValues, VIEW_VISIBILITY } from '../../models/custom-view';
import { ILookupModel } from '../../models';

export const INITIAL_SEARCH_CONDITIONS: ISearchCondition = {
  operator: 'NONE',
  columnName: '',
  comparator: '',
  compareValue: '',
  isPending: false,
  isGrouped: false,
  groupId: 0,
};

export const INITIAL_FORM_STATE: ICustomViewFormValues = {
  viewName: '',
  viewVisibility: VIEW_VISIBILITY.PUBLIC,
  searchConditions: [INITIAL_SEARCH_CONDITIONS],
  viewColumns: [
    {
      value: 'AccountCode',
      description: 'Account Code',
      shorthand: 'Account Code',
    },
    {
      value: 'AccountName',
      description: 'Account Name',
      shorthand: 'Account Name',
    },
    {
      value: 'Status',
      description: 'Status',
      shorthand: 'Status',
    },
    {
      value: 'AccountStreet',
      description: 'Account Street',
      shorthand: 'Account Street',
    },
    {
      value: 'RouteCode',
      description: 'Route Code',
      shorthand: 'Route Code',
    },
    {
      value: 'PrimaryPhoneNumber',
      description: 'Primary Phone Number',
      shorthand: 'Primary Phone Number',
    },
    {
      value: 'startupInfo',
      description: 'Startup Info',
      shorthand: 'Startup Info',
    },
  ],
};

export const AND_OR: ILookupModel[] = [
  {
    value: 'AND',
    description: 'And',
    shorthand: 'And',
  },
  {
    value: 'OR',
    description: 'Or',
    shorthand: 'Or',
  },
];

export const MIN_VIEW_COLUMNS = 1;
export const MAX_VIEW_COLUMNS = 10;
