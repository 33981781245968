import { FC, useContext, useState } from 'react';
import { Card, CardTitle, SearchField, IconPageHeading } from '../../components';
import { UserContext } from '../../context';
import { Box } from '@mui/material';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InventoryListDetails } from './inventory-list-details';
import { InventorySearchDetails } from './inventory-search-details';

interface IInventoryList {}

export const InventoryList: FC<IInventoryList> = () => {
  const { user } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchRecordCount, setSearchRecordCount] = useState<number | null>(null);

  return (
    <>
      {searchedValue && !!searchRecordCount && (
        <Box mb={2}>
          <IconPageHeading
            icon={faSearch}
            showHome={false}
            showDivider={false}
            heading={`${searchRecordCount ?? 0} Results for "${searchedValue}"`}
          />
        </Box>
      )}
      <Card>
        <CardTitle
          title=""
          mobileWrap
          action={
            <SearchField
              isDisabled={isLoading}
              placeholder="Find inventory..."
              handleSearch={val => {
                if (val) {
                  setSearchedValue(val);
                }
              }}
              handleClearSearch={() => {
                setSearchValue('');
                setSearchedValue('');
                setSearchRecordCount(null);
              }}
              searchValue={searchValue}
              setSearchValue={val => {
                if (!val) {
                  setSearchedValue('');
                  setSearchRecordCount(null);
                }
                setSearchValue(val);
              }}
            />
          }
        >
          {!!searchedValue ? (
            <InventorySearchDetails
              searchedValue={searchedValue}
              user={user}
              setLoading={val => setIsLoading(val)}
              setSearchRecordCount={val => setSearchRecordCount(val)}
            />
          ) : (
            <InventoryListDetails
              searchedValue={searchedValue}
              user={user}
              setLoading={val => setIsLoading(val)}
            />
          )}
        </CardTitle>
      </Card>
    </>
  );
};
