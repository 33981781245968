import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { PrimaryIcon } from '../icon';

interface ITableLegendIconProps {
  icon: IconDefinition;
  helpText: string;
}

export const TableLegendIcon: FC<ITableLegendIconProps> = ({ icon, helpText }) => {
  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Box mr={0.5} display="flex">
        <PrimaryIcon icon={icon} />
      </Box>
      <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
        {helpText}
      </Typography>
    </Box>
  );
};
