import { IFilter } from '../../models';

export const createFilterValueLookupTable = (filters: IFilter[]) => {
  const initialValues = {} as Record<string, string[]>;
  filters.forEach(param => {
    let defaultValues = param.defaultValues;

    if (param.selectListItems?.length) {
      switch (param.type) {
        case 'CheckboxList':
          const selectedItems = param.selectListItems.filter(item => item.selected);
          if (selectedItems.length) {
            defaultValues = selectedItems.map(item => item.value);
          }
          break;
        case 'SelectListMulti':
          const selectedMultiListItems = param.selectListItems.filter(item => item.selected);
          if (selectedMultiListItems.length) {
            defaultValues = selectedMultiListItems.map(item => item.value);
          }
          break;
        default:
          const selectedItem = param.selectListItems.find(item => item.selected);
          if (selectedItem && !defaultValues) {
            defaultValues = [selectedItem.value];
          }
          break;
      }
    }

    initialValues[param.name] = defaultValues || [];
  });
  return initialValues;
};

export const getCleanFilterValues = (values: Record<string, string[]>) => {
  const cleanValues: Record<string, string[]> = {};
  Object.entries(values).forEach(([key, value]) => {
    if (!value.length) {
      return;
    }
    if (value.length && value.every(s => !s.trim())) {
      return;
    }
    cleanValues[key] = value;
  });
  return cleanValues;
};
