import { FC, useEffect, useMemo, useState } from 'react';
import { ExternalLink, Loader, Modal, ModalSaveSection, SimpleDataGrid } from '../../components';
import { formatMoney } from '../../helpers';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IPool360LineItem, IPoolCommerceProductsRes } from '../../models';
import { Alert, Checkbox, Tooltip, Box } from '@mui/material';
import { useQuery } from 'react-query';
import { createPoolCommerceProducts, getPoolCommerceProducts } from '../../fetch';
import { useSnackbar } from 'notistack';
const { REACT_APP_POOL_360_COMMERCE_URL } = require('../../buildSettings.json');

interface IPool360CartModalProps {
  isOpen: boolean;
  onClose: () => void;
  rows: IPool360LineItem[];
}

export const Pool360CartModal: FC<IPool360CartModalProps> = ({ rows, isOpen, onClose }) => {
  const [cartLineItems, setCartLineItems] = useState<IPool360LineItem[]>([]);
  const [isSaving, setSaving] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isLoadingPool360Products, data: pool360Products } = useQuery<
    IPoolCommerceProductsRes,
    Error
  >(
    ['getPoolCommerceProducts', rows, isOpen],
    () => getPoolCommerceProducts(rows.map(r => r.poolCommerceInventoryId!)),
    {
      enabled: rows.length > 0 && isOpen,
      notifyOnChangeProps: ['data'],
    }
  );

  useEffect(() => {
    if (pool360Products && pool360Products?.results?.length > 0) {
      const items = [];
      for (const row of rows) {
        const product = pool360Products.results.find(
          p => p.productId === row.poolCommerceInventoryId
        )!;
        items.push({
          ...row,
          cost: product.unitNetPrice,
          amount: row.quantity * product.unitNetPrice,
          isSelected: product.canAddToCart ?? false,
          canAddToCart: product.canAddToCart,
        });
      }
      setCartLineItems(items);
    }
  }, [rows, pool360Products]);

  const lineItemsTotalAmount = useMemo(() => {
    if (cartLineItems && cartLineItems.length > 0) {
      const lineItemsAmount = cartLineItems.map(item => (item.cost || 0) * item.quantity ?? 0);
      const amountSum = lineItemsAmount.reduce((a, b) => a + b, 0);
      return amountSum;
    }
    return 0;
  }, [cartLineItems]);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setCartLineItems([]);
        setMessage(null);
      }}
      title="Order from Pool 360"
      maxWidth="lg"
    >
      <form
        onSubmit={async e => {
          e.preventDefault();
          e.stopPropagation();

          try {
            setSaving(true);
            const res = await createPoolCommerceProducts(
              cartLineItems
                .filter(item => item.isSelected)
                .map(item => ({
                  productId: item.poolCommerceInventoryId!,
                  qtyOrdered: item.quantity,
                }))
            );
            setMessage(`${res.itemsAddedToCart} items added to cart.`);
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving cart, please try again.`, {
              variant: 'error',
            });
          } finally {
            setSaving(false);
          }
        }}
      >
        {isSaving && <Loader type="overlay" />}

        {message && (
          <Box mt={1} mb={1}>
            <Alert severity="success">
              {message}{' '}
              <ExternalLink target="_blank" to={`${REACT_APP_POOL_360_COMMERCE_URL}/Cart`}>
                View Cart
              </ExternalLink>
            </Alert>
          </Box>
        )}
        <SimpleDataGrid
          rows={[
            ...cartLineItems,
            {
              estimateLineItemId: '',
              lookupCode: '',
              details: '',
              quantity: 'Item Total:',
              amount: lineItemsTotalAmount,
            },
          ]}
          loading={isLoadingPool360Products}
          autoHeight
          disableRowSelectionOnClick
          hideFooter
          getRowId={(row: IPool360LineItem) => row.estimateLineItemId!}
          columns={[
            {
              field: 'isSelected',
              headerName: '',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              maxWidth: 75,
              renderCell: (params: GridRenderCellParams<IPool360LineItem>) => {
                const { row: lineItem } = params;
                return (
                  lineItem.quantity.toString() !== 'Item Total:' && (
                    <Tooltip title={!lineItem.canAddToCart ? 'Please call to order this item' : ''}>
                      <span>
                        <Checkbox
                          checked={lineItem.isSelected}
                          disabled={!lineItem.canAddToCart}
                          onChange={(_, checked) => {
                            const copy: IPool360LineItem[] = JSON.parse(
                              JSON.stringify(cartLineItems)
                            );
                            const index = copy.findIndex(
                              item => item.estimateLineItemId === lineItem.estimateLineItemId
                            );
                            copy[index].isSelected = checked;
                            setCartLineItems(copy);
                          }}
                        />
                      </span>
                    </Tooltip>
                  )
                );
              },
            },
            {
              field: 'lookupCode',
              headerName: 'Lookup Code',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              minWidth: 150,
            },
            {
              field: 'details',
              headerName: 'Details',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              type: 'string',
              flex: 1,
            },
            {
              field: 'cost',
              headerName: 'Cost',
              filterable: false,
              sortable: false,
              align: 'right',
              headerAlign: 'right',
              disableColumnMenu: true,
              maxWidth: 75,
              renderCell: (params: GridRenderCellParams<IPool360LineItem>) => {
                const { row: lineItem } = params;
                return <>{lineItem.cost ? formatMoney(lineItem.cost) : ''}</>;
              },
            },
            {
              field: 'quantity',
              headerName: 'Qty',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              field: 'amount',
              headerName: 'Order Amount',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              align: 'right',
              headerAlign: 'right',
              minWidth: 150,
              renderCell: (params: GridRenderCellParams<IPool360LineItem>) => {
                const { row: lineItem } = params;
                if (lineItem?.quantity?.toString() === 'Item Total:') {
                  return <>{formatMoney(lineItem.amount)}</>;
                }
                return (
                  <>{lineItem.cost ? formatMoney(lineItem.cost * lineItem.quantity) : '$0.00'}</>
                );
              },
            },
          ]}
        />
        <ModalSaveSection
          handleCancel={() => {
            onClose();
            setCartLineItems([]);
            setMessage(null);
          }}
          submitLabel="Add To Cart"
          isSaveDisabled={
            cartLineItems.filter(item => item.isSelected).length === 0 ||
            isSaving ||
            isLoadingPool360Products
          }
        />
      </form>
    </Modal>
  );
};
