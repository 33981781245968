import { FC, useCallback, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Modal, Loader } from '../../../components';
import {
  Box,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  InputAdornment,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertPercentToNumber, getNumberRange } from '../../../helpers';
import { getBillingGroups, getLateFeeOptions, getAllTranCodes, postLateFees } from '../../../fetch';
import { IBillingGroup, ILateFeeOption } from '../../../models';

interface FormValues {
  officeId: string;
  agingDate: Date;
  agingDays: number;
  minBalance: string;
  percentage: string;
  amount: string;
  tranCodeId: string;
  reference: string;
  option: string;
  billingGroupId: string;
}

const DEFAULT_VALUES = {
  officeId: '',
  agingDate: new Date(),
  agingDays: 1,
  minBalance: '0.01',
  percentage: '1',
  amount: '0.01',
  tranCodeId: '',
  reference: 'Late Fees',
  option: '',
  billingGroupId: '',
} as FormValues;

interface ILateFeesModal {
  open: boolean;
  onClose: () => void;
  billingGroupId?: string | null;
}

const MIN_AGING_DAYS = 1;
const MAX_AGING_DAYS = 180;

const numberValidation = () => {
  return yup
    .number()
    .typeError('Must be a valid number')
    .moreThan(0, 'Must be more than 0')
    .required();
};

const LateFeesSchema = yup.object().shape({
  agingDate: yup.string().typeError('Must be a valid date').required().label('Minimum date'),
  agingDays: yup.number().min(MIN_AGING_DAYS).max(MAX_AGING_DAYS).required(),
  minBalance: numberValidation().label('Minimum balance'),
  percentage: numberValidation().label('Late fee percentage'),
  amount: numberValidation(),
  reference: yup.string().required().label('Description'),
  option: yup.string().required(),
  billingGroupId: yup.string().required(),
});

const agingDaysOptions = getNumberRange?.(1, 180, 1);

export const LateFeesModal: FC<ILateFeesModal> = ({ open, onClose, billingGroupId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [billingGroups, setBillingGroups] = useState<IBillingGroup[]>([]);
  const [selectedTranCode, setSelectedTranCode] = useState<string>('');
  const [lateFeeOptions, setLateFeeOptions] = useState<ILateFeeOption[]>([]);

  const {
    resetForm,
    isSubmitting,
    handleSubmit,
    values,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik<FormValues>({
    validateOnChange: true,
    initialValues: { ...DEFAULT_VALUES, billingGroupId: billingGroupId || '' },
    validationSchema: LateFeesSchema,
    onSubmit: async (values, actions) => {
      try {
        const officeId = billingGroups.find(
          group => group.billingGroupId === values.billingGroupId
        )?.officeId;
        if (!officeId) {
          return;
        }
        await postLateFees({
          officeId,
          agingDate: values.agingDate.toISOString(),
          agingDays: values.agingDays,
          minBalance: +values.minBalance,
          percentage: convertPercentToNumber(+values.percentage),
          amount: +values.amount,
          tranCodeId: selectedTranCode,
          reference: values.reference,
          option: values.option,
          billingGroupId: values.billingGroupId,
        });
        enqueueSnackbar('Successfully posted late fees', {
          variant: 'success',
        });
        onClose();
        actions.resetForm();
      } catch (error) {
        enqueueSnackbar('Posting late fees failed', {
          variant: 'error',
        });
      }
    },
  });

  const reset = useCallback(() => {
    resetForm({
      values: {
        ...DEFAULT_VALUES,
        billingGroupId: billingGroupId || '',
      },
    });
  }, [billingGroupId, resetForm]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const loadBillingGroups = async () => {
    const billingGroupData = await getBillingGroups({ perPage: -1 });
    setBillingGroups(billingGroupData.records);
  };

  const loadTranCode = async () => {
    const tranCodesData = await getAllTranCodes({ perPage: -1 });
    const lateFeesId =
      tranCodesData.records.filter(item => item.description === 'Late Fee')[0]?.tranCodeId ??
      '164919ff-8d19-431a-89c1-b5dcd3af9b4a';
    setSelectedTranCode(lateFeesId);
  };

  const loadLateFeeOptions = async () => {
    const lateFeeOptionData = await getLateFeeOptions();
    setLateFeeOptions(lateFeeOptionData);
  };

  useEffect(() => {
    loadBillingGroups();
    loadTranCode();
    loadLateFeeOptions();
  }, []);

  useEffect(() => {
    reset();
  }, [billingGroupId, reset]);

  return (
    <Modal open={open} onClose={handleClose} maxWidth="sm" title="Post Late Fees">
      {isSubmitting && <Loader type="overlay" position="centered" title="Posting..." />}
      <Fade in={open}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel className={classes.selectLabel}>
                  Post to accounts with balances older than this many days
                </InputLabel>
                <Select name="agingDays" value={values.agingDays} onChange={handleChange}>
                  {agingDaysOptions.map(n => (
                    <MenuItem key={n} value={n}>
                      {n}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Compute aging days as of this date"
                format="MM/dd/yyyy"
                value={values.agingDate}
                onChange={value => setFieldValue('agingDate', value, true)}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                    error: !!errors.agingDate,
                    helperText: errors.agingDate,
                    InputLabelProps: { shrink: true }
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="minBalance"
                required
                size="small"
                label="Only accounts with aged balances over"
                placeholder="0.00"
                InputLabelProps={{ shrink: true }}
                value={values.minBalance}
                onChange={handleChange}
                error={!!errors.minBalance}
                helperText={errors.minBalance}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel className={classes.selectLabel}>Select Billing Group</InputLabel>
                <Select name="billingGroupId" value={values.billingGroupId} onChange={handleChange}>
                  {billingGroups.map(group => (
                    <MenuItem key={group.billingGroupId} value={group.billingGroupId}>
                      {group.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="percentage"
                required
                size="small"
                label="Late fee percentage"
                value={values.percentage}
                onChange={handleChange}
                error={!!errors.percentage}
                helperText={errors.percentage}
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="amount"
                required
                size="small"
                label="Late fee flat amount"
                placeholder="0.00"
                value={values.amount}
                onChange={handleChange}
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="reference"
                required
                size="small"
                label="Description to show on statement"
                value={values.reference}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.reference && !!errors.reference}
                helperText={touched.reference ? errors.reference : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel className={classes.selectLabel}>Late fee transaction type</InputLabel>
                <Select name="option" value={values.option} onChange={handleChange}>
                  {lateFeeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <Button type="button" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={!dirty || isSubmitting || !isValid} type="submit" color="secondary">
              Post Late Fees
            </Button>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    background: 'white',
  },
}));
