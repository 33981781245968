import { Page } from '../../../components';
import { OTSLandingServices } from './ots-landing-services';

export const OTSLandingPage = () => {
  return (
    <Page title="One-Time Services">
      <OTSLandingServices />
    </Page>
  );
};
