export interface IScheduledService {
  accountId: string;
  scheduledServiceId: string;
  serviceDate: string;
  status: string;
  siteName: string;
  showBeakerIcon: boolean;
  showBrushIcon: boolean;
  showWrenchIcon: boolean;
  showServicePhotoIcon: boolean;
  showServiceCompleteIcon: boolean;
  showChemicalUseIcon: boolean;
  showPoolEquipmentIcon: boolean;
  showWaterTestIcon: boolean;
  serviceType: string;
  technician: string;
  repeatLink: string;
  whenStarted: string;
  whenCompleted: string;
  addressId: string;
  siteId: string;
  recurringServiceId: string;
  frequency: string;
  completed: string;
  assignedTo: string;
  psi: number;
  cleanPsi: number;
  completionNotes: string;
  tbid: number;
}

export enum Frequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface ICreateScheduledService {
  siteId: string;
  serviceDate: Date | string | null;
  serviceDefId: string;
  status: string;
  assignedTo: string;
  earliestArrival: Date | string | null;
  latestArrival: Date | string | null;
  estimateDuration: number | null;
  comments: string | null;
  completionNotes: string | null;
  serviceAmount: number | null;
  whenStarted: string | null;
  whenCompleted: string | null;
  whenCreated: Date | string | null;
  createdBy: string;
  isOverride: boolean;
}

export interface IUpdateScheduledService extends ICreateScheduledService {
  serviceChecklistItems: IServiceSuggestionChecklist[];
  sendEmail: boolean;
  emailTemplateId: string | null;
  emailAddresses: string[] | null;
  emailAddressBccs: string[];
  version: string | null; // For concurrent editing. BE compares with db value and returns error if no match.
}

export type ServiceSuggestionType = 'Single' | 'RecurringService';
export enum EServiceSuggestionType {
  SINGLE = 'Single',
  RECURRING = 'RecurringService',
}
export type DaysOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';
export enum EDaysOfWeek {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}
export enum EDaysOfWeekAbbreviations {
  Sunday = 'Sun',
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
}
export interface IServiceSuggestionResponse {
  siteId: string;
  siteName?: string | null;
  suggestions: ISiteSuggestionItem[] | null;
}

export interface ISiteSuggestionItem {
  date?: string | null;
  siteId: string;
  siteName?: string | null;
  accountId: string;
  dayOfWeek: DaysOfWeek;
  serviceDate: string;
  distance: number;
  duration: number;
  userId: string;
  username?: string | null;
  numberOfStops: number;
}

export interface IServiceSuggestion {
  dayOfWeek?: DaysOfWeek;
  userId?: string;
  username?: string;
  numberOfStops?: number;
  siteId?: string;
  siteName?: string;
  distance?: number;
  duration?: number;
  accountId?: string;
  serviceDate?: string;
  index?: number;
}

export interface IServiceSuggestionChecklist {
  isDeleted: boolean | undefined;
  serviceDefChecklistItemId: string;
  sortOrder: number;
  description: string;
  isOptional: boolean;
  limit: number;
  scheduledServiceId: string;
  scheduledServiceChecklistItemId: string;
  isCompleted: boolean;
  whenPreviouslyCompleted: string;
}

export interface IScheduledServiceLog {
  serviceLogId: string;
  userName: string;
  logEntry: string;
  whenCreated: string;
}

export interface IScheduledServiceDetail {
  addressId: string;
  amountToCharge: number;
  amountToChargeOverride: boolean;
  assignedTo: string;
  assignedToUserId: string;
  comments: string;
  completed: string | null;
  completionNotes: string;
  createdByUserId: string;
  currentPSI: number;
  durationInMinutes: number;
  earliestArrival: string;
  endDate: string;
  frequency: string;
  hasPoolEquipment: boolean;
  interval1: number;
  interval2: number;
  interval3: number;
  isAutoExtend: boolean;
  isManualRoute: boolean;
  latestArrival: string;
  officeId: string;
  previousPSI: number;
  previousSiteId?: string | null;
  recurOption: boolean;
  recurPeriod: string | null;
  recurringServiceId: string;
  repeatLink: string;
  scheduledServiceId: string;
  serviceChecklistItems: IServiceSuggestionChecklist[];
  serviceDate: string;
  serviceDefId: string;
  serviceDefReportLayout: string | undefined;
  serviceType: string;
  showBeakerIcon: boolean;
  showBrushIcon: boolean;
  showWrenchIcon: boolean;
  siteAccountId: string;
  siteId: string;
  siteName: string;
  status: string;
  technician: string;
  transactionCode: string;
  transactionId: string;
  version?: string | null; // For concurrent editing. BE compares with db value and returns error if no match.
  whenCompleted: string;
  whenCreated: string;
  whenStarted: string;
}

export enum ServicePodType {
  RECOMMENDATIONS = 'recommendations',
  POOL = 'pool',
  ANALYSIS = 'analysis',
  PHOTO = 'photo',
  DETAILS = 'details',
}

export enum ServiceTypeTab {
  RECURRING = 'R',
  OPEN = 'O',
  CLOSED = 'C',
}

export interface MonthlyDayOption {
  label: string;
  value: number;
}