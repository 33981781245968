import { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, Card, ServerSideDataGrid } from '../../components';
import { formatDate, getLocalPageSize, TREATMENT_GRID_KEY } from '../../helpers';
import { ITreatment, IResponse, ICreateTreatment } from '../../models';
import { getTreatments } from '../../fetch';
import { Button } from '@mui/material';
import { TreatmentModal } from './TreatmentModal';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface ITreatmentProps {
  siteId: string;
  siteName?: string;
  serviceId?: string;
  isModal?: boolean;
  isServiceDetailPage?: boolean;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isEditable?: boolean;
  isCardLayout?: boolean;
}
export const Treatments: FC<ITreatmentProps> = ({
  siteId,
  siteName,
  serviceId,
  isModal,
  isServiceDetailPage,
  isExpanded,
  isCollapsible = false,
  initialExpand = false,
  isEditable = true,
  isCardLayout = true,
}) => {
  const [isShowingTreatmentModal, showTreatmentModal] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(getLocalPageSize(TREATMENT_GRID_KEY));
  const [selectedTreatments, setSelectedTreatments] = useState<ICreateTreatment[]>([]);
  const [defaultTreatments, setDefautTreatments] = useState<ICreateTreatment[]>([]);
  const [initialTreatments, setInitialTreatments] = useState<ICreateTreatment[]>([]);
  const [whenAdded, setWhenAdded] = useState<Date | string | null>(null);

  const {
    isLoading: isLoadingAllTreatments,
    data: allTreatmentsData,
    refetch: fetchAllTreatments,
  } = useQuery<IResponse<ITreatment[]>, Error>(['all-treatments', perPage, page], () =>
    getTreatments(siteId, {
      perPage: -1,
      sortDirection: 'Desc',
      sortBy: 'WhenAdded',
      scheduledServiceId: serviceId,
    })
  );

  const {
    isLoading: isLoadingTreatments,
    data: treatmentsData,
    refetch: refetchTreatments,
  } = useQuery<IResponse<ITreatment[]>, Error>(
    ['treatments', perPage, page],
    () =>
      getTreatments(siteId, {
        page: page + 1,
        perPage,
        sortDirection: 'Desc',
        sortBy: 'WhenAdded',
        scheduledServiceId: serviceId,
      }),
    {
      onSuccess: data => {
        setRecordCount(data.totalRecordCount);
      },
      notifyOnChangeProps: 'tracked',
    }
  );

  const treatments = useMemo(() => treatmentsData?.records ?? [], [treatmentsData]);
  const allTreatments = useMemo(() => allTreatmentsData?.records ?? [], [allTreatmentsData]);

  const setTreatmentsForDate = (date: string | Date) => {
    // find all of the treatment options in the table by date
    const groupedTreatmentsByDate = allTreatments.filter(
      t => formatDate(t.whenAdded) === formatDate(date)
    );
    // set the field for whenAdded date field
    setWhenAdded(formatDate(date));
    // loop through all current default options and update the array with any new values
    const newSelectedTreatmentOptions = groupedTreatmentsByDate.map(treat => {
      const selected = groupedTreatmentsByDate.find(
        foundTreat => foundTreat.treatmentTypeId === treat.treatmentTypeId
      );
      if (selected) {
        return {
          treatmentTypeId: selected.treatmentTypeId,
          serviceId,
          unitsAdded: selected.unitsAdded,
          isBillable: selected.isBillable,
        };
      }
      return treat;
    });
    setDefautTreatments(newSelectedTreatmentOptions);
    setSelectedTreatments(newSelectedTreatmentOptions);
  };

  const handleTreatmentModalOpen = async (date: string | Date) => {
    showTreatmentModal(true);
    setTreatmentsForDate(date);
  };

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          headerName: 'When Added',
          field: 'whenAdded',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<ITreatment>) => {
            const { row: original } = params;
            return <>{formatDate(original.whenAdded)}</>;
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
        {
          headerName: 'Units',
          field: 'unitsAdded',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
        {
          headerName: 'Billable',
          field: 'isBillable',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<ITreatment>) => {
            const { row: original } = params;
            return original.isBillable ? 'Yes' : 'No';
          },
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treatments, isModal, isEditable]
  );
  if (!isEditable && treatments.length === 0 && isCardLayout) {
    return null;
  }
  if (!isCardLayout) {
    return (
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: ITreatment) => row.treatmentId}
        rows={treatments}
        columns={columns}
        loading={isLoadingTreatments}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        onPageChange={page => setPage(page)}
        onPageSizeChange={num => {
          setRowsPerPage(num);
          localStorage.setItem(TREATMENT_GRID_KEY, String(num));
        }}
        hasMobileLayout
        mobileProps={{
          useFirstCell: true,
        }}
        noResultsMessage="No treatments."
      />
    );
  }
  return (
    <>
      <Card>
        <CardTitle
          withExpand={isServiceDetailPage || isCollapsible}
          overrideExpand={isExpanded}
          initialExpand={initialExpand}
          title="Treatments"
          mobileWrap
          action={
            !isModal && isEditable && !isLoadingAllTreatments ? (
              <Button
                onClick={() => {
                  handleTreatmentModalOpen(new Date());
                }}
                color="secondary"
                size="small"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                {treatments && treatments?.length > 0 ? 'Edit' : 'Add'} Treatments
              </Button>
            ) : undefined
          }
        >
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: ITreatment) => row.treatmentId}
            rows={treatments}
            columns={columns}
            loading={isLoadingTreatments}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={page => setPage(page)}
            onPageSizeChange={num => {
              setRowsPerPage(num);
              localStorage.setItem(TREATMENT_GRID_KEY, String(num));
            }}
            hasMobileLayout
            mobileProps={{
              useFirstCell: true,
            }}
            noResultsMessage="No treatments."
          />
        </CardTitle>
      </Card>
      <TreatmentModal
        open={isShowingTreatmentModal}
        onClose={() => {
          showTreatmentModal(false);
          setSelectedTreatments(initialTreatments);
        }}
        siteId={siteId}
        serviceId={serviceId}
        defaultTreatments={defaultTreatments}
        setWhenAdded={setWhenAdded}
        whenAdded={whenAdded}
        selectedTreatments={selectedTreatments}
        setSelectedTreatments={setSelectedTreatments}
        setInitialTreatments={setInitialTreatments}
        getTreatments={() => {
          refetchTreatments();
          fetchAllTreatments();
        }}
        siteName={siteName}
        setTreatmentsForDate={setTreatmentsForDate}
        isLoadingAllTreatments={isLoadingAllTreatments}
        hasTreatments={treatments && treatments?.length > 0}
      />
    </>
  );
};
