export enum Permissions {
  ViewAgingOnHomeScreen = 'View Aging on Home Screen',
  TransactionEditSameDay = 'Transaction Edit Same Day',
  TransactionEditPriorDay = 'Transaction Edit Prior Day',
  ChangeAccountCode = 'Change Account Code',
  EditAccountInfo = 'Edit Account Info',
  ViewCustomers = 'View Customers',
  ViewTransactions = 'View Transactions',
  ViewPoolPhotos = 'View Pool Photos',
  ViewRoutes = 'View Routes',
  ShowOnRoutesScreen = 'Show On Routes Screen',
  EditClosedBatch = 'Edit closed batch',
  ViewPayments = 'View Payments',
  ViewScheduledServices = 'View Scheduled Services',
  EditClosedService = 'Edit Closed Service',
  ViewBilling = 'View Billing',
  ViewEstimates = 'View Estimates',
  Inventory = 'Inventory',
  ViewReports = 'View Reports',
  ViewRepairs = 'View Repairs',
  EditViewCommissions = 'Edit/View Commissions',
  NewsManagement = 'News Management',
  DeleteBillingBatches = 'Delete Billing Batches',
  Settings = 'Settings',
  TechMangement = 'Tech Management',
}
