import { Form, Formik } from 'formik';
import { Loader, Modal, ModalSaveSection, TextField } from '../../../components';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Box, Fade } from '@mui/material';
import { createUserGroup, updateUserGroupName } from '../../../fetch';
import { IUserGroup } from '../../../models';
import { FC, useContext } from 'react';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { defaultUnsavedChangesMessage } from '../../../constants';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

interface IEditUserGroupModal {
  currentUserGroup: IUserGroup | null;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
}

export const EditUserGroupModal: FC<IEditUserGroupModal> = ({
  currentUserGroup,
  isOpen,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: currentUserGroup?.userGroupName ?? '',
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          currentUserGroup
            ? await updateUserGroupName(currentUserGroup?.userGroupId, values)
            : await createUserGroup({
                name: values.name,
                officeId: user?.officeId!,
              });
          enqueueSnackbar(`User Group saved!`, {
            variant: 'success',
          });
          onClose(true);
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? 'Error saving user group, please try again.', {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit, dirty, resetForm, isValid }) => {
        const handleClose = async () => {
          if (dirty) {
            const result = await confirm(defaultUnsavedChangesMessage);
            if (result) {
              onClose();
              return resetForm();
            }
            return;
          }
          onClose();
          resetForm();
        };
        return (
          <Modal
            open={isOpen}
            onClose={handleClose}
            maxWidth="xs"
            title={currentUserGroup ? 'Edit User Group' : 'Add User Group'}
          >
            <Fade in={isOpen}>
              <div>
                <Form onSubmit={handleSubmit}>
                  {isSubmitting && <Loader type="overlay" position="centered" />}
                  <Box mt="1rem">
                    <TextField label="Name" name="name" required />
                  </Box>
                  <ModalSaveSection
                    handleCancel={handleClose}
                    isSaveDisabled={!dirty || !isValid}
                  />
                </Form>
              </div>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};
