import { DependencyList, useEffect } from 'react';
import { useTimeoutFn } from './useTimeoutFn';

export type UseDebounceReturn = [() => boolean | null, () => void];

export const useDebounce = (
  fn: Function,
  milliseconds: number = 0,
  deps: DependencyList = []
): UseDebounceReturn => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, milliseconds);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reset, deps);

  return [isReady, cancel];
};
