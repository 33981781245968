import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../../components';

export const ConditionAndNotesDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the ConditionAndNotesForm component as well
  // REMINDER: When adding fields, also update the conditionNotesSectionFields array in pool-structure-card
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={-2} mb={1}>
      <DisplayColumn sm={12} md={6} lg={6}>
        <DisplayGroup label="General Condition" labelId="condition">
          {values?.condition ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn sm={12} md={6} lg={6}>
        <DisplayGroup label="Notes" labelId="notes">
          {values?.notes ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
