import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { DateRangeNavigator } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { ICalendarDateRange, ICalendarView } from '../../../models';
import { addDays, endOfDay, endOfWeek, startOfDay, startOfWeek } from 'date-fns';

export const StyleGuideDateRangeNavigator = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>({
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  });
  const [selectedSingleDateRange, setSelectedSingleDateRange] = useState<
    ICalendarDateRange | undefined
  >({
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  });

  return (
    <StyleGuidePage title="Date Range Navigator">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Date Range Navigator component is a custom build upon the{' '}
            <Link href="https://mui.com/x/react-date-pickers/date-picker/" target="_blank">
              MUI Date Picker
            </Link>{' '}
            and other various MUI components. It has a built-in styles and a calendar picker.
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be use on pages or views where you are viewing
            data for a specific date or date range while also having the ability to change the date
            via a calendar picker or page back and forth using icon buttons. An example would be the
            route detail page or the schedule calendar.
          </Typography>
          <code>
            Props:
            <br />- view: ICalendarView
            <br />- dateRange: ICalendarDateRange
            <br />- onChange: (dateRange: ICalendarDateRange) =&gt; unknown
            <br />- color?: string
            <br />- willHandleDateChange?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1} p={0.5}>
            <DateRangeNavigator
              view={ICalendarView.Week}
              dateRange={selectedDateRange!}
              onChange={(range: ICalendarDateRange) => {
                setSelectedDateRange({
                  startDate: startOfDay(range?.startDate),
                  endDate: endOfDay(addDays(range?.startDate, 6)),
                });
              }}
              willHandleDateChange
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;DateRangeNavigator
            <br />
            &nbsp;&nbsp;&nbsp;view=&#123;ICalendarView.Week&#125;
            <br />
            &nbsp;&nbsp;&nbsp;dateRange=&#123;selectedDateRange!&#125;
            <br />
            &nbsp;&nbsp;&nbsp;onChange=&#123;(range: ICalendarDateRange) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setSelectedDateRange(&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;startDate:
            startOfDay(range?.startDate),
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;endDate:
            endOfDay(addDays(range?.startDate, 6)),
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;willHandleDateChange
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1} p={0.5} sx={{ backgroundColor: theme => theme.palette.primary.main }}>
            <DateRangeNavigator
              color="white"
              view={ICalendarView.Day}
              dateRange={selectedSingleDateRange!}
              onChange={(range: ICalendarDateRange) => {
                setSelectedSingleDateRange(range);
              }}
              willHandleDateChange
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;DateRangeNavigator
            <br />
            &nbsp;&nbsp;&nbsp;color="white"
            <br />
            &nbsp;&nbsp;&nbsp;view=&#123;ICalendarView.Day&#125;
            <br />
            &nbsp;&nbsp;&nbsp;dateRange=&#123;selectedDateRange!&#125;
            <br />
            &nbsp;&nbsp;&nbsp;onChange=&#123;(range: ICalendarDateRange) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setSelectedDateRange(range);
            <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;willHandleDateChange
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
