import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getVersion } from '../fetch';
import { APP_VERSION, WEB_VERSION, getEnvironment } from '../helpers';

/**
 * On each route change call the version endpoint to see if a new version has been deployed
 * If a new version is deployed and it doesn't match the current stored item in local storage
 * Do a full refresh on route change to reload the static index file with the new version and then update
 * LocalStorage with the new version number
 */
export const VersionWrapper = ({ children }: any) => {
  const location = useLocation();
  const [isReloading, setIsReloading] = useState(false);

  useLayoutEffect(() => {
    const fetchVersion = async () => {
      try {
        const res = await getVersion();
        const WebVersion = localStorage.getItem(WEB_VERSION);
        let needsRefresh = false;

        // Always set the Application Version from the API that contains the new symantec version value
        localStorage.setItem(APP_VERSION, res.applicationVersion);

        //Web Version is the build number from Azure DevOps we will use to refresh UI code when we have a new build pushed out.
        if (!WebVersion) {
          needsRefresh = true;
          localStorage.setItem(WEB_VERSION, res.webVersion);
        }
        if (WebVersion && WebVersion !== res.webVersion) {
          needsRefresh = true;
          localStorage.setItem(WEB_VERSION, res.webVersion); // update localStorage so we don't get any inifite refresh loops
        }

        if (needsRefresh) {
          setIsReloading(true);
          window.location.reload(); // refresh the browser
        }
      } catch (error) {}
    };
    if (getEnvironment() !== 'localhost') {
      fetchVersion();
    }
  }, [location]);
  if (isReloading) {
    return null;
  }
  return children;
};
