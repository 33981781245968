import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC } from 'react';

interface ILabelProps {
  children?: any;
  className?: string;
  sx?: SxProps;
  id?: string;
  wrap?: boolean;
  labelFor?: string;
}

export const Label: FC<ILabelProps> = ({ children, className, sx, id, wrap = false, labelFor }) => {
  const classes = useStyles({ wrap });
  return (
    <Box
      id={id}
      component="label"
      className={clsx(classes.label, className)}
      marginRight="1rem"
      sx={sx}
      htmlFor={labelFor}
      data-testid="label"
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { wrap: boolean }>(theme => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    whiteSpace: ({ wrap }) => (wrap ? 'normal' : 'nowrap'),
    fontSize: 14,
  },
}));
