import { axios } from '../helpers';
import { IAdyenSessionRes } from '../models';

export const getAdyenPaymentSession = async (payload: {
  amount: number;
  accountId: string;
  repairId: string;
  reference: string;
}): Promise<IAdyenSessionRes> => {
  try {
    const { data } = await axios.post(`/api/adyen/payment-session`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAdyenRecurringPaymentSession = async (payload: {
  accountId: string;
  amount: number;
  reference: string;
  payBalance: boolean;
  showRemovePaymentMethodButton: boolean;
}): Promise<IAdyenSessionRes> => {
  try {
    const { data } = await axios.post(`/api/adyen/recurring-payment-session`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createAdyenPayment = async (payload: {
  amount: number;
  accountId: string;
  reference: string;
}): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/adyen/payment`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteAdyenSavedPaymentMethod = async (accountId: string, storedPaymentMethodId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/adyen/recurring-payment`, {
      data: {
        accountId,
        storedPaymentMethodId
      },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

