import { FC, useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  ListItemText,
  ListItemIcon,
  useTheme,
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
  lighten,
  Grid,
  useMediaQuery,
  styled,
} from '@mui/material';
import {
  faBroom,
  faFlask,
  faHammer,
  faTasks,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from '../link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../modal';
import { UserAvatar } from '../user-avatar';
import { CalendarEventTypes, ICalendarEvent, IEmployeeStatsDetail } from '../../models';
import {
  formatDate,
  getCurrentPathAndSearch,
  hyphenSeparateTwoInputs,
  truncate,
} from '../../helpers';
import { kebabCase } from 'lodash';

interface ICalendarViewDetailsModal {
  open: boolean;
  onClose: () => void;
  details?: IEmployeeStatsDetail;
}

export const CalendarViewDetailsModal: FC<ICalendarViewDetailsModal> = ({
  open,
  onClose,
  details,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: 767px)`);

  const { scheduledTasks, otsEvents, scheduledServices } = useMemo(() => {
    const scheduledTasks: ICalendarEvent[] = [];
    const scheduledServices: ICalendarEvent[] = [];
    const otsEvents: ICalendarEvent[] = [];
    details?.calendarItemUser.events?.forEach(event => {
      if (event.eventType === CalendarEventTypes.ScheduledService) {
        scheduledServices.push(event);
      }
      if (event.eventType === CalendarEventTypes.RepairVisit) {
        otsEvents.push(event);
      }
      if (event.eventType === CalendarEventTypes.ScheduledTask) {
        scheduledTasks.push(event);
      }
    });
    return {
      scheduledTasks,
      scheduledServices,
      otsEvents,
    };
  }, [details]);

  const encodedRedirectUrl = encodeURIComponent(getCurrentPathAndSearch());

  const serviceIcons = (service: ICalendarEvent) => {
    return (
      <Box className={classes.iconWrapper}>
        {service.showBeakerIcon && (
          <ListItemIcon className={classes.iconCircle} sx={{ minWidth: 25 }}>
            <FontAwesomeIcon
              style={{
                color: theme.palette.primary.main,
              }}
              icon={faFlask}
            />
          </ListItemIcon>
        )}
        {service.showBrushIcon && (
          <ListItemIcon className={classes.iconCircle} sx={{ minWidth: 25 }}>
            <FontAwesomeIcon
              style={{
                color: theme.palette.primary.main,
              }}
              icon={faBroom}
            />
          </ListItemIcon>
        )}
        {service.showWrenchIcon && (
          <ListItemIcon className={classes.iconCircle} sx={{ minWidth: 25 }}>
            <FontAwesomeIcon
              style={{
                color: theme.palette.primary.main,
              }}
              icon={faWrench}
            />
          </ListItemIcon>
        )}
      </Box>
    );
  };

  const otsIcons = () => {
    return (
      <Box className={classes.iconWrapper}>
        <ListItemIcon className={classes.iconCircle} sx={{ minWidth: 25 }}>
          <FontAwesomeIcon
            icon={faHammer}
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </ListItemIcon>
      </Box>
    );
  };

  const taskIcons = () => {
    return (
      <Box className={classes.iconWrapper}>
        <ListItemIcon className={classes.iconCircle} sx={{ minWidth: 25 }}>
          <FontAwesomeIcon
            icon={faTasks}
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </ListItemIcon>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="sm" noPaddingContent showCloseButton={false}>
      <StyledCard isMobile={isMobile}>
        <CardHeader
          data-testid="tech-info"
          sx={{ color: theme.palette.primary.main }}
          classes={{
            title: classes.cardTitle,
            subheader: classes.cardSubheader,
          }}
          avatar={
            <UserAvatar
              src={details?.userInformation?.avatarUrl || undefined}
              userName={details?.userInformation?.username}
              noMarginRight
              backgroundColor={details?.userInformation.backgroundColor}
            />
          }
          title={details?.userInformation?.username}
          subheader={
            details?.calendarItem.calendarDate ? formatDate(details.calendarItem.calendarDate) : ''
          }
          action={
            <IconButton onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ padding: '8px' }}>
          <Grid container flexWrap="nowrap">
            {!isMobile && (
              <Grid item>
                <List disablePadding>
                  {scheduledServices?.map(service => {
                    return (
                      <ListItem
                        key={`service_icons_${service.eventId}`}
                        className={classes.listItem}
                        sx={{ minHeight: '50px' }}
                      >
                        {serviceIcons(service)}
                      </ListItem>
                    );
                  })}
                  {otsEvents?.map(ots => {
                    return (
                      <ListItem
                        key={`ots_icons_${ots.eventId}`}
                        className={classes.listItem}
                        sx={{ minHeight: '50px' }}
                      >
                        {otsIcons()}
                      </ListItem>
                    );
                  })}
                  {scheduledTasks?.map(task => {
                    return (
                      <ListItem
                        key={`task_icons_${task.eventId}`}
                        className={classes.listItem}
                        sx={{ minHeight: '50px' }}
                      >
                        {taskIcons()}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            )}
            <Grid item>
              <List
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  height: '100%',
                }}
              >
                {scheduledServices?.map(service => {
                  return (
                    <ListItem key={`${service.eventId}`} className={classes.listItem}>
                      <Link
                        data-testid={`${kebabCase(service.eventType)}-link`}
                        to={`/services/maintenance/${service.eventId}?redirect=${encodedRedirectUrl}`}
                        type="primary"
                      >
                        <ListItemText
                          primary={truncate(
                            hyphenSeparateTwoInputs(service?.siteName, service?.description),
                            50
                          )}
                        />
                      </Link>
                      {isMobile && serviceIcons(service)}
                    </ListItem>
                  );
                })}
                {otsEvents?.map(ots => {
                  return (
                    <ListItem key={`${ots.eventId}`} className={classes.listItem}>
                      <Link
                        data-testid={`${kebabCase(ots.eventType)}-link`}
                        to={`/services/ots/${ots.eventId}?redirect=${encodedRedirectUrl}`}
                        type="primary"
                      >
                        <ListItemText
                          primary={
                            ots.siteName
                              ? `${ots.siteName} - ${ots?.description ?? 'OTS'}`
                              : `${ots?.description ?? 'OTS'}`
                          }
                        />
                      </Link>
                      {isMobile && otsIcons()}
                    </ListItem>
                  );
                })}
                {scheduledTasks?.map(task => {
                  return (
                    <ListItem key={`${task.eventId}`} className={classes.listItem}>
                      <Link
                        data-testid={`${kebabCase(task.eventType)}-link`}
                        to={`/scheduling/tasks/${task.eventId}?redirect=${encodedRedirectUrl}`}
                        type="primary"
                      >
                        <ListItemText
                          primary={
                            task.accountName || task.siteName
                              ? `${task.accountName || task.siteName} - ${task.description}`
                              : task.description
                          }
                        />
                      </Link>
                      {isMobile && taskIcons()}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </Modal>
  );
};

const PREFIX = 'CalendarViewDetailsModal';

const classes = {
  cardTitle: `${PREFIX}-cardTitle`,
  cardSubheader: `${PREFIX}-cardSubheader`,
  listItem: `${PREFIX}-listItem`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  iconCircle: `${PREFIX}-iconCircle`,
  truncateText: `${PREFIX}-truncateText`,
};

const StyledCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  [`& .${classes.cardTitle}`]: {
    fontSize: 18,
    fontWeight: 600,
  },

  [`& .${classes.cardSubheader}`]: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },

  [`& .${classes.listItem}`]: {
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'flex-start' : 'center',
    marginBottom: isMobile ? 1 : '0',
  },

  [`& .${classes.iconWrapper}`]: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  [`& .${classes.iconCircle}`]: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    borderRadius: 45,
    padding: 8,
    height: 35,
    width: 35,
    '& svg': {
      height: 18,
      width: 18,
    },
  },

  [`& .${classes.truncateText}`]: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
