import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Page } from '../../components';
import { IAccountDetail } from '../../models';
import { getAccount } from '../../fetch';
import { OnlinePaymentsPageDetails } from './online-payments-page-details';

interface IOnlinePaymentProps {
  isNewPayment?: boolean;
  isRecurring?: boolean;
}

export const OnlinePayments: FC<IOnlinePaymentProps> = ({
  isNewPayment = false,
  isRecurring = false,
}) => {
  const { accountId }: { accountId: string } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: isLoadingCustomer, data: currentCustomer } = useQuery<IAccountDetail, Error>(
    ['assignToUsers'],
    () => getAccount(accountId),
    {
      onError: (err: any) => {
        enqueueSnackbar(err?.Detail ?? 'Account not found, redirecting...', {
          variant: 'info',
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      },
    }
  );

  return (
    <Page
      title={isRecurring ? 'New Recurring Payment' : 'Online Payments'}
      breadcrumb={
        Boolean(currentCustomer?.accountId)
          ? {
            text:
              currentCustomer?.name ||
              `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
            title: 'Back to Customer Detail',
            link: `/customers/${accountId}`,
          }
          : undefined
      }
    >
      <OnlinePaymentsPageDetails
        isNewPayment={isNewPayment}
        isRecurring={isRecurring}
        isLoadingCustomer={isLoadingCustomer}
        currentCustomer={currentCustomer}
        accountId={accountId}
      />
    </Page>
  );
};
