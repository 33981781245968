import { FC, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography, useMediaQuery, Divider, Fade, Button } from '@mui/material';
import { CardTitle, Loader, Modal, Card, GridDataFetcher, useDataGrid } from '../../components';
import { IEstimate, ILeadDetail } from '../../models';
import { deleteEstimate, getEstimates, createInvoiceFromLeadEstimate } from '../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context';
import { useHistory } from 'react-router-dom';
import { useConfirm } from '../../hooks';
import { RelatedEstimatesDataGrid } from './related-estimates-data-grid';

export interface IRelatedEstimatesProps {
  leadId: string;
  isCreateDisabled: boolean;
  handleSave: () => void;
  leadDetail: ILeadDetail | null;
}

export const RelatedEstimatesCard: FC<IRelatedEstimatesProps> = ({
  leadId,
  isCreateDisabled,
  handleSave,
  leadDetail,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const confirm = useConfirm();

  const isSmMobile = useMediaQuery(`(max-width: 600px)`);

  const [isDeletingEstimate, setIsDeletingEstimate] = useState(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [activeEstimate, setActiveEstimate] = useState<IEstimate | null>(null);

  const dataFetcher: GridDataFetcher<IEstimate> = useCallback(
    async ({ sortColumn, sortDirection }) => {
      try {
        const res = await getEstimates({
          sortBy: sortColumn || 'whenCreated',
          sortDirection: sortDirection || 'desc',
          perPage: -1,
          officeId: user?.officeId,
          leadId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading lead estimates, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leadId]
  );

  const {
    rows,
    isLoading: isLoadingEstimates,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchLeadEstimates,
  } = useDataGrid<IEstimate>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'lead-related-estimates-grid',
    },
    dataFetcher,
  });

  const handleCreateInvoice = async (original: IEstimate) => {
    try {
      setIsCreatingInvoice(true);
      const newInvoiceId = await createInvoiceFromLeadEstimate(leadId, original.estimateId);
      history.push(`/billing/invoices/${newInvoiceId}`);
      enqueueSnackbar(`Invoice Created!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error creating invoice, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsCreatingInvoice(false);
    }
  };

  const handleEdit = (estimate: IEstimate) => {
    history.push(`/estimates/${estimate.estimateId}?leadId=${leadId}`);
  };

  const handleDelete = async (estimateId: string | number) => {
    try {
      const result = await confirm('Are you sure you want to delete this estimate?');
      if (result) {
        setIsDeletingEstimate(true);
        await deleteEstimate(estimateId);
        enqueueSnackbar(`Estimate Deleted!`, {
          variant: 'success',
        });
        refetchLeadEstimates();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting estimate, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingEstimate(false);
    }
  };

  return (
    <Box marginTop="1rem">
      <Card>
        {isDeletingEstimate ||
          (isLoadingEstimates && (
            <Loader
              position="centered"
              type="overlay"
              title={isDeletingEstimate ? 'Deleting...' : 'Loading...'}
            />
          ))}
        <CardTitle
          title="Related Estimates"
          action={
            <Button
              color="secondary"
              size="small"
              disabled={isCreateDisabled}
              onClick={() => {
                // save the form before we re-direct to create an estimate from a lead
                handleSave();
                history.push(`/estimates/new?leadId=${leadId}`);
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              {isSmMobile ? 'Estimate' : 'Create Estimate'}
            </Button>
          }
        />
        {!isLoadingEstimates && rows && rows?.length > 0 && (
          <Box marginTop="1rem">
            <RelatedEstimatesDataGrid
              loading={isLoadingEstimates}
              rows={rows}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              refetch={refetchLeadEstimates}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              leadDetail={leadDetail}
              leadId={leadId}
              setActiveEstimate={setActiveEstimate}
            />
          </Box>
        )}
        {!isLoadingEstimates && (rows?.length === 0 || !rows) && (
          <Typography>There are no estimates to display.</Typography>
        )}
      </Card>
      <Modal
        open={!!activeEstimate}
        onClose={() => {
          setActiveEstimate(null);
        }}
        maxWidth="sm"
      >
        <Fade in={!!activeEstimate}>
          <Box>
            {isCreatingInvoice && <Loader type="overlay" position="centered" title="Saving..." />}
            <Box mb={2}>
              <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                Create Invoice
              </Typography>
              <Divider />
            </Box>
            <Typography gutterBottom align="center">
              A customer is going to be created when you create an invoice from a lead. Confirm?
            </Typography>
            <Box
              margin="1rem 0 0"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Button
                type="button"
                color="inherit"
                onClick={() => {
                  setActiveEstimate(null);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => handleCreateInvoice(activeEstimate!)}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};
