import { FC, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { Stepper } from '../../components';
import { ILeadDetail } from '../../models';

interface ILeadDetailStatusStepperProps {
  lead: ILeadDetail | null;
  showActiveAsComplete?: boolean;
}

const stepTitles = ['Lead', 'Lead Assigned', 'Estimate Delivered', 'Customer Won'];

export const LeadDetailStatusStepper: FC<ILeadDetailStatusStepperProps> = ({
  lead,
  showActiveAsComplete = true,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [failedStep, setFailedStep] = useState<number | undefined>(undefined);
  const totalSteps = () => stepTitles.length;

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({ 0: true });

  const setActiveSteps = (allSteps: string[], leadStatus: string) => {
    const currentIndex = allSteps.indexOf(leadStatus);
    setActiveStep(currentIndex);

    if (currentIndex === totalSteps() - 1) {
      completeAllSteps();
    } else if (currentIndex > -1) {
      const previousSteps = allSteps.slice(0, currentIndex);
      previousSteps.forEach((step, index) => {
        const newCompleted = completed;
        newCompleted[index] = true;
        setCompleted(newCompleted);
      });

      if (showActiveAsComplete) {
        const newCompleted = completed;
        newCompleted[currentIndex] = true;
        setCompleted(newCompleted);
      }
    } else {
      setFailedStep(0);
    }
  };

  const completeAllSteps = () => {
    const newCompleted = completed;
    stepTitles.forEach((step, index) => {
      newCompleted[index] = true;
    });
    setCompleted(newCompleted);
  };

  useEffect(() => {
    if (lead) {
      setActiveSteps(stepTitles, lead.statusBarStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Box
      margin={{
        xs: theme.spacing(4, 2),
        sm: theme.spacing(2),
      }}
    >
      <Stepper
        steps={stepTitles}
        completed={completed}
        activeStep={activeStep}
        failedStep={failedStep}
        failedStatus={lead?.statusBarStatus}
      />
    </Box>
  );
};
