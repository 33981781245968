import { Box, Card, CardContent, Grid, Link, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { Loader } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideLoader = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const [showFullscreenLoader, setShowFullscreenLoader] = useState<boolean>(false);

  return (
    <StyleGuidePage title="Loader">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The loader component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-progress/#circular" target="_blank">
              MUI Circular Progress
            </Link>{' '}
            . It used for displaying a loading state for most scenarios that require one. An
            exception to that would be a loading state for a Select dropdown, which is just a
            conditional label with disable set to true.
          </Typography>
          <Typography mb={1}>
            Additionally, unlike other applications, the Loader component is rarely used as an
            initial loading state for a Page. Tables/grids have their own loading states built-in,
            which makes an overall page loader unnecessary.
          </Typography>
          <Typography mb={1}>
            There are several different possible layouts and uses for the Loader component, the
            following are the most commonly used:
            <ul className="my-3">
              <li>
                <strong>Submitting a Form</strong>: For this scenario, the typical type and position
                is <em>overlay</em> and <em>centered</em> with a custom title of <em>Saving...</em>,
                when appropriate.
              </li>
              <li className="mt-2">
                <strong>Deleting a Record</strong>: For this scenario, the typical type and position
                is <em>fullscreen</em> and <em>centered</em> with a custom title of{' '}
                <em>Deleting...</em>, when appropriate.
              </li>
              <li className="mt-2">
                <strong>Loading a Modal or Card Content</strong>: For this scenario, the typical
                type and position is <em>fullscreen</em> and <em>centered</em>.
              </li>
              <li className="mt-2">
                <strong>Downloading a file</strong>: For this scenario, the typical type and
                position is <em>fullscreen</em> and <em>centered</em> with a custom title of{' '}
                <em>Downloading...</em>, when appropriate.
              </li>
              <li className="mt-2">
                <strong>Loading a Section or Navigation Items</strong>: For this scenario, the
                typical type and position is <em>inline</em> and <em>centered</em>.
              </li>
            </ul>
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- CircularProgressProps
                <br />- loaderWrapperClassName?: string <br />- position?: LoaderPosition
                <br />- topOffset?: number
                <br />- size?: LoaderSize
                <br />- subtitle?: string
                <br />- title?: boolean
                <br />- type?: LoaderType
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                Types:
                <br />- LoaderPosition: 'centered' | 'left' | 'top-center'
                <br />- LoaderSize: 'small' | 'medium' | 'large' <br />- LoaderType: 'fullscreen' |
                'inline' | 'overlay'
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Card>
              <CardContent
                sx={{
                  textAlign: 'center',
                  backgroundColor: theme => theme.palette.grey[100],
                  minHeight: theme => theme.spacing(10),
                }}
              >
                <Loader />
              </CardContent>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Loader /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Card>
              <CardContent
                sx={{
                  textAlign: 'center',
                  backgroundColor: theme => theme.palette.grey[100],
                  minHeight: theme => theme.spacing(10),
                }}
              >
                <Loader type="inline" position="centered" />
              </CardContent>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Loader type="inline" position="centered" /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Card>
              <CardContent
                sx={{
                  textAlign: 'center',
                  backgroundColor: theme => theme.palette.grey[100],
                  minHeight: theme => theme.spacing(10),
                  position: 'relative',
                }}
              >
                <Loader type="overlay" position="centered" />
              </CardContent>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Loader type="overlay" position="centered" /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Card>
              <CardContent
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  minHeight: theme => theme.spacing(10),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {showFullscreenLoader && (
                  <Loader type="fullscreen" position="centered" title="Fullscreen Loader..." />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowFullscreenLoader(true);
                    setTimeout(() => {
                      setShowFullscreenLoader(false);
                    }, 10000);
                  }}
                >
                  Show Fullscreen Loader
                </Button>
              </CardContent>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Loader type="overlay" position="centered" title="Fullscreen Loader..." /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
