/** NOTE, if you touch this file, you might have to touch the sibling .360 file */
import { useContext, useEffect, FC } from 'react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../context';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';
import { finishPendingRequests } from '../../helpers/axios';
import { Loader } from '../loader';
import { convertStringToArray, hasCorrectUserPermissions } from '../../helpers';
import { ROLES } from '../../constants';

interface IPrivateRoute extends RouteProps {
  featureFlag?: string[] | string;
  legacyUrl?: string;
  permission?: string;
  reverseFlagCheck?: boolean; // When OFF redirect behavior needs to happen when flag is ON
  role?: string;
  redirect?: string;
}

export const PrivateRoute: FC<IPrivateRoute> = ({
  featureFlag,
  legacyUrl,
  permission,
  reverseFlagCheck,
  role,
  redirect,
  ...props
}) => {
  const { user, isFetching, permissions } = useContext(UserContext);
  const featureFlags = useFlags();
  const ldContext = useLDClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    // any pending requests, cancel them so we don't get any possible BE errors on the wrong pages
    return () => {
      finishPendingRequests('RouteChange');
    };
  }, [location.pathname]);

  useEffect(() => {
    let mounted = true;

    const waitForFF = async () => {
      const context = ldContext?.getContext();
      // need to check for logged in users context before we evaluate the current FF
      if (context && !context.anonymous) {
        const hasFFTurnedOff =
          !isFetching &&
          featureFlags &&
          featureFlag &&
          ((!reverseFlagCheck &&
            !convertStringToArray(featureFlag).every(item => featureFlags[item])) ||
            (reverseFlagCheck &&
              !!convertStringToArray(featureFlag).every(item => featureFlags[item])));
        // check the logged in users's permissions and see if they are valid
        const hasInCorrectPermissions =
          user?.userType === ROLES.Emulating
            ? false
            : !isFetching &&
              permissions &&
              permissions?.length > 0 &&
              permission &&
              !hasCorrectUserPermissions(permission, user!);

        if (hasFFTurnedOff && !legacyUrl) {
          // feature flag is turned off and there is no legacy url getting passed in, redirect to home page
          // need to check the user is loaded and we have featureFlags loaded
          enqueueSnackbar(`Redirecting...`, {
            variant: 'info',
          });
          history.push('/');
        }
        // feature flag is turned off and there is a legacyUrl passed in, redirect to legacy
        else if (hasFFTurnedOff && legacyUrl) {
          enqueueSnackbar(`Redirecting...`, {
            variant: 'info',
          });
          window.location.href = legacyUrl;
        } // logged in user doesn't have permissions to view route
        else if (hasInCorrectPermissions) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });
          history.push(`/`);
        } else if (user?.userType !== ROLES.Emulating && role && role !== user?.userType) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });
          history.push(redirect ?? `/`);
        }
      }
    };
    if (mounted) {
      waitForFF();
    }
    return () => {
      mounted = false;
    };
  }, [
    isFetching,
    featureFlag,
    legacyUrl,
    featureFlags,
    permissions,
    history,
    permission,
    enqueueSnackbar,
    ldContext,
    user,
    location.pathname,
    props.path,
    reverseFlagCheck,
    role,
    redirect,
  ]);
  if (isFetching) {
    return <Loader type="overlay" position="centered" />;
  }

  if (!user && !isFetching) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  }
  // ignore welcome page for users that log in via entra
  if (user && user?.mustChangePassword) {
    return <Redirect to="/welcome" />;
  }
  return (
    <Route
      {...props}
      render={location => {
        return user && !isFetching ? (
          props.children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
};
