import { FC, useContext, useMemo, useState } from 'react';
import { Box, Grid, Typography, Autocomplete, Chip, Avatar, TextField } from '@mui/material';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Modal, BingMap } from '../../components';
import { Pod } from './pod/Pod';
import { colorizeTechs } from './utils';
import { IRouteUpdateMode, IServiceRoute, IServiceChangeItem, ITechnician } from '../../models';
import { createDropResultChangeSet } from './draggableUtils';
import { formatDate } from '../../helpers';
import { UserContext } from '../../context';

interface IMapModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceRoute: IServiceRoute;
  onServicesChange?: (changes: IServiceChangeItem[]) => unknown;
  updatedRoutes: IServiceRoute[];
  changes: any;
  updateMode: IRouteUpdateMode;
  hasChanges: boolean;
  reset: () => void;
  currentRouteIndex: number | null;
}

export const MapModal: FC<IMapModalProps> = ({
  isOpen,
  onClose,
  serviceRoute,
  onServicesChange,
  changes,
  updateMode,
  hasChanges,
  reset,
  currentRouteIndex,
}) => {
  const { user, isOfficeAdmin } = useContext(UserContext);
  const classes = useStyles();

  const handleDelete = (tech: ITechnician) => {
    setSelectedTechs(prev => prev.filter(t => t.userId !== tech.userId));
  };

  const colorizedTechs = useMemo(() => {
    return colorizeTechs(serviceRoute.technicians);
  }, [serviceRoute.technicians]);

  const [selectedTechs, setSelectedTechs] = useState<ITechnician[]>([]);

  const onDragEnd = (dropResult: DropResult) => {
    if (!onServicesChange) {
      return;
    }
    const changeSet = createDropResultChangeSet({
      dropResult,
      serviceRoutes: serviceRoute
        ? [
            {
              ...serviceRoute,
              technicians: selectedTechs?.length ? selectedTechs : serviceRoute.technicians,
            },
          ]
        : [],
      selectedTechs,
    });
    if (!changeSet.length) {
      return;
    }
    onServicesChange(changeSet);
  };

  const techs = selectedTechs.length ? selectedTechs : colorizedTechs;

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
    >
      <Box mt={5}>
        <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
          <Typography component="h2" variant="h4" sx={{ flex: 1 }}>
            {formatDate(serviceRoute.serviceDate)}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div>
              <div>
                <Typography className={classes.header}>
                  {!isOfficeAdmin && user?.userName ? `${user?.userName}` : 'Filter by Technician'}
                </Typography>
              </div>
              <div className={classes.techFilters}>
                {isOfficeAdmin && (
                  <Autocomplete
                    id="technicians"
                    value={selectedTechs}
                    multiple
                    disableClearable
                    popupIcon={<></>}
                    className={classes.cardBody}
                    classes={{
                      paper: classes.paperBorder,
                    }}
                    onChange={(event, newValue: ITechnician[]) => {
                      if (newValue && newValue.length > 0) {
                        setSelectedTechs(newValue);
                      } else {
                        setSelectedTechs([]);
                      }
                    }}
                    options={colorizedTechs.filter(
                      t => !selectedTechs.map(t => t.userId).includes(t.userId)
                    )}
                    getOptionLabel={(option: ITechnician) => {
                      return option.userName;
                    }}
                    renderOption={(props, option: ITechnician) => {
                      return (
                        <li {...props} key={option.userId}>
                          {option.userName}
                        </li>
                      );
                    }}
                    renderTags={tags =>
                      tags.map(tag => (
                        <Chip
                          key={tag.userId}
                          avatar={
                            <Avatar
                              style={{ backgroundColor: tag.color, color: 'white' }}
                              alt={tag.userName}
                            >
                              {tag.userName.slice(0, 1)}
                            </Avatar>
                          }
                          label={tag.userName}
                          variant="outlined"
                          onDelete={() => {
                            handleDelete(tag);
                          }}
                          className={classes.chip}
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                          }}
                        />
                      ))
                    }
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          name="technicians"
                          autoComplete="none"
                          label={selectedTechs?.length ? '' : 'Select Technician'}
                          InputLabelProps={{ shrink: false }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={classes.podContainer}>
                <div className={classes.podCard}>
                  <Pod
                    updateMode={updateMode}
                    route={serviceRoute}
                    selectedTechs={techs}
                    hideEditButton
                    changes={changes}
                    allowMultiDrag={false}
                    routeIndex={currentRouteIndex as number}
                    showServiceIndex
                    serviceDate={serviceRoute.serviceDate}
                  />
                </div>
              </div>
            </DragDropContext>
          </Grid>
          {!!techs?.length && (
            <Grid item xs={12} md={6}>
              <BingMap techs={techs} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  resetButton: {
    marginRight: theme.spacing(1),
  },
  podContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'nowrap',
    maxHeight: '800px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  podCard: {
    flex: '1',
    minWidth: '250px',
  },
  techFilters: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '85%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '175px',
    },
  },
  chipRoot: {
    '& .MuiChip-deleteIcon': {
      fontSize: '30px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  },
  chipLabel: {
    paddingRight: theme.spacing(1),
  },
}));
