import { Skeleton, SkeletonProps } from '@mui/material';
import React, { FC } from 'react';

interface ISkeletonLoader {
  className?: string;
}

/**
 * @usage
 * ```TypeScript
 * const Component: FC = () => {
 *   const [isLoading, setIsLoading] = useState(true);
 *   const [resources, setResources] = useState<Resource[]>([]);
 *   useEffect(() => {
 *     fetchResource().then(x => setResource(x)).finally(() => setIsLoading(false));
 *   }, []);
 *   if (isLoading) return <SkeletonLoader />;
 *   return <div> ... </div>;
 * };
 * ```
 */
export const SkeletonLoader: FC<ISkeletonLoader & SkeletonProps> = ({
  className = '',
  ...props
}) => (
  <div className={className}>
    <Skeleton {...props} animation={props.animation || 'wave'} />
  </div>
);
