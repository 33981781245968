import { Loader, AccountAutocomplete, CreditLimitAlert } from '../../components';
import * as Yup from 'yup';
import { TextField, Grid, Button, Box, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { IAccountSimple } from '../../models';
import { createBatchTransaction, getAccountBalance } from '../../fetch';

interface IBatchTransactionFormData {
  checkNumber: string;
  transactionAmount: string;
}

const PaymentFormSchema = Yup.object().shape({
  checkNumber: Yup.string().required(),
  transactionAmount: Yup.number().required(),
});

interface IPostPaymentsPageDetailsFields {
  transactionBatchId: string;
  loadBatchTransactionTotal: () => void;
  refetchTransactions: () => void;
  isReadOnly?: boolean;
}

export const PostPaymentsPageDetailsFields: FC<IPostPaymentsPageDetailsFields> = ({
  transactionBatchId,
  loadBatchTransactionTotal,
  refetchTransactions,
  isReadOnly,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isPosting, setIsPosting] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [amountDue, setAmountDue] = useState<string>('0.00');

  const noRef = useRef(null);
  const accountAutoCompleteRef = useRef<HTMLInputElement | null>(null);
  const checkNumberRef = useRef(null);
  const transactionAmountRef = useRef(null);
  const submitButtonRef = useRef(null);
  const handleFocusNext = (currentRef: React.MutableRefObject<HTMLInputElement | null>) => {
    const nextRef = currentRef === noRef ? accountAutoCompleteRef :
      currentRef === accountAutoCompleteRef ? checkNumberRef :
        currentRef === checkNumberRef ? transactionAmountRef :
          currentRef === transactionAmountRef ? submitButtonRef : null;
    (nextRef as React.MutableRefObject<HTMLInputElement | null>)?.current?.focus();
  };

  const loadCustomerBalance = async (accountId: string) => {
    let amountDue = await getAccountBalance(accountId);
    setAmountDue(String(Math.abs(amountDue)));
  }

  const handleAmountFocus = (event: { target: { select: () => void; }; }) => {
    event.target.select();
  };

  const { handleSubmit, values, isValid, handleChange, resetForm } = useFormik({
    validationSchema: PaymentFormSchema,
    initialValues: {
      checkNumber: '',
      transactionAmount: amountDue,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: IBatchTransactionFormData) => {
      try {
        setIsPosting(true);
        await createBatchTransaction({
          transactionBatchId: transactionBatchId,
          accountId: selectedAccount!.accountId,
          checkNumber: values.checkNumber,
          amount: +values.transactionAmount,
        });
        loadBatchTransactionTotal();
        refetchTransactions();
        resetForm();
        setSelectedAccount(null);
        setAmountDue('0.00');
        enqueueSnackbar('Transaction was successfully posted', {
          variant: 'success',
        });
        (accountAutoCompleteRef.current as HTMLInputElement | null)?.focus();
      } catch (e) {
        enqueueSnackbar('An error occurred while creating this transaction', {
          variant: 'error',
        });
      } finally {
        setIsPosting(false);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      {isPosting && <Loader type="overlay" position="centered" title="Loading..." />}
      <Box width="100%" mt={2} mb={selectedAccount?.accountId ? 1 : 0}>
        {selectedAccount?.accountId && (
          <CreditLimitAlert
            accountId={selectedAccount?.accountId}
            shouldRefetch={!!selectedAccount?.accountId}
            isAlwaysShown
            showLoader
          />
        )}
      </Box>
      <Grid container spacing={2} mb={2} mt={selectedAccount?.accountId ? 1 : -2}>
        <Grid item xs={12} sm={4}>
          <AccountAutocomplete
            setSelectedAccount={async val => {
              setSelectedAccount(val);
              if (val?.accountId) {
                loadCustomerBalance(val.accountId);
              }
              handleFocusNext(accountAutoCompleteRef)
            }}
            isRequired={false}
            inputRef={accountAutoCompleteRef}
            selectedAccount={selectedAccount}
            isDisabled={isReadOnly}
            handleBlur={() => handleFocusNext(accountAutoCompleteRef)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="checkNumber"
            value={values.checkNumber}
            label="Check #"
            size="small"
            autoComplete="on"
            variant="outlined"
            onChange={handleChange}
            onBlur={() => handleFocusNext(checkNumberRef)}
            disabled={isReadOnly}
            inputRef={checkNumberRef}
            inputProps={{
              'data-testid': 'check-number-field'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              name="transactionAmount"
              value={values.transactionAmount}
              size="small"
              label="Amount"
              autoComplete="on"
              variant="outlined"
              onChange={handleChange}
              onBlur={() => handleFocusNext(transactionAmountRef)}
              disabled={isReadOnly}
              inputProps={{ step: '0.01', 'data-testid': 'amount-field' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              type="number"
              placeholder="0.00"
              inputRef={transactionAmountRef}
              onFocus={handleAmountFocus}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!selectedAccount || !isValid}
              ref={submitButtonRef}
              data-testid="post-button"
            >
              Post
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
