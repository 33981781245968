import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { IParameterInputProps } from '../../../../models';

export const CheckboxParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const { parameterName, promptText } = parameter;

  const handleChange = (_e: SyntheticEvent<Element, Event>, checked: boolean) => {
    onChange(checked.toString(), parameter);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox name={parameterName} 
        checked={value === 'true'} />}
        label={promptText}
        onChange={handleChange}
      />
    </FormGroup>
  );
};
