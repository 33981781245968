import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Alert,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, Fragment } from 'react';
import { faCircle, faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, DisplayGroup, ExternalLink, Card } from '../../../components';
import { IAccountDetail, IAdditionalInfoItem } from '../../../models';
import { formatDate, formatMoney, getAddressMapLink, truncate } from '../../../helpers';
import { AccountDetailsCol } from './account-details-col';
import { getStatementDeliveryOption, statementDeliveryOptions } from './CustomersDetailCRUD';

export interface IAccountDetailsDisplay {
  currentCustomer: IAccountDetail | null;
  isExpanded?: boolean;
}

export const AccountDetailsDisplay: FC<IAccountDetailsDisplay> = ({
  currentCustomer,
  isExpanded,
}) => {
  const theme = useTheme();
  const isXlScreenUp = useMediaQuery(theme.breakpoints.up('xl'));
  const isSmScreenDown = useMediaQuery(theme.breakpoints.down('sm'));
  const hasAddress =
    !!currentCustomer?.address?.street ||
    !!currentCustomer?.address?.city ||
    !!currentCustomer?.address?.state ||
    !!currentCustomer?.address?.postalCode;
  const customerTerminated =
    currentCustomer?.status !== 'Active' && !!currentCustomer?.terminationTypeId;

  // Generate formatted address markup for use in an address block
  // We want conditional tags, commas, and spaces based on values available
  // <>
  //    Street<br />
  //    City, State ZIP
  // </>
  const formatAddressBlock = (
    street?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null
  ) => {
    const formattedStreet = !!street ? (
      <>
        {street}
        <br />
      </>
    ) : (
      ''
    );
    const citySeparator = !!state || !!postalCode ? `, ` : '';
    const formattedCity = !!city ? `${city}${citySeparator}` : '';
    const formattedState = !!state ? `${state} ` : '';
    const formattedPostalCode = !!postalCode ? postalCode : '';

    return (
      <>
        {formattedStreet}
        {formattedCity}
        {formattedState}
        {formattedPostalCode}
      </>
    );
  };

  const formatAdditionalInfo = (item: IAdditionalInfoItem) => {
    const isDate = item?.description?.includes('Date') || item?.description?.includes('Birthday');
    const isCurrency = item?.description === 'Spending Limit';
    if (isDate) {
      return formatDate(item.value);
    }
    if (isCurrency) {
      return formatMoney(item.value);
    }
    return item.value;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card className="print--no-margin">
          <CardTitle title="General Information" mobileWrap withExpand overrideExpand={isExpanded}>
            {currentCustomer?.accountAlert &&
              currentCustomer?.accountAlert?.toLowerCase() !== 'none' && (
                <Box pb={1}>
                  <Alert severity="error">{currentCustomer.accountAlert}</Alert>
                </Box>
              )}
            <Grid container spacing={isXlScreenUp ? 2 : 1}>
              <AccountDetailsCol
                sm={6}
                md={12}
                xl={6}
                wrapperStyles={{
                  label: {
                    minWidth: customerTerminated && isSmScreenDown ? '9rem' : undefined,
                  },
                }}
              >
                <DisplayGroup isInline label="First Name" labelId="firstName">
                  {currentCustomer?.firstName ?? ''}
                </DisplayGroup>
                <DisplayGroup isInline label="Last Name" labelId="lastName">
                  {currentCustomer?.lastName ?? ''}
                </DisplayGroup>
                <DisplayGroup
                  isInline
                  printClasses="print--none"
                  label="Customer"
                  labelId="accountName"
                >
                  {currentCustomer?.name}
                </DisplayGroup>
                <DisplayGroup isInline label="Account Type" labelId="accountType">
                  {currentCustomer?.accountType ?? ''}
                </DisplayGroup>
                <DisplayGroup isInline label="Status" labelId="status" component="div">
                  <Typography
                    className="text-uppercase"
                    sx={
                      currentCustomer?.status === 'Active'
                        ? { color: theme => theme.palette.success.main }
                        : undefined
                    }
                  >
                    <strong>{currentCustomer?.status ?? ''}</strong>
                  </Typography>
                </DisplayGroup>
                <DisplayGroup isInline label="Account Code" labelId="accountCode">
                  {currentCustomer?.accountCode ?? ''}
                </DisplayGroup>
                <DisplayGroup isInline label="Class" labelId="class">
                  {currentCustomer?.class ?? ''}
                </DisplayGroup>
              </AccountDetailsCol>
              <AccountDetailsCol sm={6} md={12} xl={6}>
                <DisplayGroup
                  isInline
                  inlineLabelWidth={customerTerminated ? '9rem' : undefined}
                  label="Date Started"
                  labelId="dateStarted"
                >
                  {currentCustomer?.dateStarted ? formatDate(currentCustomer?.dateStarted) : ''}
                </DisplayGroup>
                {currentCustomer?.status === 'Active' && (
                  <>
                    <DisplayGroup isInline label="Startup Source" labelId="referralTypeId">
                      {currentCustomer?.referralTypeId ?? ''}
                    </DisplayGroup>
                    <DisplayGroup isInline label="Startup Info" labelId="referralInfo">
                      {currentCustomer?.referralInfo ?? ''}
                    </DisplayGroup>
                  </>
                )}
                {customerTerminated && (
                  <>
                    <DisplayGroup
                      isInline
                      inlineLabelWidth={customerTerminated ? '9rem' : undefined}
                      label="Date Terminated"
                      labelId="dateTerminated"
                    >
                      {currentCustomer?.dateTerminated
                        ? formatDate(currentCustomer?.dateTerminated)
                        : ''}
                    </DisplayGroup>
                    <DisplayGroup
                      isInline
                      label="Termination Reason"
                      labelId="terminationReason"
                      inlineLabelWidth={'8.9rem'}
                    >
                      {currentCustomer?.terminationTypeDescription ?? ''}
                    </DisplayGroup>
                    <DisplayGroup label="Termination Info" labelId="terminationReason">
                      {currentCustomer?.terminationInfo && (
                        <Typography component="p">
                          {truncate(currentCustomer.terminationInfo, 160)}
                        </Typography>
                      )}
                    </DisplayGroup>
                  </>
                )}
              </AccountDetailsCol>
            </Grid>
          </CardTitle>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="print--no-margin" sxProps={{ height: '100%' }}>
          <CardTitle title="Contact Information" mobileWrap withExpand overrideExpand={isExpanded}>
            <Grid container spacing={isXlScreenUp ? 2 : 0}>
              <AccountDetailsCol sm={6} md={12} xl={5}>
                <DisplayGroup label="Account Address" labelId="accountAddress" component="div">
                  {currentCustomer?.address && (
                    <>
                      {!!currentCustomer?.address.addressName && (
                        <Typography component="span">
                          {currentCustomer?.address?.addressName}
                        </Typography>
                      )}
                      <Typography component="address">
                        {formatAddressBlock(
                          currentCustomer?.address?.street,
                          currentCustomer?.address?.city,
                          currentCustomer?.address?.state,
                          currentCustomer?.address?.postalCode
                        )}
                      </Typography>
                      {hasAddress && (
                        <ExternalLink
                          type="secondary"
                          target="_blank"
                          to={getAddressMapLink(
                            currentCustomer?.address?.street,
                            currentCustomer?.address?.city,
                            currentCustomer?.address?.state,
                            currentCustomer?.address?.postalCode
                          )}
                          title="View Map on Bing Maps"
                          className="print--none"
                        >
                          <FontAwesomeIcon icon={faMap} size="sm" /> Map
                        </ExternalLink>
                      )}
                    </>
                  )}
                </DisplayGroup>
              </AccountDetailsCol>
              <AccountDetailsCol sm={6} md={12} xl={7}>
                {currentCustomer?.phoneNumbers?.map(phoneNumber => (
                  <Fragment key={phoneNumber.accountPhoneNumberId}>
                    <DisplayGroup
                      isInline
                      label={`${phoneNumber.phoneNumberType} Phone`}
                      labelId={phoneNumber.phoneNumberType.toLowerCase()}
                    >
                      {phoneNumber.phoneNumber}
                    </DisplayGroup>
                  </Fragment>
                ))}
                <DisplayGroup alignInlineItems="flex-start" isInline label="Email" labelId="email">
                  <List dense disablePadding>
                    {currentCustomer?.emails?.map((email, emailIndex) => (
                      <ListItem dense disablePadding key={emailIndex} alignItems="flex-start">
                        <ListItemIcon
                          sx={{
                            fontSize: 10,
                            color: theme => theme.palette.secondary.main,
                            minWidth: 0,
                            marginRight: theme => theme.spacing(1),
                            position: 'relative',
                            top: theme => theme.spacing(0.4),
                          }}
                        >
                          <FontAwesomeIcon icon={faCircle} size="xs" />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography component="span" sx={{ wordBreak: 'break-all' }}>
                            {email}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </DisplayGroup>
              </AccountDetailsCol>
            </Grid>
          </CardTitle>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="print--no-margin" sxProps={{ height: '100%' }}>
          <CardTitle title="Billing Information" mobileWrap withExpand overrideExpand={isExpanded}>
            <Grid container spacing={isXlScreenUp ? 2 : 0}>
              <AccountDetailsCol sm={6} md={12} xl={6}>
                <DisplayGroup
                  isInline
                  inlineLabelWidth={isSmScreenDown ? '11.5rem' : undefined}
                  label="Billing Group"
                  labelId="billingGroup"
                >
                  {currentCustomer?.billingGroup ?? ''}
                </DisplayGroup>
                <DisplayGroup
                  isInline
                  inlineLabelWidth={isSmScreenDown ? '11.5rem' : undefined}
                  label="Sales Tax"
                  labelId="salesTax"
                >
                  {currentCustomer?.salesTax ?? ''}
                  {' - '}
                  {currentCustomer?.salesTax ? `${currentCustomer?.salesTaxRate}%` : ''}
                </DisplayGroup>
              </AccountDetailsCol>
              <AccountDetailsCol sm={6} md={12} xl={6}>
                <DisplayGroup
                  isInline
                  inlineLabelWidth="11.5rem"
                  label="Statement Delivery"
                  labelId="statementDelivery"
                >
                  {
                    statementDeliveryOptions?.find(
                      option => option?.value === getStatementDeliveryOption(currentCustomer)
                    )?.label
                  }
                </DisplayGroup>
                <DisplayGroup
                  isInline
                  inlineLabelWidth="11.5rem"
                  label="Email when service closed"
                  labelId="emailWhenServiceClosed"
                >
                  {currentCustomer?.emailWhenServiceClosed ? 'Yes' : 'No'}
                </DisplayGroup>
              </AccountDetailsCol>
            </Grid>
          </CardTitle>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="print--no-margin">
          <CardTitle
            title="Additional Information"
            mobileWrap
            withExpand
            overrideExpand={isExpanded}
          >
            <Grid container spacing={isXlScreenUp ? 2 : 0}>
              {!!currentCustomer?.additionalInfo && (
                <AccountDetailsCol sm={6} md={12} xl={5}>
                  <DisplayGroup label="Additional Info" labelId="additionalInfo" component="div">
                    <List dense disablePadding>
                      {currentCustomer?.additionalInfo.map((info, infoIndex) => {
                        return (
                          <ListItem dense disablePadding key={infoIndex} alignItems="flex-start">
                            <ListItemIcon
                              sx={{
                                fontSize: 10,
                                color: theme => theme.palette.secondary.main,
                                minWidth: 0,
                                marginRight: theme => theme.spacing(1),
                                position: 'relative',
                                top: theme => theme.spacing(0.4),
                              }}
                            >
                              <FontAwesomeIcon icon={faCircle} size="xs" />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography component="span">{info.description}: </Typography>
                              <Typography component="span">{formatAdditionalInfo(info)}</Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </DisplayGroup>
                </AccountDetailsCol>
              )}
              <AccountDetailsCol
                sm={6}
                md={12}
                xl={!!currentCustomer?.additionalInfo ? 7 : 12}
                printClasses="print--none"
              >
                <DisplayGroup label="Comments" labelId="comments">
                  {currentCustomer?.comments?.split('\n').map((v, index) => {
                    return <p key={`${index}`}>{v && truncate(v, 160)}</p>;
                  })}
                </DisplayGroup>
              </AccountDetailsCol>
            </Grid>
          </CardTitle>
          {/* Print version to allow for proper, non-flexbox interfering, "avoid breaking inside" behavior */}
          <AccountDetailsCol sm={6} md={12} xl={6} printClasses="print--only col-print-12">
            <DisplayGroup label="Comments" labelId="comments" component="div">
              {currentCustomer?.comments?.split('\n').map((v, index) => {
                return <p key={`${index}`}>{v}</p>;
              })}
            </DisplayGroup>
          </AccountDetailsCol>
        </Card>
      </Grid>
    </Grid>
  );
};
