import { axios } from '../helpers';
import { IVersion } from '../models';
import { userSignal } from '../signals';
import qs from 'querystring';

export const getVersion = async (officeId?: string | null): Promise<IVersion> => {
  try {
    const { data } = await axios.get('/api/version', {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
