import { DisplayGroup, DisplayColumn, Loader } from '../../components';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { IListUser, IServiceDefinition, ISiteSimple, IScheduledServiceDetail } from '../../models';
import { formatDate, formatShortFriendlyDateWithTime, formatTime } from '../../helpers';
import { theme } from '../../styles';

interface IServiceDetailsCardDisplay {
  site?: ISiteSimple | null;
  serviceDefinitions?: IServiceDefinition[];
  isLoading: boolean;
  users?: IListUser[];
  schedule: IScheduledServiceDetail | null;
}

export const ServiceDetailsCardDisplay: FC<IServiceDetailsCardDisplay> = ({
  site,
  isLoading,
  serviceDefinitions,
  users,
  schedule,
}) => {
  const { values } = useFormikContext<any>();
  return (
    <>
      {isLoading && <Loader type="overlay" />}
      <Grid item container spacing={2} mt={0} mb={1}>
        <DisplayColumn sm={12} md={6} lg={6} wrapperStyles={{
          label: {
            minWidth: '5rem'
          },
        }}>
          <DisplayGroup label="Site" isInline>
            {schedule?.siteName}
          </DisplayGroup>
          <DisplayGroup label="Service" labelId="serviceDefId" isInline>
            {serviceDefinitions?.find(def => def.serviceDefId === values?.serviceDefId)
              ?.description ?? null}
          </DisplayGroup>
          <DisplayGroup label="Assign To" labelId="assignedTo" isInline>
            {users?.find(user => user.userId === values?.assignedTo)?.userName ?? null}
          </DisplayGroup>
          <DisplayGroup label="Status" labelId="status" isInline>
            {values.status}
          </DisplayGroup>
        </DisplayColumn>
        <DisplayColumn sm={12} md={6} lg={6} wrapperStyles={{
          label: {
            minWidth: '11rem'
          },
        }}>
          <DisplayGroup label="Scheduled Service Date" labelId="serviceDate" isInline>
            {formatDate(values.serviceDate)}
          </DisplayGroup>
          <DisplayGroup label="Earliest/Latest Arrival" labelId="earliestLatestArrival" isInline>
            {values.earliestArrival ? formatTime(values.earliestArrival) : ''}
            {values.earliestArrival && values.latestArrival ? ' - ' : ''}
            {values.latestArrival ? formatTime(values.latestArrival) : ''}
          </DisplayGroup>
          <DisplayGroup label="Estimated Duration" labelId="estimatedDuration" isInline>
            {values.estimatedDuration ? `${values.estimatedDuration}m` : ''}
          </DisplayGroup>
          <DisplayGroup label="Actual Start" labelId="startingTime" isInline>
            {values.startingTime ? formatShortFriendlyDateWithTime(values.startingTime) : ''}
          </DisplayGroup>
          {values.status === 'Closed' && (
            <DisplayGroup label="Actual Completion" labelId="completionNotes" isInline>
              {schedule?.whenCompleted
                ? formatShortFriendlyDateWithTime(schedule?.whenCompleted)
                : ''}
            </DisplayGroup>
          )}
        </DisplayColumn>
      </Grid>
      <Grid item container spacing={2} mt={0} mb={1}>
        <DisplayColumn sm={12} md={values.status === 'Closed' ? 6 : 12} lg={values.status === 'Closed' ? 6 : 12} wrapperStyles={{
          '@media (min-width: 900px)': {
            paddingRight: theme.spacing(2)
          }
        }}>
          <DisplayGroup label="Description" labelId="description">
            {values.description}
          </DisplayGroup>
        </DisplayColumn>
        {values.status === 'Closed' && (
          <>
            <DisplayColumn sm={12} md={6} lg={6}>
              <DisplayGroup label="Completion Notes" labelId="completionNotes">
                {values.completionNotes}
              </DisplayGroup>
            </DisplayColumn>
          </>
        )}
      </Grid>
    </>
  );
};
