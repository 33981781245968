import { getEnvironment } from './environment';

export const getCookie = (name: string) => {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
};

export const deleteCookie = (name: string, path?: string, domain?: string) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

export const setCookie = (cookieKey: string, cookieValue: string, expirationDays?: number) => {
  let expiryDate = '';
  const date = new Date();

  date.setTime(date.getTime() + (expirationDays ? expirationDays : 30 * 24 * 60 * 60 * 1000));

  expiryDate = `; expiryDate=" ${date.toUTCString()}`;

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
};

export const getEnvCookieKey = () => {
  if (getEnvironment() === 'prod') {
    return 'PCPAuth';
  }
  return 'PCPAuth_Dev';
};
