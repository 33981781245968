import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormLabel, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

interface IMultiImageUpload {
  handleFileChange: (val: any[], file?: File[]) => void;
  images: string[];
  disabled?: boolean;
  isRequired?: boolean;
  showSource?: boolean;
  handleUploadClick?: () => void;
  label?: string;
  buttonText?: string;
  photoId?: string;
  buttonSize?: 'medium' | 'small' | 'large';
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  isButtonFullWidth?: boolean;
}

export const MultiImageUpload: FC<IMultiImageUpload> = ({
  handleUploadClick,
  handleFileChange,
  images,
  disabled = false,
  isRequired = false,
  showSource = true,
  label,
  buttonText,
  photoId,
  buttonSize,
  buttonColor,
  isButtonFullWidth,
}) => {
  const classes = useStyles();

  const createBase64Image = async (file: Blob) => {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCapture = async (target: EventTarget & HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const files = [];
        const base64Images: Array<{
          title: string;
          urlPath: string;
          contents: string;
          isUploaded: boolean;
          whenTaken: Date | string;
          file: File;
        }> = [];
        // Get fileList Object, convert to an array and get the values before they disappear
        // https://stackoverflow.com/questions/25333488/why-isnt-the-filelist-object-an-array
        const allFiles: File[] = Array.from(target.files);
        const fileLength = target.files.length;
        for (let i = 0; i < fileLength; i++) {
          const file = allFiles[i];
          const base64 = (await createBase64Image(file)) as string;
          const newUrl = URL.createObjectURL(file);

          files.push(file);
          let data = {
            title: file.name,
            urlPath: newUrl,
            contents: base64.split(',')[1],
            isUploaded: false,
            whenTaken: '',
            file: file,
          };
          if (photoId) {
            // @ts-ignore
            data[photoId] = Math.floor(1000000 + Math.random() * 9000000).toString();
          } else {
            // @ts-ignore
            data.servicePhotoId = Math.floor(1000000 + Math.random() * 9000000).toString();
          }
          base64Images.push(data);
        }
        handleFileChange(base64Images, files);
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.buttonsContainer}>
        {!!label && (
          <FormLabel required={isRequired} className={classes.heading}>
            {label}
          </FormLabel>
        )}
        <input
          accept="image/*"
          disabled={disabled}
          className={classes.input}
          id="icon-button-file"
          type="file"
          capture="environment"
          multiple
          onChange={e => {
            handleCapture(e.target);
            // reset the input value so you can re-upload the same photo if you want to
            e.target.value = '';
          }}
        />
        <Box>
          <label htmlFor="icon-button-file">
            <Button
              onClick={handleUploadClick}
              disabled={disabled}
              startIcon={<FontAwesomeIcon icon={faCamera} size="lg" />}
              size={buttonSize ?? 'large'}
              variant={'contained'}
              component={'span'}
              color={buttonColor ?? 'primary'}
              fullWidth={isButtonFullWidth ?? false}
            >
              <span>{buttonText ?? 'Upload Image(s)'}</span>
            </Button>
          </label>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  heading: {
    display: 'block',
    marginBottom: theme.spacing(0.75),
  },
  buttonsContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  imgBox: {
    maxWidth: '100%',
    flexDirection: 'column',
  },
  img: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  removeButton: {
    color: theme.palette.error.main,
  },
  imageHelpText: {
    color: theme.palette.error.main,
  },
  button: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));
