import { FC, useMemo, useContext } from 'react';
import { Button, Tooltip, Box, Typography, useMediaQuery } from '@mui/material';
import {
  Link,
  ServerSideDataGrid,
  ServerSideDataGridProps,
  ExternalLink,
  PrimaryIcon,
  PrimaryIconButton,
} from '../../components';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatDate, formatTime, getLegacyUrl, hasCorrectUserPermissions } from '../../helpers';
import { IScheduledService, ServicePodType, ServiceTypeTab } from '../../models';
import { UserContext } from '../../context';
import { Permissions } from '../../constants';
import {
  faBroom,
  faFileInvoice,
  faFlask,
  faVial,
  faWaterLadder,
  faCamera,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ServicesActionButton } from './services-actions-button';
import { format } from 'date-fns';

interface ScheduledServicesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IScheduledService[];
  setConfirmService: (val: IScheduledService) => void;
  dateLinkModal?: boolean;
  redirect?: string;
  accountId?: string;
  isFieldReport?: boolean;
  setServiceOption: (val: {
    currentServiceId: string | null;
    optionType: ServicePodType | null;
  }) => void;
  selectedTab: ServiceTypeTab | undefined;
  isEditable?: boolean;
  isSitePage?: boolean;
  showNonClickableAttributes?: boolean;
}

export const ScheduledServicesDataGrid: FC<ScheduledServicesDataGridProps> = ({
  rows,
  setConfirmService,
  dateLinkModal,
  redirect,
  accountId,
  isFieldReport,
  setServiceOption,
  selectedTab,
  isEditable,
  isSitePage,
  showNonClickableAttributes = true,
  ...gridProps
}) => {
  const isMidMobile = useMediaQuery('(min-width: 960px) and (max-width: 1200px)');
  const { user } = useContext(UserContext);
  const { v2Customers } = useFlags();

  const columns = useMemo(() => {
    return [
      {
        field: 'serviceDate',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 100,
        renderCell: (params: GridValueGetterParams<IScheduledService>) => {
          const { row: original } = params;
          const isRecurringService = !!original?.recurringServiceId;

          if (
            original.status === 'Closed' &&
            !hasCorrectUserPermissions(Permissions.EditClosedService, user!)
          ) {
            return <>{original.serviceDate ? formatDate(original.serviceDate) : ''}</>;
          }
          return isRecurringService && dateLinkModal ? (
            <Button
              color="primary"
              sx={{ fontWeight: 600, padding: 0 }}
              variant="text"
              onClick={() => setConfirmService(original)}
            >
              {formatDate(original.serviceDate)}
            </Button>
          ) : (
            <Link
              to={`/services/maintenance/${original.scheduledServiceId}${
                redirect ? `?redirect=${redirect}` : ''
              }`}
            >
              <Tooltip
                title={
                  !!original.frequency
                    ? 'Edit single service in recurring schedule'
                    : 'Edit service appointment'
                }
                placement="bottom"
              >
                <span>{formatDate(original.serviceDate)}</span>
              </Tooltip>
            </Link>
          );
        },
      },
      !accountId && {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          const childComponent = (
            <Tooltip title={`View customer's site`} placement="bottom">
              <span>{original.siteName}</span>
            </Tooltip>
          );
          return v2Customers ? (
            <Link to={`/customers/${original.accountId}?activeTab=sites&siteId=${original.siteId}`}>
              {childComponent}
            </Link>
          ) : (
            <ExternalLink to={`${getLegacyUrl()}/Office/Site/View/${original.siteId}`}>
              {childComponent}
            </ExternalLink>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'assignedTo',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      isFieldReport && {
        field: 'whenStarted',
        headerName: 'Started',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          if (formatDate(original.serviceDate) === formatDate(original.whenStarted)) {
            return <span>{formatTime(original.whenStarted)}</span>;
          }
          return formatDate(original.whenStarted);
        },
      },
      isFieldReport && {
        field: 'whenCompleted',
        headerName: 'Completed',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          if (original.status === 'Closed') {
            if (formatDate(original.serviceDate) === formatDate(original.whenCompleted)) {
              return <span>{formatTime(original.whenCompleted)}</span>;
            }
            return formatDate(original.whenCompleted);
          }
          return '';
        },
      },
      (isFieldReport || (isSitePage && selectedTab === ServiceTypeTab.CLOSED)) &&
        !isMidMobile && {
          field: 'psi',
          headerName: 'Psi',
          filterable: false,
          disableColumnMenu: true,
          width: 40,
          renderCell: (params: GridRenderCellParams<IScheduledService>) => {
            const { row: original } = params;
            return <>{original.psi ?? '0'}</>;
          },
        },
      isFieldReport &&
        !isMidMobile && {
          field: 'cleanPsi',
          headerName: 'Clean Psi',
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          flex: 1,
        },
      !isFieldReport && {
        field: 'repeatLink',
        headerName: 'Repeat Link',
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return (
            original.frequency && (
              <Link
                to={`/services/maintenance/recurring/${original.recurringServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`}
              >
                <Tooltip title={`Edit recurring appointment schedule`} placement="bottom">
                  <span>{original.frequency}</span>
                </Tooltip>
              </Link>
            )
          );
        },
      },
      (isFieldReport || (isSitePage && selectedTab === ServiceTypeTab.CLOSED)) &&
        !isMidMobile && {
          field: 'completionNotes',
          headerName: 'Notes',
          flex: 1,
          sortable: true,
          disableColumnMenu: true,
          filterable: false,
        },
      {
        field: 'icons',
        headerName: 'Attributes',
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        width: isFieldReport ? 200 : 150,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          const {
            showBeakerIcon,
            showBrushIcon,
            showWaterTestIcon,
            showWrenchIcon,
            showChemicalUseIcon,
            showPoolEquipmentIcon,
            showServiceCompleteIcon,
            showServicePhotoIcon,
            scheduledServiceId,
          } = original;
          return (
            <Box display="flex">
              {showNonClickableAttributes && (
                <>
                  {showBeakerIcon && <PrimaryIcon icon={faFlask} />}
                  {showBrushIcon && <PrimaryIcon icon={faBroom} />}
                  {showWrenchIcon && <PrimaryIcon icon={faFileInvoice} />}
                </>
              )}
              {(isFieldReport || (isSitePage && selectedTab === ServiceTypeTab.CLOSED)) && (
                <Box>
                  {showPoolEquipmentIcon && (
                    <Tooltip title={`Site has valid pool equipment`} placement="bottom">
                      <span>
                        <PrimaryIconButton
                          icon={faWaterLadder}
                          onClick={() => {
                            setServiceOption({
                              currentServiceId: scheduledServiceId,
                              optionType: ServicePodType.POOL,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {showWaterTestIcon && (
                    <Tooltip title={`Water Test has been Performed`} placement="bottom">
                      <span>
                        <PrimaryIconButton
                          icon={faFlask}
                          onClick={() => {
                            setServiceOption({
                              currentServiceId: scheduledServiceId,
                              optionType: ServicePodType.ANALYSIS,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {showChemicalUseIcon && (
                    <Tooltip title={`Chemical Use has been Logged`} placement="bottom">
                      <span>
                        <PrimaryIconButton
                          icon={faVial}
                          onClick={() => {
                            setServiceOption({
                              currentServiceId: scheduledServiceId,
                              optionType: ServicePodType.RECOMMENDATIONS,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}

                  {showServicePhotoIcon && (
                    <Tooltip title={`Service Photo attached`} placement="bottom">
                      <span>
                        <PrimaryIconButton
                          icon={faCamera}
                          onClick={() => {
                            setServiceOption({
                              currentServiceId: scheduledServiceId,
                              optionType: ServicePodType.PHOTO,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {showServiceCompleteIcon && (
                    <Tooltip title={`Service has been Completed`} placement="bottom">
                      <span>
                        <PrimaryIconButton
                          icon={faCircleCheck}
                          onClick={() => {
                            setServiceOption({
                              currentServiceId: scheduledServiceId,
                              optionType: ServicePodType.DETAILS,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
          );
        },
      },
      selectedTab === ServiceTypeTab.OPEN &&
        !isFieldReport && {
          headerName: 'Status',
          field: 'status',
          disableColumnMenu: true,
          filterable: false,
          sortable: false,
          renderCell: (params: GridRenderCellParams<IScheduledService>) => {
            const { row: original } = params;
            return (
              <Typography
                sx={
                  original.status === 'Open'
                    ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {original.status}
              </Typography>
            );
          },
        },
      isEditable && {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <ServicesActionButton service={original} redirect={redirect} />;
        },
      },
    ].filter(Boolean);
  }, [
    isFieldReport,
    dateLinkModal,
    redirect,
    setConfirmService,
    accountId,
    v2Customers,
    setServiceOption,
    selectedTab,
    isEditable,
    isSitePage,
    showNonClickableAttributes,
    isMidMobile,
    user,
  ]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IScheduledService) => row.scheduledServiceId}
        rows={rows}
        columns={columns as any[]}
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (val: IScheduledService) =>
            `${format(new Date(val.serviceDate), 'L/d/yy')} ${val.serviceType}${
              // only show if have a site name and we aren't on the customer site page with the accountId passed in
              val.siteName && !accountId ? ` - ${val.siteName}` : ''
            }`,
          showHandleActions: true,
          truncateAccordionLabel: true,
        }}
        {...gridProps}
      />
    </>
  );
};
