import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { CardTitle, ServerSideDataGrid } from '../../components';
import { SearchContext, UserContext } from '../../context';
import { TSearchType } from '../../models';

interface ISearchResultsGrid {
  showPagination?: boolean;
  labelContext?: string;
  columns: any;
  rowId: string;
  gridResults?: any[];
  totalCount?: number | null;
  getApiRequest: (filters?: any) => Promise<any>;
  getFilters?: any;
  searchType: TSearchType;
  pageNumber?: number;
  pageSize?: number;
  isParentLoading?: boolean;
}

export const SearchResultsGrid: FC<ISearchResultsGrid> = ({
  showPagination = true,
  labelContext,
  columns,
  rowId,
  gridResults,
  totalCount,
  getApiRequest,
  getFilters,
  searchType = 'global',
  pageNumber = 0,
  pageSize = 10,
  isParentLoading,
}) => {
  const { searchValue, isLoadingSearchResults, page, perPage, setPage, setRowsPerPage } =
    useContext(SearchContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [results, setResults] = useState<any>(gridResults ?? []);

  const resultColumns = useMemo(
    () => columns.filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNumber, pageSize, results]
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getApiRequest({
        perPage: showPagination
          ? Number(perPage.find(item => item.type === searchType)?.perPage)
          : -1,
        page: Number(page.find(item => item.type === searchType)?.page) + 1,
        sortBy: 'Score',
        sortDirection: 'Desc',
        officeId: user?.officeId,
        search: searchValue,
        ...getFilters,
      });
      setResults(response.records);
    } catch (error: any) {
      enqueueSnackbar(
        error?.Detail ||
          `Error loading the ${
            labelContext ? labelContext?.toLowerCase() + ' ' : 'search '
          }results, please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    setResults(gridResults);
    setIsPageLoaded(true);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridResults]);

  return (
    <>
      <CardTitle
        cardTitleWrapperClassName="print--only"
        title={labelContext ? `${labelContext} Search Results` : 'Search Results'}
        marginBottom={0}
      />
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: any) => row[rowId]}
        rows={results}
        columns={resultColumns ?? []}
        loading={isLoadingSearchResults || isLoading || isParentLoading}
        rowCount={totalCount ?? 0}
        page={pageNumber}
        pageSize={pageSize}
        onPageChange={pageNum => {
          const copy = JSON.parse(JSON.stringify(page));
          const selectedIndex = page.findIndex(p => p.type === searchType);
          copy[selectedIndex] = { ...copy[selectedIndex], page: pageNum };
          setPage(copy);
        }}
        onPageSizeChange={pageSize => {
          const copy = JSON.parse(JSON.stringify(perPage));
          const selectedIndex = perPage.findIndex(p => p.type === searchType);
          copy[selectedIndex] = { ...copy[selectedIndex], perPage: pageSize };
          setRowsPerPage(copy);
        }}
        hasMobileLayout
        mobileProps={{
          useFirstCell: true,
        }}
        noResultsMessage={`There are no ${
          labelContext ? `${labelContext?.toLowerCase()}` : ''
        } search results to display.`}
      />
    </>
  );
};
