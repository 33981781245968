import { useParams } from 'react-router-dom';
import { Page } from '../../components';
import { Box } from '@mui/material';
import { LeadDetailForm } from './lead-detail-form';
import { LeadDetailNotes } from './lead-detail-notes';

export const LeadDetailPage = () => {
  const { leadId }: { leadId: string } = useParams();
  const isNewLead = leadId === 'new';
  return (
    <Page
      title={isNewLead ? 'New Lead' : 'Edit Lead'}
      breadcrumb={{
        text: 'Leads',
        title: 'Back to Leads',
        link: `/leads`,
      }}
    >
      <LeadDetailForm leadId={leadId} isNewLead={isNewLead} />
      {!isNewLead && (
        <Box mt={2}>
          <LeadDetailNotes />
        </Box>
      )}
    </Page>
  );
};
