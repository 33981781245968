import { GridColDef } from '@mui/x-data-grid';

export const recommendationColumns: GridColDef[] = [
  {
    field: 'recommendedTreatment',
    headerName: 'Recommended Treatment',
    flex: 1,
  },
  {
    field: 'treatmentFor',
    headerName: 'For',
    flex: 0.3,
  },
];
