import { Stack, Box, RadioGroup, Radio, FormControlLabel, Typography } from '@mui/material';
import { Card, TextField, CreditCardInputs, Tabs, ITab, AdyenDropIn } from '../../components';
import { IAgreementInitialValues } from './contract-page';
import { FC, useEffect, useState } from 'react';
import { getEstimateSavedCreditCards, getRepairSavedCreditCards } from '../../fetch';
import { IAgreementData, IAgreementStatus, ISavedCreditCard } from '../../models';
import { formatMoney } from '../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IPaymentMethod {
  values: IAgreementInitialValues;
  setFieldValue: (key: string, value: any) => void;
  handleBlur: any;
  repairId?: string;
  estimateId?: string;
  token: string;
  validateForm: (val?: any) => void;
  estimateAgreementStatus: IAgreementStatus | undefined;
  agreementData: IAgreementData;
  guid: string;
  shouldCallAdyenSession: boolean;
  showAdyenPaymentSection: boolean;
  afterAdyenPaymentComplete: () => void;
  creditCardRequired: boolean;
  setInvalidCard: (val: boolean) => void;
}

export const PaymentMethod: FC<IPaymentMethod> = ({
  values,
  setFieldValue,
  handleBlur,
  repairId,
  token,
  estimateId,
  validateForm,
  estimateAgreementStatus,
  agreementData,
  guid,
  shouldCallAdyenSession,
  showAdyenPaymentSection,
  afterAdyenPaymentComplete,
  creditCardRequired,
  setInvalidCard,
}) => {
  const [savedCreditCards, setSavedCreditCards] = useState<ISavedCreditCard[] | null>(null);
  const [isLoadingSavedCards, setIsLoadingSavedCards] = useState(false);
  const { adyen } = useFlags();

  const fetchSavedCards = async () => {
    try {
      setIsLoadingSavedCards(true);
      const res = !!repairId
        ? await getRepairSavedCreditCards(repairId, token)
        : await getEstimateSavedCreditCards(estimateId as string, token);
      setSavedCreditCards(res);
    } catch (error) {
    } finally {
      setIsLoadingSavedCards(false);
    }
  };

  const getTotalPlusTax = () => {
    if (agreementData?.items) {
      const total = agreementData.items.map(item => item.amount || 0).reduce((a, b) => a + b, 0);
      return total;
    }
    return 0;
  };

  useEffect(() => {
    fetchSavedCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    ((adyen && showAdyenPaymentSection) || !adyen) && (
      <Card
        cardTitleProps={{
          title: estimateAgreementStatus?.isSecurityDepositRequired
            ? `Deposit Required - ${formatMoney(estimateAgreementStatus?.securityDepositAmount)}`
            : `Payment Method`,
          marginBottom: 0,
        }}
      >
        <Box mb={theme => theme.spacing(3)}>
          <Tabs
            id="payment-method-tabs"
            selectedTab={values.selectedTab}
            setSelectedTab={async val => {
              setFieldValue('selectedTab', val);
              setFieldValue('recurringPaymentId', '');
              validateForm({
                ...values,
                selectedTab: val,
                recurringPaymentId: '',
              });
            }}
            tabs={
              [
                {
                  key: 'new-card',
                  title: adyen
                    ? `Total: ${
                        estimateAgreementStatus?.isSecurityDepositRequired
                          ? formatMoney(estimateAgreementStatus?.securityDepositAmount)
                          : formatMoney(getTotalPlusTax())
                      }`
                    : 'Add New Card',
                },
                savedCreditCards &&
                  savedCreditCards?.length > 0 &&
                  !adyen && {
                    key: 'saved-card',
                    title: 'Saved Cards',
                  },
              ].filter(Boolean) as ITab[]
            }
          />
        </Box>

        {values.selectedTab === 'new-card' && (
          <Stack gap={2}>
            <TextField
              name="name"
              label="Full Name on Card"
              required={values.selectedTab === 'new-card'}
            />
            <TextField label="Street" name="street" required />
            <TextField label="City" name="city" required />
            <TextField label="State" name="state" required />
            <TextField label="Postal Code" name="zipCode" required />
            {adyen ? (
              <AdyenDropIn
                amount={
                  estimateAgreementStatus?.isSecurityDepositRequired
                    ? estimateAgreementStatus?.securityDepositAmount
                    : getTotalPlusTax()
                }
                accountId={agreementData?.accountId}
                shouldCallAdyenSession={shouldCallAdyenSession}
                reference={guid}
                afterPaymentComplete={afterAdyenPaymentComplete}
                createAdyenTransRecord={false}
                repairId={repairId}
              />
            ) : (
              <CreditCardInputs
                numberValue={values.cardNumber}
                cvvValue={values.cvv}
                required={values.selectedTab === 'new-card' && creditCardRequired}
                handleCreditCardNumberChange={val => {
                  setFieldValue('cardNumber', val);
                  if (!val) {
                    setFieldValue('cvv', '');
                  }
                }}
                handleCvvChange={val => setFieldValue('cvv', val)}
                handleBlur={handleBlur}
                setInvalidCard={setInvalidCard}
              />
            )}
          </Stack>
        )}
        {values.selectedTab === 'saved-card' && (
          <>
            {!isLoadingSavedCards && savedCreditCards && savedCreditCards?.length > 0 && (
              <RadioGroup
                name="saved-cards"
                value={values.recurringPaymentId}
                onChange={e => {
                  setFieldValue('recurringPaymentId', e.target.value);
                }}
              >
                {savedCreditCards?.map((card, index) => (
                  <Box mb={theme => theme.spacing(1)} key={`${index}`}>
                    <FormControlLabel
                      value={card.recurringPaymentId}
                      control={<Radio />}
                      label={
                        <Box>
                          <Typography>XXXX-XXXX-XXXX-{card.paymentLastFour}</Typography>
                        </Box>
                      }
                    />
                  </Box>
                ))}
              </RadioGroup>
            )}
          </>
        )}
      </Card>
    )
  );
};
