import { axios } from '../helpers';
import qs from 'querystring';
import {
  ICreateOneTimeService,
  IOneTimeServiceDetail,
  IUpdateOneTimeService,
  IOneTimeService,
  IResponse,
} from '../models';
import { userSignal } from '../signals';

export const getOneTimeServices = async (filters?: {
  officeId?: string;
  accountId?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IOneTimeService>> => {
  try {
    const { data } = await axios.get(`/api/repairs`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOneTimeService = async (
  id: string | number,
  officeId?: string | null
): Promise<IOneTimeServiceDetail | any> => {
  try {
    const { data } = await axios.get(`/api/repairs/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOneTimeService = async (
  id: number | string,
  payload: IUpdateOneTimeService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`/api/repairs/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createOneTimeService = async (
  payload: ICreateOneTimeService,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/repairs`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const closeOneTimeService = async (
  id: number | string,
  missingInvoiceConfirmed: boolean,
  missingPaymentConfirmed: boolean,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(
      `/api/repairs/${id}/close?missingInvoiceConfirmed=${missingInvoiceConfirmed}&missingPaymentConfirmed=${missingPaymentConfirmed}`,
      {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
