import { FC, useState } from 'react';
import { Loader, CreditLimitAlert } from '../../components';
import { PaymentInfo } from './payment-info';
import { RecurringPayments } from './recurring-payments';
import { IAccountDetail } from '../../models';

interface IOnlinePaymentsPageDetailProps {
  isNewPayment?: boolean;
  isRecurring?: boolean;
  isLoadingCustomer: boolean;
  currentCustomer: IAccountDetail | undefined;
  accountId: string;
}

export const OnlinePaymentsPageDetails: FC<IOnlinePaymentsPageDetailProps> = ({
  isNewPayment,
  isRecurring,
  isLoadingCustomer,
  currentCustomer,
  accountId,
}) => {
  const [shouldRefetchAgingReport, setShouldRefetchAgingReport] = useState<boolean>();
  return (
    <>
      {isLoadingCustomer && <Loader position="centered" type="inline" />}
      {!isLoadingCustomer && currentCustomer && (
        <>
          <CreditLimitAlert
            accountId={currentCustomer?.accountId}
            isAlwaysShown
            shouldRefetch={shouldRefetchAgingReport}
            containerShadow
            sx={{ mb: 2 }}
          />
          <PaymentInfo currentCustomer={currentCustomer} />
          {!isNewPayment && !isRecurring && (
            <RecurringPayments
              accountId={accountId}
              currentCustomer={currentCustomer}
              setShouldRefetchAgingReport={setShouldRefetchAgingReport}
            />
          )}
        </>
      )}
    </>
  );
};
