import { FC } from 'react';
import { DateTimePicker } from '../../../inputs';
import { IFilterInputProps } from '../../../../models';
import { isValidDate } from '../../../../helpers';

export const DateTimeFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;
  const [value = ''] = values;

  const handleChange = (date: Date | null) => {
    if (isValidDate(date)) {
      const value = date ? date.toISOString() : '';
      onChange([value], filter);
    }
  };

  return (
    <DateTimePicker
      label={label}
      onChange={handleChange}
      name={name}
      value={value ? new Date(value) : null}
    />
  );
};
