import * as Yup from 'yup';
import { stringNumNull } from '../helpers/util';

export const InvoicelineItemInitialState = {
  tranCodeId: '',
  details: '',
  lookupCode: '',
  serialNumber: '',
  rate: '',
  quantity: '',
  storeInventoryId: '',
  isSerialized: false,
};

export const EstimatelineItemInitialState = {
  tranCodeId: '',
  tranCodeDescription: '',
  lookupCode: '',
  details: '',
  serialNumber: '',
  rate: '',
  quantity: '',
  storeInventoryId: '',
  isSerialized: false,
};

export const LINE_ITEMS_FORM_VALIDATION = Yup.object().shape({
  sortOrder: Yup.number()
    .typeError('Must be a number')
    .required('Required')
    .test('Is positive?', "Sort Order number must be greater than '0'", (value: any) => value > 0),
  tranCodeId: Yup.string().required('Required'),
  details: Yup.string(),
  rate: Yup.string().required('Required'),
  quantity: Yup.string().required('Required'),
});

export interface IFormLineItem {
  sortOrder: string | number;
  tranCodeId: string;
  details: string;
  serialNumber: string;
  rate: string | number;
  lookupCode: string;
  quantity: string | number;
  storeInventoryId?: stringNumNull;
  externalPartId?: stringNumNull;
  isSerialized: boolean | undefined;
  poolCommerceInventoryId?: string | null;
}
