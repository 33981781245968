import { FC, useContext } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Redirect, useLocation } from 'react-router-dom';
import { Checkbox, FormControlLabel, Typography, Button, Box, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Formik, FormikHelpers, FormikProps, Form } from 'formik';
import {
  FullPageImageLayout,
  PublicPageTitle,
  Link,
  Loader,
  ExternalLink,
  AuthInput,
} from '../../components';
import { UserContext } from '../../context';
import { login } from '../../fetch';
import { getLegacyUrl, REMEMBER_ME } from '../../helpers';
import { BrandingContext } from '../../context/branding-context';
import { Paths, ROLES } from '../../constants';

interface ILoginDetailsProps {}

interface ILoginDetailsValues {
  email: string;
  password: string;
  remember: boolean;
}
const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Required'),
  password: Yup.string().required('Required'),
  remember: Yup.boolean(),
});
export const LoginDetails: FC<ILoginDetailsProps> = () => {
  const { v2Login } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isFetching, setUser, isSuperAdmin } = useContext(UserContext);
  const { appLongName } = useContext(BrandingContext);
  const location = useLocation();
  const locationState: any = location.state;
  const emailInput = document.getElementById('email');
  const legacyUrl = getLegacyUrl?.();
  // no flag redirect to legacy
  if (!v2Login) {
    window.location.href = `${legacyUrl}/Admin/Office/List`;
    return null;
  }

  // have flag and checking state of user
  if (isFetching) {
    return <Loader type="overlay" position="centered" />;
  }
  // have flag and we have a user and they are loaded redirect to correct page
  if (user && !isFetching) {
    if (user?.mustChangePassword && !user.isEntraId) {
      return <Redirect to="/welcome" />;
    }
    if (isSuperAdmin) {
      return <Redirect to={Paths.storeManagement.url} />;
    }
    return <Redirect to={locationState?.from ?? '/'} />;
  }

  return (
    <Formik
      initialValues={{
        email: localStorage.getItem(REMEMBER_ME) ?? '',
        password: '',
        remember: !!localStorage.getItem(REMEMBER_ME) ? true : false,
      }}
      onSubmit={async (
        values: ILoginDetailsValues,
        actions: FormikHelpers<ILoginDetailsValues>
      ) => {
        try {
          const res: any = await login({
            loginName: values.email,
            password: values.password,
          });
          // legacy admin user login, then redirect legacy /admin/office/list
          if (!res?.userType && res?.loginType === ROLES.Administrator) {
            window.location.href = `${legacyUrl}/Admin/Office/List`;
            return;
          }
          if (!res || res.Detail) {
            enqueueSnackbar(res.Detail || 'Error logging in, please try again', {
              variant: 'error',
            });
          } else if (res && res.loginName) {
            if (values.remember) {
              localStorage.setItem(REMEMBER_ME, values.email);
            } else {
              localStorage.removeItem(REMEMBER_ME);
            }
            const emulatingData = !!localStorage.getItem('emulatingData')
              ? JSON.parse(localStorage.getItem('emulatingData')!)
              : null;
            if (res.userId === emulatingData?.userId) {
              res.loginType = ROLES.Emulating;
              res.officeId = emulatingData?.officeId;
              res.officeCode = emulatingData?.code;
              res.officeName = emulatingData?.officeName;
            }
            setUser(res);
          } else {
            throw Error();
          }
        } catch (error) {
          enqueueSnackbar('Error logging in, please try again', {
            variant: 'error',
          });
          actions.resetForm();
          if (emailInput) {
            emailInput.focus();
          }
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={LoginSchema}
    >
      {({
        values,
        isValid,
        isSubmitting,
        dirty,
        handleSubmit,
        touched,
        errors,
        setFieldValue,
        handleBlur,
      }: FormikProps<ILoginDetailsValues>) => {
        return (
          <FullPageImageLayout>
            <Form data-testid="login-form" onSubmit={handleSubmit}>
              <PublicPageTitle
                title={`Login to ${appLongName}`}
                subTitle="Please fill out the fields below to enter the dashboard."
              />
              <Stack
                gap={3}
                mt={{
                  xs: 2,
                  sm: 4,
                }}
              >
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  shrink
                  label="User Name"
                  inputKey="email"
                  onChange={e => {
                    setFieldValue('email', e.target.value);
                  }}
                  onBlur={handleBlur}
                  id="login"
                />
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  shrink
                  label="Password"
                  inputKey="password"
                  onChange={e => {
                    setFieldValue('password', e.target.value);
                  }}
                  id="login"
                  onBlur={handleBlur}
                  type="password"
                />
              </Stack>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="space-between"
                mt={4}
              >
                <FormControlLabel
                  control={<Checkbox checked={values.remember} id="remember-me-checkbox" />}
                  disabled={!values.email}
                  label="Remember Me"
                  onChange={(_, checked) => {
                    setFieldValue('remember', checked);
                  }}
                />
                <Link to={`/forgot-password`} hasTextDecoration={false} type="secondary">
                  <strong>Forgot Password?</strong>
                </Link>
              </Box>
              <Box
                display="flex"
                gap={2}
                mt={2}
                mb={2}
                sx={{
                  color: theme => theme.palette.grey[600],
                }}
              >
                <WarningAmberIcon />
                <Typography variant="body1">
                  Note: Your password is case-sensitive. Make sure caps-lock is off. Unauthorized
                  access is prohibited.
                </Typography>
              </Box>
              <Button
                // @ts-ignore
                color="tertiary"
                disabled={!dirty || isSubmitting || !isValid}
                fullWidth
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  padding: theme => theme.spacing(1.5),
                  borderRadius: '30px',
                }}
                type="submit"
                onKeyDown={(e: { key: any }) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                {!isSubmitting ? 'Login' : 'Logging in...'}
              </Button>
              <Typography mt={2} variant="body1" fontWeight="bold">
                <Typography component="span" color="primary" fontWeight="bold">
                  New users:
                </Typography>{' '}
                Please retrieve your username and password from your email account.
              </Typography>
            </Form>
            <Box display={'flex'} justifyContent={'center'} mt={2}>
              <ExternalLink to={`${getLegacyUrl()}/mobile/login`}>Mobile Login</ExternalLink>
            </Box>
          </FullPageImageLayout>
        );
      }}
    </Formik>
  );
};
