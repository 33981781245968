import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { isNil } from 'lodash';
import { formatDate } from '../../helpers';
import { IAnalysis } from '../../models';
import { Link } from '../link';
import { Tooltip } from '@mui/material';

export const analysisColumns: GridColDef[] = [
  {
    field: 'createDate',
    headerName: 'Date',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<IAnalysis>) => {
      const { row: analysis } = params;
      return analysis.createDate ? formatDate(analysis.createDate) : '';
    },
  },
  {
    headerName: 'Service Type',
    field: 'serviceType',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 2,
    minWidth: 200,
    type: 'string',
    renderCell: (params: GridRenderCellParams<IAnalysis>) => {
      const { row: history } = params;
      if (history.scheduledServiceId) {
        return (
          <Link to={`/services/maintenance/${history.scheduledServiceId}`}>
            <Tooltip placement="bottom" title="Edit Service">
              <span>{history.serviceType}</span>
            </Tooltip>
          </Link>
        );
      }
      return history.serviceType;
    },
  },
  {
    field: 'freeChlorineBromine',
    headerName: 'F/T C',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 90,
    valueGetter: (params: GridValueGetterParams<IAnalysis>) => {
      const { row } = params;
      return `${row.freeChlorineBromine}/${row.totalChlorineBromine}`;
    },
  },
  {
    field: 'phLevel',
    headerName: 'Ph',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'totalAlkalinity',
    headerName: 'AI',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 90,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      const { value } = params;
      if (isNil(value)) {
        return '';
      }
      return `${value} PPM`;
    },
  },
  {
    field: 'stabilizer',
    headerName: 'Stab',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'calciumHardness',
    headerName: 'Ca',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'salt',
    headerName: 'Salt',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 90,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      const { value } = params;
      if (isNil(value)) {
        return '';
      }
      return `${value} PPM`;
    },
  },
];
