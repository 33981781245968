import { Card, DatePicker } from '../../../components';
import { FC } from 'react';
import { Box, Button, FormHelperText } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IAccountExportSummary } from '../../../models';
import { isInvalidDateRange } from '../../../helpers';
import { getAccountingExportSummary } from '../../../fetch';
import { startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';

const Schema = Yup.object({
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required'),
});

interface IAccountExportParametersProps {
  setAccountingExportData: (data: IAccountExportSummary | null) => void;
  setIsSubmitting: (val: boolean) => void;
  setDates: (val: { startDate: string | Date; endDate: string | Date }) => void;
  handleReset: () => void;
  dates: {
    startDate: Date | string;
    endDate: Date | string;
  };
  setHasProcessed: (val: boolean) => void;
  hasProcessed: boolean;
}

export const AccountingExportParameters: FC<IAccountExportParametersProps> = ({
  setAccountingExportData,
  setIsSubmitting,
  setDates,
  handleReset,
  dates,
  setHasProcessed,
  hasProcessed,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        startDate: dates.startDate ?? new Date(),
        endDate: dates.endDate ?? new Date(),
      }}
      enableReinitialize
      validationSchema={Schema}
      onSubmit={async values => {
        try {
          setIsSubmitting(true);
          const startDate = startOfDay(values.startDate as Date).toISOString();
          const endDate = startOfDay(values.endDate as Date).toISOString();
          const accountingData = await getAccountingExportSummary(startDate, endDate);
          setAccountingExportData(accountingData);
          setHasProcessed(true);
          setDates({ startDate: values.startDate as Date, endDate: values.endDate as Date });
        } catch (error: any) {
          enqueueSnackbar(
            error?.Detail ?? 'Error getting accounting export summary, please try again.',
            {
              variant: 'error',
            }
          );
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      {({
        isSubmitting,
        setFieldValue,
        values,
        isValid,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
      }) => (
        <Form autoComplete="none" onSubmit={handleSubmit}>
          <Card cardTitleProps={{ title: 'Accounting Export Parameters' }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              justifyContent="center"
            >
              <DatePicker
                label="Starting Business Date"
                name="startDate"
                onChange={(date: any) => {
                  setFieldValue('startDate', date);
                  setFieldTouched('startDate', true);
                }}
                disabled={isSubmitting || hasProcessed}
                value={values.startDate}
                slotProps={{
                  textField: {
                    error: isInvalidDateRange(
                      values.endDate as Date,
                      values.startDate as Date,
                      false
                    ),
                    size: 'small',
                    fullWidth: true,
                  },
                }}
              />
              <DatePicker
                label="Ending Business Date"
                name="endDate"
                onChange={(date: any) => {
                  setFieldValue('endDate', date);
                  setFieldTouched('endDate', true);
                }}
                disabled={isSubmitting || hasProcessed}
                value={values.endDate}
                slotProps={{
                  textField: {
                    error: isInvalidDateRange(
                      values.endDate as Date,
                      values.startDate as Date,
                      false
                    ),
                    size: 'small',
                    fullWidth: true,
                  },
                }}
              />
              {(errors.startDate || errors.endDate) && (touched.startDate || touched.endDate) && (
                <FormHelperText error>{errors.endDate}</FormHelperText>
              )}
              {(touched.startDate || touched.endDate) &&
                isInvalidDateRange(values.endDate as Date, values.startDate as Date, false) && (
                  <FormHelperText error>Start Date must be after End Date.</FormHelperText>
                )}
            </Box>
            <Box display="flex" justifyContent="center" gap={1} mt={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  isInvalidDateRange(values.endDate as Date, values.startDate as Date, false)
                }
                type="submit"
              >
                Process
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => handleReset()}
                disabled={isSubmitting || !hasProcessed}
                type="button"
              >
                Reset
              </Button>
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
