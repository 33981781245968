import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider, InputAdornment } from '@mui/material';

// Fetch
import { updateRateDetail, createRateDetail } from '../../../fetch';
import { IRateDetail } from '../../../models';
import { convertToNumber } from '../../../helpers';

interface IAddEditRateDetailsModal {
  open: boolean;
  onClose: () => void;
  currentRateDetail?: IRateDetail | null;
  rateId: string;
  fetchRateDetails: () => void;
}

const Schema = Yup.object().shape({
  rateDetailId: Yup.string(),
  rateId: Yup.string(),
  officeId: Yup.string(),
  tranCodeDescription: Yup.string(),
  sortOrder: Yup.number().required('Required'),
  amount: Yup.number().required('Required')
});

export const AddEditRateDetailsModal: FC<IAddEditRateDetailsModal> = ({
  open,
  onClose,
  currentRateDetail,
  rateId,
  fetchRateDetails,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  if(currentRateDetail) {
    currentRateDetail.rateId = rateId;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          rateDetailId: currentRateDetail?.rateDetailId ?? '',
          rateId: rateId ?? '',
          officeId: user?.officeId ?? '',
          tranCodeDescription: currentRateDetail?.tranCodeDescription ?? '',
          sortOrder: currentRateDetail?.sortOrder ? currentRateDetail?.sortOrder.toString() : '0',
          amount: currentRateDetail?.amount ? Number(currentRateDetail?.amount).toFixed(2) : '0.00'
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: IRateDetail = {
            rateDetailId: values.rateDetailId,
            rateId: values.rateId,
            officeId: values.officeId,
            tranCodeDescription: values.tranCodeDescription,
            sortOrder: convertToNumber(values.sortOrder),
            amount: convertToNumber(values.amount)
          };

          try {
            currentRateDetail
              ? await updateRateDetail(data)
              : await createRateDetail(data);
            enqueueSnackbar(
              currentRateDetail
                ? 'Successfully updated rate detail!'
                : 'Successfully created rate detail!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchRateDetails();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving rate detail, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
          values
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentRateDetail ? 'Edit Rate Detail' : 'Add Rate Detail'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number"
                        inputProps={{ min: 0, max: 32767 }}
                        value={values.sortOrder}
                        required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="amount" label="Amount" type="number"
                        inputProps={{ min: 0, max: 10000000000, step: 0.01 }}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        value={Number(values.amount).toFixed(2)}
                        required
                        />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
