import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IParameterInputProps } from '../../../../models';

export const DropdownParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const classes = useStyles();
  const { parameterName, promptText } = parameter;  

  return (
    <FormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel} shrink={true}>{promptText}</InputLabel>
      <Select name={parameterName} value={value || ''} displayEmpty onChange={(e) => {
        onChange(e.target.value as string, parameter);
      }}>
        {parameter.selectListItems.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    background: 'white',
  },
}));