import {
  faFilter,
  faFilterCircleXmark,
  faFlask,
  faArrowsRotate,
  faCalendar,
  faPlusCircle,
  faVial,
  faWaterLadder,
  faCamera,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import {
  CardTitle,
  Menu,
  CardFiltersLayout,
  Card,
  useDataGrid,
  GridDataFetcher,
  TableLegend,
  TableLegendIcon,
  Tabs,
  ITab,
} from '../../components';
import { ServiceDetailPage } from '../service-details';
import { getRecurringServices, getScheduledServices, recurringExpiresInNext30Days } from '../../fetch';
import {
  IDateRange,
  IRecurringService,
  IScheduledService,
  ISimpleDateRange,
  ServicePodType,
  ServiceTypeTab,
} from '../../models';
import { ScheduledServicesFilters } from './ScheduledServicesFilters';
import clsx from 'clsx';
import { format, subDays } from 'date-fns';
import { ConfirmModal } from './confirm-modal';
import { ScheduledServicesDataGrid } from './scheduled-services-data-grid';
import { GridSortDirection } from '@mui/x-data-grid';
import { MAINTENANCE_FIELD_REPORT_USER_GROUP } from '../../helpers';
import { RecurringServicesDataGrid } from './recurring-services-data-grid';

export interface IScheduledServices {
  dateRange?: ISimpleDateRange;
  accountId?: string;
  redirect?: string;
  isFieldReport?: boolean;
  selectedUserFilter?: string;
  title?: string;
  noResultsText?: string;
  siteId?: string;
  onNavigation?: (route: string) => unknown;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  dateLinkModal?: boolean;
  currentStartDatePast30Days?: boolean;
  isSitePage?: boolean;
  showNonClickableAttributes?: boolean;
  showRecurringTab?: boolean;
  parentRecurringService?: string;
}

export const ScheduledServices: FC<IScheduledServices> = ({
  dateRange,
  accountId,
  redirect = '',
  isFieldReport,
  selectedUserFilter,
  title,
  siteId,
  onNavigation,
  isCollapsible = false,
  initialExpand = false,
  isExpanded,
  isEditable = true,
  dateLinkModal = true,
  currentStartDatePast30Days = false,
  isSitePage = false,
  showNonClickableAttributes,
  showRecurringTab = true,
  parentRecurringService,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isSmMobile = useMediaQuery('(max-width: 600px)');
  const searchParams = new URLSearchParams(window.location.search);
  const userGroupId = searchParams.get('userGroupId');
  const localStorageUserGroupId = localStorage?.getItem(MAINTENANCE_FIELD_REPORT_USER_GROUP);
  const sub30Days = subDays(new Date(), 30);
  const initialCurrentStartDate = sub30Days;
  const initialCompletedStartDate = sub30Days;
  const initialCompletedEndDate = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingFilters, setIsShowingFilters] = useState(isFieldReport ?? false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: dateRange?.selection?.startDate ?? null,
    endDate: dateRange?.selection?.endDate ?? null,
    key: 'selection',
    inputValue: '',
  });
  const [selectedSite, setSelectedSite] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>(isFieldReport ? 'All' : 'O');
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<string>('');
  const [selectedServiceType, setSelectedServiceType] = useState<string>('');
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>(
    isFieldReport ? userGroupId ?? localStorageUserGroupId ?? '' : ''
  );
  const [defaultUserGroup, setDefaultUserGroup] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [confirmService, setConfirmService] = useState<IScheduledService | null>(null);
  const [filters, setFilters] = useState<{
    serviceDefId?: number | string;
    status?: string;
    startDate?: Date | string | null;
    endDate?: Date | string | null;
    userIds?: string;
    accountId?: string;
    userGroupId?: string;
  } | null>({});
  const [selectedTab, setSelectedTab] = useState<ServiceTypeTab>(ServiceTypeTab.OPEN);
  const [currentServiceOption, setServiceOption] = useState<{
    currentServiceId: string | null;
    optionType: ServicePodType | null;
  }>({
    currentServiceId: null,
    optionType: null,
  });
  const [isLoadingServices, setLoadingServices] = useState(false);
  const [isLoadingRecurringServices, setLoadingRecurringServices] = useState(false);
  const [internalUserFilter, setInternalUserFilter] = useState(selectedUserFilter ?? undefined);

  const fetchServices = async ({
    sortColumn,
    beforeItemId,
    sortDirection,
    afterItemId,
    perPage,
    isFirstPage,
    isLastPage,
  }: {
    sortColumn?: string;
    beforeItemId?: number;
    sortDirection?: GridSortDirection;
    afterItemId?: number;
    perPage: number;
    isFirstPage?: boolean;
    isLastPage?: boolean;
  }) => {
    setLoadingServices(true);
    if (selectedTab === ServiceTypeTab.RECURRING) return;
    const getStartDate = () => {
      if (!!selectedDateRange?.startDate) {
        return format(selectedDateRange?.startDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return undefined;
    };
    const getEndDate = () => {
      if (!!selectedDateRange?.endDate) {
        return format(selectedDateRange?.endDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return undefined;
    };

    const params = {
      startDate: getStartDate(),
      endDate: getEndDate(),
      sortBy: sortColumn,
      serviceDefId:
        selectedServiceType === 'All' || !selectedServiceType ? undefined : selectedServiceType,
      status: selectedStatus === 'All' || !selectedStatus ? ['O, H, C, P'] : selectedStatus,
      sortDirection: sortDirection ? sortDirection : 'asc',
      perPage,
      accountId: accountId ?? '',
      userIds: internalUserFilter ?? selectedAssignedTo,
      userGroupId: selectedUserGroup === 'all' ? '' : selectedUserGroup ?? '',
      siteId: siteId ?? selectedSite ?? '',
      first: isFirstPage ?? false,
      last: isLastPage ?? false,
      recurringServiceId: parentRecurringService ?? '',
    };
    if (beforeItemId) {
      // @ts-ignore
      params.before = beforeItemId;
    }
    if (afterItemId) {
      // @ts-ignore
      params.after = afterItemId;
    }
    try {
      const res = await getScheduledServices(params);
      return {
        rows: res.data,
        rowCount: res.totalCount,
      };
    } catch (error) {
      enqueueSnackbar(`Error loading scheduled services, please try again.`, {
        variant: 'error',
      });
      throw error;
    } finally {
      setLoadingServices(false);
    }
  };

  useEffect(() => {

    if (isFieldReport) return;

    const fetchDataToDisplayCorrespondingTab = async () => {
      try {
        const result = await recurringExpiresInNext30Days(siteId);
        if (result === true) {
          setSelectedTab(ServiceTypeTab.RECURRING);
        }
        else {
          setSelectedTab(ServiceTypeTab.OPEN);
        }
      } catch (error) { }
    };
    fetchDataToDisplayCorrespondingTab();
  }, [siteId, isFieldReport]);

  useEffect(() => {
    if (selectedUserFilter !== internalUserFilter) {
      setInternalUserFilter(selectedUserFilter);
    }
  }, [selectedUserFilter, internalUserFilter]);

  const fieldReportdataFetcher: GridDataFetcher<IScheduledService, number> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      return fetchServices({
        sortColumn,
        beforeItemId,
        sortDirection,
        afterItemId,
        perPage,
        isFirstPage,
        isLastPage,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalUserFilter, selectedDateRange, filters, selectedUserGroup]
  );

  const dataFetcher: GridDataFetcher<IScheduledService, number> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      if (selectedTab === ServiceTypeTab.RECURRING) return;
      return fetchServices({
        sortColumn,
        beforeItemId,
        sortDirection,
        afterItemId,
        perPage,
        isFirstPage,
        isLastPage,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, searchedValue, selectedTab]
  );

  const {
    rows,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onKeysetPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IScheduledService, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: `scheduled-services-grid${isFieldReport ? '-field-report' : ''}`,
      sortColumn: 'serviceDate',
      sortDirection: 'asc',
    },
    keysetPagingKey: 'scheduledServiceId',
    dataFetcher: isFieldReport ? fieldReportdataFetcher : dataFetcher,
  });

  const formatInitialDate = () => {
    var date = new Date(filters?.startDate!);
    return format(date, "yyyy-MM-dd'T'HH:mm:ss")
  }

  const formatEndDate = () => {
    var date = new Date(filters?.endDate!);
    return format(date, "yyyy-MM-dd'T'HH:mm:ss")
  }

  const recurringServicesDataFetcher: GridDataFetcher<IRecurringService, number> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage, isInitialSort }) => {
      setLoadingRecurringServices(true);
      try {
        if (selectedTab !== ServiceTypeTab.RECURRING) return;
        const params = {
          siteId: siteId ?? selectedSite ?? '',
          sortBy: isInitialSort === undefined || isInitialSort === true ? 'endDateEmptyFarthest' : sortColumn,
          sortDirection: sortDirection ?? 'asc',
          serviceDefId: selectedServiceType,
          onlyIncludeActiveServices: true,
          page: isInitialSort ? 1 : page + 1,
          perPage,
          userId: internalUserFilter ?? selectedAssignedTo,
          initialDate: !!filters?.startDate ? formatInitialDate() : null,
          endDate: !!filters?.endDate ? formatEndDate() : null,
        }
        const res = await getRecurringServices(params);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading recurring services, please try again.`, {
          variant: 'error',
        });
        throw error;
      } finally {
        setLoadingRecurringServices(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, selectedTab]
  );

  const {
    rows: recurringServices,
    page: recurringPage,
    pageSize: recurringPerPage,
    rowCount: recurringServicesCount,
    sortModel: recurringSortModel,
    onSortModelChange: onRecurringSortModelChange,
    onPageChange: onRecurringPageChange,
    onPageSizeChange: onRecurringPageSizeChange,
  } = useDataGrid<IRecurringService, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: `recurring-services-grid`,
      sortColumn: 'initialDate',
      sortDirection: 'asc',
    },
    dataFetcher: recurringServicesDataFetcher,
  });

  const handleResetPaging = () => {
    onKeysetPageChange(0, true, false);
  };

  // Allows for external picker to control and set visible filters and update grid based on selection
  useEffect(() => {
    handleResetPaging();
    if (dateRange?.selection?.startDate?.getTime() !== selectedDateRange.startDate?.getTime()) {
      setSelectedDateRange({
        startDate: dateRange?.selection?.startDate ?? null,
        endDate: dateRange?.selection?.endDate ?? null,
        key: 'selection',
        inputValue: '',
      });
      setFilters({
        ...filters,
        startDate: dateRange?.selection?.startDate ?? null,
        endDate: dateRange?.selection?.endDate ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);
  const userGroupRedirect = useMemo(() => {
    return isFieldReport && selectedUserGroup ? `&userGroupId=${selectedUserGroup}` : '';
  }, [isFieldReport, selectedUserGroup]);
  const addServiceOptions = [
    {
      icon: faCalendar,
      link: `/services/maintenance/new${redirect ? `?redirect=${redirect}${userGroupRedirect}` : ''
        }`,
      text: 'New Single Service',
    },
    {
      icon: faArrowsRotate,
      link: `/services/maintenance/recurring${redirect ? `?redirect=${redirect}${userGroupRedirect}` : ''
        }`,
      text: 'New Recurring Service',
    },
  ];

  return (
    <>
      <Card>
        <CardTitle
          title={title ? title : 'Scheduled Services'}
          mobileWrap
          marginBottom={0}
          action={
            <>
              <Button
                onClick={() => setIsShowingFilters(!isShowingFilters)}
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoadingServices}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                data-testid="filters-button"
              >
                Filters
              </Button>

              <Menu
                color="secondary"
                disableMargin
                isDisabled={isLoadingServices}
                startIcon={faPlusCircle}
                buttonText="Add Service"
                buttonProps={{
                  fullWidth: isSmMobile, 
                }}
                options={addServiceOptions.map(o => {
                  if (!onNavigation) {
                    return o;
                  }
                  return {
                    ...o,
                    link: null,
                    onClick: () => onNavigation(o.link),
                  };
                })}
              />
            </>
          }
          withExpand={isCollapsible}
          initialExpand={initialExpand}
          overrideExpand={isExpanded}
        >
          <CardFiltersLayout isOpen={isShowingFilters}>
            <ScheduledServicesFilters
              initialDateRange={dateRange}
              setSelectedDateRange={setSelectedDateRange}
              selectedDateRange={selectedDateRange}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              hasAppliedFilters={hasAppliedFilters}
              setHasAppliedFilters={setHasAppliedFilters}
              selectedServiceType={selectedServiceType}
              setSelectedServiceType={setSelectedServiceType}
              isLoading={isLoadingServices || isLoadingRecurringServices}
              setSelectedSite={accountId ? undefined : setSelectedSite}
              selectedSite={selectedSite}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              setSearchedValue={setSearchedValue}
              handleSearch={() => {
                handleResetPaging();
              }}
              applyFilters={(clearFilters?: boolean, userGroupId?: string) => {
                if (clearFilters) {
                  handleResetPaging();
                  setFilters({
                    serviceDefId: '',
                    status: '',
                    startDate: dateRange?.selection?.startDate?.toUTCString(),
                    endDate: dateRange?.selection?.endDate?.toUTCString(),
                    accountId: '',
                    userIds: '',
                    userGroupId: defaultUserGroup ?? '',
                  });
                } else {
                  handleResetPaging();
                  setFilters({
                    ...filters,
                    serviceDefId: selectedServiceType,
                    status: selectedStatus,
                    startDate: selectedDateRange?.startDate,
                    endDate: selectedDateRange?.endDate,
                    accountId: selectedSite,
                    userIds: internalUserFilter ?? selectedAssignedTo,
                    userGroupId: userGroupId ?? selectedUserGroup,
                  });
                }
              }}
              setSelectedAssignedTo={setSelectedAssignedTo}
              selectedAssignedTo={internalUserFilter ?? selectedAssignedTo}
              selectedTab={selectedTab}
              selectedUserGroup={selectedUserGroup}
              defaultUserGroup={defaultUserGroup}
              setDefaultUserGroup={setDefaultUserGroup}
              setSelectedUserGroup={setSelectedUserGroup}
              isFieldReport={isFieldReport}
              isRecurringServiceDetail={parentRecurringService ? true : false}
            />
          </CardFiltersLayout>
          {selectedTab && !isFieldReport && (
            <Box mt={isCollapsible ? -1.5 : undefined}>
              <Tabs
                id="maintenance-service-tabs"
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  // Reset filters on tab switch
                  setHasAppliedFilters(false);
                  setSelectedServiceType('');
                  setSelectedUserGroup(defaultUserGroup ?? '');
                  setSelectedAssignedTo('');
                  setSelectedStatus(val);
                  setSearchedValue('');
                  setSearchValue('');
                  // don't reset the internal date range if there is passed in a parent date range, i.e. home page date selector
                  if (!dateRange) {
                    setSelectedDateRange({
                      startDate:
                        currentStartDatePast30Days && val === 'O'
                          ? initialCurrentStartDate
                          : !currentStartDatePast30Days && val === 'O'
                            ? null
                            : initialCompletedStartDate,
                      endDate: val === 'O' ? null : initialCompletedEndDate,
                      key: 'selection',
                      inputValue: '',
                    });
                  }
                  // Reset pagination upon tab switch
                  handleResetPaging();
                  setSelectedTab(val as ServiceTypeTab);
                }}
                tabs={
                  [
                    {
                      key: ServiceTypeTab.OPEN,
                      value: ServiceTypeTab.OPEN,
                      title: 'Current',
                      disabled: isLoadingServices || isLoadingRecurringServices,
                    },
                    {
                      key: ServiceTypeTab.CLOSED,
                      value: ServiceTypeTab.CLOSED,
                      title: 'Completed',
                      disabled: isLoadingServices || isLoadingRecurringServices,
                    },
                    showRecurringTab && {
                      key: ServiceTypeTab.RECURRING,
                      value: ServiceTypeTab.RECURRING,
                      title: 'Recurring',
                      disabled: isLoadingServices || isLoadingRecurringServices,
                    },
                  ].filter(Boolean) as ITab[]
                }
              />
            </Box>
          )}
          <Box mt={1}>
            {(selectedTab === ServiceTypeTab.OPEN || selectedTab === ServiceTypeTab.CLOSED) && (
              <ScheduledServicesDataGrid
                isKeysetTable
                rows={rows}
                rowCount={recordCount}
                page={page}
                pageSize={perPage}
                onPageChange={onKeysetPageChange}
                onPageSizeChange={onPageSizeChange}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                setConfirmService={setConfirmService}
                dateLinkModal={dateLinkModal}
                redirect={`${redirect}${userGroupRedirect}`}
                accountId={accountId}
                isFieldReport={isFieldReport}
                setServiceOption={setServiceOption}
                selectedTab={selectedTab}
                isEditable={isEditable}
                loading={isLoadingServices}
                isSitePage={isSitePage}
                showNonClickableAttributes={
                  isSitePage && selectedTab === ServiceTypeTab.CLOSED
                    ? false
                    : showNonClickableAttributes
                }
              />
            )}
            {selectedTab === ServiceTypeTab.RECURRING && (
              <RecurringServicesDataGrid
                rows={recurringServices}
                rowCount={recurringServicesCount}
                page={recurringPage}
                pageSize={recurringPerPage}
                onPageChange={onRecurringPageChange}
                onPageSizeChange={onRecurringPageSizeChange}
                sortModel={recurringSortModel}
                onSortModelChange={onRecurringSortModelChange}
                loading={isLoadingRecurringServices}
                redirect={redirect}
              />
            )}
          </Box>
          {isFieldReport && !isLoadingServices && rows && rows?.length > 0 && (
            <Box mt={isMobile ? 1 : 0}>
              <TableLegend>
                <TableLegendIcon icon={faWaterLadder} helpText="Site has valid Pool Equipment" />
                <TableLegendIcon icon={faFlask} helpText="Water Test has been Performed" />
                <TableLegendIcon icon={faVial} helpText="Chemical Use has been Logged" />
                <TableLegendIcon icon={faCamera} helpText="Service Photo Attached" />
                <TableLegendIcon icon={faCircleCheck} helpText="Service has been Completed" />
              </TableLegend>
            </Box>
          )}
        </CardTitle>
      </Card>
      <ConfirmModal
        isOpen={!!confirmService}
        handleClose={() => setConfirmService(null)}
        confirmService={confirmService}
        redirect={`${redirect}${userGroupRedirect}`}
      />
      {!!currentServiceOption?.currentServiceId && (
        <ServiceDetailPage
          isModal
          isModalOpen={true}
          handleModalClose={() =>
            setServiceOption({
              optionType: null,
              currentServiceId: null,
            })
          }
          podType={currentServiceOption?.optionType}
          currentServiceId={currentServiceOption?.currentServiceId}
        />
      )}
    </>
  );
};
