import { Grid, Link, Typography, Box } from '@mui/material';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { CodeBlock } from '../code-block';
import { useState } from 'react';
import { StyleGuideServerSideDataGridExample } from './data-grid-example';

export const StyleGuidePagination = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  return (
    <StyleGuidePage title="Pagination">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Pagination component is custom pagination component built upon the{' '}
            <Link
              href="https://mui.com/material-ui/react-table/#custom-pagination-options"
              target="_blank"
            >
              MUI Table Pagination
            </Link>{' '}
            component. It can be used with any component, including BS Table and Cards, to create a
            paginated list of items.
          </Typography>
          <Grid container spacing={{ xs: 1, md: 3 }} mb={1}>
            <Grid item xs={12} md={7} lg={6}>
              <Typography mb={1}>
                <code>
                  Props:
                  <br />- TablePaginationProps (MUI)
                  <br />- setPage: (newPage: number) =&gt; void
                  <br />- setRowsPerPage: (val: number) =&gt; void
                </code>
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} lg={6}>
              <Typography mb={1}>
                <code>
                  Common variables:
                  <br />
                  Note: "Data" should be replaced with appropriate context/verbiage
                  <br />- page: (useState) number
                  <br />- isFirstPage: (useState) boolean
                  <br />- isLastPage: (useState) boolean
                  <br />- perPage: (useState) number
                  <br />- beforeDataId: (useState) string
                  <br />- afterDataId: (useState) string
                  <br />- recordCount: (useState) number
                </code>
              </Typography>
              <Typography mb={1}>
                <code>
                  Common functions:
                  <br />- handlePageChange
                  <br />- handleResetPaging
                </code>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <StyleGuideServerSideDataGridExample id="pagination-example" showPagination />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &#123;!isLoadingData && data && data?.length &gt; 0 && (
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;Box
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;marginTop="1rem"
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;display="flex"
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;flexDirection=&#123;&#123; xs: 'column', sm:
            'row', &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;alignItems="center"
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;justifyContent=&#123;&#123; xs: 'center', sm:
            'flex-end', &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Pagination
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;page=&#123;page&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;count=&#123;recordCount&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rowsPerPage=&#123;perPage&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setPage=&#123;newPage =&gt;
            &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setPage(newPage);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handlePageChange(newPage);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setRowsPerPage=&#123;setRowsPerPage&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            )&#125;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
