import { axios } from '../helpers';
import qs from 'querystring';
import {
  IEstimateLineItemDetail,
  IResponse,
  IErrorResponse,
  IEstimate,
  IEstimateForm,
  IEstimatePost,
  IDropdownResponse,
  IEstimateLineItem,
  IEstimateLineItemPost,
  IInitialEmailFormValues,
  IGetFiltersResponse,
  ISavedCreditCard,
  IAgreementStatus,
  IEstimateLaborLineItem,
  IEstimateSalesTaxPost,
  IEstimateSalesTax,
  IAgreementData,
} from '../models';
import { userSignal } from '../signals';

export const getEstimates = async ({
  filters,
  ...otherParams
}: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
  accountId?: string | number;
  leadId?: number | string;
  repairId?: string;
  whenCreatedStartDate?: string;
  whenCreatedEndDate?: string;
  filters?: Record<string, string[]>;
}): Promise<IResponse<IEstimate[]>> => {
  try {
    const { data } = await axios.get(`/api/estimates`, {
      params: {
        ...otherParams,
        ...(filters || {}),
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/estimates/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getEstimateLeadOptions = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>(
    `/api/estimates/filters?OfficeId=${officeId}`,
    {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    }
  );
  const options = data.filters.find(filter => filter.name === 'LeadId')?.selectListItems;
  return options ?? [];
};

export const getEstimate = async (
  estimateId: string,
  officeId?: string | null
): Promise<IEstimateForm> => {
  try {
    const { data } = await axios.get(`/api/estimates/${estimateId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const postEstimate = async (
  payload: IEstimatePost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/estimates`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const putEstimate = async (
  estimateId: string,
  payload: IEstimatePost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/estimates/${estimateId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteEstimate = async (estimateId: string | number): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.delete(`/api/estimates/${estimateId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEstimateStatuses = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/estimates/statuses`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNextAvailableEstimateNumber = async (officeId: string): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/estimates/next-available?OfficeId=${officeId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEstimateLineItems = async (
  estimateId: string,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  },
  officeId?: string | null
): Promise<IResponse<IEstimateLineItem[]>> => {
  try {
    const { data } = await axios.get(`/api/estimates/${estimateId}/line-items`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEstimateLineItem = async (
  estimateId: string,
  estimateLineItemId: string,
  officeId?: string | null
): Promise<IEstimateLineItemDetail> => {
  try {
    const { data } = await axios.get(
      `/api/estimates/${estimateId}/line-items/${estimateLineItemId}`,
      {
        params: {
          officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteEstimateLineItem = async (
  estimateId: string | number,
  estimateLineItemId: string
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.delete(
      `/api/estimates/${estimateId}/line-items/${estimateLineItemId}`
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const postEstimateLineItem = async (
  estimateId: string,
  payload: IEstimateLineItemPost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/line-items`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const putEstimateLineItem = async (
  estimateId: string,
  estimateLineItemId: string,
  payload: IEstimateLineItemPost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(
      `/api/estimates/${estimateId}/line-items/${estimateLineItemId}`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const copyEstimate = async (
  estimateId: string,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/copy`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createInvoiceFromEstimate = async (
  estimateId: string,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/invoice`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const sendEstimateEmail = async (
  estimateId: string | number,
  payload: IInitialEmailFormValues,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/email`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateLaborFeeTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/estimates/labor-fee-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateLaborDurations = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/estimates/labor-durations`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const calculateLaborRate = async (req: {
  laborFeeType?: string | null;
  fixedLaborRate?: number | null;
  initialLaborfees?: number | null;
  additionalLaborFees?: number | null;
  initialLaborFeesDuration?: string | null;
  estimatedLaborDuration?: number | null;
  officeId?: string | null;
}): Promise<number> => {
  try {
    const { data } = await axios.post('/api/estimates/labor-rate', {
      ...req,
      officeId: req.officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createEstimateLaborLineItem = async (
  estimateId: string,
  payload: IEstimateLaborLineItem,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/line-items/labor`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateEstimateLaborLineItem = async (
  estimateId: string,
  lineItemId: string | number,
  payload: IEstimateLaborLineItem,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(
      `/api/estimates/${estimateId}/line-items/${lineItemId}/labor`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createEstimateAgreement = async (estimateId: string, officeId?: string | null) => {
  try {
    const { data } = await axios.post(`/api/estimates/${estimateId}/agreement`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateAgreement = async (
  token: string,
  estimateId: string | undefined,
  repairId: string | undefined,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.get(`/api/estimates/agreement`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/pdf',
      },
      params: {
        estimateId: estimateId ?? null,
        repairId: repairId ?? null,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateAgreementData = async (
  token: string,
  estimateId: string | undefined,
  repairId: string | undefined,
  officeId?: string | null
): Promise<IAgreementData> => {
  try {
    const { data } = await axios.get(`/api/estimates/agreement-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        estimateId: estimateId ?? null,
        repairId: repairId ?? null,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const downloadEstimateAgreement = async (
  estimateId: string,
  token: string,
  officeId: string
): Promise<any> => {
  try {
    const { data, headers } = await axios.get(`/api/estimates/${estimateId}/agreement/pdf`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return { data, headers };
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const getEstimateAgreementStatus = async (
  token: string,
  estimateId: string | undefined,
  repairId: string | undefined,
  officeId?: string | null
): Promise<IAgreementStatus> => {
  try {
    const { data } = await axios.get(`/api/estimates/agreement/status`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        estimateId: estimateId ?? null,
        repairId: repairId ?? null,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const signEstimateAgreement = async (
  token: string,
  payload: FormData,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/estimates/agreement/signature`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const setEstimateOverrideStatus = async (
  estimateId: string | number,
  actionType: 'OVERRIDE' | 'RESET',
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.put(
      `/api/estimates/${estimateId}/agreement/set-approval-override-status`,
      {
        actionType,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateSavedCreditCards = async (
  estimateId: string,
  token: string,
  officeId?: string | null
): Promise<ISavedCreditCard[]> => {
  try {
    const { data } = await axios.get(`/api/estimates/${estimateId}/saved-cards`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getEstimateVariableLabor = async (
  estimateId: string,
  officeId?: string | null
): Promise<IEstimateLineItemDetail[]> => {
  try {
    const { data } = await axios.get(`/api/estimates/${estimateId}/line-items/variable-labor`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const generateEstimateSalesTax = async (
  payload: IEstimateSalesTaxPost,
  officeId?: string | null
): Promise<IEstimateSalesTax> => {
  try {
    const { data } = await axios.post(`/api/estimates/tax`, {
      data: {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
