import { FC } from 'react';
import { Page } from '../../components';
import { CustomersList } from './Customers-list';

interface ICustomersListPage {}

export const CustomersListPage: FC<ICustomersListPage> = () => {
  return (
    <Page title="Customers">
      <CustomersList />
    </Page>
  );
};
