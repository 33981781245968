import { FC, ReactNode } from 'react';
import { TextField, StandardTextFieldProps, InputAdornment } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface SelectProps extends StandardTextFieldProps {
  name: string;
  children: ReactNode;
  hasClear?: boolean;
  onClear?: () => void;
}

export const Select: FC<SelectProps> = ({
  name,
  children,
  hasClear = false,
  onClear,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { value } = target;
    setFieldValue(name, value);
  };

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    ...field,
    ...props,
    select: true,
    onChange: props.onChange ?? handleChange,
    InputProps: {
      ...props.InputProps,
      ...(hasClear
        ? {
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                position: 'absolute',
                right: 35,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                title="Clear"
                onClick={() => {
                  setFieldValue(name, '');
                  onClear && onClear();
                }}
              />
            </InputAdornment>
          ),
        }
        : undefined),
    },
  };

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return <TextField {...config}>{children}</TextField>;
};
