import React, { useContext, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import SetupPageNav from './setup-page-nav';
import { Switch } from 'react-router-dom';
import { ISetupPageRoute, setupPageRoutes } from './setup-page-routes';
import { Card } from '../../components';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { PrivateRoute } from '../../components/skeleton/PrivateRoute';
import { hasNavPermissions } from '../../helpers';
import { UserContext } from '../../context';
import { Paths } from '../../constants';

const SetupPageDetail = () => {
  const classes = useStyles();
  const setupContentRef = useRef<HTMLDivElement>(null);
  const { user } = useContext(UserContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <Card className={classes.card}>
          <SetupPageNav
            className={clsx(classes.nav)}
            onNavLinkClick={() => {
              setTimeout(() => {
                setupContentRef?.current?.scrollIntoView();
              }, 200);
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <div ref={setupContentRef}>
          <Switch>
            {setupPageRoutes
              .filter(route =>
                hasNavPermissions(
                  {
                    ...route,
                    permissions: route.permission ? [route.permission] : [],
                  },
                  user!
                )
              )
              .map((route: ISetupPageRoute) => (
                <PrivateRoute
                  exact
                  key={route.title}
                  path={route.path}
                  legacyUrl={route.legacyUrl}
                  featureFlag={route.featureFlag}
                  permission={route.permission}
                  reverseFlagCheck={route.title === Paths.techAvailability.label}
                >
                  {route.element}
                </PrivateRoute>
              ))}
          </Switch>
        </div>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  card: {
    display: 'flex',
    flex: 1,
    minWidth: 'initial',
    height: 460,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    '@media (min-width: 900px)': {
      maxWidth: 300,
    },
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    padding: 2,
    '& a': {
      color: theme.palette.grey['600'],
      fontSize: theme.typography.caption.fontSize,
    },
  },
}));
export default SetupPageDetail;
