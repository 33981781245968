import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { getOptimizeRoutesMethods } from '../../fetch';
import { IDropdownResponse } from '../../models';

export const useGetOptimizationMethods = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(['getOptimizeRoutesMethods'], async function(): Promise<IDropdownResponse[]> {
    try {
      const res = await getOptimizeRoutesMethods();
      if (res && res.Detail) {
        enqueueSnackbar(`Error, ${res.Detail}`, {
          variant: 'error',
        });

        return [];
      } else if (
        res &&
        res.Errors &&
        Object.values(res.Errors)[0] &&
        Object.values(Object.values(res.Errors)[0] as any)[0]
      ) {
        enqueueSnackbar(`Error, ${Object.values(Object.values(res.Errors)[0] as any)[0]}`, {
          variant: 'error',
        });

        return [];
      }

      return res satisfies IDropdownResponse[];
    } catch (error: any) {
      if (error?.Detail) {
        enqueueSnackbar(`Error, ${error.Detail}`, {
          variant: 'error',
        });

        return [];
      }

      enqueueSnackbar(`We were unable fetch the Optimization Methods.`, {
        variant: 'error',
      });

      return [];
    }
  });
};
