import clsx from 'clsx';
import { Grid } from '@mui/material';
import { FC } from 'react';

// Custom component for display columns, to reduce duplicated code and make it easier to update all at once
export interface ISiteDetailsCol {
  children: any;
  className?: string;
  printClasses?: string;
  sm?: number;
  md?: number;
  lg?: number;
  isModal?: boolean;
}

export const SiteDetailsCol: FC<ISiteDetailsCol> = ({
  children,
  className,
  printClasses = 'col-print-6',
  sm = 6,
  md = 4,
  lg = 3,
  isModal,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={sm}
      md={md}
      lg={lg}
      className={clsx('print--avoid-break', printClasses, className)}
    >
      {children}
    </Grid>
  );
};
