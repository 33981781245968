import {
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  FormHelperText,
} from '@mui/material';
import { title } from 'case';
import { Dispatch, FC, useEffect, useState, Fragment } from 'react';
import { cloneViaSerialization, getDailyWeeklyOptions } from '../../helpers';
import { useFormikContext } from 'formik';

interface IWeekly {
  setSchedule: Dispatch<{
    interval1: number;
    interval2: number;
    recurPeriod: string;
  }>;
  interval1: number;
  interval2: number;
}

interface IDayOption {
  name: string;
  checked: boolean;
  interval: number;
}

export const defaultDays = [
  {
    name: 'sunday',
    checked: false,
    interval: 1,
  },
  {
    name: 'monday',
    checked: false,
    interval: 2,
  },
  {
    name: 'tuesday',
    checked: false,
    interval: 4,
  },

  {
    name: 'wednesday',
    checked: false,
    interval: 8,
  },

  {
    name: 'thursday',
    checked: false,
    interval: 16,
  },

  {
    name: 'friday',
    checked: false,
    interval: 32,
  },

  {
    name: 'saturday',
    checked: false,
    interval: 64,
  },
];

export const getSingleDayNumberTracker = (daysNumber: number) => {
  const selectedDays: string[] = [];
  if (daysNumber >= 64) {
    return ['saturday'];
  }
  if (daysNumber >= 32) {
    return ['friday'];
  }
  if (daysNumber >= 16) {
    return ['thursday'];
  }
  if (daysNumber >= 8) {
    return ['wednesday'];
  }
  if (daysNumber >= 4) {
    return ['tuesday'];
  }
  if (daysNumber >= 2) {
    return ['monday'];
  }
  if (daysNumber >= 1) {
    return ['sunday'];
  }
  return selectedDays;
};

export const getDayNumberTrackerAddedUp = (daysNumber: number) => {
  let daysNumberTracker = daysNumber;

  const selectedDays: string[] = [];
  if (daysNumberTracker >= 64) {
    selectedDays.push('saturday');
    daysNumberTracker -= 64;
  }
  if (daysNumberTracker >= 32) {
    selectedDays.push('friday');
    daysNumberTracker -= 32;
  }
  if (daysNumberTracker >= 16) {
    selectedDays.push('thursday');
    daysNumberTracker -= 16;
  }
  if (daysNumberTracker >= 8) {
    selectedDays.push('wednesday');
    daysNumberTracker -= 8;
  }
  if (daysNumberTracker >= 4) {
    selectedDays.push('tuesday');
    daysNumberTracker -= 4;
  }
  if (daysNumberTracker >= 2) {
    selectedDays.push('monday');
    daysNumberTracker -= 2;
  }
  if (daysNumberTracker >= 1) {
    selectedDays.push('sunday');
    daysNumberTracker -= 1;
  }
  return selectedDays;
};

export const Weekly: FC<IWeekly> = ({ setSchedule, interval1, interval2 }) => {
  const { errors, touched } = useFormikContext<any>();
  const [days, setDays] = useState<IDayOption[]>(defaultDays);
  const [shouldCalculate, setShouldCalculate] = useState(interval2 > 0 ? true : false);

  const updateDaySelection = (
    selectedDays: string[],
    selected: boolean,
    shouldSumDays: boolean = true
  ) => {
    if (!selectedDays.length) {
      return;
    }
    const newDays = cloneViaSerialization(shouldSumDays ? days : defaultDays);
    const newSelectedDays = (shouldSumDays ? newDays : defaultDays).map(day => {
      if (selectedDays.includes(day.name)) {
        return {
          ...day,
          checked: selected,
        };
      }
      return day;
    });
    setDays(newSelectedDays);
    // by default grab the first selected interval value
    let sumOfDaysSelected = newSelectedDays.filter(d => d.checked)?.[0]?.interval ?? 0;

    if (shouldSumDays) {
      // need to add up the intervals to match the days selected
      // i.e. Wed = 8, Thrus = 16, Fri = 32, 8 + 16 + 32 = 56 which needs to be set for the second interval2
      sumOfDaysSelected = newSelectedDays.reduce((prev, curr) => {
        if (curr.checked) {
          return prev + curr.interval;
        }
        return prev;
      }, 0);
    }
    setSchedule({
      recurPeriod: 'Weekly',
      interval1: interval1,
      interval2: sumOfDaysSelected,
    });
  };

  const calculateWeekdaysThatAreChecked = (daysNumber: number) => {
    // We only get 1 number back. Each day represents 1 "two's" place in a number
    // Sunday = 1, Monday = 2, Tuesday = 4, Wednesday = 8, Thursday = 16, Friday = 32, Saturday = 64
    //
    // The interesting property of this, is if you add up every number it is 1 less than then next day
    // That also means, that if you subtract starting at the end, you can decode which days are selected.
    const selectedDays: string[] = getDayNumberTrackerAddedUp(daysNumber);
    if (selectedDays.length) {
      updateDaySelection(selectedDays, true);
      // reset the flag so it doesn't calculate when changes are done outside this component
      setShouldCalculate(false);
    }
  };

  useEffect(() => {
    if (interval2) {
      if (typeof interval2 === 'string') {
        const resetInterval2: number = Number((interval2 as string)?.split('-')?.[0]);
        const selectedDays = getSingleDayNumberTracker(resetInterval2);
        updateDaySelection(selectedDays, true, false);
      }
      if (shouldCalculate) {
        calculateWeekdaysThatAreChecked(interval2 ?? 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval2, shouldCalculate]);

  const handleInterval1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInterval1 = +event.target.value;
    setSchedule({
      recurPeriod: 'Weekly',
      interval1: newInterval1,
      interval2: interval2,
    });
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <span>Every</span>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label=""
            select
            onChange={handleInterval1Change}
            value={interval1}
            size="small"
            inputProps={{ 'data-testid': 'weekly-interval-select' }}
          >
            {getDailyWeeklyOptions().map((val, index) => (
              <MenuItem key={`${index}`} value={val.value}>
                {val.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <span>week(s)</span>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" flexWrap="wrap" mt={1}>
        {days.map((day, index) => {
          return (
            <Fragment key={`${index}`}>
              <FormControlLabel
                control={<Checkbox />}
                label={title(day.name)}
                onChange={(_, checked) => {
                  updateDaySelection([day.name], checked);
                  // set this flag so it does calculate when changes are done inside this component
                  setShouldCalculate(true);
                }}
                checked={day.checked}
              />
            </Fragment>
          );
        })}
      </Box>
      {touched?.serviceDefId && errors?.weeklyInterval2 && (
        <FormHelperText error style={{ marginLeft: 0 }}>
          {errors?.weeklyInterval2}
        </FormHelperText>
      )}
    </>
  );
};
