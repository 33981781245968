import { FC, useMemo } from 'react';
import { EmployeeStatusCard } from './EmployeeStatusCard';
import {
  ICalendarDateRange,
  IMonthViewSettings,
  IWeekViewSettings,
  ICalendarView,
  ITimelessEvent,
  IDayViewSettings,
  ICalendarItem,
  IEmployeeStatsDetail,
} from '../../../../models';
import { alphaSort, parseCalendarDate } from '../../../../helpers';
import { TimelessCalendar } from '../../timeless/TimelessCalendar';
import { v4 as uuidv4 } from 'uuid';
import { IColorSetMap } from '../../../../models/colors';

export interface EmployeeStatsViewProps {
  userColorMap?: IColorSetMap;
  calendarItems: ICalendarItem[];
  dateRange: ICalendarDateRange;
  view: ICalendarView;
  daySettings?: IDayViewSettings;
  workWeekSettings?: IWeekViewSettings;
  weekSettings?: IWeekViewSettings;
  monthSettings?: IMonthViewSettings;
  onEmployeeClick?: (detail: IEmployeeStatsDetail) => unknown;
  onEmployeeViewClick?: (detail: IEmployeeStatsDetail) => unknown;
}

export const EmployeeStatsView: FC<EmployeeStatsViewProps> = ({
  userColorMap,
  dateRange,
  calendarItems,
  view,
  daySettings,
  workWeekSettings,
  weekSettings,
  monthSettings,
  onEmployeeClick,
  onEmployeeViewClick,
}) => {
  const events = useMemo(() => {
    const rtn: ITimelessEvent<IEmployeeStatsDetail>[] = [];
    calendarItems.forEach(calendarItem => {
      calendarItem.users.forEach(calendarItemUser => {
        const event: ITimelessEvent<IEmployeeStatsDetail> = {
          username: calendarItemUser.userInformation.username,
          id: uuidv4(),
          date: parseCalendarDate(calendarItem.calendarDate),
          data: {
            calendarItem,
            calendarItemUser,
            userInformation: calendarItemUser.userInformation,
          },
        };
        rtn.push(event);
      });
    });
    return alphaSort(rtn, 'username');
  }, [calendarItems]);
  const renderEvent = (event: ITimelessEvent<IEmployeeStatsDetail>) => {
    const { calendarItemUser } = event.data;
    return (
      <EmployeeStatusCard
        key={calendarItemUser.userInformation.userId}
        colorSet={userColorMap?.[calendarItemUser.userInformation.userId]}
        calendarItemUser={calendarItemUser}
        calendarDate={event.date.toISOString()}
        onNameClick={() => onEmployeeClick?.(event.data)}
        onViewClick={() => onEmployeeViewClick?.(event.data)}
        view={view}
      />
    );
  };

  return (
    <TimelessCalendar
      dateRange={dateRange}
      events={events}
      view={view}
      daySettings={daySettings}
      workWeekSettings={workWeekSettings}
      weekSettings={weekSettings}
      monthSettings={monthSettings}
      renderEvent={renderEvent}
      allowDragging={false}
    />
  );
};
