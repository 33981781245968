import { useContext } from 'react';
import { NonAuthPage, Page } from '../../components';
import { UserContext } from '../../context';

export const NotFound = () => {
  const { user, isFetching } = useContext(UserContext);

  if (isFetching) {
    return null;
  }
  if (user?.userId) {
    return <Page title={'404'}>Page Not Found</Page>;
  }
  return <NonAuthPage title={'404'}>Page Not Found</NonAuthPage>;
};
