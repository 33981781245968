import { Box, Divider, Button } from '@mui/material';
import { useState, useContext, FC, useCallback } from 'react';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import {
  deleteInvoiceLineItem,
  getInvoiceLines,
  postFinalizeInvoice,
  postRecallInvoice,
  postFinalizeInvoiceAutopay,
  voidSecurityDeposit,
  canFinalizeInvoice,
} from '../../fetch';
import { CardTitle, Card, useDataGrid, GridDataFetcher } from '..';
import { IInvoiceLineItem, IInvoiceDetail, IInvoiceLaborCharges } from '../../models';
import { UserContext } from '../../context/user';
import { InvoiceLinesModal } from './invoice-lines-modal';
import { hasCorrectUserPermissions, scrollToTop } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useConfirm } from '../../hooks';
import { Permissions } from '../../constants';
import { InvoiceLineItemsDataGrid } from './invoice-line-items-data-grid';
import { LaborHoursModal } from '../../pages/estimates/labor-hours-modal';
import { InvoiceLaborModal } from './invoice-labor-modal';

interface IInvoiceLinesProps {
  transactionId: string;
  isProcessed?: boolean;
  setIsUpdatingInvoice: (val: boolean) => void;
  accountId: string | null;
  invoice?: IInvoiceDetail;
  isUpdatingInvoice: boolean;
  transactionDate: string;
}

export const InvoiceLines: FC<IInvoiceLinesProps> = ({
  transactionId,
  isProcessed = false,
  setIsUpdatingInvoice,
  accountId,
  invoice,
  isUpdatingInvoice,
  transactionDate,
}) => {
  const confirm = useConfirm();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isCheckingInvoice, setCheckingInvoice] = useState(false);
  const [isShowingLaborModal, setIsShowingLaborModal] = useState<{
    type: string | null;
  }>({
    type: null,
  });
  const [invoiceLineItemId, setInvoiceLineItemId] = useState<string | undefined>(undefined);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdatingLineItems, setUpdatingLineItems] = useState(false);
  const [isLaborModalOpen, setIsLaborModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const showAutopay =
    invoice &&
    invoice?.canFinalizeAndAutoPay &&
    hasCorrectUserPermissions(Permissions.ViewRepairs, user!) &&
    !invoice.isProcessed;

  const dataFetcher: GridDataFetcher<IInvoiceLineItem> = useCallback(
    async () => {
      if (!transactionId) return;
      try {
        const res = await getInvoiceLines({
          page: 1,
          perPage: -1,
          sortDirection: 'Asc',
          officeId: user?.officeId,
          transactionId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading invoice line items, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionId, isProcessed]
  );

  const {
    rows,
    isLoading: isLoadingInvoiceLines,
    rowCount: recordCount,
    refetch: getInvoiceLineItems,
    page,
    pageSize,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IInvoiceLineItem>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'sortOrder',
      sortDirection: 'asc',
      gridKeyName: 'invoice-line-items',
    },
    dataFetcher,
  });

  const finalizeInvoiceAndAutopay = async (laborHours?: IInvoiceLaborCharges[]) => {
    setIsUpdatingInvoice(true);
    try {
      await postFinalizeInvoiceAutopay(transactionId, user?.officeId!, laborHours);
      enqueueSnackbar(`Invoice finalized!`, {
        variant: 'success',
      });
      history.push(redirect ?? `/customers/${accountId}?activeTab=transactions`);
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error finalizing invoice, please try again.`, {
        variant: 'error',
      });
      return true;
    } finally {
      setIsUpdatingInvoice(false);
    }
  };
  const finalizeInvoice = async (laborRates?: IInvoiceLaborCharges[]) => {
    setIsUpdatingInvoice(true);
    try {
      await postFinalizeInvoice(transactionId, laborRates);
      enqueueSnackbar(`Invoice finalized!`, {
        variant: 'success',
      });
      history.push(redirect ?? `/customers/${accountId}?activeTab=transactions`);
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error finalizing invoice, please try again.`, {
        variant: 'error',
      });
      return true;
    } finally {
      setIsUpdatingInvoice(false);
    }
  };
  const recallInvoice = async () => {
    const result = await confirm('Are you sure you want to recall this invoice?');
    if (result) {
      setIsUpdatingInvoice(true);
      try {
        const res = await postRecallInvoice(transactionId);
        enqueueSnackbar(`Invoice recalled!`, {
          variant: 'success',
        });
        scrollToTop();
        history.push(
          redirect
            ? `/billing/invoices/${res}?refresh&redirect=${redirect}`
            : `/billing/invoices/${res}?refresh`
        );
      } catch (err: any) {
        enqueueSnackbar(err?.Detail || `Error recalling invoice, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsUpdatingInvoice(false);
      }
    }
  };

  const handleEditLineItem = async (lineItem: IInvoiceLineItem) => {
    if (lineItem?.lookupCode?.startsWith('M14') || lineItem?.lookupCode?.startsWith('M11')) {
      setIsLaborModalOpen(true);
    } else {
      setModalOpen(true);
    }
    setInvoiceLineItemId(lineItem.invoiceLineItemId);
  };

  const handleDeleteLineItem = async (lineItem: IInvoiceLineItem) => {
    const result = await confirm('Are you sure you want to delete this line item?');
    if (result) {
      try {
        setUpdatingLineItems(true);
        await deleteInvoiceLineItem(transactionId, lineItem.invoiceLineItemId);
        enqueueSnackbar('Successfully deleted line item!', {
          variant: 'success',
        });
        getInvoiceLineItems();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error deleting line item, please try again.`, {
          variant: 'error',
        });
      } finally {
        setUpdatingLineItems(false);
      }
    }
  };

  const handleVoid = async (lineItem: IInvoiceLineItem) => {
    const confirmed = await confirm('Are you sure you want to void the security deposit?');
    if (!confirmed) {
      return;
    }
    try {
      setUpdatingLineItems(true);
      await voidSecurityDeposit(lineItem.invoiceLineItemId, user?.officeId);
      enqueueSnackbar(`Security Deposit was successfully voided!`, {
        variant: 'success',
      });
      getInvoiceLineItems();
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error voiding security deposit, please try again.`, {
        variant: 'error',
      });
    } finally {
      setUpdatingLineItems(false);
    }
  };

  const handleCanFinalize = async () => {
    try {
      setCheckingInvoice(true);
      const res = await canFinalizeInvoice(transactionId);
      if (res.errors?.length > 0) {
        setCheckingInvoice(false);
        // show first error in errors array
        enqueueSnackbar(res.errors?.[0], {
          variant: 'error',
        });
        return true;
      }
      return false;
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error finalizing invoice, please try again.`, {
        variant: 'error',
      });
    } finally {
      setCheckingInvoice(false);
    }
  };

  return (
    <>
      <Card>
        <CardTitle
          title="Invoice Lines"
          action={
            !isProcessed ? (
              <>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setModalOpen(true)}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                  disabled={isLoadingInvoiceLines || isUpdatingLineItems}
                >
                  Add Item
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    setIsLaborModalOpen(true);
                  }}
                  disabled={isLoadingInvoiceLines || isUpdatingLineItems}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                >
                  Add Labor
                </Button>
              </>
            ) : undefined
          }
        />
        <InvoiceLineItemsDataGrid
          loading={isLoadingInvoiceLines || isUpdatingLineItems}
          rows={rows}
          page={page}
          pageSize={pageSize}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          rowCount={recordCount}
          refetch={getInvoiceLineItems}
          handleEdit={handleEditLineItem}
          handleDelete={handleDeleteLineItem}
          handleVoid={handleVoid}
          isProcessed={isProcessed}
          transactionDate={transactionDate}
          getInvoiceLineItems={getInvoiceLineItems}
        />
        <Divider />
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          mt={1}
          justifyContent="space-between"
        >
          <Box display="flex" gap={1.5}>
            {!!accountId && (
              <Button
                onClick={async () => {
                  if (!isProcessed) {
                    if (invoice?.promptForLaborRate) {
                      const hasError = await handleCanFinalize();
                      if (!hasError) {
                        setIsShowingLaborModal({
                          type: 'finalize',
                        });
                      }
                    } else {
                      finalizeInvoice();
                    }
                  } else {
                    recallInvoice();
                  }
                }}
                color="primary"
                variant="outlined"
                size="small"
                disabled={isCheckingInvoice || isUpdatingInvoice}
              >
                {!isProcessed ? 'Finalize' : 'Recall'}
              </Button>
            )}
            {showAutopay && (
              <Button
                onClick={async () => {
                  if (invoice?.promptForLaborRate) {
                    const hasError = await handleCanFinalize();
                    if (!hasError) {
                      setIsShowingLaborModal({
                        type: 'autopay',
                      });
                    }
                  } else {
                    finalizeInvoiceAndAutopay();
                  }
                }}
                color="primary"
                variant="outlined"
                size="small"
                disabled={isCheckingInvoice || isUpdatingInvoice}
              >
                Finalize and Autopay
              </Button>
            )}
          </Box>
        </Box>
      </Card>
      {isModalOpen && (
        <InvoiceLinesModal
          isOpen={true}
          onClose={(shouldUpdate?: boolean) => {
            if (shouldUpdate) {
              getInvoiceLineItems();
            }
            setInvoiceLineItemId(undefined);
            setModalOpen(false);
          }}
          transactionId={transactionId}
          invoiceLineItemId={invoiceLineItemId}
          totalLineItems={recordCount}
          invoiceLines={rows}
        />
      )}
      <LaborHoursModal
        isOpen={isShowingLaborModal.type !== null}
        handleClose={() => {
          setIsShowingLaborModal({
            type: null,
          });
        }}
        isSubmitting={isUpdatingInvoice}
        handleSubmit={async (laborHours: IInvoiceLaborCharges[]) => {
          if (isShowingLaborModal.type === 'finalize') {
            return await finalizeInvoice(laborHours);
          } else {
            return await finalizeInvoiceAndAutopay(laborHours);
          }
        }}
        repairId={invoice?.repairId}
      />
      <InvoiceLaborModal
        lineItemId={invoiceLineItemId}
        allLineItems={rows}
        isOpen={isLaborModalOpen}
        transactionId={transactionId}
        onClose={() => {
          setIsLaborModalOpen(false);
          setInvoiceLineItemId(undefined);
        }}
        fetchInvoiceLineItems={getInvoiceLineItems}
      />
    </>
  );
};
