import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { PhoneTextField } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { formatInputPhoneNumber } from '../../../helpers';
import { StyleGuideValidationCallout } from '../validation-callout';

export const StyleGuidePhoneTextField = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Phone Text Field">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Phone Text Field component is a custom build upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/" target="_blank">
              MUI Text Field
            </Link>
            . It has a built-in styles, phone number formatting, and Formik props required for use
            with a Formik form and Yup form validation.
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be used specifically on forms that utilize Formik.
            For things like filters, you can use the standard MUI Text Field component, or other
            custom filter components defined in the project.
          </Typography>
          <Typography mb={1}>
            The formatting can be achieved by using the <em>formatInputPhoneNumber</em> helper
            function. Use this helper when setting initialValues for phone number fields.
          </Typography>
          <StyleGuideValidationCallout />
          <code>
            Props:
            <br />- name: string
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: formatInputPhoneNumber('8139889300'),
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box mb={1} p={0.5}>
                <PhoneTextField name="testField" label="Phone Number" />
              </Box>
              <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
                &lt;PhoneTextField name="testField" label="Phone Number" /&gt;
              </CodeBlock>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </StyleGuidePage>
  );
};
