import { FormControl, InputLabel, MenuItem, Select, InputAdornment } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFilterInputProps } from '../../../../models';
import { kebabCase } from 'lodash';

export const SelectListFilter: FC<IFilterInputProps> = ({
  filter,
  values,
  onChange,
  showClear = true,
  defaultValue,
}) => {
  const classes = useStyles();
  const { name, label } = filter;
  const [value = ''] = values;

  return (
    <FormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel}>{label}</InputLabel>
      <Select
        name={name}
        value={value || ''}
        displayEmpty
        disabled={filter?.disabled}
        onChange={e => {
          onChange([e.target.value as string], filter);
        }}
        endAdornment={
          value &&
          showClear && (
            <InputAdornment
              position="end"
              sx={{
                position: 'absolute',
                right: 35,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                title="Clear"
                onClick={() => onChange([defaultValue ?? ''], filter)}
              />
            </InputAdornment>
          )
        }
        inputProps={{
          'data-testid': `${kebabCase(name)}-select`
        }}
      >
        {filter.selectListItems?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    background: 'white',
  },
}));
