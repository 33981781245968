import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TextField, Checkbox } from '../../../../components/formikMui';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface ISpaInformationFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

interface ISpaInformationFields {
  spaDateOfPurchase: string;
}

export const SpaInformationForm: FC<ISpaInformationFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the SpaInformationDisplay component as well
  // REMINDER: When adding fields, also update the spaInfoSectionFields array in pool-structure-card
  const { values, setFieldValue, errors, touched, handleBlur } =
    useFormikContext<ISpaInformationFields>();

  return (
    <Box mt={2.5}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <FormControlLabel
            control={
              <Checkbox
                name="hasSpaBoosterPump"
                disabled={readOnly}
                onChange={e => {
                  setFieldValue('hasSpaBoosterPump', Boolean(e.target.checked));
                  handleFormChange({ ...values, hasSpaBoosterPump: Boolean(e.target.checked) });
                }}
              />
            }
            label={<Typography variant="body2">Spa Booster Pump</Typography>}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="spaVolume"
            label="Gallons"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="spaBoosterStyle"
            label="Spa Booster Style"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="spaBrand"
            label="Spa Brand"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="spaBoosterHp"
            label="Spa Booster HP"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="spaModel"
            label="Spa Model"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="spaSerialNumber"
            label="Spa Serial #"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <DatePicker
            label="Date of Purchase"
            format="MM/dd/yyyy"
            onChange={(date: any) => {
              setFieldValue('spaDateOfPurchase', date);
              handleFormChange({ ...values, spaDateOfPurchase: date });
            }}
            value={values.spaDateOfPurchase ? new Date(values.spaDateOfPurchase) : null}
            maxDate={new Date()}
            readOnly={readOnly}
            slotProps={{
              textField: {
                error: !!errors?.spaDateOfPurchase && !!touched?.spaDateOfPurchase,
                size: 'small',
                fullWidth: true,
                inputProps: {
                  readOnly: true,
                },
              },
            }}
          />
        </Box>
        <Box flex={1} />
      </Stack>
    </Box>
  );
};
