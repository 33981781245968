import { Fade, Box } from '@mui/material';
import { FC } from 'react';
import { Modal } from '../modal';
import { UploadedImage } from './uploaded-image';
import { ISitePhoto } from '../../models';

interface IImageModal {
  isOpen: boolean;
  onClose: () => void;
  source: string;
  isLoading?: boolean;
  title?: string;
  photos?: ISitePhoto[];
}

export const ImageModal: FC<IImageModal> = ({
  isOpen,
  onClose,
  source,
  isLoading,
  title,
  photos,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="xl"
      title={title ?? photos?.length! > 1 ? 'View Images' : 'View Image'}
    >
      <Fade in={isOpen}>
        <Box mt={1}>
          <UploadedImage source={source} isLoading={isLoading} photos={photos} />
        </Box>
      </Fade>
    </Modal>
  );
};
