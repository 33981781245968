import { Page } from '../../components';
import { BatchesListDetails } from './batches-list-details';

export const BatchesList = () => {
  return (
    <Page title="Payments">
      <BatchesListDetails />
    </Page>
  );
};
