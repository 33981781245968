import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faPeopleGroup,
  faWrench,
  faList,
  faDollarSign,
  faRoute,
  faCalendar,
  faMoneyCheckDollar,
  faCalculator,
  faClipboard,
  faCreditCard,
  faFlask,
  faRepeat,
  faNewspaper,
  faCommentDots,
  faCircleQuestion,
  faChartArea,
  faEllipsis,
  faLock,
  faShop,
} from '@fortawesome/free-solid-svg-icons';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import { FeatureFlag } from './feature-flags';
import { Permissions } from './permissions';
import { ROLES } from './roles';
import { getLegacyUrl } from '../helpers';
import { IRoute } from '../models';
import { styleGuideParentLink } from './style-guide-links';
const PCP_LEGACY_URL = getLegacyUrl?.();

export const REDIRECT_TO_PARAM_NAME = 'redirectTo';

export const Paths = {
  home: {
    url: '/',
    label: 'Home',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Home`,
  },
  customers: {
    url: '/customers',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Account`,
    label: 'Customers',
  },
  services: {
    url: '/services',
    legacyUrl: `${PCP_LEGACY_URL}/Office/ScheduledService`,
    label: 'Services',
  },
  maintenance: {
    url: '/services/maintenance',
    legacyUrl: `${PCP_LEGACY_URL}/Office/ScheduledService`,
    label: 'Maintenance Services',
  },
  ots: {
    url: '/services/ots',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Repair`,
    label: 'One-Time Services',
  },
  repairs: {
    url: '/services/repairs',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Repair`,
    label: 'Repairs',
  },
  fieldReport: {
    url: '/services/field-reports',
    legacyUrl: `${PCP_LEGACY_URL}/Office/FieldReport`,
    label: 'Field Report',
  },
  scheduling: {
    url: '/scheduling',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Calendar`,
    label: 'Scheduling',
  },
  tasks: {
    url: '/scheduling/tasks',
    legacyUrl: `${PCP_LEGACY_URL}/Office/ScheduledTask`,
    label: 'Tasks',
  },
  techAvailability: {
    url: '/setup/tech-availability',
    label: 'Tech Availability',
  },
  techManagement: {
    url: '/setup/tech-management',
    label: 'Tech Management',
  },
  routes: {
    url: '/routes',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Route`,
    label: 'Routes',
  },
  calendar: {
    url: '/scheduling/calendar',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Calendar`,
    label: 'Calendar',
  },
  reports: {
    url: `/reports`,
    legacyUrl: `${PCP_LEGACY_URL}/Office/Report`,
    label: 'Reports',
  },
  billing: {
    url: '/billing',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Billing`,
    label: 'Billing',
  },
  commissions: {
    url: '/commissions',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Commission`,
    label: 'Commissions',
  },
  inventory: {
    url: '/inventory',
    legacyUrl: `${PCP_LEGACY_URL}/Office/AzureInventory`,
    label: 'Inventory',
  },
  payments: {
    url: '/payments',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Payment`,
    label: 'Payments',
  },
  leads: {
    url: '/leads',
    label: 'Leads',
  },
  estimates: {
    url: '/estimates',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Estimate`,
    label: 'Estimates',
  },
  setup: {
    url: '',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Setup`,
    label: 'Setup',
  },
  admin: {
    url: '',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Admin`,
    label: 'Admin',
  },
  newsManagementAdmin: {
    url: '/admin/news-management',
    label: 'News Management',
  },
  newsManagementSetup: {
    url: '/setup/news-management',
    label: 'News Management',
  },
  userManagement: {
    url: '/setup/user-management',
    label: 'User Management',
    legacyUrl: `${PCP_LEGACY_URL}/Office/User/List`,
  },
  emailTemplates: {
    url: '/setup/email-templates',
    label: 'Email Templates',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Email/Template/Index`,
  },
  massSend: {
    url: '/setup/communication',
    label: 'Send Messages',
    legacyUrl: undefined,
  },
  taskTypes: {
    url: '/setup/task-types',
    label: 'Task Types',
    legacyUrl: `${PCP_LEGACY_URL}/Office/ScheduledTask/SetupTaskTypes`,
  },
  commissionsSetup: {
    url: '/setup/commissions',
    label: 'Commissions',
    legacyUrl: undefined,
  },
  paymentSetups: {
    url: '/setup/payment-setups',
    label: 'Payment Setup',
    legacyUrl: undefined,
  },
  salesTaxes: {
    url: '/setup/sales-taxes',
    label: 'Sales Taxes',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Setup/SalesTaxes`,
  },
  salesTaxGroups: {
    url: '/setup/sales-tax-groups',
    label: 'Sales Tax Groups',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Setup/SalesTaxes`,
  },
  billingGroups: {
    url: '/setup/billing-groups',
    label: 'Billing Groups',
    legacyUrl: undefined,
  },
  terminationTypes: {
    url: '/setup/termination-types',
    label: 'Termination Types',
    legacyUrl: undefined,
  },
  companyInfo: {
    url: '/setup/company-info',
    label: 'Company Info',
    legacyUrl: undefined,
  },
  rates: {
    url: '/setup/rates',
    label: 'Rates',
    legacyUrl: undefined,
  },
  tranCodes: {
    url: '/setup/tran-codes',
    label: 'Transaction Codes',
    legacyUrl: undefined,
  },
  tranCodeGroups: {
    url: '/setup/tran-code-groups',
    label: 'Transaction Code Groups',
    legacyUrl: undefined,
  },
  referralTypes: {
    url: '/setup/referral-types',
    label: 'Referral Types',
    legacyUrl: undefined,
  },
  userDefinedAccount: {
    url: '/setup/user-defined-account',
    label: 'Account Attribute Types',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Account/SetupAttributeTypes`,
  },
  userDefinedSiteDefs: {
    url: '/setup/user-defined-site-defs',
    label: 'Custom Site Info',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Site/SetupAttributeTypes`,
  },
  serviceLocations: {
    url: '/setup/service-locations',
    label: 'Service Locations',
    legacyUrl: undefined,
  },
  serviceCodes: {
    url: '/setup/service-codes',
    label: 'Service Codes',
    legacyUrl: `${PCP_LEGACY_URL}/Office/ScheduledService/SetupServiceTypes`,
  },
  treatmentTypes: {
    url: '/setup/treatment-types',
    label: 'Treatment Types',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Treatment/Types`,
  },
  accountClassDefs: {
    url: '/setup/account-class-defs',
    label: 'Account Class Definitions',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Account/SetupClassDefs`,
  },
  accountAlertDefs: {
    url: '/setup/account-alert-defs',
    label: 'Account Alert Definitions',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Account/SetupAlertDefs`,
  },
  companySetup: {
    url: '',
    label: 'Company Setup',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Setup`,
  },
  news: {
    url: 'https://fin.pinchapenny.com/sos/Lists/ITAnnouncements/AllItems.aspx',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Home/WhatsNew`,
    label: "What's New",
    icon: faNewspaper,
  },
  feedbackManagement: {
    url: '/admin/feedback-management',
    label: 'Feedback',
  },
  feedback: {
    url: '/feedback',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Home/Feedback`,
    label: 'Feedback',
    icon: faCommentDots,
  },
  help: {
    url: '/help',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Setup`,
    label: 'Help',
    icon: faCircleQuestion,
  },
  storeManagement: {
    url: '/admin/store-management',
    label: 'Store Management',
  },
  login: {
    url: '/login',
    label: 'Login',
  },
  logout: {
    url: '/logout',
    label: 'Logout',
  },
  forgotPassword: {
    url: '/forgot-password',
    label: 'Forgot Password',
  },
  resetPassword: {
    url: '/reset-password',
    label: 'Reset Password',
  },
  welcome: {
    url: '/welcome',
    label: 'Welcome',
  },
  verifyAddresses: {
    url: '/verify-addresses',
    label: 'Verify Addresses',
    legacyUrl: `${PCP_LEGACY_URL}/Office/Address/Verify`,
  },
  appSetup: {
    url: '/setup',
    label: 'Setup',
  },
};

let routeIndex = 0;

export const mainRoutes: IRoute[] = [
  {
    id: routeIndex++,
    label: Paths.home.label,
    link: Paths.home.url,
    legacyUrl: Paths.home.legacyUrl,
    icon: <FontAwesomeIcon icon={faHouse} />,
    featureFlags: [FeatureFlag.v2HomePage],
    subNav: null,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.customers.label,
    link: Paths.customers.url,
    legacyUrl: Paths.customers.legacyUrl,
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    featureFlags: [FeatureFlag.v2Customers],
    subNav: null,
    permissions: [Permissions.ViewCustomers],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.maintenance.label,
    link: Paths.maintenance.url,
    legacyUrl: Paths.maintenance.legacyUrl,
    icon: <FontAwesomeIcon icon={faRepeat} />,
    featureFlags: [FeatureFlag.v2Services],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.ots.label,
    link: Paths.ots.url,
    legacyUrl: Paths.ots.legacyUrl,
    icon: <FontAwesomeIcon icon={faWrench} />,
    featureFlags: [FeatureFlag.v2Ots],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.routes.label,
    link: Paths.routes.url,
    legacyUrl: Paths.routes.legacyUrl,
    icon: <FontAwesomeIcon icon={faRoute} />,
    featureFlags: [FeatureFlag.v2Routes],
    subNav: null,
    permissions: [Permissions.ViewRoutes],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.calendar.label,
    link: Paths.calendar.url,
    legacyUrl: Paths.calendar.legacyUrl,
    icon: <FontAwesomeIcon icon={faCalendar} />,
    featureFlags: [FeatureFlag.v2Scheduling],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.fieldReport.label,
    link: Paths.fieldReport.url,
    legacyUrl: Paths.fieldReport.legacyUrl,
    icon: <FontAwesomeIcon icon={faFlask} />,
    featureFlags: [FeatureFlag.v2FieldReport],
    permissions: [Permissions.ViewPayments],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.tasks.label,
    link: Paths.tasks.url,
    legacyUrl: Paths.tasks.legacyUrl,
    icon: <FontAwesomeIcon icon={faList} />,
    featureFlags: [FeatureFlag.v2Scheduling],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.reports.label,
    link: Paths.reports.url,
    legacyUrl: Paths.reports.legacyUrl,
    icon: <FontAwesomeIcon icon={faChartArea} />,
    featureFlags: [FeatureFlag.v2Reports],
    permissions: [Permissions.ViewReports],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.billing.label,
    link: Paths.billing.url,
    legacyUrl: Paths.billing.legacyUrl,
    icon: <FontAwesomeIcon icon={faDollarSign} />,
    featureFlags: [FeatureFlag.v2Billing],
    subNav: null,
    permissions: [Permissions.ViewBilling],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.leads.label,
    link: Paths.leads.url,
    icon: <FontAwesomeIcon icon={faWpforms} />,
    featureFlags: [FeatureFlag.v2Leads],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: 'More',
    link: '',
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faEllipsis} />,
    roles: [ROLES.Office, ROLES.Emulating],
    subNav: [
      {
        id: routeIndex++,
        label: Paths.estimates.label,
        link: Paths.estimates.url,
        legacyUrl: Paths.estimates.legacyUrl,
        icon: <FontAwesomeIcon icon={faCalculator} />,
        permissions: [Permissions.ViewEstimates],
        featureFlags: [FeatureFlag.v2Ots],
        roles: [ROLES.Office, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.inventory.label,
        link: Paths.inventory.url,
        legacyUrl: Paths.inventory.legacyUrl,
        icon: <FontAwesomeIcon icon={faClipboard} />,
        featureFlags: [FeatureFlag.v2InventoryReport],
        permissions: [Permissions.Inventory],
        roles: [ROLES.Office, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.commissions.label,
        link: Paths.commissions.url,
        legacyUrl: Paths.commissions.legacyUrl,
        icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
        featureFlags: [FeatureFlag.v2Commissions],
        permissions: [Permissions.EditViewCommissions],
        roles: [ROLES.Office, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.payments.label,
        link: Paths.payments.url,
        legacyUrl: Paths.payments.legacyUrl,
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        featureFlags: [FeatureFlag.v2Payments],
        permissions: [Permissions.ViewPayments],
        roles: [ROLES.Office, ROLES.Emulating],
      },
    ],
  },

  {
    id: routeIndex++,
    label: 'Setup',
    link: Paths.appSetup.url,
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faLock} />,
    featureFlags: [FeatureFlag.v2Setup],
    roles: [ROLES.Office, ROLES.Emulating],
    permissions: [Permissions.Settings, Permissions.NewsManagement, Permissions.TechMangement],
  },
  {
    id: routeIndex++,
    label: 'Admin',
    link: '',
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faLock} />,
    roles: [ROLES.SuperAdmin, ROLES.Emulating],
    subNav: [
      {
        id: routeIndex++,
        label: Paths.storeManagement.label,
        link: Paths.storeManagement.url,
        icon: <FontAwesomeIcon icon={faShop} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.newsManagementAdmin.label,
        link: Paths.newsManagementAdmin.url,
        icon: <FontAwesomeIcon icon={faNewspaper} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.feedbackManagement.label,
        link: Paths.feedbackManagement.url,
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      styleGuideParentLink,
    ],
  },
];
