import React, { FC, useEffect, useMemo, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Alert,
  ListItemButton,
  useMediaQuery,
} from "@mui/material";
import { formatDate, formatRawDate } from "../../../helpers";
import { DatePickerPopover, TableActionsMenu } from "../../../components";
import { ITechnicianDayOff, ITechnicianUpdatedDayOff, ITechnicianUserDetail } from "../../../models";
import { techDateHasServices } from "../../../fetch";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../../styles";

interface IDaysOffListProps {
  daysOff: ITechnicianDayOff[];
  setDaysOff: (daysOff: ITechnicianDayOff[]) => void;
  isLoadingDaysOff?: boolean;
  deletedDaysOff: string[];
  setDeletedDaysOff: (deletedDaysOff: string[]) => void;
  updatedDaysOff?: ITechnicianUpdatedDayOff[];
  setUpdatedDaysOff?: (updatedDaysOff: ITechnicianUpdatedDayOff[]) => void;
  addedDaysOff: { dayOff: string; hasServices: boolean; }[];
  setAddedDaysOff: React.Dispatch<React.SetStateAction<{
    dayOff: string;
    hasServices: boolean;
  }[]>>;
  hasAssignedServices: boolean;
  setHasAssignedServices: (hasAssignedServices: boolean) => void;
  disabledDates: string[];
}

export const DaysOffList: FC<IDaysOffListProps> = ({
  daysOff,
  setDaysOff,
  deletedDaysOff,
  setDeletedDaysOff,
  updatedDaysOff,
  setUpdatedDaysOff,
  addedDaysOff,
  setAddedDaysOff,
  hasAssignedServices,
  setHasAssignedServices,
  disabledDates
}) => {
  const { values } =
    useFormikContext<ITechnicianUserDetail>();
  const isMobile = useMediaQuery(`(max-width: 567px)`);
  const [selectedDayOff, setSelectedDayOff] = useState<ITechnicianDayOff>();
  const [selectedDayOffIndex, setSelectedDayOffIndex] = useState<number>(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleDatePickerClose = () => {
    setAnchorEl(null);
    setSelectedDayOff(undefined);
  };

  const handleRemoveDayOff = (dayOff: ITechnicianDayOff, index: number) => {
    const updatedDaysOff = [...daysOff];
    updatedDaysOff.splice(daysOff.indexOf(dayOff), 1);
    setDaysOff(updatedDaysOff);
    setDeletedDaysOff([...deletedDaysOff, dayOff.technicianDayOffId]);
  };

  const handleDateChange = async (date: string | Date) => {
    const newDate = formatDate(date);
    const rawDate = formatRawDate(date as Date);

    const newDaysOffArray = [...daysOff];
    newDaysOffArray[selectedDayOffIndex].dayOff = newDate!;
    if (await techDateHasServices(values.userId, rawDate) === true) {
      newDaysOffArray[selectedDayOffIndex].hasServices = true;
    } else {
      newDaysOffArray[selectedDayOffIndex].hasServices = false;
    }

    setDaysOff(newDaysOffArray);
    setUpdatedDaysOff?.([
      ...(updatedDaysOff || []),
      daysOff.filter(
        day => day.technicianDayOffId === selectedDayOff?.technicianDayOffId)[0]
    ]);
    setSelectedDayOff(undefined);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (daysOff.filter(day => day.hasServices).length > 0
      || addedDaysOff.filter(day => day.hasServices).length > 0) {
      setHasAssignedServices(true);
    } else {
      setHasAssignedServices(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysOff, addedDaysOff]);

  const allDaysOff = useMemo(() => {
    const combinedDaysOff = [...daysOff, ...addedDaysOff]
      .sort((a, b) => new Date(b.dayOff).getTime() - new Date(a.dayOff).getTime());
    return combinedDaysOff;
  }, [daysOff, addedDaysOff]);

  return (
    <>
      {hasAssignedServices &&
        <Alert severity="error" sx={{ mt: 1, mb: 1, textAlign: 'left' }}>
          Dates have existing assigned services.
        </Alert>
      }
      <List sx={{ maxHeight: '225px', overflowX: 'auto', }}>
        <>
          {allDaysOff && allDaysOff.map((item: any, index) => {
            if (item.technicianDayOffId) {
              return (
                <ListItem
                  key={item.technicianDayOffId}
                  id={`${item.technicianDayOffId}_item`}
                  sx={{
                    pt: 0,
                    pb: 0,
                    justifyContent: 'space-between',
                    color: item.hasServices ? theme.palette.error.main : undefined,
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                  }}
                >
                  {`${formatDate(item.dayOff)} - ${new Date(item.dayOff).toLocaleDateString('en-US', { weekday: 'long' })}`}
                  <TableActionsMenu
                    labelContext="Days Off"
                    id={`action-menu-${item.technicianDayOffId}`}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ color: theme => theme.palette.primary.main }}
                        title={`Edit day off`}
                        onClick={() => {
                          setAnchorEl(
                            isMobile ? document.body
                              : document.getElementById(`action-menu-${item.technicianDayOffId}-button`)
                          );
                          setSelectedDayOff(item);
                          setSelectedDayOffIndex(index);
                        }}
                      >
                        Edit Day Off
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ color: theme => theme.palette.error.main }}
                        title={`Delete Day Off`}
                        onClick={() => handleRemoveDayOff(item, index)}
                      >
                        Delete Day Off
                      </ListItemButton>
                    </ListItem>
                  </TableActionsMenu>
                </ListItem>
              )
            } else {
              return (
                <ListItem key={index} sx={{
                  color: item.hasServices ? theme.palette.error.main : undefined,
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  textShadow: '.75px 0 0 currentColor'
                }}>
                  {`${item.dayOff} - ${new Date(item.dayOff).toLocaleDateString('en-US', { weekday: 'long' })}`}
                  <ListItemSecondaryAction sx={{ right: '32px' }}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="error"
                      onClick={() => {
                        const updatedAddedDaysOff = [...addedDaysOff];
                        updatedAddedDaysOff.splice(addedDaysOff.indexOf(item.dayOff), 1);
                        setAddedDaysOff(updatedAddedDaysOff);
                      }}
                      sx={{ fontSize: '1.25rem' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            }
          })}
          {(daysOff.length === 0 && addedDaysOff.length === 0) && (
            <ListItem sx={{ textAlign: 'center' }}>
              <ListItemText primary="Tech currently has no days off scheduled." />
            </ListItem>
          )}
          <DatePickerPopover
            defaultDate={new Date(selectedDayOff?.dayOff!)}
            onChange={date => handleDateChange(date!)}
            handleClose={handleDatePickerClose}
            anchorEl={anchorEl}
            disabledDates={disabledDates}
            anchorOrigin={isMobile ? { vertical: 'center', horizontal: 'center' }
              : { vertical: 'top', horizontal: 'right' }
            }
          />
        </>
      </List>
    </>
  );
};