import { FC } from 'react';
import { Page } from '../../components';
import { InventoryList } from './InventoryList';

export const InventoryListPage: FC = () => {
  return (
    <Page title="Inventory">
      <InventoryList />
    </Page>
  );
};
