import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps, TableActionsMenu } from '../../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { unCamelCase, formatDate } from '../../../helpers';
import { IRepair } from '../../../models';
import { format } from 'date-fns';
import { ListItem, ListItemButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

interface OTSVisitsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IRepair[];
  refetch: () => Promise<void>;
  handleDelete: (repairVisitId: string) => void;
  handleEdit: (repairVisitId: string) => void;
  isModal?: boolean;
}

export const OTSVisitsDataGrid: FC<OTSVisitsDataGridProps> = ({
  rows,
  refetch,
  handleDelete,
  handleEdit,
  isModal,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: 'assignedTo',
        headerName: 'Tech',
        flex: 1,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: status } = params;
          return status.status ? unCamelCase(status.status) : '';
        },
      },
      {
        field: 'serviceDate',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: status } = params;
          return status.serviceDate ? <>{formatDate(status.serviceDate)}</> : <>--</>;
        },
      },
      {
        field: 'serviceTime',
        headerName: 'Window',
        minWidth: 150,
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: status } = params;
          if (!status.startTime && !status.endTime) {
            return <></>;
          }
          return (
            <>{`${format(new Date(status.startTime), 'h:mma')} - ${format(
              new Date(status.endTime),
              'h:mma'
            )}`}</>
          );
        },
      },
      {
        field: 'repairNotes',
        headerName: 'Visit Notes',
        minWidth: 125,
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      !isModal && {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        maxWidth: 75,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: visit } = params;
          return (
            <TableActionsMenu labelContext="OTS Visit" id={`action-menu-${visit.repairVisitId}`}>
              <ListItem disablePadding sx={{ color: theme => theme.palette.primary.main }}>
                <ListItemButton color="primary" onClick={() => handleEdit(visit.repairVisitId!)}>
                  <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                  Edit Visit
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.error.main }}
                  onClick={() => handleDelete(visit.repairVisitId!)}
                  disabled={visit.status === 'Closed' ? true : false}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
                  Delete Visit
                </ListItemButton>
              </ListItem>
            </TableActionsMenu>
          );
        },
      },
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isModal]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IRepair) => row.repairVisitId!}
      rows={rows.filter(x => x.accountId !== null)}
      columns={columns as any[]}
      disableColumnSelector
      disableColumnFilter
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      hasMobileLayout
      mobileProps={{
        showHandleActions: true,
      }}
      {...gridProps}
    />
  );
};
