import { Box, Grid, Link, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { Select } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { ISelectOption } from '../../../models';
import { getNumberRange } from '../../../helpers';
import { StyleGuideValidationCallout } from '../validation-callout';

export const StyleGuideSelectField = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const options: ISelectOption[] = (() => {
    const values = getNumberRange(0, 23, 1)?.map(val => {
      return { label: `Option ${val}`, value: `${val}` };
    });
    return values ?? [];
  })();

  return (
    <StyleGuidePage title="Select">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Select component is a custom build upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#select" target="_blank">
              MUI Text Field
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has a built-in styles, and Formik props required for use with a Formik form and Yup
            form validation.
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be used specifically on forms that utilize Formik.
            For things like filters, you can use the standard MUI Text Field component with the{' '}
            <em>select</em> prop, or other custom filter components defined in the project.
          </Typography>
          <StyleGuideValidationCallout />
          <code>
            Props:
            <br />- name: string
            <br />- children: ReactNode
            <br />- hasClear?: boolean
            <br />- onClear?: () =&gt; void
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '1',
          testField2: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box mb={1} p={0.5}>
                    <Select label="Test Field" name="testField">
                      {options.map(option => (
                        <MenuItem key={`${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;Select
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Test Field"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField"
                    <br />
                    &gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&#123;options.map(option =&gt; ( <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;MenuItem
                    key=&#123;`$&#123;option.value&#125;`&#125; value=&#123;option.value&#125;&gt;
                    &#123;option.label&#125; &lt;/MenuItem&gt; <br />
                    &nbsp;&nbsp;&nbsp;))&#125; <br />
                    &lt;/Select&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={1} p={0.5}>
                    <Select
                      label="Test Field 2"
                      name="testField2"
                      SelectProps={{
                        displayEmpty: true,
                        defaultValue: values.testField,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">All</MenuItem>
                      {options.map(option => (
                        <MenuItem key={`${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;Select
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Test Field 2"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField2"
                    <br />
                    &nbsp;&nbsp;&nbsp;SelectProps=&#123;&#123; displayEmpty: true, defaultValue:
                    values.testField &#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;InputLabelProps=&#123;&#123; shrink: true &#125;&#125; <br />
                    &gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;MenuItem value=""&gt;All&lt;/MenuItem&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&#123;options.map(option =&gt; ( <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;MenuItem
                    key=&#123;`$&#123;option.value&#125;`&#125; value=&#123;option.value&#125;&gt;
                    &#123;option.label&#125; &lt;/MenuItem&gt; <br />
                    &nbsp;&nbsp;&nbsp;))&#125; <br />
                    &lt;/Select&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
