import { FC } from 'react';
import { MenuItem, TextField, Box, Chip, StandardTextFieldProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { useField, useFormikContext, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { ILookupModel } from '../../models/util';

interface SelectChipProps extends StandardTextFieldProps {
  name: string
  options: ILookupModel[]
};

export const SelectChip: FC<SelectChipProps> = ({
  name,
  options,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { value } = target;
    const newChip = options.find((chip) => chip.description === value);
    const chips = meta.value.concat(newChip)
    setFieldTouched(name, true, true);
    setFieldValue(name, chips)
  };

  const handleDelete = (event: React.SyntheticEvent, chipText: string): void => {
    const newChips = meta.value.filter((chip: ILookupModel) => chip.description !== chipText);
    setFieldTouched(name, true, true);
    setFieldValue(name, newChips)
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const chips = [...meta.value]

    const [removed] = chips.splice(result.source.index, 1);
    chips.splice(result.destination.index, 0, removed);

    setFieldValue(name, chips);
  }

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    ...field,
    ...props,
    select: true,
    onChange: handleChange,
  }

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <FieldArray name={name}>{() => (
      <>
        <TextField { ...config } value="">
          {options.map((field) => {
            // If chip has already been selected, do not show chip in select drop down
            if (meta.value.find((chip: ILookupModel) => chip.value === field.value)) return null;

            return (
              <MenuItem key={`${name}-${field.value}`} value={field.description}>
                {field.description}
              </MenuItem>
            );
          })}
        </TextField>
        <Box mt={2}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`${name}-droppable`} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
              {meta.value.map((chip: ILookupModel, index: number) => (
                <Draggable key={chip.value} draggableId={chip.value} index={index}>
                {(provided, snapshot) => (
                  <span
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Chip key={chip.value} label={`${index+1} - ${chip.description}`} className={clsx(classes.draggableChip, snapshot.isDragging && classes.draggingChip)} onDelete={(event) => handleDelete(event, chip.description)} sx={{ mr: 2, mb: 1 }} />
                  </span>
                )}
                </Draggable>
              ))}
              {provided.placeholder}
              </div>
            )}
            </Droppable>
          </DragDropContext>
        </Box>
      </>
    )}
    </FieldArray>
  )
};

const useStyles = makeStyles<Theme>(theme => ({
  draggableChip: {
    cursor: 'grab',
  },
  draggingChip: {
    backgroundColor: theme.palette.secondary.light,
  },
}));
