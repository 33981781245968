import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../../components';
import { ITechnicianUser } from '../../../models';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

interface ITechManagementDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: ITechnicianUser[];
  refetch: () => Promise<void>;
  handleEdit: (tech: ITechnicianUser) => void;
  handleDelete: (tech: ITechnicianUser) => void;
}

export const TechManagementDataGrid: FC<ITechManagementDataGridProps> = ({
  rows,
  refetch,
  handleEdit,
  handleDelete,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'userName',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        flex: 1,
        minWidth: 150,
        type: 'string',
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ITechnicianUser>) => {
          const { row: tech } = params;

          return (
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box display="flex" gap={1} className={clsx('print--none')}>
                <IconButton
                  color="primary"
                  title={`Edit Tech User: ${tech.userName}`}
                  onClick={() => {
                    handleEdit(tech);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
                <IconButton
                  color="error"
                  title={`Delete Tech User: ${tech.userName}`}
                  onClick={async () => {
                    handleDelete(tech);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              </Box>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: ITechnicianUser) => row.userId}
      rows={rows}
      columns={columns}
      {...gridProps}
    />
  );
};
