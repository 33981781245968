import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';
// fetch
import { updateAccountClassDef, createAccountClassDef } from '../../../fetch';

import { IAccountClass } from '../../../models';

interface IAddEditAccountClassDefsModal {
  open: boolean;
  onClose: () => void;
  currentAccountClassDef?: IAccountClass | null;
  fetchAccountClassDefs: () => void;
}

const Schema = Yup.object().shape({
  accountClassDefId: Yup.string(),
  code: Yup.string().required('Required').max(20),
  description: Yup.string().required('Required').max(100),
  sortOrder: Yup.number().max(10000, 'Sort Order should not be more than 10000'),
});

export const AddEditAccountClassDefsModal: FC<IAddEditAccountClassDefsModal> = ({
  open,
  onClose,
  currentAccountClassDef,
  fetchAccountClassDefs,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          accountClassDefId: currentAccountClassDef?.accountClassDefId ?? '',
          code: currentAccountClassDef?.code ?? '',
          description: currentAccountClassDef?.description ?? '',
          officeId: user?.officeId ?? '',
          sortOrder: currentAccountClassDef?.sortOrder ?? 0,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
          };
          try {
            currentAccountClassDef
              ? await updateAccountClassDef(data)
              : await createAccountClassDef(data);
            enqueueSnackbar(
              currentAccountClassDef
                ? 'Successfully updated Account Class!'
                : 'Successfully created Account Class!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchAccountClassDefs();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Account Class, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentAccountClassDef ? 'Edit Account Class' : 'Add Account Class'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number" required />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
