import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';

interface ICircleProgress {
  className?: string;
  color?: string;
  size?: string;
}

export const CircleProgress: FC<ICircleProgress> = ({ className, color, size }) => {
  const classes = useStyles({ color });
  return (
    <CircularProgress
      className={clsx(classes.progress, className)}
      size={size === 'large' ? 18 : size === 'small' ? 10 : 14}
      variant="indeterminate"
      data-testid="circle-progress"
    />
  );
};

const useStyles = makeStyles<Theme, { color?: string }>((theme: Theme) => {
  return createStyles({
    progress: {
      color: ({ color }) =>
        color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main,
      margin: theme.spacing(0, 1, 0, 0),
    },
  });
});
