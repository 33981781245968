import { Page } from '../../../components';
import { FieldReportDetails } from './field-reports-details';

export const FieldReportPage = () => {
  return (
    <Page title="Field Report">
      <FieldReportDetails />
    </Page>
  );
};
