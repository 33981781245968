import { Page } from '../../components';
import { ServiceRoutesPageDetails } from './service-routes-page-details';

export const ServiceRoutes = () => {
  return (
    <Page title="Service Routes" hasOverflow>
      <ServiceRoutesPageDetails />
    </Page>
  );
};
