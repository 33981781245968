import { FC, useCallback, useContext } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  ServerSideDataGrid,
  useDataGrid,
  Card,
} from '../../components';
import { ILead } from '../../models';
import { getLeads } from '../../fetch';

import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { newLeadsColumns } from './new-leads-columns';
import { UserContext } from '../../context';
import { Button } from '@mui/material';

export const NewLeadsGrid: FC = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const dataFetcher: GridDataFetcher<ILead> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const params = {
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'date',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
          statuses: ['New'],
        };

        const res = await getLeads(params);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading leads, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    isLoading,
    rows,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<ILead>({
    initialOptions: {
      page: 0,
      pageSize: 5,
      gridKeyName: 'new-leads-grid',
      sortColumn: 'date',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        title="New Leads"
        action={
          <Button
            onClick={() => history.push('/leads/new')}
            startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            className={clsx('print--none')}
            color="secondary"
            size="small"
          >
            Add New Lead
          </Button>
        }
      />
      <ServerSideDataGrid
        disableColumnFilter
        loading={isLoading}
        disableColumnSelector
        autoHeight
        getRowId={(row: ILead) => row.leadId}
        rows={rows}
        columns={newLeadsColumns}
        sortModel={sortModel}
        page={page}
        pageSize={perPage}
        rowCount={recordCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
      />
    </Card>
  );
};
