import { Box, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateRangePicker } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { ICalendarDateRange, IDateRange } from '../../../models';
import { format } from 'date-fns';

export const StyleGuideDateRangePicker = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [value, setValue] = useState<string>('');
  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>(
    undefined
  );

  useEffect(() => {
    let formattedStartDate = '';
    let formattedEndDate = '';

    if (selectedDateRange) {
      formattedStartDate = format(selectedDateRange.startDate as Date, 'M/d/yyyy');
      formattedEndDate = format(selectedDateRange.endDate as Date, 'M/d/yyyy');
      setValue(`${formattedStartDate} - ${formattedEndDate}`);
    }
  }, [selectedDateRange, value]);
  return (
    <StyleGuidePage title="Date Range Picker">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Date Range Picker component is built upon the{' '}
            <Link href="https://github.com/hypeserver/react-date-range" target="_blank">
              React Date Range
            </Link>{' '}
            component. It has a built-in styles and a calendar picker.
          </Typography>
          <code>
            Props:
            <br />- value: IDateRange | null
            <br />- onChange: (value: IDateRange) =&gt; void
            <br />- id: string
            <br />- className?: string
            <br />- placeholder?: string
            <br />- inputSize?: 'medium' | 'small' | undefined
            <br />- fullWidth?: boolean
            <br />- showClear?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <DateRangePicker
              id="test-date-range"
              value={{
                key: 'selection',
                startDate: selectedDateRange?.startDate || null,
                endDate: selectedDateRange?.endDate || null,
                inputValue: value,
              }}
              onChange={(value: IDateRange) => {
                if (!value.startDate || !value.endDate) {
                  return;
                }

                setSelectedDateRange({
                  startDate: value.startDate,
                  endDate: value.endDate,
                });
              }}
              inputSize="medium"
              placeholder="Select a Date Range"
              showClear
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;DateRangePicker
            <br />
            &nbsp;&nbsp;&nbsp;id="test-date-range"
            <br />
            &nbsp;&nbsp;&nbsp;value=&#123;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;key: 'selection',
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;startDate: selectedDateRange?.startDate || null,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;endDate: selectedDateRange?.endDate || null,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inputValue: value, <br />
            &nbsp;&nbsp;&nbsp;&#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;onChange=&#123;(value: IDateRange) =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (!value.startDate || !value.endDate) &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setSelectedDateRange(&#123; startDate:
            value.startDate, endDate: value.endDate, &#125;);
            <br />
            &nbsp;&nbsp;&nbsp; &#125;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;inputSize="medium"
            <br />
            &nbsp;&nbsp;&nbsp;placeholder="Select a Date Range"
            <br />
            &nbsp;&nbsp;&nbsp;showClear
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
