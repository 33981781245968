import { Page } from '../../components';
import { NewsCategories } from './news-categories';

export const NewsPage = () => {
  return (
    <Page title="What's New">
      <NewsCategories />
    </Page>
  );
};
