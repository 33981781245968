import { axios } from '../helpers';
import {
  ICreateScheduledService,
  IResponse,
  IScheduledServiceDetail,
  IServiceSuggestionChecklist,
  IServiceSuggestionResponse,
  ServiceSuggestionType,
  IScheduledServiceLog,
  IUpdateScheduledService,
  IPaginatedResponse,
  IScheduledService,
} from '../models';
import qs from 'qs';
import { userSignal } from '../signals';

export const getScheduledServices = async (filters?: {
  userIds?: string;
  startDate?: string;
  endDate?: string;
  sortBy?: string;
  status?: string[] | string;
  sortDirection?: string;
  siteId?: string;
  first?: boolean;
  last?: boolean;
  before?: string;
  after?: string;
  perPage?: number;
  officeId?: string;
  serviceDefId?: string;
  accountId?: string;
  userGroupId?: string;
}): Promise<IPaginatedResponse<IScheduledService>> => {
  try {
    const { data } = await axios.get(`/api/scheduled-services`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getScheduledService = async (
  id: string | number,
  officeId?: string | null
): Promise<IScheduledServiceDetail | any> => {
  try {
    const { data } = await axios.get(`/api/scheduled-services/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateScheduledServices = async (
  id: number | string,
  payload: IUpdateScheduledService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`/api/scheduled-services/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createScheduledServices = async (
  payload: ICreateScheduledService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.post(`/api/scheduled-services`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRouteSuggestions = async (filters?: {
  routeSuggestionType: ServiceSuggestionType;
  siteId: string;
  officeId?: string | null;
  startDate: string;
  endDate?: string;
  excludeScheduledServiceId?: string | null;
  excludeRecurringServiceId?: string | null;
}): Promise<IServiceSuggestionResponse> => {
  try {
    const { data } = await axios.get(`/api/scheduled-services/suggestions`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getScheduledServiceChecklist = async (
  serviceId: string | number,
  serviceDefId: string | number,
  officeId?: string | null
): Promise<IServiceSuggestionChecklist[]> => {
  try {
    const { data } = await axios.get(
      `/api/scheduled-services/${serviceId}/checklist?serviceDefId=${serviceDefId}`,
      {
        params: {
          officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getScheduledServiceServiceLog = async (
  serviceId: string | number,
  officeId?: string | null
): Promise<IResponse<IScheduledServiceLog[]>> => {
  try {
    const { data } = await axios.get(`/api/scheduled-services/${serviceId}/service-logs`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createScheduledServiceServiceLog = async (
  serviceId: string | number,
  description: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.post(`/api/scheduled-services/${serviceId}/service-logs`, {
      description,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateScheduledServiceServiceLog = async (
  serviceId: string | number,
  serviceLogId?: string | number,
  description?: string,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.put(
      `/api/scheduled-services/${serviceId}/service-logs/${serviceLogId}`,
      {
        description,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteScheduledService = async (
  serviceId: string | number | undefined
): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/scheduled-services/${serviceId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
