import { useContext, useMemo, useState } from 'react';
import { Loader, Card, IconPageHeading, Tabs, ITab } from '../../components';
import { SearchContext } from '../../context';
import { SearchTypes, TSearchType } from '../../models';

import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BrandingContext } from '../../context/branding-context';
import { CustomerSearchResults } from './customer-search-results';
import { SiteSearchResults } from './site-search-results';
import { WaterTestCustomerSearchResults } from './water-test-customer-results';

export const SearchDetails = () => {
  const { waterTestSearch } = useFlags();
  const { isPoolService } = useContext(BrandingContext);

  const { results, recordCount, isLoadingSearchResults, page, perPage } = useContext(SearchContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const activeTab = queryParams.get('activeTab');

  const assembleTabList = useMemo(() => {
    let newTabList = [
      {
        label: 'Customers',
        value: 'customers',
        sort: results?.accounts?.totalRecordCount ?? 0,
      },
      {
        label: 'Sites',
        value: 'sites',
        sort: results?.sites?.totalRecordCount ?? 0,
      },
      waterTestSearch &&
        isPoolService && {
          label: 'Pool 360 Water Test Customers',
          value: 'waterTestAccounts',
          sort: results?.waterTestAccounts?.totalRecordCount ?? 0,
        },
    ].filter(Boolean);
    // remove tab options that have no results
    return newTabList.filter(tab => !!tab.sort);
  }, [results, waterTestSearch, isPoolService]);

  const setDefaultTab = useMemo(() => {
    const isSearchType = (x: any): x is TSearchType => SearchTypes.includes(x);
    if (activeTab && isSearchType(activeTab)) {
      return activeTab as TSearchType;
    }
    if (assembleTabList && assembleTabList.length > 0 && isSearchType(assembleTabList[0]?.value)) {
      return assembleTabList[0]?.value as TSearchType;
    }
    return 'customers';
  }, [activeTab, assembleTabList]);

  const [selectedTab, setSelectedTab] = useState<TSearchType>(setDefaultTab);

  const redirect = useMemo(() => {
    const root = `/search?query=${query}`;
    const type = `&activeTab=${selectedTab ?? activeTab}`;
    const params = encodeURIComponent(`${root}${type}`);
    return `redirect=${params}` ?? null;
  }, [activeTab, selectedTab, query]);

  const renderResultTabContent = (tab: TSearchType) => {
    if (tab === 'customers' && !!results) {
      return <CustomerSearchResults results={results} page={page} perPage={perPage} />;
    }
    if (tab === 'sites' && !!results) {
      return <SiteSearchResults results={results} page={page} perPage={perPage} />;
    }
    if (tab === 'waterTestAccounts' && !!results) {
      return (
        <WaterTestCustomerSearchResults
          results={results}
          page={page}
          perPage={perPage}
          redirect={redirect}
        />
      );
    }
    return null;
  };

  const renderResultTabs = () => {
    if (!!assembleTabList) {
      return assembleTabList.map(tab => {
        const label = `${tab.label} (${tab.sort})`;
        return {
          key: tab.value,
          title: label,
          mobileTitle: label,
          children: renderResultTabContent(tab.value as TSearchType),
        };
      });
    }
    return [];
  };

  return (
    <>
      <IconPageHeading
        icon={faSearch}
        showHome={false}
        showDivider={false}
        heading={`${isLoadingSearchResults ? '' : recordCount + ' '}Results for "${query}"`}
      />
      <Box mt={2}>
        <Card>
          {isLoadingSearchResults && (
            <Box height="5rem">
              <Loader position="centered" type="inline" />
            </Box>
          )}
          {!isLoadingSearchResults && recordCount === 0 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="5rem"
            >
              <Typography>There are no search results to display.</Typography>
            </Box>
          )}
          {!isLoadingSearchResults && recordCount > 0 && (
            <Box mt={-1} mb={-1}>
              <Tabs
                id="search-result-tabs"
                printAllTabs
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  setSelectedTab(val as TSearchType);
                }}
                tabs={renderResultTabs().filter(Boolean) as ITab[]}
              />
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
};
