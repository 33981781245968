import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import {
  CardTitle,
  Card,
  useDataGrid,
  GridDataFetcher,
  CardFiltersLayout,
  FilterButtons,
  Link,
} from '../../../components';
import { IDropdownResponse, IEmailTemplate } from '../../../models';
import { getEmailTemplateEventTypes, getEmailTemplates } from '../../../fetch';
import { EmailTemplatesDataGrid } from './email-templates-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faFilter,
  faFilterCircleXmark,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { UserContext } from '../../../context';

export const EmailTemplatesListPageDetails: FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [eventTypeFilterOption, setEventTypeFilterOption] = useState<string>('');
  const [appliedTypeFilterOption, setAppliedTypeFilterOption] = useState<string>('');
  const { user } = useContext(UserContext);

  const { isLoading: isLoadingEventTypes, data: eventTypesData } = useQuery<IDropdownResponse[]>(
    ['getEmailTemplateEventTypes'],
    () => getEmailTemplateEventTypes()
  );
  const templateEventTypes = useMemo(() => eventTypesData || [], [eventTypesData]);

  const dataFetcher: GridDataFetcher<IEmailTemplate> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getEmailTemplates({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          event: eventTypeFilterOption,
          officeId: user?.officeId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading email templates, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedTypeFilterOption]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IEmailTemplate>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'email-templates-grid',
      sortColumn: 'emailTemplateEventDescription',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        cardTitleWrapperClassName={classes.cardTitle}
        action={
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              onClick={() => setIsShowingFilters(!isShowingFilters)}
              startIcon={
                <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
              }
              className={clsx('print--none')}
              color="secondary"
              size="small"
              disabled={isLoading}
              data-testid="filters-button"
            >
              Filters
            </Button>
            <Button
              color="secondary"
              size="small"
              component={forwardRef((props: any, _ref) => {
                return <Link {...props} />;
              })}
              type="white"
              to={`/setup/email-templates/new`}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              data-testid="add-email-template-button"
            >
              Add Email Template
            </Button>
          </Box>
        }
      >
        <CardFiltersLayout isOpen={isShowingFilters}>
          <Grid container spacing={2} className="print--none">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                label="Event Type"
                size="small"
                onChange={e => {
                  setEventTypeFilterOption(e.target.value);
                }}
                value={eventTypeFilterOption}
                disabled={isLoadingEventTypes}
                SelectProps={{
                  inputProps: {
                    'data-testid': 'event-type-select'
                  },
                  endAdornment: eventTypeFilterOption && (
                    <InputAdornment
                      position="end"
                      sx={{
                        position: 'absolute',
                        right: 35,
                        cursor: 'pointer',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        title="Clear"
                        onClick={() => setEventTypeFilterOption('')}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {templateEventTypes &&
                  templateEventTypes
                    .sort((a, b) => (a.description > b.description ? 1 : -1))
                    .map(templateEventType => {
                      return (
                        <MenuItem
                          key={`${templateEventType.value}`}
                          value={templateEventType.value}
                        >
                          {templateEventType.description}
                        </MenuItem>
                      );
                    })}
              </TextField>
            </Grid>
            <Grid item>
              <FilterButtons
                hasAppliedFilters={!!appliedTypeFilterOption}
                isDisabled={isLoadingEventTypes || isLoading}
                handleApplyFilters={() => {
                  setAppliedTypeFilterOption(eventTypeFilterOption);
                }}
                handleResetFilters={() => {
                  setEventTypeFilterOption('');
                  setAppliedTypeFilterOption('');
                }}
              />
            </Grid>
          </Grid>
        </CardFiltersLayout>
      </CardTitle>
      <Box marginTop="1rem">
        <EmailTemplatesDataGrid
          loading={isLoading}
          rows={rows}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          refetch={refetch}
        />
      </Box>
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  cardTitle: {
    marginBottom: 0,
    '& > .MuiBox-root': {
      justifyContent: 'flex-end',
    },
  },
}));
