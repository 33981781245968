import { FC, useContext, useState, useMemo, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { faCartShopping, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, Button, Divider, useMediaQuery } from '@mui/material';
import { Loader, CardTitle } from '../../components';
import { deleteEstimateLineItem, generateEstimateSalesTax } from '../../fetch';
import {
  IEstimateForm,
  IEstimateLineItem,
  IEstimateSalesTaxPost,
  IPool360LineItem,
} from '../../models';
import { EstimateLineItemModal } from './estimate-line-item-modal';
import { useConfirm } from '../../hooks';
import { EstimateLineItemsDataGrid } from './estimate-line-items-data-grid';
import { GridSortModel } from '@mui/x-data-grid';
import { EstimateLaborRateModal } from './estimate-labor-rate-modal';
import { UserContext } from '../../context';
import { Pool360CartModal } from './pool-360-cart-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IEstimateLineItemsProps {
  estimateId: string | null;
  estimateLineItems: IEstimateLineItem[];
  fetchEstimateLineItems: () => Promise<void>;
  setEstimateLineItems: (val: IEstimateLineItem[]) => void;
  isLoading: boolean;
  recordCount: number;
  page: number;
  pageSize: number;
  sortModel: GridSortModel;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  estimate: IEstimateForm;
  accountId: string | null;
  isPoolService?: boolean;
}

export const EstimateLineItems: FC<IEstimateLineItemsProps> = ({
  estimateId,
  estimateLineItems,
  fetchEstimateLineItems,
  isLoading,
  recordCount,
  setEstimateLineItems,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  sortModel,
  estimate,
  accountId,
  isPoolService,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeLineItemId, setActiveLineItemId] = useState<string>('');
  const [isEstimateModalOpen, setIsEstimateModalOpen] = useState<boolean>(false);
  const [isLaborModalOpen, setIsLaborModalOpen] = useState(false);
  const [laborItemId, setLaborItemId] = useState<string | null>(null);
  const [isPool360CartOpen, setPool369CartOpen] = useState(false);
  const { pool360CartIntegration } = useFlags();
  const isMobile = useMediaQuery(`(max-width: 567px)`);
  const [estimateLineItemVariables, setEstimateLineItemVariables] = useState<IEstimateLineItem[]>(
    []
  );
  const laborTypeVariable = 'Variable';

  const pool360LineItems = useMemo(() => {
    return estimateLineItems.filter(
      item =>
        !item.isLaborLineItem && item.tranCodeDescription !== 'Tax' && item.poolCommerceInventoryId
    );
  }, [estimateLineItems]);

  useEffect(() => {
    const variables = estimateLineItems.map(item => ({
      estimateLineItemId: item.estimateLineItemId!,
      details: item.details!,
      laborFeeType: item.laborFeeType,
      initialLaborFeesDuration:
        item.laborFeeType === laborTypeVariable
          ? textToNumber(item.initialLaborFeesDuration!)
          : item.initialLaborFeesDuration,
      initialLaborFees: item.initialLaborFees,
      estimatedLaborDuration: item.estimatedLaborDuration!,
      additionalLaborFees: item.additionalLaborFees!,
      amount: item.amount!,
      sortOrder: item.sortOrder,
      tranCodeId: item.tranCodeId,
      serialNumber: item.serialNumber,
      isLaborLineItem: item.isLaborLineItem,
      rate: item.rate,
      quantity: item.quantity,
    }));
    setEstimateLineItemVariables(variables);
  }, [estimateLineItems]);

  const textToNumber = (text: string) => {
    if (text === 'Hour') {
      return '1';
    }
    const startIndex = 0;
    const endIndex = text.indexOf('Minutes');
    const numberText = text.slice(startIndex, endIndex);
    switch (numberText) {
      case 'Fifteen':
        return '15';
      case 'Thirty':
        return '30';
    }
  };

  // Code for handling manual calculation of sales tax, specifically for new estimates
  const { user } = useContext(UserContext);
  const fetchSalesTax = async (lineItems: IEstimateLineItem[]) => {
    try {
      const payload: IEstimateSalesTaxPost = {
        lineItems:
          lineItems
            .filter(item => item.tranCodeDescription !== 'Tax')
            .map(item => ({
              amount: Number(item.amount),
              storeInventoryId: item.inventoryId ?? null,
            })) ?? null,
        officeId: user?.officeId ?? null,
        accountId: accountId ?? null,
      };
      const res = await generateEstimateSalesTax(payload);
      return res;
    } catch (err: any) {
      enqueueSnackbar(err?.res?.data?.Detail || `Error loading sales tax. Please try again.`, {
        variant: 'error',
      });
    }
  };
  const handleSalesTax = async (lineItems: IEstimateLineItem[]) => {
    if (!estimateId || estimateId === 'new') {
      const filteredItems = lineItems.filter(item => item.tranCodeDescription !== 'Tax');
      const res = await fetchSalesTax(filteredItems);
      if (res) {
        const taxItem = {
          estimateLineItemId: 'new-tax-item',
          sortOrder: 1001,
          tranCodeId: '',
          tranCodeDescription: 'Tax',
          details: '',
          serialNumber: '',
          rate: res.rate,
          quantity: 0,
          amount: Number(res?.amount) ?? 0,
          isLaborLineItem: false,
        };
        return [...filteredItems, taxItem];
      }
      return lineItems;
    }
    return lineItems;
  };

  const handleCloseModal = (shouldUpdate?: boolean) => {
    setActiveLineItemId('');
    setIsEstimateModalOpen(false);
    setIsLaborModalOpen(false);
    setLaborItemId(null);
    if (shouldUpdate) {
      fetchEstimateLineItems();
    }
  };

  const handleEdit = (lineItem: IEstimateLineItem) => {
    if (lineItem.isLaborLineItem) {
      setIsLaborModalOpen(true);
      setLaborItemId(lineItem.estimateLineItemId);
    } else {
      setActiveLineItemId(lineItem.estimateLineItemId);
      setIsEstimateModalOpen(true);
    }
  };

  const handleDelete = async (lineItem: IEstimateLineItem) => {
    try {
      const result = await confirm('Are you sure you want to delete this line item?');
      if (result) {
        if (!estimateId) {
          const filteredItems = await handleSalesTax(
            estimateLineItems.filter(
              item => item.estimateLineItemId !== lineItem.estimateLineItemId
            )
          );
          setEstimateLineItems(filteredItems);
          enqueueSnackbar(`Line item deleted!`, {
            variant: 'success',
          });
        } else {
          setIsDeleting(true);
          await deleteEstimateLineItem(estimateId, lineItem.estimateLineItemId);
          enqueueSnackbar(`Line item deleted!`, {
            variant: 'success',
          });
          setIsDeleting(false);
          await fetchEstimateLineItems();
        }
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting line item, please try again.`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <CardTitle
        title="Line Items"
        mobileWrap
        action={
          estimate?.hasEstimateAgreementBeenSigned ? (
            <Typography color="secondary" fontSize=".9rem" fontWeight="bold">
              Agreement Signed
            </Typography>
          ) : (
            <>
              {isPoolService && pool360CartIntegration && (
                <>
                  <Button
                    onClick={() => {
                      setPool369CartOpen(true);
                    }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faCartShopping} size="lg" />}
                    disabled={pool360LineItems?.length === 0}
                    sx={{ padding: '4px 10px' }}
                    data-testid="order-from-pool-button"
                  >
                    Order from Pool 360
                  </Button>
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  setActiveLineItemId('');
                  setIsEstimateModalOpen(true);
                }}
                disabled={isLoading || isDeleting}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                data-testid="add-item-button"
              >
                Add Item
              </Button>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setIsLaborModalOpen(true);
                }}
                disabled={isLoading || isDeleting}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                data-testid="add-labor-button"
              >
                Add Labor
              </Button>
            </>
          )
        }
      />
      {isDeleting && <Loader type="overlay" position="centered" title="Loading" />}
      {!isLoading &&
        estimateLineItems?.length > 0 &&
        estimateLineItems.filter(item => item.tranCodeDescription !== 'Tax')?.length > 0 && (
          <EstimateLineItemsDataGrid
            loading={isLoading}
            rows={estimateLineItems}
            page={page}
            pageSize={pageSize}
            sortModel={sortModel}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
            rowCount={recordCount}
            refetch={fetchEstimateLineItems}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            estimate={estimate}
          />
        )}
      {isLoading && (
        <Box height="10rem">
          <Loader position="centered" type="inline" />
        </Box>
      )}
      <Box
        sx={{
          width: '70%',
          maxWidth: '1000px',
          padding: 2,
          textAlign: 'left',
        }}
      >
        {estimateLineItemVariables.map(item =>
          item.laborFeeType === laborTypeVariable ? (
            <Typography
              key={item.estimateLineItemId}
              sx={{
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 'bold',
                marginBottom: '10px',
                width: '100%',
                overflowWrap: 'break-word',
              }}
            >
              *{item.details} - Initial ${Number(item.initialLaborFees).toFixed(2).toString()} for
              the first{' '}
              {item.initialLaborFeesDuration! === '1' ? '' : item.initialLaborFeesDuration!}{' '}
              {item.initialLaborFeesDuration! === '1' ? 'hour' : 'Minutes'}.
              {Number(item.additionalLaborFees) > 0 && (
                <>
                  {' '}
                  Thereafter, ${Number(item.additionalLaborFees).toFixed(2).toString()} per quarter
                  hour rounded to the next quarter hour.
                </>
              )}{' '}
              The above services are estimated to require {item.estimatedLaborDuration}{' '}
              {item.estimatedLaborDuration === 1 ? 'hr' : 'hrs'}.
            </Typography>
          ) : null
        )}
      </Box>
      {!isLoading &&
        (estimateLineItems?.length === 0 ||
          !estimateLineItems ||
          estimateLineItems.filter(item => item.tranCodeDescription !== 'Tax')?.length === 0) && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="10rem"
          >
            <Typography>No line items found.</Typography>
          </Box>
        )}
      {isEstimateModalOpen && (
        <EstimateLineItemModal
          estimateId={estimateId}
          estimateLineItemId={activeLineItemId}
          isOpen={true}
          onClose={handleCloseModal}
          estimateLineItems={estimateLineItems}
          setEstimateLineItems={setEstimateLineItems}
          totalLineItems={!!estimateId ? recordCount : estimateLineItems?.length}
          isEditable={!estimate?.hasEstimateAgreementBeenSigned}
          handleSalesTax={handleSalesTax}
        />
      )}
      <EstimateLaborRateModal
        isOpen={isLaborModalOpen}
        onClose={handleCloseModal}
        estimateId={estimateId!}
        lineItemId={laborItemId}
        estimateLineItems={estimateLineItems}
        setEstimateLineItems={setEstimateLineItems}
        fetchEstimateLineItems={fetchEstimateLineItems}
        isEditable={!estimate?.hasEstimateAgreementBeenSigned}
        handleSalesTax={handleSalesTax}
      />
      {isPoolService && pool360CartIntegration && (
        <Pool360CartModal
          rows={pool360LineItems as IPool360LineItem[]}
          isOpen={isPool360CartOpen}
          onClose={() => setPool369CartOpen(false)}
        />
      )}
    </>
  );
};
