import { useFormikContext } from 'formik';
import { Grid, Stack, Box, FormControlLabel, IconButton, FormHelperText } from '@mui/material';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PhoneTextField,
  TextField,
  SelectAsync,
  Checkbox,
  AddressAutocomplete,
} from '../../components';
import { FC, useContext } from 'react';
import { getUsers, getLeadSources, getLeadServiceTypes } from '../../fetch';
import { IDropdownResponse, IListUser, ILeadDetail, ILeadPost, IResponse } from '../../models';
import { UserContext } from '../../context';
import { RelatedEstimatesCard } from './related-estimates-card';
import { Permissions } from '../../constants';
import { hasCorrectUserPermissions } from '../../helpers';
import { QueryObserverResult } from 'react-query';

interface ILeadDetailFormFieldsProps {
  fieldContext?: string;
  isNewLead?: boolean;
  handleCopyClick?: (val: string) => void;
  isCopied?: {
    [val: string]: boolean;
  };
  leadId?: string;
  leadDetail?: ILeadDetail | null;
  refetchLead?: () => Promise<QueryObserverResult<ILeadDetail, Error>>;
  handleSave?: (val: ILeadPost) => Promise<void>;
}

export const LeadDetailFormFields: FC<ILeadDetailFormFieldsProps> = ({
  fieldContext,
  isNewLead = true,
  handleCopyClick,
  isCopied,
  leadId,
  leadDetail,
  refetchLead,
  handleSave,
}) => {
  const { user } = useContext(UserContext);
  const { values, setSubmitting, setFieldValue } = useFormikContext<any>();
  const address = {
    street:
      (fieldContext && values?.[fieldContext]?.addressModel?.address) ||
      values.addressModel?.address ||
      '',
    city:
      (fieldContext && values?.[fieldContext]?.addressModel?.city) ||
      values.addressModel?.city ||
      '',
    state:
      (fieldContext && values?.[fieldContext]?.addressModel?.state) ||
      values.addressModel?.state ||
      '',
    postalCode:
      (fieldContext && values?.[fieldContext]?.addressModel?.postalCode) ||
      values.addressModel?.postalCode ||
      '',
  };
  return (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      <Grid item xs={12} lg={4}>
        <Stack spacing={2}>
          <TextField
            name={fieldContext ? `${fieldContext}.customerFirstName` : 'customerFirstName'}
            label="First Name"
            required
          />
          <TextField
            name={fieldContext ? `${fieldContext}.customerLastName` : 'customerLastName'}
            label="Last Name"
            required
          />
          <Box display="flex">
            <TextField
              name={fieldContext ? `${fieldContext}.customerEmail` : 'customerEmail'}
              label="Email"
              required
            />
            {!isNewLead && isCopied && handleCopyClick && (
              <IconButton
                disabled={isCopied[values.customerEmail]}
                onClick={() => handleCopyClick(values.customerEmail)}
                sx={{ marginLeft: '0.5rem' }}
                title="Copy"
                color="secondary"
              >
                <FontAwesomeIcon icon={faCopy} />
              </IconButton>
            )}
          </Box>
          <Box display="flex">
            <PhoneTextField
              name={fieldContext ? `${fieldContext}.customerPhone` : 'customerPhone'}
              label="Phone Number"
              required
            />
            {!isNewLead && isCopied && handleCopyClick && (
              <IconButton
                disabled={isCopied[values.customerPhone as string]}
                onClick={() => handleCopyClick(values.customerPhone as string)}
                sx={{ marginLeft: '0.5rem' }}
                title="Copy"
                color="secondary"
              >
                <FontAwesomeIcon icon={faCopy} />
              </IconButton>
            )}
          </Box>
          <AddressAutocomplete
            option={{
              street: address?.street,
              secondary: '',
              city: address?.city,
              state: address?.state,
              zipCode: address?.postalCode,
            }}
            handleChange={val => {
              setFieldValue(
                fieldContext ? `${fieldContext}.addressModel.address` : 'addressModel.address',
                val?.street
              );
              setFieldValue(
                fieldContext ? `${fieldContext}.addressModel.city` : 'addressModel.city',
                val?.city
              );
              setFieldValue(
                fieldContext ? `${fieldContext}.addressModel.state` : 'addressModel.state',
                val?.state
              );
              setFieldValue(
                fieldContext
                  ? `${fieldContext}.addressModel.postalCode`
                  : 'addressModel.postalCode',
                val?.zipCode
              );
            }}
          />
          <SelectAsync
            name={fieldContext ? `${fieldContext}.serviceType` : 'serviceType'}
            label="Service Type"
            required
            apiRequest={() => getLeadServiceTypes()}
            transformResponse={(response: IDropdownResponse[]) => {
              return response.map((record, index) => ({
                label: record.description,
                value: record.value,
              }));
            }}
            hasClear
          />
          <SelectAsync
            name={fieldContext ? `${fieldContext}.source` : 'source'}
            label="Lead Source"
            required
            apiRequest={() => getLeadSources()}
            transformResponse={(response: IDropdownResponse[]) => {
              return response.map((record, index) => ({
                label: record.description,
                value: record.value,
              }));
            }}
            hasClear
          />
          <SelectAsync
            name={fieldContext ? `${fieldContext}.userId` : 'userId'}
            label="Assign To"
            apiRequest={() => getUsers({ officeId: user?.officeId, perPage: -1 })}
            transformResponse={(response: IResponse<IListUser[]>) => {
              return response.records.map(record => ({
                label: record.userName,
                value: record.userId,
              }));
            }}
            hasClear
          />
          <FormControlLabel
            control={<Checkbox name={fieldContext ? `${fieldContext}.createTask` : 'createTask'} />}
            label="Create Task**"
            disabled={fieldContext ? !values?.[fieldContext]?.userId : !values.userId}
          />
          <FormHelperText>**Assign To input is required to create a task</FormHelperText>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={8}>
        <TextField
          name={fieldContext ? `${fieldContext}.description` : 'description'}
          label="Request"
          required
          multiline
          rows={3}
        />
        {!isNewLead &&
          leadId &&
          leadDetail &&
          handleSave &&
          refetchLead &&
          hasCorrectUserPermissions(Permissions.ViewEstimates, user!) && (
            <RelatedEstimatesCard
              leadId={leadId}
              leadDetail={leadDetail}
              isCreateDisabled={!values.userId}
              handleSave={async () => {
                if (values.userId && leadDetail?.user?.userId !== values.userId) {
                  setSubmitting(true);
                  await handleSave(values as ILeadPost);
                  await refetchLead();
                  setSubmitting(false);
                }
              }}
            />
          )}
      </Grid>
    </Grid>
  );
};
