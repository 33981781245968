import { Autocomplete, TextField, Box } from '@mui/material';
import { FC, useContext } from 'react';
import { UserContext } from '../../context';
import { IListUser } from '../../models';
import { getUsers } from '../../fetch';
import { useQuery } from 'react-query';

interface IUserAutocomplete {
  setSelectedUser: (val: IListUser | null) => void;
  isDisabled?: boolean;
  handleOptions?: (val: IListUser[]) => void;
  selectedUser: IListUser | null;
  hasError?: boolean;
  helperText?: string;
  handleBlur?: (e: any) => void;
  isRequired?: boolean;
  labelText?: string;
  filters?: any;
}

export const UserAutocomplete: FC<IUserAutocomplete> = ({
  isDisabled,
  handleOptions,
  hasError,
  helperText,
  selectedUser,
  setSelectedUser,
  handleBlur,
  isRequired,
  labelText,
  filters,
}) => {
  const { user } = useContext(UserContext);

  const { isLoading: isLoadingAccounts, data: users } = useQuery(
    ['getUsers', user],
    async () => {
      const accountRes = await getUsers(filters ? filters : { perPage: -1 });
      return accountRes.records;
    },
    {
      notifyOnChangeProps: 'tracked',
      onSuccess: options => {
        handleOptions?.(options);
      },
    }
  );

  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      <Autocomplete
        fullWidth
        value={selectedUser}
        disabled={isLoadingAccounts || isDisabled}
        onChange={(_event: any, newValue: IListUser | null) => {
          setSelectedUser(newValue);
        }}
        onBlur={handleBlur}
        options={users || []}
        getOptionLabel={(option: IListUser) => {
          return option.userName;
        }}
        renderOption={(props: any, option: IListUser) => {
          return (
            <li {...props} key={option.userId}>
              {option.userName}
            </li>
          );
        }}
        data-testid="user-autocomplete"
        renderInput={params => {
          return (
            <TextField
              {...params}
              size="small"
              label={labelText ?? 'User'}
              required={isRequired ?? true}
              fullWidth
              autoComplete="none"
              variant="outlined"
              placeholder={`Select ${labelText ?? 'User'}`}
              error={hasError}
              helperText={helperText}
              name="userId"
            />
          );
        }}
      />
    </Box>
  );
};
