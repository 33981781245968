import { faCodeCompare, faClose } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from 'react-query';
import { FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { ConfirmPrompt, Loader } from '../../components';
import { Pod } from './pod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext, ServiceRoutesContext } from '../../context';
import {
  IRouteUpdateMode,
  IServiceChange,
  IServiceRoute,
  IServiceChangeItem,
  ITechOptimizationEvent,
  ICalendarView,
  IResponse,
  ITechnician,
  ICalendarDateRange,
} from '../../models';
import { DateRangeFilter } from './DateRangeFilter';
import { TechnicianFilters } from './TechnicianFilters';
import { useHistory } from 'react-router-dom';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { createDropResultChangeSet } from './draggableUtils';
import { buildTechnicianInfoList } from './utils';
import { TechPod } from './pod/TechPod';
import {
  isThisWeek,
  endOfDay,
  endOfWeek,
  nextFriday,
  nextMonday,
  nextSunday,
  startOfWeek,
} from 'date-fns';
import { parseCalendarDate } from '../../helpers';
import { useConfirm } from '../../hooks';
import { getTechnicianUsers } from '../../fetch';
import { deepEqual } from 'fast-equals';
import { defaultUnsavedChangesMessage } from '../../constants';
import { UnsortedStopsAlert } from './unsorted-stops-alert';
import { ButtonsToolbar } from './buttons-toolbar';
import { ManualAdjustAlert } from './manual-adjust-alert';
import { useSnackbar } from 'notistack';

interface IServiceRoutesPods {
  updateMode: IRouteUpdateMode;
  isSaving: boolean;
  isLoading: boolean;
  isOptimizing?: boolean;
  serviceRoutes: IServiceRoute[];
  showSave?: boolean;
  showWeekViewSelector?: boolean;
  showUpdateMode?: boolean;
  disableUpdateMode?: boolean;
  hideEditButton?: boolean;
  hasChanges?: boolean;
  changes?: Record<string, IServiceChange>;
  onServicesChange?: (changes: IServiceChangeItem[]) => unknown;
  onUpdateModeChange?: (value: IRouteUpdateMode) => unknown;
  onSave?: () => unknown;
  showToolbar?: boolean;
  onMapClick?: (routeIndex: number) => void;
  toolbarControls?: ReactNode;
  showReset?: boolean;
  onReset?: () => unknown;
  serviceRouteType?: string;
  allowOptimization?: boolean;
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  singleViewServiceDate?: string;
  setSingleViewServiceDate?: React.Dispatch<React.SetStateAction<string>>;
  onOptimizeSwitch?: () => void;
  allowedTechIds?: string[];
  setOptimizedTech?: React.Dispatch<React.SetStateAction<ITechnician | undefined>>;
  optimizedTech?: ITechnician;
  setUpdatedRoutes?: (data: IServiceRoute[]) => void;
  loadServiceRoutes?: (dateRange: ICalendarDateRange, userId?: string | null) => void;
  initialServiceRoutes?: IServiceRoute[];
  isCompareView?: boolean;
  setCompareView?: React.Dispatch<React.SetStateAction<boolean>>;
  showReload?: boolean;
  onReload?: () => void;
}

export const ServiceRoutesPods: FC<IServiceRoutesPods> = ({
  isSaving,
  isLoading,
  isOptimizing = false,
  serviceRoutes,
  showSave = true,
  showWeekViewSelector = true,
  showUpdateMode = true,
  hideEditButton = false,
  disableUpdateMode = false,
  hasChanges,
  changes,
  onServicesChange,
  updateMode,
  onUpdateModeChange,
  onSave,
  showToolbar = true,
  onMapClick,
  toolbarControls = null,
  showReset = true,
  onReset,
  serviceRouteType,
  allowOptimization = true,
  onOptimizationClick,
  singleViewServiceDate,
  setSingleViewServiceDate,
  onOptimizeSwitch,
  allowedTechIds,
  setOptimizedTech,
  optimizedTech,
  setUpdatedRoutes,
  loadServiceRoutes,
  initialServiceRoutes,
  isCompareView,
  setCompareView,
  showReload,
  onReload,
}) => {
  const history = useHistory();
  const confirm = useConfirm();
  const [selectedDraggableIds, setSelectedDraggableIds] = useState<string[]>([]);
  const [activeDraggableId, setActiveDraggableId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const params = new URLSearchParams(window.location.search);
  const paramDate = params.get('date');

  const { isTechUser } = useContext(UserContext);

  const classes = useStyles();
  const {
    selectedTechs,
    setSelectedTechs,
    selectedDateRange,
    setSelectedDateRange,
    view,
    setView,
    isSingleViewMode,
    setIsSingleViewMode,
  } = useContext(ServiceRoutesContext);

  const { data: usersData } = useQuery<IResponse<any[]>, Error>(['getTechnicianUsers'], () =>
    getTechnicianUsers({ perPage: -1, roles: 'ServiceTech', isDisabled: false })
  );

  const technicians = useMemo(() => usersData?.records ?? [], [usersData]);
  const techInfoList = useMemo(() => buildTechnicianInfoList(serviceRoutes), [serviceRoutes]);

  const filteredTechInfoList = useMemo(() => {
    if (allowedTechIds?.length) {
      const allowedTechInfos = techInfoList.filter(info =>
        allowedTechIds.some(id => id === info.tech.userId)
      );
      if (!selectedTechs.length) {
        return allowedTechInfos;
      }
      return allowedTechInfos.filter(techInfo =>
        selectedTechs.find(t => t.userId === techInfo.tech.userId)
      );
    }
    if (!selectedTechs.length) {
      return techInfoList;
    }
    return techInfoList.filter(techInfo =>
      selectedTechs.find(t => t.userId === techInfo.tech.userId)
    );
  }, [techInfoList, selectedTechs, allowedTechIds]);

  const [allSitesDraggableIds, setAllSitesDraggableIds] = useState<string[] | null>(null);

  const handleUpdateSelectedDraggableIds = (selectedDraggableIds: string[]) => {
    const startAndEndServiceId = '00000000-0000-0000-0000-000000000000';
    const filteredSelectedDraggableIds = selectedDraggableIds.filter(
      id => !id.includes(startAndEndServiceId)
    );
    setAllSitesDraggableIds(filteredSelectedDraggableIds);
    setSelectedDraggableIds(filteredSelectedDraggableIds);
  };

  const toggleSelection = (
    e: React.KeyboardEvent,
    checked: boolean,
    podId: string,
    techServices?: string[]
  ) => {
    if (checked) {
      handleCheckedState(podId, techServices);
    } else {
      handleUncheckedState(podId);
    }

    if (e.nativeEvent.shiftKey) {
      onShiftKeyPressed(techServices ?? [], podId);
    }
  };

  const handleCheckedState = (podId: string, techServices?: string[]) => {
    const techServiceIds = techServices ?? [];
    const idsFromDifferentTechService =
      allSitesDraggableIds?.filter(id => !techServiceIds.includes(id)) ?? [];

    //Uncheck checkboxes from a different tech service
    if (idsFromDifferentTechService.length > 0) {
      setAllSitesDraggableIds([]);
      setSelectedDraggableIds([]);
    }
    setAllSitesDraggableIds(prev => [...(prev ?? []), podId]);
    setSelectedDraggableIds(prev => [...(prev ?? []), podId]);
  };

  const handleUncheckedState = (podId: string) => {
    const serviceId = podId.split('_')[2];
    const updatedDraggableIds = (allSitesDraggableIds ?? []).filter(id => !id.includes(serviceId));
    setAllSitesDraggableIds(updatedDraggableIds);
    setSelectedDraggableIds(updatedDraggableIds);
  };

  const onShiftKeyPressed = (techServices: string[], podId: string) => {
    const shouldSelectRange = !allSitesDraggableIds || allSitesDraggableIds.length === 1;

    if (shouldSelectRange) {
      const startIndex = techServices.indexOf(selectedDraggableIds[0]);
      const endIndex = techServices.indexOf(podId);

      //Adjust the start and end index if needed.
      const adjustedStartIndex = Math.min(startIndex, endIndex);
      const adjustedEndIndex = Math.max(startIndex, endIndex);

      // Slice the techServices array to get the range of IDs to select
      const draggableIdListUpdated = techServices.slice(adjustedStartIndex, adjustedEndIndex + 1);

      setAllSitesDraggableIds(draggableIdListUpdated);
      setSelectedDraggableIds(draggableIdListUpdated);
    } else {
      setAllSitesDraggableIds([podId]);
      setSelectedDraggableIds([podId]);
    }
  };

  const onDragStart = (props: DragStart) => {
    setIsDragging(true);
    if (selectedDraggableIds.includes(props.draggableId)) {
      setActiveDraggableId(props.draggableId);
    } else {
      setSelectedDraggableIds([]);
    }
  };

  const onDragEnd = (dropResult: DropResult) => {
    setIsDragging(false);
    if (!onServicesChange) {
      return;
    }

    const changeSet = createDropResultChangeSet({
      dropResult,
      selectedDraggableIds,
      serviceRoutes,
      selectedTechs,
    });

    if (!changeSet.length) {
      setActiveDraggableId(null);
      return;
    }

    let techUnavailable: ITechnician | undefined = undefined;
    let techDayOff: ITechnician | undefined = undefined;
    let techNotServiceTech: ITechnician | undefined = undefined;

    changeSet.forEach(change => {
      // check for a tech that doesn't work on the day so we can show the user a prompt warning when dragging a service to this tech
      if (!serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].isAvailable) {
        techUnavailable = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
      // check for a tech has a day off so we can show the toast warning
      if (serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].hasDayOff) {
        techDayOff = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
      // check for a tech that isn't a service tech so we can show the user a prompt warning when dragging a service to this tech
      if (!serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].isServiceTech) {
        techNotServiceTech = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
    });

    const confirmTechChanges = async (message: string) => {
      const result = await confirm(message);
      if (result) {
        setChanges();
      }
    };

    const setChanges = () => {
      onServicesChange(changeSet);
      setActiveDraggableId(null);
      setSelectedDraggableIds([]);
    };

    if (techDayOff) {
      // @ts-ignore
      enqueueSnackbar(`${techDayOff?.userName!} has the day off, cannot assign services.`, {
        variant: 'warning',
      });
    } else if (techUnavailable) {
      confirmTechChanges(
        // @ts-ignore
        `${techUnavailable?.userName!} does not work on this day. Continue with assignment?`
      );
    } else if (techNotServiceTech) {
      confirmTechChanges(
        // @ts-ignore
        `${techNotServiceTech?.userName!} is not a service tech. Continue with assignment?`
      );
    } else {
      setChanges();
    }
  };

  const confirmChangesLoss = async () => {
    return await confirm(defaultUnsavedChangesMessage);
  };

  const handleWeekViewChange = useCallback(
    async (newView: ICalendarView) => {
      if (!selectedDateRange?.startDate || !setView) {
        return;
      }
      if (hasChanges) {
        const result = await confirmChangesLoss();
        if (!result) {
          return;
        }
      }
      switch (newView) {
        case ICalendarView.DateRange:
          setIsSingleViewMode(false);
          if (updateMode !== 'Single') {
            onUpdateModeChange?.('Single');
          }

          break;
        case ICalendarView.Week:
          setIsSingleViewMode(false);
          const weekStart = nextMonday(startOfWeek(selectedDateRange.startDate));
          const weekEnd = endOfDay(nextSunday(weekStart));
          setSelectedDateRange({
            startDate: weekStart,
            endDate: weekEnd,
          });
          break;
        case ICalendarView.WorkWeek:
          setIsSingleViewMode(false);
          const workWeekStart = nextMonday(startOfWeek(selectedDateRange.startDate));
          const workWeekEnd = endOfDay(nextFriday(workWeekStart));
          setSelectedDateRange({
            startDate: workWeekStart,
            endDate: workWeekEnd,
          });
          break;
      }
      setView(newView);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateRange, hasChanges]
  );

  const handleCloseSingleViewMode = useCallback(() => {
    setIsSingleViewMode(false);
    const dateSelected = paramDate
      ? parseCalendarDate(paramDate)
      : parseCalendarDate(singleViewServiceDate as string);
    history.push('/routes');

    switch (view) {
      case ICalendarView.DateRange:
      case ICalendarView.WorkWeek:
        const workWeekStart = startOfWeek(dateSelected, { weekStartsOn: 1 });
        setSelectedDateRange({
          startDate: workWeekStart,
          endDate: endOfWeek(workWeekStart, { weekStartsOn: 6 }),
        });
        break;
      case ICalendarView.Week:
        setIsSingleViewMode(false);
        const weekStart = startOfWeek(dateSelected);
        setSelectedDateRange({
          startDate: weekStart,
          endDate: endOfWeek(weekStart),
        });
        break;
    }
    if (setSingleViewServiceDate) setSingleViewServiceDate('');
  }, [
    setIsSingleViewMode,
    singleViewServiceDate,
    setSingleViewServiceDate,
    view,
    setSelectedDateRange,
    history,
    paramDate,
  ]);

  const hasUnsortedItems = useMemo(
    () =>
      filteredTechInfoList.some(techInfo =>
        techInfo.tech.services.some(service => !service.isSorted)
      ),
    [filteredTechInfoList]
  );
  // show the confirm prompt if the update mode is recurring and the date range is not this week
  const showConfirmPrompt = useMemo(() => {
    return updateMode === 'Recurring' && !isThisWeek(selectedDateRange?.startDate!);
  }, [updateMode, selectedDateRange]);

  return (
    <>
      {isSaving && <Loader position="centered" type="overlay" title="Saving..." />}
      <ConfirmPrompt when={hasChanges} message={defaultUnsavedChangesMessage} />
      <Grid container spacing={1} alignItems="center">
        {isOptimizing && <Loader position="centered" type="overlay" title="Optimizing..." />}
        <ManualAdjustAlert />
        {showToolbar && (
          <ButtonsToolbar
            showWeekViewSelector={showWeekViewSelector}
            isSingleViewMode={isSingleViewMode}
            view={view}
            handleWeekViewChange={handleWeekViewChange}
            showUpdateMode={showUpdateMode}
            updateMode={updateMode}
            onUpdateModeChange={onUpdateModeChange}
            disableUpdateMode={disableUpdateMode}
            isTechUser={isTechUser}
            showReset={showReset}
            isSaving={isSaving}
            hasChanges={hasChanges}
            isLoading={isLoading}
            onReset={onReset}
            showSave={showSave}
            onSave={onSave}
            toolbarControls={toolbarControls}
            showConfirmPrompt={showConfirmPrompt}
            showReload={showReload}
            onReload={onReload}
          />
        )}
        {!isTechUser && (
          <Grid item xs={12} md={4}>
            <div className={classes.techFilter}>
              <Typography>Filter by Technician</Typography>
              <TechnicianFilters
                technicians={technicians}
                selectedTechs={selectedTechs}
                setSelectedTechs={setSelectedTechs}
                isLoading={isLoading}
              />
            </div>
          </Grid>
        )}
        {serviceRouteType !== 'optimize' && (
          <>
            <Grid item xs={12} md={4}>
              <div className={classes.dateFilter}>
                <Typography>Filter by Date </Typography>
                <DateRangeFilter
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                  singleViewServiceDate={singleViewServiceDate}
                  hasChanges={hasChanges}
                  view={view}
                  setView={setView}
                  isSingleViewMode={isSingleViewMode}
                  setIsSingleViewMode={setIsSingleViewMode}
                  isBorderless
                  isRoutesDateRange={!isSingleViewMode}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mt={3}>
                {isSingleViewMode ? (
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleCloseSingleViewMode}
                    startIcon={<FontAwesomeIcon icon={faClose} size="lg" />}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    data-testid="close-day-view-button"
                  >
                    Close Day View
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color={isCompareView ? 'inherit' : 'primary'}
                    startIcon={
                      <FontAwesomeIcon icon={isCompareView ? faClose : faCodeCompare} size="lg" />
                    }
                    onClick={async () => {
                      setCompareView && setCompareView(!isCompareView);
                      if (isCompareView) {
                        const hasDiffDates = !deepEqual(initialServiceRoutes, serviceRoutes);
                        if (hasChanges) {
                          const result = await confirmChangesLoss();
                          if (result) {
                            return loadServiceRoutes?.(selectedDateRange!);
                          }
                        } else if (hasDiffDates) {
                          return loadServiceRoutes?.(selectedDateRange!);
                        } else {
                          return loadServiceRoutes?.(selectedDateRange!);
                        }
                      }
                    }}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    data-testid="compare-button"
                  >
                    {isCompareView ? 'Close Compare Mode' : 'Compare'}
                  </Button>
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      {isLoading && <Loader type="overlay" position="centered" />}
      {hasUnsortedItems && <UnsortedStopsAlert onOptimizeSwitch={onOptimizeSwitch} />}
      <Box mt={2}>
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onBeforeCapture={() => setIsDragging(true)}
        >
          <div className={isDragging ? classes.podContainer : classes.podContainerOverflow}>
            {!isSingleViewMode &&
              serviceRoutes?.map((route, routeIndex) => {
                const routeId = `route-${route.routeId}-${route.serviceDate.toString()}`;
                return (
                  <div key={routeId} className={classes.podCard}>
                    <Pod
                      updateMode={updateMode}
                      changes={changes}
                      onMapClick={onMapClick}
                      routeIndex={routeIndex}
                      route={route}
                      saving={isSaving}
                      toggleSelection={toggleSelection}
                      selectedDraggableIds={selectedDraggableIds}
                      hideEditButton={hideEditButton}
                      showAllTechsButton={true}
                      activeDraggableId={activeDraggableId}
                      selectedTechs={selectedTechs}
                      allowOptimization={allowOptimization}
                      onOptimizationClick={onOptimizationClick}
                      setSingleViewServiceDate={setSingleViewServiceDate}
                      isSingleViewMode={serviceRouteType === 'optimize' || isSingleViewMode}
                      setOptimizedTech={setOptimizedTech}
                      optimizedTech={optimizedTech}
                      isCompareView={isCompareView}
                      serviceRoutes={serviceRoutes}
                      setUpdatedRoutes={setUpdatedRoutes}
                      hasChanges={hasChanges}
                      showServiceIndex
                      serviceIndexStyle="inline"
                      view={view}
                      serviceDate={route.serviceDate}
                      handleUpdateSelectedDraggableIds={handleUpdateSelectedDraggableIds}
                    />
                  </div>
                );
              })}
            {isSingleViewMode &&
              filteredTechInfoList.map(techInfo => {
                const { route, routeIndex, tech, techIndex } = techInfo;
                const routeId = `route-${route.routeId}-${route.serviceDate.toString()}-tech-${
                  tech.userId
                }`;
                return (
                  <div key={routeId} className={classes.podCard}>
                    <TechPod
                      tech={tech}
                      techIndex={techIndex}
                      route={route}
                      updateMode={updateMode}
                      changes={changes}
                      onMapClick={onMapClick}
                      routeIndex={routeIndex}
                      saving={isSaving}
                      toggleSelection={toggleSelection}
                      selectedDraggableIds={selectedDraggableIds}
                      hideEditButton={hideEditButton}
                      activeDraggableId={activeDraggableId}
                      allowOptimization={allowOptimization}
                      onOptimizationClick={onOptimizationClick}
                      showServiceIndex
                      serviceIndexStyle="inline"
                      setUpdatedRoutes={setUpdatedRoutes}
                      view={view}
                      serviceDate={route.serviceDate}
                    />
                  </div>
                );
              })}
          </div>
        </DragDropContext>
      </Box>
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  podContainerOverflow: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'nowrap',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  podContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  podCard: {
    flex: '1',
    minWidth: '175px',
  },
  techFilter: {},
  dateFilter: {},
}));
