import { FC, useState, useEffect, useMemo, useContext } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Button, Stack } from '@mui/material';
import { useUnload } from '../../hooks';
import {
  Loader,
  Documents,
  FloatingToolbar,
  ConfirmPrompt,
  SaveButton,
  WaterAnalysis,
  CancelIcon,
} from '../../components';
import { IAccountDetail, IAddress, IResponse, ISiteGetResponse } from '../../models';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createPoolEquipment,
  updatePoolEquipment,
  createSiteDocuments,
  deleteAccountDocument,
  getAccountDocuments,
  updateAccountDocument,
  createSite,
  updateSite,
  getAllSiteDefs,
} from '../../fetch';
import { SiteImages, AddEditSite, SITE_DETAIL_VALIDATION } from './';
import { IPoolEquipment, ISiteDefinitionValue } from '../../models/sites';
import { ScheduledServices } from '../services/ScheduledServices';
import { PoolStructureCard, poolStructurePerField } from './pool-structure';
import { getYear } from 'date-fns';
import { Treatments } from '../../components/treaments';
import { SiteEquipment } from '../../components/siteEquipment';
import { UserContext } from '../../context';
import {
  Paths,
  Permissions,
  defaultSaveAndContinueMessage,
  defaultUnsavedChangesMessage,
} from '../../constants';
import { useConfirm } from '../../hooks';
import { OTSLandingServices } from '../services/one-time-services/ots-landing-services';
import { hasCorrectUserPermissions } from '../../helpers';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';

interface ISiteDetailCrudViewProps {
  handleModalClose?: () => void;
  isModal?: boolean;
  currentSiteId?: string;
  currentAccountId?: string;
  isNewSite?: boolean;
  accountId: string;
  siteId: string;
  site: ISiteGetResponse;
  fetchSite: (val: string) => void;
  isLoading?: boolean;
  isDeleting?: boolean;
  accountAddress: IAddress | null;
  isAllExpanded?: boolean;
  currentCustomer: IAccountDetail | null;
  setEditing?: (val: boolean) => void;
  handleDelete?: (site: ISiteGetResponse) => void;
  handleCancel?: (isNew?: boolean) => void;
  setIsTabDirty?: (val: boolean) => void;
  handleSave?: (id?: string, isNew?: boolean) => void;
}

export interface ISiteForm {
  form: string;
  formValues: ISiteGetResponse;
  isValid: boolean;
  isDirty: boolean;
}

const SITE_EDIT_VALIDATION = Yup.object().shape({
  addEditSite: SITE_DETAIL_VALIDATION,
  poolStructure: Yup.object().shape(poolStructurePerField).nullable(),
});

export const SiteDetailCRUDView: FC<ISiteDetailCrudViewProps> = ({
  isModal,
  handleModalClose,
  isNewSite = false,
  accountId,
  siteId,
  site,
  fetchSite,
  isLoading = false,
  isDeleting = false,
  accountAddress,
  isAllExpanded,
  currentCustomer,
  setEditing,
  handleSave,
  handleDelete,
  handleCancel,
  setIsTabDirty,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const confirm = useConfirm();
  const { v2Ots } = useFlags();

  const [isHandlingServiceNavigation, setIsHandlingServiceNavigation] = useState(false);
  const [shouldPoolRefresh, setShouldPoolRefresh] = useState(false);
  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);

  // States for Global Save/Cancel Buttons
  // Conditional logic based on validation states for multiple forms
  const [isGlobalSaving, setIsGlobalSaving] = useState<boolean>(false);
  const [triggerFormValidation, setTriggerFormValidation] = useState(false); // For manually triggering form validation, since there are required fields in other sections
  const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([]);

  const { isLoading: isLoadingSiteDefinitions, data: siteDefinitions } = useQuery<
    IResponse<ISiteDefinitionValue[]>,
    Error
  >(
    ['getAllSiteDefs'],
    () => {
      const options: any = {
        sortBy: 'sortOrder',
        sortDirection: 'asc',
        perPage: 500,
        page: 1,
        officeId: user?.officeId,
      };
      return getAllSiteDefs(options);
    },
    {
      enabled: true,
    }
  );

  //Initial replacement of siteFormCollection - Future iteration will create more interchangeable forms
  const globalFormik = useFormik({
    initialValues: {
      addEditSite: {
        ...(site || {}),
        siteDescription: site?.siteDescription || currentCustomer?.name,
        addressName: !!site?.addressName
          ? site?.addressName
          : currentCustomer?.address?.addressName ?? '',
        street: isNewSite ? accountAddress?.street : site.street ?? '',
        city: isNewSite ? accountAddress?.city : site.city ?? '',
        state: isNewSite ? accountAddress?.state : site.state ?? '',
        postalCode: isNewSite ? accountAddress?.postalCode : site.postalCode ?? '',
        whenVerified: site?.whenVerified ?? '',
        mgrAccountId: site?.mgrAccountId ?? '',
        userId: site?.userId ?? '',
        useAccountAddress:
          site?.useAccountAddress !== null && site?.useAccountAddress !== undefined
            ? site?.useAccountAddress
            : true,
        additionalSiteInformation: siteDefinitions?.records.map(field => {
          return {
            ...field,
            value:
              site?.additionalSiteInformation?.find(
                val => val.userDefinedSiteDefId === field.userDefinedSiteDefId
              )?.value ?? '',
          };
        }),
        chargeForChemicals: site?.chargeForChemicals ?? false,
        latitude: site?.latitude ?? 0,
        longitude: site?.longitude ?? 0,
      },
      poolStructure: null,
    },
    validationSchema: SITE_EDIT_VALIDATION,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: values => {
      // Form save handled elsewhere
    },
  });

  const hasChanges = useMemo(() => {
    return (
      globalFormik.dirty ||
      (pushPinLocation && (pushPinLocation as Microsoft.Maps.Location)?.latitude !== site?.latitude
        ? true
        : false)
    );
  }, [pushPinLocation, site, globalFormik.dirty]);

  useEffect(() => {
    if (!isNewSite && globalFormik.dirty && triggerFormValidation) {
      setTriggerFormValidation(true); // For other forms with required fields
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFormik.dirty]);

  useEffect(() => {
    setIsTabDirty?.(globalFormik.dirty);
  }, [globalFormik.dirty, setIsTabDirty]);

  const saveChanges = async () => {
    setIsGlobalSaving(true);
    try {
      let res = null;
      const addEditSiteValues: ISiteGetResponse | undefined =
        (globalFormik.values.addEditSite as ISiteGetResponse) ??
        ({
          ...(site || {}),
          siteDescription: site?.siteDescription || currentCustomer?.name,
          addressName: accountAddress?.addressName,
          street: accountAddress?.street,
          city: accountAddress?.city,
          state: accountAddress?.state,
          postalCode: accountAddress?.postalCode,
          whenVerified: site?.whenVerified ?? '',
          mgrAccountId: site?.mgrAccountId ?? '',
          userId: site?.userId ?? '',
          useAccountAddress:
            site?.useAccountAddress !== null && site?.useAccountAddress !== undefined
              ? site?.useAccountAddress
              : true,
          additionalSiteInformation: [],
          latitude: pushPinLocation?.latitude ?? 0,
          longitude: pushPinLocation?.longitude ?? 0,
        } as any);
      const poolEquipmentValues: any = globalFormik.values.poolStructure;
      const poolEquipmentData: IPoolEquipment = !!poolEquipmentValues
        ? {
            ...poolEquipmentValues,
            lastResurfaced: !!poolEquipmentValues.lastResurfaced
              ? getYear(new Date(poolEquipmentValues.lastResurfaced))
              : null,
            poolBuilt: !!poolEquipmentValues.poolBuilt
              ? getYear(new Date(poolEquipmentValues.poolBuilt))
              : null,
          }
        : {};
      if (addEditSiteValues || hasChanges) {
        res = isNewSite
          ? await createSite(
              {
                ...((globalFormik?.values?.addEditSite as ISiteGetResponse) || site),
                latitude: pushPinLocation?.latitude ?? 0,
                longitude: pushPinLocation?.longitude ?? 0,
              },
              accountId,
              poolEquipmentData
            )
          : await updateSite(
              {
                ...((globalFormik?.values?.addEditSite as ISiteGetResponse) || site),
                latitude:
                  globalFormik?.values?.addEditSite?.latitude ?? pushPinLocation?.latitude ?? 0,
                longitude:
                  globalFormik?.values?.addEditSite?.longitude ?? pushPinLocation?.longitude ?? 0,
              },
              accountId,
              site.siteId ?? ''
            );
      }

      setUploadedPhotos([]);
      if (poolEquipmentValues && !isNewSite) {
        site?.hasPoolEquipment
          ? await updatePoolEquipment(poolEquipmentData, siteId)
          : await createPoolEquipment(poolEquipmentData, siteId);
        setShouldPoolRefresh(true);
        setTimeout(() => {
          setShouldPoolRefresh(false);
        }, 500);
      }

      enqueueSnackbar(`Site ${isNewSite ? 'Created!' : 'Updated!'}`, {
        variant: 'success',
      });

      globalFormik.resetForm();

      if (isModal && handleModalClose) {
        return handleModalClose();
      }

      if (isNewSite && res) {
        handleSave?.(res as string, isNewSite);
      } else {
        fetchSite(siteId);
        setEditing?.(false);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Error, saving site.', {
        variant: 'error',
      });
      throw error;
    } finally {
      setIsGlobalSaving(false);
    }
  };

  const onServiceNavigation = async (url: string) => {
    try {
      setIsHandlingServiceNavigation(true);
      if (hasChanges) {
        const result = await confirm(defaultSaveAndContinueMessage); // Specific wording, intended to be different from default unsaved changes messaging
        if (!!result) {
          await saveChanges();
        }
      }
      history.push(url);
    } finally {
      setIsHandlingServiceNavigation(false);
    }
  };
  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, !isHandlingServiceNavigation && hasChanges);

  const isSaveDisabled =
    (isNewSite && !globalFormik.dirty) ||
    !globalFormik.isValid ||
    isLoading ||
    isGlobalSaving ||
    isDeleting ||
    !hasChanges ||
    !globalFormik.values.addEditSite?.postalCode ||
    !globalFormik.values.addEditSite?.state ||
    !globalFormik.values.addEditSite?.city ||
    !globalFormik.values.addEditSite?.street ||
    !globalFormik.values.addEditSite?.addressName;

  const onAddPhotos = (photos: string[]) => {
    setUploadedPhotos([...uploadedPhotos, ...photos]);
  };
  const onRemovePhoto = (photo: string) => {
    setUploadedPhotos(uploadedPhotos.filter(imgTitle => imgTitle !== photo));
  };

  return (
    <>
      <ConfirmPrompt
        when={
          globalFormik.dirty
            ? true
            : !isGlobalSaving &&
              !isLoading &&
              !isHandlingServiceNavigation &&
              hasChanges &&
              !isNewSite
        }
        message={defaultUnsavedChangesMessage}
      />
      {isGlobalSaving && (
        <Loader position="centered" type="overlay" title="Saving..." zIndex={1200} />
      )}
      <AddEditSite
        showConfirmPrompt={isNewSite}
        isNewSite={isNewSite}
        site={site}
        siteDefinitions={siteDefinitions?.records ?? []}
        isLoadingSiteDefinitions={isLoadingSiteDefinitions}
        accountAddress={accountAddress}
        isLoading={isLoading || isLoadingSiteDefinitions}
        isDeleting={isDeleting}
        globalFormik={globalFormik}
        isModal={isModal}
        setPushPinLocation={setPushPinLocation}
        pushPinLocation={pushPinLocation}
        isExpanded={isAllExpanded}
        currentCustomer={currentCustomer}
      />
      {!isDeleting && (
        <>
          <Stack gap={2}>
            {!isNewSite && (
              <>
                <ScheduledServices
                  accountId={accountId}
                  redirect={encodeURIComponent(
                    `${Paths.customers.url}/${accountId}?activeTab=sites&siteId=${siteId}&isEdit=true`
                  )}
                  siteId={siteId}
                  onNavigation={onServiceNavigation}
                  title="Maintenance Services"
                  isCollapsible
                  initialExpand
                  isExpanded={isAllExpanded}
                />
                {v2Ots && (
                  <OTSLandingServices
                    accountId={accountId}
                    redirect={encodeURIComponent(
                      `${Paths.customers.url}/${accountId}?activeTab=sites&siteId=${siteId}&isEdit=true`
                    )}
                    siteId={siteId}
                    isCollapsible
                    initialExpand
                    isExpanded={isAllExpanded}
                    onNavigation={onServiceNavigation}
                  />
                )}
                <SiteEquipment
                  siteId={siteId}
                  isModal={isModal}
                  isCollapsible
                  initialExpand
                  isExpanded={isAllExpanded}
                />
              </>
            )}
            <PoolStructureCard
              siteId={siteId}
              globalFormik={globalFormik}
              isCollapsible
              initialExpand
              isExpanded={isAllExpanded}
              shouldPoolRefresh={shouldPoolRefresh}
              shouldFetch={site?.hasPoolEquipment ?? false}
              triggerFormValidation={triggerFormValidation} // Required for manually triggering form validation, since there are required fields
            />
            {!isNewSite && (
              <>
                {hasCorrectUserPermissions(Permissions.ViewPoolPhotos, user!) && (
                  <SiteImages
                    siteId={siteId}
                    isEditable
                    isExpanded={isAllExpanded}
                    showPagination
                    onAddPhotos={onAddPhotos}
                    onRemovePhoto={onRemovePhoto}
                  />
                )}
                <Documents
                  gridKeyName="site-documents-grid"
                  labelContext="Site"
                  getApiRequest={getAccountDocuments}
                  deleteApiRequest={deleteAccountDocument}
                  postApiRequest={createSiteDocuments}
                  putApiRequest={updateAccountDocument}
                  getFilters={{ siteId: siteId, accountId: accountId }}
                  postFilterId={siteId}
                  disableDelete={item => {
                    return !!item.repairId ? true : false; // If repairId, disable delete button
                  }}
                  isEditable={!isModal}
                  marginTop={0}
                  isCollapsible
                  initialExpand
                  isExpanded={isAllExpanded}
                  hasWrapper
                />
                <Treatments
                  isCollapsible
                  initialExpand
                  siteId={siteId}
                  siteName={site?.accountName}
                  isModal={isModal}
                  isExpanded={isAllExpanded}
                />
                <WaterAnalysis
                  siteId={siteId}
                  isExpanded={isAllExpanded}
                  initialExpand
                  cardTitle="Water Analysis History"
                  isCardLayout
                  isHistory
                />
                {site?.canDelete && handleDelete && (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection={{
                      xs: 'column',
                      sm: 'row',
                    }}
                    justifyContent={{
                      xs: 'center',
                      sm: 'flex-start',
                    }}
                    className="print--none"
                  >
                    <Button
                      color="error"
                      type="button"
                      onClick={() => handleDelete(site)}
                      className="print--none"
                      startIcon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                    >
                      Delete Site
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Stack>
          <FloatingToolbar isModal={isModal}>
            <Box display="flex" gap={1}>
              <Button
                color="inherit"
                onClick={async () => {
                  if (isModal && handleModalClose) {
                    return handleModalClose();
                  }
                  if (globalFormik.dirty) {
                    const result = await confirm(defaultUnsavedChangesMessage);
                    if (result) {
                      return handleCancel?.();
                    }
                    return;
                  }
                  handleCancel?.();
                }}
                disabled={isGlobalSaving || isDeleting}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>

              <SaveButton handleSave={saveChanges} disabled={isSaveDisabled} />
            </Box>
          </FloatingToolbar>
        </>
      )}
    </>
  );
};
