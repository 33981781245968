import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IFilterInputProps } from '../../../../models';

export const SelectListMultiFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const classes = useStyles();
  const { name, label } = filter;

  return (
    <FormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel} shrink={true}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={values}
        displayEmpty
        multiple
        renderValue={selected => {
          const optionLabels = selected
            .map(s => {
              const option = filter.selectListItems!.find(i => i.value === s);
              if (option) {
                return option!.text;
              }
              return '';
            })
            .filter(Boolean);

          return optionLabels.join(', ');
        }}
        onChange={e => {
          onChange(e.target.value as string[], filter);
        }}
      >
        {filter.selectListItems?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={values.indexOf(option.value) !== -1} />
            <ListItemText primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    background: 'white',
  },
}));
