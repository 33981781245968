import { Modal, ModalSaveSection, Loader, Tabs } from '../../../../components';
import { Fade, Box, useMediaQuery, Button } from '@mui/material';
import { FC, useState, useMemo, useContext, useEffect } from 'react';
import { getUsers, updateRepairVisits, getRepairVisits } from '../../../../fetch';
import {
  IListUser,
  IOneTimeServiceDetail,
  IResponse,
  IRepair,
  IUpdateRepairVisitMultiple,
  ICalendarDateRange,
  IColorSetMap,
} from '../../../../models';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../../../hooks';
import { endOfWeek, isBefore, startOfWeek } from 'date-fns';
import { deepEqual } from 'fast-equals';
import { UserContext } from '../../../../context';
import { getColorMap } from '../../../../helpers';
import { defaultSaveAndContinueMessage, defaultUnsavedChangesMessage } from '../../../../constants';
import { OTSSchedulerLegend } from './ots-scheduler-legend';
import { OTSSchedulerCalendarTab } from './ots-scheduler-calendar-tab';
import { OTSSchedulerMapTab } from './ots-scheduler-map-tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

interface IOTSSchedulerModal {
  open: boolean;
  onClose: (shouldReload?: boolean) => void;
  service?: IOneTimeServiceDetail | null;
  allowCreate?: boolean;
}

export const OTSSchedulerModal: FC<IOTSSchedulerModal> = ({
  onClose,
  open,
  service,
  allowCreate,
}) => {
  const map = 'map';
  const scheduler = 'scheduler';
  const [selectedTechId, setSelectedTechId] = useState('');
  const [selectedTech, setSelectedTech] = useState<IListUser | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingSelectedTech, setIsShowingSelectedTech] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>({
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  });
  const [initialVisits, setInitialVisits] = useState<IRepair[]>([]);
  const [visitsColorMap, setVisitsColorMap] = useState<IColorSetMap>({});
  const [scheduledVisits, setScheduledVisits] = useState<IRepair[]>([]);
  const [deletedVisits, setDeletedVisits] = useState<string[]>([]);
  const [isSingleViewMode, setIsSingleViewMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(scheduler);
  const [technicianChanged, setTechnicianChanged] = useState(false);

  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isSmMobile = useMediaQuery(`(max-width: 800px)`);

  const searchVisitAndUpdateTechnician = (
    visitId: string,
    userId: string,
    selectedTech: string
  ) => {
    const scheduledVisitsList = scheduledVisits.filter(item => item.repairVisitId === visitId);
    if (scheduledVisitsList.length > 0) {
      scheduledVisitsList[0].userId = userId;
      scheduledVisitsList[0].assignedTo = selectedTech;
      setTechnicianChanged(true);
      setScheduledVisits(scheduledVisits);
      setSelectedTechId(userId);
    }

    const initialVisitsList = initialVisits.filter(item => item.repairVisitId === visitId);
    if (initialVisitsList.length > 0) {
      initialVisitsList[0].userId = userId;
      scheduledVisitsList[0].assignedTo = selectedTech;
      setTechnicianChanged(true);
      setInitialVisits(initialVisits);
      setSelectedTechId(userId);
    }
  };

  const changeToSelectedTechnician = async (
    userId: string,
    visitId: string,
    selectedTech: string
  ) => {
    const visitToUpdate = initialVisits.filter(item => item.repairVisitId === visitId);
    if (visitToUpdate.length > 0) {
      visitToUpdate[0].userId = userId;
      visitToUpdate[0].assignedTo = selectedTech;
      visitToUpdate[0].isMoved = true;
      setTechnicianChanged(true);
      setInitialVisits(initialVisits);
      setSelectedTechId(userId);
    }
    searchVisitAndUpdateTechnician(visitId, userId, selectedTech);
  };

  const { isLoading: isLoadingAllVisits, refetch: fetchRepairVisits } = useQuery<
    IResponse<IRepair[]>,
    Error
  >(
    ['getRepairVisits', selectedDateRange, open],
    () =>
      getRepairVisits({
        perPage: -1,
        officeId: user?.officeId,
        sortDirection: 'desc',
        includeCancelledRepairs: false,
        serviceStartDate: selectedDateRange?.startDate?.toISOString(),
        serviceEndDate: selectedDateRange?.endDate?.toISOString(),
        includeStartAndEnd: true,
      }),
    {
      onSuccess: d => {
        const filteredAssignedToArray = d.records.filter((obj, index, arr) => {
          return arr.map(mapObj => mapObj.assignedTo).indexOf(obj.assignedTo) === index;
        });
        setVisitsColorMap(getColorMap(filteredAssignedToArray, 'assignedTo'));
        const initial = isSubmitting ? [...d.records] : [...initialVisits, ...d.records]; // If submitting, completely reset array
        const scheduled = isSubmitting ? [...d.records] : [...scheduledVisits, ...d.records]; // If submitting, completely reset array
        setInitialVisits(
          // remove duplicates, so we maintain if the user created any visits and then changed the date range and then came back
          initial.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.repairVisitId === value.repairVisitId)
          )
        );
        setScheduledVisits(
          // remove duplicates, so we maintain if the user created any visits and then changed the date range and then came back
          scheduled.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.repairVisitId === value.repairVisitId)
          )
        );
      },
      enabled: open,
    }
  );

  const { isLoading: isLoadingTechs, data: techsData } = useQuery<IResponse<IListUser[]>, Error>(
    ['getUsers'],
    () => getUsers({ perPage: -1, onlyIncludeRepairTechs: true, isDisabled: false }),
    {
      onSuccess: d => {
        setSelectedTech(d.records?.[0]);
        setSelectedTechId(d.records?.[0].userId);
      },
    }
  );

  const techs = useMemo(() => techsData?.records ?? [], [techsData]);

  const handleClose = async () => {
    if (!deepEqual(initialVisits, scheduledVisits)) {
      const result = await confirm(defaultUnsavedChangesMessage);
      if (result) {
        onClose();
        setScheduledVisits(initialVisits);
        setSelectedDateRange({
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
          endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
        });
      } else {
        return;
      }
    } else {
      onClose();
      setSelectedDateRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      });
    }
    onClose(true);

    // reset to first tech in the list on close
    setTimeout(() => {
      setSelectedTechId(techs?.[0].userId);
      setSelectedTech(techs?.[0]);
      setIsShowingSelectedTech(false);
      setScheduledVisits([]);
      setDeletedVisits([]);
      setInitialVisits([]);
      setTechnicianChanged(false);
      setSelectedTechId(techs?.[0]?.userId);
      setSelectedTab(scheduler);
      if (isSmMobile) {
        setIsSingleViewMode(true);
      } else {
        setIsSingleViewMode(false);
      }
      setSelectedDateRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      });
    }, 500);
  };

  const hasVisitBetweenMidnightAndNow = (scheduledVisits: IUpdateRepairVisitMultiple[]) => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);

    return scheduledVisits.some(v => {
      if (!v.startTime) return false;

      const startTime = new Date(v.startTime);
      return startTime >= midnight && startTime <= now;
    });
  };

  const handleSave = async (closeModal?: boolean) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const presentAndFutureScheduledVisits = scheduledVisits.filter(
      v => v?.startTime && !isBefore(new Date(v.startTime), today)
    );

    const formatVisits = (visits: IRepair[]) => {
      return visits.map(v => ({
        repairVisitId: v.isNew ? null : v.repairVisitId,
        technicianId: v.userId,
        startTime: v.startTime ? new Date(v.startTime).toISOString() : null,
        endTime: v.endTime ? new Date(v.endTime).toISOString() : null,
      })) as IUpdateRepairVisitMultiple[];
    };

    const newVisits = formatVisits(presentAndFutureScheduledVisits.filter(e => !!e.isNew));
    const updatedVisits = formatVisits(
      presentAndFutureScheduledVisits.filter(e => !e.isNew && !!e.isMoved)
    );

    const shouldConfirm =
      hasVisitBetweenMidnightAndNow(newVisits) || hasVisitBetweenMidnightAndNow(updatedVisits);

    if (shouldConfirm) {
      const result = await confirm('This visit occurs in the past. Do you wish to continue?');
      if (!result) return;
    }

    try {
      setIsSubmitting(true);
      await updateRepairVisits(
        {
          addedRepairVisits: newVisits,
          updatedRepairVisits:
            updatedVisits.length === 0 && technicianChanged
              ? formatVisits(initialVisits)
              : updatedVisits,
          deletedRepairVisits: deletedVisits,
        },
        {
          repairId: service?.repairId as string | undefined,
        }
      );

      await fetchRepairVisits();

      enqueueSnackbar('OTS Visits saved successfully', {
        variant: 'success',
      });

      if (!!closeModal) {
        onClose(true);
        setSelectedTechId(techs?.[0]?.userId);
        setSelectedTech(techs?.[0]);
        setIsShowingSelectedTech(false);
        setInitialVisits([]);
        setScheduledVisits([]);
        setTechnicianChanged(false);
      }
      setDeletedVisits([]);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Error saving ots visits, please try again', {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
      setTechnicianChanged(false);
    }
  };

  useEffect(() => {
    if (isSmMobile) {
      return setIsSingleViewMode(true);
    }
    return setIsSingleViewMode(false);
  }, [isSmMobile]);

  const handleUnsavedChanges = async (tab: string) => {
    if (tab === map) {
      const result = await confirm(defaultSaveAndContinueMessage);
      if (result) {
        await handleSave();
        setSelectedTab(tab);
      }
    }
    setSelectedTab(tab);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      fullHeight
      fullWidth
      maxWidth={false}
      title="OTS Visit Scheduler"
    >
      <Box>
        {(isSubmitting || isLoadingTechs || isLoadingAllVisits || isLoading) && (
          <Loader type="overlay" position="centered" />
        )}
        <Fade in={open}>
          <Box>
            <Tabs
              id="ots-scheduler-tabs"
              size="md"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                if (!deepEqual(initialVisits, scheduledVisits)) {
                  handleUnsavedChanges(val);
                } else {
                  setSelectedTab(val);
                }
              }}
              tabs={[
                {
                  key: scheduler,
                  title: 'Scheduler',
                  hasUnsavedChanges: !deepEqual(initialVisits, scheduledVisits),
                  children: (
                    <OTSSchedulerCalendarTab
                      allowCreate={allowCreate}
                      service={service}
                      selectedTechId={selectedTechId}
                      setSelectedTechId={setSelectedTechId}
                      isLoadingTechs={isLoadingTechs}
                      techs={techs}
                      setSelectedTech={setSelectedTech}
                      isShowingSelectedTech={isShowingSelectedTech}
                      setIsShowingSelectedTech={setIsShowingSelectedTech}
                      selectedDateRange={selectedDateRange}
                      setSelectedDateRange={setSelectedDateRange}
                      selectedTech={selectedTech}
                      visitsColorMap={visitsColorMap}
                      scheduledVisits={scheduledVisits}
                      setScheduledVisits={setScheduledVisits}
                      setDeletedVisits={setDeletedVisits}
                      deletedVisits={deletedVisits}
                      setIsSingleViewMode={setIsSingleViewMode}
                      isSingleViewMode={isSingleViewMode}
                      isSmMobile={isSmMobile}
                      setSelectedTechnician={changeToSelectedTechnician}
                    />
                  ),
                },
                {
                  key: map,
                  title: 'Map',
                  children: (
                    <OTSSchedulerMapTab
                      selectedTab={selectedTab}
                      techs={techs}
                      initialDateRange={selectedDateRange}
                      deletedVisits={deletedVisits}
                      isLoading={isLoading || isLoadingAllVisits || isLoadingTechs}
                      setIsLoading={setIsLoading}
                    />
                  ),
                },
              ]}
            />
            {selectedTab === scheduler && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: isSmMobile ? 'flex-end' : 'center',
                  justifyContent: 'flex-end',
                  flexDirection: isSmMobile ? 'column' : 'row',
                  width: '100%',
                  marginTop: theme => theme.spacing(2),
                }}
              >
                <OTSSchedulerLegend />
                <ModalSaveSection
                  hasNoMarginTop={!isSmMobile}
                  handleCancel={handleClose}
                  handleSave={() => handleSave(false)}
                  isSaveDisabled={
                    isSubmitting ||
                    (deepEqual(initialVisits, scheduledVisits) && !technicianChanged)
                  }
                  fullWidth={isSmMobile}
                >
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      handleSave(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faSave} />}
                    disabled={
                      isSubmitting ||
                      (deepEqual(initialVisits, scheduledVisits) && !technicianChanged)
                    }
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Save & Close
                  </Button>
                </ModalSaveSection>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
    </Modal>
  );
};
