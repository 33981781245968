import { Button, Divider, Box, Stack, Typography, Grid } from '@mui/material';
import { FC } from 'react';
import { formatMoney } from '../../helpers';
import { IIventoryItemDetail } from '../../models';

interface IInventoryItem {
  item: IIventoryItemDetail;
  isSearching: boolean;
  onSelectInventoryItem: (item: IIventoryItemDetail) => void;
}

export const InventoryItem: FC<IInventoryItem> = ({
  item,
  isSearching,
  onSelectInventoryItem,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight="bold">
              {item.description}
            </Typography>
            {item.lookupCode && (
              <Typography variant="body2">Lookup Code: {item.lookupCode}</Typography>
            )}
            {item.manufacturerNumber && (
              <Typography variant="body2">Mfg: {item.manufacturerNumber}</Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack spacing={1}>
            <Button
              size="small"
              variant="outlined"
              disabled={isSearching}
              fullWidth
              onClick={() => onSelectInventoryItem(item)}
            >
              Select Item
            </Button>
            <Box textAlign="right">
              <Typography variant="body2">{formatMoney(item.price)}</Typography>
            </Box>
            {item.quantity !== undefined && (
              <Box textAlign="right">
                <Typography variant="body2">On Hand: {item.quantity}</Typography>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box my={1.5}>
        <Divider />
      </Box>
    </Box>
  );
};
