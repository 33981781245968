import { FC, useMemo } from 'react';
import { UnscheduledWorkCard } from './UnscheduledWorkCard';
import {
  ICalendarEventDetail,
  ICalendarDateRange,
  ITimelessEventChange,
  ICalendarChanges,
  IMonthViewSettings,
  IWeekViewSettings,
  ICalendarView,
  ITimelessEvent,
  IDayViewSettings,
} from '../../../../../models';
import { alphaSort, parseCalendarDate } from '../../../../../helpers';
import { TimelessCalendar } from '../../../timeless/TimelessCalendar';
import { IColorSetMap } from '../../../../../models/colors';

export interface UnscheduledWorkProps {
  userColorMap?: IColorSetMap;
  unscheduledWorkItems: ICalendarEventDetail[];
  dateRange: ICalendarDateRange;
  onDateChange?: (change: ITimelessEventChange<ICalendarEventDetail>) => unknown;
  changes?: ICalendarChanges;
  view: ICalendarView;
  daySettings?: IDayViewSettings;
  workWeekSettings?: IWeekViewSettings;
  weekSettings?: IWeekViewSettings;
  monthSettings?: IMonthViewSettings;
}

export const UnscheduledWork: FC<UnscheduledWorkProps> = ({
  userColorMap,
  dateRange,
  unscheduledWorkItems,
  onDateChange,
  changes,
  view,
  daySettings,
  workWeekSettings,
  weekSettings,
  monthSettings,
}) => {
  const events = useMemo(() => {
    const rtn = unscheduledWorkItems.map(item => {
      const { event: calendarEvent } = item;
      const event: ITimelessEvent<ICalendarEventDetail> = {
        username: item.userInformation.username,
        id: calendarEvent.eventId,
        index: calendarEvent.index,
        date: parseCalendarDate(item.calendarItem.calendarDate),
        data: item,
      };

      return event;
    });
    return alphaSort(rtn, 'username');
  }, [unscheduledWorkItems]);

  const renderEvent = (event: ITimelessEvent<ICalendarEventDetail>) => {
    return (
      <UnscheduledWorkCard
        workDetail={event.data}
        changes={changes}
        colorSet={userColorMap?.[event.data.userInformation.userId]}
        view={view}
      />
    );
  };

  return (
    <TimelessCalendar
      dateRange={dateRange}
      events={events}
      view={view}
      daySettings={daySettings}
      workWeekSettings={workWeekSettings}
      weekSettings={weekSettings}
      monthSettings={monthSettings}
      renderEvent={renderEvent}
      allowDragging
      onDateChange={onDateChange}
    />
  );
};
