export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
};

export const base64StringToBlob = (data: string, options?: BlobPropertyBag | undefined) => {
  const byteCharacters = atob(data);
  let bytes = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = byteCharacters.charCodeAt(i);
  }
  return new Blob([bytes], options);
}
