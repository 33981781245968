import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse, Grid, SxProps, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { ExpandMoreButton } from '../../components';

export interface ICardTitleProps {
  children?: any;
  cardTitleClassName?: string;
  cardTitleWrapperClassName?: string;
  childrenWrapperClassName?: string;
  sx?: SxProps;
  title?: string;
  titleIcon?: IconProp;
  action?: JSX.Element;
  mobileWrap?: boolean;
  variant?: Variant;
  component?: React.ElementType;
  withExpand?: boolean;
  overrideExpand?: boolean;
  initialExpand?: boolean;
  expandOnPrint?: boolean;
  marginBottom?: number;
  overrideMobileView?: string | number;
  shouldNoWrap?: boolean;
  customerInfoTitle?: boolean;
  accountInfo?: JSX.Element;
  siteInfo?: JSX.Element;
}

interface ICardTitleStyles {
  mobileWrap?: boolean;
  withExpand?: boolean;
  expandOnPrint?: boolean;
  marginBottom?: number;
}

export const CardTitle: FC<ICardTitleProps> = ({
  children,
  cardTitleClassName,
  cardTitleWrapperClassName,
  title,
  titleIcon,
  childrenWrapperClassName,
  sx,
  action,
  mobileWrap = true,
  variant = 'body1',
  component = 'h3',
  withExpand,
  overrideExpand,
  initialExpand,
  expandOnPrint = true,
  marginBottom,
  overrideMobileView,
  shouldNoWrap = true,
  customerInfoTitle,
  accountInfo,
  siteInfo,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${overrideMobileView ?? 600}px)`);
  const classes = useStyles({ mobileWrap, withExpand, expandOnPrint, marginBottom });
  const [expanded, setExpanded] = useState(initialExpand ?? true);
  useEffect(() => {
    if (overrideExpand !== null && overrideExpand !== undefined) {
      setExpanded(overrideExpand as boolean);
    }
  }, [overrideExpand]);
  return (
    <Box className={clsx(classes.cardWrapper, cardTitleWrapperClassName)}>
      <Box className={clsx(classes.cardTitle, cardTitleClassName)} sx={sx}>
        <Box flex={1}>
          {title && customerInfoTitle && (
            <>
              <Typography
                variant={variant}
                component={component}
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: shouldNoWrap ? 'nowrap' : 'wrap',
                  marginBottom: isMobile && !!action ? '0.5rem' : 0,
                }}
              >
                {titleIcon && <FontAwesomeIcon icon={titleIcon} style={{ marginRight: '.5rem' }} />}
                {title}
              </Typography>
              <Grid className="parentContainer">
                <Grid className="childContainer">{accountInfo}</Grid>
                <Grid className="childContainer">{siteInfo}</Grid>
              </Grid>
            </>
          )}
          {title && !customerInfoTitle && (
            <Typography
              variant={variant}
              component={component}
              sx={{
                fontWeight: 'bold',
                whiteSpace: shouldNoWrap ? 'nowrap' : 'wrap',
                marginBottom: isMobile && !!action ? '0.5rem' : 0,
              }}
            >
              {titleIcon && <FontAwesomeIcon icon={titleIcon} style={{ marginRight: '.5rem' }} />}
              {title}
            </Typography>
          )}
        </Box>
        {isMobile && withExpand && (
          <ExpandMoreButton expand={expanded} setExpanded={setExpanded} className="print--none" />
        )}
        {action && (
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            width={isMobile ? '100%' : 'auto'}
            gap={1}
            className="print--none"
          >
            {action}
          </Box>
        )}
        {!isMobile && withExpand && (
          <ExpandMoreButton
            sx={{ marginLeft: '0.5rem' }}
            expand={expanded}
            setExpanded={setExpanded}
            className="print--none"
          />
        )}
      </Box>
      {withExpand ? (
        <Collapse
          in={expanded}
          timeout="auto"
          className={classes.cardCollapse}
          data-testid="Collapse.Button"
        >
          <Box className={clsx(classes.childrenWrapper, childrenWrapperClassName)}>{children}</Box>
        </Collapse>
      ) : (
        <Box className={clsx(classes.childrenWrapper, childrenWrapperClassName)}>{children}</Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, ICardTitleStyles>(theme => ({
  cardWrapper: {
    marginBottom: ({ withExpand, marginBottom }) =>
      marginBottom !== undefined ? marginBottom : withExpand ? 0 : theme.spacing(2.5),
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: ({ mobileWrap }: ICardTitleStyles) => (mobileWrap ? 'wrap' : undefined),
  },
  childrenWrapper: {
    borderTop: `${theme.spacing(0.25)} solid ${theme.palette.secondary.light}`,
    paddingTop: ({ withExpand }) => (withExpand ? theme.spacing(1.75) : 0),
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  cardCollapse: {
    '@media print': {
      visibility: ({ expandOnPrint }: ICardTitleStyles) =>
        expandOnPrint ? 'visible !important' : undefined,
      height: ({ expandOnPrint }: ICardTitleStyles) =>
        expandOnPrint ? 'auto !important' : undefined,
    },
  },
}));
