import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';

// fetch
import { updateTaskType, createTaskType } from '../../../fetch';
import { ITaskDefinition } from '../../../models';

interface IAddEditTaskTypeModal {
  open: boolean;
  onClose: () => void;
  currentTaskType?: ITaskDefinition | null;
  fetchTaskTypes: () => void;
}

const Schema = Yup.object().shape({
  taskDefinitionId: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export const AddEditTaskTypeModal: FC<IAddEditTaskTypeModal> = ({
  open,
  onClose,
  currentTaskType,
  fetchTaskTypes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          taskDefinitionId: currentTaskType?.taskDefinitionId ?? '',
          officeId: user?.officeId ?? '',
          code: currentTaskType?.code ?? '',
          description: currentTaskType?.description ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values
          };
          try {
            currentTaskType
              ? await updateTaskType(data)
              : await createTaskType(data);
            enqueueSnackbar(
              currentTaskType
                ? 'Successfully updated task type!'
                : 'Successfully created task type!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchTaskTypes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving task type, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentTaskType ? 'Edit Task Type' : 'Add Task Type'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                        }
                      }
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
