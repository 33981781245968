import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatDate } from '../../../helpers';
import { Box, Tooltip, Typography } from '@mui/material';
import { Link, PrimaryIcon } from '../../../components';
import { faBroom, faFileInvoice, faFlask } from '@fortawesome/free-solid-svg-icons';

export const dataGridExampleColumns: GridColDef[] = [
  {
    field: 'displayDate',
    headerName: 'Display Date',
    type: 'dateTime',
    flex: 1,
    filterable: false,
    disableColumnMenu: true,
    maxWidth: 120,
    valueFormatter: (params: GridValueFormatterParams<any>) => {
      return !!params.value ? formatDate(params.value) : '';
    },
  },
  {
    field: 'siteName',
    headerName: 'Site',
    type: 'string',
    flex: 1,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      const { row: original } = params;
      const childComponent = (
        <Tooltip title={`View customer's site`} placement="bottom">
          <span>Site Name {original.newsItemId.slice(0, 4)}</span>
        </Tooltip>
      );
      return <Link to={`#`}>{childComponent}</Link>;
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    type: 'string',
    flex: 1,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'message',
    headerName: 'Message',
    type: 'string',
    flex: 2,
    filterable: false,
    disableColumnMenu: true,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      // drop html tags in this context so the display doesn't look wonky in the table
      // might need to revisit this because it probably isn't fullproof
      params.value.replace(/<\/?[^>]+(>|$)/g, ''),
  },
  {
    field: 'icon',
    headerName: 'Icons',
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Box display="flex">
          <PrimaryIcon icon={faFlask} />
          <PrimaryIcon icon={faBroom} />
          <PrimaryIcon icon={faFileInvoice} />
        </Box>
      );
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    type: 'string',
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Typography
          sx={
            params.row.index % 2 === 0
              ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
              : { fontWeight: 'bold', fontSize: '14px' }
          }
        >
          {params.row.index % 2 === 0 ? 'Active' : 'Inactive'}
        </Typography>
      );
    },
  },
];
