import {
  Step,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
  styled,
  useMediaQuery,
  Typography,
} from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { FC } from 'react';
import { Check } from '@mui/icons-material';

interface IStepper {
  steps: string[];
  activeStep?: number;
  completed: { [k: number]: boolean };
  failedStep?: number;
  failedStatus?: string;
  isSequential?: boolean;
}

interface StepIconPropsExtend extends StepIconProps {
  steps: string[];
  isSequential?: boolean;
}

export const Stepper: FC<IStepper> = ({
  steps,
  activeStep,
  completed,
  failedStep,
  failedStatus,
  isSequential = true,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  return (
    <MuiStepper
      nonLinear
      activeStep={!isSequential && !activeStep ? -1 : activeStep}
      alternativeLabel={!isMobile}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      connector={isSequential ? <StyledStepConnector /> : null}
      data-testid="Stepper"
      sx={!isSequential ? { flexWrap: 'wrap', flexDirection: 'row' } : undefined}
    >
      {steps.map((label, index) => {
        const labelProps: {
          optional?: React.ReactNode;
          error?: boolean;
        } = {};
        if (failedStep === 0 && index === 0) {
          labelProps.optional = (
            <Typography variant="caption" color="error" data-testid="Stepper.FailedStep">
              {failedStatus}
            </Typography>
          );
          labelProps.error = true;
        }
        const children = (
          <StepLabel
            {...labelProps}
            StepIconComponent={props => (
              <StepIcon {...props} steps={steps} isSequential={isSequential} />
            )}
          >
            {label}
          </StepLabel>
        );
        if (isSequential) {
          return (
            <Step key={label} completed={completed[index]}>
              {children}
            </Step>
          );
        } else {
          return (
            <StyledStep key={label} completed={completed[index]}>
              {children}
            </StyledStep>
          );
        }
      })}
    </MuiStepper>
  );
};

const StepIcon: FC<StepIconPropsExtend> = ({
  active,
  completed,
  className,
  icon,
  steps,
  isSequential = true,
}) => {
  // converts the number of steps into an object of index and string
  // i.e. {1: '01', 2: '02'} etc
  const icons: { [index: string]: string } = steps?.reduce(
    (prev, curr, index) => ({
      ...prev,
      [`${index + 1}`]: index < 10 ? `0${index + 1}` : `${index + 1}`,
    }),
    {}
  );
  return (
    <IconWrapper ownerState={{ completed, active, isSequential }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" data-testid="Stepper.CompletedStep" />
      ) : isSequential ? (
        icons?.[String(icon)]
      ) : (
        ''
      )}
    </IconWrapper>
  );
};

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [theme.breakpoints.down(960)]: {
    marginLeft: '22px',
  },

  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      height: 10,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(960)]: {
        width: 1,
        height: 1,
      },
      [theme.breakpoints.up(960)]: {
        ':before': {
          content: '""',
          position: 'absolute',
          top: -3,
          left: -12,
          borderRadius: '50%',
          borderTop: `16px solid ${theme.palette.primary.main}`,
          borderLeft: `24px solid ${theme.palette.primary.main}`,
        },
        ':after': {
          content: '""',
          position: 'absolute',
          top: -3,
          right: -12,
          borderRadius: '50%',
          borderTop: `16px solid ${theme.palette.primary.main}`,
          borderLeft: `24px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      height: 10,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up(960)]: {
        ':before': {
          content: '""',
          position: 'absolute',
          top: -3,
          left: -12,
          borderRadius: '50%',
          borderTop: `16px solid ${theme.palette.primary.main}`,
          borderLeft: `24px solid ${theme.palette.primary.main}`,
        },
        ':after': {
          content: '""',
          position: 'absolute',
          top: -3,
          right: -12,
          borderRadius: '50%',
          borderTop: `16px solid ${theme.palette.primary.main}`,
          borderLeft: `24px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
    [theme.breakpoints.down(960)]: {
      width: 1,
      height: 1,
    },
    [theme.breakpoints.up(960)]: {
      ':before': {
        content: '""',
        position: 'absolute',
        top: -3,
        left: -12,
        borderRadius: '50%',
        borderTop: `16px solid ${theme.palette.grey[300]}`,
        borderLeft: `24px solid ${theme.palette.grey[300]}`,
      },
      ':after': {
        content: '""',
        position: 'absolute',
        top: -3,
        right: -12,
        borderRadius: '50%',
        borderTop: `16px solid ${theme.palette.grey[300]}`,
        borderLeft: `24px solid ${theme.palette.grey[300]}`,
      },
    },
  },
}));

const IconWrapper = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean; isSequential?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[400],
  zIndex: 1,
  color: '#fff',
  width: ownerState.isSequential ? 50 : 35,
  height: ownerState.isSequential ? 50 : 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(!ownerState.isSequential && {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.grey[400]}`,
  }),
  ...(ownerState.active &&
    ownerState.isSequential && {
      backgroundColor: theme.palette.secondary.main,
    }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(!ownerState.isSequential &&
    ownerState.active && {
      borderColor: theme.palette.secondary.main,
    }),
  ...(!ownerState.isSequential &&
    ownerState.completed && {
      borderColor: theme.palette.secondary.main,
    }),
}));

const StyledStep = styled(Step)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {
    width: '33.333%',
  },
  [theme.breakpoints.up(960)]: {
    width: 'auto',
  },
}));
