import { TextField as MuiTextField, Chip, Autocomplete, Theme } from '@mui/material';
import { useState, useMemo, FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@mui/styles';
import { IOffice, IResponse } from '../../../models';
import { getOffices } from '../../../fetch';

interface IStoresAutocomplete {
  values: any;
  setFieldValue: (key: string, val: any) => void;
}

export const StoresAutocomplete: FC<IStoresAutocomplete> = ({ values, setFieldValue }) => {
  const [selectedStores, setSelectedStores] = useState<IOffice[]>([]);
  const classes = useStyles();
  const { isLoading: isLoadingStores, data: storesData } = useQuery<IResponse<IOffice[]>, Error>(
    ['getOffices'],
    () =>
      getOffices({
        perPage: '2147483647', // get all records,
        sortBy: 'OfficeName',
      })
  );
  const stores = useMemo(() => storesData?.records || [], [storesData]);

  useEffect(() => {
    if (!values.officeIds && !isLoadingStores) {
      setSelectedStores([]);
    } else if (values.officeIds?.length > 0) {
      const selectedStores = [
        {
          officeId: '-1',
          storeId: -1,
          code: '-1',
          officeName: 'All',
          inactive: true,
        },
        ...stores,
      ]
        .map(store => {
          if (values.officeIds.includes(store.officeId)) {
            return store;
          }
          return null;
        })
        .filter(Boolean);
      setSelectedStores(selectedStores as IOffice[]);
    }
  }, [values.officeIds, isLoadingStores, stores]);

  return (
    <Autocomplete
      value={selectedStores}
      multiple
      disableClearable
      onChange={(event, newValue: IOffice[]) => {
        if (newValue && newValue.length > 0) {
          const hasAllLastSelected = newValue?.[newValue.length - 1].officeId === '-1';
          setSelectedStores(newValue);
          if (hasAllLastSelected) {
            setFieldValue('officeIds', ['-1']);
          } else {
            setFieldValue(
              'officeIds',
              newValue.filter(o => o.officeId !== '-1').map(o => o.officeId)
            );
          }
        } else {
          setSelectedStores([]);
          setFieldValue('officeIds', []);
        }
      }}
      id="officeIds"
      options={[
        {
          officeId: '-1',
          storeId: -1,
          code: '-1',
          officeName: 'All',
          inactive: true,
          adminEmail: '',
          address: null,
          canEditAccountCode: false,
        },
        ...stores,
      ]}
      getOptionLabel={(store: IOffice) => {
        return store.officeName;
      }}
      renderOption={(props, option: IOffice) => {
        return (
          <li {...props} key={option.officeId}>
            {option.officeName}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <Chip
            key={tag.officeId}
            label={tag.officeName}
            variant="outlined"
            className={classes.chip}
            classes={{ root: classes.chipRoot }}
            onDelete={() => {
              setSelectedStores(prev => prev.filter(t => t.officeId !== tag.officeId));
            }}
          />
        ))
      }
      disabled={isLoadingStores}
      renderInput={params => {
        return (
          <MuiTextField
            {...params}
            size="medium"
            name="officeIds"
            autoComplete="none"
            disabled={isLoadingStores}
            label="Stores"
            variant="outlined"
            placeholder="Add Store"
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  chip: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
  },
  chipRoot: {
    '& .MuiChip-deleteIcon': {
      fontSize: '30px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  },
}));
