import { FC, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Modal, Loader, Wysiwyg } from '..';
import { Fade, TextField, Grid, Switch, FormControlLabel, Box } from '@mui/material';
import { IInitialEmailFormValues } from '../../models';
import { ModalSaveSection } from '../modal';
import { MultipleEmailInput } from '../../components';
import { faMailForward } from '@fortawesome/free-solid-svg-icons';

interface ISendEmailModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (payload: IInitialEmailFormValues) => Promise<unknown>;
  modalTitle?: string;
  subtitle?: string;
  defaultToAddress?: string[];
  defaultSubject?: string;
  defaultBody?: string;
  showSendAsAgreementToggle?: boolean;
  sendAsFormalAgreement?: boolean;
  isSendFormalAgreementDisabled?: boolean;
  isInvoiceType?: boolean;
}

const EmailModalSchema = yup.object().shape({
  toAddress: yup
    .array(yup.string().email('Must be a valid email address').required().label('Recipient'))
    .min(1, 'Recipient is a required field'),
  ccAddress: yup.array(yup.string().email('Must be a valid email address').label('CC')),
  subject: yup.string().required().label('Subject'),
  body: yup.string().typeError('Must be a valid email address').required().label('Body'),
  sendAsFormalAgreement: yup.boolean(),
});

export const SendEmailModal: FC<ISendEmailModalProps> = ({
  open,
  onClose,
  onSubmit,
  modalTitle = 'Send Email',
  subtitle,
  defaultToAddress,
  defaultSubject,
  defaultBody,
  showSendAsAgreementToggle = true,
  sendAsFormalAgreement = false,
  isSendFormalAgreementDisabled = true,
  isInvoiceType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const EMAIL_FORM_VALUES: IInitialEmailFormValues = {
    toAddress: defaultToAddress ?? [],
    ccAddress: [],
    subject: defaultSubject ?? '',
    body: defaultBody ?? '',
    sendAsFormalAgreement,
    includePaymentLink: false,
  };

  const {
    resetForm,
    isSubmitting,
    handleSubmit,
    values,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik<IInitialEmailFormValues>({
    validateOnChange: true,
    validateOnMount: !!defaultToAddress && !!defaultSubject && !!defaultBody,
    enableReinitialize: true,
    initialValues: { ...EMAIL_FORM_VALUES },
    validationSchema: EmailModalSchema,
    onSubmit: async (values, actions) => {
      try {
        await onSubmit({
          toAddress: values.toAddress,
          ccAddress: values.ccAddress,
          subject: values.subject,
          body: values.body,
          sendAsFormalAgreement: values.sendAsFormalAgreement,
          includePaymentLink: values.includePaymentLink,
        });
        enqueueSnackbar('Email was successfully sent', {
          variant: 'success',
        });
        onClose();
        actions.resetForm();
      } catch (error: any) {
        enqueueSnackbar(
          error?.data?.Detail ?? 'An unexpected error occurred while sending the email',
          {
            variant: 'error',
          }
        );
      }
    },
  });
  const reset = useCallback(() => {
    resetForm({
      values: {
        ...EMAIL_FORM_VALUES,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);
  const handleClose = () => {
    onClose();
    reset();
  };
  const isSaveDisabled =
    defaultBody && defaultSubject ? isSubmitting || !isValid : !dirty || isSubmitting || !isValid;

  return (
    <Modal open={open} onClose={handleClose} maxWidth="sm" title={modalTitle} subtitle={subtitle}>
      {isSubmitting && <Loader type="fullscreen" position="centered" title="Sending..." />}
      <Fade in={open}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box mt={2} mb={-1}>
            <Grid container spacing={2}>
              {showSendAsAgreementToggle && (
                <Grid item xs={12}>
                  <FormControlLabel
                    name="sendAsFormalAgreement"
                    onChange={(_, checked) => {
                      setFieldValue('sendAsFormalAgreement', checked);
                    }}
                    control={<Switch />}
                    label="Send as formal agreement"
                    checked={values.sendAsFormalAgreement}
                    disabled={isSendFormalAgreementDisabled}
                  />
                </Grid>
              )}
              {isInvoiceType && (
                <Grid item xs={12}>
                  <FormControlLabel
                    name="includePaymentLink"
                    onChange={(_, checked) => {
                      setFieldValue('includePaymentLink', checked);
                    }}
                    control={<Switch />}
                    label="Include a payment link"
                    checked={values.includePaymentLink}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MultipleEmailInput
                  fullWidth
                  required
                  size="small"
                  placeholder="test@example.com"
                  label="Recipient"
                  value={values.toAddress}
                  onChange={(_e, newEmailsValue) => {
                    setFieldValue('toAddress', newEmailsValue);
                  }}
                  hasError={!!errors.toAddress}
                  helperText={errors.toAddress ?? undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleEmailInput
                  fullWidth
                  size="small"
                  placeholder="test@example.com"
                  label="CC (optional)"
                  value={values.ccAddress ?? []}
                  onChange={(_e, newEmailsValue) => {
                    setFieldValue('ccAddress', newEmailsValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="subject"
                  required
                  size="small"
                  label="Subject"
                  data-testid="subject"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.subject && !!errors.subject}
                  helperText={touched.subject ? errors.subject : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg
                  label="Body"
                  required
                  value={values.body}
                  onChange={val => setFieldValue('body', val)}
                />
              </Grid>
            </Grid>
          </Box>
          <ModalSaveSection
            handleCancel={handleClose}
            isSaveDisabled={isSaveDisabled}
            submitLabel="Send"
            submitIcon={faMailForward}
          />
        </form>
      </Fade>
    </Modal>
  );
};
