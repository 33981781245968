import { FC, useMemo } from 'react';
import {
  ICalendarView,
  IRouteUpdateMode,
  IService,
  IServiceChange,
  IServiceRoute,
  ITechOptimizationEvent,
  ITechnician,
} from '../../../models';
import { createServiceDraggableId } from '../draggableUtils';
import { isRouteStart, isRouteStartOrEnd } from '../utils';
import { SortableItem } from './SortableItem';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

interface IPodServiceAccordionDetails {
  route: IServiceRoute;
  tech: ITechnician;
  service: IService;
  routeIndex: number;
  techIndex: number;
  serviceIndex: number;
  toggleSelection?: (
    e: React.KeyboardEvent,
    checked: boolean,
    podId: string,
    techServices: string[]
  ) => void;
  selectedDraggableIds?: string[];
  activeDraggableId?: string | null;
  updateMode?: IRouteUpdateMode;
  changes?: Record<string, IServiceChange>;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showServiceIndex?: boolean;
  serviceIndexStyle?: 'avatar' | 'inline';
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  colorizeSiteIndex?: boolean;
  serviceDate: string;
  isCondensed: boolean;
  isShowingServiceIndex: boolean;
  view: ICalendarView;
  handleSiteChange: (id: string | number) => void;
  podReadOnlyInCompareMode: boolean;
  saving: boolean;
  allowOptimization: boolean;
  handleSelectAllSitesChange: (value: boolean) => void;
  allSitesSelected: boolean;
}

export const PodServiceAccordionDetails: FC<IPodServiceAccordionDetails> = ({
  route,
  tech,
  service,
  routeIndex,
  techIndex,
  serviceIndex,
  updateMode,
  isCondensed,
  readonly,
  isShowingServiceIndex,
  changes,
  view,
  serviceDate,
  serviceIndexStyle,
  handleSiteChange,
  podReadOnlyInCompareMode,
  toggleSelection,
  activeDraggableId,
  colorizeSiteIndex,
  allowMultiDrag,
  saving,
  selectedDraggableIds,
  allowOptimization,
  onOptimizationClick,
  handleSelectAllSitesChange,
  allSitesSelected,
}) => {
  const serviceDraggableId = useMemo(
    () =>
      createServiceDraggableId({
        routeId: route.routeId,
        userId: tech.userId,
        serviceId: service.scheduledServiceId,
        routeIndex,
        techIndex,
        serviceIndex,
        version: service?.version,
      }),
    [
      route.routeId,
      tech.userId,
      service.scheduledServiceId,
      routeIndex,
      techIndex,
      serviceIndex,
      service?.version,
    ]
  );

  const isStartOrEnd = isRouteStartOrEnd(service);
  const isStart = isRouteStart(service);

  const isLocked = useMemo(() => {
    return (
      (updateMode === 'Single' && service.isSingleDayModeLocked) ||
      (updateMode === 'All' && service.isAllDayModeLocked)
    );
  }, [updateMode, service.isSingleDayModeLocked, service.isAllDayModeLocked]);

  const hasChanged = useMemo(
    () => !!changes?.[service.scheduledServiceId],
    [changes, service.scheduledServiceId]
  );

  const currentTechServices = useMemo(() => {
    return tech.services.map((site, index) =>
      createServiceDraggableId({
        routeId: route.routeId,
        userId: tech.userId,
        serviceId: site.scheduledServiceId,
        routeIndex,
        techIndex,
        serviceIndex: index,
        version: site?.version,
      })
    );
  }, [tech, route.routeId, routeIndex, techIndex]);

  const selectedCurrentTechServicePod = useMemo(() => {
    const serviceIds = selectedDraggableIds!.map(id => {
      //Deserialize the draggableId to get the serviceId
      const serviceDraggableId = id.split('_');
      return serviceDraggableId[2];
    });
    return serviceIds.every(id => tech.services.some(obj => obj.scheduledServiceId === id));
  }, [selectedDraggableIds, tech.services]);

  if (isStartOrEnd) {
    return (
      <>
        {isStart && allowMultiDrag && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <SortableItem
              key={serviceDraggableId}
              isLocked={isLocked}
              service={service}
              condense={isCondensed}
              readonly={readonly}
              showIndex={isShowingServiceIndex}
              indexStyle={serviceIndexStyle}
              index={serviceIndex}
              techColor={tech.color}
              updateMode={updateMode}
              view={view}
              serviceDate={serviceDate}
            />
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': { fontSize: '0.85rem' },
              }}
              color="primary"
              control={
                <Checkbox
                  checked={allSitesSelected! && selectedCurrentTechServicePod}
                  onChange={(_, checked) => {
                    handleSelectAllSitesChange(checked);
                  }}
                />
              }
              label="Select All"
            />
          </Box>
        )}
        {!isStart && (
          <SortableItem
            key={serviceDraggableId}
            isLocked={isLocked}
            service={service}
            condense={isCondensed}
            readonly={readonly}
            showIndex={isShowingServiceIndex}
            indexStyle={serviceIndexStyle}
            index={serviceIndex}
            techColor={tech.color}
            updateMode={updateMode}
            view={view}
            serviceDate={serviceDate}
          />
        )}
      </>
    );
  }

  return (
    <Draggable
      key={serviceDraggableId}
      draggableId={serviceDraggableId}
      index={serviceIndex}
      isDragDisabled={
        readonly || isStartOrEnd || isLocked || service.isClosed || podReadOnlyInCompareMode
      }
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SortableItem
            isLocked={isLocked}
            hasChanged={hasChanged}
            service={service}
            handleSiteChange={() => {
              handleSiteChange(route.routeId);
            }}
            draggableId={serviceDraggableId}
            activeDraggableId={activeDraggableId}
            isDragging={snapshot.isDragging}
            handleSelection={(e: React.KeyboardEvent, checked: boolean, val: string) =>
              toggleSelection && toggleSelection(e, checked, val, currentTechServices)
            }
            condense={isCondensed}
            selectedDraggableIds={selectedDraggableIds}
            saving={saving}
            readonly={readonly || podReadOnlyInCompareMode}
            allowMultiDrag={allowMultiDrag}
            showIndex={isShowingServiceIndex}
            indexStyle={serviceIndexStyle}
            index={serviceIndex}
            techColor={tech.color}
            allowOptimization={allowOptimization}
            onOptimizationClick={() => {
              if (allowOptimization) {
                onOptimizationClick?.({ tech, route });
              }
            }}
            updateMode={updateMode}
            colorizeSiteIndex={colorizeSiteIndex}
            view={view}
            serviceDate={serviceDate}
            techServices={currentTechServices}
          />
        </div>
      )}
    </Draggable>
  );
};
