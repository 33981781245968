import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { CardTitle, Card } from '../../components';

interface IPSISectionProps {
  previousPSI: string | number;
  currentPSI: string | number;
  isExpanded?: boolean;
}

export const PSISection: FC<IPSISectionProps> = ({ previousPSI, currentPSI, isExpanded }) => {
  const classes = useStyles();
  return (
    <Card className="print--avoid-break">
      <CardTitle
        title="PSI Information"
        withExpand
        initialExpand={false}
        overrideExpand={isExpanded}
      >
        <Box>
          <Typography>
            <span className={classes.label}>Previous PSI</span> <span>{previousPSI}</span>
          </Typography>
          <Typography>
            <span className={classes.label}>PSI</span> <span>{currentPSI}</span>
          </Typography>
        </Box>
      </CardTitle>
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  label: {
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: '1rem',
    display: 'inline-block',
    width: '7rem',
  },
}));
