import { Page } from '../../components';
import { TransactionBatchDetails } from './transaction-batch-details';

export const TransactionBatchPage = () => {
  return (
    <Page
      title="Transaction Batch"
      breadcrumb={{
        text: 'Payments',
        title: 'Back to Payments',
        link: `/payments`,
      }}
    >
      <TransactionBatchDetails />
    </Page>
  );
};
