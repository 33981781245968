import { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Stack,
  TextField as MuiTextField,
  Grid,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { IAddress, ISiteGetResponse, IVerifyAddressRes } from '../../models';
import { formatDate, zipCodeRegExp } from '../../helpers';
import { useQuery } from 'react-query';
import { verifyAddress } from '../../fetch';
import { useSnackbar } from 'notistack';
import { AddressAutocomplete, Loader } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { PinMap } from '../../pages/sites/pin-map';
import { useConfirm } from '../../hooks';
const { BING_MAP_KEY } = require('../../buildSettings.json');

declare let window: {
  bingAPIReady?: () => void;
  BingMap: Microsoft.Maps.Map;
  activePushPin?: Microsoft.Maps.Pushpin;
};

interface IEditAddressInformationProps {
  accountAddress?: IAddress | null;
  handleFormChange?: (val?: any) => void;
  pushPinLocation: Microsoft.Maps.Location | null;
  setPushPinLocation: (val: Microsoft.Maps.Location | null) => void;
  site?: ISiteGetResponse;
  showAccountAddressButton?: boolean;
  addressNameLabel?: string;
  isNewCustomer?: boolean;
  isOfficeAddress?: boolean;
}

export const EditAddressInformation: FC<IEditAddressInformationProps> = ({
  showAccountAddressButton = false,
  accountAddress,
  handleFormChange,
  pushPinLocation,
  setPushPinLocation,
  site,
  addressNameLabel = 'Address Name',
  isNewCustomer,
  isOfficeAddress = false,
}) => {
  const [showMap, setShowMap] = useState(false);
  const [newLat, setNewLat] = useState<number[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue, setFieldTouched, validateForm, handleBlur, values, errors, touched } =
    useFormikContext<any>();
  const confirm = useConfirm();
  const nameKey = isNewCustomer ? `newCustomer.addressName` : 'addressName';
  const streetKey = isNewCustomer ? `newCustomer.street` : 'street';
  const stateKey = isNewCustomer ? `newCustomer.state` : 'state';
  const postalCodeKey = isNewCustomer ? `newCustomer.postalCode` : 'postalCode';
  const cityKey = isNewCustomer ? `newCustomer.city` : 'city';
  const latitudeKey = isNewCustomer ? `newCustomer.latitude` : 'latitude';
  const longitudeKey = isNewCustomer ? `newCustomer.longitude` : 'longitude';

  const nameValue = isNewCustomer ? values.newCustomer.addressName : values.addressName;
  const streetValue = isNewCustomer ? values.newCustomer.street : values.street;
  const cityValue = isNewCustomer ? values.newCustomer.city : values.city;
  const stateValue = isNewCustomer ? values.newCustomer.state : values.state;
  const postalCodeValue = isNewCustomer ? values.newCustomer.postalCode : values.postalCode;
  const latValue = isNewCustomer ? values.newCustomer.latitude : values.latitude;
  const lonValue = isNewCustomer ? values.newCustomer.longitude : values.longitude;

  const streetError = isNewCustomer
    ? // @ts-ignore
    errors?.newCustomer?.street && touched?.newCustomer?.street
    : errors?.street && touched?.street;
  const postalCodeError = isNewCustomer
    ? // @ts-ignore
    errors?.newCustomer?.postalCode && touched?.newCustomer?.postalCode
    : errors?.postalCode && touched?.postalCode;

  // custom effect her for new customers form to keep track when the lat/lng changes in the parent component
  useEffect(() => {
    if (isNewCustomer) {
      handleFormChange?.();
    }
    // eslint-disable-next-line
  }, [isNewCustomer, pushPinLocation]);

  const { isFetching: isVerifying, refetch: handleVerify } = useQuery<IVerifyAddressRes, Error>(
    ['verifyAddress', values],
    () => {
      return verifyAddress({
        addressId: '',
        street: streetValue,
        state: stateValue,
        zipCode: postalCodeValue,
        city: cityValue,
        latitude: latValue,
        longitude: lonValue,
      });
    },
    {
      enabled: false,
      onSuccess: d => {
        if (d.isVerified && d.address) {
          setFieldValue('whenVerified', new Date().toISOString());
          updateField(streetKey, d.address.street);
          updateField(cityKey, d.address.city);
          updateField(stateKey, d.address.state);
          updateField(postalCodeKey, d.address.zipCode);
          updateField(latitudeKey, d.address.latitude.toString());
          updateField(longitudeKey, d.address.longitude.toString());
          setPushPinLocation(null);
          setShowMap(false);
          setFieldValue('manualLocation', false);
          handleFormChange?.({
            ...values,
            [streetKey]: d.address.street,
            [cityKey]: d.address.city,
            [stateKey]: d.address.state,
            [postalCodeKey]: d.address.zipCode,
            [latitudeKey]: d.address.latitude,
            [longitudeKey]: d.address.longitude,
            isVerified: true,
          });
          return enqueueSnackbar('Address is verified and updated!', {
            variant: 'success',
          });
        }
        setShowMap(true);
        setFieldValue('whenVerified', '');
        enqueueSnackbar(d.verificationMessage, {
          variant: 'info',
          autoHideDuration: 10000,
        });
      },
    }
  );

  const updateField = (name: string, value = '', shouldResetAccountAddress?: boolean) => {
    setFieldValue(name, value);
    setFieldTouched(name, false, true); // Reset validation error
    if (shouldResetAccountAddress) {
      setFieldValue('useAccountAddress', false);
    }
  };
  const handleAddressSourceChange = (checked: boolean) => {
    setFieldValue('useAccountAddress', checked);

    if (checked) {
      updateField('addressName', accountAddress?.addressName ?? '');
      updateField('street', accountAddress?.street ?? '');
      updateField('city', accountAddress?.city ?? '');
      updateField('state', accountAddress?.state ?? '');
      updateField('postalCode', accountAddress?.postalCode ?? '');
      updateField('latitude', accountAddress?.latitude?.toString() ?? '');
      updateField('longitude', accountAddress?.longitude?.toString() ?? '');
    }

    setTimeout(() => {
      validateForm();
      handleFormChange?.({
        ...values,
        useAccountAddress: checked,
        addressName: accountAddress?.addressName ?? '',
        street: accountAddress?.street ?? '',
        city: accountAddress?.city ?? '',
        state: accountAddress?.state ?? '',
        postalCode: accountAddress?.postalCode ?? '',
        latitude: accountAddress?.latitude ?? '',
        longitude: accountAddress?.longitude ?? '',
      });
    }, 10);
  };
  const getCoordinates = async (zipCode: string) => {
    try {
      // using fetch here because axios was giving CORS errors
      const res = await fetch(
        `https://dev.virtualearth.net/REST/v1/Locations/US/${zipCode}?key=${BING_MAP_KEY}`
      );
      const json = await res.json();
      setNewLat(json?.resourceSets?.[0]?.resources?.[0]?.point?.coordinates ?? []);
    } catch (error) { }
  };

  const handleLatAndLongOverride = async (
    latitude: string | number,
    longitude: string | number
  ) => {
    try {
      const message =
        'Are you sure you want to continue?\nThese changes will overwrite the current latitude and longitude for this address.';
      const result = await confirm(message);
      if (result) {
        updateField(latitudeKey, latitude as string, true);
        updateField(longitudeKey, longitude as string, true);
        enqueueSnackbar(`Latitude and Longitude updated!`, {
          variant: 'success',
        });
      } else {
        updateField(latitudeKey, '', true);
        updateField(longitudeKey, '', true);
        window.BingMap.entities.removeAt(0);
        setPushPinLocation(null);
        window.activePushPin = undefined;
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error updating latitude and longitude, please try again.`, {
        variant: 'error',
      });
    }
  };
  return (
    <>
      {isVerifying && <Loader position="centered" type="overlay" title="Verifying..." />}
      <Stack gap={2.5}>
        {showAccountAddressButton && (
          <Box>
            <Button
              color="secondary"
              onClick={() => handleAddressSourceChange(true)}
              disabled={values.useAccountAddress}
              data-testid='reset-to-account-address-button'
            >
              Reset to Account Address
            </Button>
          </Box>
        )}
        {!isOfficeAddress && (
          <MuiTextField
            name={nameKey}
            label={addressNameLabel}
            required
            fullWidth
            size="small"
            value={nameValue}
            onBlur={e => {
              handleBlur(e);
            }}
            onChange={e => {
              updateField(nameKey, e.target.value, true);
              handleFormChange?.();
            }}
            inputProps={{
              'data-testid': 'address-name-field',
            }}
          />
        )}

        <AddressAutocomplete
          helperText={!isOfficeAddress && 'Search to populate fields with an existing address'}
          option={{
            street: streetValue ?? site?.street ?? '',
            secondary: '',
            city: '',
            state: '',
            zipCode: '',
          }}
          label="Street"
          isFreeSolo={true}
          required
          name={streetKey}
          handleChange={val => {
            updateField(
              streetKey,
              (val?.inputValue ?? val?.street ?? '') + (val?.secondary ?? ''),
              true
            );
            updateField(cityKey, val?.city, true);
            updateField(stateKey, val?.state, true);
            updateField(postalCodeKey, val?.zipCode, true);
            updateField(latitudeKey, val?.latitude?.toString(), true);
            updateField(longitudeKey, val?.longitude?.toString(), true);
            setTimeout(() => {
              validateForm();
              handleFormChange?.();
              if (
                (!!val?.street || !!val?.city || !!val?.state || !!val?.zipCode) &&
                !val?.inputValue
              ) {
                handleVerify();
              }
            }, 10);
          }}
          handleBlur={e => {
            handleBlur(e);
            setTimeout(() => {
              validateForm();
              handleFormChange?.();
            }, 10);
          }}
          error={streetError}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <MuiTextField
              name={cityKey}
              label="City"
              fullWidth
              size="small"
              required
              value={cityValue}
              onBlur={e => {
                handleBlur(e);
                handleFormChange?.();
              }}
              onChange={e => {
                updateField(cityKey, e.target.value, true);
                handleFormChange?.();
              }}
              inputProps={{
                'data-testid': 'city-field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiTextField
              name={stateKey}
              label="State"
              fullWidth
              size="small"
              required
              value={stateValue}
              onBlur={e => {
                handleBlur(e);
                handleFormChange?.();
              }}
              onChange={e => {
                updateField(stateKey, e.target.value, true);
                handleFormChange?.();
              }}
              inputProps={{
                'data-testid': 'state-field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiTextField
              name={postalCodeKey}
              label="Postal Code"
              required
              fullWidth
              size="small"
              value={postalCodeValue}
              onBlur={e => {
                handleBlur(e);
                handleFormChange?.();
              }}
              onChange={e => {
                const { value } = e.target;
                updateField(postalCodeKey, value, true);
                if (value && value.match(zipCodeRegExp) !== null) {
                  getCoordinates(value);
                }
                handleFormChange?.();
              }}
              error={postalCodeError}
              helperText={postalCodeError ? 'Required' : ''}
              inputProps={{
                'data-lpignore': 'true',
                'data-testid': 'postal-code-field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <MuiTextField
              name={latitudeKey}
              label="Latitude"
              fullWidth
              size="small"
              value={latValue}
              required
              onBlur={e => {
                if (latValue === '0' || latValue === 0) {
                  updateField(latitudeKey, '', true);
                }
                handleBlur(e);
                handleFormChange?.();
              }}
              onChange={e => {
                updateField(latitudeKey, e.target.value, true);
                handleFormChange?.();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                'data-testid': 'latitude-field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <MuiTextField
              name={longitudeKey}
              label="Longitude"
              fullWidth
              size="small"
              value={lonValue}
              required
              onBlur={e => {
                if (lonValue === '0' || lonValue === 0) {
                  updateField(longitudeKey, '', true);
                }
                handleBlur(e);
                handleFormChange?.();
              }}
              onChange={e => {
                updateField(longitudeKey, e.target.value, true);
                handleFormChange?.();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                'data-testid': 'longitude-field',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box display="flex" alignItems="center" gap={2}>
              <Button
                disabled={
                  !streetValue ||
                  !cityValue ||
                  !stateValue ||
                  !postalCodeValue ||
                  postalCodeValue.match(zipCodeRegExp) === null
                }
                color="primary"
                variant="contained"
                onClick={() => handleVerify()}
                data-testid="verify-address-button"
              >
                Verify Address
              </Button>
              <Tooltip title={showMap ? 'Hide Map with pin' : 'See Map with pin'}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowMap(!showMap)}
                  startIcon={<FontAwesomeIcon icon={faLocationPin} />}
                  data-testid="view-map-button"
                >
                  View Map
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="caption">
                <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                  Latitude:
                </Typography>{' '}
                {pushPinLocation?.latitude ?? latValue}
              </Typography>
              <Typography variant="caption">
                <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                  Longitude:
                </Typography>{' '}
                {pushPinLocation?.longitude ?? lonValue}
              </Typography>
            </Box>
          </Grid>
          {values.whenVerified && (
            <Grid item xs={12} md={4}>
              <Typography component="span">When Verified:</Typography>{' '}
              <Typography color="secondary" component="span">
                {formatDate(new Date(values.whenVerified))}
              </Typography>
            </Grid>
          )}
        </Grid>

        {showMap && (
          <PinMap
            mapCenter={
              !!newLat
                ? [newLat[0], newLat[1]]
                : pushPinLocation?.latitude || !!latValue
                  ? [
                    pushPinLocation?.latitude ?? (latValue as number),
                    pushPinLocation?.longitude ?? (lonValue as number),
                  ]
                  : undefined
            }
            setPushPinLocation={data => {
              setPushPinLocation(data);
              const latitude = data?.latitude === undefined ? '' : data?.latitude;
              const longitude = data?.longitude === undefined ? '' : data?.longitude;

              const sameLatitude = Number(latValue) === Number(latitude);
              const sameLongitude = Number(lonValue) === Number(longitude);

              if (
                latitude !== '' &&
                longitude !== '' &&
                values.whenVerified !== '' &&
                (!sameLatitude || !sameLongitude)
              ) {
                handleLatAndLongOverride(latitude, longitude);
              } else {
                setFieldValue(latitudeKey, latitude, true);
                setFieldValue(longitudeKey, longitude, true);
              }
            }}
            pushPinLocation={
              pushPinLocation ??
              (!!latValue && !!lonValue
                ? ({
                  latitude: latValue,
                  longitude: lonValue,
                } as any)
                : undefined)
            }
          />
        )}
      </Stack>
    </>
  );
};
