import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../../components';

export const PumpFilterCleanerDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the PumpFilterCleanerForm component as well
  // REMINDER: When adding fields, also update the pumpFilterCleanerSectionFields array in pool-structure-card
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={-2} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Pump Basket" labelId="pumpBasket">
          {values?.pumpBasket ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Air Relief" labelId="airRelief">
          {values?.airRelief ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Pool Motor" labelId="poolMotor">
          {values?.poolMotor ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Cleaner Style" labelId="cleanerStyle">
          {values?.cleanerStyle ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Pool HP" labelId="poolHp">
          {values?.poolHp ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Cleaner Pump" labelId="cleanerPump">
          {values?.cleanerPump ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Conduit" labelId="hasConduit">
          {values?.hasConduit ? 'Yes' : 'No'}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Cleaner Clock" labelId="cleanerClock">
          {values?.cleanerClock ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Conduit Condition" labelId="conduitCondition">
          {values?.conduitCondition ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Auto Cleaner" labelId="autoCleaner">
          {values?.autoCleaner ? 'Yes' : 'No'}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Time Clock" labelId="timeClock">
          {values?.timeClock ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Cleaner Volts" labelId="cleanerVolts">
          {values?.cleanerVolts ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Time Clock Volts" labelId="timeClockVolts">
          {values?.timeClockVolts ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
