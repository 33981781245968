import { DropResult } from 'react-beautiful-dnd';
import { IService, IServiceChangeItem, IServiceRoute, ITechnician } from '../../models';
import { isRouteEnd, isRouteStart } from './utils';

const DATA_DELIMITER = '_';

interface TechnicianDroppableIdOptions {
  routeId: string | number;
  userId: string;
  serviceDate: string;
  routeIndex: number;
  techIndex: number;
}

export const createTechnicianDroppableId = ({
  routeId,
  serviceDate,
  userId,
  routeIndex,
  techIndex,
}: TechnicianDroppableIdOptions) => {
  const parts = [routeId, userId, serviceDate, routeIndex, techIndex];
  return parts.join(DATA_DELIMITER);
};

export const parseTechnicianDroppableId = (droppableId: string): TechnicianDroppableIdOptions => {
  const parts = droppableId.split(DATA_DELIMITER);

  return {
    routeId: parts[0],
    userId: parts[1],
    serviceDate: parts[2],
    routeIndex: +parts[3],
    techIndex: +parts[4],
  };
};

interface ServiceDraggableIdOptions {
  routeId: string | number;
  userId: string;
  serviceId: string;
  routeIndex: number;
  techIndex: number;
  serviceIndex: number;
  version: string | null;
}

export const createServiceDraggableId = ({
  routeId,
  userId,
  serviceId,
  routeIndex,
  techIndex,
  serviceIndex,
  version,
}: ServiceDraggableIdOptions) => {
  const parts = [routeId, userId, serviceId, routeIndex, techIndex, serviceIndex, version];
  return parts.join(DATA_DELIMITER);
};

export const parseServiceDraggableId = (droppableId: string): ServiceDraggableIdOptions => {
  const parts = droppableId.split(DATA_DELIMITER);

  return {
    routeId: parts[0],
    userId: parts[1],
    serviceId: parts[2],
    routeIndex: +parts[3],
    techIndex: +parts[4],
    serviceIndex: +parts[5],
    version: parts[6],
  };
};

interface CreateDropResultChangeSetOptions {
  dropResult: DropResult;
  selectedDraggableIds?: string[];
  serviceRoutes: IServiceRoute[];
  selectedTechs: ITechnician[];
}

export const createDropResultChangeSet = ({
  dropResult,
  selectedDraggableIds = [],
  serviceRoutes,
  selectedTechs,
}: CreateDropResultChangeSetOptions) => {
  const { source, destination, draggableId } = dropResult;

  if (!destination) {
    return [];
  }
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return [];
  }

  const isMultiDrag = selectedDraggableIds.length;

  const sourceDroppable = parseTechnicianDroppableId(source.droppableId);
  const targetDroppable = parseTechnicianDroppableId(destination.droppableId);
  let draggableIds = [draggableId];

  if (isMultiDrag) {
    draggableIds = [...selectedDraggableIds];
  }

  let destinationIndex = destination.index;

  const withinSameRoute = sourceDroppable.routeIndex === targetDroppable.routeIndex;
  const withinSameTech = withinSameRoute && sourceDroppable.techIndex === targetDroppable.techIndex;

  if (withinSameTech && isMultiDrag) {
    // grab by finding the correct tech based on the userId because the tech index could be wrong if you filtered the techs down
    const targetTech = serviceRoutes[targetDroppable.routeIndex].technicians.find(
      tech => tech.userId === targetDroppable.userId
    );
    const dragged = draggableIds.map(parseServiceDraggableId);

    const checkIfDragging = (service: IService) =>
      !!dragged.find(d => d.serviceId === service.scheduledServiceId);

    const draggedToService = targetTech!.services[destinationIndex];
    const draggedToServiceIsDragging = !!checkIfDragging(draggedToService);

    const nonDraggingServices = targetTech!.services.filter(s => !checkIfDragging(s));

    if (draggedToServiceIsDragging) {
      const nextNonDraggingService = targetTech!.services.find((s, index) => {
        if (index <= destination.index) {
          return false;
        }
        const isDragging = checkIfDragging(s);
        return !isDragging;
      });

      if (!nextNonDraggingService) {
        destinationIndex = 1;
      } else if (isRouteStart(nextNonDraggingService)) {
        destinationIndex = 1;
      } else if (isRouteEnd(nextNonDraggingService)) {
        destinationIndex = nonDraggingServices.length - 2;
      } else {
        const nextNonDraggingServiceIndex = nonDraggingServices.findIndex(
          s => s.scheduledServiceId === nextNonDraggingService?.scheduledServiceId
        );
        destinationIndex = nextNonDraggingServiceIndex;
      }
    } else {
      const isDraggingDown = destination.index > source.index;
      destinationIndex = nonDraggingServices.findIndex(
        s => s.scheduledServiceId === draggedToService.scheduledServiceId
      );

      if (isDraggingDown) {
        destinationIndex++;
      }
    }
  }
  const changeSet: IServiceChangeItem[] = [];
  draggableIds.forEach((id, index) => {
    const { serviceId, serviceIndex, version, routeIndex, userId } = parseServiceDraggableId(id);
    // special check here when the routes are being filtered to specific tech(s)
    // so we grab the correct tech index for all of the overall services even though we are filtered down
    const fromTechs = serviceRoutes
      .filter(s => s.serviceDate === sourceDroppable.serviceDate)
      .flatMap(s => s.technicians);
    const toTechs = serviceRoutes
      .filter(s => s.serviceDate === targetDroppable.serviceDate)
      .flatMap(s => s.technicians);
    const newFromTechIndex = fromTechs.findIndex(tech => tech.userId === userId);
    const newToTechIndex = toTechs.findIndex(tech => tech.userId === targetDroppable.userId);
    const change: IServiceChangeItem = {
      scheduledServiceId: serviceId,
      version,
      from: {
        serviceDate: sourceDroppable.serviceDate,
        techIndex: newFromTechIndex,
        routeIndex,
        userId,
        serviceIndex,
      },
      to: {
        userId: targetDroppable.userId,
        serviceDate: targetDroppable.serviceDate,
        routeIndex: targetDroppable.routeIndex,
        techIndex: newToTechIndex,
        serviceIndex: destinationIndex + index,
      },
    };
    changeSet.push(change);
  });

  return changeSet;
};
