import { FC, useContext } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory } from 'react-router-dom';
import { Stack, Button, Box } from '@mui/material';
import { Formik, FormikHelpers, FormikProps, Form } from 'formik';
import {
  FullPageImageLayout,
  PublicPageTitle,
  PasswordSecurity,
  Link,
  Loader,
  AuthInput,
} from '../../components';
import { UserContext } from '../../context';
import { changePassword } from '../../fetch';
import { passwordRegex } from '../../helpers';
import { IChangePasswordPost } from '../../models';
import { BrandingContext } from '../../context/branding-context';

interface IWelcomeValues {
  newPassword: string;
  currentPassword: string;
  password: string;
  passwordHint: string;
}

const Schema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  password: Yup.string()
    .required('Required')
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid password',
    }),
  newPassword: Yup.string()
    .required('Required')
    .min(12, 'Password must be at least 12 characters')
    .matches(passwordRegex, {
      message: 'Invalid Password',
    })
    .oneOf([Yup.ref('password'), null], `Passwords don't match`),
  passwordHint: Yup.string().required('Required'),
});

export const WelcomePageDetails: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isFetching, loadUser } = useContext(UserContext);
  const { appLongName } = useContext(BrandingContext);
  const history = useHistory();

  const handleOnSubmit = () => {
    setTimeout(function () {
      history.push(`/`);
    }, 3000);
  };

  // have flag and checking state of user
  if (isFetching) {
    return <Loader type="overlay" position="centered" />;
  }
  // have flag and we have a user and they are loaded redirect to correct page
  // Prevent users from getting to this screen without a token in the URL
  // or someone passes in an override flag
  if (user && !isFetching && !user?.mustChangePassword) {
    return <Redirect to="/" />;
  }

  return (
    <Formik
      initialValues={{
        newPassword: '',
        currentPassword: '',
        passwordHint: '',
        password: '',
      }}
      onSubmit={async (values: IWelcomeValues, actions: FormikHelpers<IWelcomeValues>) => {
        const data: IChangePasswordPost = {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          passwordHint: values.passwordHint,
        };
        try {
          await changePassword(data);

          enqueueSnackbar(`Password set successfully. Logging in...`, {
            variant: 'success',
            onClose: () => {
              handleOnSubmit();
            },
          });

          actions.resetForm({
            values: {
              newPassword: '',
              password: '',
              currentPassword: '',
              passwordHint: '',
            },
          });

          loadUser();
        } catch (error: any) {
          if (error?.Detail) {
            return enqueueSnackbar(error?.Detail ?? `Error, ${error.Detail}`, {
              variant: 'error',
            });
          }
          actions.resetForm();
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={Schema}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        setFieldValue,
        isValid,
        isSubmitting,
        dirty,
        handleSubmit,
      }: FormikProps<IWelcomeValues>) => {
        return (
          <FullPageImageLayout>
            <PublicPageTitle
              title="Welcome"
              subTitle={`Welcome to ${appLongName}! First, you must change your password. Please enter and confirm a new password below. Also, please enter a password hint, which can be emailed to you in case you ever forget your password.`}
            />
            <Form
              data-testid="setup-password-form"
              id="setup-password-form"
              onSubmit={handleSubmit}
            >
              <Stack
                gap={3}
                mt={{
                  xs: 2,
                  sm: 4,
                }}
              >
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  inputKey="currentPassword"
                  label="Tempory Password"
                  onChange={e => setFieldValue('currentPassword', e.target.value)}
                  onBlur={handleBlur}
                  id="setup-currentPassword"
                  type="password"
                />
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  inputKey="password"
                  label="Password"
                  onChange={e => setFieldValue('password', e.target.value)}
                  onBlur={handleBlur}
                  id="setup-currentPassword"
                  type="password"
                />
                <PasswordSecurity />
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  inputKey="newPassword"
                  label="Confirm Password"
                  onChange={e => setFieldValue('newPassword', e.target.value)}
                  onBlur={handleBlur}
                  id="setup-currentPassword"
                  type="password"
                />
                <AuthInput
                  errors={errors}
                  values={values}
                  touched={touched}
                  inputKey="passwordHint"
                  label="Password Hint"
                  onChange={e => setFieldValue('passwordHint', e.target.value)}
                  onBlur={handleBlur}
                  id="setup-currentPassword"
                />
                <Button
                  // @ts-ignore
                  color="tertiary"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    padding: theme => theme.spacing(1.5),
                    borderRadius: '30px',
                  }}
                  size="large"
                  disabled={!dirty || isSubmitting || !isValid}
                  id="submit"
                  type="submit"
                  onKeyDown={(e: { key: any }) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                >
                  {!isSubmitting ? 'Save & Login' : 'Updating...'}
                </Button>
              </Stack>
              <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                <Link to={`/logout`} hasTextDecoration={false} type="secondary">
                  <strong>Back to Login?</strong>
                </Link>
              </Box>
            </Form>
          </FullPageImageLayout>
        );
      }}
    </Formik>
  );
};
