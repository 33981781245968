import { CardTitle, Loader } from '..';
import { FC, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { getReportParameters, generateReportAndSave, getReport, generateReport } from '../../fetch';
import { IGenerateReportParameter, IReport, IReportParameter, ReportFormat } from '../../models';
import { UserContext } from '../../context';
import { ReportParameterForm } from './parameter-form';
import { Container, Grid, Box } from '@mui/material';
import { Card } from '../../components';
import { scrollToTop, downloadFile } from '../../helpers';
import { useSnackbar } from 'notistack';
import { ReportPdfPreview } from './pdf-viewer';

export interface GenerateReportProps {
  reportId: string;
  onReportLoad?: (report: IReport) => unknown;
  onParametersLoad?: (
    parameters: IReportParameter[]
  ) => IReportParameter[] | Promise<IReportParameter[]>;
  generateImmediately?: boolean;
  setStartDate?: Dispatch<SetStateAction<string>>;
  setEndDate?: Dispatch<SetStateAction<string>>;
  afterFormContent?: any;
  generateOnBlur?: boolean;
}

export const GenerateReport: FC<GenerateReportProps> = ({
  reportId,
  onReportLoad,
  onParametersLoad,
  generateImmediately,
  setStartDate,
  setEndDate,
  afterFormContent,
  generateOnBlur = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [, setReport] = useState<IReport | null>();
  const [reportGuid, setReportGuid] = useState<string | null>(null);
  const [reportParams, setReportParams] = useState<IReportParameter[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [initialGenerated, setInitialGenerated] = useState(false);

  const loadReportMetadata = async (reportId: string) => {
    try {
      setIsLoading(true);
      setReport(null);
      setReportParams([]);
      let [report, params] = await Promise.all([
        getReport(reportId),
        getReportParameters(user?.officeId as string, reportId),
      ]);
      onReportLoad?.(report);
      if (onParametersLoad) {
        params = await onParametersLoad(params);
      }
      setReport(report);
      setReportParams(params);
    } finally {
      setIsLoading(false);
    }
  };

  const generateReportPreview = async (values: Record<string, string>) => {
    try {
      scrollToTop();
      setIsGenerating(true);
      setInitialGenerated(true);
      setReportGuid(null);
      const parameters: IGenerateReportParameter[] = [];
      Object.entries(values).forEach(([key, value]) => {
        parameters.push({
          reportParameterId: key,
          value: value,
        });
      });
      const generatedReportGuid = await generateReportAndSave(reportId, {
        parameters,
      });
      setReportGuid(generatedReportGuid);
    } catch (error: any) {
      enqueueSnackbar('An error occurred while generating your report', {
        variant: 'error',
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const downloadReportAsExcel = async (values: Record<string, string>) => {
    try {
      setIsExporting(true);
      const parameters: IGenerateReportParameter[] = [];
      Object.entries(values).forEach(([key, value]) => {
        parameters.push({
          reportParameterId: key,
          value: value,
        });
      });
      
      const res = await generateReport(reportId, {
        parameters,
      }, ReportFormat.Xls);
      
      downloadFile(res, 'export.xls');
    } catch (e) {
      enqueueSnackbar('An error occurred while generating the report', {
        variant: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    setInitialGenerated(false);
    loadReportMetadata(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const renederParamsForm = () => {
    if (!reportParams.length) {
      return null;
    }

    return (
      <Card>
        <CardTitle title="Parameters" mobileWrap />
        <ReportParameterForm
          parameters={reportParams}
          onSubmit={generateReportPreview}
          onExport={downloadReportAsExcel}
          isSubmitting={isGenerating}
          isExporting={isExporting}
          submitImmediately={generateImmediately}
          generateOnBlur={generateOnBlur}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isForm
        />
        {afterFormContent}
      </Card>
    );
  };

  return (
    <div>
      {isLoading && (
        <Box mt={2}>
          <Loader position="centered" />
        </Box>
      )}
      {!isLoading && (
        <>
          {!initialGenerated && <Container maxWidth="sm">{renederParamsForm()}</Container>}
          {initialGenerated && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                {renederParamsForm()}
              </Grid>
              <Grid item xs={12} md={9}>
                {isGenerating && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="30rem"
                    justifyContent="center"
                    sx={{ background: theme => theme.palette.common.white }}
                  >
                    <Loader position="centered" />
                  </Box>
                )}
                {!!reportGuid && <ReportPdfPreview reportGuid={reportGuid} />}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};
