import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { IParameterInputProps } from '../../../../models';

export const RadioButtonsParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {
  const { parameterName, promptText } = parameter;

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    onChange(newValue, parameter);
  };

  return (
    <FormControl>
      <FormLabel>{promptText}</FormLabel>
      <RadioGroup name={parameterName} value={value} onChange={handleChange}>
        {parameter.selectListItems.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
