import { FC, useEffect, useMemo, useState } from 'react';
import { EDaysOfWeek, ICreateTechnicianUser, ITechnicianDayOff, ITechnicianUpdatedDayOff, IUpdateTechnicianUser } from '../../../models';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { Loader, Modal, ModalSaveSection } from '../../../components';
import { Fade } from '@mui/material';
import * as Yup from 'yup';
import { AddEditTechManagementModalContent } from './tech-management-add-edit-modal-content';
import { useQuery } from 'react-query';
import { createTechnicianUser, getTechnicianDaysOff, getTechnicianUser, updateTechnicianUser } from '../../../fetch';

interface ITechManagementAddEditModalProps {
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  selectedTechnicianId: string | null;
  selectedTechnicianName: string | null;
}

const AddEditTechManagementSchema = Yup.object().shape({
  userId: Yup.string().required('Required'),
  daysAvailable: Yup.array().of(Yup.string().nullable()).nullable(),
  serviceLocationId: Yup.string().nullable(),
  roles: Yup.array(),
});

export const AddEditTechManagementModal: FC<ITechManagementAddEditModalProps> = ({
  isOpen,
  onClose,
  selectedTechnicianId,
  selectedTechnicianName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [daysOff, setDaysOff] = useState<ITechnicianDayOff[]>([]);
  const [deletedDaysOff, setDeletedDaysOff] = useState<string[]>([]);
  const [addedDaysOff, setAddedDaysOff] = useState<{
    dayOff: string;
    hasServices: boolean;
  }[]>([]);
  const [updatedDaysOff, setUpdatedDaysOff] = useState<ITechnicianUpdatedDayOff[]>([]);

  const { isLoading: isLoadingTechnician, data: technicianData } = useQuery(
    ['getTechnicianUser', selectedTechnicianId],
    () => getTechnicianUser(selectedTechnicianId!),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!isOpen && !!selectedTechnicianId,
    }
  );
  const { isLoading: isLoadingDaysOff, data: technicianDaysOff } = useQuery(
    ['getTechnicianDaysOff', selectedTechnicianId],
    () => getTechnicianDaysOff(
      selectedTechnicianId!,
      { sortDirection: 'desc', perPage: -1, sortBy: 'dayOff' }
    ),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!isOpen && !!selectedTechnicianId,
    }
  );
  const modalTitle = useMemo(() => {
    const titleContext = !!selectedTechnicianId ? 'Edit' : 'Add';
    const title = !!selectedTechnicianName ? selectedTechnicianName : 'Technician User';
    return `${titleContext} ${title}`;
  }, [selectedTechnicianName, selectedTechnicianId]);

  const resetDaysOff = () => {
    setDaysOff([]);
    setDeletedDaysOff([]);
    setAddedDaysOff([]);
    setUpdatedDaysOff([]);
  };

  useEffect(() => {
    technicianDaysOff && setDaysOff(
      technicianDaysOff.records.map((day: ITechnicianDayOff) => day ?? '')
    );
  }, [technicianDaysOff]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          userId: technicianData?.userId ?? '',
          daysAvailable: technicianData?.daysAvailable ?? [
            EDaysOfWeek.MONDAY,
            EDaysOfWeek.TUESDAY,
            EDaysOfWeek.WEDNESDAY,
            EDaysOfWeek.THURSDAY,
            EDaysOfWeek.FRIDAY,
            EDaysOfWeek.SATURDAY,
            EDaysOfWeek.SUNDAY,
          ],
          serviceLocationId: technicianData?.serviceLocationId ?? null,
          roles: technicianData?.roles ?? [],
        }}
        validationSchema={AddEditTechManagementSchema}
        onSubmit={async (values, actions) => {
          try {
            const updatePayload: IUpdateTechnicianUser = {
              daysAvailable: values.daysAvailable,
              serviceLocationId: values.serviceLocationId,
              roles: values.roles,
              addedDaysOff: addedDaysOff.map(d => d.dayOff) ?? [],
              updatedDaysOff: updatedDaysOff ?? [],
              deletedDaysOff: deletedDaysOff ?? [],
            };
            const createPayload: ICreateTechnicianUser = {
              daysAvailable: values.daysAvailable,
              serviceLocationId: values.serviceLocationId,
              roles: values.roles,
              userId: values.userId,
              daysOff: addedDaysOff.map(d => d.dayOff) ?? [],
            };
            selectedTechnicianId
              ? await updateTechnicianUser(selectedTechnicianId, updatePayload)
              : await createTechnicianUser(createPayload);
            enqueueSnackbar(
              `Successfully ${!selectedTechnicianId ? 'created' : 'updated'} technician!`,
              {
                variant: 'success',
              }
            );
            onClose(true);
            actions.resetForm();
            resetDaysOff();
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving technician. Please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, values, handleSubmit, dirty, isValid, errors }) => {
          const handleClose = () => {
            resetForm();
            resetDaysOff();
            onClose();
          };

          return (
            <Modal open={isOpen} onClose={() => handleClose()} maxWidth="sm" title={modalTitle}>
              {isSubmitting && <Loader type="overlay" position="centered" title="Saving..." />}
              {(isLoadingTechnician || isLoadingDaysOff) && <Loader type="overlay" position="centered" />}
              <Fade in={isOpen}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <AddEditTechManagementModalContent
                    selectedTech={selectedTechnicianId}
                    daysOff={daysOff}
                    setDaysOff={setDaysOff}
                    deletedDaysOff={deletedDaysOff}
                    setDeletedDaysOff={setDeletedDaysOff}
                    updatedDaysOff={updatedDaysOff}
                    setUpdatedDaysOff={setUpdatedDaysOff}
                    addedDaysOff={addedDaysOff}
                    setAddedDaysOff={setAddedDaysOff}
                  />
                  <ModalSaveSection
                    isSaveDisabled={
                      !isValid
                    }
                    handleCancel={() => {
                      handleClose();
                    }}
                    handleSave={handleSubmit}
                  />
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
