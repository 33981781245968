export interface ISite {
  siteId: string;
  addressId: string;
  siteName: string;
  accountName: string;
  address: ISiteAddress;
  description: string;
  managedBy?: string;
  supervisor?: string;
  chargeForChemicals?: boolean;
  canDelete?: boolean;
}
export interface ISiteSimple {
  siteId: string;
  addressId: string;
  accountId: string;
  accountName: string;
  accountStatus?: string;
  address: ISiteAddressSimple;
  description: string;
  chargeForChemicals?: boolean;
  additionalSiteInformation?: ISiteDefinitionValue[];
}

export interface ISiteAddressSimple {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  hasValidLatLong: boolean;
}

export interface ISiteAddress {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  latitude?: number;
  longitude?: number;
}

export interface ISiteAccountAddress {
  addressName?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
}

export interface ISiteDefinition {
  userDefinedSiteDefInfoId: string;
  userDefinedSiteDefId: string;
  officeId: string;
  description: string;
  code: string;
  sortOrder: string | number;
  isDeleted: boolean;
}

export interface ISiteDefinitionValue extends ISiteDefinition {
  value: string;
}

export interface ISiteGetResponse {
  siteId: string;
  siteDescription: string;
  hasPoolEquipment: boolean;
  accountName: string;
  supervisor: string;
  userId: string;
  managedBy: string;
  mgrAccountId: string;
  useAccountAddress: boolean;
  addressName: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
  chargeForChemicals?: boolean;
  additionalSiteInformation: ISiteDefinitionValue[];
  canDelete?: boolean;
  whenVerified: string | null;
  manualLocation: boolean | null;
  isVerified?: boolean;
}

export interface IEquipmentLookup {
  name: string;
  manufacturer: string;
}

export type EquipmentType = 'heaters' | 'automatic-chlorinators' | 'pool-pumps' | 'filters';

export interface IPoolEquipment {
  poolEquipmentId?: string;
  poolId?: string;
  filter?: string;
  poolPump?: string;
  heater?: string;
  automaticControls?: string;
  backwash?: string;
  automaticChlorinator?: string;
  filterPressure?: number | null;
  timerBrandModel?: string;
  otherEquipment?: string;
  waterFeature?: string;
  piping?: string;
  dualMainDrain?: string;
  drainCover?: string;
  suctionSafetyLock?: string;
  secondaryBarrier?: string;
  safetyVacuumRelease?: string;
  boards?: string;
  additionalRecommendations?: string;
  size?: string;
  depth?: string;
  tile?: string;
  skimBasket?: string;
  coping?: string;
  skimCover?: string;
  mastic?: string;
  coverType?: string;
  poolType?: string;
  notes?: string;
  hasSpaBoosterPump?: boolean;
  spaVolume?: string;
  spaBoosterStyle?: string;
  spaBrand?: string;
  spaBoosterHp?: string;
  spaModel?: string;
  spaSerialNumber?: string;
  spaDateOfPurchase?: string;
  pumpBasket?: string;
  airRelief?: string;
  poolMotor?: string;
  cleanerStyle?: string;
  poolHp?: string;
  cleanerPump?: string;
  hasConduit?: boolean;
  cleanerClock?: string;
  conduitCondition?: string;
  autoCleaner?: boolean;
  timeClock?: string;
  cleanerVolts?: string;
  timeClockVolts?: string;
  gauge?: string;
  valveSize?: string;
  mainDrain?: string;
  controlValve?: string;
  condition?: string;
  // Structure Details
  appearance?: string | null; // Maps to Shape
  surface?: string | null; // Maps to Finish
  poolVolume?: string | null; // Maps to Gallons
  sanitizer?: string | null;
  hasSpa?: boolean | null;
  saltChlorinator?: string | null;
  algaeType?: string;
  stains?: string | null;
  poolFinish?: string | null; // Maps to Pool Finish Condition
  lastResurfaced?: number | null;
  poolBuilt?: number | null;
  showLegacyInfo: boolean;
}

export interface IPoolEquipmentShape {
  shape: string | null;
}

export interface IPoolEquipmentFinish {
  finish: string | null;
}

export interface IPoolEquipmentSanitizer {
  sanitizer: string | null;
}

export enum EPoolEquipmentTab {
  StructureDetails = 'structure-details',
  StructureEquipment = 'structure-equipment',
  SafetyChecklist = 'safety-checklist',
  LegacyPoolInfo = 'legacy-pool-info',
}
