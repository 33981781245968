import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../components';

export const SafetyChecklistDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the SafetyChecklistForm component as well
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={0} mb={1}>
      <DisplayColumn lg={4}>
        <DisplayGroup label="Dual Main Drain" labelId="dualMainDrain">
          {values?.dualMainDrain ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn lg={4}>
        <DisplayGroup label="Approved Drain Covers in Pool and/or Spa" labelId="drainCover">
          {values?.drainCover ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn lg={4}>
        <DisplayGroup
          label="If dedicated suction line, is there a safety lock"
          labelId="suctionSafetyLock"
        >
          {values?.suctionSafetyLock ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn lg={4}>
        <DisplayGroup label="Secondary barrier fence or safety cover" labelId="secondaryBarrier">
          {values?.secondaryBarrier ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn lg={4}>
        <DisplayGroup label="Safety Vacuum Release System" labelId="safetyVacuumRelease">
          {values?.safetyVacuumRelease ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn lg={4}>
        <DisplayGroup label="Slides and/or Diving Boards?" labelId="boards">
          {values?.boards ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
