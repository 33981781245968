import { FC, useMemo } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../../../components';
import { Box, IconButton } from '@mui/material';
import { IEmailTemplateFollowUpNote } from '../../../../models';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

interface IFollowUpNotesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IEmailTemplateFollowUpNote[];
  refetch: () => Promise<void>;
  handleEdit: (note: IEmailTemplateFollowUpNote) => void;
  handleDelete: (note: IEmailTemplateFollowUpNote) => void;
}

export const FollowUpNotesDataGrid: FC<IFollowUpNotesDataGridProps> = ({
  rows,
  refetch,
  handleEdit,
  handleDelete,
  ...gridProps
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
        minWidth: 80,
        maxWidth: 140,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        type: 'string',
        renderCell: (params: GridRenderCellParams<IEmailTemplateFollowUpNote>) => {
          const { row: note } = params;
          return note.sortOrder; // Needed for properly displaying 0
        },
      },
      {
        headerName: 'Name',
        field: 'insertName',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        flex: 1,
        minWidth: 150,
        type: 'string',
      },
      {
        headerName: 'Text',
        field: 'insertText',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        flex: 2,
        minWidth: 150,
        type: 'string',
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEmailTemplateFollowUpNote>) => {
          const { row: note } = params;

          return (
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box display="flex" gap={1} className={clsx('print--none')}>
                <IconButton
                  color="primary"
                  title={`Edit Follow Up Note: ${note.insertName}`}
                  onClick={() => {
                    handleEdit(note);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
                <IconButton
                  color="error"
                  title={`Delete Follow Up Note: ${note.insertName}`}
                  onClick={async () => {
                    handleDelete(note);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              </Box>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IEmailTemplateFollowUpNote) => row.emailTemplateInsertId}
      rows={rows}
      columns={columns}
      hasMobileLayout
      mobileProps={{
        useFirstCell: false,
        mobileCustomDefaultAccessor: (val: IEmailTemplateFollowUpNote) =>
          `${String(val.sortOrder)} - ${val.insertName}`,
        showHandleActions: true,
        truncateAccordionLabel: true,
      }}
      {...gridProps}
    />
  );
};
