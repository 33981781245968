import { Formik, Form } from 'formik';
import { Modal, ModalSaveSection, Loader, TextField, SelectAsync } from '../../../components';
import { Fade, Grid, Box, Select, MenuItem } from '@mui/material';
import * as yup from 'yup';
import { FC, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { format, getDay } from 'date-fns';
import { updateRepairVisit, getRepairVisitStatuses, getUsers } from '../../../fetch';
import {
  IRepairVisit,
  IUpdateRepairVisit,
  IDropdownResponse,
  IListUser,
  IResponse,
} from '../../../models';
import { formatDate, getDaysOfWeek, formatDayMonthYearDate } from '../../../helpers';
import { useQuery } from 'react-query';

interface IOTSVisitModalProps {
  open: boolean;
  onClose: (shouldReload?: boolean) => void;
  currentRepairVisit?: IRepairVisit | null;
  repairId: string;
}

const Schema = yup.object().shape({
  status: yup.string(),
  notes: yup.string(),
});

export const OTSVisitModal: FC<IOTSVisitModalProps> = ({
  onClose,
  open,
  currentRepairVisit,
  repairId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data: statuses } = useQuery<IDropdownResponse[], Error>(
    ['getRepairVisitStatuses'],
    () => getRepairVisitStatuses()
  );
  const selectedDay = useMemo(
    () =>
      currentRepairVisit?.serviceDate
        ? getDaysOfWeek().find(
            day =>
              day.value ===
              getDay(new Date(formatDayMonthYearDate(new Date(currentRepairVisit?.serviceDate!))))
          )?.label
        : '',
    [currentRepairVisit?.serviceDate]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        status: currentRepairVisit?.status ?? '',
        notes: currentRepairVisit?.notes ?? '',
        technicianId: currentRepairVisit?.technicianId ?? '',
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          await updateRepairVisit(currentRepairVisit?.repairVisitId!, {
            ...currentRepairVisit,
            notes: values.notes,
            status: values.status,
            technicianId: values.technicianId,
          } as IUpdateRepairVisit);
          enqueueSnackbar('OTS Visit Saved successfully', {
            variant: 'success',
          });
          onClose(true);
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? 'An unexpected error occurred while saving the visit', {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit, isValid, resetForm }) => {
        const handleClose = () => {
          onClose();
          resetForm();
        };
        const scheduledDate = currentRepairVisit?.serviceDate
          ? formatDate(currentRepairVisit?.serviceDate)
          : '';
        const scheduledTime = currentRepairVisit?.serviceDate
          ? `${format(new Date(currentRepairVisit.startTime), 'h:mma')} - ${format(
              new Date(currentRepairVisit.endTime),
              'h:mma'
            )}`
          : '';

        return (
          <Modal
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            title={currentRepairVisit?.technicianName ? `${scheduledDate} ${scheduledTime}` : ''}
          >
            <Box mt={2}>
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectAsync
                        label="Assigned To"
                        name="technicianId"
                        required
                        apiRequest={() => getUsers({ perPage: -1, onlyIncludeRepairTechs: true })}
                        transformResponse={(res: IResponse<IListUser[]>) =>
                          res.records.map(r => ({
                            label: selectedDay
                              ? `${r.userName} ${
                                  r.daysAvailable.includes(selectedDay) ? '' : '(unavailable)'
                                }`
                              : r.userName,
                            value: r.userId,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        fullWidth
                        required
                        size="small"
                        autoComplete="nope"
                        label="Status"
                        name="status"
                        value={values.status}
                        disabled={isLoading}
                        onChange={e => {
                          setFieldValue('status', e.target.value);
                        }}
                      >
                        {statuses?.map(stat => {
                          return (
                            <MenuItem key={stat.value} value={stat.value}>
                              {stat.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name="notes" label="Visit Notes" rows={3} multiline />
                    </Grid>
                  </Grid>
                  <ModalSaveSection
                    handleCancel={handleClose}
                    isSaveDisabled={isSubmitting || !isValid}
                  />
                </Form>
              </Fade>
            </Box>
          </Modal>
        );
      }}
    </Formik>
  );
};
