import { Page } from '../../components';
import { ResetPasswordDetails } from './reset-password-details';

export const ResetPassword = () => {
  return (
    <Page title="Reset Password" isExternal={true} customPageHeader={true}>
      <ResetPasswordDetails />
    </Page>
  );
};
