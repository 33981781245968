import { FC, useContext, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button } from '@mui/material';
import { faFilterCircleXmark, faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CardTitle, FilterForm, CardFiltersLayout, Card } from '../../components';
import { CommissionsDataGrid } from './CommissionsDataGrid';
import { IFilterLayout } from '../../models';
import { CommissionsListContext } from '../../context/commissions-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CommissionsListDetailsProps {}

const filtersLayout: Record<string, IFilterLayout> = {
  DateType: {
    sortOrder: 0,
    xs: 12,
    sm: 4,
  },
  StartDate: {
    sortOrder: 1,
    xs: 12,
    sm: 4,
  },
  EndDate: {
    sortOrder: 2,
    xs: 12,
    sm: 4,
  },
  CommissionTypeId: {
    sortOrder: 3,
    xs: 12,
    sm: 12,
    md: 6,
  },
  AccountId: {
    sortOrder: 4,
    xs: 12,
    md: 6,
  },
  UserId: {
    sortOrder: 5,
    xs: 12,
    md: 6,
  },
  ServiceDefId: {
    sortOrder: 6,
    xs: 12,
    md: 6,
  },
};

export const CommissionsListDetails: FC<CommissionsListDetailsProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    isShowingFilters,
    filters,
    filterValues,
    onSubmitFilters,
    onFilterToggle,
    onFiltersChange,
    onReset,
    rows,
    isLoading,
    page,
    perPage,
    recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useContext(CommissionsListContext);

  const [isDeleting, setDeleting] = useState(false);

  return (
    <Box marginTop="1rem">
      <Card>
        <CardTitle
          title="Commissions"
          mobileWrap
          marginBottom={0}
          action={
            <>
              <Button
                onClick={onFilterToggle}
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
              >
                Filters
              </Button>
              <Button
                onClick={() => history.push(`/commissions/new?redirect=/commissions`)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add New Commission
              </Button>
            </>
          }
        >
          <div className={classes.filtersWrapper}>
            <CardFiltersLayout isOpen={isShowingFilters}>
              <FilterForm
                filters={filters}
                values={filterValues}
                filterLayouts={filtersLayout}
                defaultLayout={{ xs: 12 }}
                onSubmit={onSubmitFilters}
                onChange={onFiltersChange}
                isSubmitting={isLoading}
                onReset={onReset}
              />
            </CardFiltersLayout>
          </div>
        </CardTitle>
        <Box marginTop="1rem">
          <CommissionsDataGrid
            loading={isLoading || isDeleting}
            rows={rows}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            refetch={refetch}
            setDeleting={setDeleting}
          />
        </Box>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  filtersWrapper: {
    [theme.breakpoints.up('sm')]: {
      '& [role=radiogroup]': {
        flexDirection: 'row',
      },
    },
  },
}));
