import { useContext, FC } from 'react';
import { UserContext } from '../../context';
// Components
import { DatePicker as KeyboardDatePicker } from '@mui/x-date-pickers/DatePicker';

// TODO: I was getting an error using DatePickerProps<T>. I've changed it to any so that it works.
// Will need to look at this in the future.
export const DatePicker: FC<any> = ({ onChange, slotProps, showClearButton = true, ...props }) => {
  const { timezone } = useContext(UserContext);
  return (
    <KeyboardDatePicker
      slotProps={{
        ...slotProps,
        actionBar: showClearButton
          ? {
              // The actions will be the same between desktop and mobile
              actions: ['clear'],
            }
          : undefined,
      }}
      format={props?.format ?? 'MM/dd/yyyy'}
      onChange={(date: any) => {
        // we allow partial dates now, don't return the date unless
        // it is actually valid. If invalid the .getDate() method
        // will return NaN
        if (date && !isNaN(date)) {
          onChange(date);
        } else {
          onChange('');
        }
      }}
      timezone={timezone}
      {...props}
    />
  );
};
