import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getAnalysisById } from '../../fetch';
import { Box, Divider, Typography } from '@mui/material';
import { SimpleDataGrid } from '../data-grid';
import { camelCase, startCase } from 'lodash';
import { format } from 'date-fns';

export interface IWaterAnalysisDetailsProps {
  selectionModel: GridRowSelectionModel;
}

export const WaterAnalysisDetails: FC<IWaterAnalysisDetailsProps> = ({ selectionModel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    isLoading,
    isFetching,
    data: analysisDetail,
  } = useQuery(
    ['getAnalysisById', selectionModel],
    async () => {
      const selectedId = selectionModel[0];
      const res = await getAnalysisById(selectedId);
      return res;
    },
    {
      enabled: selectionModel.length > 0,
      onError: (err: any) => {
        enqueueSnackbar(err?.Detail ?? `Error loading analysis, please try again.`, {
          variant: 'error',
        });
      },
      keepPreviousData: true, // Prevent data from clearing while loading, show loading state
    }
  );
  if (analysisDetail?.analysisDetails && analysisDetail.analysisDetails.length > 0) {
    return (
      <>
        <Box mb={2}>
          <SimpleDataGrid
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            getRowId={row => row.analysisDetailId}
            columns={[
              {
                field: 'condition',
                headerName: 'Condition',
                flex: 1,
                renderCell: params => startCase(camelCase(params.value)),
                sortable: false,
              },
              { field: 'value', headerName: 'Value', flex: 1, sortable: false },
              { field: 'result', headerName: 'Result', flex: 1, sortable: false },
            ]}
            rows={analysisDetail?.analysisDetails ?? []}
            hasMobileLayout
            loading={isLoading || isFetching}
          />
          {analysisDetail.createDate && (
            <Typography
              variant="caption"
              sx={{ display: 'block', marginTop: '1rem', marginBottom: '-0.5rem' }}
            >
              Results for {format(new Date(analysisDetail.createDate), 'eeee, MMMM d, yyyy')}
            </Typography>
          )}
        </Box>
        <Divider />
      </>
    );
  }
  return null;
};
