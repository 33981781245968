import { Divider, Grid, Typography } from '@mui/material';
import { CardTitle, FormikCard, SelectAsync, TextField, DisplayGroup } from '../../../components';
import { useState, FC, ChangeEvent } from 'react';
import { getRepairStatuses, listServiceTypes } from '../../../fetch';
import { NewCustomerForm } from '../../customers';
import {
  IOneTimeServiceDetail,
  IBillingGroup,
  IDropdownResponse,
  IServiceType,
  ISalesTax,
} from '../../../models';
import { formatDate } from '../../../helpers';
import { useFormikContext } from 'formik';

interface IOTSServiceDetails {
  isNewService: boolean;
  isAllExpanded?: boolean;
  service: IOneTimeServiceDetail | null;
  billingGroups: IBillingGroup[];
  isLoadingBillingGroups: boolean;
  salesTaxes: ISalesTax[];
  isLoadingSalesTaxes: boolean;
  showConfirm?: boolean;
  shouldUnload?: boolean;
}

export const OTSServiceDetails: FC<IOTSServiceDetails> = ({
  isNewService,
  isAllExpanded,
  service,
  billingGroups,
  isLoadingBillingGroups,
  salesTaxes,
  isLoadingSalesTaxes,
  showConfirm,
  shouldUnload,
}) => {
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
  const { setFieldValue, values } = useFormikContext<any>();

  const handleServiceTypeChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value;
    const selectedServiceType = serviceTypes.find(
      s => Number(s.serviceTypeId) === Number(id) || s.customAgreementId === id
    );
    if (selectedServiceType) {
      setFieldValue('serviceTypeId', selectedServiceType.serviceTypeId);
      setFieldValue('customAgreementId', selectedServiceType.customAgreementId ?? '');
    }
  };

  return (
    <FormikCard showConfirm={showConfirm} shouldUnload={shouldUnload}>
      <CardTitle title="Service Details" withExpand overrideExpand={isAllExpanded}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={isNewService ? 12 : 4}>
            <SelectAsync
              fullWidth
              size="small"
              autoComplete="off"
              label="Service Type"
              name="serviceTypeId"
              disabled={!!values.signature}
              required
              value={values.serviceTypeId || values.customAgreementId}
              apiRequest={() => listServiceTypes()}
              handleResponseOptions={(data: IServiceType[]) => setServiceTypes(data)}
              transformResponse={(res: IServiceType[]) =>
                res.map(r => ({
                  label: r.description,
                  value:
                    (r.serviceTypeId ? `${r.serviceTypeId}` : '') ||
                    (r.customAgreementId ? `${r.customAgreementId}` : '') ||
                    '',
                }))
              }
              onChange={handleServiceTypeChange}
              inputProps={{
                'data-testid': 'service-type-select',
              }}
            />
          </Grid>

          {values.customerType === 'NewCustomer' && isNewService && (
            <>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderColor: theme => theme.palette.dividers.grey,
                    marginBottom: theme => theme.spacing(2),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NewCustomerForm
                  billingGroups={billingGroups}
                  isLoadingBillingGroups={isLoadingBillingGroups}
                  salesTaxes={salesTaxes}
                  isLoadingSalesTaxes={isLoadingSalesTaxes}
                  isEmailRequired={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderColor: theme => theme.palette.dividers.grey,
                    marginBottom: theme => theme.spacing(2),
                  }}
                />
              </Grid>
            </>
          )}

          {service?.whenCreated && (
            <Grid item xs={6} md={4}>
              <SelectAsync
                name="repairStatus"
                label="Status"
                required
                apiRequest={() => getRepairStatuses()}
                transformResponse={(response: IDropdownResponse[]) => {
                  return response.map(record => ({
                    label: record.description ?? '',
                    value: record.value ?? '',
                  }));
                }}
                hasClear={!!values.repairStatus}
                inputProps={{
                  'data-testid': 'status-select',
                }}
              />
            </Grid>
          )}

          {service?.whenCreated && (
            <Grid item xs={6} md={3}>
              <Typography>
                Created on{' '}
                <Typography color="secondary" component="span">
                  {service?.whenCreated ? formatDate(new Date(service?.whenCreated)) : ''}{' '}
                </Typography>
                by {service?.createdBy}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} xl={6}>
            <TextField
              label="Service Description"
              name={'problemDescription'}
              multiline
              rows={4}
              className={'print--none'}
              inputProps={{
                'data-testid': 'service-description-text',
              }}
            />
            {/* Used for the print screen only */}
            {values.problemDescription && (
              <DisplayGroup
                label="Service Description"
                labelId="problemDescription"
                printClasses={'print--only'}
              >
                {values.problemDescription}
              </DisplayGroup>
            )}
          </Grid>
          <Grid item xs={12} xl={6}>
            <TextField
              label="Technician Notes"
              name={'repairNotes'}
              multiline
              rows={4}
              className={'print--none'}
              inputProps={{
                'data-testid': 'technician-notes-text',
              }}
            />
            {/* Used for the print screen only */}
            {values.repairNotes && (
              <DisplayGroup
                label="Technician Notes"
                labelId="repairNotes"
                printClasses={'print--only'}
              >
                {values.repairNotes}
              </DisplayGroup>
            )}
          </Grid>
        </Grid>
      </CardTitle>
    </FormikCard>
  );
};
