import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CardTitle, DisplayGroup, Loader, Card } from '../../components';
import { getTransactionDetail } from '../../fetch';
import { Divider, Box, Grid, Button } from '@mui/material';
import { formatDate, formatMoney } from '../../helpers';
import { CustomersDetail } from '../customers';
import { useState } from 'react';
import { TransactionPageDetailsRefund } from './transaction-page-details-refund';
import { TransactionPageDetailsButtons } from './transaction-page-details-buttons';

export const TransactionPageDetails = () => {
  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState<string>('');
  const { transactionId }: { transactionId: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const {
    isLoading: isLoadingBatch,
    isFetching,
    data: transaction,
  } = useQuery('transactionDetail', () => getTransactionDetail(transactionId));

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          {(isLoadingBatch || isFetching) && <Loader type="overlay" position="centered" />}
          <CardTitle title="Details" />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Customer">
                <Button
                  color="primary"
                  variant="text"
                  sx={{ padding: 0, fontSize: '1rem' }}
                  onClick={() => {
                    setCurrentAccountId(transaction?.accountId as string);
                    setCustomerModalOpen(true);
                  }}
                >
                  {transaction?.accountName}
                </Button>
              </DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="When Posted">{formatDate(transaction?.whenPosted)}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Amount">{formatMoney(transaction?.amount)}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Reference">{transaction?.reference}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Details">{transaction?.details}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Last Four">{transaction?.lastFour}</DisplayGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DisplayGroup label="Payment Accepted By">{transaction?.userName}</DisplayGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TransactionPageDetailsButtons transaction={transaction} redirect={redirect} />
            </Grid>
          </Grid>
        </Card>

        {transaction && transaction.isRefundable && (
          <TransactionPageDetailsRefund transaction={transaction} redirect={redirect} />
        )}
      </Box>
      <CustomersDetail
        isModal
        isModalOpen={isCustomerModalOpen}
        handleModalClose={() => {
          setCustomerModalOpen(false);
          setCurrentAccountId('');
        }}
        currentAccountId={currentAccountId}
        isEditMode={false}
      />
    </>
  );
};
