import { FC } from 'react';
import { Page } from '../../../components';
import { StoreManagementTable } from './store-management-table';

export const StoreManagement: FC = () => {
  return (
    <Page title="Store Management">
      <StoreManagementTable />
    </Page>
  );
};
