import { GridValueFormatterParams } from '@mui/x-data-grid';
import format from 'date-fns/format';

export const dateColFormatter = (params: GridValueFormatterParams<number>) => {
  if (!params.value) {
    return '';
  }

  return format(new Date(params.value), 'M/d/yyyy');
};
