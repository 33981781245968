import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../../components';

export const PipingInformationDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the PipingInformationForm component as well
  // REMINDER: When adding fields, also update the pipingInfoSectionFields array in pool-structure-card
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={-2} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Gauge" labelId="gauge">
          {values?.gauge ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Valve Size" labelId="valveSize">
          {values?.valveSize ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Main Drain" labelId="mainDrain">
          {values?.mainDrain ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Control Valve" labelId="controlValve">
          {values?.controlValve ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
