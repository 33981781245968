import { formatISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { ServiceRoutesContext, UserContext } from '../../context';
import { getServiceRoutes } from '../../fetch';
import { IServiceRoute, ICalendarDateRange } from '../../models';

export const useRoutes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { selectedTechs } = useContext(ServiceRoutesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceRoutes, setServiceRoutes] = useState<IServiceRoute[]>([]);

  const loadServiceRoutes = async (dateRange: ICalendarDateRange, userId?: string | null) => {
    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }
    setIsLoading(true);
    try {
      const { records } = await getServiceRoutes({
        startDate: formatISO(dateRange.startDate, { representation: 'date' }),
        endDate: formatISO(dateRange.endDate, { representation: 'date' }),
        userIds: userId ? [userId] : selectedTechs.map(tech => tech.userId),
        perPage: -1,
        officeId: user?.officeId,
      });
      setServiceRoutes(records);
    } catch (error) {
      enqueueSnackbar(`Error loading routes, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    setIsLoading,
    serviceRoutes,
    loadServiceRoutes,
    setServiceRoutes,
  };
};
