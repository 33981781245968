import { faFilter, faFilterCircleXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { CardTitle } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideCardTitle = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [isShowingFilters, setIsShowingFilters] = useState(false);

  return (
    <StyleGuidePage title="Card Title">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Card Title component is a custom built component to be used with card sections. This
            component is most commonly used for rendering h2, h3, and h4 elements within a card or
            section. The component has a built-in divider, and allows for an icon and action
            buttons. To create subsections, more than one Card Title component can be used per card
            or cards can be nested.
          </Typography>
          <Typography mb={1}>
            NOTE: It is intended that any filters are rendered as children of this component. The
            trigger for showing/hiding the filters should be defined in the <em>action</em> prop.
          </Typography>
          <Typography mb={1}>
            Multiple button colors are permitted, though the typical coloring of buttons displayed
            in the Card Title action prop are <em>secondary</em> colored. It is recommended to only
            differentiate button colors to draw more attention to one button vs another.
          </Typography>
          <code>
            Props:
            <br />- children?: any
            <br />- cardTitleClassName?: string
            <br />- cardTitleWrapperClassName?: string
            <br />- sx?: SxProps
            <br />- title: string
            <br />- titleIcon?: IconProp
            <br />- action?: JSX.Element
            <br />- mobileWrap?: boolean
            <br />- variant?: Variant
            <br />- component?: React.ElementType
            <br />- withExpand?: boolean
            <br />- overrideExpand?: boolean
            <br />- initialExpand?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle title="Custom Card Title" />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle title="Custom Card Title" /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle title="Custom Card Title" component="h4" />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle title="Custom Card Title" component="h4" /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle
              title="Custom Card Title"
              action={
                <Box textAlign="right" className="print--none">
                  <Button
                    color="secondary"
                    size="small"
                    title="Add Something"
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  >
                    Add Something
                  </Button>
                </Box>
              }
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle <br />
            &nbsp;&nbsp;&nbsp;title="Custom Card Title" <br />
            &nbsp;&nbsp;&nbsp;action=&#123; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box textAlign="right"
            className="print--none"&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button color="secondary"
            size="small" title="Add Something" icon=&#123;faPlusCircle&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add&lt;span{' '}
            className="d-none d-sm-inline"&gt; Something&lt;/span&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Button&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt; <br />
            &nbsp;&nbsp;&nbsp;&#125; <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle
              mobileWrap
              title="Custom Card Title"
              action={
                <Box textAlign="right" className="print--none">
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        onClick={() => setIsShowingFilters(!isShowingFilters)}
                        className="print--none"
                        color="secondary"
                        size="small"
                        startIcon={
                          <FontAwesomeIcon
                            icon={isShowingFilters ? faFilterCircleXmark : faFilter}
                          />
                        }
                      >
                        Filters
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        title="Add Something"
                        startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                      >
                        Add Something
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              }
            >
              {isShowingFilters && <Box>Filter Components render here</Box>}
            </CardTitle>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle
            <br />
            &nbsp;&nbsp;&nbsp;mobileWrap
            <br />
            &nbsp;&nbsp;&nbsp;title="Custom Card Title"
            <br />
            &nbsp;&nbsp;&nbsp;action=&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box textAlign="right"
            className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Grid container
            spacing=&#123;1&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Grid
            item&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
            onClick=&#123;() =&gt; setIsShowingFilters(!isShowingFilters)&#125;
            className="print--none" color="secondary" size="small" startIcon=&#123;isShowingFilters
            ? faFilterCircleXmark : faFilter&#125; &gt; Filters &lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Grid
            item&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
            color="secondary" size="small" title="Add Something" startIcon=&#123;faPlusCircle&#125;
            &gt; Add Something &lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Grid&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&#125;
            <br />
            &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&#123;isShowingFilters && &lt;Box&gt;Filter Components render
            here&lt;/Box&gt;&#125;
            <br />
            &lt;/CardTitle&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle title="Expander Card Title" withExpand>
              <p>Card Content Goes Here</p>
            </CardTitle>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle title="Expander Card Title" withExpand&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;p&gt;Card Content Goes Here&lt;/p&gt;
            <br /> &lt;/CardTitle&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <CardTitle title="Expander Card Title" withExpand initialExpand={false}>
              <p>Card Content Goes Here</p>
            </CardTitle>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;CardTitle title="Expander Card Title" withExpand initialExpand=&#123;false&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;p&gt;Card Content Goes Here&lt;/p&gt;
            <br /> &lt;/CardTitle&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
