import { FC } from 'react';
import { Page } from '../../components';
import { CommissionsListDetails } from './CommissionsListDetails';

export const CommissionsListPage: FC = () => {

  return (
    <Page title="Commissions">
      <CommissionsListDetails />
    </Page>
  );
};

