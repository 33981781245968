import { Box, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '../loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { ISitePhoto } from '../../models';

export const UploadedImage = ({
  source,
  isLoading,
  showZoom = true,
  photos,
}: {
  source: string;
  isLoading?: boolean;
  showZoom?: boolean;
  photos?: ISitePhoto[];
}) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const transformWrappersRef = useRef<any[]>([]);

  useEffect(() => {
    if (photos) {
      const index = photos.findIndex(photo => photo.urlPath === source);
      setCurrentIndex(index !== -1 ? index : 0);
    }
  }, [photos, source]);

  const handleZoomIn = () => {
    transformWrappersRef.current[currentIndex]?.zoomIn();
  };

  const handleZoomOut = () => {
    transformWrappersRef.current[currentIndex]?.zoomOut();
  };

  const handleReset = () => {
    transformWrappersRef.current[currentIndex]?.resetTransform();
  };

  const handleIndexChange = (index?: number) => {
    setCurrentIndex(index!);
    // Reset zoom when the carousel index changes
    if (transformWrappersRef.current[index!]) {
      transformWrappersRef.current[index!].resetTransform();
    }
  };

  return (
    <Box className={classes.container}>
      {showZoom && (
        <Box className={classes.zoomControls}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            onClick={handleZoomIn}
            className={classes.zoomButton}
          >
            Zoom In
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faMinusCircle} />}
            onClick={handleZoomOut}
            className={classes.zoomButton}
          >
            Zoom Out
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faRefresh} />}
            onClick={handleReset}
            className={classes.zoomButton}
          >
            Reset
          </Button>
        </Box>
      )}
      <Box className={classes.carouselContainer}>
        <Box className={classes.imgContainer}>
          {isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Loader type="overlay" />
            </Box>
          )}
          {photos && photos.length > 1 ? (
            <Carousel
              indicators={false}
              navButtonsAlwaysVisible={true}
              fullHeightHover={false}
              autoPlay={false}
              swipe={false}
              className={classes.carouselBox}
              index={currentIndex}
              onChange={handleIndexChange}
            >
              {photos.map((photo, index) => (
                <Box key={index} className={classes.carouselItem}>
                  <TransformWrapper
                    initialScale={1}
                    wheel={{ step: 0.1 }}
                    ref={el => (transformWrappersRef.current[index] = el)}
                    onInit={() => transformWrappersRef.current[index]?.resetTransform()}
                  >
                    <TransformComponent>
                      <img
                        src={photo.urlPath}
                        alt={`slide-${index}`}
                        className={classes.carouselImage}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              ))}
            </Carousel>
          ) : (
            <TransformWrapper
              initialScale={1}
              wheel={{ step: 0.1 }}
              ref={el => (transformWrappersRef.current[0] = el)}
              onInit={() => transformWrappersRef.current[0]?.resetTransform()}
            >
              <TransformComponent>
                <Box className={classes.singleImageBox}>
                  <img src={source} alt="single-view" className={classes.singleImage} />
                </Box>
              </TransformComponent>
            </TransformWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  zoomControls: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '20px',
    zIndex: 1,
  },
  zoomButton: {
    marginBottom: '0',
  },
  carouselContainer: {
    width: '100%',
    maxWidth: '1200px',
    height: '100%',
    maxHeight: '1000px',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselBox: {
    width: '100%',
    height: '100%',
  },
  carouselItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  transformWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  singleImageBox: {
    width: '100%',
    maxWidth: '1200px',
    height: '100%',
    maxHeight: '1000px',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
}));
