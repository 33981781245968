import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ITreatmentType } from '../../../models';
import { getTreatmentTypes, deleteTreatmentType } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { AddEditTreatmentTypeModal } from './add-edit-treatment-types-modal';
import { formatMoney } from '../../../helpers';

export const TreatmentTypesTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentTreatmentType, setCurrentTreatmentType] = useState<ITreatmentType | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ITreatmentType> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };
        const res = await getTreatmentTypes(options);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading Treatment Types, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITreatmentType>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'treatment-types-grid',
      sortColumn: 'code',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleDelete = async (item: ITreatmentType) => {
    try {
      const result = await confirm('Are you sure you want to delete this Treatment Type?');
      if (result) {
        setDeleting(true);
        await deleteTreatmentType(item.treatmentTypeId);
        await refetch();
        enqueueSnackbar(`Treatment Type Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting Treatment Type, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        filterable: false,
        flex: 1,
        maxWidth: 150,
        minWidth: 130,
        width: 140,
      },
      {
        field: 'description',
        headerName: 'Description',
        filterable: false,
        flex: 1,
      },
      {
        field: 'unitDescription',
        headerName: 'Unit',
        flex: 1,
        maxWidth: 140,
        minWidth: 120,
        width: 130,
      },
      {
        field: 'highestToAdd',
        headerName: 'Limit',
        flex: 1,
        maxWidth: 140,
        minWidth: 120,
        width: 130,
        valueGetter: params => {
          if (!params.value) {
            return params.value;
          }
          return String(Number(params.value).toFixed(2));
        },
      },
      {
        field: 'unitIncrement',
        headerName: 'Increase',
        flex: 1,
        valueGetter: params => {
          if (!params.value) {
            return params.value;
          }
          return String(Number(params.value).toFixed(2));
        },
        maxWidth: 140,
        minWidth: 120,
        width: 130,
      },
      {
        field: 'pricePerUnit',
        headerName: 'Price',
        flex: 1,
        maxWidth: 100,
        minWidth: 80,
        width: 90,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<ITreatmentType>) => {
          const { row: treatmentType } = params;
          return treatmentType.pricePerUnit ? formatMoney(treatmentType.pricePerUnit) : '$0.00';
        },
      },
      {
        field: 'tranCode',
        headerName: 'Tran Code',
        flex: 1,
      },
      {
        field: 'inventoryItem',
        headerName: 'Inventory Item',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ITreatmentType>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Treatment Type"
                onClick={() => {
                  showModal(true);
                  setCurrentTreatmentType(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Treatment Type"
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Treatment Types"
          mobileWrap
          action={
            <Button
              onClick={() => showModal(true)}
              color="secondary"
              size="small"
              disabled={isLoading}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Treatment Type
            </Button>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ITreatmentType) => row.treatmentTypeId}
          rows={rows}
          columns={columns}
          loading={isLoading || isDeleting}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            handleEdit: (item: ITreatmentType) => {
              showModal(true);
              setCurrentTreatmentType(item);
            },
            handleDelete: (item: ITreatmentType) => {
              handleDelete(item);
            },
          }}
        />
      </Card>
      <AddEditTreatmentTypeModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentTreatmentType(null);
        }}
        currentTreatmentType={currentTreatmentType}
        fetchTreatmentTypes={() => refetch()}
      />
    </>
  );
};
