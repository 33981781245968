import { axios } from '../helpers';
import qs from 'querystring';
import { ITask, IResponse, ITaskDetail, ITaskPost, ILookupModel, ITaskDefinition } from '../models';
import { userSignal } from '../signals';

export const getTasks = async (filters?: {
  whenScheduledStartDate?: string;
  whenScheduledEndDate?: string;
  whenCompletedEndDate?: string;
  whenCompletedStartDate?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  accountId?: string;
  officeId?: string | null;
  isComplete?: boolean;
  forCurrentUser?: boolean;
  taskDefinitionId?: string;
  includeOpen?: boolean;
  userId?: string | null;
  priority?: string;
}): Promise<IResponse<ITask[]>> => {
  try {
    const { data } = await axios.get(`/api/tasks`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSingleTask = async (
  taskId: string | number,
  officeId?: string | null
): Promise<ITaskDetail | any> => {
  try {
    const { data } = await axios.get(`/api/tasks/${taskId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createTask = async (body: ITaskPost, officeId?: string | null): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/tasks`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateTask = async (
  taskId: string | number,
  body: ITaskPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/tasks/${taskId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteTask = async (taskId: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/tasks/${taskId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskPriorityTypes = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/tasks/priority-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskDefinitions = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  officeId?: string | null;
  scheduledTaskId?: string;
}): Promise<IResponse<ITaskDefinition[]>> => {
  try {
    const { data } = await axios.get(`/api/task-definitions`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
