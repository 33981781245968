import { eachDayOfInterval, format, isEqual, startOfDay, getMonth } from 'date-fns';
import {
  ICalendarItem,
  IDayInfo,
  ICalendarDateRange,
  IInitialCalendarState,
  ICalendarEventDetail,
  IUserInformation,
  ScheduledWorkCalendarEvent,
  ICalendarChanges,
  IWeekdayInfo,
  IWeekday,
  IListUser,
  FinalOrderCalendarItem,
  FinalOrderUser,
} from '../models';
import { makeColor } from './util';
import Color from 'color';
import { IColorSetMap } from '../models/colors';
import { setStartOfWeek, setEndOfWeek } from '.';

export const viewWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const getUserColorMap = (users: (IListUser | IUserInformation)[]) => {
  const userColors: IColorSetMap = {};
  users.forEach((user, index) => {
    const backgroundColor = makeColor(index, users.length);
    const foregroundColor = Color(backgroundColor).isDark() ? 'white' : 'black';
    userColors[user.userId] = {
      backgroundColor,
      foregroundColor,
    };
  });
  return userColors;
};

export const getColorMap = (data: any[], key: string, length?: number) => {
  const userColors: IColorSetMap = {};
  data.forEach((d, index) => {
    const backgroundColor = makeColor(index, length ?? data.length);
    const foregroundColor = Color(backgroundColor).isDark() ? 'white' : 'black';
    userColors[d[key]] = {
      backgroundColor,
      foregroundColor,
    };
  });
  return userColors;
};

export const colorizeCalendarItems = (items: ICalendarItem[]) => {
  const uniqueUsers: Record<string, IUserInformation> = {};
  items.forEach(item => {
    item.users.forEach(user => {
      uniqueUsers[user.userInformation.userId] = user.userInformation;
    });
  });
  const uniqueUsersList = Object.values(uniqueUsers);

  const colorMap = getUserColorMap(uniqueUsersList);

  items.forEach(item => {
    item.users.forEach(user => {
      const { userInformation } = user;
      const colors = colorMap[userInformation.userId];
      userInformation.backgroundColor = colors?.backgroundColor;
      userInformation.foregroundColor = colors?.foregroundColor;
    });
  });
};

export const initializeUnscheduledWorkIndeces = (items: ICalendarItem[]) => {
  const { unscheduledWorkList } = buildCalendarItemWorkDetails(items);
  const unscheduledWorkByDate = groupByCalendarDate(unscheduledWorkList);

  Object.values(unscheduledWorkByDate).forEach(unscheduledWorkDetails => {
    unscheduledWorkDetails.forEach((detail, index) => {
      const { event } = detail;
      event.index = index;
    });
  });
};

export const initializeCalendarItems = (items: ICalendarItem[]) => {
  colorizeCalendarItems(items);
  initializeUnscheduledWorkIndeces(items);
};

export const createDateRangeLabel = (
  startDate: Date,
  endDate: Date,
  abbreviated: boolean = false
) => {
  const isSameDate = isEqual(startOfDay(startDate), startOfDay(endDate));
  if (isSameDate) {
    return abbreviated ? format(startDate, 'LLL d') : format(startDate, 'LLLL d');
  }

  if (abbreviated) {
    const fromDate = format(startDate, 'LLL d');
    let toDate = format(endDate, 'LLL d, yyyy');

    if (getMonth(startDate) === getMonth(endDate)) {
      toDate = format(endDate, 'd, yyyy');
    }
    return `${fromDate} - ${toDate}`;
  }

  const fromDate = format(startDate, 'LLLL d');
  let toDate = format(endDate, 'LLLL d, yyyy');

  if (getMonth(startDate) === getMonth(endDate)) {
    toDate = format(endDate, 'd, yyyy');
  }
  return `${fromDate} - ${toDate}`;
};

export const parseCalendarDate = (calendarDate: string) => {
  let dateStr = calendarDate;

  // remove time part of string if present
  const indexOfTime = dateStr.indexOf('T');
  if (indexOfTime !== -1) {
    dateStr = dateStr.substring(0, indexOfTime);
  }
  const parts = dateStr.split('-');
  return new Date(+parts[0], +parts[1] - 1, +parts[2]);
};

export const toCalendarDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const groupByCalendarDate = <T extends ICalendarEventDetail>(details: T[]) => {
  const rtn: Record<string, T[]> = {};
  if (!details.length) {
    return rtn;
  }
  details.forEach(item => {
    const { calendarDate } = item.calendarItem;
    if (!rtn[calendarDate]) {
      rtn[calendarDate] = [];
    }
    rtn[calendarDate].push(item);
  });
  return rtn;
};

export const getDateRangeInfo = (dateRange: ICalendarDateRange) => {
  const interval = { start: dateRange.startDate, end: dateRange.endDate };
  const dates = eachDayOfInterval(interval);
  const startMonth = dateRange.startDate.getMonth();
  const startOfToday = startOfDay(new Date());
  const info: Record<number, IDayInfo> = {};
  dates.forEach(date => {
    const isSameMonth = date.getMonth() === startMonth;
    info[date.getDay()] = {
      calendarDate: format(date, 'yyyy-MM-dd'),
      date,
      weekdayLabel: format(date, 'EEE'),
      dateLabel: isSameMonth ? format(date, 'd') : format(date, 'MMM d'),
      isCurrentDay: isEqual(startOfDay(date), startOfToday),
    };
  });

  return info;
};

export const getWeekdayInfo = (weekday: IWeekday) => {
  let startDate = setStartOfWeek(new Date());
  let endDate = setEndOfWeek(startDate);
  const interval: Interval = {
    start: startDate,
    end: endDate,
  };
  const date = eachDayOfInterval(interval).find(d => d.getDay() === weekday);

  const info: IWeekdayInfo = {
    weekdayLabel: format(date!, 'EEE'),
  };
  return info;
};

export const buildCalendarItemWorkDetails = (
  calendarItems: ICalendarItem[],
  changes?: ICalendarChanges
) => {
  const scheduledWorkList: ICalendarEventDetail[] = [];
  const unscheduledWorkList: ICalendarEventDetail[] = [];
  calendarItems.forEach(calendarItem => {
    const { users } = calendarItem;

    users.forEach(calendarItemUser => {
      const { userInformation, events } = calendarItemUser;

      events.forEach(event => {
        const hasChanged = !!changes?.[event.eventId];
        unscheduledWorkList.push({
          calendarItemUser,
          userInformation,
          event,
          calendarItem,
          hasChanged,
        });
      });
    });
  });
  return { scheduledWorkList, unscheduledWorkList };
};

export const buildInitialCalendarState = (calendarItems: ICalendarItem[]) => {
  const initialState: IInitialCalendarState = {};

  const { scheduledWorkList, unscheduledWorkList } = buildCalendarItemWorkDetails(
    calendarItems,
    {}
  );

  const scheduledWorkByDate = groupByCalendarDate(scheduledWorkList);
  const unscheduledWorkByDate = groupByCalendarDate(unscheduledWorkList);

  Object.values(scheduledWorkByDate).forEach(scheduledWorkUserDetails => {
    scheduledWorkUserDetails.forEach(detail => {
      const { event } = detail;
      initialState[event.eventId] = {
        startTime: event.startTime,
        endTime: event.endTime,
      };
    });
  });

  Object.entries(unscheduledWorkByDate).forEach(([calendarDate, unscheduledWorkDetails]) => {
    unscheduledWorkDetails.forEach((detail, index) => {
      const { event } = detail;
      initialState[event.eventId] = {
        calendarDate,
        index,
      };
    });
  });

  return initialState;
};

export const convertScheduledWorkToCalendarEvent = (detail: ICalendarEventDetail) => {
  const { event } = detail;
  const schedulerEvent: ScheduledWorkCalendarEvent = {
    event_id: event.eventId,
    title: event.siteName?.trim() || '',
    start: new Date(event.startTime!),
    end: new Date(event.endTime!),
    editable: false,
    deletable: false,
    scheduledWork: detail,
  };
  return schedulerEvent;
};

export const createFinalOrderList = (
  updatedCalendarItems: ICalendarItem[]
): FinalOrderCalendarItem[] => {
  const finalOrderList = updatedCalendarItems.map(calendarItem => {
    const finalOrderUsers: FinalOrderUser[] = [];

    calendarItem.users.forEach(u => {
      const finalOrderUser: FinalOrderUser = {
        userId: u.userInformation.userId,
        events: u.events.map(e => ({
          eventId: e.eventId,
          eventType: e.eventType,
          index: e.index,
        })),
      };

      finalOrderUsers.push(finalOrderUser);
    });

    const finalOrderModel: FinalOrderCalendarItem = {
      calendarDate: calendarItem.calendarDate,
      users: finalOrderUsers,
    };

    return finalOrderModel;
  });

  return finalOrderList;
};
