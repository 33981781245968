import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, ReactNode, useEffect, useContext, useMemo } from 'react';
import { SideNavContext, UserContext } from '../../context';
import { useLocation } from 'react-router-dom';
import { InstallPrompt } from '../install-prompt';
import { SideNav } from '../side-nav';
import { Header, IconPageHeading, PrintHeader } from './';
import { Footer } from './footer/Footer';
import { IRoute, IBreadcrumb } from '../../models';
import { SIDENAV_OPEN } from '../../helpers';
import { BrandingContext } from '../../context/branding-context';

export interface IPage {
  children: ReactNode;
  title: string;
  customPageHeader?: boolean;
  hasOverflow?: boolean;
  isExternal?: boolean;
  className?: string;
  sideBarLinks?: IRoute[];
  showAlerts?: boolean;
  breadcrumb?: IBreadcrumb;
  // used for testing purposes
  isTesting?: boolean;
}
/**
 * Controls both the <Footer /> text display and the window title.
 */
export const HEADER_HEIGHT = 64;
export const FOOTER_HEIGHT = 45;

export const Page: FC<IPage> = ({
  children,
  title,
  customPageHeader = false,
  hasOverflow,
  isExternal = false,
  className,
  sideBarLinks,
  showAlerts,
  breadcrumb,
  isTesting,
}) => {
  const { pathname } = useLocation();
  const { isSuperAdmin } = useContext(UserContext);
  const { appLongName, copyRight, isPoolService } = useContext(BrandingContext);

  const { showNav, setShowNav, sideNavWidth, isMobile, isSmMobile, isPrint } =
    useContext(SideNavContext);

  useEffect(() => {
    if (isMobile && !isPrint) {
      setShowNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isPrint]);

  const classes = useStyles({ isSmMobile, HEADER_HEIGHT, hasOverflow, isExternal, isPoolService });

  // Anytime isNavOpen changes set it in localForage. setItem is async but we don't care
  // to wait since we look at this on load only.
  useEffect(() => {
    localStorage.setItem(SIDENAV_OPEN, `${showNav}`);
  }, [showNav]);

  useEffect(() => {
    // this is here to handle a scroll bar issue where the main content scroll bar is hidden behind the header
    if (!isExternal) {
      window.document.body.style.marginTop = '64px';
    }
    return () => {
      window.document.body.style.marginTop = '0px';
    };
  }, [isExternal]);

  useEffect(() => {
    document.title = `${title} | ${appLongName}`;
  }, [pathname, title, appLongName]);

  const contentWidth = useMemo(
    () => (isPrint || isExternal || isMobile ? '100%' : `calc(100% - ${sideNavWidth}px)`),
    [isMobile, isExternal, sideNavWidth, isPrint]
  );

  return (
    <div className={clsx(classes.appFrame, className)} id="top">
      {!isExternal && (
        <Header
          setShowNav={setShowNav}
          showNav={showNav}
          width={sideNavWidth}
          height={HEADER_HEIGHT}
          isMobile={isMobile}
          isSmMobile={isSmMobile}
          isTesting={isTesting}
        />
      )}
      <Box display="flex" minHeight="100%" className="print--block">
        {!isExternal && (
          <SideNav
            open={showNav}
            setShowNav={setShowNav}
            isMobile={isMobile}
            width={sideNavWidth}
            navItems={sideBarLinks}
            showAlerts={isSuperAdmin ? false : showAlerts}
            isTesting={isTesting}
          />
        )}
        <Box width={contentWidth} flexDirection="column" className={classes.mainContent}>
          <div className={clsx(classes.contentWrapper, isExternal ? classes.noPadding : undefined)}>
            <div className={clsx(classes.content, isExternal ? classes.noPadding : undefined)}>
              <PrintHeader />
              {!customPageHeader && (
                <IconPageHeading
                  showHome={isSuperAdmin ? false : true}
                  showDivider
                  heading={title}
                  breadcrumb={breadcrumb}
                />
              )}
              {children}
            </div>
          </div>

          {!isExternal && <Footer copyRight={copyRight} height={FOOTER_HEIGHT} />}
          <InstallPrompt name={`${appLongName} App`} />
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles<
  Theme,
  {
    isSmMobile: boolean;
    HEADER_HEIGHT: number;
    hasOverflow?: boolean;
    isExternal?: boolean;
    isPoolService?: boolean;
  }
>((theme: Theme) => ({
  appFrame: {
    height: `calc(100vh - 64px)`,
    backgroundColor: '#F5F5F5',
  },
  contentWrapper: {
    width: '100%',
    padding: theme.spacing(0),
    flex: 1,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    '@media print': {
      padding: 0,
      display: 'block',
    },
  },
  mainContent: {
    display: 'flex',
    height: ({ isExternal }) => (isExternal ? '100vh' : `calc(100vh - 64px)`),
    overflow: 'hidden scroll',
    '@media print': {
      display: 'block',
      width: '100% !important',
      height: 'auto',
      overflow: 'visible !important',
      backgroundColor: '#ffffff',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    '@media print': {
      padding: 0,
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
  noPadding: {
    padding: 0,
  },
}));
