import { AppBar, Box, SxProps } from '@mui/material';
import { IconPageHeading, Footer, FOOTER_HEIGHT, HEADER_HEIGHT } from '../../components';
import { FC, useContext, useEffect } from 'react';
import papLogo from '../../styles/images/pap-logo-white.png';
import { BrandingContext } from '../../context/branding-context';

export interface INonAuthPage {
  title: string;
  children: any;
  logoType?: 'poolcare-pro' | 'pinch-a-penny';
  officeName?: string;
  hideHeader?: boolean;
  innerWrapStyles?: SxProps;
}

export const NonAuthPage: FC<INonAuthPage> = ({
  title,
  children,
  officeName,
  hideHeader = false,
  innerWrapStyles,
}) => {
  const { appLongName, fullLogo, fullColorLogo, isPoolService, copyRight } =
    useContext(BrandingContext);

  useEffect(() => {
    const documentTitle = !!title ? `${title} | ` : '';
    document.title = `${documentTitle}${!!officeName ? officeName : appLongName}`;
  }, [title, appLongName, fullColorLogo, isPoolService, officeName]);

  return (
    <>
      <Box display="flex" flexDirection="column" height="100vh">
        {!hideHeader && (
          <AppBar
            id="header"
            position="relative"
            className="print--none"
            sx={{ width: '100%', height: HEADER_HEIGHT, zIndex: theme => theme.zIndex.drawer + 1 }}
          >
            <Box display="flex" height="100vh" alignItems="center" pl={2}>
              <Box
                component="img"
                src={isPoolService ? fullLogo : papLogo}
                width="170px"
                alt="App Logo"
              />
            </Box>
          </AppBar>
        )}
        <Box p={4} flex={1} sx={innerWrapStyles}>
          <IconPageHeading showDivider showHome={false} heading={title} />
          {children}
        </Box>
        <Footer height={FOOTER_HEIGHT} copyRight={copyRight} showFooterLinks={false} />
      </Box>
    </>
  );
};
