import React from 'react';
import { Page } from '../../components';
import SetupPageDetail from './setup-page-detail';
import { BrowserRouter } from 'react-router-dom';

export const SetupPage = () => {
  return (
    <Page title="Setup">
      <BrowserRouter>
        <SetupPageDetail />
      </BrowserRouter>
    </Page>
  );
};
