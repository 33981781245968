import { FC, useMemo, useContext } from 'react';
import {
  updateInvoiceLineItem,
  createInvoiceLineItem,
  getInventory,
  getInvoiceLineItem,
  getAllTranCodes,
} from '../../fetch';
import {
  IInvoiceLineItemPost,
  IResponse,
  IInventory,
  IInvoiceLineItemDetail,
  IInvoiceLineItem,
} from '../../models';
import { Formik, Form } from 'formik';
import { formatMoney, convertToNumber } from '../../helpers';
import { Grid, TextField as MuiTextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { AutocompleteAsync, TextField } from '../formikMui';
import { Modal, ModalSaveSection } from '../modal';
import { Loader } from '../loader';
import { UserContext } from '../../context';

interface IInvoiceLaborModal {
  lineItemId?: string | null;
  allLineItems: IInvoiceLineItem[];
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
  fetchInvoiceLineItems: () => void;
}

const FORM_VALIDATION = Yup.object().shape({
  sortOrder: Yup.number()
    .typeError('Must be a number')
    .required('Required')
    .test('Is positive?', "Line number must be greater than '0'", (value: any) => value > 0),
  postLaborChargesAs: Yup.string().required('Required'),
  details: Yup.string(),
  rate: Yup.string().required('Required'),
});

export const InvoiceLaborModal: FC<IInvoiceLaborModal> = ({
  lineItemId,
  allLineItems,
  isOpen,
  onClose,
  transactionId,
  fetchInvoiceLineItems,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const filteredLineItems = useMemo(() => {
    const filteredItems = allLineItems.filter(
      item => item.tranCodeDescription !== 'Security Deposit' && item.tranCodeDescription !== 'Tax'
    );
    const lastItemNumber =
      filteredItems[filteredItems.length - 1]?.sortOrder ?? filteredItems.length;
    return lastItemNumber + 1;
  }, [allLineItems]);

  const currentSortNumbers = useMemo(
    () => allLineItems.map(line => line.sortOrder),
    [allLineItems]
  );

  const { isLoading: isLoadingLineItem, data: lineItem } = useQuery<IInvoiceLineItemDetail, Error>(
    ['getEstimateLineItem', transactionId, lineItemId],
    () => getInvoiceLineItem(transactionId!, lineItemId!),
    {
      enabled: isOpen && !!transactionId && !!lineItemId,
    }
  );

  const { isLoading: isLoadingTranCode, data: laborTranCode } = useQuery<string>(
    ['getAllTranCodes', user],
    async () => {
      const res = await getAllTranCodes({
        officeId: user?.officeId,
        tranCodeType: 'Invoice',
        perPage: -1,
      });
      const tranCode = res.records.filter(code => code.description === 'Department')?.[0]
        ?.tranCodeId;
      return tranCode;
    },
    {
      enabled: isOpen && !lineItemId,
    }
  );

  return (
    <Formik
      initialValues={{
        sortOrder: lineItem?.sortOrder ?? `${filteredLineItems}`,
        rate: lineItem?.rate ? formatMoney(lineItem.rate) : '',
        details: lineItem?.reference ?? '',
        postLaborChargesAs: lineItem?.itemLookupCode ?? '',
        storeInventoryId: lineItem?.storeInventoryId ?? '',
        tranCodeId: lineItem?.tranCodeId ?? laborTranCode ?? '',
      }}
      enableReinitialize
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values, actions) => {
        const splitValue = values.postLaborChargesAs.split(' ');
        const lookupCode = splitValue.shift();
        const payload: IInvoiceLineItemPost = {
          sortOrder: Number(values.sortOrder),
          tranCodeId: !!values.tranCodeId ? values.tranCodeId : null,
          rate: convertToNumber(values.rate),
          quantity: 1,
          reference: values.details,
          lookupCode,
          storeInventoryId: convertToNumber(values.storeInventoryId),
          isForLabor: true,
        };
        try {
          !!lineItem
            ? await updateInvoiceLineItem(transactionId, lineItemId!, payload)
            : await createInvoiceLineItem(transactionId, payload);
          enqueueSnackbar('Line item saved!', { variant: 'success' });
          onClose();
          fetchInvoiceLineItems();
          actions.resetForm();
        } catch (err: any) {
          enqueueSnackbar(err?.Detail || `Error saving line item. Please try again.`, {
            variant: 'error',
          });
        }
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        handleBlur,
        values,
        setFieldValue,
        isValid,
        resetForm,
      }) => {
        const currentFormOrderNumber = Number(values.sortOrder);
        const currentOrderNumber = Number(lineItem?.sortOrder!);
        const hasCurrentOrderNumber = currentSortNumbers?.includes(currentFormOrderNumber);
        const hasSameOrderNumber = !lineItemId
          ? // if we are adding a new line item check its current form value with previous existing line item values
            hasCurrentOrderNumber
          : hasCurrentOrderNumber && currentOrderNumber !== currentFormOrderNumber;
        return (
          <Modal
            open={isOpen}
            onClose={() => {
              onClose();
              resetForm();
            }}
            title={`${lineItemId ? 'Edit' : 'Add'} Labor Rate`}
            maxWidth="sm"
          >
            {(isLoadingLineItem || isSubmitting || isLoadingTranCode) && <Loader type="overlay" />}
            <Form>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12}>
                  <MuiTextField
                    fullWidth
                    name="sortOrder"
                    required
                    size="small"
                    label="Line"
                    type="number"
                    value={values.sortOrder}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('sortOrder', e.target.value);
                    }}
                    error={hasSameOrderNumber ? true : touched.sortOrder && !!errors.sortOrder}
                    helperText={
                      hasSameOrderNumber
                        ? 'Line is already in use'
                        : touched.sortOrder && errors.sortOrder
                        ? errors.sortOrder
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteAsync
                    name="postLaborChargesAs"
                    label="Post Labor Charges as"
                    apiRequest={() =>
                      getInventory({
                        perPage: -1,
                        isForLabor: true,
                        sortBy: 'LookupCode',
                      })
                    }
                    transformResponse={(response: IResponse<IInventory[]>) => {
                      return response.records.map(
                        item => `${item.lookupCode} (${item.description})`
                      );
                    }}
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: string,
                      options: IResponse<IInventory[]>
                    ) => {
                      const splitValue = newValue?.split(' ');
                      if (splitValue) {
                        const code = splitValue[0];
                        const notCodeValue = splitValue.slice(1);
                        const codeDetails = notCodeValue
                          .join(' ')
                          .replace('(', '')
                          .replace(')', '');
                        const selectedLaborRecord = options.records.find(
                          option => option.lookupCode === code
                        );
                        setFieldValue('details', codeDetails);
                        if (selectedLaborRecord) {
                          setFieldValue('storeInventoryId', selectedLaborRecord?.inventoryId ?? '');
                          setFieldValue('rate', formatMoney(selectedLaborRecord.price));
                        }
                      } else {
                        setFieldValue('inventoryId', '');
                        setFieldValue('details', '');
                      }
                      setFieldValue('postLaborChargesAs', newValue ?? '');
                    }}
                    onBlur={e => {
                      handleBlur(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="details" label="Details" required fullWidth size="small" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="rate"
                    label="Price"
                    required
                    placeholder="0.00"
                    onBlur={e => {
                      handleBlur(e);
                      setFieldValue('rate', formatMoney(e.target.value, 2));
                    }}
                  />
                </Grid>
              </Grid>
              <ModalSaveSection
                isSaveDisabled={isSubmitting || !isValid || hasSameOrderNumber}
                handleCancel={() => {
                  onClose();
                  resetForm();
                }}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
