import { FC } from 'react';
import { TextField, SelectAsync } from '../../../../components/formikMui';
import { Box, Stack } from '@mui/material';
import { getCoverTypes } from '../../../../fetch/sites';
import { ILookupModel } from '../../../../models/util';
import { useFormikContext } from 'formik';

interface IGeneralInformationFormProps {
  handleFormChange: (val?: any) => void;
  readOnly?: boolean;
}

export const GeneralInformationForm: FC<IGeneralInformationFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the GeneralInformationDisplay component as well
  // REMINDER: When adding fields, also update the generalInfoSectionFields array in pool-structure-card
  const { handleBlur } = useFormikContext();
  return (
    <Box mt={2.5}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="size"
            label="Size"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="depth"
            label="Depth"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="tile"
            label="Tile"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="skimBasket"
            label="Skimmer Basket"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="coping"
            label="Coping"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <TextField
            name="skimCover"
            label="Skimmer Cover"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            name="mastic"
            label="Mastic"
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            inputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Box flex={1}>
          <SelectAsync
            name="coverType"
            label="Cover Type"
            apiRequest={getCoverTypes}
            transformResponse={(response: ILookupModel[]) =>
              response.map(item => ({ label: item.description, value: item.value }))
            }
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
            InputProps={{
              readOnly,
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
