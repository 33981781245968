import { IconButton, ListItem, Menu, ListItemButton, Button } from '@mui/material';
import { useState, FC } from 'react';
import { MoreVert } from '@mui/icons-material';
import { ICommissionRule } from '../../../models';
import { deleteCommissionRule } from '../../../fetch';
import { useConfirm } from '../../../hooks';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

interface ICommissionsRulesActionButton {
  commissionRule: ICommissionRule;
  redirect?: string;
  setDeleting: (val: boolean) => void;
  handleEdit: (val: boolean) => void;
  setCurrentCommissionRule: (val: ICommissionRule | null) => void;
  refetchData: () => void;
}

export const CommissionsRulesActionButton: FC<ICommissionsRulesActionButton> = ({
  commissionRule, 
  setDeleting,
  handleEdit,
  setCurrentCommissionRule,
  refetchData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setNewCommissionRule = (commissionRule: ICommissionRule) => {
    setCurrentCommissionRule(commissionRule);
    handleEdit(true); 
    handleClose();
  }

  const confirm = useConfirm();

  return (
    <div>
      <IconButton
        color="primary"
        id="services-actions"
        aria-controls={open ? 'services-actions' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="services-actions"
        MenuListProps={{
          'aria-labelledby': 'services-actions',
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Button
          onClick={() => setNewCommissionRule(commissionRule)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
              Edit Rule
            </ListItemButton>
          </ListItem>
        </Button>
        <ListItem disablePadding>
          <ListItemButton
            onClick={async () => {
              const result = await confirm('Are you sure you want to delete this commission rule?');
              if (result) {
                try {
                  setDeleting(true);
                  await deleteCommissionRule(commissionRule.serviceCommissionRuleId);
                  enqueueSnackbar(`Commission Rule Deleted!`, {
                    variant: 'success',
                  });
                  refetchData();
                } catch (error) {
                } finally {                  
                  handleEdit(false); 
                  setDeleting(false);
                  handleClose();
                }
              }
            }}
            sx={{ color: theme => theme.palette.error.main }}
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
            Delete Rule
          </ListItemButton>
        </ListItem>
      </Menu>
    </div>
  );
};
