import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { dateColFormatter, formatDate } from '../../helpers';
import { dateColMinWidth, statusColMinWidth, nameColMinWidth } from '../../constants/data-grid';
import { ILead } from '../../models';
import { Link } from '../../components';

export const leadsColumns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Lead Date',
    type: 'string',
    valueFormatter: dateColFormatter,
    minWidth: dateColMinWidth,
    renderCell: (params: GridRenderCellParams<ILead>) => {
      const { row: lead } = params;
      return (
        <Link to={`/leads/${lead.leadId}`} type="secondary">
          {lead.date ? formatDate(lead.date) : ''}
        </Link>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    minWidth: statusColMinWidth,
    flex: 0.3,
  },
  {
    field: 'serviceType',
    headerName: 'Service Type',
    type: 'string',
    minWidth: 250,
    flex: 0.3,
  },
  {
    field: 'description',
    headerName: 'Request Summary',
    type: 'string',
    minWidth: 250,
    flex: 1,
  },
  {
    field: 'customerName',
    headerName: 'Contact',
    type: 'string',
    minWidth: nameColMinWidth,
  },
  {
    field: 'source',
    headerName: 'Source',
    type: 'string',
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: 'userLoginName',
    headerName: 'Lead Owner',
    type: 'string',
    sortable: false,
    minWidth: nameColMinWidth,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams<ILead>) => {
      const { row: lead } = params;
      return lead.user?.userName;
    },
  },
];
