import { FC, useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { CardTitle, DisplayGroup, ExternalLink, Loader, Card } from '../../components';
import { ISiteGetResponse } from '../../models';
import { SiteDetailsCol } from './site-details-col';
import { UserContext } from '../../context';
import { getAccounts, getUsers } from '../../fetch';
import { useSnackbar } from 'notistack';
import { getAddressMapLink } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';

interface IDisplaySiteInformationProps {
  isExpanded?: boolean;
  initialExpand?: boolean;
  site: ISiteGetResponse;
}

export const DisplaySiteInformation: FC<IDisplaySiteInformationProps> = ({
  isExpanded,
  initialExpand = true,
  site,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingManagedBy, setIsLoadingManagedBy] = useState(false);
  const [managedBy, setManagedBy] = useState<string | undefined>(undefined);

  const [isLoadingSupervisor, setIsLoadingSupervisor] = useState(false);
  const [supervisor, setSupervisor] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchManagedBy = async () => {
      setIsLoadingManagedBy(true);
      try {
        const response = await getAccounts({
          officeId: user?.officeId,
          accountType: 'ManagementCompany',
          perPage: -1,
        });
        const managementCompany = response.records.filter(
          account => account.accountId === site.mgrAccountId
        )?.[0];
        setManagedBy(managementCompany.accountName ?? '');
      } catch (error: any) {
        enqueueSnackbar(
          error?.Detail || `Error loading the site managment company, please try again.`,
          {
            variant: 'error',
          }
        );
      } finally {
        setIsLoadingManagedBy(false);
      }
    };
    if (!managedBy && !!site.managedBy) {
      fetchManagedBy();
    }

    const fetchSupervisor = async () => {
      setIsLoadingSupervisor(true);
      try {
        const response = await getUsers({ perPage: -1 });
        const siteSupervisor = response.records.filter(user => user.userId === site.userId)?.[0];
        setSupervisor(siteSupervisor?.userName ?? '');
      } catch (error: any) {
        enqueueSnackbar(error?.Detail || `Error loading the site supervisor, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingSupervisor(false);
      }
    };
    if (!supervisor && !!site.supervisor) {
      fetchSupervisor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAddress = !!site?.street || !!site?.city || !!site?.state || !!site?.postalCode;
  const formatAddressBlock = (
    street?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null
  ) => {
    const formattedStreet = !!street ? (
      <>
        {street}
        <br />
      </>
    ) : (
      ''
    );
    const citySeparator = !!state || !!postalCode ? `, ` : '';
    const formattedCity = !!city ? `${city}${citySeparator}` : '';
    const formattedState = !!state ? `${state} ` : '';
    const formattedPostalCode = !!postalCode ? postalCode : '';

    return (
      <>
        {formattedStreet}
        {formattedCity}
        {formattedState}
        {formattedPostalCode}
      </>
    );
  };
  return (
    <Card>
      <CardTitle
        title="Site Information"
        withExpand
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
      >
        <Grid container spacing={2}>
          <SiteDetailsCol>
            <DisplayGroup label="Site Description" labelId="siteDescription">
              {site?.siteDescription ?? ''}
            </DisplayGroup>
          </SiteDetailsCol>
          {user?.officeId && !!site?.managedBy && (
            <SiteDetailsCol>
              <DisplayGroup label="Management Company" labelId="managedBy">
                {isLoadingManagedBy && <Loader type="inline" />}
                {!isLoadingManagedBy && managedBy}
              </DisplayGroup>
            </SiteDetailsCol>
          )}
          {!!site.supervisor && (
            <SiteDetailsCol>
              <DisplayGroup label="Site Supervisor" labelId="supervisor">
                {isLoadingSupervisor && <Loader type="inline" />}
                {!isLoadingSupervisor && supervisor}
              </DisplayGroup>
            </SiteDetailsCol>
          )}
          <SiteDetailsCol>
            <DisplayGroup label="Site Addressee Name" labelId="addressName">
              {site?.addressName ?? ''}
            </DisplayGroup>
          </SiteDetailsCol>
          <SiteDetailsCol>
            <DisplayGroup label="Address" labelId="siteAddress" component="div">
              <Typography component="address">
                {formatAddressBlock(site?.street, site?.city, site?.state, site?.postalCode)}
              </Typography>
              {hasAddress && (
                <ExternalLink
                  type="secondary"
                  target="_blank"
                  to={getAddressMapLink(site?.street, site?.city, site?.state, site?.postalCode)}
                  title="View Map on Bing Maps"
                  className="print--none"
                >
                  <FontAwesomeIcon icon={faMap} size="sm" /> Map
                </ExternalLink>
              )}
            </DisplayGroup>
          </SiteDetailsCol>
        </Grid>
      </CardTitle>
    </Card>
  );
};
