import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { IPoolEquipment } from '../../../../models/sites';
import { DisplayGroup, DisplayColumn } from '../../../../components';

export const GeneralInformationDisplay = () => {
  // REMINDER: When adding fields, ensure that you update the GeneralInformationForm component as well
  // REMINDER: When adding fields, also update the generalInfoSectionFields array in pool-structure-card
  const { values } = useFormikContext<IPoolEquipment>();
  return (
    <Grid container spacing={2} mt={-2} mb={1}>
      <DisplayColumn>
        <DisplayGroup label="Size" labelId="size">
          {values?.size ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Depth" labelId="depth">
          {values?.depth ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Tile" labelId="tile">
          {values?.tile ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Skimmer Basket" labelId="skimBasket">
          {values?.skimBasket ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Coping" labelId="coping">
          {values?.coping ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Skimmer Cover" labelId="skimCover">
          {values?.skimCover ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Mastic" labelId="mastic">
          {values?.mastic ?? ''}
        </DisplayGroup>
      </DisplayColumn>
      <DisplayColumn>
        <DisplayGroup label="Cover Type" labelId="coverType">
          {values?.coverType ?? ''}
        </DisplayGroup>
      </DisplayColumn>
    </Grid>
  );
};
